import { Injectable } from '@angular/core';
import { CalendarEvent, CalendarCategory } from '../models/calendar';
import { setDate, setMonth, endOfDay, endOfMonth, endOfYear } from 'date-fns';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppConstants } from '../utils/app-costants';
import { CalendarEventsViewDialogComponent } from './calendar-events-view-dialog/calendar-events-view-dialog.component';
import { CalendarAddCategoryDialogComponent } from './calendar-add-category-dialog/calendar-add-category-dialog.component';
import { Router } from '@angular/router';
import { MixpanelService } from '../core/services/mixpanel-service.service';
import { Store } from '@ngrx/store';
import {
	createCategoryAction,
	deleteCategoryAction,
	deleteEventAction,
	fixCategoriesOrderAction,
	loadCategoriesAction,
	loadEventsFromDateIntervalAction,
	updateCategoryAction
} from './state/calendar.actions';
import { LandlordService, SectionsList } from '../core/services/landlord/landlord.service';
import { selectCalendarCategories, selectCalendarEvents } from './state/calendar.selectors';
import { Observable } from 'rxjs';
import { EntityDialogsService } from '../core/services/entity-dialogs.service';
import { filter, take } from 'rxjs/operators';
import { LocalizationUtils } from '../utils/localization-utils';
import { RouterExtService } from '../services/router-external.service';

@Injectable({
	providedIn: 'root'
})
export class CalendarService {
	private landlordId: string;

	private translations = LocalizationUtils.getTranslations();
	private systemCategories: { [key: string]: CalendarCategory };

	/* Category colors: #0B93EF #00E6DC #FA3C4C #F4A9D4 #FF9900 #8F37AA */

	constructor(
		private store: Store,
		private dialog: MatDialog,
		private router: Router,
		private analyticsService: MixpanelService,
		private landlordService: LandlordService,
		public eds: EntityDialogsService,
		private readonly routerExtService: RouterExtService
	) {
		this.landlordService
			.getLandlordId()
			.pipe(filter(it => !!it))
			.subscribe(id => {
				this.landlordId = id;

				this.systemCategories = {
					tenants: new CalendarCategory(
						`${this.landlordId}tenants`,
						this.translations.events_cats.tenants,
						'#0B93EF',
						'system',
						0
					),
					lease_reg: new CalendarCategory(
						`${this.landlordId}lease_reg`,
						this.translations.events_cats.lease_reg,
						'#00E6DC',
						'system',
						1
					),
					stamp_duty: new CalendarCategory(
						`${this.landlordId}stamp_duty`,
						this.translations.events_cats.stamp_duty,
						'#FA3C4C',
						'system',
						2
					),
					leases: new CalendarCategory(
						`${this.landlordId}leases`,
						this.translations.events_cats.leases,
						'#F4A9D4',
						'system',
						3
					),
					check_ins_outs: new CalendarCategory(
						`${this.landlordId}check_ins_outs`,
						this.translations.events_cats.check_ins_outs,
						'#FF9900',
						'system',
						4
					)
				};
			});
	}

	public getCalendarCategories(): Observable<CalendarCategory[]> {
		return this.store.select<CalendarCategory[]>(selectCalendarCategories);
	}

	public getCalendarEvents(): Observable<CalendarEvent[]> {
		return this.store.select<CalendarEvent[]>(selectCalendarEvents);
	}

	public loadEventsOf(year: number, month: number = -1, day: number = -1): void {
		let startTime = new Date(year, 0, 1);
		let endTime: Date;

		if (month >= 0) {
			startTime = setMonth(startTime, month);
			if (day >= 0) {
				startTime = setDate(startTime, day);
				endTime = endOfDay(startTime);
			} else {
				endTime = endOfMonth(startTime);
			}
		} else {
			endTime = endOfYear(startTime);
		}
		this.store.dispatch(loadEventsFromDateIntervalAction({ startTime, endTime }));
	}

	public loadCategories(): void {
		return this.store.dispatch(loadCategoriesAction());
	}

	public getSystemCategories(): CalendarCategory[] {
		return Object.values(this.systemCategories);
	}

	public addCategory(name: string, color: string): void {
		const category = new CalendarCategory('', name, color, this.landlordId, 999);

		return this.store.dispatch(createCategoryAction({ category }));
	}

	public editCategory(category: CalendarCategory): void {
		return this.store.dispatch(updateCategoryAction({ category }));
	}

	public deleteCalendarCategoryDialog(category: CalendarCategory): void {
		this.eds
			.openDeleteConfirmDialog(
				$localize`:@@delete_confirm_cal_category_title_new:<span class="color-orange">Delete</span>&nbsp;Category`,
				$localize`:@@delete_confirm_cal_category_desc:You are about to delete the category, are you sure?`,
				$localize`:@@com_delete:Delete`
			)
			.subscribe(confirmed => {
				if (confirmed) {
					// Track event
					this.analyticsService.track('cal_category_delete');
					this.store.dispatch(deleteCategoryAction({ category }));
				}
			});
	}

	public editCalendarEvent(event: CalendarEvent): void {
		this.routerExtService.navigateHidingUrl(['/calendar/addEvent'], {
			state: {
				initialData: {
					event: event
				}
			}
		});
	}

	public deleteCalendarEvent(event: CalendarEvent): void {
		this.eds
			.openDeleteConfirmDialog(
				$localize`:@@delete_confirm_cal_event_title_new:<span class="color-orange">Delete</span>&nbsp;Event`,
				$localize`:@@delete_confirm_cal_event_desc:You are about to delete the event, are you sure?`,
				$localize`:@@com_delete:Delete`
			)
			.subscribe(confirmed => {
				if (confirmed) {
					// Track event
					this.analyticsService.track('cal_event_delete');
					this.store.dispatch(deleteEventAction({ event }));
				}
			});
	}

	public openViewEventsPopup(
		currentDate: Date,
		events: CalendarEvent[],
		categories: CalendarCategory[],
		currentCategory?: string
	): void {
		const dialogRef = this.dialog.open(CalendarEventsViewDialogComponent, {
			...AppConstants.NEW_LATERAL_DIALOG_CONFIG,
			data: { currentDate, events, categories }
		});

		dialogRef.afterClosed().subscribe(async result => {
			if (result.action === 'add_event') {
				const canFeatureWrite = await this.landlordService
					.canFeatureWrite(SectionsList.CALENDAR, true)
					.pipe(take(1))
					.toPromise();

				if (canFeatureWrite) {
					this.addCalendarEvent(currentDate, currentCategory);
				}
			} else if (result.action === 'edit_event') {
				const canFeatureWrite = await this.landlordService
					.canFeatureWrite(SectionsList.CALENDAR, true)
					.pipe(take(1))
					.toPromise();

				if (canFeatureWrite) {
					this.editCalendarEvent(result.event);
				}
			} else if (result.action === 'delete_event') {
				const canFeatureWrite = await this.landlordService
					.canFeatureWrite(SectionsList.CALENDAR, true)
					.pipe(take(1))
					.toPromise();

				if (canFeatureWrite) {
					this.deleteCalendarEvent(result.event);
				}
			} else if (result.action === 'see_tenant') {
				this.router.navigate(['tenants', result.tid]);
			} else if (result.action === 'see_property') {
				this.router.navigate(['properties', result.pid]);
			}
		});
	}

	public addCalendarEvent(
		suggestedDate: Date = new Date(),
		suggestedCategory: string = '',
		title: string = ''
	): void {
		this.routerExtService.navigateHidingUrl(['/calendar/addEvent'], {
			state: {
				initialData: {
					suggestedData: {
						date: suggestedDate,
						categoryId: suggestedCategory,
						title: title
					}
				}
			}
		});
	}

	public createOrUpdateCalendarCategoryDialog(category?: CalendarCategory): void {
		const dialogRef: MatDialogRef<CalendarAddCategoryDialogComponent> = this.dialog.open(
			CalendarAddCategoryDialogComponent,
			{
				...AppConstants.NEW_LATERAL_DIALOG_CONFIG,
				data: { category }
			}
		);

		dialogRef.afterClosed().subscribe(result => {
			if (result && result.action === 'delete') {
				this.deleteCalendarCategoryDialog(category);
			}
		});
	}

	public fixCategoryOrder(categories: CalendarCategory[]): void {
		return this.store.dispatch(fixCategoriesOrderAction({ categories }));
	}
}
