<div mat-dialog-content>
	<input type="text" style="display: none" cdkFocusInitial />

	<div fxLayout="column" fxLayoutAlign="flex-start center" class="popup-top-section">
		<div class="v-spacing-20"></div>

		<p class="semi-bold-15 color-white no-margin" i18n="@@send_tenant_invite_title">
			<span class="color-orange">Send </span>Tenant App Invite
		</p>
	</div>

	<div fxLayout="column" fxLayoutAlign="flex-start flex-start" fxLayoutWrap>
		<div *ngIf="status === 0" class="popup-margin">
			<div fxLayout="row" fxLayoutAlign="center center">
				<img src="./assets/img/ic_tenant_invite_multiple.svg" class="top-img" />
			</div>

			<p
				*ngIf="data.alreadyLinked > 0"
				class="regular-12 no-margin text-center"
				i18n="@@send_tenant_invite_multiple_desc_1"
			>
				You have selected <span class="semi-bold-12 color-1">{{ data.total }}</span> tenants, of which
				<span class="semi-bold-12 color-green">{{ data.alreadyLinked }} </span
				><span class="semi-bold-12">have already set up</span> the Places App<br />
			</p>
			<p
				*ngIf="data.alreadyLinked > 0"
				class="regular-12 no-margin text-center v-spacing-20"
				i18n="@@send_tenant_invite_multiple_desc_2"
			>
				Do you want to send an invitation to the remaining
				<span class="semi-bold-12">{{ data.total - data.alreadyLinked }}</span> tenants?
			</p>

			<p
				*ngIf="data.alreadyLinked === 0"
				class="regular-12 no-margin text-center"
				i18n="@@send_tenant_invite_multiple_desc_3"
			>
				Do you want to send an invitation for Places App to
				<span class="semi-bold-12">{{ data.total }}</span> tenants?
			</p>

			<div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 30px">
				<div fxLayout="column" fxLayoutAlign="center flex-start" style="text-align: center">
					<div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="20px" style="margin-top: 10px">
						<p class="regular-15 no-margin" i18n="@@send_tenant_invite_multiple_email_desc">
							Send invite via email
						</p>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="status === 1" class="popup-margin">
			<div fxLayout="row" fxLayoutAlign="center center">
				<img src="./assets/img/payment_validated_2.png" class="top-img-done" />
			</div>

			<p class="semi-bold-15 no-margin text-center" i18n="@@send_tenant_invite_multiple_done_desc_1">
				<span class="color-green">{{ data.total }}</span> invites <span class="color-green">sent</span>.
			</p>
			<p class="regular-12 no-margin text-center v-spacing-20" i18n="@@send_tenant_invite_multiple_done_desc_2">
				An invite has been sent to the selected tenants
			</p>
		</div>

		<div
			class="popup-bottom-section"
			fxLayout="row"
			fxLayoutAlign="space-between center"
			fxLayoutWrap
			style="width: 100%"
			*ngIf="status === 0"
		>
			<button mat-button class="button-negative-empty button-small" i18n="@@com_cancel" (click)="cancelClick()">
				Cancel
			</button>

			<button (click)="proceedClick()" mat-button class="button-positive button-small" i18n="@@com_send">
				Send
			</button>
		</div>
		<div
			class="popup-bottom-section"
			fxLayout="row"
			fxLayoutAlign="center center"
			fxLayoutWrap
			style="width: 100%"
			*ngIf="status === 1"
		>
			<button (click)="doneClick()" mat-button class="button-primary button-small" i18n="@@com_ok">Ok</button>
		</div>
	</div>
</div>
