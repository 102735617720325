import { MaintenancePriority } from 'src/app/models/maintenanceRequest.model';
import { UnitType } from 'src/app/models/property.model';
import { PaymentsCategoriesService } from 'src/app/payments/paymentsCategories.service';

export enum DateCustomFilterType {
	TODAY = 'TODAY',
	LAST_WEEK = 'LAST_WEEK',
	LAST_MONTH = 'LAST_MONTH',
	LAST_3_MONTHS = 'LAST_3_MONTHS',
	LAST_6_MONTHS = 'LAST_6_MONTHS',
	CUSTOM = 'CUSTOM'
}

export enum CustomFilterType {
	DATE,
	SELECT,
	GREATER_THAN,
	SMALLER_THAN,
	EQUAL
}

export enum PaymentType {
	IN = 'in',
	OUT = 'out',
	REIMBURSEMENT = 'reimbursement'
}

export interface CustomFilterOption {
	value: any; // The value is for reference and it could be any object, where as the displayed value is for showing on the mat select
	displayedValue: string;
}

export interface SingleFilterSelectorData {
	id: string;
	name: string;
	type: CustomFilterType;
	isSelected?: boolean;
	availableOptions: CustomFilterOption[];
	selectedCustomOption?: CustomFilterOption;
	selectedCustomDate?: CustomFilterDate;
	selectedCustomNumber?: number;
}

/**
 * This is what roommate-custom-filter components accepts and display
 */
export interface FilterSelectorData {
	[filterId: string]: SingleFilterSelectorData;
}

export interface CustomFilterDate {
	from: Date;
	to: Date;
}

export interface AppliedFilter {
	filterId: string;
	filterName?: string;
	type: CustomFilterType;
	selectedValue?: CustomFilterOption;
	selectedCustomDate?: CustomFilterDate;
	selectedCustomNumber?: number;
}

export interface FilterContainer {
	selectedTabId?: string;
	searchedValue?: string;
	appliedFilters?: AppliedFilter[];
}

export class FilterContainer {
	static getFilterIfExists(it: FilterContainer, key: string): AppliedFilter | undefined {
		return it.appliedFilters.find(it => it.filterId === key);
	}
}

export interface SortEvent {
	type?:
		| 'name'
		| 'property'
		| 'rent'
		| 'leaseEndingDate'
		| 'rented_units'
		| 'address'
		| 'owner'
		| 'type'
		| 'dueDate'
		| 'assignee'
		| 'category'
		| 'unit'
		| 'inflow'
		| 'outflow'
		| 'amount'
		| 'invoice'
		| 'lease_terms'
		| 'title'
		| 'category'
		| 'added_by'
		| 'reported_on'
		| 'num_properties'
		| 'email'
		| 'room_availability'
		| 'listing_score';
	direction?: 1 | -1;
}

export interface FiltersStoreModel {
	type:
		| 'tenants'
		| 'properties'
		| 'units'
		| 'maintenances'
		| 'owners'
		| 'payments'
		| 'wallet'
		| 'cm-units'
		| 'cm-sync-units'
		| 'broadcast';
	filters?: FilterContainer;
	sort?: SortEvent;
	pageIndex?: number;
	entityType?: string;
	entityId?: string;
}

export interface PaymentsStoreModel extends FiltersStoreModel {
	type: 'payments';
}

export interface SortQuery {
	sortType?:
		| 'name'
		| 'property'
		| 'rent'
		| 'leaseEndingDate'
		| 'rented_units'
		| 'address'
		| 'owner'
		| 'type'
		| 'dueDate'
		| 'assignee'
		| 'category'
		| 'unit'
		| 'inflow'
		| 'outflow'
		| 'amount'
		| 'invoice'
		| 'lease_terms'
		| 'title'
		| 'category'
		| 'added_by'
		| 'reported_on'
		| 'num_properties'
		| 'email'
		| 'room_availability'
		| 'listing_score';
	sortDirection?: 1 | -1;
}

/* Tenant filters */
export enum TenantFilterKeys {
	APP_INSTALLED = 'appInstalled',
	APP_INVITED = 'appInvited',
	PENDING = 'pending',
	ARCHIVED = 'archived',
	TENANT_TYPE = 'type'
}
export interface TenantQueryFilters {
	status?: 'all' | 'overdue' | 'expiring';
	text?: string;
	appInstalled?: boolean;
	appInvited?: boolean;
	pending?: boolean;
	archived?: boolean;
	studentType?: boolean;
}

/* Property filters */
export enum PropertyTabKeys {
	ALL = 'all',
	OVERDUE = 'overdue',
	EXPIRING = 'expiring'
}

export enum PropertyFilterKeys {
	OWNER = 'ownerId'
}
export interface PropertyQueryFilters {
	status?: 'all' | 'overdue' | 'expiring';
	text?: string;
	ownerId?: string;
}

/* Unit filters */
export enum UnitTabKeys {
	RENTED = 'rented',
	SOON_RENTED = 'soon_rented',
	VACANT = 'vacant',
	EXPIRING = 'expiring',
	ALL = 'all',
	BOOKED = 'booked'
}

export enum UnitFilterKeys {
	TYPE = 'type'
}
export interface UnitQueryFilters {
	status?:
		| 'all'
		| 'vacant'
		| 'expiring'
		| 'rented'
		| 'soon_rented'
		| 'booked'
		| 'cm-synced'
		| 'cm-syncing'
		| 'cm-error';
	text?: string;
	type?: UnitType;
	channelManager?: boolean;
	channelManagerSync?: boolean;
}

/** Payment filters. They make use of a bit more classes because they were coded with a different approach in mind */
export interface BaseQueryOptions {
	page: number;
	items: number;
}
export interface PaymentOptions extends BaseQueryOptions {
	filters?: PaymentQueryFilters;
	sort?: SortQuery;
}

export enum PaymentTabKeys {
	ALL = 'all',
	OPEN = 'open',
	PENDING = 'pending',
	PAID = 'paid',
	OVERDUE = 'overdue',
	UPCOMING = 'upcoming',
	FORECASTED = 'forecasted'
}
export enum PaymentFilterKeys {
	CATEGORY = 'category',
	BY_DATE = 'by_date',
	INVOICE = 'invoice',
	TYPE = 'type',
	OWNER = 'owner'
}

export interface PaymentQueryFilters {
	dateFrom?: number;
	dateTo?: number;
	invoice?: boolean;
	category?: string[];
	type?: 'in' | 'out' | 'reimbursement';
	status?: 'open' | 'pending' | 'close' | 'overdue' | 'upcoming' | 'forecasted';
	tenantId?: string;
	propertyId?: string;
	leaseId?: string;
	ownerId?: string;
	propertyOwnerId?: string;
	tenantIds?: string[];
	ownerIds?: string[];
	propertyIds?: string[];
	unitIds?: string[];
	textSearch?: string;
	paymentIds?: string[];
	ownerPageQuery?: boolean;
}

/* Maintenances filters */
export enum MaintenanceFilterKeys {
	PRIORITY = 'priority',
	ADDED_BY = 'addedByYou'
}

export enum MaintenancesTabKeys {
	ALL = 'all',
	OPEN = 'open',
	CLOSED = 'close'
}

export interface MaintenanceQueryFilters {
	status?: MaintenancesTabKeys;
	text?: string;
	addedByYou?: boolean;
	priority?: number;
}

export const maintenancesFilterData = {
	[MaintenanceFilterKeys.PRIORITY]: {
		id: MaintenanceFilterKeys.PRIORITY,
		name: $localize`:@@com_priority:Priority`,
		availableOptions: [
			{
				value: MaintenancePriority.MEDIUM,
				displayedValue: $localize`:@@top-menu-maintenance-medium-priority:Medium`
			},
			{
				value: MaintenancePriority.HIGH,
				displayedValue: $localize`:@@top-menu-item-high-priority:High`
			},
			{
				value: MaintenancePriority.CRITICAL,
				displayedValue: $localize`:@@top-menu-item-critical:Critical`
			}
		],
		type: CustomFilterType.SELECT
	},
	[MaintenanceFilterKeys.ADDED_BY]: {
		id: MaintenanceFilterKeys.ADDED_BY,
		name: $localize`:@@com_added_by:Added by`,
		availableOptions: [
			{
				value: 'true',
				displayedValue: $localize`:@@com_you:You`
			},
			{
				value: 'false',
				displayedValue: $localize`:@@com_tenants:Tenants`
			}
		],
		type: CustomFilterType.SELECT
	}
};

/** Payments filters */
export const paymentsFilterData = {
	[PaymentFilterKeys.BY_DATE]: {
		id: PaymentFilterKeys.BY_DATE,
		name: $localize`:@@com_date:Date`,
		availableOptions: [
			{
				value: DateCustomFilterType.LAST_WEEK,
				displayedValue: $localize`:@@com_last_week:Last Week`
			},
			{
				value: DateCustomFilterType.LAST_MONTH,
				displayedValue: $localize`:@@com_last_month:Last Month`
			},
			{
				value: DateCustomFilterType.LAST_3_MONTHS,
				displayedValue: $localize`:@@com_last_3_months:Last 3 Months`
			},
			{
				value: DateCustomFilterType.LAST_6_MONTHS,
				displayedValue: $localize`:@@com_last_6_months:Last 6 Months`
			}
		],
		type: CustomFilterType.DATE
	},
	[PaymentFilterKeys.CATEGORY]: {
		id: PaymentFilterKeys.CATEGORY,
		name: $localize`:@@com_category:Category`,
		availableOptions: PaymentsCategoriesService.getAllCategories().map(category => {
			return {
				value: category.id,
				displayedValue: category.name
			};
		}),
		type: CustomFilterType.SELECT
	},
	[PaymentFilterKeys.INVOICE]: {
		id: PaymentFilterKeys.INVOICE,
		name: $localize`:@@com_invoice:Invoice`,
		availableOptions: [
			{
				value: true,
				displayedValue: $localize`:@@com_generated_invoices:Generated Invoices`
			},
			{
				value: false,
				displayedValue: $localize`:@@com_non_generated_invoices:Non Generated Invoices`
			}
		],
		type: CustomFilterType.SELECT
	},
	[PaymentFilterKeys.TYPE]: {
		id: PaymentFilterKeys.TYPE,
		name: $localize`:@@com_type:Type`,
		availableOptions: [
			{
				value: PaymentType.IN,
				displayedValue: $localize`:@@com_incomes:Incomes`
			},
			{
				value: PaymentType.OUT,
				displayedValue: $localize`:@@com_costs:Costs`
			},
			{
				value: PaymentType.REIMBURSEMENT,
				displayedValue: $localize`:@@com_reimbursements:Reimbursements`
			}
		],
		type: CustomFilterType.SELECT
	}
};

/* Owners  filters */
export interface OwnersQueryFilters {
	status?: 'all' | 'natural' | 'legal';
	text?: string;
}

/* Availability filters */
export enum AvailabilityTabKeys {
	ALL = 'all',
	RENTED = 'rented',
	VACANT = 'vacant',
	BOOKED = 'booked'
}

export const availailityFiltersData = {
	check_availability: {
		id: 'check_availability',
		name: $localize`:@@availability_filter_check_availability:Check Availability`,
		availableOptions: [],
		type: CustomFilterType.DATE
	},
	rent_value: {
		id: 'rent_value',
		name: $localize`:@@availability_filter_rent_value:Rent Value`,
		availableOptions: [],
		type: CustomFilterType.SMALLER_THAN
	},
	unit_type: {
		id: 'unit_type',
		name: $localize`:@@availability_filter_unit_type:Unit Type`,
		availableOptions: [
			{
				value: 'single',
				displayedValue: $localize`:@@com_single:Single`
			},
			{
				value: 'double',
				displayedValue: $localize`:@@com_double:Double`
			},
			{
				value: 'property',
				displayedValue: $localize`:@@com_whole_apartment:Whole apartment`
			}
		],
		type: CustomFilterType.SELECT
	}
};

/* Bookings */
export enum BookingTabKeys {
	ALL = 'all',
	PENDING = 'pending',
	CONFIRMED = 'confirmed',
	PROCESSED = 'processed',
	TENANT = 'tenant',
	OTHER = 'other'
}

/* Leases */
export enum LeaseTabKeys {
	ALL = 'all',
	CURRENT = 'current',
	UPCOMING = 'upcoming',
	PAST = 'past'
}

/* Other filter tabs */
export enum ChannelManagerChannelsTabKeys {
	AVAILABLE = 'available',
	ACTIVE = 'active'
}

export enum ChannelManagerUnitsTabsKes {
	ALL = 'all',
	SYNCED = 'synced',
	SYNCING = 'syncing',
	ERROR = 'error'
}
