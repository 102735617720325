import { enableProdMode, TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
}

// use the require method provided by webpack
declare const require;
// we use the webpack raw-loader to return the content as a string

const locale = localStorage.getItem('locale');

const translationModule = { providers: [] };

if (locale === 'it') {
	const translations = require(`raw-loader!./locale/messages.${locale}.xlf`);
	translationModule.providers.push(
		{ provide: TRANSLATIONS, useValue: translations },
		{ provide: TRANSLATIONS_FORMAT, useValue: 'xlf' }
	);
}

platformBrowserDynamic()
	.bootstrapModule(AppModule, translationModule)
	.catch(err => console.error(err));
