import { ElementRef, Injectable } from '@angular/core';
import {
	ConnectedPosition,
	ConnectionPositionPair,
	Overlay,
	OverlayConfig,
	PositionStrategy
} from '@angular/cdk/overlay';
export interface PopoverPositions {
	[key: string]: ConnectionPositionPair;
}

export const POSITION_MAP: PopoverPositions = {
	top: new ConnectionPositionPair({ originX: 'center', originY: 'top' }, { overlayX: 'center', overlayY: 'bottom' }),
	topLeft: new ConnectionPositionPair(
		{ originX: 'start', originY: 'top' },
		{ overlayX: 'start', overlayY: 'bottom' }
	),
	topRight: new ConnectionPositionPair({ originX: 'end', originY: 'top' }, { overlayX: 'end', overlayY: 'bottom' }),
	right: new ConnectionPositionPair({ originX: 'end', originY: 'center' }, { overlayX: 'start', overlayY: 'center' }),
	rightTop: new ConnectionPositionPair({ originX: 'end', originY: 'top' }, { overlayX: 'start', overlayY: 'top' }),
	rightBottom: new ConnectionPositionPair(
		{ originX: 'end', originY: 'bottom' },
		{ overlayX: 'start', overlayY: 'bottom' }
	),
	bottom: new ConnectionPositionPair(
		{ originX: 'center', originY: 'bottom' },
		{ overlayX: 'center', overlayY: 'top' }
	),
	bottomRight: new ConnectionPositionPair(
		{ originX: 'start', originY: 'bottom' },
		{ overlayX: 'start', overlayY: 'top' }
	),
	bottomLeft: new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }),
	left: new ConnectionPositionPair({ originX: 'start', originY: 'center' }, { overlayX: 'end', overlayY: 'center' }),
	leftTop: new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'end', overlayY: 'top' }),
	leftBottom: new ConnectionPositionPair(
		{ originX: 'start', originY: 'bottom' },
		{ overlayX: 'end', overlayY: 'bottom' }
	)
};

@Injectable({
	providedIn: 'root'
})
export class PopoverService {
	constructor() {}

	public getOverlayConfig(overlay: Overlay, positions: PopoverPositions, origin: ElementRef): OverlayConfig {
		const defaultConfig = new OverlayConfig({
			positionStrategy: this.getOverlayPositionStrategy(overlay, origin, positions),
			width: 'fit-content',
			height: 'fit-content'
		});
		return defaultConfig;
	}

	private getOverlayPositionStrategy(
		overlay: Overlay,
		origin: ElementRef,
		positions?: PopoverPositions
	): PositionStrategy {
		overlay.scrollStrategies.block();
		const connectedPositions: ConnectedPosition[] = Object.values(positions);
		return overlay
			.position()
			.flexibleConnectedTo(origin)
			.withPositions(connectedPositions)
			.withFlexibleDimensions(false)
			.withPush(true);
	}
}
