import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { publishNewFiltersAction } from 'src/app/core/services/filters/state/filters.actions';
import { OwnerPageModelStore } from 'src/app/models/propertyOwner.model';
import {
	addPropertySuccessAction,
	deletePropertySuccessAction,
	editPropertySuccessAction,
	loadPropertiesBasicSuccessAction,
	loadPropertyByIdSuccessAction,
	uploadMultiplePropertiesSuccessAction
} from 'src/app/properties/state/properties.actions';
import {
	addNewPropertyToOwnerSuccessAction,
	addOwnerSuccessAction,
	deleteOwnerSuccessAction,
	editOwnerSuccessAction,
	loadOwnersPaginatedSuccessAction,
	loadOwnersSuccessAction,
	loadOwnerSuccessAction,
	resetOwnersStateSuccessAction
} from './owners.actions';

export interface OwnerPagesState extends EntityState<OwnerPageModelStore> {
	isLoaded: boolean;
}
export const ownerPagesAdapter = createEntityAdapter<OwnerPageModelStore>({
	selectId: model => model.id
});
const initialOwnerPagesState = ownerPagesAdapter.getInitialState({
	isLoaded: false
});

export const OwnerPagesReducers = createReducer(
	initialOwnerPagesState,

	// Load pages

	on(loadOwnersPaginatedSuccessAction, (state, payload) => {
		if (payload.ownersQueryResult) {
			return ownerPagesAdapter.upsertOne(
				{
					id: payload.page,
					ownerIds: payload.ownersQueryResult.data.map(property => property.id),
					totalItems: payload.ownersQueryResult.metadata.totalItems || 0,
					filteredItems: payload.ownersQueryResult.metadata.filteredItems || 0
				},
				{ ...state, isLoaded: true }
			);
		} else {
			return state;
		}
	}),

	// Filters user actions

	on(publishNewFiltersAction, (state, payload) => {
		if (payload.filters.type === 'owners' && (payload.filters.filters || payload.filters.sort)) {
			return initialOwnerPagesState;
		} else {
			return state;
		}
	}),

	// Owner actions (user actions + realtime updates)

	// ALL pages invalidation

	on(
		resetOwnersStateSuccessAction,

		loadOwnerSuccessAction,

		addOwnerSuccessAction,
		editOwnerSuccessAction,

		(state, payload) => ({ ...initialOwnerPagesState, isLoaded: true })
	),

	on(loadOwnersSuccessAction, loadPropertiesBasicSuccessAction, (state, payload) => {
		if (payload['refreshAll']) {
			return { ...initialOwnerPagesState, isLoaded: true };
		} else {
			return state;
		}
	}),

	// SOME pages invalidation

	on(
		deleteOwnerSuccessAction,
		addNewPropertyToOwnerSuccessAction,

		(state, payload) => {
			const allPages = Object.values(state.entities).sort((a, b) => a.id - b.id);
			let minDirtyPage = -1;
			for (let i = 0; i < allPages.length; i++) {
				if (allPages[i].ownerIds.includes(payload.ownerId)) {
					minDirtyPage = allPages[i].id;
					break;
				}
			}

			if (minDirtyPage >= 0) {
				return ownerPagesAdapter.removeMany(it => it.id >= minDirtyPage, state);
			} else {
				return state;
			}
		}
	),

	// Property actions (user actions + realtime updates)

	// ALL pages invalidation

	on(
		loadPropertyByIdSuccessAction,

		addPropertySuccessAction,
		editPropertySuccessAction,

		uploadMultiplePropertiesSuccessAction,

		deletePropertySuccessAction,

		(state, payload) => ({ ...initialOwnerPagesState, isLoaded: true })
	)
);
