import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { addDays } from 'date-fns';
import { SnotifyService } from 'ng-snotify';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BillingService, BillingFeatures } from 'src/app/billing/billing.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { LandlordService } from 'src/app/core/services/landlord/landlord.service';
import { MixpanelService } from 'src/app/core/services/mixpanel-service.service';
import { PresenceService } from 'src/app/core/services/presence.service';
import { PropertyService } from 'src/app/properties/property.service';
import { ChangePaidPlanDialogComponent } from '../change-paid-plan-dialog/change-paid-plan-dialog.component';

@Component({
	selector: 'app-choose-paid-plan-dialog',
	templateUrl: './choose-paid-plan-dialog.component.html',
	styleUrls: ['./choose-paid-plan-dialog.component.scss']
})
export class ChoosePaidPlanDialogComponent implements OnInit, OnDestroy {
	public landlordName: string;
	private numOfUnits: number;
	private componentDestroyed = new Subject();
	public confirmCheckbox: boolean = false;
	private landlordData: any;
	public premiumMaxUnits = BillingFeatures.PREMIUM_MAX_UNITS;
	public professionalMaxUnits = BillingFeatures.PROFESSIONAL_MAX_UNITS;
	isAdmin: boolean = true;

	constructor(
		public dialogRef: MatDialogRef<ChoosePaidPlanDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { origin: string; plan: string; requestedPlan?: string },
		public authService: AuthService,
		private prensenceService: PresenceService,
		private billingService: BillingService,
		private propertyService: PropertyService,
		private readonly landlordService: LandlordService,
		private toastService: SnotifyService,
		public dialog: MatDialog,
		private readonly router: Router,
		private readonly mixpanelService: MixpanelService
	) {}

	ngOnDestroy(): void {
		this.componentDestroyed.next();
	}

	ngOnInit(): void {
		this.propertyService
			.getPropertiesBasicArray()
			.pipe(takeUntil(this.componentDestroyed))
			.subscribe(propertiesBasic => {
				const realProperties = propertiesBasic.filter(p => !p.isMock);
				this.numOfUnits = realProperties.map(p => p.units).reduce((a, b) => a.concat(b), []).length;
			});

		this.landlordService
			.getLandlordUserData()
			.pipe(takeUntil(this.componentDestroyed))
			.subscribe(data => {
				this.isAdmin = data.role === 'admin' || data.role === 'system_admin';
				this.landlordName = data.name + ' ' + data.surname;
			});
	}

	getLandlordName(): string {
		return this.landlordName;
	}

	btnProfessionalClick() {
		this.planMainActionClick('professional', this.data.plan);
	}

	btnPermiumClick() {
		this.planMainActionClick('premium', this.data.plan);
	}

	finalizeSubscriptionClick() {
		this.planMainActionClick(this.data.requestedPlan, this.data.plan);
	}

	planMainActionClick(selected, current) {
		if (selected === current) {
			// MANAGE

			this.billingService.requestPortalPage();

			// TODO: track plan cancellation events
			// this.mixpanelService.track('plan_switch');
		} else if (current.startsWith('free')) {
			// UPGRADE

			this.billingService.requestCheckoutPage(selected, null, {
				success: () => {
					this.mixpanelService.track('plan_switch');
					this.router.navigateByUrl('/waiting');
					this.dialogRef.close({ action: `chosen_${selected}` });
				}
			});
		} else {
			// CHANGE

			let numUnits = this.numOfUnits;
			if (numUnits === 0) {
				numUnits = 1;
			}

			this.billingService
				.loadChangePlanEstimateInfo(selected, numUnits)
				.pipe(takeUntil(this.componentDestroyed))
				.subscribe(result => {
					const changePlanDialogRef = this.dialog.open(ChangePaidPlanDialogComponent, {
						width: '720px',
						backdropClass: 'backdrop-dark-background',
						disableClose: true,
						data: { estimation: result, currentPlan: current, selectedPlan: selected },
						panelClass: 'no-padding-modalbox'
					});

					changePlanDialogRef.afterClosed().subscribe(res => {
						if (res.action === 'ok') {
							this.mixpanelService.track('plan_switch');
							this.dialogRef.close({ action: `changed_${selected}` });
							this.router.navigateByUrl('/waiting');
						}
					});
				});
		}
	}

	downgradeRequestedPlanClick() {
		this.billingService.downgradeRequestedPlan();
		this.dialogRef.close({ action: 'downgrade' });
	}

	logout() {
		this.prensenceService.signOut();
		this.authService.logout();

		this.dialogRef.close({ action: 'logout' });
	}

	closeClick() {
		this.dialogRef.close({ action: 'none' });
	}

	selectionChange(event) {
		this.confirmCheckbox = event.checked;
	}

	confirmSkipClick() {
		if (!this.confirmCheckbox) {
			this.toastService.error(
				$localize`:@@checkbox_not_checked: Click the checkbox to confirm that you have read and acknowledged the changes`,
				''
			);
			return;
		}

		this.landlordService.allowLandlordSkipBlock(1);
		this.dialogRef.close({ action: 'none' });
	}
}
