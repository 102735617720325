import { BasePageModelStore } from './common';
import { AttachedFile } from './fileRm.model';

export enum MaintenancePriority {
	LOW = 0, // Not used
	MEDIUM = 1,
	HIGH = 2,
	CRITICAL = 3
}
export class MaintenanceRequest {
	constructor(
		public id: string,
		public title: string,
		public message: string,
		public reportedOn: number,
		public status: number,
		public tenantId: string,
		public propertyId: string,
		public photos: string[] = [],
		public priority: number = MaintenancePriority.MEDIUM,
		public resolvedOn: number = 0,
		public files: AttachedFile[] = [],
		public category: string,
		public unitId: string = '',
		public privateNotes: string = '',
		public isMock: boolean = false
	) {}

	public static sanitize(m: MaintenanceRequest): MaintenanceRequest {
		const mte = new MaintenanceRequest(
			m.id,
			m.title,
			m.message,
			m.reportedOn,
			m.status,
			m.tenantId,
			m.propertyId,
			m.photos,
			m.priority,
			m.resolvedOn,
			m.files,
			m.category || '',
			m.unitId || '',
			m.privateNotes || '',
			m.isMock
		);

		if (mte.priority === MaintenancePriority.LOW) {
			mte.priority = MaintenancePriority.MEDIUM;
		}

		return mte;
	}
}

export interface MaintenanceViewData {
	maintenance: MaintenanceRequest;
	propertyId: string;
}

export interface MaintenancePageModelStore extends BasePageModelStore {
	maintenanceIds: string[];
}

export interface MaintenancePageModelUI {
	maintenances: MaintenanceRequest[];
	totalItems: number;
	filteredItems: number;
}
