import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { QueryResult } from 'src/app/models/common';
import { OperationStatus } from 'src/app/models/payments';
import { environment } from 'src/environments/environment';
import { LandlordPaymentUserProfile, MangopayTransaction } from '../../models/mangopay.model';
import { SettingsWalletData } from '../../models/settingsWallet.model';
import { LandlordService } from './landlord/landlord.service';

interface MangopayTransactionsResponse {
	list: MangopayTransaction[];
	pages: number;
	itemsCount: number;
}
@Injectable({
	providedIn: 'root'
})
export class MangopayService {
	private BACKEND_HOST = `${environment.services.backend}/payments/v1`;
	private landlordId: string;
	private readonly MILLISECONDS_MULTIPLIER = 1000;

	constructor(
		private readonly store: Store,
		private landlordService: LandlordService,
		private httpClient: HttpClient
	) {
		this.updateInfo();
	}

	private updateInfo() {
		this.landlordService
			.getLandlordId()
			.pipe(take(1))
			.subscribe(landlordId => {
				this.landlordId = landlordId;
				this.getLandlordPaymentsInfo();
			});
	}

	refreshPaymentStatus() {
		this.updateInfo();
	}

	public getLandlordPaymentsInfo(): Observable<LandlordPaymentUserProfile> {
		return this.httpClient.get<LandlordPaymentUserProfile>(`${this.BACKEND_HOST}/landlords/${this.landlordId}`);
	}

	async processWithWithdrawal(amount: number): Promise<any> {
		return this.httpClient
			.post<any>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/payout`, { amount })
			.pipe(take(1))
			.toPromise();
	}

	getPayouts(page = 1, itemsPerPage?: number): Observable<MangopayTransactionsResponse> {
		return this.httpClient.get<MangopayTransactionsResponse>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/transactions?perPage=${
				itemsPerPage || 10
			}&page=${page}&type=Payout`
		);
	}

	public getWithdrawalsTransactions(page = 1, itemsPerPage?: number): Observable<QueryResult<SettingsWalletData>> {
		return this.getPayouts(page, itemsPerPage).pipe(
			map(result => {
				const totalItems = result.itemsCount;

				let settingWalletTransactions: SettingsWalletData[] = [];
				result.list.forEach(transaction => {
					settingWalletTransactions.push({
						linkedPaymentId: transaction.PaymentId,
						amount: transaction.CreditedFunds.Amount,
						currency: transaction.CreditedFunds.Currency,
						createdDate: transaction.CreationDate * this.MILLISECONDS_MULTIPLIER,
						completionTime: transaction.ExecutionDate * this.MILLISECONDS_MULTIPLIER,
						status: this.getOperationStatus(transaction.Status),
						landlordId: transaction.LandlordId,
						failureReason: transaction.ResultMessage,
						type: 'PAYOUT',
						fee: 0
					});
				});

				return {
					data: settingWalletTransactions,
					metadata: {
						totalItems
					}
				};
			})
		);
	}

	private getOperationStatus(status: string): OperationStatus {
		switch (status) {
			case 'SUCCEEDED':
				return 'succeeded';
			case 'FAILED':
				return 'failure';
			default:
				return 'pending';
		}
	}
}
