import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { MinimumUserData } from 'src/app/models/common';
import { ProductTour, ProductTourComponentName } from 'src/app/models/intercom.model';
import { AuthService } from './auth.service';
import { LandlordService } from './landlord/landlord.service';
import { UsersService } from './users.service';
import { ChannelManagerService } from 'src/app/channel-manager/channel-manager-service';
import { LandlordData } from 'src/app/models/landlord';

declare let Intercom;

@Injectable({
	providedIn: 'root'
})
export class IntercomService {
	landlordId: string = '';
	onboardingDone: boolean = false;

	debugService = false;
	defaultValuesChecked = false;

	log(...args) {
		this.debugService && console.log(...args);
	}

	constructor(
		public readonly router: Router,
		private readonly landlordService: LandlordService,
		private authService: AuthService,
		private usersService: UsersService,
		private channelmanagerService: ChannelManagerService
	) {}

	startIntercom() {
		this.log(`intercom-service started`);

		combineLatest([this.landlordService.getLandlordData(), this.landlordService.getLandlordUserData()])
			.pipe(filter(([landlord, userData]) => !!landlord && !!userData))
			.subscribe(([landlord, userData]) => {
				this.log(
					`intercom-service intercom for ${landlord.name} ${landlord.surname} (${landlord.id}) -> User ${userData.id}`
				);

				if (!!userData) {
					this.isOnboardingDone(userData);
				}
				if (landlord && landlord.id && !this.authService.isGodUser()) {
					Intercom('boot', {
						api_base: 'https://api-iam.intercom.io',
						app_id: 'ifs122wo',
						email: landlord.email,
						user_id: landlord.id,
						name: `${landlord.name} ${landlord.surname}`,
						custom_attributes: JSON.stringify(landlord),
						language_override: landlord.language || '',
						user_type: 'landlord',
						uniaffittiId: landlord['uniaffittiId']
					});
					this.hasChannelsActiveThisLandlord(landlord);
				} else {
					Intercom('hide');
				}
			});
	}

	openNewMessage() {
		Intercom('showNewMessage');
	}

	openGuide(id: number) {
		Intercom('showArticle', id);
	}

	displayHelpCenter() {
		Intercom('show');
	}

	hideHelpCenter() {
		Intercom('hide');
	}

	displayProductTour(componentName: ProductTourComponentName, restartTour: boolean = false) {
		if (this.onboardingDone && !restartTour) {
			return;
		}

		combineLatest([this.landlordService.getLandlordData(), this.landlordService.getLandlordUserData()])
			.pipe(take(1))
			.subscribe(([landlord, userData]) => {
				this.log('intercom-service', `Looking for tour ${componentName}-${landlord.language}`);

				if (restartTour || userData.onboardingEstelle[componentName] === 0) {
					const tourId = ProductTour.getProductTourId(componentName, landlord.language);

					if (tourId) {
						this.log('intercom-service Showing Product Tour ' + tourId);
						Intercom('startTour', tourId);
						if (!restartTour) {
							this.usersService.updateOnboardingData(userData.id, componentName);
							this.isOnboardingDone(userData);
						}
					}
				}
			});
	}

	isOnboardingDone(userData: MinimumUserData) {
		if (!userData.onboardingEstelle) {
			this.onboardingDone = true;
			this.log('intercom-service oboarding not set');
			return;
		}

		let productToursDone = 0;

		Object.keys(userData.onboardingEstelle).map((componentName: ProductTourComponentName) => {
			if (userData.onboardingEstelle[componentName] !== 0) {
				productToursDone++;
			}
		});

		if (productToursDone === Object.keys(ProductTour.productTours).length) {
			this.onboardingDone = true;
			this.log(
				`intercom-service Onboarding Complete! ${Object.keys(ProductTour.productTours).length} Product Tours`
			);
		}
	}

	trackEvent(event: string, metadata?: any) {
		Intercom('trackEvent', event, metadata);
	}

	showChecklist() {
		Intercom('startChecklist', 29927767);
	}

	hasChannelsActiveThisLandlord(landlord: LandlordData) {
		if (this.defaultValuesChecked) {
			return;
		}

		this.channelmanagerService
			.getChannelConnections()
			.pipe(take(1))
			.subscribe(connections => {
				const activeConnections = connections.filter(
					it => it.status === 'active' || it.status === 'created' || it.status === 'error'
				);
				const availableChannels = this.channelmanagerService.allAvailableChannels.filter(availableChannel => {
					const respectiveActiveConnection = activeConnections.filter(
						connection =>
							availableChannel.id === connection.channelId &&
							(connection.status === 'active' || connection.status === 'created')
					);

					return respectiveActiveConnection.length === 0;
				});

				if (
					availableChannels.length > 0 &&
					(landlord.defaultValues?.includedBillsInFlatFees || []).length === 0 &&
					(landlord.defaultValues?.includedBillsInRent || []).length === 0
				) {
					this.trackEvent('request-default-rules');
				}

				this.defaultValuesChecked = true;
			});
	}
}
