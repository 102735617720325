import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { DateFnsModule } from 'ngx-date-fns';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';
import { ScrollEventModule } from 'ngx-scroll-event';
import { DragulaModule } from 'ng2-dragula';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ChannelManagerUnitListingChannelsComponent } from '../channel-manager/channel-manager-unit-listing-channels/channel-manager-unit-listing-channels.component';
import { LeaseItemComponent } from '../leases/lease-item/lease-item.component';
import { AssigneeSelectorComponent } from '../payments/assignee-selector/assignee-selector.component';
import { PaymentHistoryComponent } from '../payments/payment-history/payment-history.component';
import { TextareaAutoresizeDirective } from '../textarea-autoresize.directive';
import { RoommateTableSpinnerComponent } from '../utils/roommate-table-spinner/roommate-table-spinner.component';
import { ActivityLogComponent } from './activity-log/activity-log.component';
import { AdditionalTenantFormComponent } from './additional-tenant-form/additional-tenant-form.component';
import { AdditionalTenantsComponent } from './additional-tenants/additional-tenants.component';
import { AddressFormComponent } from './address-form/address-form.component';
import { BankFormComponent } from './bank-form/bank-form.component';
import { BookingsPopoverComponent } from './bookings-table/bookings-popover/bookings-popover.component';
import { BookingsTableComponent } from './bookings-table/bookings-table.component';
import { ButtonNewPaymentComponent } from './button-new-payment/button-new-payment.component';
import { ChatListComponent } from './chat-list/chat-list.component';
import { CurrencySymbolPipe } from './currency-symbol.pipe';
import { DashLabelOutlineComponent } from './dash-label-outline/dash-label-outline.component';
import { DashLabelSimpleComponent } from './dash-label-simple/dash-label-simple.component';
import { DragDropDirective } from './file-picker/drag-drop-directive';
import { FilePickerComponent } from './file-picker/file-picker.component';
import { FilteredPaymentsTableComponent } from './filtered-payments-table/filtered-payments-table.component';
import { FormatAmountNoCodePipe } from './format-amount-no-code.pipe';
import { FormatAmountSymbolPipeHtml } from './format-amount-symbol-html.pipe';
import { FormatAmountSymbolPipe } from './format-amount-symbol.pipe';
import { InvoiceFormComponent } from './invoice-form/invoice-form.component';
import { InvoiceTypeSelectorComponent } from './invoice-type-selector/invoice-type-selector.component';
import { FilterableItemListComponent } from './list-item-selector/filterable-item-list/filterable-item-list.component';
import { ListItemSelectorComponent } from './list-item-selector/list-item-selector.component';
import { NoteFormComponent } from './note-form/note-form.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { OneShotPaymentDialogComponent } from './one-shot-payment-dialog/one-shot-payment-dialog.component';
import { OwnersTableItemComponent } from './owners-table/owners-table-item/owners-table-item.component';
import { OwnersTableComponent } from './owners-table/owners-table.component';
import { PaymentItemsComponent } from './payment-items/payment-items.component';
import { PaymentRecipientComponent } from './payment-recipient/payment-recipient.component';
import { PaymentsTableNewComponent } from './payments-table-new/payments-table-new.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { PhotoGalleryComponent } from './photo-gallery/photo-gallery.component';
import { PictureMultiselectorComponent } from './picture-multiselector/picture-multiselector.component';
import { PopoverDirective } from './popover.directive';
import { ProgressBarColorDirective } from './progress-bar-color.directive';
import { RightMenuClickDirective } from './RightMenuClickDirective';
import { RoommateListingScoreListComponent } from './roomate-listing-score-list/roomate-listing-score-list.component';
import { RoommateListingScoreSpinnerComponent } from './roomate-listing-score-spinner/roomate-listing-score-spinner.component';
import { RoommateBulkActionsComponent } from './roommate-bulk-actions/roommate-bulk-actions.component';
import { RoommateCalendarPopoverComponent } from './roommate-calendar/roommate-calendar-popover/roommate-calendar-popover.component';
import { RoommateCalendarComponent } from './roommate-calendar/roommate-calendar.component';
import { RoomMateCardUnitComponent } from './roommate-card-unit/roommate-card-unit.component';
import { RoommateCardComponent } from './roommate-card/roommate-card.component';
import { RoommateCustomFilterComponent } from './roommate-custom-filter/roommate-custom-filter.component';
import { RoommateDropdownOptionsComponent } from './roommate-dropdown-options/roommate-dropdown-options.component';
import { RoommateFilterBarComponent } from './roommate-filter-bar/roommate-filter-bar.component';
import { RoommateFilterRangeComponent } from './roommate-filter-range/roommate-filter-range.component';
import { RoommateFullSelectorComponent } from './roommate-full-selector/roommate-full-selector.component';
import { RoommateHelpcenterComponent } from './roommate-helpcenter/roommate-helpcenter.component';
import { RoommateInfoCardComponent } from './roommate-info-card/roommate-info-card.component';
import { RoommateLoadingTableComponent } from './roommate-loading-table/roommate-loading-table.component';
import { RoommateMarginsComponent } from './roommate-margins/roommate-margins.component';
import { RoommateMenuBarMobileComponent } from './roommate-menu-bar-mobile/roommate-menu-bar-mobile.component';
import { RoommatePaginatorComponent } from './roommate-paginator/roommate-paginator/roommate-paginator.component';
import { RoommatePopoverComponent } from './roommate-popover/roommate-popover.component';
import { RoommateProcedureNavigationComponent } from './roommate-procedure-navigation/roommate-procedure-navigation.component';
import { RoommateProgressBarComponent } from './roommate-progress-bar/roommate-progress-bar.component';
import { RoommateProgressCircleComponent } from './roommate-progress-circle/roommate-progress-circle.component';
import { RoommateSearchBarComponent } from './roommate-search-bar/roommate-search-bar.component';
import { RoommateSectionNavigationComponent } from './roommate-section-navigation/roommate-section-navigation.component';
import { RoommateStepperComponent } from './roommate-stepper/roommate-stepper.component';
import { RoommateStyledAmountComponent } from './roommate-styled-amount/roommate-styled-amount.component';
import { RoommateSuggestionsListComponent } from './roommate-suggestions-list/roommate-suggestions-list.component';
import { RoommateTabsComponent } from './roommate-tabs/roommate-tabs.component';
import { RoommateWarningCardComponent } from './roommate-warning-card/roommate-warning-card.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SectionMenuComponent } from './section-menu/section-menu.component';
import { TagListComponent } from './tag-list/tag-list.component';
import { TruncatePipe } from './truncate.pipe';
import { UserPermissionsComponent } from './user-permissions/user-permissions.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { RoommateHelpcenterButtonComponent } from './roommate-helpcenter-button/roommate-helpcenter-button.component';
import { EsignatureDetailsComponent } from './esignature-details/esignature-details.component';
import { FormatAmountNoCodeNoDecimalPipe } from './format-amount-no-code-no-decimal.pipe';
import { LateFeesFormComponent } from './late-fees/late-fees-form/late-fees-form.component';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
	imports: [
		NgxSpinnerModule,
		FlexLayoutModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatProgressBarModule,
		DragDropModule,
		ScrollingModule,
		MatRadioModule,
		MatFormFieldModule,
		MatInputModule,
		MatMenuModule,
		ScrollEventModule,
		MatTooltipModule,
		DateFnsModule,
		MatIconModule,
		MatTableModule,
		MatCheckboxModule,
		MatSelectModule,
		MatDatepickerModule,
		DragulaModule,
		MatProgressSpinnerModule,
		MatPaginatorModule,
		MatSortModule,
		NgxLinkifyjsModule.forRoot(),
		MatGoogleMapsAutocompleteModule,
		MatTabsModule,
		ImageCropperModule,
		MatCardModule,
		MatDialogModule,
		MatButtonModule,
		MatBadgeModule,
		NgImageSliderModule,
		MatStepperModule,
		Ng2TelInputModule,
		MatAutocompleteModule,
		MatOptionModule,
		OverlayModule,
		MatSelectCountryModule,
		MatExpansionModule
	],
	declarations: [
		// SHARED COMPONENTS
		RoommateCardComponent,
		RoomMateCardUnitComponent,
		ListItemSelectorComponent,
		SectionMenuComponent,
		FilePickerComponent,
		TagListComponent,
		DashLabelSimpleComponent,
		DashLabelOutlineComponent,
		PhotoGalleryComponent,
		InvoiceFormComponent,
		AdditionalTenantFormComponent,
		AdditionalTenantsComponent,
		AddressFormComponent,
		PaymentRecipientComponent,
		OneShotPaymentDialogComponent,
		ChatListComponent,
		OwnersTableComponent,
		OwnersTableItemComponent,
		ButtonNewPaymentComponent,
		FilterableItemListComponent,
		PaymentItemsComponent,
		PaymentRecipientComponent,
		PaymentStatusComponent,
		RoommateInfoCardComponent,
		RoommateProgressBarComponent,
		RoommateProgressCircleComponent,
		RoommateStepperComponent,
		RoommateHelpcenterComponent,
		RoommateSectionNavigationComponent,
		RoommateProcedureNavigationComponent,
		RoommatePopoverComponent,
		RoommateCalendarComponent,
		RoommateFilterBarComponent,
		RoommateCustomFilterComponent,
		RoommateDropdownOptionsComponent,
		RoommateTabsComponent,
		RoommateSearchBarComponent,
		RoommateFilterRangeComponent,
		RoommateListingScoreSpinnerComponent,
		RoommateListingScoreListComponent,
		RoommateSuggestionsListComponent,
		BankFormComponent,
		InvoiceTypeSelectorComponent,
		PaymentHistoryComponent,
		ChannelManagerUnitListingChannelsComponent,
		PictureMultiselectorComponent,
		LeaseItemComponent,
		RoommateCalendarPopoverComponent,
		NoteFormComponent,
		// SHARED PIPES & DIRECTIVES
		CurrencySymbolPipe,
		FormatAmountSymbolPipe,
		FormatAmountSymbolPipeHtml,
		FormatAmountNoCodePipe,
		FormatAmountNoCodeNoDecimalPipe,
		TruncatePipe,
		RightMenuClickDirective,
		DragDropDirective,
		ProgressBarColorDirective,
		SafeHtmlPipe,
		PopoverDirective,
		TextareaAutoresizeDirective,
		PictureMultiselectorComponent,
		NotificationsComponent,
		RoommateBulkActionsComponent,
		RoommateWarningCardComponent,
		RoommateMenuBarMobileComponent,
		RoommatePaginatorComponent,
		ActivityLogComponent,
		RoommateLoadingTableComponent,
		AssigneeSelectorComponent,
		RoommateMarginsComponent,
		RoommateStyledAmountComponent,
		PaymentsTableNewComponent,
		FilteredPaymentsTableComponent,
		BookingsTableComponent,
		BookingsPopoverComponent,
		RoommateFullSelectorComponent,
		RoommateTableSpinnerComponent,
		RoommateHelpcenterButtonComponent,
		EsignatureDetailsComponent,
		UserPermissionsComponent,
		RoommateHelpcenterButtonComponent,
		LateFeesFormComponent
	],
	exports: [
		// SHARED COMPONENTS
		RoommatePaginatorComponent,
		RoommateTableSpinnerComponent,
		RoommateCardComponent,
		RoomMateCardUnitComponent,
		ListItemSelectorComponent,
		SectionMenuComponent,
		FilePickerComponent,
		TagListComponent,
		DashLabelSimpleComponent,
		DashLabelOutlineComponent,
		PhotoGalleryComponent,
		InvoiceFormComponent,
		AdditionalTenantFormComponent,
		AdditionalTenantsComponent,
		AddressFormComponent,
		PaymentRecipientComponent,
		OneShotPaymentDialogComponent,
		ChatListComponent,
		OwnersTableComponent,
		OwnersTableItemComponent,
		ButtonNewPaymentComponent,
		PaymentItemsComponent,
		PaymentRecipientComponent,
		PaymentStatusComponent,
		RoommateInfoCardComponent,
		RoommateProgressBarComponent,
		RoommateProgressCircleComponent,
		RoommateStepperComponent,
		RoommateHelpcenterComponent,
		RoommateSectionNavigationComponent,
		RoommateProcedureNavigationComponent,
		RoommatePopoverComponent,
		RoommateCalendarComponent,
		RoommateFilterBarComponent,
		RoommateCustomFilterComponent,
		RoommateDropdownOptionsComponent,
		RoommateFullSelectorComponent,
		RoommateTabsComponent,
		RoommateSearchBarComponent,
		RoommateFilterRangeComponent,
		RoommateListingScoreSpinnerComponent,
		RoommateListingScoreListComponent,
		RoommateSuggestionsListComponent,
		BankFormComponent,
		InvoiceTypeSelectorComponent,
		PaymentHistoryComponent,
		ChannelManagerUnitListingChannelsComponent,
		PictureMultiselectorComponent,
		LeaseItemComponent,
		RoommateCalendarPopoverComponent,
		NoteFormComponent,
		// SHARED PIPES & DIRECTIVES
		CurrencySymbolPipe,
		FormatAmountSymbolPipe,
		FormatAmountSymbolPipeHtml,
		FormatAmountNoCodePipe,
		FormatAmountNoCodeNoDecimalPipe,
		TruncatePipe,
		RightMenuClickDirective,
		DragDropDirective,
		ProgressBarColorDirective,
		SafeHtmlPipe,
		PopoverDirective,
		TextareaAutoresizeDirective,
		// SHARED MODULES
		NgxSpinnerModule,
		FlexLayoutModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatProgressBarModule,
		ScrollingModule,
		MatRadioModule,
		MatFormFieldModule,
		MatInputModule,
		MatMenuModule,
		ScrollEventModule,
		MatTooltipModule,
		DateFnsModule,
		MatIconModule,
		MatTableModule,
		MatCheckboxModule,
		MatSelectModule,
		MatDatepickerModule,
		DragulaModule,
		DragDropModule,
		MatProgressSpinnerModule,
		MatPaginatorModule,
		MatSortModule,
		MatGoogleMapsAutocompleteModule,
		MatTabsModule,
		ImageCropperModule,
		MatCardModule,
		MatDialogModule,
		MatButtonModule,
		MatBadgeModule,
		NgImageSliderModule,
		MatStepperModule,
		Ng2TelInputModule,
		MatAutocompleteModule,
		MatOptionModule,
		OverlayModule,
		RoommateBulkActionsComponent,
		RoommateMenuBarMobileComponent,
		ActivityLogComponent,
		RoommateLoadingTableComponent,
		RoommateMarginsComponent,
		AssigneeSelectorComponent,
		PaymentsTableNewComponent,
		FilteredPaymentsTableComponent,
		BookingsTableComponent,
		UserPermissionsComponent,
		RoommateHelpcenterButtonComponent,
		LateFeesFormComponent
	]
})
export class SharedModule {}
