import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { Property } from 'src/app/models/property.model';
import { resetStoreAction, resetStoreActionSuccess } from 'src/app/store/actions';
import {
	addPropertySuccessAction,
	deletePropertySuccessAction,
	editPropertySuccessAction,
	loadChannelManagerSyncUnitsPaginatedSuccessAction,
	loadChannelManagerUnitsPaginatedSuccessAction,
	loadPropertiesPaginatedSuccessAction,
	loadPropertiesBasicSuccessAction,
	loadPropertyByIdSuccessAction,
	loadUnitsPaginatedSuccessAction,
	resetPropertiesStateSuccessAction,
	uploadMultiplePropertiesSuccessAction
} from './properties.actions';
import { addMockMaintenanceSuccessAction } from 'src/app/maintenances/state/maintenances.actions';
import { addMockLeasesSuccessAction } from 'src/app/leases/state/lease.actions';

export interface PropertiesState extends EntityState<Property> {}

export const propertiesAdapter = createEntityAdapter<Property>();

const initialPropertiesState = propertiesAdapter.getInitialState();

export const propertiesReducer = createReducer(
	initialPropertiesState,
	on(resetPropertiesStateSuccessAction, (state, payload) => (state = initialPropertiesState)),
	on(loadPropertyByIdSuccessAction, addPropertySuccessAction, (state, payload) =>
		propertiesAdapter.upsertOne(payload.property, { ...state, isLoaded: true })
	),
	// We refresh if properties basic are fetched from scrath for any reason
	on(loadPropertiesBasicSuccessAction, (state, payload) => {
		if (payload.propertiesBasic) {
			if (payload.refreshAll) {
				return { ...initialPropertiesState };
			} else {
				return state;
			}
		} else {
			return state;
		}
	}),
	on(uploadMultiplePropertiesSuccessAction, (state, payload) =>
		propertiesAdapter.upsertMany(payload.properties, state)
	),
	on(editPropertySuccessAction, (state, payload) =>
		propertiesAdapter.updateOne({ id: payload.property.id, changes: payload.property }, state)
	),
	on(deletePropertySuccessAction, (state, payload) => propertiesAdapter.removeOne(payload.deletePropertyId, state)),

	on(addMockLeasesSuccessAction, addMockMaintenanceSuccessAction, (state, payload) => {
		if (payload.property) {
			return propertiesAdapter.addOne(payload.property.property, state);
		} else {
			return state;
		}
	}),

	// Version 2
	on(loadPropertiesPaginatedSuccessAction, (state, payload) => {
		if (payload.propertiesQueryResult) {
			return propertiesAdapter.upsertMany(payload.propertiesQueryResult.data, { ...state, isLoaded: true });
		} else {
			return state;
		}
	}),

	on(
		loadUnitsPaginatedSuccessAction,
		loadChannelManagerUnitsPaginatedSuccessAction,
		loadChannelManagerSyncUnitsPaginatedSuccessAction,
		(state, payload) => {
			if (payload.properties) {
				return propertiesAdapter.upsertMany(payload.properties, { ...state, isLoaded: true });
			} else {
				return state;
			}
		}
	)
);
