import { createAction, props } from '@ngrx/store';
import { MinimumUserData } from 'src/app/models/common';
import { LandlordData } from 'src/app/models/landlord';
import { LandlordReport } from 'src/app/models/report';
import { CallbackFunctions } from 'src/app/store/reducer';

export enum LandlordActions {
	INIT_USER = '[Landlord] Init User',

	LOAD_LANDLORD_DATA = '[Landlord] Load Landlord Info',
	LOAD_REPORTS = '[Landlord] Load Reports',
	UPDATE_LANDLORD_DATA = '[Landlord] Update Landlord Data',
	SET_LANDLORD_TOKEN = '[Landlord] Set Landlord Token',
	ASK_FOR_REPORT_GENERATION = '[Landlord] Ask For Report Generation',
	REQUEST_NEW_PASSWORD = '[Landlord] Request New Password',
	LANDLORD_SIGN_OUT = '[Landlord] Sign Out',

	// Success
	LOAD_LANDLORD_DATA_SUCCESS = '[Landlord] Load Landlord Info Success',
	LOAD_REPORTS_SUCCESS = '[Landlord] Load Reports Success',
	UPDATE_LANDLORD_DATA_SUCCESS = '[Landlord] Update Landlord Data Success',
	SET_LANDLORD_TOKEN_SUCCESS = '[Landlord] Set Landlord Token Success',
	ASK_FOR_REPORT_GENERATION_SUCCESS = '[Landlord] Ask For Report Generation Success',
	REQUEST_NEW_PASSWORD_SUCCESS = '[Landlord] Request New Password Success',
	LANDLORD_SIGN_OUT_SUCCESS = '[Landlord] Sign Out Success',

	// Failure
	LOAD_LANDLORD_DATA_FAILURE = '[Landlord] Load Landlord Info Failure',
	LOAD_REPORTS_FAILURE = '[Landlord] Load Reports Failure',
	UPDATE_LANDLORD_FAILURE = '[Landlord] Update Landlord Failure',
	UPDATE_LANDLORD_DATA_FAILURE = '[Landlord] Update Landlord Data Failure',
	SET_LANDLORD_TOKEN_FAILURE = '[Landlord] Set Landlord Token Failure',
	ASK_FOR_REPORT_GENERATION_FAILURE = '[Landlord] Ask For Report Generation Failure',
	REQUEST_NEW_PASSWORD_FAILURE = '[Landlord] Request New Password Failure',
	LANDLORD_SIGN_OUT_FAILURE = '[Landlord] Sign Out Failure'
}

export const initUserAction = createAction(LandlordActions.INIT_USER, props<{ userId?: string; forcedLandlordIdForGodUser?: string }>());
export const loadLandlordDataAction = createAction(LandlordActions.LOAD_LANDLORD_DATA, props<{ landlordId?: string }>());
export const loadReportsAction = createAction(LandlordActions.LOAD_REPORTS);

export const updateLandlordAction = createAction(LandlordActions.UPDATE_LANDLORD_DATA, props<{ landlord: LandlordData; callbacks?: CallbackFunctions }>());
export const setLandlordTokenAction = createAction(LandlordActions.SET_LANDLORD_TOKEN, props<{ token: string }>());
export const askForReportGenerationAction = createAction(LandlordActions.ASK_FOR_REPORT_GENERATION, props<{ options: any; hostedPageId?: string; callbacks: CallbackFunctions }>());
export const requestNewPasswordAction = createAction(LandlordActions.REQUEST_NEW_PASSWORD, props<{ password: string }>());
export const loadLandlordDataSuccessAction = createAction(LandlordActions.LOAD_LANDLORD_DATA_SUCCESS, props<{ landlord: LandlordData; user?: MinimumUserData }>());
export const loadReportsSuccessAction = createAction(LandlordActions.LOAD_REPORTS_SUCCESS, props<{ reports: LandlordReport[] }>());
export const updateLandlordDataSuccessAction = createAction(LandlordActions.UPDATE_LANDLORD_DATA_SUCCESS, props<{ landlord: LandlordData }>());
export const setLandlordTokenSuccessAction = createAction(LandlordActions.SET_LANDLORD_TOKEN_SUCCESS);
export const askForReportGenerationSuccessAction = createAction(LandlordActions.ASK_FOR_REPORT_GENERATION_SUCCESS, props<{ reports: LandlordReport[] }>());
export const requestNewPasswordSuccessAction = createAction(LandlordActions.REQUEST_NEW_PASSWORD_SUCCESS);
export const loadLandlordDataFailureAction = createAction(LandlordActions.LOAD_LANDLORD_DATA_FAILURE, props<{ error?: any }>());
export const loadReportsFailureAction = createAction(LandlordActions.LOAD_REPORTS_FAILURE, props<{ error?: any }>());
export const updateLandlordFailureAction = createAction(LandlordActions.UPDATE_LANDLORD_FAILURE, props<{ error?: any }>());
export const updateLandlordDataFailureAction = createAction(LandlordActions.UPDATE_LANDLORD_DATA_FAILURE, props<{ error?: any }>());
export const setLandlordTokenFailureAction = createAction(LandlordActions.SET_LANDLORD_TOKEN_FAILURE, props<{ error?: any }>());
export const askForReportGenerationFailureAction = createAction(LandlordActions.ASK_FOR_REPORT_GENERATION_FAILURE, props<{ error?: any }>());
export const requestNewPasswordFailureAction = createAction(LandlordActions.REQUEST_NEW_PASSWORD_FAILURE, props<{ error?: any }>());
export const landlordSignOutAction = createAction(LandlordActions.LANDLORD_SIGN_OUT);
export const landlordSignOutSuccessAction = createAction(LandlordActions.LANDLORD_SIGN_OUT_SUCCESS);
export const landlordSignOutFailureAction = createAction(LandlordActions.LANDLORD_SIGN_OUT_FAILURE, props<{ error?: any }>());
