import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/store/reducer';
import { paymentPagesAdapter } from './payments-pages.reducers';

const selectPaymentPagesState = createSelector(
	state => state,
	(state: AppState) => state.paymentPages
);

export const { selectTotal: selectPaymentPagesLength } = paymentPagesAdapter.getSelectors(selectPaymentPagesState);

export const selectPaymentPagesEntities = createSelector(selectPaymentPagesState, state => {
	if (state.isLoaded) {
		return state.entities;
	}
	return null;
});
