import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { of, Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { FileService } from 'src/app/core/services/file/file.service';
import { LandlordService, SectionsList } from 'src/app/core/services/landlord/landlord.service';
import { MixpanelService } from 'src/app/core/services/mixpanel-service.service';
import { AttachedFile, FileStatus, FileWrapper, PendingFile } from 'src/app/models/fileRm.model';
import { ScreenStateService } from 'src/app/services/screen-state.service';
import { FileEntities } from '../../core/services/file/file.service';

@Component({
	selector: 'app-file-picker',
	templateUrl: './file-picker.component.html',
	styleUrls: ['./file-picker.component.scss']
})
export class FilePickerComponent implements OnInit, OnChanges, OnDestroy {
	private files: (AttachedFile | PendingFile)[] = [];
	public stagingArea: FileWrapper[] = [];
	public metadata: { [keyId: string]: string[] } = null;

	@Input() entityId: string;
	@Input() entityType: FileEntities;

	@Input() customAcceptedFiles = '';
	@Input() updateImmediatly = true;
	@Input() uploadEnabled = true;
	@Input() maxFiles = 100;

	@Input() tooltipPublicTitle = '';
	@Input() tooltipPublicDesc = '';
	@Input() tooltipSendCta = '';
	@Input() truncateLenght = 100;
	@Input() showMessage = true;
	@Input() displayedColumns: string[] = [];
	@Input() pressLinkToShare = false;
	@Input() showIsShared = true;
	@Input() checklistFiles: boolean = false;
	@Input() whiteBackground = false;
	@Input() isShared = false;
	@Input() showChatButton = true;

	@Input() readonly = false;

	@Input() askToUploadFileFromOutside: EventEmitter<{ [keyId: string]: string[] }> = null;

	@ViewChild('file', { static: true }) elUploadFile: ElementRef;

	@Output() fileShared = new EventEmitter<AttachedFile>();
	@Output() filesChanged = new EventEmitter<AttachedFile[]>();
	@Output() filesDeleted = new EventEmitter<number>();
	@Output() statusChanged = new EventEmitter<FileWrapper[]>();
	@Output() filesRetrieved = new EventEmitter<AttachedFile[]>();
	@Output() fileAction = new EventEmitter<FileStatus>();

	isLoading = false;
	@Output() loading = new EventEmitter<boolean>();

	private filesChangedSubject = new Subject();
	acceptedFiles = '.pdf,.doc,.docx, image/*';
	hasFileSizeError = false;
	hasLoadingError = false;
	hasFileResizeError = false;
	uploadPending = false;
	waitingForCallback = false;
	showFileDropper = false;
	private componentDestroyed = new Subject();
	allowMultipleDownloads = false;
	isDownloadingAll = false;
	action: FileStatus;

	// Mobile
	mobile: boolean = false;

	constructor(
		private readonly fileService: FileService,
		private readonly screenStateService: ScreenStateService,
		private readonly landlordService: LandlordService
	) {
		this.mobile = screenStateService.isMobileResolution();
	}

	ngOnChanges(changes: SimpleChanges) {
		// Entity exists: we load files
		if (!changes.fileWrappers && (changes.entityType || changes.entityId)) {
			this.startLoading();
			this.loadFilesMetadata();
		}

		this.calculateDonwloadAllFeasability();
		this.setDisplayedColumns();
	}

	ngOnDestroy(): void {
		this.componentDestroyed.next();
	}

	ngOnInit() {
		this.filesChangedSubject
			.pipe(debounceTime(200))
			.pipe(takeUntil(this.componentDestroyed))
			.subscribe(() => {
				this.fileAction.emit(this.action);
				this.filesChanged.emit(
					this.stagingArea.filter(it => it.status === 'done').map(it => it.file as AttachedFile)
				);
			});

		if (!!this.askToUploadFileFromOutside) {
			this.askToUploadFileFromOutside.pipe(takeUntil(this.componentDestroyed)).subscribe(metadata => {
				this.uploadFile(metadata);
			});
		}

		this.screenStateService.screenResized
			.pipe(takeUntil(this.componentDestroyed))
			.subscribe(isMobile => (this.mobile = isMobile));
	}

	calculateDonwloadAllFeasability() {
		const donwloadableFiles = this.stagingArea.filter(it => it.status === 'done');
		if (donwloadableFiles.length < 2) {
			this.allowMultipleDownloads = false;
		} else {
			const totalSize = donwloadableFiles
				.map(it => it.file as AttachedFile)
				.map(it => it.size)
				.reduce((a, b) => a + b, 0);
			this.allowMultipleDownloads = totalSize < 32000000;
		}
	}

	onDownloadFile(fileWrapper: FileWrapper) {
		const file = fileWrapper.file as AttachedFile;

		fileWrapper.status = 'downloading';

		this.fileService.downloadFile(file.id, file.name).subscribe(
			res => {
				fileWrapper.status = 'done';
			},
			err => {
				fileWrapper.status = 'error';
			}
		);
	}

	downloadAllFiles() {
		const uploadedFilesId = this.stagingArea
			.filter(it => it.status === 'done')
			.map(it => it.file as AttachedFile)
			.map(it => it.id);

		this.isDownloadingAll = true;

		this.fileService.downloadMultipleFiles(uploadedFilesId).subscribe(
			res => {
				this.isDownloadingAll = false;
			},
			err => {
				this.isDownloadingAll = false;
			}
		);
	}

	saveFilesForNewEntity(entityId?: string) {
		if (this.stagingArea.filter(it => this.isPendingStatus(it.status)).length === 0) {
			// No files to upload call immediatly.
			this.callFilesUpdatedEventEmitter();
		} else {
			if (!this.uploadPending) {
				if (entityId) {
					this.entityId = entityId;
				}
				this.startUploadPendingFiles();
			}
		}
	}

	refreshFiles() {
		this.hasLoadingError = false;
		this.startLoading();
		this.loadFilesMetadata();
	}

	loadFilesMetadata() {
		this.startLoading();

		this.fileService
			.getFileMetadataByEntity({ entityType: this.entityType, entityId: this.entityId })
			.pipe(take(1))
			.subscribe(
				files => {
					this.files = files;
					this.stagingArea = (cloneDeep(this.files) || []).map(it => ({
						status: 'done',
						file: it
					}));

					this.stopLoading();
					this.filesRetrieved.next(this.stagingArea.map(it => it.file as AttachedFile));
					this.statusChanged.next(this.stagingArea);
					this.calculateDonwloadAllFeasability();
					this.hasLoadingError = false;
				},
				error => {
					this.stopLoading();
					this.hasLoadingError = true;
				}
			);
	}

	startLoading() {
		this.isLoading = true;
		this.loading.emit(true);
	}

	stopLoading() {
		this.isLoading = false;
		this.loading.emit(false);
	}

	setDisplayedColumns() {
		let columns: string[] = [];

		columns.push('name');

		if (this.readonly) {
			columns.push('creationTime');
		} else {
			if (!!!this.checklistFiles) {
				if (this.updateImmediatly) {
					columns.push('creationTime');
				}

				if (this.showIsShared) {
					columns.push('isShared');
				}

				columns.push('actions');
			} else {
				columns.push('linked_to');
				columns.push('delete');
			}
		}

		this.displayedColumns = columns;
	}

	uploadFile(metadata: { [keyId: string]: string[] }) {
		this.metadata = metadata;
		this.elUploadFile.nativeElement.click();
	}

	uploadFilesByDrop(fileList: FileList, metadata?: { [keyId: string]: string[] }) {
		if (!this.readonly) {
			this.metadata = metadata;
			return this.processFileList(fileList);
		}
	}

	fileChangeEvent(ev: Event, metadata?: { [keyId: string]: string[] }) {
		this.metadata = metadata;
		return this.processFileList((ev.target as HTMLInputElement).files);
	}

	fileDraggingStatusUpdated(dragging: boolean) {
		this.showFileDropper = dragging;
	}

	private processFileList(fileList: FileList) {
		this.hasFileSizeError = false;
		this.hasFileResizeError = false;

		const fileArray: File[] = [];

		// tslint:disable-next-line: prefer-for-of
		for (let i = 0; i < fileList.length; i++) {
			fileArray.push(fileList[i]);
		}

		if (fileArray.filter(it => it.size > 15 * 1024 * 1024).length > 0) {
			this.hasFileSizeError = true;
		}

		const newFiles = fileArray
			.filter(it => it.size <= 15 * 1024 * 1024)
			.map(file => new PendingFile(file.name, 0, this.isShared, -1, true, 0, null, file));

		this.elUploadFile.nativeElement.value = '';

		// Preparing the files in the staging area
		this.stagingArea = [
			...this.stagingArea,
			...newFiles.map(it => ({ status: 'to_upload' as FileStatus, file: it }))
		];

		// Publish the arrival of the files
		this.statusChanged.next(this.stagingArea);
		this.calculateDonwloadAllFeasability();

		if (this.updateImmediatly) {
			this.startUploadPendingFiles();
		}
	}

	private startUploadPendingFiles() {
		this.startLoading();
		this.uploadPending = true;
		this.action = 'uploading';

		const uploadFile = this.fileService.syncFileWrappers(
			this.entityType,
			this.entityId,
			this.stagingArea,
			this.metadata,
			this.updateImmediatly
		);

		uploadFile
			.then(newStagingArea => {
				this.stagingArea = cloneDeep(newStagingArea);
				this.statusChanged.next(this.stagingArea);
				this.calculateDonwloadAllFeasability();
				this.callFilesUpdatedEventEmitter();
			})
			.finally(() => {
				this.metadata = null;
				this.uploadPending = false;
				this.stopLoading();
			});
	}

	/* Action of files */

	public onFileVisibilityChange(index: number, value: boolean, file: AttachedFile) {
		this.stagingArea[index].file.isShared = value;
		this.statusChanged.next(this.stagingArea);
		this.calculateDonwloadAllFeasability();

		if (file.id) {
			// When updateImmediatly is be true we need to upload immediatly every file so every change involves just the uploaded files
			this.fileService.updateFile(file).subscribe();
			this.callFilesUpdatedEventEmitter();
		}
	}

	public fileClicked(wrapper: FileWrapper) {
		if (wrapper.status === 'done') {
			const file = wrapper.file as AttachedFile;
			if (this.pressLinkToShare) {
				this.shareFile(file);
			} else {
				this.onDownloadFile(wrapper);
			}
		}
	}

	public async shareFile(file: AttachedFile) {
		const canFeatureWrite = await this.landlordService
			.canFeatureWrite(SectionsList.CHAT, true)
			.pipe(take(1))
			.toPromise();

		if (canFeatureWrite) {
			if (!file.isShared) {
				file.isShared = true;
				this.fileService.updateFile(file).subscribe(() => {
					this.fileShared.emit(file);
				});
			} else {
				this.fileShared.emit(file);
			}
		}
	}

	public removeFile(position: number, file: AttachedFile) {
		this.stagingArea = cloneDeep(
			this.stagingArea.map((it, index) => {
				if (index === position) {
					it.status = 'deleting';
				}

				return it;
			})
		);

		this.statusChanged.next(this.stagingArea);
		this.calculateDonwloadAllFeasability();
		this.elUploadFile.nativeElement.value = '';
		this.action = 'deleting';

		if (!!file.id) {
			this.fileService
				.deleteFile({ entityType: this.entityType, entityId: this.entityId }, file.id)
				.subscribe(() => {
					// Delete successfull: I remove the item from the list
					this.stagingArea = cloneDeep(this.stagingArea.filter(it => it?.file['id'] !== file.id));
					this.callFilesUpdatedEventEmitter();
					if (!!this.filesDeleted) {
						this.filesDeleted.next(position);
					}
				});
		} else {
			this.stagingArea = cloneDeep(this.stagingArea.filter((it, index) => index !== position));
			this.callFilesUpdatedEventEmitter();
			if (!!this.filesDeleted) {
				this.filesDeleted.next(position);
			}
		}
	}

	private callFilesUpdatedEventEmitter() {
		// We emit ready files
		this.filesChangedSubject.next();
	}

	private isPendingStatus(status: FileStatus): boolean {
		return status === 'to_upload' || status === 'error' || status === 'edit';
	}

	// Mobile

	getFileImg(name: string) {
		let fName = './assets/img/rm_icon_doc_jpg.svg';

		if (name.indexOf('.pdf') >= 0) {
			fName = './assets/img/rm_icon_doc_pdf.svg';
		} else if (name.indexOf('.docx') >= 0 || name.indexOf('.doc') >= 0) {
			fName = './assets/img/rm_icon_doc_docx.svg';
		}
		// else jpg jpeg png + FALLBACK

		return fName;
	}
}
