import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { LandlordData } from 'src/app/models/landlord';
import { LocalizationUtils } from 'src/app/utils/localization-utils';
import { environment } from '../../../environments/environment';
import { LandlordService } from './landlord/landlord.service';

@Injectable({
	providedIn: 'root'
})
export class PreferencesService {
	private cedolareSecca = false;
	private maintenanceMigrationDone = false;

	private cedolareSeccaEnabledSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

	constructor(private readonly landlordService: LandlordService) {
		this.landlordService.getLandlordData().subscribe(it => {
			const fetchedLocale = (
				(it as LandlordData)?.invoiceData?.countryCode ||
				(it as LandlordData).profileInfo?.country ||
				LocalizationUtils.getLanguage()
			).toLowerCase();

			this.cedolareSecca = fetchedLocale === 'it';
			this.cedolareSeccaEnabledSubject.next(this.cedolareSecca);
		});
	}

	getInvoicesEnabledObservable(): Observable<boolean> {
		return of(true);
	}

	isInvoiceEnabled(): boolean {
		return true;
	}

	isEsignEnabled(): boolean {
		return true;
	}

	isMaintenanceMigrationDone(): boolean {
		return this.maintenanceMigrationDone;
	}

	isProduction(): boolean {
		return !!environment.production;
	}

	isCedolareSeccaEnabled(): boolean {
		return this.cedolareSecca;
	}

	getCedolareSeccaEnabledObservable(): Observable<boolean> {
		return this.cedolareSeccaEnabledSubject;
	}
}
