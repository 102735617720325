import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDialogData } from './dialogData';

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<ConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public dialogData: IDialogData
	) {}

	ngOnInit() {}

	cancelClick() {
		this.dialogRef.close({ action: 'none' });
	}

	proceedClick() {
		this.dialogRef.close({ action: 'do_action' });
	}
}
