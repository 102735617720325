import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/store/reducer';

const selectChannelManagerListingsState = createSelector(
	state => state,
	(state: AppState) => state.channelManagerListings
);

export const selectChannelManagerListingsEntities = createSelector(selectChannelManagerListingsState, state => {
	if (state.isLoaded) {
		return state.entities;
	} else {
		return null;
	}
});
