import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { startOfDay } from 'date-fns';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LandlordService } from '../core/services/landlord/landlord.service';
import { MixpanelService } from '../core/services/mixpanel-service.service';
import { QueryResult } from '../models/common';
import { PaymentOperation, PropertyPayment } from '../models/payments';
import { SettingsWalletData } from '../models/settingsWallet.model';
import {
	createPaymentOperationAction,
	createPaymentOperationFailureAction,
	createPaymentOperationSuccessAction,
	deletePaymentOperationAction
} from './state/payments.actions';

@Injectable({
	providedIn: 'root'
})
export class PaymentsOperationsService {
	private landlordId: string;
	private markAsPaidLog: { [paymentId: string]: { amount: number } } = {};

	private BACKEND_HOST = `${environment.services.backend}/api-dash/v1`;
	private PAYMENT_BACKEND_HOST = `${environment.services.backend}/payments/v1`;

	constructor(
		private readonly analyticsService: MixpanelService,
		private readonly store: Store,
		private readonly landlordService: LandlordService,
		private readonly httpClient: HttpClient,
		private readonly actions$: Actions
	) {
		this.landlordService.getLandlordId().subscribe(landlordId => (this.landlordId = landlordId));
	}

	public getPaymentOperations(paymentId: string): Observable<PaymentOperation[]> {
		return this.httpClient.get<PaymentOperation[]>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/paymentOperations/${paymentId}`
		);
	}

	public markAsPaid(
		payment: PropertyPayment,
		paidAmount: number,
		paidTime: number = Date.now(),
		paymentMethod: string = 'manual',
		ficPaymentAccount?: string,
		isMaintenanceClosingPayment?: boolean,
		note?: string
	): Observable<{ paymentOperation: PaymentOperation; payment: PropertyPayment }> {
		if (this.markAsPaidLog[payment.id]) {
			if (this.markAsPaidLog[payment.id].amount + paidAmount > payment.amount) {
				console.error('Detected double mark as paid. Avoiding it');
				throw Error('Double mark as paid');
			}
		} else {
			this.markAsPaidLog[payment.id] = { amount: paidAmount };
		}

		let usedPaymentOperationAmount = paidAmount;

		if (payment.amount < 0) {
			// Handling deposit out case: I want a payment operation with negative amount
			usedPaymentOperationAmount = -usedPaymentOperationAmount;
		}

		this.createPaymentOperation(
			new PaymentOperation(
				'',
				payment.id,
				usedPaymentOperationAmount,
				payment.currency,
				Date.now(),
				startOfDay(paidTime).getTime(), // In this case is paid time
				paymentMethod,
				'',
				'succeeded',
				this.landlordId,
				payment.landlordId,
				0,
				undefined,
				{ ficPaymentAccount },
				note
			),
			isMaintenanceClosingPayment
		);

		// Update the UI for new payments here

		const daysBeforeDueDate = (new Date().getTime() - payment.dueDate) / (1000 * 3600 * 24);

		// Track event
		this.analyticsService.track('expense_paid', {
			amount: paidAmount,
			payment_method: paymentMethod,
			days_from_expiry: daysBeforeDueDate,
			currency: payment.currency
		});

		return this.actions$.pipe(ofType(createPaymentOperationSuccessAction), take(1));
	}

	public markAsPaidMultiple(
		packedData: {
			payment: PropertyPayment;
			paidAmount: number;
			paidTime: number;
			paymentMethod: string;
			ficPaymentAccount: string;
			note?: string;
		}[]
	): Observable<{ paymentOperation: PaymentOperation; payment: PropertyPayment } | void> {
		packedData.forEach(pd => {
			if (this.markAsPaidLog[pd.payment.id]) {
				if (this.markAsPaidLog[pd.payment.id].amount + pd.paidAmount > pd.payment.amount) {
					console.error('Detected double mark as paid. Avoiding it');
					throw Error('Double mark as paid');
				}
			} else {
				this.markAsPaidLog[pd.payment.id] = { amount: pd.paidAmount };
			}

			let usedPaymentOperationAmount = pd.paidAmount;

			if (pd.payment.amount < 0) {
				// Handling deposit out case: I want a payment operation with negative amount
				usedPaymentOperationAmount = -usedPaymentOperationAmount;
			}

			this.createPaymentOperation(
				new PaymentOperation(
					'',
					pd.payment.id,
					usedPaymentOperationAmount,
					pd.payment.currency,
					Date.now(),
					startOfDay(pd.paidTime).getTime(), // In this case is paid time
					pd.paymentMethod,
					'',
					'succeeded',
					this.landlordId,
					pd.payment.landlordId,
					0,
					undefined,
					{
						ficPaymentAccount: pd.ficPaymentAccount
					},
					pd.note
				)
			);

			// Update the UI for new payments here

			const daysBeforeDueDate = (new Date().getTime() - pd.payment.dueDate) / (1000 * 3600 * 24);

			// Track event
			this.analyticsService.track('expense_paid', {
				amount: pd.paidAmount,
				payment_method: pd.paymentMethod,
				days_from_expiry: daysBeforeDueDate,
				currency: pd.payment.currency
			});
		});

		return this.actions$.pipe(
			ofType(createPaymentOperationSuccessAction, createPaymentOperationFailureAction),
			take(packedData.length),
			map(action => {
				if (action.type === createPaymentOperationSuccessAction.type) {
					return { paymentOperation: action.paymentOperation, payment: action.payment };
				} else {
					return;
				}
			})
		);
	}

	public deletePaymentOperation(paymentOperation: PaymentOperation): void {
		this.markAsPaidLog[paymentOperation.linkedPaymentId] = undefined; // We can do other mark as paid from here
		this.store.dispatch(deletePaymentOperationAction({ paymentOperation }));
	}

	public createPaymentOperation(paymentOperation: PaymentOperation, isMaintenanceClosingPayment?: boolean): void {
		return this.store.dispatch(createPaymentOperationAction({ paymentOperation, isMaintenanceClosingPayment }));
	}

	public getPaymentsOperation(page = 1, itemsPerPage?: number): Observable<QueryResult<PaymentOperation>> {
		return this.httpClient.get<QueryResult<PaymentOperation>>(
			`${this.PAYMENT_BACKEND_HOST}/landlords/${this.landlordId}/incomes?perPage=${
				itemsPerPage || 10
			}&page=${page}`
		);
	}

	public getIncomesTransactions(page = 1, itemsPerPage?: number): Observable<QueryResult<SettingsWalletData>> {
		return this.getPaymentsOperation(page, itemsPerPage).pipe(
			map(result => {
				const totalItems = result.metadata.totalItems;

				let settingWalletTransactions: SettingsWalletData[] = [];

				result.data.forEach(paymentOperation => {
					const feesOnTenant = paymentOperation.fees?.feesType === 'tenant';

					settingWalletTransactions.push({
						linkedPaymentId: paymentOperation.linkedPaymentId,
						amount: paymentOperation.amount,
						currency: paymentOperation.currency,
						createdDate: paymentOperation.createdDate,
						completionTime: paymentOperation.completionTime,
						status: paymentOperation.status,
						landlordId: paymentOperation.landlordId,
						failureReason: paymentOperation.failureReason,
						type: 'PAYIN',
						fee: feesOnTenant ? 0 : paymentOperation.fees?.feesAmount || paymentOperation.fee || 0
					});
				});

				console.log(settingWalletTransactions);

				return {
					data: settingWalletTransactions,
					metadata: {
						totalItems
					}
				};
			})
		);
	}
}
