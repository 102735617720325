import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { MinimumUserData } from 'src/app/models/common';
import { LocalizationUtils } from 'src/app/utils/localization-utils';
import { environment } from 'src/environments/environment';
import { LandlordService } from '../../landlord/landlord.service';
import {
	updateOnboardingDataAction,
	updateOnboardingDataFailureAction,
	updateOnboardingDataSuccessAction
} from './user.actions';

@Injectable()
export class UserEffects {
	private landlordId: string;
	private userId: string;

	private translations = LocalizationUtils.getTranslations();
	private readonly BACKEND_HOST = `${environment.services.backend}/api-dash/v1`;

	constructor(
		private readonly landlordService: LandlordService,
		private readonly actions$: Actions,
		private readonly httpClient: HttpClient
	) {
		this.landlordService.getLandlordId().subscribe(id => {
			this.landlordId = id;
		});
		this.landlordService.getLandlordUserData().subscribe(userData => {
			if (userData && userData.id) {
				this.userId = userData.id;
			} else {
				this.userId = '';
			}
		});
	}

	public updateOnboardingDataEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(updateOnboardingDataAction),
			switchMap(action => {
				return this.updateOnboardingData(action.userId, action.componentName).pipe(
					map(userData => {
						return updateOnboardingDataSuccessAction({ user: userData });
					}),
					catchError(err => of(updateOnboardingDataFailureAction({})))
				);
			})
		)
	);

	private updateOnboardingData(userId: string, componentName: string): Observable<MinimumUserData> {
		return this.httpClient.post<MinimumUserData>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/users/${userId}/updateOnboardingEstelle/${componentName}`,
			{}
		);
	}
}
