import { createAction, props } from '@ngrx/store';
import { FilterContainer, SortEvent } from 'src/app/core/services/filters/filters.model';
import { QueryResult } from 'src/app/models/common';
import { Tenant } from 'src/app/models/tenant.model';
import { CallbackFunctions } from 'src/app/store/reducer';
import { DeleteTenantResult } from './tenant.effects';

export enum TenantActions {
	// Effect Actions
	LOAD_TENANT_BY_ID = '[Tenant] Get One Tenant By ID',
	ADD_TENANT = '[Tenant] Add Tenant',
	EDIT_TENANT = '[Tenant] Edit Tenant',
	DELETE_TENANT = '[Tenant] Delete Tenant',
	DELETE_TENANT_BY_ID = '[Tenant] Delete Tenant by id',
	DELETE_TENANTS = '[Tenant] Delete Teants',
	LOAD_TENANTS = '[Tenant] Load Tenants',
	ARCHIVE_TENANT = '[Tenant] Archive Tenant',
	RECOVER_TENANT = '[Tenant] Recover Tenant',
	UPLOAD_MULTIPLE_TENANTS = '[Tenant] Upload Multiple Tenants',
	GET_TENANT_CSV_STRING = '[Tenant] Get Tenant CSV String',
	GET_TENANTS_CSV = '[Tenant] Get Tenants CSV',
	RESET_TENANTS_STATE = '[Tenant] Reset Tenants State',
	SEND_PLACES_INVITATION = '[Tenant] Send Places invitation',
	GET_TENANT_PLACES_STATUS = '[Tenant] Get Places Status',
	ADD_MOCK_TENANT = '[Tenant] Add Mock Tenant',
	// Version 2
	LOAD_TENANTS_PAGINATED = '[Tenant] Load Tenants Paginated',
	LOAD_ARCHIVED_TENANTS = '[Tenant] Load Archived Tenants',

	// Reducer Success Actions
	LOAD_TENANT_SUCCESS = '[Tenant] Load Tenant Success',
	ADD_TENANT_SUCCESS = '[Tenant] Add Tenant Success',
	EDIT_TENANT_SUCCESS = '[Tenant] Edit Tenant Success',
	DELETE_TENANT_SUCCESS = '[Tenant] Delete Tenant Success',
	DELETE_TENANTS_SUCCESS = '[Tenant] Delete Tenants Success',
	LOAD_TENANTS_SUCCESS = '[Tenant] Load Tenants Success',
	ARCHIVE_TENANT_SUCCESS = '[Tenant] Archive Tenant Success',
	RECOVER_TENANT_SUCCESS = '[Tenant] Recover Tenant Success',
	UPLOAD_MULTIPLE_TENANTS_SUCCESS = '[Tenant] Upload Multiple Tenants Success',
	GET_TENANT_CSV_STRING_SUCCESS = '[Tenant] Get Tenant CSV String Success',
	GET_TENANTS_CSV_SUCCESS = '[Tenant] Get Tenants CSV Success',
	RESET_TENANTS_STATE_SUCCESS = '[Tenant] Reset Tenants State Success',
	SEND_PLACES_INVITATION_SUCCESS = '[Tenant] Send Places invitation Success',
	SEND_PLACES_INVITATION_SUCCESS_MULTIPLE = '[Tenant] Send Places invitation multiple Success',
	GET_TENANT_PLACES_STATUS_SUCCESS = '[Tenant] Get Places Status Success',
	// Version 2
	LOAD_TENANTS_PAGINATED_SUCCESS = '[Tenant] Load Tenants Paginated Success',
	LOAD_ARCHIVED_TENANTS_SUCCESS = '[Tenant] Load Archived Tenants Success',

	// Reducer Failure actions
	LOAD_TENANT_FAILURE = '[Tenant] Load Tenant Failure',
	ADD_TENANT_FAILURE = '[Tenant] Add Tenant Failure',
	EDIT_TENANT_FAILURE = '[Tenant] Edit Tenant Failure',
	DELETE_TENANT_FAILURE = '[Tenant] Delete Tenant Failure',
	DELETE_TENANTs_FAILURE = '[Tenant] Delete Tenants Failure',
	LOAD_TENANTS_FAILURE = '[Tenant] Load Tenants Failure',
	ARCHIVE_TENANT_FAILURE = '[Tenant] Archive Tenant Failure',
	RECOVER_TENANT_FAILURE = '[Tenant] Recover Tenant Failure',
	UPLOAD_MULTIPLE_TENANTS_FAILURE = '[Tenant] Upload Multiple Tenants Failure',
	GET_TENANT_CSV_STRING_FAILURE = '[Tenant] Get Tenant CSV String Failure',
	GET_TENANTS_CSV_FAILURE = '[Tenant] Get Tenants CSV Failure',
	RESET_TENANTS_STATE_FAILURE = '[Tenant] Reset Tenants State Failure',
	SEND_PLACES_INVITATION_FAILURE = '[Tenant] Send Places invitation Failure',
	GET_TENANT_PLACES_STATUS_FAILURE = '[Tenant] Get Places Status Failure',
	// Version 2
	LOAD_TENANTS_PAGINATED_FAILURE = '[Tenant] Load Tenants Paginated Failure',
	LOAD_ARCHIVED_TENANTS_FAILURE = '[Tenant] Load Archived Tenants Failure'
}

// Effect Actions
export const loadTenantByIdAction = createAction(TenantActions.LOAD_TENANT_BY_ID, props<{ tenantId: string; bypassPermissionsCheck?: boolean; options?: any }>());
export const addTenantAction = createAction(TenantActions.ADD_TENANT, props<{ tenant: Tenant; options: { sendInvite: boolean }; callbacks?: CallbackFunctions }>());
export const editTenantAction = createAction(TenantActions.EDIT_TENANT, props<{ tenant: Tenant; callbacks?: CallbackFunctions; bypassPermissionsCheck?: boolean }>());
export const deleteTenantByIdAction = createAction(TenantActions.DELETE_TENANT, props<{ tenantId: string; isBulk: boolean; callbacks?: CallbackFunctions }>());
export const loadTenantsAction = createAction(TenantActions.LOAD_TENANTS, props<{ refreshAll?: boolean }>());
export const archiveTenantAction = createAction(TenantActions.ARCHIVE_TENANT, props<{ tenant: Tenant; callbacks?: CallbackFunctions }>());
export const recoverTenantAction = createAction(TenantActions.RECOVER_TENANT, props<{ tenant: Tenant; callbacks?: CallbackFunctions }>());
export const uploadMultipleTenantsAction = createAction(TenantActions.UPLOAD_MULTIPLE_TENANTS, props<{ tenants: Tenant[]; hostedPageId?: string; callbacks?: CallbackFunctions }>());
export const getTenantCsvStringAction = createAction(TenantActions.GET_TENANT_CSV_STRING);
export const getTenantsCsvAction = createAction(TenantActions.GET_TENANTS_CSV);
export const sendPlacesInvitationAction = createAction(TenantActions.SEND_PLACES_INVITATION, props<{ tenantsId: string[]; invites: { email: boolean } }>());
export const getTenantPlacesStatusAction = createAction(TenantActions.GET_TENANT_PLACES_STATUS, props<{ tenantId: string }>());
export const deleteTenantsAction = createAction(TenantActions.DELETE_TENANTS, props<{ tenantIds: string[]; callbacks?: CallbackFunctions }>());
export const addMockTenantAction = createAction(TenantActions.ADD_MOCK_TENANT, props<{ callbacks?: CallbackFunctions }>());
// Version 2
export const loadTenantsPaginatedAction = createAction(TenantActions.LOAD_TENANTS_PAGINATED, props<{ page: number; pageSize: number; filters: FilterContainer; sort: SortEvent }>());
export const loadArchivedTenantsAction = createAction(TenantActions.LOAD_ARCHIVED_TENANTS);

// Reducer Success Actions
export const loadTenantSuccessAction = createAction(TenantActions.LOAD_TENANT_SUCCESS, props<{ tenant: Tenant }>());
export const addTenantSuccessAction = createAction(TenantActions.ADD_TENANT_SUCCESS, props<{ tenant: Tenant }>());
export const editTenantSuccessAction = createAction(TenantActions.EDIT_TENANT_SUCCESS, props<{ tenant: Tenant }>());
export const deleteTenantSuccessAction = createAction(TenantActions.DELETE_TENANT_SUCCESS, props<DeleteTenantResult>());
export const deleteTenantsSuccessAction = createAction(TenantActions.DELETE_TENANTS_SUCCESS, props<{ deleteTenantResults: DeleteTenantResult[] }>());
export const loadTenantsSuccessAction = createAction(TenantActions.LOAD_TENANTS_SUCCESS, props<{ tenants: Tenant[]; refreshAll?: boolean }>());
export const archiveTenantSuccessAction = createAction(TenantActions.ARCHIVE_TENANT_SUCCESS, props<{ tenant: Tenant }>());
export const recoverTenantSuccessAction = createAction(TenantActions.RECOVER_TENANT_SUCCESS, props<{ tenant: Tenant }>());
export const uploadMultipleTenantsSuccessAction = createAction(TenantActions.UPLOAD_MULTIPLE_TENANTS_SUCCESS, props<{ tenants: Tenant[] }>());
export const getTenantCsvStringSuccessAction = createAction(TenantActions.GET_TENANT_CSV_STRING_SUCCESS, props<{ url: string }>());
export const getTenantsCsvSuccessAction = createAction(TenantActions.GET_TENANTS_CSV_SUCCESS, props<{ url: string }>());
export const sendPlacesInvitationSuccessAction = createAction(TenantActions.SEND_PLACES_INVITATION_SUCCESS);
export const sendPlacesInvitationMultipleSuccessAction = createAction(TenantActions.SEND_PLACES_INVITATION_SUCCESS_MULTIPLE, props<{ invitesSent: number }>());
export const getTenantPlacesStatusSuccessAction = createAction(
	TenantActions.GET_TENANT_PLACES_STATUS_SUCCESS,
	props<{ appSetup: boolean; paymentsSetup: boolean; inviteSentOn: number; inviteStatus: 'not-invited' | 'sent' | 'delivered' | 'opened' | 'clicked' }>()
);
// Version 2
export const loadTenantsPaginatedSuccessAction = createAction(TenantActions.LOAD_TENANTS_PAGINATED_SUCCESS, props<{ page: number; pageSize: number; tenantsQueryResult: QueryResult<Tenant> }>());
export const loadArchivedTenantsSuccessAction = createAction(TenantActions.LOAD_ARCHIVED_TENANTS_SUCCESS, props<{ tenantsQueryResult: QueryResult<Tenant> }>());

// Reducer Failure Actions
export const loadTenantFailureAction = createAction(TenantActions.LOAD_TENANT_FAILURE, props<{ error?: any }>());
export const addTenantFailureAction = createAction(TenantActions.ADD_TENANT_FAILURE, props<{ errorName: string; error?: any }>());
export const editTenantFailureAction = createAction(TenantActions.EDIT_TENANT_FAILURE, props<{ errorName?: string; error?: any }>());
export const deleteTenantFailureAction = createAction(TenantActions.DELETE_TENANT_FAILURE, props<{ isBulk: boolean; error?: any }>());
export const deleteTenantsFailureAction = createAction(TenantActions.DELETE_TENANTs_FAILURE, props<{ error?: any }>());
export const loadTenantsFailureAction = createAction(TenantActions.LOAD_TENANTS_FAILURE, props<{ error?: any }>());
export const archiveTenantFailureAction = createAction(TenantActions.ARCHIVE_TENANT_FAILURE, props<{ error?: any }>());
export const recoverTenantFailureAction = createAction(TenantActions.RECOVER_TENANT_FAILURE, props<{ error?: any }>());
export const uploadMultipleTenantsFailureAction = createAction(TenantActions.UPLOAD_MULTIPLE_TENANTS_FAILURE, props<{ error?: any }>());
export const getTenantCsvStringFailureAction = createAction(TenantActions.GET_TENANT_CSV_STRING_FAILURE, props<{ error?: any }>());
export const getTenantsCsvFailureAction = createAction(TenantActions.GET_TENANTS_CSV_FAILURE, props<{ error?: any }>());
export const resetTenantStateAction = createAction(TenantActions.RESET_TENANTS_STATE);
export const resetTenantStateSuccessAction = createAction(TenantActions.RESET_TENANTS_STATE_SUCCESS);
export const resetTenantStateFailureAction = createAction(TenantActions.RESET_TENANTS_STATE_FAILURE, props<{ error?: any }>());
export const sendPlacesInvitationFailureAction = createAction(TenantActions.SEND_PLACES_INVITATION_FAILURE, props<{ error?: any }>());
export const getTenantPlacesStatusFailureAction = createAction(TenantActions.GET_TENANT_PLACES_STATUS_FAILURE, props<{ error?: any }>());
// Version 2
export const loadTenantsPaginatedFailureAction = createAction(TenantActions.LOAD_TENANTS_PAGINATED_FAILURE, props<{ error?: any }>());
export const loadArchivedTenantsFailureAction = createAction(TenantActions.LOAD_ARCHIVED_TENANTS_FAILURE, props<{ error?: any }>());
