<div
	class="rf-file-picker"
	appDragDrop
	(onFileDragging)="fileDraggingStatusUpdated($event)"
	(onFileDropped)="uploadFilesByDrop($event, metadata)"
>
	<input
		type="file"
		[accept]="customAcceptedFiles || acceptedFiles"
		#file
		(change)="fileChangeEvent($event, metadata)"
		multiple
	/>

	<div class="rf-upload-section" *ngIf="!readonly && showMessage && uploadEnabled">
		<button
			*ngIf="entityType"
			[disabled]="stagingArea.length >= maxFiles || isLoading"
			mat-button
			class="rf-upload-button"
			(click)="stagingArea.length < maxFiles ? uploadFile() : undefined"
			i18n="@@com_add_document"
		>
			Add document
		</button>
		<ng-container *ngIf="!customAcceptedFiles">
			<p i18n="@@upload_types_allowed">Type of file: PDF, .doc, PNG, JPG</p>
			<p i18n="@@upload_max_size_allowed">Max file size: 15 MB</p>
		</ng-container>
	</div>

	<div *ngIf="showFileDropper" class="rf-upload-file-container">
		<img src="./assets/img/drag_and_drop.svg" />
	</div>

	<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

	<div (click)="refreshFiles()" class="rf-important-alert-with-side-bar" *ngIf="hasLoadingError">
		<div class="rf-side-bar"></div>
		<div class="rf-text">
			<div>
				<img src="./assets/img/exclamation_red.svg" />
				<p i18n="@@file_picker_loading_error_title">Error</p>
			</div>
			<p i18n="@@file_picker_loading_error_message">
				Something went wrong while loading the documents.
				<span>Click here to try again</span>. If the error persists, please <span>contact the support</span>.
				Don't worry, your documents are safe.
			</p>
		</div>
	</div>

	<mat-card *ngIf="!mobile && stagingArea.length > 0">
		<table mat-table [dataSource]="stagingArea">
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef i18n="@@com_document_name">Document name</th>
				<td mat-cell *matCellDef="let f">
					<mat-spinner
						*ngIf="f.status === 'downloading' || f.status === 'uploading' || f.status === 'deleting'"
						diameter="22"
					>
					</mat-spinner>
					<span
						(click)="fileClicked(f)"
						[ngClass]="{ 'rf-light-name': done, 'rf-is-clickable-underline': !done }"
					>
						{{ f.file.name | truncate: [truncateLenght, '…'] }}
					</span>
				</td>
			</ng-container>

			<ng-container matColumnDef="creationTime">
				<th mat-header-cell *matHeaderCellDef i18n="@@com_uploaded_on">Uploaded on</th>
				<td mat-cell *matCellDef="let f">
					<span>{{ f.file.creationTime ? (f.file.creationTime | dfnsFormat: 'dd MMM yyyy') : '' }}</span>
				</td>
			</ng-container>

			<ng-container matColumnDef="isShared">
				<th mat-header-cell *matHeaderCellDef [matTooltip]="tooltipPublicTitle" i18n="@@com_visibility">
					Visibility
				</th>
				<td mat-cell *matCellDef="let f; let index = index">
					<mat-checkbox
						[(checked)]="stagingArea[index].file.isShared"
						(change)="onFileVisibilityChange(index, $event.checked, stagingArea[index].file)"
						[matTooltip]="tooltipPublicDesc"
						i18n="@@com_public"
						>Public</mat-checkbox
					>
				</td>
			</ng-container>

			<ng-container matColumnDef="actions">
				<th mat-header-cell *matHeaderCellDef i18n="@@com_actions">Actions</th>
				<td mat-cell *matCellDef="let f; let index = index">
					<div class="rf-file-actions">
						<mat-icon
							*ngIf="
								(showChatButton && f.file.isShared) ||
								(pressLinkToShare && f.file.publicUrl && f.status === 'done')
							"
							(click)="shareFile(f.file)"
							[matTooltip]="tooltipSendCta"
						>
							<img src="./assets/img/ic_write.png" />
						</mat-icon>
						<mat-icon
							*ngIf="f.status !== 'uploading' && f.status !== 'deleting'"
							(click)="removeFile(index, f.file)"
							i18n-matTooltip="@@com_delete"
							matTooltip="Delete"
						>
							<img src="./assets/img/ic_delete.png" />
						</mat-icon>
					</div>
				</td>
			</ng-container>

			<ng-container matColumnDef="linked_to">
				<th mat-header-cell *matHeaderCellDef i18n="@@com_linked_to">Linked to</th>
				<td mat-cell *matCellDef="let f; let index = index">
					{{ f.file.metadata ? f.file.metadata['checklistName'] : '' }}
				</td>
			</ng-container>

			<ng-container matColumnDef="delete">
				<th mat-header-cell *matHeaderCellDef i18n="@@com_delete">Delete</th>
				<td mat-cell *matCellDef="let f; let index = index">
					<div class="rf-file-actions">
						<mat-icon
							*ngIf="f.status !== 'uploading' && f.status !== 'deleting'"
							(click)="removeFile(index, f.file)"
							i18n-matTooltip="@@com_delete"
							matTooltip="Delete"
						>
							<img src="./assets/img/ic_delete.png" />
						</mat-icon>
					</div>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr
				mat-row
				[ngClass]="{ 'rf-white-background': whiteBackground }"
				*matRowDef="let row; columns: displayedColumns"
			></tr>
		</table>
	</mat-card>

	<div class="rf-download-files">
		<button
			*ngIf="allowMultipleDownloads"
			mat-button
			class="rf-upload-button"
			[disabled]="isDownloadingAll"
			(click)="downloadAllFiles()"
			i18n="@@com_download_all"
		>
			Download all
		</button>

		<mat-spinner *ngIf="isDownloadingAll" diameter="22"> </mat-spinner>
	</div>

	<ng-container *ngIf="mobile && stagingArea.length > 0">
		<div *ngFor="let f of stagingArea" class="rf-file-container" (click)="shareFile(f.file)">
			<img [src]="getFileImg(f.file.name)" />
			<p>{{ f.file.name }}</p>
		</div>
	</ng-container>

	<div *ngIf="hasFileSizeError" class="rf-error-message" i18n="@@upload_file_err_size">
		File not valid: maximum size is 15 MB
	</div>
	<div *ngIf="hasFileResizeError" class="rf-error-message" i18n="@@upload_file_err_size">
		File not valid: error during loading. Try with another file
	</div>
</div>
