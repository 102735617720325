import { HttpResponse } from '@angular/common/http';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { Action, TypedAction } from '@ngrx/store/src/models';
import { getMonth, getYear, startOfDay, subDays, subMonths } from 'date-fns';
import { saveAs } from 'file-saver';
import { PhoneNumber } from 'libphonenumber-js';
import { Observable, UnaryFunction, defer, pipe, throwError } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { DateCustomFilterType, SortEvent, SortQuery } from 'src/app/core/services/filters/filters.model';
import { EntityLoadable } from '../bookings/state/bookings.reducers';
import { UnitChannelConnectionStatusTypes } from '../models/channelManager.model';
import { InvoiceData } from '../models/common';
import { PaymentSetup, TaxInfo } from '../models/payments';
import { CallbackFunctions } from '../store/reducer';

export class AppUtils {
	static fetchIfMissing<T>(
		store: Store,
		loadItemAction: Action
	): UnaryFunction<Observable<EntityLoadable<T>>, Observable<Dictionary<T>>> {
		return pipe(
			AppUtils.tapOne(x => {
				if (!x.isLoading && !x.isLoaded && !x.error) {
					store.dispatch(loadItemAction);
				}
			}),
			filter(it => !it.isLoading && !it.error && it.isLoaded), // Bugfix: force also the check on isLoaded=true
			map(it => it.entities)
		);
	}

	/**
	 * This util helps fetching an item from the backend if it's not found in its internal cache.
	 * @param store Store from class constructor
	 * @param id id of the entity to retrieve
	 * @param loadItemAction action to send to the store
	 * @returns
	 */
	static applyRefreshLogicForItemNotFound<T>(
		store: Store,
		id: string,
		loadItemAction: Action
	): UnaryFunction<Observable<Dictionary<T>>, Observable<T>> {
		let loadedOnce = false;

		return pipe(
			map(dictionary => {
				if (dictionary && dictionary[id]) {
					return dictionary[id];
				}

				// A maintenance delete will not trigger this
				if (!loadedOnce) {
					loadedOnce = true;
					store.dispatch(loadItemAction);
				} else {
					console.error(`Item not found: ${id}`, loadItemAction);
					throw throwError(`Item not found: ${id}`);
				}

				return null;
			}),
			filter(item => !!item)
		);
	}

	static buildActionWithPromise<T>(
		store: Store,
		action: {
			callbacks?: CallbackFunctions;
		} & TypedAction<any>
	): Promise<T> {
		return new Promise<T>((resolve, reject) => {
			const callbacks = {
				success: data => {
					resolve(data);
				},
				fail: () => {
					reject();
				}
			};

			action.callbacks = callbacks;

			store.dispatch(action);
		});
	}

	static getNameSplitted(name: string = '', maxLength: number = 0) {
		let allFields = (maxLength > 0 ? name.substring(0, maxLength) : name).split(' ');
		const twoFields: string[] = [];

		if (allFields.length > 0) {
			twoFields.push(allFields[0]);

			if (allFields.length > 1) {
				allFields = allFields.splice(1);

				twoFields.push(allFields.join(' '));
			} else {
				twoFields.push('');
			}
		} else {
			twoFields.push('');
			twoFields.push('');
		}

		return twoFields;
	}

	static getPhoneStringForInputForm(phone: PhoneNumber): string {
		const phoneString = phone.formatInternational();
		return phoneString
			.split(' ')
			.filter((_, index) => index > 0)
			.join('');
	}

	static amountKeyPressValidate(event: any) {
		const pattern = /[0-9]/;

		const inputChar = String.fromCharCode(event.charCode);

		if (event.keyCode !== 8 && !pattern.test(inputChar)) {
			event.preventDefault();
			const totPatter = /^\d+(\.)?(\d+)?$/;
			let toSetString = event.target.value + inputChar;
			if (inputChar === ',') {
				toSetString = event.target.value + '.';
			}
			if (totPatter.test(toSetString)) {
				event.target.value = toSetString;
			}
		}
	}

	static onPasteAmountValidate(formControl: UntypedFormControl, event: ClipboardEvent) {
		const clipBoardDataProp = 'clipboardData';
		const clipboardData = event.clipboardData || window[clipBoardDataProp];
		const pastedText: string = clipboardData.getData('text');
		const clearText = pastedText.substr(0, 10).replace(',', '.');
		formControl.setValue(clearText);
		event.preventDefault();
	}

	static maximumAmountValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			const isValid = Math.round(control.value * 1) < 999999;
			return isValid ? null : { amount: 'maxAmount' };
		};
	}

	static maximumAmountSetValidator(maxValue: number): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			const isValid = Math.round(control.value * 1) < maxValue;
			return isValid ? null : { amount: 'maxAmount' };
		};
	}

	static minimumAmountValidator(minValue: number): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			const finalValue = control.value.replace ? (control.value || '').replace(',', '.') : control.value;
			const isValid = parseFloat(finalValue) >= minValue;
			return isValid ? null : { amount: 'minAmount' };
		};
	}

	static compareTaxInfoFn(optionOne: TaxInfo, optionTwo: TaxInfo): boolean {
		return optionOne.vatCode === optionTwo.vatCode;
	}

	static clearEmptyKeys(obj: any): any {
		const result = {};

		Object.keys(obj).forEach(key => {
			if (obj[key]) {
				result[key] = obj[key];
			}
		});

		return result;
	}

	static tapOne = <T>(callback: (T) => void) => this.tapN<T>(1, callback);

	static tapN =
		<T>(nEmissions, callback: (T) => void) =>
		(source$: Observable<T>): Observable<T> =>
			defer(() => {
				let counter = 0;
				return source$.pipe(
					tap(item => {
						if (counter < nEmissions) {
							callback(item);
							counter++;
						}
					})
				);
			});

	static integer(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const error: ValidationErrors = { integer: true };

			if (control.value && control.value !== `${parseInt(control.value, 10)}`) {
				control.setErrors(error);
				return error;
			}

			control.setErrors(null);
			return null;
		};
	}

	static deepCopy(obj, onlyTopFields?: string[]) {
		let copy;

		// Handle the 3 simple types, and null or undefined
		if (null === obj || 'object' !== typeof obj) return obj;

		// Handle Date
		if (obj instanceof Date) {
			copy = new Date();
			copy.setTime(obj.getTime());
			return copy;
		}

		// Handle Array
		if (obj instanceof Array) {
			copy = [];
			for (let i = 0, len = obj.length; i < len; i++) {
				copy[i] = this.deepCopy(obj[i]);
			}
			return copy;
		}

		// Handle Object
		if (obj instanceof Object) {
			copy = {};
			for (const attr in obj) {
				if (obj.hasOwnProperty(attr) && (!onlyTopFields || onlyTopFields.includes(attr)))
					copy[attr] = this.deepCopy(obj[attr]);
			}
			return copy;
		}

		throw new Error("Unable to copy obj! Its type isn't supported.");
	}

	static partition<T>(ary: T[], callback: (T) => boolean): [T[], T[]] {
		return ary.reduce(
			(acc, e) => {
				acc[callback(e) ? 0 : 1].push(e);
				return acc;
			},
			[[], []]
		);
	}

	/**
	 * Saves a file by opening file-save-as dialog in the browser
	 * using file-save library.
	 * @param blobContent file content as a Blob
	 * @param fileName name file should be saved as
	 */
	static saveFile = (blobContent: Blob, fileName: string) => {
		const blob = new Blob([blobContent], { type: 'application/octet-stream' });
		saveAs(blob, fileName);
	};

	/**
	 * Derives file name from the http response
	 * by looking inside content-disposition
	 * @param res http Response
	 */
	static getFileNameFromResponseContentDisposition = (res: HttpResponse<Blob>, fileNameIfMissing?: string) => {
		const contentDisposition =
			res.headers.get('content-disposition') || res.headers.get('Content-Disposition') || '';
		if (!contentDisposition) {
			console.log(`File name headers not found: using default name: ${fileNameIfMissing}`);
			console.log(JSON.stringify(res.headers));
		} else {
			console.log(contentDisposition);
		}
		const matches = /filename=([^;]+)/gi.exec(contentDisposition) || [];
		const fileName = (matches[1] || fileNameIfMissing || 'untitled').trim();
		return fileName.replace(/\"/gi, '');
	};

	static getDateFromFilterOption(endingDate: Date, filterOption: DateCustomFilterType) {
		switch (filterOption) {
			case DateCustomFilterType.TODAY:
				return startOfDay(endingDate);
			case DateCustomFilterType.LAST_WEEK:
				return startOfDay(subDays(endingDate, 7));
			case DateCustomFilterType.LAST_MONTH:
				return startOfDay(subMonths(endingDate, 1));
			case DateCustomFilterType.LAST_3_MONTHS:
				return startOfDay(subMonths(endingDate, 3));
			case DateCustomFilterType.LAST_6_MONTHS:
				return startOfDay(subMonths(endingDate, 6));
			default:
				throw new Error(`Cannot handle filter not specified. Are you trying to use a custom date?`);
		}
	}

	static isInvoiceDataComplete(invoiceData: InvoiceData): boolean {
		if (invoiceData) {
			if (invoiceData.type === 'legal') {
				if (invoiceData.countryCode === 'IT') {
					return !!(
						invoiceData.address &&
						invoiceData.taxCode &&
						invoiceData.type &&
						invoiceData.city &&
						invoiceData.countryCode &&
						invoiceData.postalCode &&
						invoiceData.region &&
						invoiceData.businessName
					);
				} else {
					return !!(
						invoiceData.address &&
						invoiceData.taxCode &&
						invoiceData.type &&
						invoiceData.city &&
						invoiceData.countryCode &&
						invoiceData.businessName &&
						invoiceData.postalCode
					);
				}
			} else {
				if (invoiceData.countryCode === 'IT') {
					return !!(
						invoiceData.address &&
						invoiceData.taxCode &&
						invoiceData.city &&
						invoiceData.countryCode &&
						invoiceData.postalCode &&
						invoiceData.region
					);
				} else {
					return !!(
						invoiceData.address &&
						invoiceData.city &&
						invoiceData.countryCode &&
						invoiceData.postalCode &&
						invoiceData.taxCode
					);
				}
			}
		} else {
			return false;
		}
	}

	static getDateString() {
		const date = new Date();
		const year = date.getFullYear();
		const month = `${date.getMonth() + 1}`.padStart(2, '0');
		const day = `${date.getDate()}`.padStart(2, '0');
		return `${year}${month}${day}`;
	}

	static filterDict<T>(input: Dictionary<T>, predict: (item: T) => boolean): Dictionary<T> {
		const obj: Dictionary<T> = {};
		for (let x in input) {
			if (predict(input[x])) {
				obj[x] = input[x];
			}
		}
		return obj;
	}

	static getDateKey(date: number): string {
		return `${getMonth(date) + 1}-${getYear(date)}`;
	}

	static getQuerySortFromEstelleSort(sort: SortEvent): SortQuery {
		let customSort: SortQuery = {};

		if (sort.type) {
			customSort.sortType = sort.type;
		}
		if (sort.direction) {
			customSort.sortDirection = sort.direction;
		}

		return customSort;
	}

	static getAFlatFeesLeaseIncomeTemplate(amount: number, code: string, description: string): PaymentSetup {
		const leaseIncome: PaymentSetup = {
			item: {
				amount: amount,
				category: 'flat_fees',
				code: code,
				description: description,
				name: 'Flat fees',
				taxInfo: {
					description: '',
					vatCode: '6',
					vatValue: 0
				}
			},
			info: {
				leaseIncomeAmountType: 'fixed',
				leaseIncomeType: 'recurring_fees'
			}
		};

		return leaseIncome;
	}
}
