import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import {
	addMockLeasesSuccessAction,
	createLeaseSuccessAction,
	removeLeaseSuccessAction,
	updateLeaseSuccessAction
} from 'src/app/leases/state/lease.actions';
import { Tenant } from 'src/app/models/tenant.model';
import { deletePropertySuccessAction } from 'src/app/properties/state/properties.actions';
import {
	addTenantSuccessAction,
	archiveTenantSuccessAction,
	deleteTenantSuccessAction,
	editTenantSuccessAction,
	loadTenantsPaginatedSuccessAction,
	loadTenantsSuccessAction,
	loadTenantSuccessAction,
	recoverTenantSuccessAction,
	resetTenantStateSuccessAction,
	uploadMultipleTenantsSuccessAction
} from './tenant.actions';
import { addMockMaintenanceSuccessAction } from 'src/app/maintenances/state/maintenances.actions';

export interface TenantsState extends EntityState<Tenant> {
	isLoaded: boolean;
}
export const tenantsAdapter = createEntityAdapter<Tenant>();
const initialTenantState = tenantsAdapter.getInitialState({
	isLoaded: false
});

export const TenantsReducers = createReducer(
	initialTenantState,
	on(resetTenantStateSuccessAction, (state, payload) => (state = initialTenantState)),
	on(loadTenantSuccessAction, addTenantSuccessAction, createLeaseSuccessAction, (state, payload) =>
		tenantsAdapter.upsertOne(payload.tenant, { ...state, isLoaded: true })
	),
	on(loadTenantsSuccessAction, (state, payload) => {
		// Basic tenants
		if (payload.tenants) {
			if (payload['refreshAll']) {
				return { ...initialTenantState, isLoaded: true };
			} else {
				return state;
			}
		} else {
			return state;
		}
	}),
	on(editTenantSuccessAction, updateLeaseSuccessAction, removeLeaseSuccessAction, (state, payload) => {
		if (payload.tenant) {
			return tenantsAdapter.updateOne({ id: payload.tenant.id, changes: payload.tenant }, state);
		} else {
			return state;
		}
	}),
	on(deleteTenantSuccessAction, (state, payload) => {
		return tenantsAdapter.removeOne(payload.deletedTenantId, state);
	}),
	on(deletePropertySuccessAction, (state, payload) => {
		if (payload.tenants) {
			if (payload['refreshAll']) {
				return tenantsAdapter.setAll(payload.tenants, { ...state, isLoaded: true });
			} else {
				return tenantsAdapter.upsertMany(payload.tenants, { ...state, isLoaded: true });
			}
		} else {
			return state;
		}
	}),
	on(archiveTenantSuccessAction, (state, payload) =>
		tenantsAdapter.updateOne({ id: payload.tenant.id, changes: payload.tenant }, state)
	),
	on(recoverTenantSuccessAction, (state, payload) =>
		tenantsAdapter.updateOne({ id: payload.tenant.id, changes: payload.tenant }, state)
	),
	on(uploadMultipleTenantsSuccessAction, (state, payload) => tenantsAdapter.upsertMany(payload.tenants, state)),

	on(addMockLeasesSuccessAction, addMockMaintenanceSuccessAction, (state, payload) => {
		let tenants = [];
		payload.leases.forEach(lResult => {
			if (lResult.tenant) {
				tenants.push(lResult.tenant);
			}
		});

		if (tenants.length > 0) {
			return tenantsAdapter.upsertMany(tenants, state);
		} else {
			return state;
		}
	}),

	// Version 2

	on(loadTenantsPaginatedSuccessAction, (state, payload) => {
		if (payload.tenantsQueryResult) {
			return tenantsAdapter.upsertMany(payload.tenantsQueryResult.data, { ...state, isLoaded: true });
		} else {
			return state;
		}
	})
);
