import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/store/reducer';
import { leaseAdapter } from './lease.reducers';

export const selectLeasesState = createSelector(
	state => state,
	(state: AppState) => state.leases
);

export const selectLeasesEntities = createSelector(selectLeasesState, state => state.entities);

export const { selectTotal: selectLeasesLength } = leaseAdapter.getSelectors(selectLeasesState);
