import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';
import {
	addMaintenanceSuccessAction,
	addMockMaintenanceSuccessAction,
	editMaintenanceSuccessAction,
	invalidateMaintenancePagesAction,
	loadMaintenanceSuccessAction,
	resetMaintenancesStateSuccessAction
} from './maintenances.actions';
import {
	deletePropertySuccessAction,
	loadPropertyByIdSuccessAction
} from 'src/app/properties/state/properties.actions';

@Injectable()
export class MaintenancesPageEffects {
	constructor(private readonly actions$: Actions) {}

	public invalidateMaintenancePagesEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(
				loadPropertyByIdSuccessAction,
				deletePropertySuccessAction,
				resetMaintenancesStateSuccessAction,
				loadMaintenanceSuccessAction,
				addMaintenanceSuccessAction,
				editMaintenanceSuccessAction,
				addMockMaintenanceSuccessAction
			),
			mergeMap(() => {
				return of(invalidateMaintenancePagesAction());
			})
		)
	);
}
