import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { ScrollEventModule } from 'ngx-scroll-event';
import { DialogsModule } from '../shared/dialogs/dialogs.module';
import { UnitService } from '../unit.service';
import { AuthGuardService } from './guards/auth-guard.service';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { LandlordDataReadyGuard } from './guards/landlord-data-ready-guard';
import { AuthService } from './services/auth.service';
import { BankAccountService } from './services/bank-account.service';
import { EntityDialogsService } from './services/entity-dialogs.service';
import { FileService } from './services/file/file.service';
import { ChatFilterBroadcastService } from './services/filter-broadcaster.service';
import { LandlordModule } from './services/landlord/landlord.module';
import { LoadingService } from './services/loading.service';
import { MangopayService } from './services/mangopay.service';
import { MessagingService } from './services/messaging.service';
import { MixpanelService } from './services/mixpanel-service.service';
import { MockDataGeneratorService } from './services/mock-data-generator.service';
import { PreferencesService } from './services/preferences.service';
import { PresenceService } from './services/presence.service';
import { ReportsService } from './services/reports.service';
import { VersionCheckService } from './services/version-check.service';

@NgModule({
	imports: [DialogsModule, LandlordModule],
	exports: [
		// Singleton Modules
		FlexLayoutModule,
		HttpClientModule,
		ScrollEventModule,
		MatRippleModule,
		MatNativeDateModule,
		SnotifyModule,
		LandlordModule
	],
	providers: [
		// Singleton Services
		AuthService,
		AuthGuardService,
		LandlordDataReadyGuard,
		CanDeactivateGuard,
		BankAccountService,
		EntityDialogsService,
		FileService,
		ChatFilterBroadcastService,
		MangopayService,
		MessagingService,
		MixpanelService,
		MockDataGeneratorService,
		PreferencesService,
		PresenceService,
		LoadingService,
		ReportsService,
		VersionCheckService,
		SnotifyService,
		{ provide: 'SnotifyToastConfig', useValue: ToastDefaults },
		UnitService
	]
})
export class RoomMateCoreModule {
	constructor(@Optional() @SkipSelf() core: RoomMateCoreModule) {
		if (core) {
			throw new Error('You should import core module only in the app module');
		}
	}
}
