import { Injectable } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { AppConstants } from 'src/app/utils/app-costants';
import { MatDialog } from '@angular/material/dialog';
import { BasicInfoDialogComponent } from 'src/app/shared/dialogs/basic-info-dialog/basic-info-dialog.component';
import { CallbackFunctions } from 'src/app/store/reducer';

@Injectable({
	providedIn: 'root'
})
export class EffectInteractionService {
	constructor(private readonly dialog: MatDialog, private readonly toastService: SnotifyService) {}

	displaySuccessToast(title: string, message: string, callback?: () => void) {
		const toast = this.toastService.success(message, title, AppConstants.TOAST_STD_CONFIG);

		toast.on('click', callback);
	}

	showDialogError(contentMessage) {
		const dialogRef = this.dialog.open(BasicInfoDialogComponent, {
			...AppConstants.CENTRAL_DIALOG_CONFIG,
			width: '600px',
			data: {
				rightButton: $localize`:@@com_ok:Ok`,
				picturePath: './assets/img/exclamation_red.svg',
				content: contentMessage
			},
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(result => {});
	}

	logErrorSilently(actionName?: string, error?: any) {
		console.error(`Error when performing an action`, actionName, error);
	}

	callbackSuccess(action: { callbacks?: CallbackFunctions }, data?: any) {
		try {
			if (action?.callbacks?.success) {
				action.callbacks.success(data);
			}
		} catch (e) {
			console.error(e);
		}
	}

	callbackFail(action: { callbacks?: CallbackFunctions }, data?: any) {
		try {
			if (action?.callbacks?.fail) {
				action.callbacks.fail(data);
			}
		} catch (e) {
			console.error(e);
		}
	}

	callbackFinally(action: { callbacks?: CallbackFunctions }, data?: any) {
		try {
			if (action?.callbacks?.finally) {
				action.callbacks.finally(data);
			}
		} catch (e) {
			console.error(e);
		}
	}
}
