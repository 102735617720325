import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import hash from 'object-hash';
import { Observable, of } from 'rxjs';
import { filter, finalize, mergeMap, switchMap, take } from 'rxjs/operators';
import packageInfo from '../../../../package.json';
import { AuthService } from '../services/auth.service';
interface InterceptorHeaders {
	authorization: string;
	estelleVersion: string;
	estelleSession: string;
}

@Injectable({
	providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
	currentSession = this.firebaseAuth.currentSession;
	constructor(private firebaseAuth: AuthService) {}

	private getAuthenticatedHeaders(): Observable<InterceptorHeaders> {
		return this.firebaseAuth.getTokenHeader().pipe(
			switchMap(token => {
				const headers = {
					authorization: `Bearer ${token}`,
					estelleVersion: packageInfo.version,
					estelleSession: this.currentSession
				};
				return of(headers);
			}),
			take(1)
		);
	}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return this.getAuthenticatedHeaders().pipe(
			mergeMap((headers: InterceptorHeaders) => {
				const newRequest = request.clone({
					setHeaders: {
						Authorization: headers.authorization,
						'Estelle-Version': `${headers.estelleVersion}`,
						'Estelle-Session': `${headers.estelleSession}`
					}
				});
				return next.handle(newRequest);
			})
		);
	}
}
