<!-- TENANT -->
<app-roommate-info-card *ngIf="tenantData" sidebarColor="#0058AA">
	<div class="rf-info-card-content">
		<div class="rf-title" i18n="@@cm_tenant_data">Tenant Data</div>
		<div class="rf-content" style="align-items: center">
			<div class="rf-info">
				<app-dash-label-simple label="Name" i18n-label="@@com_name">
					<span style="cursor: pointer" (click)="goToTenant(tenantData.id)">
						{{ tenantData.name }}
					</span>
				</app-dash-label-simple>
			</div>
			<div class="rf-info">
				<app-dash-label-simple label="Lease status" i18n-label="@@com_lease_status" [maxHeight]="true">
					<div *ngIf="tenantData.leaseStatus === eLeaseStatus.LEASE_CURRENT" class="rf-tag-green">
						<p i18n="@@com_running">Running</p>
					</div>
					<div *ngIf="tenantData.leaseStatus === eLeaseStatus.LEASE_PAST" class="rf-tag-red">
						<p i18n="@@com_ended">Ended</p>
					</div>
					<div *ngIf="tenantData.leaseStatus === eLeaseStatus.LEASE_FUTURE" class="rf-tag-green">
						<p i18n="@@com_starting">Starting</p>
					</div>
				</app-dash-label-simple>
			</div>
		</div>
		<div class="rf-content">
			<div class="rf-info-full-width">
				<app-dash-label-simple label="Property Name" i18n-label="@@com_property_name" [isTwoLines]="true">
					<span>{{ tenantData.propertyName }}</span>
				</app-dash-label-simple>
			</div>
		</div>
	</div>
</app-roommate-info-card>

<!-- OWNER -->
<app-roommate-info-card *ngIf="ownerData" sidebarColor="#0058AA">
	<div class="rf-info-card-content">
		<div class="rf-title" i18n="@@cm_owner_data">Owner Data</div>
		<div class="rf-content">
			<div class="rf-info">
				<app-dash-label-simple label="Owner" i18n-label="@@com_prop_owner">
					<span style="cursor: pointer" (click)="goToOwner(ownerData.id)">
						{{ ownerData.name }}
					</span>
				</app-dash-label-simple>
			</div>
			<div class="rf-info">
				<app-dash-label-simple label="Status" i18n-label="@@com_status" [maxHeight]="true">
					<div *ngIf="ownerData.status === 'legal'" class="rf-tag-blue">
						<p i18n="@@com_legal">Legal</p>
					</div>
					<div *ngIf="ownerData.status === 'natural'" class="rf-tag-blue">
						<p i18n="@@com_natural">Natural</p>
					</div>
					<div *ngIf="!ownerData.status">
						<p>-</p>
					</div>
				</app-dash-label-simple>
			</div>
		</div>
		<div class="rf-content" style="display: none">
			<div class="rf-info-full-width">
				<app-dash-label-simple label="Property Name" i18n-label="@@com_property_name">
					<span>{{ ownerData.propertyName }}</span>
				</app-dash-label-simple>
			</div>
		</div>
	</div>
</app-roommate-info-card>

<!-- PROPERTY -->
<app-roommate-info-card *ngIf="propertyData" sidebarColor="#0058AA">
	<div class="rf-info-card-content">
		<div class="rf-title" i18n="@@cm_property_data">Property Data</div>
		<div class="rf-content">
			<div class="rf-info">
				<app-dash-label-simple label="Name" i18n-label="@@com_name">
					<span style="cursor: pointer" (click)="goToProperty(propertyData.id)">
						{{ propertyData.name }}
					</span>
				</app-dash-label-simple>
			</div>
			<div class="rf-info" *ngIf="propertyData.city">
				<app-dash-label-simple label="City" i18n-label="@@com_city">
					<span>{{ propertyData.city }}</span>
				</app-dash-label-simple>
			</div>
		</div>
		<div class="rf-content" *ngIf="propertyData.address">
			<div class="rf-info-full-width">
				<app-dash-label-simple label="Address" i18n-label="@@com_prop_address" [isTwoLines]="true">
					<span>{{ propertyData.address }}</span>
				</app-dash-label-simple>
			</div>
		</div>
	</div>
</app-roommate-info-card>

<!-- UNIT -->
<app-roommate-info-card *ngIf="unitData" sidebarColor="#0058AA">
	<div class="rf-info-card-content">
		<div class="rf-title">{{ unitData.name }}</div>
		<div class="rf-content">
			<div class="rf-info">
				<app-dash-label-simple label="Property Name" i18n-label="@@com_property_name">
					<span style="cursor: pointer" (click)="goToUnit(unitData.propertyId, unitData.id)">
						{{ unitData.propertyName }}
					</span>
				</app-dash-label-simple>
			</div>
			<div class="rf-info">
				<app-dash-label-simple label="Unit type" i18n-label="@@com_unit_type">
					<span>{{ unitData.type }}</span>
				</app-dash-label-simple>
			</div>
		</div>
	</div>
</app-roommate-info-card>

<!-- LEASE & BOOKING -->
<app-roommate-info-card
	*ngIf="leaseOrBookingData"
	sidebarColor="{{
		'rgba(' +
			leaseOrBookingData.color[0] +
			',' +
			leaseOrBookingData.color[1] +
			',' +
			leaseOrBookingData.color[2] +
			',0.2)'
	}}"
>
	<div class="rf-info-card-content">
		<div
			class="rf-title"
			[ngStyle]="{
				color:
					'rgba(' +
					leaseOrBookingData.color[0] +
					',' +
					leaseOrBookingData.color[1] +
					',' +
					leaseOrBookingData.color[2] +
					', 1)'
			}"
		>
			{{ leaseOrBookingData.title }}
		</div>
		<div class="rf-content">
			<div
				[ngClass]="{
					'rf-info': leaseOrBookingData.value,
					'rf-info-full-width': !leaseOrBookingData.value
				}"
			>
				<app-dash-label-simple label="Name" i18n-label="@@com_name">
					<span style="cursor: pointer" (click)="goToTenant(leaseOrBookingData.tenantId)">
						{{ leaseOrBookingData.name }}
					</span>
				</app-dash-label-simple>
			</div>
			<div class="rf-info" *ngIf="leaseOrBookingData.value">
				<app-dash-label-simple label="Monthly rent" i18n-label="@@com_monthly_rent">
					<span>
						{{ leaseOrBookingData.value | formatAmountSymbol: leaseOrBookingData.currency:false }}
					</span>
				</app-dash-label-simple>
			</div>
		</div>
		<div class="rf-content">
			<div class="rf-info">
				<app-dash-label-simple label="From" i18n-label="@@com_from">
					<span>{{ leaseOrBookingData.startDate }}</span>
				</app-dash-label-simple>
			</div>
			<div class="rf-info">
				<app-dash-label-simple label="To" i18n-label="@@com_to">
					<span>{{ leaseOrBookingData.endDate }}</span>
				</app-dash-label-simple>
			</div>
		</div>
	</div>
</app-roommate-info-card>

<!-- TRANSACTION -->
<app-roommate-info-card *ngIf="paymentData" sidebarColor="#0058AA">
	<div class="rf-info-card-content">
		<div class="rf-title">{{ paymentData.title }}</div>
		<div class="rf-content">
			<div class="rf-info">
				<app-dash-label-simple label="Assignee" i18n-label="@@com_assignee">
					<span>{{ paymentData.assigneeName }}</span>
				</app-dash-label-simple>
			</div>
			<div class="rf-info">
				<app-dash-label-simple label="Paid on" i18n-label="@@com_paid">
					<span>
						{{ paymentData.paidOn ? (paymentData.paidOn | dfnsFormat: 'dd MMM yyyy') : '-' }}
					</span>
				</app-dash-label-simple>
			</div>
		</div>
		<div class="rf-content">
			<div class="rf-info">
				<app-dash-label-simple label="Invoiced" i18n-label="@@com_invoiced">
					<span>{{ paymentData.invoiced ? 'Yes' : '-' }}</span>
				</app-dash-label-simple>
			</div>
		</div>
	</div>
</app-roommate-info-card>

<div class="rf-loader" [ngStyle]="{ display: loading ? 'block' : 'none' }"></div>
