import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/store/reducer';
import { unitPagesAdapter } from './units-pages.reducers';

const selectUnitPagesState = createSelector(
	state => state,
	(state: AppState) => state.unitPages
);

export const { selectTotal: selectUnitPagesLength } = unitPagesAdapter.getSelectors(selectUnitPagesState);

export const selectUnitPagesEntities = createSelector(selectUnitPagesState, state => {
	if (state.isLoaded) {
		return state.entities;
	}
	return null;
});
