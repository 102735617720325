import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import {
	MatSelectCountryModule,
	MatSelectCountrySupportedLanguages
} from '@angular-material-extensions/select-country';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import { isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFirestoreModule, SETTINGS as FS_SETTINGS } from '@angular/fire/compat/firestore';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { DEFAULTS, SETTINGS } from '@angular/fire/compat/remote-config';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxPicaModule } from '@digitalascetic/ngx-pica';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AngularIbanModule } from 'angular-iban';
import { es, fr, it } from 'date-fns/locale';
import { ChartsModule } from 'ng2-charts';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { ClipboardModule } from 'ngx-clipboard';
import { DateFnsConfigurationService, DateFnsModule } from 'ngx-date-fns';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { DragulaModule } from 'ng2-dragula';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InterceptorModule } from './core/interceptor/interceptor.module';
import { RoomMateCoreModule } from './core/roommate-core.module';
import { HomepageComponent } from './homepage/homepage.component';
import { DialogsModule } from './shared/dialogs/dialogs.module';
import { SharedModule } from './shared/shared.module';
import { AppEffects } from './store/effects';
import { AppReducers } from './store/reducer';
import { NgImageSliderModule } from 'ng-image-slider';
import { DateFnsDateAdapter, MAT_DATE_FNS_DATE_FORMATS } from './utils/date-fns-adapter';

// Loading language on angular

let preferredLanguage = 'en';

const dateLanguageConfig = new DateFnsConfigurationService();

if (localStorage.getItem('locale') === 'it') {
	console.log(`Registering it locale`);
	registerLocaleData(localeIt, 'it');
	preferredLanguage = 'it';
	dateLanguageConfig.setLocale(it);
} else if (localStorage.getItem('locale') === 'es') {
	console.log(`Registering es locale`);
	registerLocaleData(localeEs, 'es');
	preferredLanguage = 'es';
	dateLanguageConfig.setLocale(es);
} else if (localStorage.getItem('locale') === 'fr') {
	console.log(`Registering fr locale`);
	registerLocaleData(localeFr, 'fr');
	preferredLanguage = 'fr';
	dateLanguageConfig.setLocale(fr);
} else {
	console.log(`Registering en locale`);
	registerLocaleData(localeIt, 'en');
	preferredLanguage = 'en';
}

const pathName = window.location.pathname;
const languagePath = pathName.substr(1, 2);

if (languagePath === 'it' || languagePath === 'en' || languagePath === 'es' || languagePath === 'fr') {
	if (preferredLanguage !== languagePath) {
		console.log(`Preferred language ${preferredLanguage} is different than language path ${languagePath}`);
		const path = `/${preferredLanguage}${window.location.pathname.substr(3)}`;
		window.location.pathname = path;
	}
}

const googleMapsParams = {
	apiKey: environment.maps.key,
	libraries: ['places'],
	language: 'en'
	// region: 'DE'
};

const socketConfig: SocketIoConfig = {
	url: environment.services.socket,
	options: {
		transports: ['websocket', 'polling']
	}
};

@NgModule({
	declarations: [AppComponent, HomepageComponent],
	imports: [
		EffectsModule.forRoot(AppEffects),
		StoreModule.forRoot(AppReducers),
		StoreDevtoolsModule.instrument({
			maxAge: 100, // Retains last 100 states
			autoPause: true // Pauses recording actions and state changes when the extension window is not open
		}),
		BrowserModule,
		BrowserAnimationsModule,
		SharedModule,
		RoomMateCoreModule,
		DialogsModule,
		AppRoutingModule,
		InterceptorModule,
		AngularFireModule.initializeApp(environment.firebase, 'landlord-dashboard'),
		AngularFireMessagingModule,
		AngularFireDatabaseModule,
		AngularFireStorageModule,
		AngularFireAuthModule,
		// https://meumobi.github.io/ionic/firebase/2020/01/20/firebase-web-push-ionic.html
		// This should allow us to have both the angular service worker and the angularfire one
		ServiceWorkerModule.register('combined-sw.js', { enabled: environment.production }),
		AngularFirestoreModule,
		NgxPicaModule,
		ChartsModule,
		ImageCropperModule,
		DateFnsModule.forRoot(),
		ClipboardModule,
		NgxSpinnerModule,
		Ng2TelInputModule,
		OverlayModule,
		ScrollingModule,
		DragDropModule,
		AngularIbanModule,
		NgImageSliderModule,
		NgxLinkifyjsModule.forRoot(),
		AgmCoreModule.forRoot(googleMapsParams),
		MatGoogleMapsAutocompleteModule,
		DragulaModule.forRoot(),
		MatSelectCountryModule.forRoot(preferredLanguage as MatSelectCountrySupportedLanguages), // you can use 'br' | 'de' | 'en' | 'es' | 'fr' | 'hr' | 'it' | 'nl' | 'pt' --> MatSelectCountrySupportedLanguages
		SocketIoModule.forRoot(socketConfig)
	],
	providers: [
		{ provide: LOCALE_ID, useValue: preferredLanguage },
		{ provide: DateFnsConfigurationService, useValue: dateLanguageConfig },
		{
			provide: DateAdapter,
			useClass: DateFnsDateAdapter
		},
		{
			provide: FS_SETTINGS,
			useValue: {
				experimentalForceLongPolling: true
			}
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue: MAT_DATE_FNS_DATE_FORMATS
		},
		{ provide: DEFAULTS, useValue: { chat_migration_complete: false, maintenances_migration_complete: false } },
		{
			provide: SETTINGS,
			useFactory: () => (isDevMode() ? { 60000: 60000 * 20 } : {})
		}
	],

	bootstrap: [AppComponent]
})
export class AppModule {}
