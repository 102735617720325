import { BillingEffects } from '../billing/state/billing.effects';
import { BookingsEffects } from '../bookings/state/bookings.effects';
import { CalendarEffects } from '../calendar/state/calendar.effects';
import { ChannelManagerConnectionsEffects } from '../channel-manager/state/connections.effects';
import { LandlordEffects } from '../core/services/landlord/state/landlord.effects';
import { MetricsEffects } from '../core/services/metrics/metrics.effects';
import { UserEffects } from '../core/services/user/state/user.effects';
import { LeaseEffects } from '../leases/state/lease.effects';
import { MaintenancesPageEffects } from '../maintenances/state/maintenances-pages.effects';
import { MaintenancesEffects } from '../maintenances/state/maintenances.effects';
import { OwnersEffects } from '../owners/state/owners.effects';
import { PaymentsPageEffects } from '../payments/state/payments-pages.effects';
import { PaymentsEffects } from '../payments/state/payments.effects';
import { PropertiesEffects } from '../properties/state/properties.effects';
import { TenantsEffects } from '../tenants/state/tenant.effects';

export const AppEffects = [
	CalendarEffects,
	PaymentsEffects,
	TenantsEffects,
	PropertiesEffects,
	LandlordEffects,
	UserEffects,
	BillingEffects,
	LeaseEffects,
	MaintenancesEffects,
	OwnersEffects,
	BookingsEffects,
	MetricsEffects,
	PaymentsPageEffects,
	MaintenancesPageEffects,
	ChannelManagerConnectionsEffects
];
