import { AvailabilityTabKeys } from '../core/services/filters/filters.model';
import { Booking } from './booking.model';
import { EpaymentPreferences, InvoiceData } from './common';
import { AttachedFile } from './fileRm.model';
import { PaymentItem } from './payments';
import { Property, PropertyPaymentsSetup, UnitType } from './property.model';
import { Tenant } from './tenant.model';

export interface LateFeesData {
	type: 'fixed' | 'percentage';
	amount: number;
	timeDelta: number;
	recurringFee: boolean;
	recurring: number;
	applyTimes: number;
}
export interface AdditionalTenant {
	name?: string;
	surname?: string;
	email?: string;
	phone?: string;
	invoiceData?: InvoiceData;
}
export interface SignatureDatabaseDetails {
	landlordUrl: string;
	createdAt: number;
	updatedAt: number;
	status: 'active' | 'refused' | 'finished';
	users: {
		createdAt: number;
		updatedAt: number;
		userId: string;
		email: string;
		phone: string;
		name: string;
		surname: string;
		dashId: string;
		status: 'done' | 'pending' | 'refused';
		type: 'landlord' | 'tenant';
	}[];
}

export interface LeaseRegistrationData {
	regDate?: number;
	lastRegDate?: number;
	leaseNumber?: string;
	leaseCode?: string;
}

export interface LeaseData {
	id: string;
	currency: string;
	entryDate: number;
	startDate: number;
	exitDate: number;
	endDate: number;
	tenantId: string;
	value: number;
}

export class Lease {
	public static ACTIVE = 0;
	public static PAST = -1;
	public static FUTURE = 1;
	public creationDate = 0;
	public signatureFiles: AttachedFile[];
	public signatureDetails?: SignatureDatabaseDetails;
	public unitId?: string;
	public bookingId?: string;
	public landlordId?: string;

	public entryDate: number = 0;
	public exitDate: number = 0;

	public paymentsSetup: PropertyPaymentsSetup;

	public isMock: boolean = false;
	public metadata: any;

	public rentItem: PaymentItem;
	public epaymentPreferences?: EpaymentPreferences;

	public referenceCode?: string;

	constructor(
		public id: string,
		public startDate: number,
		public endDate: number,
		public monthlyRent: number,
		public propertyId: string,
		public tenantsId: { [key: string]: string },
		public deposit: number = 0,
		public currency: string = 'EUR', // Default
		public dueOn: number = null,
		public frequency: number = 1,
		public registrationData: LeaseRegistrationData = {},
		public leaseType?: { type: number; percentage: number },
		public lateFees?: LateFeesData,
		public otherTenants: AdditionalTenant[] = []
	) {}

	public static sanitize(lease: Lease) {
		const sanitizedLease = new Lease(
			lease.id,
			lease.startDate,
			lease.endDate,
			lease.monthlyRent,
			lease.propertyId,
			lease.tenantsId,
			lease.deposit,
			lease.currency,
			lease.dueOn,
			lease.frequency,
			lease.registrationData,
			lease.leaseType,
			lease.lateFees,
			lease.otherTenants
		);
		sanitizedLease.creationDate = lease.creationDate;
		sanitizedLease.signatureFiles = lease.signatureFiles;
		sanitizedLease.signatureDetails = lease.signatureDetails;
		sanitizedLease.unitId = lease.unitId;
		sanitizedLease.bookingId = lease.bookingId;
		sanitizedLease.entryDate = lease.entryDate;
		sanitizedLease.exitDate = lease.exitDate;
		sanitizedLease.isMock = lease.isMock;
		sanitizedLease.metadata = lease.metadata;
		sanitizedLease.landlordId = lease.landlordId;
		sanitizedLease.rentItem = lease.rentItem;
		sanitizedLease.epaymentPreferences = lease.epaymentPreferences;

		sanitizedLease.paymentsSetup = {
			incomes: [],
			costs: []
		};

		sanitizedLease.paymentsSetup.costs = lease.paymentsSetup?.costs || [];
		sanitizedLease.paymentsSetup.incomes = lease.paymentsSetup?.incomes || [];

		sanitizedLease.referenceCode = lease.referenceCode || null;

		return sanitizedLease;
	}
}

export interface LeaseAddData {
	property: Property;
	unitId: string;
	tenant?: Tenant;
	suggestedStartDate?: number;
	suggestedEndDate?: number;
}

export interface LeaseEditData {
	lease: Lease;
}

export interface LeasesPastData {
	property: Property;
}

export interface LeaseAddOptions {
	firstTimeCosts?: PaymentItem[]; // Not present by default
	extraFeesOnInvoice?: boolean; // True by default
	pastPaymentsIncluded?: boolean; // False by default
	pastPaymentsPaid?: boolean; // True by default
	depositAlreadyPaid?: boolean; // False by default
	depositCustomExpiryDate?: number; // Not present by default
	sendInviteToTenant?: boolean; // True by default
	booking?: Booking;
	emailTemplateGeneration?: boolean;
}

export interface AvailabilityQueryOptions {
	year: number;
	status?: AvailabilityTabKeys;
	text?: string;
	dateFrom?: number;
	dateTo?: number;
	maxRent?: number;
	unitType?: UnitType;
	ownerId?: string;
}
