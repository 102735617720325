<div class="rf-top-section">
	<div class="rf-years-container">
		<div class="rf-space"></div>
		<div
			class="rf-previous-year"
			(click)="moveToYear('previous')"
			matTooltip="Previous year"
			i18n-matTooltip="@@tooltip_previous_year"
		>
			<img class="rf-arrow-image rf-is-back" src="./assets/img/arrow_forward.png" />
		</div>
		<div class="rf-years">
			<div
				[ngStyle]="{ flex: flexFirstPart + ' 1 0%' }"
				[ngClass]="{ 'rf-not-current-year': !firstPartCurrentYear, clickable: !firstPartCurrentYear }"
				(click)="!firstPartCurrentYear ? moveToYear('previous') : $event.preventDefault()"
			>
				{{ years[0] }}
			</div>
			<div
				[ngStyle]="{ flex: flexSecondPart + ' 1 0%' }"
				[ngClass]="{ 'rf-not-current-year': !secondPartCurrentYear, clickable: !secondPartCurrentYear }"
				(click)="!secondPartCurrentYear ? moveToYear('next') : $event.preventDefault()"
			>
				<span>{{ years[1] }}</span>
				<div
					class="rf-next-year"
					matTooltip="Next year"
					i18n-matTooltip="@@tooltip_next_year"
					(click)="moveToYear('next')"
				>
					<img class="rf-arrow-image" src="./assets/img/arrow_forward.png" />
				</div>
			</div>
		</div>
		<div class="rf-space" [ngClass]="{ 'rf-not-current-year': !secondPartCurrentYear }"></div>
	</div>
	<div class="rf-months-container">
		<div
			fxLayout="row"
			fxLayoutAlign="space-between center"
			class="rf-calendar-title rf-is-clickable"
			(click)="onSortDataClicked('propertyName')"
		>
			<span class="rf-is-clickable-underline">{{ mainCalendarTitle }}</span>
			<div *ngIf="sort && sort.active === 'unit'" style="margin-right: 15px">
				<ng-container *ngTemplateOutlet="sortArrows; context: { sortDirection: sort.direction }"></ng-container>
			</div>
		</div>
		<div
			fxLayout="row"
			fxLayoutAlign="space-between center"
			class="rf-calendar-title rf-is-clickable"
			(click)="onSortDataClicked('unitName')"
		>
			<span class="rf-is-clickable-underline">{{ calendarTitle }}</span>
			<div *ngIf="sort && sort.active === 'unit'" style="margin-right: 15px">
				<ng-container *ngTemplateOutlet="sortArrows; context: { sortDirection: sort.direction }"></ng-container>
			</div>
		</div>
		<div
			*ngFor="let month of monthsDisplayed; let monthIndex = index"
			class="rf-month"
			[ngStyle]="{ flex: daysPerMonth[monthIndex] }"
		>
			<a class="rf-vertical-border"></a>
			<a [ngClass]="{ 'rf-is-selected': monthIndex === monthSelected }">{{ month }}</a>
			<a></a>
		</div>
	</div>
</div>
<div class="rf-content-calendar" [ngClass]="{ 'rf-withouth-scroll': withoutScroll }">
	<cdk-virtual-scroll-viewport itemSize="30" [ngStyle]="{ height: suggestedHeight }" #virtualScroll>
		<div class="rf-row-calendar" *cdkVirtualFor="let row of displayedRows; let index = index">
			<span
				[appPopoverRef]="PopoverCardTemplate"
				[delay]="500"
				[openOnHover]="true"
				[position]="[titlePopoverPosition]"
				[context]="{
					entityType: entityType.PROPERTY,
					entityId: row.propertyId
				}"
				(click)="onItemTitleClicked(row.propertyId, row.unitId)"
			>
				{{ row.propertyName }}
			</span>
			<span
				[appPopoverRef]="PopoverCardTemplate"
				[delay]="500"
				[openOnHover]="true"
				[position]="[titlePopoverPosition]"
				[context]="{
					entityType: entityType.UNIT,
					entityId: row.unitId
				}"
				(click)="onItemTitleClicked(row.propertyId, row.unitId)"
				>{{ row.title }}</span
			>
			<div class="rf-unit-availability">
				<div
					*ngFor="let item of row.items"
					class="rf-availability"
					[ngStyle]="{
						left: slice * item.startDayOfYear + '%',
						width: slice * (item.endDayOfYear - item.startDayOfYear) + '%'
					}"
					(click)="onItemClicked(item, index)"
				>
					<div
						[ngStyle]="{
							'background-color':
								'rgba(' + item.color[0] + ',' + item.color[1] + ',' + item.color[2] + ', 0.2)'
						}"
						[appPopoverRef]="PopoverCardTemplate"
						[delay]="500"
						[openOnHover]="true"
						[showPopoverOnMouseLocation]="true"
						[ignoreClassOnMouseLeave]="['rf-vertical-border']"
						[context]="{
							entityType: item.type,
							entityId: item.id
						}"
					></div>
				</div>
				<div
					*ngFor="let month of months; let monthIndex = index"
					class="rf-month"
					[ngStyle]="{ flex: daysPerMonth[monthIndex] }"
				>
					<a [ngClass]="{ 'rf-vertical-border': monthIndex !== 0 }"></a>
					<a></a>
					<a></a>
				</div>
			</div>
		</div>
	</cdk-virtual-scroll-viewport>
</div>

<ng-template #PopoverCardTemplate let-entityType="entityType" let-entityId="entityId">
	<app-roommate-popover [entityType]="entityType" [entityId]="entityId"></app-roommate-popover>
</ng-template>

<ng-template #sortArrows let-sortDirection="sortDirection">
	<img
		src="./assets/img/icon_sort.png"
		class="rf-img-sort"
		style="margin-bottom: 2px"
		*ngIf="sortDirection"
		[ngClass]="{ 'rf-img-reversed': sortDirection !== 'asc' }"
	/>
</ng-template>
