<div mat-dialog-content>
	<input type="text" style="display: none" cdkFocusInitial />

	<div fxLayout="column" fxLayoutAlign="flex-start center" class="oneshot-top-section">
		<img class="premium-img" i18n-src="@@trial_or_img" src="./assets/img/rm_premium_icon.png" />
		<p class="semi-bold-25 color-white no-margin" i18n="@@change_paid_plan_title">
			<span class="color-orange">Change</span>&nbsp;Plan
		</p>
		<div
			fxLayout="row"
			fxLayoutAlign="flex-end flex-start"
			style="margin-top: -65px; width: 100%; padding-right: 25px"
		>
			<img class="img-close rf-is-clickable" src="./assets/img/close.svg" (click)="closeClick()" />
		</div>
	</div>
	<p
		*ngIf="data.currentPlan.startsWith('units') && data.selectedPlan.startsWith('premium')"
		class="regular-15"
		style="margin: 20px 0px 0px 40px"
		i18n="@@paid_plan_change_desc_1"
	>
		Hi {{ getLandlordName() }}, you are changing from&nbsp;<span class="semi-bold-15 color-1">Units</span>&nbsp;plan
		to&nbsp;<span class="semi-bold-15 color-1">Premium</span>&nbsp;plan.
	</p>
	<p
		*ngIf="data.currentPlan.startsWith('units') && data.selectedPlan.startsWith('professional')"
		class="regular-15"
		style="margin: 20px 0px 0px 40px"
		i18n="@@paid_plan_change_desc_2"
	>
		Hi {{ getLandlordName() }}, you are changing from&nbsp;<span class="semi-bold-15 color-1">Units</span>&nbsp;plan
		to&nbsp;<span class="semi-bold-15 color-orange">Professional</span>&nbsp;plan.
	</p>
	<p
		*ngIf="data.currentPlan.startsWith('transaction') && data.selectedPlan.startsWith('premium')"
		class="regular-15"
		style="margin: 20px 0px 0px 40px"
		i18n="@@paid_plan_change_desc_3"
	>
		Hi {{ getLandlordName() }}, you are changing from&nbsp;<span class="semi-bold-15 color-orange">Transaction</span
		>&nbsp;plan to&nbsp;<span class="semi-bold-15 color-1">Premium</span>&nbsp;plan.
	</p>
	<p
		*ngIf="data.currentPlan.startsWith('transaction') && data.selectedPlan.startsWith('professional')"
		class="regular-15"
		style="margin: 20px 0px 0px 40px"
		i18n="@@paid_plan_change_desc_4"
	>
		Hi {{ getLandlordName() }}, you are changing from&nbsp;<span class="semi-bold-15 color-orange">Transaction</span
		>&nbsp;plan to&nbsp;<span class="semi-bold-15 color-orange">Professional</span>&nbsp;plan.
	</p>
	<p
		*ngIf="data.selectedPlan.startsWith('units')"
		class="regular-15"
		style="margin: 20px 0px 0px 40px"
		i18n="@@paid_plan_change_desc_units"
	>
		Hi {{ getLandlordName() }}, you are changing your plan to&nbsp;<span class="semi-bold-15 color-orange"
			>Units</span
		>&nbsp;plan.
	</p>

	<div class="side-padded">
		<div class="side-padded">
			<p class="regular-15 color-1 no-margin" i18n="@@paid_plan_change_desc_7">Details of your new plan</p>

			<div fxLayout="row" fxLayoutAlign="flex-start flex-start" fxLayoutGap="20px" class="v-spacing">
				<div fxFlex fxLayoutAlign="flex-start flex-start" fxLayout="column">
					<app-dash-label-simple i18n-label="@@com_renewal" label="Renewal">
						<span i18n="@@settings_profile_renewal_month">Monthly plan, monthly payment</span> |
						<span i18n="@@paid_plan_change_starting">This plan will start on</span>&nbsp;{{
							data.estimation.nextInvoice.date | dfnsFormat : 'dd MMM yyyy'
						}}
					</app-dash-label-simple>
				</div>
			</div>

			<div>
				<div style="margin-top: 20px" fxFlex fxLayoutAlign="flex-start flex-start" fxLayout="column">
					<app-dash-label-simple i18n-label="@@com_next_amount_to_pay" label="Next amount to pay">
						<span>{{
							data.estimation.nextInvoice.subTotal
								| formatAmountSymbol : data.estimation.nextInvoice.currency
						}}</span>
					</app-dash-label-simple>
				</div>
			</div>
		</div>

		<div fxLayout="row" fxLayoutAlign="center flex-end" style="margin-top: 30px; margin-bottom: 20px">
			<button mat-button class="button-positive uppercase" (click)="okClick()" i18n="@@con_confirm">
				Confirm
			</button>
		</div>

		<!-- <div class="side-padded">
			<p
				class="regular-12 color-grey rf-is-clickable"
				style="text-align: center; font-style: italic; text-decoration: rf-is-underlined"
				(click)="seeTerms()"
				i18n="@@see_subscription_and_cancellation_conditions"
			>
				See our Subscription and Cancellation conditions
			</p>
		</div> -->

		<div class="v-spacing"></div>
	</div>
</div>
