import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/store/reducer';
import { ownersAdapter } from './owners.reducers';

const selectOwnersState = createSelector(
	state => state,
	(state: AppState) => state.owners
);

export const selectOwnersEntities = createSelector(selectOwnersState, state => {
	if (state.isLoaded) {
		return state.entities;
	} else {
		return null;
	}
});
