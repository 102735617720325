import { createAction, props } from '@ngrx/store';
import { CalendarEvent } from 'src/app/models/calendar';
import {
	OpenMaintenancesData,
	UnitStatusData,
	TenantsMetrics,
	PropertiesMetricsData,
	TenantMetricsData,
	PaymentMetricsData,
	ChannelManagerUnitMetricsData,
	ChannelManagerMetricsData,
	OverdueIdsData,
	EventMetricsData,
	ChatMetricsData
} from './metrics.reducers';

export enum MetricsActions {
	LOAD_PAYMENTS_VIEW = '[Metrics] Load Payment Views',
	LOAD_OVERDUE_IDS_VIEW = '[Metrics] Load Overdue Ids view',
	LOAD_MAINTENANCES_VIEW = '[Metrics] Load Maintenances Metrics',
	LOAD_UNIT_STATUS_VIEW = '[Metrics] Load Unit Status View Metrics',
	LOAD_TENANTS_METRICS = '[Metrics] Load Tenant Metrics Metrics',
	LOAD_PROPERTIES_METRICS = '[Metrics] Load Properties Metrics Metrics',
	LOAD_CM_METRICS = '[Metrics] Load Channel Manager Metrics',
	LOAD_CM_UNIT_METRICS = '[Metrics] Load Channel Manager Unit Metrics',
	LOAD_EVENTS_METRICS = '[Metrics] Load Event Metrics',
	LOAD_CHAT_METRICS = '[Metrics] Load Chat Metrics',
	RESET_PAYMENT_DATABASE_VIEW = '[Metrics] Reset payment database view',
	LOAD_PAYMENTS_VIEW_SUCCESS = '[Metrics] Load Payments View Success',
	LOAD_OVERDUE_IDS_VIEW_SUCCESS = '[Metrics] Load Overdue Ids view Success',
	LOAD_MAINTENANCES_VIEW_SUCCESS = '[Metrics] Load Maintenances Metrics Success',
	LOAD_UNIT_STATUS_VIEW_SUCCESS = '[Metrics] Load Unit Status View Metrics Success',
	LOAD_TENANTS_METRICS_SUCCESS = '[Metrics] Load Tenant Metrics Metrics Success',
	LOAD_PROPERTIES_METRICS_SUCCESS = '[Metrics] Load Properties Metrics Metrics Success',
	LOAD_CM_METRICS_SUCCESS = '[Metrics] Load Channel Manager Metrics Success',
	LOAD_CM_UNIT_METRICS_SUCCESS = '[Metrics] Load Channel Unit Manager Metrics Success',
	LOAD_EVENTS_METRICS_SUCCESS = '[Metrics] Load Event Metrics Success',
	LOAD_CHAT_METRICS_SUCCESS = '[Metrics] Load Chat Metrics Success',
	RESET_PAYMENT_DATABASE_VIEW_SUCCESS = '[Metrics] Reset payment database view Success',
	LOAD_PAYMENTS_VIEW_FAILURE = '[Metrics] Load Payments View Failure',
	LOAD_OVERDUE_IDS_VIEW_FAILURE = '[Metrics] Load Overdue Ids view Failure',
	LOAD_MAINTENANCES_VIEW_FAILURE = '[Metrics] Load Maintenances Metrics Failure',
	LOAD_UNIT_STATUS_VIEW_FAILURE = '[Metrics] Load Unit Status View Metrics Failure',
	LOAD_TENANTS_METRICS_FAILURE = '[Metrics] Load Tenant Metrics Metrics Failure',
	LOAD_PROPERTIES_METRICS_FAILURE = '[Metrics] Load Tenant Metrics Metrics Failure',
	LOAD_CM_METRICS_FAILURE = '[Metrics] Load Channel Manager Metrics Failure',
	LOAD_CM_UNIT_METRICS_FAILURE = '[Metrics] Load Channel Unit Manager Metrics Failure',
	LOAD_EVENTS_METRICS_FAILURE = '[Metrics] Load Event Metrics Failure',
	LOAD_CHAT_METRICS_FAILURE = '[Metrics] Load Chat Metrics Failure',
	RESET_PAYMENT_DATABASE_VIEW_FAILURE = '[Metrics] Reset payment database view Failure'
}

export const loadPaymentsViewAction = createAction(MetricsActions.LOAD_PAYMENTS_VIEW);
export const loadOverdueIdsViewAction = createAction(MetricsActions.LOAD_OVERDUE_IDS_VIEW);
export const loadMaintenancesViewAction = createAction(MetricsActions.LOAD_MAINTENANCES_VIEW);
export const loadUnitStatusViewAction = createAction(MetricsActions.LOAD_UNIT_STATUS_VIEW);
export const loadTenantsMetricsAction = createAction(MetricsActions.LOAD_TENANTS_METRICS);
export const loadPropertiesMetricsAction = createAction(MetricsActions.LOAD_PROPERTIES_METRICS);
export const loadCMMetricsAction = createAction(MetricsActions.LOAD_CM_METRICS);
export const loadCMUnitMetricsAction = createAction(MetricsActions.LOAD_CM_UNIT_METRICS);
export const loadEventsMetricsAction = createAction(MetricsActions.LOAD_EVENTS_METRICS);
export const loadChatMetricsAction = createAction(MetricsActions.LOAD_CHAT_METRICS);

export const loadPaymentsViewSuccessAction = createAction(MetricsActions.LOAD_PAYMENTS_VIEW_SUCCESS, props<{ views: PaymentMetricsData }>());
export const loadPaymentsViewFailureAction = createAction(MetricsActions.LOAD_PAYMENTS_VIEW_FAILURE, props<{ errorName?: string; error?: any }>());

export const loadOverdueIdsViewSuccessAction = createAction(MetricsActions.LOAD_OVERDUE_IDS_VIEW_SUCCESS, props<{ view: OverdueIdsData }>());
export const loadOverdueIdsViewFailureAction = createAction(MetricsActions.LOAD_OVERDUE_IDS_VIEW_FAILURE, props<{ errorName?: string; error?: any }>());

export const loadMaintenancesViewSuccessAction = createAction(MetricsActions.LOAD_MAINTENANCES_VIEW_SUCCESS, props<{ view: OpenMaintenancesData }>());
export const loadMaintenancesViewFailureAction = createAction(MetricsActions.LOAD_MAINTENANCES_VIEW_FAILURE, props<{ errorName?: string; error?: any }>());

export const loadUnitStatusViewSuccessAction = createAction(MetricsActions.LOAD_UNIT_STATUS_VIEW_SUCCESS, props<{ view: UnitStatusData }>());
export const loadUnitStatusViewFailureAction = createAction(MetricsActions.LOAD_UNIT_STATUS_VIEW_FAILURE, props<{ errorName?: string; error?: any }>());

export const loadTenantsMetricsSuccessAction = createAction(MetricsActions.LOAD_TENANTS_METRICS_SUCCESS, props<{ view: TenantMetricsData }>());
export const loadTenantsMetricsFailureAction = createAction(MetricsActions.LOAD_TENANTS_METRICS_FAILURE, props<{ errorName?: string; error?: any }>());

export const loadPropertiesMetricsSuccessAction = createAction(MetricsActions.LOAD_PROPERTIES_METRICS_SUCCESS, props<{ view: PropertiesMetricsData }>());
export const loadPropertiesMetricsFailureAction = createAction(MetricsActions.LOAD_PROPERTIES_METRICS_FAILURE, props<{ errorName?: string; error?: any }>());

export const loadCMMetricsSuccessAction = createAction(MetricsActions.LOAD_CM_METRICS_SUCCESS, props<{ view: ChannelManagerMetricsData }>());
export const loadCMMetricsFailureAction = createAction(MetricsActions.LOAD_CM_METRICS_FAILURE, props<{ errorName?: string; error?: any }>());

export const loadCMUnitMetricsSuccessAction = createAction(MetricsActions.LOAD_CM_UNIT_METRICS_SUCCESS, props<{ view: ChannelManagerUnitMetricsData }>());
export const loadCMUnitMetricsFailureAction = createAction(MetricsActions.LOAD_CM_UNIT_METRICS_FAILURE, props<{ errorName?: string; error?: any }>());

export const loadEventsMetricsSuccessAction = createAction(MetricsActions.LOAD_EVENTS_METRICS_SUCCESS, props<{ view: EventMetricsData }>());
export const loadEventsMetricsFailureAction = createAction(MetricsActions.LOAD_EVENTS_METRICS_FAILURE, props<{ errorName?: string; error?: any }>());

export const loadChatMetricsSuccessAction = createAction(MetricsActions.LOAD_CHAT_METRICS_SUCCESS, props<{ view: ChatMetricsData }>());
export const loadChatMetricsFailureAction = createAction(MetricsActions.LOAD_CHAT_METRICS_FAILURE, props<{ errorName?: string; error?: any }>());

// Reset
export const resetPaymentDatabaseViewAction = createAction(MetricsActions.RESET_PAYMENT_DATABASE_VIEW);
export const resetPaymentDatabaseViewSuccessAction = createAction(MetricsActions.RESET_PAYMENT_DATABASE_VIEW_SUCCESS);
export const resetPaymentDatabaseViewFailureAction = createAction(MetricsActions.RESET_PAYMENT_DATABASE_VIEW_FAILURE, props<{ errorName?: string; error?: any }>());
