import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { MaintenanceRequest } from 'src/app/models/maintenanceRequest.model';
import { resetStoreActionSuccess } from 'src/app/store/actions';
import {
	addMockMaintenanceSuccessAction,
	loadMaintenancesPaginatedSuccessAction,
	resetMaintenancesStateSuccessAction
} from './maintenances.actions';
import {
	addMaintenanceSuccessAction,
	deleteMaintenanceSuccessAction,
	editMaintenanceSuccessAction,
	loadMaintenanceSuccessAction
} from './maintenances.actions';

export interface MaintenanceRequestsState extends EntityState<MaintenanceRequest> {
	isLoaded: boolean;
}
export const maintenancesAdapter = createEntityAdapter<MaintenanceRequest>();
const initialMaintenanceRequestState = maintenancesAdapter.getInitialState({
	isLoaded: false
});

export const MaintenanceRequestsReducers = createReducer(
	initialMaintenanceRequestState,
	on(resetMaintenancesStateSuccessAction, (state, payload) => (state = initialMaintenanceRequestState)),
	on(loadMaintenanceSuccessAction, (state, payload) =>
		maintenancesAdapter.upsertOne(payload.maintenance, { ...state, isLoaded: true })
	),
	on(addMaintenanceSuccessAction, (state, payload) => maintenancesAdapter.addOne(payload.maintenance, state)),
	on(editMaintenanceSuccessAction, (state, payload) =>
		maintenancesAdapter.updateOne({ id: payload.maintenance.id, changes: payload.maintenance }, state)
	),
	on(deleteMaintenanceSuccessAction, (state, payload) => maintenancesAdapter.removeOne(payload.maintenanceId, state)),
	on(addMockMaintenanceSuccessAction, (state, payload) => maintenancesAdapter.addOne(payload.maintenance, state)),

	// Version 2

	on(loadMaintenancesPaginatedSuccessAction, (state, payload) => {
		if (payload.maintenancesQueryResult) {
			return maintenancesAdapter.upsertMany(payload.maintenancesQueryResult.data, { ...state, isLoaded: true });
		} else {
			return state;
		}
	})
);
