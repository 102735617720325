import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/store/reducer';
import { CalendarState, currentCategoriesAdapter, currentEventsAdapter } from './calendar.reducers';

export const selectCalendar = createSelector(
	state => state,
	(state: AppState) => state.calendar
);
const selectCategoriesState = createSelector(selectCalendar, (state: CalendarState) => state.currentCategories);
const selectEventsState = createSelector(selectCalendar, (state: CalendarState) => state.currentEvents);

export const { selectAll: selectCalendarCategories } = currentCategoriesAdapter.getSelectors(selectCategoriesState);
export const { selectAll: selectCalendarEvents } = currentEventsAdapter.getSelectors(selectEventsState);
