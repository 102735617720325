import { translations } from 'src/locale/controller-translations';

declare const require;

export class LocalizationUtils {
	public static getTranslations() {
		return translations;
	}

	public static getLanguage(): 'it' | 'en' {
		return localStorage.getItem('locale') as 'it' | 'en';
	}
}
