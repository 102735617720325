import { Injectable } from '@angular/core';
import {
	ActivatedRoute,
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	CanLoad,
	Route,
	Router,
	RouterStateSnapshot,
	UrlSegment
} from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AppConstants } from 'src/app/utils/app-costants';
import { AuthService } from '../services/auth.service';
import { LandlordService, SectionsList } from '../services/landlord/landlord.service';
import { BillingService } from 'src/app/billing/billing.service';

@Injectable()
export class AuthGuardService implements CanActivate, CanLoad, CanActivateChild {
	constructor(
		private authService: AuthService,
		private landlordService: LandlordService,
		private readonly toastService: SnotifyService,
		private router: Router,
		private billingsService: BillingService
	) {}

	private isUserLoggedIn(): Observable<boolean> {
		return this.authService.getUserLoggedInObservable().pipe(filter(loggedIn => loggedIn !== null));
	}

	private isUserAuthorizedRead(section?: SectionsList): Observable<{ canAccess: boolean; blockedBy?: string }> {
		return combineLatest([
			this.landlordService.getLandlordUserData(),
			this.billingsService.canAccessSection(section),
			this.landlordService.canFeatureRead(section)
		]).pipe(
			map(([landlord, canAccessGlobal, canAccessUser]) => {
				if (section === SectionsList.PRICING) {
					if (landlord.role !== 'admin') {
						return { canAccess: false, blockedBy: 'pricing' };
					} else {
						return { canAccess: true };
					}
				}
				if (!canAccessGlobal) {
					return { canAccess: false, blockedBy: 'plan' };
				}
				if (!canAccessUser) {
					return { canAccess: false, blockedBy: 'permission' };
				}
				return { canAccess: true };
			})
		);
	}

	private isUserAuthorizedWrite(section?: SectionsList): Observable<boolean> {
		return this.landlordService.canFeatureWrite(section);
	}

	canLoad(route: Route, segments: UrlSegment[]) {
		const section: SectionsList = this.getSectionFromRoute(route.path);

		return this.isUserLoggedIn().pipe(
			switchMap(userLoggeedIn => {
				if (!userLoggeedIn) {
					this.setPendingRouteAfterLogin(segments);
					return of(false);
				} else {
					// Check VIEW permission
					return this.canActivatePermissionCheckRead(section).pipe(
						map(canAccess => {
							return canAccess.canAccess;
						})
					);
				}
			})
		);
	}

	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.canActivate(childRoute, state);
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.isUserLoggedIn().pipe(
			switchMap(userLoggeedIn => {
				if (!userLoggeedIn) {
					this.authService.setPendingRouteToRedirect(state.url.toString());
					return of(false);
				} else {
					const section: SectionsList = this.getSectionFromRoute(state.url);

					if (state.url.includes('home')) {
						return this.canActivatePermissionCheckRead(section).pipe(
							map(canAccess => {
								return canAccess.canAccess;
							})
						);
					} else if (
						state.url.includes('/add') ||
						state.url.includes('/edit') ||
						state.url.includes('resolve') ||
						state.url.includes('invoices') ||
						state.url.includes('markAsPaid') ||
						state.url.includes('generate') ||
						state.url.includes('broadcast') ||
						state.url.includes('addEvent')
					) {
						// Check EDIT permission
						return this.canActivatePermissionCheckWrite(section);
					} else if (
						(section === SectionsList.PROPERTIES || section === SectionsList.TENANTS) &&
						(state.url.includes('balance') ||
							state.url.includes('payments') ||
							state.url.includes('margins'))
					) {
						// Check PAYMENTS-VIEW permission
						return this.canActivatePermissionCheckRead(SectionsList.PAYMENTS).pipe(
							map(canAccess => {
								return canAccess.canAccess;
							})
						);
					} else if (
						(section === SectionsList.PROPERTIES || section === SectionsList.TENANTS) &&
						state.url.includes('conversation')
					) {
						// Check CHAT-VIEW permission
						return this.canActivatePermissionCheckRead(SectionsList.CHAT).pipe(
							map(canAccess => {
								return canAccess.canAccess;
							})
						);
					} else if (
						section === SectionsList.CHANNEL_MANAGER &&
						(state.url.includes('sync') || state.url.includes('activate'))
					) {
						// Check CHANNEL_MANAGER-WRITE permission
						return this.canActivatePermissionCheckWrite(SectionsList.CHANNEL_MANAGER);
					} else {
						return of(true);
					}
				}
			})
		);
	}

	private canActivatePermissionCheckWrite(section: SectionsList) {
		// Check EDIT permission
		return this.isUserAuthorizedWrite(section).pipe(
			tap(userAuthorized => {
				if (!userAuthorized) {
					this.showPermissionErrorToastAndManageEventualRedirect(section);
				}
			})
		);
	}

	private canActivatePermissionCheckRead(section: SectionsList) {
		// Check EDIT permission
		return this.isUserAuthorizedRead(section).pipe(
			tap(userAuthorized => {
				if (!userAuthorized.canAccess) {
					this.showPermissionErrorToastAndManageEventualRedirect(section, userAuthorized.blockedBy);
				}
			})
		);
	}

	private async showPermissionErrorToastAndManageEventualRedirect(section: string, blockedBy?: string) {
		if (section !== SectionsList.METRICS) {
			if (blockedBy === 'plan') {
				this.landlordService
					.getLandlordUserData()
					.pipe(take(1))
					.subscribe(landlord => {
						if (landlord.role === 'admin') {
							this.toastService.warning(
								$localize`:@@allowed_sections_error_toast_desc:Upgrade your plan to access this feature`,
								$localize`:@@allowed_sections_error_toast_title:Plans & Pricing`,
								{
									...AppConstants.TOAST_STD_CONFIG,
									timeout: 5000
								}
							);
							this.router.navigate(['/pricing']);
						} else {
							this.toastService.warning(
								$localize`:@@allowed_sections_multiuser_error_toast_desc:Speak to your administrator to upgrade your plan to access this feature`,
								$localize`:@@allowed_sections_error_toast_title:Plans & Pricing`,
								{
									...AppConstants.TOAST_STD_CONFIG,
									timeout: 5000
								}
							);
						}
					});
			} else if (blockedBy === 'pricing') {
				this.toastService.error(
					$localize`:@@permissions_pricing_error_toast_desc:Only the Administrator can access this page`,
					$localize`:@@permissions_error_toast_title:Access Denied`,
					{
						...AppConstants.TOAST_STD_CONFIG,
						timeout: 5000
					}
				);
			} else {
				this.toastService.error(
					$localize`:@@permissions_error_toast_desc:Please contact your administrator to request permission`,
					$localize`:@@permissions_error_toast_title:Access Denied`,
					{
						...AppConstants.TOAST_STD_CONFIG,
						timeout: 5000
					}
				);
			}
		}

		// This is to manage forced landing into a specific section by specifying it in the URL (i.e. typing /tenants in the browser address bar)
		// Without this being handled this way, the guard would redirect them to /, and not /home => they would see a blank page, while Estelle is actually loaded behind the scenes
		// We redirect them to /home as that component is never supposed to have managed permissions
		// Moreover, we abstract the '/home' concept to a more generic "accessible section" concept, basing on the user permissions

		const redirectionPath = await this.getDefaultRedirectionPathIfNoPermissionToAccessRequestedPath();
		if (this.router.url === '/' || section === SectionsList.METRICS) {
			this.router.navigate([redirectionPath]);
		}
	}

	private async getDefaultRedirectionPathIfNoPermissionToAccessRequestedPath() {
		// Check permissions to access homepage => if not allowed, check for other permissions in series of priority

		const canAccessHome = await this.isUserAuthorizedRead(SectionsList.METRICS).pipe(take(1)).toPromise();
		if (canAccessHome.canAccess) {
			return '/home';
		} else {
			const canAccessProperties = await this.isUserAuthorizedRead(SectionsList.PROPERTIES)
				.pipe(take(1))
				.toPromise();
			if (canAccessProperties.canAccess) {
				return '/properties';
			} else {
				const canAccessTenants = await this.isUserAuthorizedRead(SectionsList.TENANTS)
					.pipe(take(1))
					.toPromise();
				if (canAccessTenants.canAccess) {
					return '/tenants';
				} else {
					const canAccessAvailability = await this.isUserAuthorizedRead(SectionsList.AVAILABILITY)
						.pipe(take(1))
						.toPromise();
					if (canAccessAvailability.canAccess) {
						return '/availability';
					} else {
						const canAccessOwners = await this.isUserAuthorizedRead(SectionsList.OWNERS)
							.pipe(take(1))
							.toPromise();
						if (canAccessOwners.canAccess) {
							return '/owners';
						} else {
							const canAccessPayments = await this.isUserAuthorizedRead(SectionsList.PAYMENTS)
								.pipe(take(1))
								.toPromise();
							if (canAccessPayments.canAccess) {
								return '/payments';
							} else {
								const canAccessMaintenances = await this.isUserAuthorizedRead(SectionsList.MAINTENANCES)
									.pipe(take(1))
									.toPromise();
								if (canAccessMaintenances.canAccess) {
									return '/maintenances';
								} else {
									const canAccessCalendar = await this.isUserAuthorizedRead(SectionsList.CALENDAR)
										.pipe(take(1))
										.toPromise();
									if (canAccessCalendar.canAccess) {
										return '/calendar';
									} else {
										const canAccessChat = await this.isUserAuthorizedRead(SectionsList.CHAT)
											.pipe(take(1))
											.toPromise();
										if (canAccessChat.canAccess) {
											return '/chat/private';
										} else {
											const canAccessChannelManager = await this.isUserAuthorizedRead(
												SectionsList.CHANNEL_MANAGER
											)
												.pipe(take(1))
												.toPromise();
											if (canAccessChannelManager.canAccess) {
												return '/channelmanager';
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		// It should never reach this point
		return '/home';
	}

	private setPendingRouteAfterLogin(segments: UrlSegment[]) {
		this.authService.setPendingRouteToRedirect(
			segments.reduce((path, currentSegment) => {
				return `${path}/${currentSegment.path}`;
			}, '')
		);
	}

	private getSectionFromRoute(path: string): SectionsList | undefined {
		if (path.includes('home')) {
			return SectionsList.METRICS;
		} else if (path.includes(SectionsList.PROPERTIES) || path.includes('reports')) {
			return SectionsList.PROPERTIES;
		} else if (path.includes(SectionsList.TENANTS)) {
			return SectionsList.TENANTS;
		} else if (
			path.includes(SectionsList.AVAILABILITY) ||
			path.includes('leases') ||
			path.includes('lease/') ||
			path.includes('bookings')
		) {
			return SectionsList.AVAILABILITY;
		} else if (path.includes(SectionsList.OWNERS)) {
			return SectionsList.OWNERS;
		} else if (path.includes(SectionsList.PAYMENTS) || path.includes('wallet')) {
			return SectionsList.PAYMENTS;
		} else if (path.includes(SectionsList.MAINTENANCES)) {
			return SectionsList.MAINTENANCES;
		} else if (path.includes(SectionsList.CALENDAR)) {
			return SectionsList.CALENDAR;
		} else if (path.includes(SectionsList.CHAT)) {
			return SectionsList.CHAT;
		} else if (path.includes(SectionsList.CHANNEL_MANAGER.replace('_', ''))) {
			return SectionsList.CHANNEL_MANAGER;
		} else if (path.includes(SectionsList.PRICING)) {
			return SectionsList.PRICING;
		}

		return undefined;
	}
}
