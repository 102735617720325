import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/store/reducer';
import { bookingsAdapter } from './bookings.reducers';

export const selectBookingsState = createSelector(
	state => state,
	(state: AppState) => state.bookings
);

export const { selectTotal: selectBookingsLength } = bookingsAdapter.getSelectors(selectBookingsState);

export const selectBookingsEntities = createSelector(selectBookingsState, state => {
	if (state.isLoaded) {
		return state.entities;
	}
	return null;
});
