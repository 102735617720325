import { createAction, props } from '@ngrx/store';
import { FilterContainer, SortEvent } from 'src/app/core/services/filters/filters.model';
import { LeaseCreateResult } from 'src/app/leases/state/lease.effects';
import { QueryResult } from 'src/app/models/common';
import { MaintenanceRequest } from 'src/app/models/maintenanceRequest.model';
import { Property } from 'src/app/models/property.model';
import { Tenant } from 'src/app/models/tenant.model';
import { PropertyCreationResult } from 'src/app/properties/state/properties.effects';
import { CallbackFunctions } from 'src/app/store/reducer';

export enum MaintenanceActions {
	//Effect Actions
	LOAD_MAINTENANCE_BY_ID = '[Maintenance] Get One MaintenanceRequest By ID',
	ADD_MAINTENANCE = '[Maintenance] Add Maintenance',
	EDIT_MAINTENANCE = '[Maintenance] Edit Maintenance',
	DELETE_MAINTENANCE = '[Maintenance] Delete Maintenance',
	DELETE_MAINTENANCE_BY_ID = '[Maintenance] Delete MaintenanceRequest by id',
	DELETE_MAINTENANCES = '[Maintenance] Delete Maintenances',
	LOAD_MAINTENANCES = '[Maintenance] Load Maintenances',
	RESET_MAINTENANCES_STATE = '[Maintenance] Reset Maintenances State',
	ADD_MOCK_MAINTENANCE = '[Maintenance] Add Mock Maintenance',
	// Version 2
	LOAD_MAINTENANCES_PAGINATED = '[Maintenance] Load Maintenances Paginated',

	// Reducer Success Actions
	LOAD_MAINTENANCE_SUCCESS = '[Maintenance] Load MaintenanceRequest Success',
	ADD_MAINTENANCE_SUCCESS = '[Maintenance] Add MaintenanceRequest Success',
	EDIT_MAINTENANCE_SUCCESS = '[Maintenance] Edit MaintenanceRequest Success',
	DELETE_MAINTENANCE_SUCCESS = '[Maintenance] Delete MaintenanceRequest Success',
	DELETE_MAINTENANCES_SUCCESS = '[Maintenance] Delete Maintenances Success',
	LOAD_MAINTENANCES_SUCCESS = '[Maintenance] Load Maintenances Success',
	RESET_MAINTENANCES_STATE_SUCCESS = '[Maintenance] Reset Maintenances State Success',
	ADD_MOCK_MAINTENANCE_SUCCESS = '[Maintenance] Add Mock MaintenanceRequest Success',
	// Version 2
	LOAD_MAINTENANCES_PAGINATED_SUCCESS = '[Maintenance] Load Maintenances Paginated Success',

	// Reducer Failure actions
	LOAD_MAINTENANCE_FAILURE = '[Maintenance] Load MaintenanceRequest Failure',
	ADD_MAINTENANCE_FAILURE = '[Maintenance] Add MaintenanceRequest Failure',
	EDIT_MAINTENANCE_FAILURE = '[Maintenance] Edit MaintenanceRequest Failure',
	DELETE_MAINTENANCE_FAILURE = '[Maintenance] Delete MaintenanceRequest Failure',
	DELETE_MAINTENANCES_FAILURE = '[Maintenance] Delete Maintenances Failure',
	LOAD_MAINTENANCES_FAILURE = '[Maintenance] Load Maintenances Failure',
	RESET_MAINTENANCES_STATE_FAILURE = '[Maintenance] Reset Maintenances State Failure',
	// Version 2
	LOAD_MAINTENANCES_PAGINATED_FAILURE = '[Maintenance] Load Maintenances Paginated Failure',

	INVALIDATE_MAINTENANCE_PAGES = '[Maintenance] Invalidate Maintenance Pages'
}

// Effect Actions
export const loadMaintenanceByIdAction = createAction(MaintenanceActions.LOAD_MAINTENANCE_BY_ID, props<{ maintenanceId: string; options?: any }>());
export const addMaintenanceAction = createAction(MaintenanceActions.ADD_MAINTENANCE, props<{ maintenance: MaintenanceRequest; options?: any }>());
export const editMaintenanceAction = createAction(MaintenanceActions.EDIT_MAINTENANCE, props<{ maintenance: MaintenanceRequest; options?: any }>());
export const deleteMaintenanceByIdAction = createAction(MaintenanceActions.DELETE_MAINTENANCE, props<{ maintenanceId: string; isBulk: boolean; callbacks?: CallbackFunctions }>());
export const deleteMaintenancesAction = createAction(MaintenanceActions.DELETE_MAINTENANCES, props<{ maintenanceIds: string[]; callbacks?: CallbackFunctions }>());
export const addMockMaintenanceAction = createAction(MaintenanceActions.ADD_MOCK_MAINTENANCE, props<{ callbacks?: CallbackFunctions }>());
// Version 2
export const loadMaintenancesPaginatedAction = createAction(MaintenanceActions.LOAD_MAINTENANCES_PAGINATED, props<{ page: number; pageSize: number; filters: FilterContainer; sort: SortEvent }>());

// Reducer Success Actions
export const loadMaintenanceSuccessAction = createAction(MaintenanceActions.LOAD_MAINTENANCE_SUCCESS, props<{ maintenance: MaintenanceRequest }>());
export const addMaintenanceSuccessAction = createAction(MaintenanceActions.ADD_MAINTENANCE_SUCCESS, props<{ maintenance: MaintenanceRequest }>());
export const editMaintenanceSuccessAction = createAction(MaintenanceActions.EDIT_MAINTENANCE_SUCCESS, props<{ maintenance: MaintenanceRequest }>());
export const deleteMaintenanceSuccessAction = createAction(MaintenanceActions.DELETE_MAINTENANCE_SUCCESS, props<{ maintenanceId: string }>());
export const deleteMaintenancesSuccessAction = createAction(MaintenanceActions.DELETE_MAINTENANCES_SUCCESS);
export const addMockMaintenanceSuccessAction = createAction(MaintenanceActions.ADD_MOCK_MAINTENANCE_SUCCESS, props<{ maintenance: MaintenanceRequest; leases: LeaseCreateResult[]; property?: PropertyCreationResult }>());
// Version 2
export const loadMaintenancesPaginatedSuccessAction = createAction(MaintenanceActions.LOAD_MAINTENANCES_PAGINATED_SUCCESS, props<{ page: number; pageSize: number; maintenancesQueryResult: QueryResult<MaintenanceRequest> }>());

// Reducer Failure Actions
export const loadMaintenanceFailureAction = createAction(MaintenanceActions.LOAD_MAINTENANCE_FAILURE, props<{ error?: any }>());
export const addMaintenanceFailureAction = createAction(MaintenanceActions.ADD_MAINTENANCE_FAILURE, props<{ errorName: string; error?: any }>());
export const editMaintenanceFailureAction = createAction(MaintenanceActions.EDIT_MAINTENANCE_FAILURE, props<{ error?: any }>());
export const deleteMaintenanceFailureAction = createAction(MaintenanceActions.DELETE_MAINTENANCE_FAILURE, props<{ isBulk: boolean }>());
export const deleteMaintenancesFailureAction = createAction(MaintenanceActions.DELETE_MAINTENANCES_FAILURE, props<{ error?: any }>());
export const resetMaintenancesStateAction = createAction(MaintenanceActions.RESET_MAINTENANCES_STATE);
export const resetMaintenancesStateSuccessAction = createAction(MaintenanceActions.RESET_MAINTENANCES_STATE_SUCCESS);
export const resetMaintenancesStateFailureAction = createAction(MaintenanceActions.RESET_MAINTENANCES_STATE_FAILURE, props<{ error?: any }>());
// Version 2
export const loadMaintenancesPaginatedFailureAction = createAction(MaintenanceActions.LOAD_MAINTENANCES_PAGINATED_FAILURE, props<{ error?: any }>());
export const invalidateMaintenancePagesAction = createAction(MaintenanceActions.INVALIDATE_MAINTENANCE_PAGES);
