import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';
import {
	addMockLeasesSuccessAction,
	createLeaseSuccessAction,
	removeLeaseSuccessAction,
	updateLeaseSuccessAction
} from 'src/app/leases/state/lease.actions';
import { addMockMaintenanceSuccessAction } from 'src/app/maintenances/state/maintenances.actions';
import { deletePropertySuccessAction, editPropertySuccessAction } from 'src/app/properties/state/properties.actions';
import { deleteTenantSuccessAction } from 'src/app/tenants/state/tenant.actions';
import {
	createPaymentOperationSuccessAction,
	createPaymentSuccessAction,
	deletePaymentOperationSuccessAction,
	deletePaymentsSuccessAction,
	invalidatePaymentPagesAction,
	loadPaymentByIdSuccessAction,
	lockPaymentsSuccessAction,
	updatePaymentSuccessAction
} from './payments.actions';

@Injectable()
export class PaymentsPageEffects {
	constructor(private readonly actions$: Actions) {}

	public invalidatePaymentsPageEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(
				deleteTenantSuccessAction,
				deletePropertySuccessAction,
				updateLeaseSuccessAction,
				removeLeaseSuccessAction,
				editPropertySuccessAction,
				lockPaymentsSuccessAction,
				createLeaseSuccessAction,
				updateLeaseSuccessAction,
				editPropertySuccessAction,
				createPaymentSuccessAction,
				loadPaymentByIdSuccessAction,
				updatePaymentSuccessAction,
				createPaymentOperationSuccessAction,
				deletePaymentOperationSuccessAction,
				addMockLeasesSuccessAction,
				addMockMaintenanceSuccessAction,
				deletePaymentsSuccessAction
			),
			debounceTime(5000),
			mergeMap(() => {
				return of(invalidatePaymentPagesAction());
			})
		)
	);
}
