import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { MultipleInvoiceResponse } from 'src/app/core/services/invoicing.service';
import { PaymentMetricsData } from 'src/app/core/services/metrics/metrics.reducers';
import {
	addMockLeasesSuccessAction,
	createLeaseSuccessAction,
	removeLeaseSuccessAction,
	updateLeaseSuccessAction
} from 'src/app/leases/state/lease.actions';
import { addMockMaintenanceSuccessAction } from 'src/app/maintenances/state/maintenances.actions';
import { GenericInvoice, PropertyPayment } from 'src/app/models/payments';
import { deletePropertySuccessAction, editPropertySuccessAction } from 'src/app/properties/state/properties.actions';
import { SettingsPayment } from 'src/app/settings/settings-payment/settings-payment.component';
import { deleteTenantSuccessAction } from 'src/app/tenants/state/tenant.actions';
import {
	createPaymentInvoicesSuccess,
	createPaymentOperationSuccessAction,
	createPaymentSuccessAction,
	deletePaymentOperationSuccessAction,
	deletePaymentsSuccessAction,
	deletePaymentSuccessAction,
	loadOpenPaymentsByTenantSuccessAction,
	loadPaymentByIdSuccessAction,
	loadPaymentsByDateAndPropertiesSuccessAction,
	loadPaymentsByOwnerPlusConnectedRentsSuccessAction,
	loadPaymentsByOwnerSuccessAction,
	loadPaymentsByPropertySuccessAction,
	loadPaymentSettingsSuccessAction,
	loadPaymentsPaginatedSuccessAction,
	lockPaymentsSuccessAction,
	resetPaymentDatabaseViewSuccessAction,
	resetPaymentsStateSuccessAction,
	updatePaymentSettingSuccessAction,
	updatePaymentSuccessAction
} from './payments.actions';

export interface PaymentsMetricsState {
	settings: SettingsPayment;
}

export interface PaymentsState extends EntityState<PropertyPayment> {
	isLoaded: boolean;
}

export const paymentsAdapter = createEntityAdapter<PropertyPayment>();

export const initialPaymentState = paymentsAdapter.getInitialState({
	isLoaded: false
});

export const paymentsInitialState: PaymentsMetricsState = {
	settings: null
};

const deleteMultiplePaymentsState = (state: PaymentsState, deletedPaymentIds: string[]) => {
	const paymentToDelete: string[] = [];

	deletedPaymentIds?.forEach(paymentId => {
		if (state.entities[paymentId]) {
			paymentToDelete.push(paymentId);
		}
	});

	return paymentsAdapter.removeMany(paymentToDelete, state);
};

const updateMultiplePaymentsState = (state: PaymentsState, payments: PropertyPayment[]) => {
	const paymentToUpdate: PropertyPayment[] = [];

	payments?.forEach(paymentId => {
		if (state.entities[paymentId.id]) {
			paymentToUpdate.push(paymentId);
		}
	});

	return paymentsAdapter.upsertMany(paymentToUpdate, state);
};

const updatePaymentsWithInvoices = (state: PaymentsState, payload: { invoices: MultipleInvoiceResponse }) => {
	const paymentIds = Object.keys(payload.invoices).filter(it => payload.invoices[it].status !== 'error');

	const payments = state.entities;

	const intersectionIds = paymentIds.filter(paymentId => !!payments.entities[paymentId]); // Only payments into the container

	return paymentsAdapter.updateMany(
		intersectionIds.map(paymentId => {
			const oldPayment = payments.entities[paymentId];
			const invoice = payload.invoices[paymentId] as GenericInvoice;

			return {
				id: paymentId,
				changes: {
					...oldPayment,
					invoices: {
						...oldPayment.invoices,
						[invoice.type]: invoice
					}
				}
			};
		}),
		state
	);
};

const updateSinglePaymentState = (state: PaymentsState, payload) => {
	const payments = state.entities;

	if (Object.values(payments).filter(payment => payment.id === payload.payment.id)) {
		return paymentsAdapter.upsertOne(payload.payment, { ...state, isLoaded: true });
	}

	return state;
};

export const PaymentsReducers = createReducer(
	initialPaymentState,
	on(loadPaymentsPaginatedSuccessAction, (state, payload) => {
		if (payload.paymentsQueryResult) {
			return paymentsAdapter.upsertMany(payload.paymentsQueryResult.data, { ...state, isLoaded: true });
		} else {
			return state;
		}
	}),
	on(
		loadOpenPaymentsByTenantSuccessAction,
		loadPaymentsByPropertySuccessAction,
		loadPaymentsByOwnerSuccessAction,
		loadPaymentsByOwnerPlusConnectedRentsSuccessAction,
		loadPaymentsByDateAndPropertiesSuccessAction,
		(state, payload) => {
			if (payload.paymentsQueryResult) {
				return paymentsAdapter.upsertMany(payload.paymentsQueryResult.data, { ...state, isLoaded: true });
			} else {
				return state;
			}
		}
	),
	on(resetPaymentsStateSuccessAction, (state, action) => (state = initialPaymentState)),
	on(
		deleteTenantSuccessAction,
		deletePropertySuccessAction,
		updateLeaseSuccessAction,
		removeLeaseSuccessAction,
		editPropertySuccessAction,
		deletePaymentsSuccessAction,
		(state, payload) => {
			if (payload.deletedPaymentIds) {
				return deleteMultiplePaymentsState(state, payload.deletedPaymentIds);
			} else {
				return state;
			}
		}
	),
	on(
		lockPaymentsSuccessAction,
		createLeaseSuccessAction,
		updateLeaseSuccessAction,
		editPropertySuccessAction,
		(state, payload) => {
			if (payload.payments) {
				return updateMultiplePaymentsState(state, payload.payments);
			} else {
				return state;
			}
		}
	),
	on(createPaymentInvoicesSuccess, (state, payload) => {
		return updatePaymentsWithInvoices(state, payload);
	}),
	on(
		createPaymentSuccessAction,
		loadPaymentByIdSuccessAction,
		updatePaymentSuccessAction,
		createPaymentOperationSuccessAction,
		deletePaymentOperationSuccessAction,
		(state, payload) => {
			return updateSinglePaymentState(state, payload);
		}
	),
	on(deletePaymentSuccessAction, (state, payload) => {
		return paymentsAdapter.removeOne(payload.paymentId, state);
	}),
	on(addMockLeasesSuccessAction, addMockMaintenanceSuccessAction, (state, payload) => {
		let payments = [];
		payload.leases.forEach(lResult => {
			payments.push(lResult.payments);
		});
		if (payments.length > 0) {
			return updateMultiplePaymentsState(state, payments);
		} else {
			return state;
		}
	})
);

export const PaymentsReducer = createReducer(
	paymentsInitialState,
	on(
		resetPaymentsStateSuccessAction,
		resetPaymentDatabaseViewSuccessAction,
		(state, action) => (state = paymentsInitialState)
	),
	on(updatePaymentSettingSuccessAction, (state, payload) => (state = { ...state, settings: payload.setting })),
	on(loadPaymentSettingsSuccessAction, (state, payload) => (state = { ...state, settings: payload.settings }))
);
