import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { filtersDiffer } from 'src/app/core/services/filters/filters.service';
import { publishNewFiltersAction } from 'src/app/core/services/filters/state/filters.actions';
import {
	addMockLeasesSuccessAction,
	createLeaseSuccessAction,
	loadLeaseByIdSuccessAction,
	removeLeaseSuccessAction,
	updateLeaseSuccessAction
} from 'src/app/leases/state/lease.actions';
import { addMockMaintenanceSuccessAction } from 'src/app/maintenances/state/maintenances.actions';
import { TenantPageModelStore } from 'src/app/models/tenant.model';
import {
	deletePropertySuccessAction,
	loadPropertiesBasicSuccessAction,
	loadPropertyByIdSuccessAction
} from 'src/app/properties/state/properties.actions';
import {
	addTenantSuccessAction,
	archiveTenantSuccessAction,
	deleteTenantSuccessAction,
	editTenantSuccessAction,
	loadTenantsPaginatedSuccessAction,
	loadTenantsSuccessAction,
	loadTenantSuccessAction,
	recoverTenantSuccessAction,
	resetTenantStateSuccessAction,
	uploadMultipleTenantsSuccessAction
} from './tenant.actions';

export interface TenantPagesState extends EntityState<TenantPageModelStore> {
	isLoaded: boolean;
}
export const tenantPagesAdapter = createEntityAdapter<TenantPageModelStore>({
	selectId: model => model.id
});
const initialTenantPagesState = tenantPagesAdapter.getInitialState({
	isLoaded: false
});

export const TenantPagesReducers = createReducer(
	initialTenantPagesState,

	// Load pages

	on(loadTenantsPaginatedSuccessAction, (state, payload) => {
		if (payload.tenantsQueryResult) {
			return tenantPagesAdapter.upsertOne(
				{
					id: payload.page,
					tenantIds: payload.tenantsQueryResult.data.map(tenant => tenant.id),
					totalItems: payload.tenantsQueryResult.metadata.totalItems || 0,
					filteredItems: payload.tenantsQueryResult.metadata.filteredItems || 0
				},
				{ ...state, isLoaded: true }
			);
		} else {
			return state;
		}
	}),

	// Filters user actions

	on(publishNewFiltersAction, (state, payload) => {
		if (payload.filters.type === 'tenants' && (payload.filters.filters || payload.filters.sort)) {
			return initialTenantPagesState;
		} else {
			return state;
		}
	}),

	// Tenant actions (user actions + realtime updates)

	// ALL pages invalidation

	on(
		resetTenantStateSuccessAction,

		loadTenantSuccessAction,

		addTenantSuccessAction,
		editTenantSuccessAction,

		recoverTenantSuccessAction,
		uploadMultipleTenantsSuccessAction,

		(state, payload) => ({ ...initialTenantPagesState, isLoaded: true })
	),

	on(loadTenantsSuccessAction, loadPropertiesBasicSuccessAction, (state, payload) => {
		if (payload['refreshAll']) {
			return { ...initialTenantPagesState, isLoaded: true };
		} else {
			return state;
		}
	}),

	on(addMockLeasesSuccessAction, addMockMaintenanceSuccessAction, (state, payload) => {
		let tenants = [];
		payload.leases.forEach(lResult => {
			if (lResult.tenant) {
				tenants.push(lResult.tenant);
			}
		});

		if (tenants.length > 0) {
			return { ...initialTenantPagesState, isLoaded: true };
		} else {
			return state;
		}
	}),

	// SOME pages invalidation

	on(deleteTenantSuccessAction, (state, payload) => {
		const allPages = Object.values(state.entities).sort((a, b) => a.id - b.id);
		let minDirtyPage = -1;
		for (let i = 0; i < allPages.length; i++) {
			if (allPages[i].tenantIds.includes(payload.deletedTenantId)) {
				minDirtyPage = allPages[i].id;
				break;
			}
		}

		if (minDirtyPage >= 0) {
			return tenantPagesAdapter.removeMany(it => it.id >= minDirtyPage, state);
		} else {
			return state;
		}
	}),

	on(archiveTenantSuccessAction, (state, payload) => {
		const allPages = Object.values(state.entities).sort((a, b) => a.id - b.id);
		let minDirtyPage = -1;
		for (let i = 0; i < allPages.length; i++) {
			if (allPages[i].tenantIds.includes(payload.tenant.id)) {
				minDirtyPage = allPages[i].id;
				break;
			}
		}

		if (minDirtyPage >= 0) {
			return tenantPagesAdapter.removeMany(it => it.id >= minDirtyPage, state);
		} else {
			return state;
		}
	}),

	// Property actions (user actions + realtime updates)

	// ALL pages invalidation

	on(
		loadPropertyByIdSuccessAction,

		deletePropertySuccessAction,

		(state, payload) => ({ ...initialTenantPagesState, isLoaded: true })
	),

	// Lease actions (user actions + realtime updates)

	// ALL pages invalidation

	on(
		loadLeaseByIdSuccessAction,

		createLeaseSuccessAction,
		removeLeaseSuccessAction,
		updateLeaseSuccessAction,

		(state, payload) => ({ ...initialTenantPagesState, isLoaded: true })
	)
);
