import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/store/reducer';
import { tenantPagesAdapter } from './tenants-pages.reducers';

const selectTenantPagesState = createSelector(
	state => state,
	(state: AppState) => state.tenantPages
);

export const { selectTotal: selectTenantPagesLength } = tenantPagesAdapter.getSelectors(selectTenantPagesState);

export const selectTenantPagesEntities = createSelector(selectTenantPagesState, state => {
	if (state.isLoaded) {
		return state.entities;
	}
	return null;
});
