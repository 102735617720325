import { Injectable } from '@angular/core';
import { MinimumUserData } from 'src/app/models/common';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LandlordService } from './landlord/landlord.service';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { updateOnboardingDataAction } from './user/state/user.actions';
import { ProductTourComponentName } from 'src/app/models/intercom.model';

@Injectable({
	providedIn: 'root'
})
export class UsersService {
	private landlordId: string;
	private BACKEND_HOST = `${environment.services.backend}/api-dash/v1`;

	constructor(
		private readonly landlordService: LandlordService,
		private readonly httpClient: HttpClient,
		private readonly store: Store
	) {
		this.landlordService.getLandlordId().subscribe(id => (this.landlordId = id));
	}

	getAllLinkedUsers(): Observable<MinimumUserData[]> {
		return this.httpClient.get<MinimumUserData[]>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/users`);
	}

	editUser(user: MinimumUserData): Observable<MinimumUserData> {
		return this.httpClient.post<MinimumUserData>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/users/edit`, {
			user: user
		});
	}

	removeUser(userId: string): Observable<void> {
		return this.httpClient.delete<void>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/users/${userId}`);
	}

	addUser(user: MinimumUserData): Observable<MinimumUserData> {
		return this.httpClient.post<MinimumUserData>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/users/add`, {
			user: user
		});
	}

	updateOnboardingData(userId: string, componentName: ProductTourComponentName): void {
		return this.store.dispatch(updateOnboardingDataAction({ userId, componentName }));
	}
}
