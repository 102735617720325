import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Property } from 'src/app/models/property.model';
import { UnitStatus } from 'src/app/models/channelManager.model';
import {
	loadChannelManagerSyncUnitsPaginatedSuccessAction,
	loadChannelManagerUnitsPaginatedSuccessAction
} from 'src/app/properties/state/properties.actions';

export interface ChannelManagerListingsState extends EntityState<UnitStatus> {
	isLoaded: boolean;
}
export const channelManagerListingsAdapter = createEntityAdapter<UnitStatus>({
	selectId: model => model.unitId
});
const initialChannelManagerListingsState = channelManagerListingsAdapter.getInitialState({
	isLoaded: false
});

export const ChannelManagerListingsReducer = createReducer(
	initialChannelManagerListingsState,

	on(
		loadChannelManagerUnitsPaginatedSuccessAction,
		loadChannelManagerSyncUnitsPaginatedSuccessAction,
		(state, payload) => {
			if (payload.listings) {
				return channelManagerListingsAdapter.upsertMany(payload.listings, { ...state, isLoaded: true });
			} else {
				return state;
			}
		}
	)
);
