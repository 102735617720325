import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { LandlordService } from '../core/services/landlord/landlord.service';
import { TaxInfo } from '../models/payments';
import { AppConstants } from '../utils/app-costants';

@Injectable({
	providedIn: 'root'
})
export class TaxService {
	private customVatInfo: { [key: string]: TaxInfo } = {};
	private currentCountry = 'it';
	private commonVatInfo = AppConstants.taxValueList;

	constructor(private readonly landlordService: LandlordService) {
		this.landlordService
			.getLandlordData()
			.pipe(filter(landlordData => !!landlordData.profileInfo))
			.subscribe(landlord => {
				this.customVatInfo = landlord.customVAT || {};
				this.currentCountry = (
					landlord?.invoiceData?.countryCode ||
					landlord.profileInfo?.country ||
					''
				).toLowerCase();
				this.commonVatInfo = AppConstants.taxValueList.filter(
					vatInfo => !vatInfo.exlusiveOf || vatInfo.exlusiveOf?.indexOf(this.currentCountry) > -1
				);
			});
	}

	/* The landlord can assign default values for tax info on payment items */
	getCustomVat(): { [key: string]: TaxInfo } {
		return this.customVatInfo;
	}

	/* Depending on the country, we retrieve the list to show the landlord.  */
	// This system needs to be improved and made more reliable. It should be possible to chose the country of Estelle
	getEnabledVatList(): TaxInfo[] {
		return this.commonVatInfo;
	}
}
