import { createSelector } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store/reducer';
import { PaymentsStoreModel } from '../filters.model';
import { filtersAdapter } from './filters.reducers';

const selectFiltersState = createSelector(
	state => state,
	(state: AppState) => state.filters
);

export const { selectTotal: selectFiltersLength } = filtersAdapter.getSelectors(selectFiltersState);

export const selectFiltersEntities = createSelector(selectFiltersState, state => {
	return state.entities;
});

export const selectMaintenancesFilters = createSelector(selectFiltersState, state => {
	return state.entities.maintenances;
});

export const selectPaymentsFilters = createSelector(selectFiltersState, state => {
	return state.entities.payments as PaymentsStoreModel;
});
