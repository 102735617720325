import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-dash-label-simple',
	templateUrl: './dash-label-simple.component.html',
	styleUrls: ['./dash-label-simple.component.scss']
})
export class DashLabelSimpleComponent implements OnInit {
	@Input() label: string;
	@Input() color = 'black';
	@Input() align = 'left';

	@Input() isBold = false;

	@Input() multiline = false;
	@Input() isTwoLines = false;

	@Input() maxHeight = false;

	@Input() suggestedHeight = '';

	constructor() {}

	ngOnInit() {
		if (this.suggestedHeight !== '') {
			this.suggestedHeight = this.suggestedHeight + 'px';
		}
	}
}
