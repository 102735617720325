import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { cloneDeep } from 'lodash-es';
import { Observable, of, throwError, zip } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { LandlordService } from 'src/app/core/services/landlord/landlord.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { EffectInteractionService } from 'src/app/core/services/effectInteraction.service';
import { CalendarCategory, CalendarEvent } from 'src/app/models/calendar';
import { LocalizationUtils } from 'src/app/utils/localization-utils';
import { environment } from 'src/environments/environment';
import { resetStoreAction } from '../../store/actions';
import {
	resetCalendarStateAction,
	resetCalendarStateSuccessAction,
	resetCalendarStateFailureAction
} from './calendar.actions';
import {
	CalendarCategoryActions,
	CalendarEventActions,
	createCategoryAction,
	createOrUpdateCategoryFailureAction,
	createCategorySuccessAction,
	createEventAction,
	createEventFailureAction,
	createEventSuccessAction,
	deleteCategoryAction,
	deleteCategoryFailureAction,
	deleteCategorySuccessAction,
	deleteEventAction,
	deleteEventFailureAction,
	deleteEventSuccessAction,
	fixCategoriesOrderAction,
	fixCategoriesOrderFailureAction,
	fixCategoriesOrderSuccessAction,
	loadCategoriesAction,
	loadCategoriesFailureAction,
	loadCategoriesSuccessAction,
	loadEventsFromDateIntervalAction,
	loadEventsFromDateIntervalFailureAction,
	loadEventsFromDateIntervalSuccessAction,
	updateCategoryAction,
	updateCategoryFailureAction,
	updateCategorySuccessAction,
	updateEventAction,
	updateEventFailureAction,
	updateEventSuccessAction
} from './calendar.actions';
import { CalendarService } from '../calendar.service';

@Injectable()
export class CalendarEffects {
	private landlordId: string = '';
	private BACKEND_HOST = `${environment.services.backend}/api-dash/v1`;

	constructor(
		private readonly landlordService: LandlordService,
		private readonly actions$: Actions,
		private readonly httpClient: HttpClient,
		private readonly loadingService: LoadingService,
		private readonly calendarService: CalendarService,
		private readonly effectInteractionService: EffectInteractionService
	) {
		this.landlordService.getLandlordId().subscribe(landlordId => (this.landlordId = landlordId));
	}

	// Categories

	public resetStoreEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(resetStoreAction),
			switchMap(() => of(resetCalendarStateAction()))
		)
	);

	public resetCalendarEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(resetCalendarStateAction),
			switchMap(() => of(resetCalendarStateSuccessAction())),
			catchError(() => of(resetCalendarStateFailureAction({})))
		)
	);

	public loadCategoriesEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadCategoriesAction),
			switchMap(() =>
				this.loadCalendarCategories().pipe(
					map((categories: CalendarCategory[]) => loadCategoriesSuccessAction({ categories })),
					catchError(err => of(loadCategoriesFailureAction({})))
				)
			)
		)
	);

	public createCategoryEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(createCategoryAction, updateCategoryAction),
			mergeMap(action =>
				zip(this.createOrUpdateCalendarCategory(action.category), of(action)).pipe(
					map(([category, action]) => {
						if (action.type === CalendarCategoryActions.CREATE_CATEGORY)
							return createCategorySuccessAction({ category });
						else if (action.type === CalendarCategoryActions.UPDATE_CATEGORY)
							return updateCategorySuccessAction({ category });
					}),
					catchError(err => of(createOrUpdateCategoryFailureAction({})))
				)
			)
		);
	});

	public deleteCategoryEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(deleteCategoryAction),
			mergeMap(action =>
				this.deleteCalendarCategory(action.category).pipe(
					map(() => deleteCategorySuccessAction(action.category)),
					catchError(err => of(deleteCategoryFailureAction({})))
				)
			)
		);
	});

	public fixCategoriesOrderEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(fixCategoriesOrderAction),
			mergeMap(action =>
				this.fixCategoriesOrder(action.categories).pipe(
					map((categories: CalendarCategory[]) => fixCategoriesOrderSuccessAction({ categories })),
					catchError(err => of(fixCategoriesOrderFailureAction({})))
				)
			)
		)
	);

	// Events

	public loadEventsFromDateIntervalEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadEventsFromDateIntervalAction),
			mergeMap(action =>
				this.loadEventsFromDateInterval(action.startTime, action.endTime).pipe(
					map((events: CalendarEvent[]) => loadEventsFromDateIntervalSuccessAction({ events })),
					catchError(err => of(loadEventsFromDateIntervalFailureAction({})))
				)
			)
		)
	);

	public createEventEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(createEventAction, updateEventAction),
			mergeMap(action =>
				zip(this.createOrUpdateCalendarEvent(action.event), of(action)).pipe(
					map(([event, action]) => {
						if (action.type === CalendarEventActions.CREATE_EVENT)
							return createEventSuccessAction({ event });
						else if (action.type === CalendarEventActions.UPDATE_EVENT)
							return updateEventSuccessAction({ event });
					}),
					catchError(err => of(createEventFailureAction({})))
				)
			)
		);
	});

	public deleteEventEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(deleteEventAction),
			mergeMap(action =>
				this.removeEvent(action.event).pipe(
					map(() => deleteEventSuccessAction(action.event)),
					catchError(err => of(deleteEventFailureAction({})))
				)
			)
		);
	});

	/*** Effects handle success and failure actions  ***/
	public genericCalendarFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(
					createOrUpdateCategoryFailureAction,
					deleteCategoryFailureAction,
					createEventFailureAction,
					deleteEventFailureAction
				),
				tap(action => this.loadingService.hideAndDisplayErrorToast())
			),
		{ dispatch: false }
	);

	public silentCalendarFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(
					loadCategoriesFailureAction,
					loadEventsFromDateIntervalFailureAction,
					fixCategoriesOrderFailureAction,
					resetCalendarStateFailureAction
				),
				tap(action => this.effectInteractionService.logErrorSilently(action.type))
			),
		{ dispatch: false }
	);

	/***   ***/

	// Private calls
	private loadCalendarCategories(): Observable<CalendarCategory[]> {
		if (this.landlordId) {
			return this.httpClient
				.get<CalendarCategory[]>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/calendarCategories`)
				.pipe(
					map(it => {
						it.unshift(
							...this.calendarService
								.getSystemCategories()
								.filter(sysCat => it.findIndex(cat => cat.id === sysCat.id) < 0)
								.map(it => ({ ...it }))
						);
						return it.sort((a, b) => a.order - b.order);
					})
				);
		} else {
			return throwError('Error on loading eventsFromDateInterval. No landlordId is present');
		}
	}

	private createOrUpdateCalendarEvent(calendarEvent: CalendarEvent): Observable<CalendarEvent> {
		return this.httpClient.post<CalendarEvent>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/calendarEvents`, {
			event: calendarEvent
		});
	}

	private createOrUpdateCalendarCategory(cat: CalendarCategory): Observable<CalendarCategory> {
		return this.httpClient.post<CalendarCategory>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/calendarCategories`,
			{
				category: cat
			}
		);
	}

	private deleteCalendarCategory(cat: CalendarCategory): Observable<void> {
		return this.httpClient.delete<void>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/calendarCategories/${cat.id}`
		);
	}

	private fixCategoriesOrder(cats: CalendarCategory[]): Observable<CalendarCategory[]> {
		const result = {};

		const categoryClone = cloneDeep(cats);
		categoryClone.forEach((cat, index) => {
			cat.order = index;
			result[cat.id] = cat;
		});

		return this.httpClient.post<CalendarCategory[]>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/calendarCategories/fixOrder`,
			{ categories: result }
		);
	}

	private removeEvent(calendarEvent: CalendarEvent): Observable<void> {
		return this.httpClient.delete<void>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/calendarEvents/${calendarEvent.id}`
		);
	}

	private loadEventsFromDateInterval(startTime: Date, endTime: Date) {
		if (this.landlordId) {
			return this.httpClient.get<CalendarEvent[]>(
				`${this.BACKEND_HOST}/landlords/${this.landlordId}/calendarEvents`,
				{
					params: {
						startTime: startTime.getTime(),
						endTime: endTime.getTime()
					}
				}
			);
		} else {
			return throwError('Error on loading eventsFromDateInterval. No landlordId is present');
		}
	}
}
