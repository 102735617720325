import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SnotifyService } from 'ng-snotify';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { EffectInteractionService } from 'src/app/core/services/effectInteraction.service';
import {
	FilterContainer,
	SortEvent,
	SortQuery,
	TenantFilterKeys,
	TenantQueryFilters
} from 'src/app/core/services/filters/filters.model';
import { LandlordService } from 'src/app/core/services/landlord/landlord.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { MixpanelService } from 'src/app/core/services/mixpanel-service.service';
import { LeaseService } from 'src/app/leases/lease.service';
import { QueryResult } from 'src/app/models/common';
import { Tenant, TenantConnectionResponse } from 'src/app/models/tenant.model';
import { resetStoreAction } from 'src/app/store/actions';
import { AppConstants } from 'src/app/utils/app-costants';
import { AppUtils } from 'src/app/utils/app-utils';
import { LocalizationUtils } from 'src/app/utils/localization-utils';
import { environment } from 'src/environments/environment';
import { SendTenantAppInviteMultipleDialogComponent } from '../tenant-app/send-tenant-app-invite-multiple-dialog/send-tenant-app-invite-multiple-dialog.component';
import {
	addMockTenantAction,
	addTenantAction,
	addTenantFailureAction,
	addTenantSuccessAction,
	archiveTenantAction,
	archiveTenantFailureAction,
	archiveTenantSuccessAction,
	deleteTenantByIdAction,
	deleteTenantFailureAction,
	deleteTenantsAction,
	deleteTenantsFailureAction,
	deleteTenantsSuccessAction,
	deleteTenantSuccessAction,
	editTenantAction,
	editTenantFailureAction,
	editTenantSuccessAction,
	getTenantCsvStringAction,
	getTenantsCsvAction,
	getTenantCsvStringFailureAction,
	getTenantCsvStringSuccessAction,
	getTenantPlacesStatusAction,
	getTenantPlacesStatusFailureAction,
	getTenantPlacesStatusSuccessAction,
	loadArchivedTenantsAction,
	loadArchivedTenantsFailureAction,
	loadArchivedTenantsSuccessAction,
	loadTenantByIdAction,
	loadTenantFailureAction,
	loadTenantsAction,
	loadTenantsPaginatedAction,
	loadTenantsPaginatedFailureAction,
	loadTenantsPaginatedSuccessAction,
	loadTenantsSuccessAction,
	loadTenantSuccessAction,
	recoverTenantAction,
	recoverTenantFailureAction,
	recoverTenantSuccessAction,
	resetTenantStateAction,
	resetTenantStateFailureAction,
	resetTenantStateSuccessAction,
	sendPlacesInvitationAction,
	sendPlacesInvitationFailureAction,
	sendPlacesInvitationMultipleSuccessAction,
	sendPlacesInvitationSuccessAction,
	uploadMultipleTenantsAction,
	uploadMultipleTenantsFailureAction,
	uploadMultipleTenantsSuccessAction,
	getTenantsCsvSuccessAction,
	getTenantsCsvFailureAction
} from './tenant.actions';

export interface DeleteTenantResult {
	deletedTenantId: string;
	deletedLeaseIds?: string[];
	deletedPaymentIds?: string[];
	deletedCheckListIds?: string[];
	deletedBookingIds?: string[];
	deletedCalendarEventIds?: string[];
	deletedRecurringPaymentIds?: string[];
}
@Injectable()
export class TenantsEffects {
	private BACKEND_HOST = `${environment.services.backend}/api-dash/v1`;
	private BACKEND_TENANTS_HOST = `${environment.services.backend}/tenants/v1`;

	private landlordId: string;
	private translations = LocalizationUtils.getTranslations();

	constructor(
		private readonly analyticsService: MixpanelService,
		private readonly dialog: MatDialog,
		private readonly toastService: SnotifyService,
		private readonly landlordService: LandlordService,
		private readonly httpClient: HttpClient,
		private readonly actions$: Actions,
		private readonly leaseService: LeaseService,
		private readonly loadingService: LoadingService,
		private readonly effectInteractionService: EffectInteractionService,
		private readonly router: Router
	) {
		this.landlordService.getLandlordId().subscribe(landlordId => (this.landlordId = landlordId));
	}

	public resetStoreState = createEffect(() =>
		this.actions$.pipe(
			ofType(resetStoreAction),
			switchMap(() => of(resetTenantStateAction()))
		)
	);

	public resetTenantStateEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(resetTenantStateAction),
			switchMap(() => of(resetTenantStateSuccessAction())),
			catchError(() => of(resetTenantStateFailureAction))
		)
	);

	public loadTenantEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadTenantByIdAction),
			mergeMap(action =>
				this.loadTenant(action.tenantId, action.options, action.bypassPermissionsCheck).pipe(
					map(tenant => loadTenantSuccessAction({ tenant: Tenant.sanitize(tenant) })),
					catchError(err => of(loadTenantFailureAction({})))
				)
			)
		)
	);

	public addTenantEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(addTenantAction),
			distinctUntilChanged((a, b) => a.tenant.email === b.tenant.email),
			mergeMap(action =>
				this.addTenant(action.tenant, action.options).pipe(
					map(tenant => {
						this.effectInteractionService.callbackSuccess(action);
						return addTenantSuccessAction({ tenant });
					}),
					catchError(errorResponse => {
						this.effectInteractionService.callbackFail(action);
						return of(addTenantFailureAction({ errorName: errorResponse?.error?.name || '' }));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public editTenantEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(editTenantAction),
			mergeMap(action =>
				this.editTenant(action.tenant, action.bypassPermissionsCheck).pipe(
					map(tenant => {
						this.effectInteractionService.callbackSuccess(action);
						return editTenantSuccessAction({ tenant });
					}),
					catchError(err => {
						this.effectInteractionService.callbackFail(action);
						return of(editTenantFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public deleteTenantByIdEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(deleteTenantByIdAction),
			mergeMap(action =>
				this.deleteTenant(action.tenantId).pipe(
					map(deleteResult => {
						this.effectInteractionService.callbackSuccess(action);
						if (deleteResult) {
							// New version
							return deleteTenantSuccessAction(deleteResult);
						} else {
							// Old version
							return deleteTenantSuccessAction({
								deletedTenantId: action.tenantId,
								deletedPaymentIds: [],
								deletedLeaseIds: []
							});
						}
					}),
					catchError(() => {
						this.effectInteractionService.callbackFail(action);
						return of(deleteTenantFailureAction({ isBulk: action.isBulk }));
					}),
					tap(() => {
						this.effectInteractionService.callbackFinally(action);
					})
				)
			)
		);
	});

	public deleteTenantsEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(deleteTenantsAction),
			mergeMap(action =>
				this.deleteTenants(action.tenantIds).pipe(
					map(deleteResults => {
						if (deleteResults) {
							// New version
							this.effectInteractionService.callbackSuccess(action);
							return deleteTenantsSuccessAction({ deleteTenantResults: deleteResults });
						} else {
							// Old version
							const deleteTenantResults: DeleteTenantResult[] = action.tenantIds.map(tenantId => {
								return {
									deletedTenantId: tenantId,
									deletedPaymentIds: [],
									deletedLeaseIds: []
								};
							});
							this.effectInteractionService.callbackSuccess(action);
							return deleteTenantsSuccessAction({ deleteTenantResults });
						}
					}),
					catchError(() => {
						this.effectInteractionService.callbackFail(action);
						return of(deleteTenantsFailureAction({}));
					}),
					tap(() => {
						this.effectInteractionService.callbackFinally(action);
					})
				)
			)
		);
	});

	public loadTenantsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadTenantsAction),
			switchMap(action =>
				this.loadTenants().pipe(
					map(tenants => loadTenantsSuccessAction({ tenants: tenants, refreshAll: action.refreshAll })),
					catchError(err => of(loadTenantFailureAction({})))
				)
			)
		)
	);

	public archiveTenantEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(archiveTenantAction),
			mergeMap(action =>
				this.archiveTenant(action.tenant).pipe(
					map(successTenant => {
						this.effectInteractionService.callbackSuccess(action);
						return archiveTenantSuccessAction({ tenant: successTenant });
					}),
					catchError(() => {
						this.effectInteractionService.callbackFail(action);
						return of(archiveTenantFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public recoverTenantEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(recoverTenantAction),
			mergeMap(action =>
				this.recoverTenant(action.tenant).pipe(
					take(1),
					map(successTenant => {
						this.effectInteractionService.callbackSuccess(action);
						return recoverTenantSuccessAction({ tenant: successTenant });
					}),
					catchError(() => {
						this.effectInteractionService.callbackFail(action);
						return of(recoverTenantFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public uploadMultipleTenantsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(uploadMultipleTenantsAction),
			mergeMap(action =>
				this.uploadMultipleTenants(action.tenants).pipe(
					map(tenants => {
						this.effectInteractionService.callbackSuccess(action);
						return uploadMultipleTenantsSuccessAction({
							tenants: tenants.map(tenant => Tenant.sanitize(tenant))
						});
					}),
					catchError(() => {
						this.effectInteractionService.callbackFail(action);
						return of(uploadMultipleTenantsFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public sendPlacesInvitationEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(sendPlacesInvitationAction),
			mergeMap(action =>
				this.sendPlacesInvitation(action.tenantsId, action.invites).pipe(
					map(response =>
						action.tenantsId.length === 1
							? sendPlacesInvitationSuccessAction()
							: sendPlacesInvitationMultipleSuccessAction({ invitesSent: response.invitesSent })
					),
					catchError(err => of(sendPlacesInvitationFailureAction({})))
				)
			)
		)
	);

	public getTenantPlacesStatusEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(getTenantPlacesStatusAction),
			mergeMap(action =>
				this.getTenantPlacesStatus(action.tenantId).pipe(
					map(response =>
						getTenantPlacesStatusSuccessAction({
							appSetup: response.appSetup,
							paymentsSetup: response.paymentsSetup,
							inviteSentOn: response.inviteSentOn,
							inviteStatus: response.inviteStatus
						})
					),
					catchError(err => of(getTenantPlacesStatusFailureAction({})))
				)
			)
		)
	);

	public getTenantCsvStringEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(getTenantCsvStringAction),
			mergeMap(action =>
				this.getTenantCsvString().pipe(
					map(url => {
						return getTenantCsvStringSuccessAction({ url });
					}),
					catchError(() => of(getTenantCsvStringFailureAction({})))
				)
			)
		)
	);

	public getTenantsCsvEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(getTenantsCsvAction),
			mergeMap(action =>
				this.getTenantsCsv().pipe(
					map(url => {
						return getTenantsCsvSuccessAction({ url });
					}),
					catchError(() => of(getTenantsCsvFailureAction({})))
				)
			)
		)
	);

	public addMockTenantEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(addMockTenantAction),
			mergeMap(action =>
				this.generateMockTenant().pipe(
					map(tenant => {
						this.effectInteractionService.callbackSuccess(action);
						return addTenantSuccessAction({ tenant: Tenant.sanitize(tenant) });
					}),
					catchError(errorResponse => {
						this.effectInteractionService.callbackFail(action);
						return of(addTenantFailureAction({ errorName: errorResponse?.error?.name || '' }));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	// Version 2

	public loadTenantsPaginatedEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadTenantsPaginatedAction),
			switchMap(action =>
				this.loadTenantsPaginated(action.page, action.pageSize, action.filters, action.sort).pipe(
					map(tenants =>
						loadTenantsPaginatedSuccessAction({
							page: action.page,
							pageSize: action.pageSize,
							tenantsQueryResult: tenants
						})
					),
					catchError(err => of(loadTenantsPaginatedFailureAction({ error: err })))
				)
			)
		)
	);

	public loadArchivedTenantsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadArchivedTenantsAction),
			switchMap(() =>
				this.loadArchivedTenants().pipe(
					map(tenants =>
						loadArchivedTenantsSuccessAction({
							tenantsQueryResult: tenants
						})
					),
					catchError(err => of(loadArchivedTenantsFailureAction({ error: err })))
				)
			)
		)
	);

	/*** Effects handle success and failure actions  ***/
	public addTenantSuccessEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(addTenantSuccessAction),
				tap(action =>
					this.effectInteractionService.displaySuccessToast(
						this.translations.toast.tenant_added_title,
						this.translations.toast.tenant_added_msg,
						() => {
							this.router.navigate(['tenants', action.tenant.id]);
						}
					)
				)
			),
		{ dispatch: false }
	);

	public addTenantFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(addTenantFailureAction),
				tap(action => {
					// Generic error message
					let errorStr = $localize`:@@add_tenant_failed:Something went wrong during the creation of the tenant. Try again and if the problem persists, contact us.`;

					// Specific error message
					if (action.errorName === 'ExternalServiceFailedError') {
						errorStr = $localize`:@@add_tenant_failed_external_service:Something went wrong during an external service execution. Try again and if the problem persists, contact us.`;
					}

					this.effectInteractionService.showDialogError(errorStr);
				})
			),
		{ dispatch: false }
	);

	public editTenantSuccessEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(editTenantSuccessAction),
				tap(() =>
					this.effectInteractionService.displaySuccessToast(
						this.translations.toast.tenant_edit_title,
						this.translations.toast.tenant_edit_msg
					)
				)
			),
		{ dispatch: false }
	);

	public deleteTenantFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(deleteTenantFailureAction),
				tap(action => {
					if (action.isBulk) {
						this.loadingService.hideAndDisplayErrorToast();
					} else {
						this.loadingService.hide();
						this.effectInteractionService.showDialogError(
							$localize`:@@delete_tenant_failed:Something went wrong during the delete of the tenant. Try again and if the problem persists, contact us.`
						);
					}
				})
			),
		{ dispatch: false }
	);

	public deleteTenantsFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(deleteTenantsFailureAction),
				tap(action => {
					this.loadingService.hide();
					this.effectInteractionService.showDialogError(
						$localize`:@@delete_tenant_failed:Something went wrong during the delete of the tenants. Try again and if the problem persists, contact us.`
					);
				})
			),
		{ dispatch: false }
	);

	public recoverTenantSuccessEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(recoverTenantSuccessAction),
				tap(action =>
					this.effectInteractionService.displaySuccessToast(
						this.translations.toast.tenant_recovered_title,
						this.translations.toast.tenant_recovered_msg,
						() => {
							this.router.navigate(['tenants', action.tenant.id]);
						}
					)
				)
			),
		{ dispatch: false }
	);

	public trackUploadMultipleTenantsSuccessEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(uploadMultipleTenantsSuccessAction),
				tap(action =>
					this.analyticsService.track('import_tenants', {
						numTenants: action.tenants.length
					})
				)
			),
		{ dispatch: false }
	);
	public tenantEditFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(editTenantFailureAction),
				tap(() => {
					this.effectInteractionService.showDialogError(
						$localize`:@@edit_tenant_failed:Something went wrong during the update of the tenant. Try again and if the problem persists, contact us.`
					);
				})
			),
		{ dispatch: false }
	);

	public getTenantCsvStringSuccessEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(getTenantCsvStringSuccessAction),
				tap(action => {
					this.effectInteractionService.displaySuccessToast(
						this.translations.toast.get_csv_string_title,
						this.translations.toast.get_csv_string_msg
					);
					this.doDownload(action.url);
				})
			),
		{ dispatch: false }
	);

	public getTenantsCsvSuccessEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(getTenantsCsvSuccessAction),
				tap(action => {
					this.effectInteractionService.displaySuccessToast(
						this.translations.toast.get_csv_string_title,
						this.translations.toast.get_csv_string_msg
					);
					this.doDownload(action.url);
				})
			),
		{ dispatch: false }
	);

	public genericTenantFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(
					archiveTenantFailureAction,
					getTenantCsvStringFailureAction,
					getTenantPlacesStatusFailureAction,
					resetTenantStateFailureAction,
					loadTenantFailureAction,
					recoverTenantFailureAction,
					editTenantFailureAction,
					uploadMultipleTenantsFailureAction,
					sendPlacesInvitationFailureAction
				),
				tap(() => this.loadingService.hideAndDisplayErrorToast())
			),
		{ dispatch: false }
	);

	public sendPlacesInvitationSuccessEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(sendPlacesInvitationSuccessAction),
				tap(() => {
					this.loadingService.hide();

					this.toastService.success(
						this.translations.toast.invitation_sent_message,
						this.translations.toast.invitation_sent_title,
						{
							...AppConstants.TOAST_STD_CONFIG,
							timeout: 4000
						}
					);
				})
			),
		{ dispatch: false }
	);

	public sendPlacesInvitationMultipleSuccessEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(sendPlacesInvitationMultipleSuccessAction),
				tap(action => {
					this.loadingService.hide();

					this.toastService.success(
						this.translations.toast.invitation_multiple_sent_message,
						this.translations.toast.invitation_multiple_sent_title,
						{
							...AppConstants.TOAST_STD_CONFIG,
							timeout: 4000
						}
					);

					this.dialog.open(SendTenantAppInviteMultipleDialogComponent, {
						width: '720px',
						backdropClass: 'backdrop-dark-background',
						disableClose: true,
						panelClass: 'no-padding-modalbox',
						data: {
							total: action.invitesSent,
							alreadyLinked: 0,
							popupStatus: 1 // INVITES SENT
						}
					});
				})
			),
		{ dispatch: false }
	);

	/***  ***/

	// Private calls
	private loadTenant(tenantId: string, options: any = {}, bypassPermissionsCheck?: boolean): Observable<Tenant> {
		let params = {};
		if (bypassPermissionsCheck) {
			params['bpcheck'] = true;
		}

		return combineLatest([
			this.leaseService.getLeasesDict().pipe(take(1)),
			this.httpClient.get<Tenant>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/tenants/${tenantId}`, {
				params: params
			})
		]).pipe(
			map(([leasesDict, t]) => {
				const tenant = Tenant.sanitize(t);
				tenant.currentLeaseStatus = this.leaseService.getTenantCurrentLeaseStatus(tenant, leasesDict);
				return tenant;
			})
		);
	}

	private addTenant(tenant: Tenant, options: { sendInvite: boolean }): Observable<Tenant> {
		return this.httpClient
			.put<Tenant>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/tenants`, {
				tenant,
				options
			})
			.pipe(
				map(t => {
					const tenant = Tenant.sanitize(t);
					tenant.currentLeaseStatus = 2; // We assume this way, it's just been created
					return tenant;
				})
			);
	}

	private editTenant(tenant: Tenant, bypassPermissionsCheck?: boolean): Observable<Tenant> {
		let params = {
			tenant
		};
		if (bypassPermissionsCheck) {
			params['bpcheck'] = true;
		}

		return this.leaseService.getLeasesDict().pipe(
			take(1),
			mergeMap(leasesDict => {
				return this.httpClient
					.put<Tenant>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/tenants/${tenant.id}`, params)
					.pipe(
						map(t => {
							const tenant = Tenant.sanitize(t);
							tenant.currentLeaseStatus = this.leaseService.getTenantCurrentLeaseStatus(
								tenant,
								leasesDict
							);
							return tenant;
						})
					);
			})
		);
	}

	private deleteTenant(tenantId: string, options: any = {}): Observable<DeleteTenantResult> {
		return this.httpClient.delete<DeleteTenantResult>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/tenants/${tenantId}`
		);
	}

	private deleteTenants(tenantIds: string[]): Observable<DeleteTenantResult[] | void> {
		const bulkDeleteTenants = {
			isAllSelected: false,
			selectedEntities: tenantIds,
			deselectedEntities: []
		};

		return this.httpClient.delete<DeleteTenantResult[] | void>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/tenants`,
			{
				body: { bulkDeleteTenants: bulkDeleteTenants }
			}
		);
	}

	private archiveTenant(tenant: Tenant): Observable<Tenant> {
		return this.httpClient.get<Tenant>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/tenants/${tenant.id}/archive`
		);
	}

	private recoverTenant(tenant: Tenant): Observable<Tenant> {
		return this.httpClient.get<Tenant>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/tenants/${tenant.id}/recover`
		);
	}

	private uploadMultipleTenants(tenants: Tenant[], hostedPageId?: string): Observable<Tenant[]> {
		return this.httpClient.post<Tenant[]>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/tenants`, {
			tenants,
			hostedPageId
		});
	}

	private getTenantCsvString(): Observable<string> {
		return this.httpClient.get(`${this.BACKEND_HOST}/landlords/${this.landlordId}/tenants?format=csv&type=fic`, {
			responseType: 'text'
		});
	}

	private getTenantsCsv(): Observable<string> {
		return this.httpClient.get(`${this.BACKEND_HOST}/landlords/${this.landlordId}/tenants?format=csv&type=all`, {
			responseType: 'text'
		});
	}

	private sendPlacesInvitation(
		tenantsId: string[],
		invites: { email: boolean }
	): Observable<{ invitesSent: number }> {
		return this.httpClient
			.post(`${this.BACKEND_TENANTS_HOST}/landlords/${this.landlordId}/requestUser`, {
				tenantsId: tenantsId,
				invites: invites
			})
			.pipe(
				map(it => {
					return { invitesSent: (it as TenantConnectionResponse[]).length };
				})
			);
	}

	private getTenantPlacesStatus(tenantId: string): Observable<{
		appSetup: boolean;
		paymentsSetup: boolean;
		inviteSentOn: number;
		inviteStatus: 'not-invited' | 'sent' | 'delivered' | 'opened' | 'clicked';
	}> {
		return this.httpClient
			.get(`${this.BACKEND_TENANTS_HOST}/landlords/${this.landlordId}/tenants/${tenantId}`)
			.pipe(
				map(
					(it: {
						appSetup: boolean;
						paymentsSetup: boolean;
						inviteSentOn: number;
						inviteStatus: 'not-invited' | 'sent' | 'delivered' | 'opened' | 'clicked';
					}) => {
						console.log(it);
						return it;
					}
				)
			);
	}

	private doDownload(url: string) {
		const element = document.createElement('a');
		const fileType = 'text/csv';
		element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(url)}`);
		element.setAttribute('download', `roommate-tenants-${AppUtils.getDateString()}.csv`);
		const event = new MouseEvent('click');
		element.dispatchEvent(event);
	}

	private generateMockTenant(): Observable<Tenant> {
		return this.httpClient.post<Tenant>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/mock/tenant`, {});
	}

	// Version 2

	private loadTenants(): Observable<Tenant[]> {
		// the getTenantCurrentLeaseStatus method calculates the currentLeaseStatus, when a lease is deleted
		//  	the observable below re-run and recalculate the currentTenantLeaseStatuses
		return combineLatest([
			this.leaseService.getLeasesDict().pipe(take(1)),
			this.httpClient.get<Tenant[]>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/tenants/basic`)
		]).pipe(
			map(([leasesDict, tenants]) => {
				const tenantsWithLeaseStatus = tenants.map(t => {
					t.currentLeaseStatus = this.leaseService.getTenantCurrentLeaseStatus(t, leasesDict);
					return t;
				});
				return tenantsWithLeaseStatus;
			})
		);
	}

	private loadTenantsPaginated(
		page: number,
		pageSize: number,
		filters: FilterContainer,
		sort: SortEvent
	): Observable<QueryResult<Tenant>> {
		const customFilters = this.getTenantQueryFiltersFromEstelleFilters(filters);
		const customSort = AppUtils.getQuerySortFromEstelleSort(sort);

		const params = {
			page: page,
			pageSize: pageSize,
			...customFilters,
			...customSort
		};

		return combineLatest([
			this.leaseService.getLeasesDict().pipe(take(1)),
			this.httpClient.get<QueryResult<Tenant>>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/tenants`, {
				params
			})
		]).pipe(
			map(([leasesDict, tenants]) => {
				const tenantsWithLeasesSetUp = tenants.data.map(t => {
					const tenant = Tenant.sanitize(t);
					tenant.currentLeaseStatus = this.leaseService.getTenantCurrentLeaseStatus(tenant, leasesDict);
					return tenant;
				});
				tenants.data = tenantsWithLeasesSetUp;

				return tenants;
			})
		);
	}

	private loadArchivedTenants(): Observable<QueryResult<Tenant>> {
		const customFilters: TenantQueryFilters = {
			archived: true
		};

		const customSort: SortQuery = {
			sortType: 'name',
			sortDirection: 1
		};

		return combineLatest([
			this.leaseService.getLeasesDict().pipe(take(1)),
			this.httpClient.get<QueryResult<Tenant>>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/tenants`, {
				params: {
					...customFilters,
					...customSort
				}
			})
		]).pipe(
			map(([leasesDict, tenants]) => {
				const tenantsWithLeasesSetUp = tenants.data.map(t => {
					const tenant = Tenant.sanitize(t);
					tenant.currentLeaseStatus = this.leaseService.getTenantCurrentLeaseStatus(tenant, leasesDict);
					return tenant;
				});
				tenants.data = tenantsWithLeasesSetUp;

				return tenants;
			})
		);
	}

	private getTenantQueryFiltersFromEstelleFilters(filters: FilterContainer): TenantQueryFilters {
		let customFilters: TenantQueryFilters = {};

		// Status
		customFilters.status = filters.selectedTabId as 'all' | 'overdue' | 'expiring';

		// Text search
		if (filters.searchedValue) {
			customFilters.text = filters.searchedValue;
		}

		// Custom filters
		if (filters.appliedFilters) {
			const appInstalledFilter = FilterContainer.getFilterIfExists(filters, TenantFilterKeys.APP_INSTALLED);
			if (appInstalledFilter) {
				customFilters.appInstalled = appInstalledFilter.selectedValue.value;
			}

			const appInvitedFilter = FilterContainer.getFilterIfExists(filters, TenantFilterKeys.APP_INVITED);
			if (appInvitedFilter) {
				customFilters.appInstalled = appInvitedFilter.selectedValue.value;
			}

			const appPendingFilter = FilterContainer.getFilterIfExists(filters, TenantFilterKeys.PENDING);
			if (appPendingFilter) {
				customFilters.pending = appPendingFilter.selectedValue.value;
			}

			const tenantTypeFilter = FilterContainer.getFilterIfExists(filters, TenantFilterKeys.TENANT_TYPE);
			if (tenantTypeFilter) {
				customFilters.studentType = tenantTypeFilter.selectedValue.value;
			}
		}

		return customFilters;
	}
}
