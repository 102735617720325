import { SnapshotAction } from '@angular/fire/compat/database';
import { ListingScoreService } from '../core/services/optimization-score-service';
import { PropertyDisplayData } from '../properties/properties-view/properties-table/properties-table-datasource';
import { UnitDisplayData } from '../properties/properties.component';
import { AppUtils } from '../utils/app-utils';
import { AddressData, BasePageModelStore } from './common';
import { AttachedFile } from './fileRm.model';
import { ListingScoreResult } from './listingScore.model';
import { MaintenanceRequest } from './maintenanceRequest.model';
import { DatedItem, PaymentItem, PaymentSetup, TaxInfo } from './payments';
import { Tenant } from './tenant.model';
import { PhotoData } from './viewPhotosData.model';
import { bills } from './landlord';
import { CaclulatedUnitAvailabilities } from '../leases/availability-service';
import { SeasonalPricingTypes } from '../properties/seasonal-pricing/seasonal-pricing.component';

export type UnitType = 'single' | 'double' | 'shared' | 'property';
export interface UnitCount {
	total: number;
	single: number;
	double: number;
	prop: number;
}

export interface PropertyPaymentsSetup {
	incomes: PaymentSetup[];
	costs: PaymentSetup[];
}

export interface UnitPaymentsSetup {
	unitIncomes: PaymentSetup[];
	unitCosts: PaymentSetup[];
	leaseIncomes: PaymentSetup[];
	leaseCosts: PaymentSetup[];
}

export interface SeasonalPricingEstelleUnit {
	startDate: number;
	endDate: number;
	value: number;
	type: SeasonalPricingTypes;
}

export interface EstelleUnit {
	id: string;
	propertyId: string;
	type: UnitType;
	additionalRentAmount?: number;
	picturesData?: PhotoData[];
	desc?: string;
	name: string;
	budget?: number;
	deposit?: number;
	seasonalPricing?: SeasonalPricingEstelleUnit[];
	order: number;
	amenities?: string[];
	featureTags?: string[];
	firstTimeCosts?: PaymentItem[];
	endCosts?: PaymentItem[];
	unitSize?: number;
	minimumStay?: {
		amount: number;
		type: 'months' | 'days' | 'years';
	};
	floor?: string;

	paymentsSetup?: UnitPaymentsSetup;
	privateNotes?: string;

	listingScore?: ListingScoreResult;
	landlordReferenceCode?: string;

	cancellationPolicy: 'flexible' | 'moderate' | 'strict' | 'non-refundable';
	contractType: 'daily' | 'weekly' | 'fortnightly' | 'monthly';
	bedType?: 'single' | 'twin' | 'double' | 'queen' | 'king';
	numberOfBeds?: number;
	sharedRoomWith?: string;

	includedBills?: bills[];
	includedBillsAmount?: number;
}

export interface UnitData {
	id: string;
	name: string;
	type: UnitType;
	propertyId: string;
	propertyName: string;
}

export interface PropertyData {
	id: string;
	name: string;
	city: string;
	address: string;
}

export interface PropertyOptions {
	invoiceToOwners?: boolean;
}

export interface PropertyRules {
	smoking?: 'no' | 'yes' | 'discussable' | 'outsideOnly';
	pets?: 'no' | 'yes' | 'discussable' | 'outsideOnly';
	playMusic?: 'no' | 'yes' | 'discussable' | 'outsideOnly';
	preferredGender?: 'none' | 'male' | 'female';
	minimumAge?: number;
	maximumAge?: number;
}

export class Property implements AddressData, DatedItem {
	public units?: { [key: string]: EstelleUnit } = {};

	public picturesData: PhotoData[] = [];
	public managedAsProperty = false;

	public propertyLeaseStartDate: number = 0;
	public propertySize: number = 0;

	public privateNotes?: string;

	createdBy?: string;
	createdDate?: number;
	updatedBy?: string;
	updatedDate?: number;
	landlordId?: string;

	// Address components
	public countryCode: string;
	public houseNumber: string;
	public region: string;
	public city: string;
	public country: string;
	public regionCode: string;
	public postalCode: string;
	public extraAddressInfo = {};
	public floor: string = '';

	public bathroomsCount: number;
	public landlordReferenceCode?: string;

	public cadastralData: {
		sheet?: string;
		parcel?: string;
		subordinate?: string;
		category?: string;
		cadastralIncome?: string;
	} = {};

	public rules?: PropertyRules;

	public paymentsSetup: PropertyPaymentsSetup;
	public rentTaxRate?: TaxInfo;

	public listingScore?: ListingScoreResult;

	constructor(
		public id: string,
		public name: string,
		public address: string,
		public stairwell: string = '',
		public unit: string = '',
		public type: number = null,
		public desc: string = '',
		public servicesCustom: string[] = [],
		public placeTags: string[] = [],
		public isMock: boolean = false,
		public investment: number = null,
		public options: PropertyOptions = {},
		public owners: { id: string; investment?: number }[] = [],
		public amenities: string[] = []
	) {}

	public static isWholeHouse(property: Property): boolean {
		return property.managedAsProperty;
	}

	public static clone(prop: Property): Property {
		return AppUtils.deepCopy(prop) as Property;
	}

	public static sanitize(
		propData: Property,
		unitScoreService: ListingScoreService,
		propertyScoreService: ListingScoreService
	): Property {
		const p = new Property(
			propData.id,
			propData.name,
			propData.address,
			propData.stairwell,
			propData.unit,
			propData.type,
			propData.desc,
			propData.servicesCustom,
			propData.placeTags,
			propData.isMock,
			propData.investment || 0,
			propData.options,
			propData.owners,
			propData.amenities
		);

		p.createdBy = propData.createdBy;
		p.createdDate = propData.createdDate;
		p.updatedBy = propData.updatedBy || '';
		p.updatedDate = propData.updatedDate || 0;
		p.privateNotes = propData.privateNotes || '';
		p.floor = propData.floor;
		p.landlordReferenceCode = propData.landlordReferenceCode || '';
		p.bathroomsCount = propData.bathroomsCount;

		p.landlordId = propData.landlordId;
		p.rentTaxRate = propData.rentTaxRate;

		p.propertySize = propData.propertySize || 0;
		p.propertyLeaseStartDate = propData.propertyLeaseStartDate || 0;
		p.rules = propData.rules || {};
		p.managedAsProperty = propData.managedAsProperty || false;

		// Adress components
		p.city = propData.city || '';
		p.country = propData.country || '';
		p.countryCode = propData.countryCode || '';
		p.houseNumber = propData.houseNumber || '';
		p.region = propData.region || '';
		p.regionCode = propData.regionCode || '';
		p.postalCode = propData.postalCode || propData['zip'] || '	';
		p.extraAddressInfo = propData.extraAddressInfo || {};

		p.picturesData = propData.picturesData || [];

		p.cadastralData = propData.cadastralData || {
			sheet: '',
			parcel: '',
			subordinate: '',
			category: '',
			cadastralIncome: ''
		};

		// Old pictures management (to remove after migration)
		if (p.picturesData.length === 0 && propData['pictures'] && propData['pictures'].length > 0) {
			p.picturesData = propData['pictures'].map(it => ({ data: it }));
		}

		// From ownerId to owners  (to remove after migration)
		if (propData['ownerId']) {
			if (!p.owners) {
				p.owners = [{ id: propData['ownerId'], investment: 0 }];
			} else {
				p.owners.push({ id: propData['ownerId'], investment: 0 });
			}
		}

		p.paymentsSetup = propData['paymentsSetup'] || {
			incomes: [],
			costs: []
		};

		p.paymentsSetup.costs = p.paymentsSetup.costs || [];
		p.paymentsSetup.incomes = p.paymentsSetup.incomes || [];

		// Units and units ordering
		let orderedUnits = Object.values(propData.units || {}).sort((a, b) => a.order - b.order);

		orderedUnits.forEach(it => {
			// Defaults for units
			const unit: EstelleUnit = {
				id: it.id,
				order: it.order,
				propertyId: it.propertyId || p.id,
				type: it.type,
				additionalRentAmount: it.additionalRentAmount || 0,
				name: it.name,
				amenities: it.amenities || [],
				budget: it.budget || 0,
				deposit: it.deposit || 0,
				seasonalPricing: it.seasonalPricing || [],
				desc: it.desc || '',
				endCosts: it.endCosts || [],
				featureTags: it.featureTags || [],
				firstTimeCosts: it.firstTimeCosts || [],
				floor: it.floor || '',
				minimumStay: it.minimumStay || null,
				picturesData: it.picturesData || [],
				unitSize: it.unitSize || 0,
				paymentsSetup: it.paymentsSetup,
				privateNotes: it.privateNotes || '',
				landlordReferenceCode: it.landlordReferenceCode || '',
				bedType: it.bedType || null,
				cancellationPolicy: it.cancellationPolicy,
				contractType: it.contractType,
				numberOfBeds: it.numberOfBeds || null,
				sharedRoomWith: it.sharedRoomWith || null,
				includedBills: it.includedBills || null,
				includedBillsAmount: it.includedBillsAmount || null
			};

			unit.paymentsSetup = unit.paymentsSetup || {
				leaseCosts: unit?.paymentsSetup?.leaseCosts || [],
				leaseIncomes: unit?.paymentsSetup?.leaseIncomes || [],
				unitCosts: unit?.paymentsSetup?.unitCosts || [],
				unitIncomes: unit?.paymentsSetup?.unitIncomes || []
			};

			if (it.listingScore) {
				unit.listingScore = unitScoreService.applyLocalizedMessages(
					it.listingScore,
					p.managedAsProperty,
					it.name || p.name
				);
			}

			p.units[it.id] = unit;
		});

		p.listingScore = propertyScoreService.applyLocalizedMessages(propData.listingScore, propData.managedAsProperty);

		return p;
	}

	public static getBasicInfo(p: Property): PropertyBasic {
		return {
			id: p.id,
			name: p.name,
			managedAsProperty: p.managedAsProperty,
			units: Object.values(p.units || {}).map(u => ({
				id: u.id,
				name: u.name,
				type: u.type,
				propertyId: u.propertyId
			})),
			isMock: p.isMock
		};
	}

	public static getBasicInfoMultiple(ps: Property[]): PropertyBasic[] {
		return ps.map(p => this.getBasicInfo(p)).reduce((a, b) => a.concat(b), []);
	}
}

export interface UnitBasic {
	id: string;
	name: string;
	type: UnitType;
	propertyId: string;
}

export interface PropertyBasic {
	id: string;
	name: string;
	managedAsProperty: boolean;
	units: UnitBasic[];
	isMock?: boolean;
}

export interface UnitCompleteData {
	unit: EstelleUnit;
	property: Property;
}

export interface MessageAddFileData {
	property?: Property;
	tenant?: Tenant;
}

export interface PropertyPageModelStore extends BasePageModelStore {
	propertyIds: string[];
}

export interface PropertyPageModelUI {
	properties: PropertyDisplayData[];
	totalItems: number;
	filteredItems: number;
}

export interface UnitPageModelStore extends BasePageModelStore {
	unitIds: string[];
	availabilities?: { [unitId: string]: CaclulatedUnitAvailabilities };
}

export interface UnitPageModelUI {
	units: UnitDisplayData[];
	totalItems: number;
	filteredItems: number;
}
