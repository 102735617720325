import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { ActionsSubject } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PendingFile } from '../../../models/fileRm.model';
import { PhotoData, PhotoWrapper } from '../../../models/viewPhotosData.model';
import { TenantFilesComponent } from '../../../tenants/tenant-files/tenant-files.component';
import { AppUtils } from '../../../utils/app-utils';
import { LoadingService } from '../loading.service';

export enum FileEntities {
	TENANT = 'tenants',
	PROPERTY = 'properties',
	UNIT = 'units',
	PAYMENT = 'payments',
	OWNER = 'owners',
	MAINTENANCE = 'maintenances',
	TENANT_CHECKLISTS = 'tenant_checklists',
	CHAT = 'chats',
	CONFIGURATION = 'configurations',
	BOOKING = 'bookings',
	LEASE = 'leases'
}
export interface FileQuery {
	entityType: string;
	entityId: string;
	pendingFile?: PendingFile; // on a get the pendingFile is null
}

@Injectable({
	providedIn: 'root'
})
export class PicturesService implements CanDeactivate<TenantFilesComponent> {
	private fileUploadActive = false;
	public userId = ''; // Initialized at the auth service level
	uploadProgressTranferred: Observable<number>[] = [];
	uploadProgressTotals: Observable<number>[] = [];

	uploadProgressTransferredAggregated: number;
	uploadProgressTotalAggregated: number;

	constructor(private storage: AngularFireStorage) {}

	canDeactivate(
		component: TenantFilesComponent,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot
	): boolean {
		return (
			!this.fileUploadActive ||
			confirm(
				$localize`:@@com_files_uploading_warning:Some files are uploading. Press OK to continue and stop the process. Otherwise press Cancel`
			)
		);
	}

	public async uploadPhoto(folder: string, name: string, fileData: string): Promise<PhotoData> {
		this.fileUploadActive = true;
		const ref = this.storage.ref(`/landlords/${this.userId}/${folder}/pictures/${name}`);
		const elaboratedData = fileData; // this.Base64.atob(fileData.substr("data:image/png;".length));
		const task = ref.putString(elaboratedData, 'data_url', {
			customMetadata: {}
		});

		const snapshot: firebase.default.storage.UploadTaskSnapshot = await task;
		const url: string = await snapshot.ref.getDownloadURL();
		this.fileUploadActive = false;

		const validMetadata = Object.keys(snapshot.metadata)
			.filter(
				key =>
					[
						'customMetadata',
						'fullPath',
						'name',
						'private',
						'timeCreated',
						'updated',
						'contentType',
						'size'
					].indexOf(key) > -1
			)
			.reduce((obj, key) => {
				return {
					...obj,
					[key]: snapshot.metadata[key]
				};
			}, {});

		return { data: url, metadata: AppUtils.clearEmptyKeys(validMetadata) };
	}

	public async uploadPhotoData(folder: string, photoData: PhotoData): Promise<PhotoData> {
		return this.uploadPhoto(folder, (photoData.metadata && photoData.metadata.name) || 'Photo', photoData.data);
	}

	public async syncPhotoWrappers(
		folder: string,
		pendingPhotos: PhotoWrapper[],
		filterErrors: boolean = false
	): Promise<PhotoWrapper[]> {
		const promises = pendingPhotos.map(async wrapper => {
			if (wrapper.status === 'error' || wrapper.status === 'to_upload' || wrapper.status === 'edit') {
				return this.uploadPhotoData(folder, wrapper.photo)
					.then(result => {
						return {
							status: 'done',
							photo: result
						} as PhotoWrapper;
					})
					.catch(
						e =>
							({
								status: 'error',
								photo: wrapper.photo
							} as PhotoWrapper)
					);
			} else {
				return wrapper;
			}
		});

		const data = await Promise.all(promises);
		if (filterErrors) {
			return data.filter(it => it.status === 'done');
		} else {
			return data;
		}
	}
}
