import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { publishNewFiltersAction } from 'src/app/core/services/filters/state/filters.actions';
import { UnitPageModelStore } from 'src/app/models/property.model';
import {
	addPropertySuccessAction,
	deletePropertySuccessAction,
	editPropertySuccessAction,
	loadChannelManagerSyncUnitsPaginatedSuccessAction,
	loadPropertiesBasicSuccessAction,
	loadPropertyByIdSuccessAction,
	resetPropertiesStateSuccessAction,
	uploadMultiplePropertiesSuccessAction
} from 'src/app/properties/state/properties.actions';
import {
	addMockLeasesSuccessAction,
	createLeaseSuccessAction,
	loadLeaseByIdSuccessAction,
	removeLeaseSuccessAction,
	updateLeaseSuccessAction
} from 'src/app/leases/state/lease.actions';
import { addMockMaintenanceSuccessAction } from 'src/app/maintenances/state/maintenances.actions';
import {
	addNewPropertyToOwnerSuccessAction,
	addOwnerSuccessAction,
	deleteOwnerSuccessAction,
	editOwnerSuccessAction,
	loadOwnersSuccessAction,
	loadOwnerSuccessAction
} from 'src/app/owners/state/owners.actions';

export interface ChannelManagerSyncUnitPagesState extends EntityState<UnitPageModelStore> {
	isLoaded: boolean;
}
export const channelManagerSyncUnitPagesAdapter = createEntityAdapter<UnitPageModelStore>({
	selectId: model => model.id
});
const initialChannelManagerSyncUnitPagesState = channelManagerSyncUnitPagesAdapter.getInitialState({
	isLoaded: false
});

export const ChannelManagerSyncUnitPagesReducers = createReducer(
	initialChannelManagerSyncUnitPagesState,

	// Load pages

	on(loadChannelManagerSyncUnitsPaginatedSuccessAction, (state, payload) => {
		if (payload.unitIdsQueryResult) {
			return channelManagerSyncUnitPagesAdapter.upsertOne(
				{
					id: payload.page,
					unitIds: payload.unitIdsQueryResult.data,
					totalItems: payload.unitIdsQueryResult.metadata.totalItems || 0,
					filteredItems: payload.unitIdsQueryResult.metadata.filteredItems || 0,
					availabilities: payload.availabilities
				},
				{ ...state, isLoaded: true }
			);
		} else {
			return state;
		}
	}),

	// Filters user actions

	on(publishNewFiltersAction, (state, payload) => {
		if (payload.filters.type === 'cm-sync-units' && (payload.filters.filters || payload.filters.sort)) {
			return initialChannelManagerSyncUnitPagesState;
		} else {
			return state;
		}
	}),

	// Property actions (user actions + realtime updates)

	// ALL pages invalidation

	on(
		resetPropertiesStateSuccessAction,

		loadPropertyByIdSuccessAction,

		addPropertySuccessAction,
		editPropertySuccessAction,

		uploadMultiplePropertiesSuccessAction,
		addMockLeasesSuccessAction,
		addMockMaintenanceSuccessAction,

		deletePropertySuccessAction,

		(state, payload) => ({ ...initialChannelManagerSyncUnitPagesState, isLoaded: true })
	),

	on(loadPropertiesBasicSuccessAction, (state, payload) => {
		if (payload.propertiesBasic) {
			if (payload['refreshAll']) {
				return { ...initialChannelManagerSyncUnitPagesState, isLoaded: true };
			} else {
				return state;
			}
		} else {
			return state;
		}
	}),

	// Owner actions (user actions + realtime updates)

	// ALL pages invalidation

	on(
		loadOwnersSuccessAction,
		loadOwnerSuccessAction,

		addOwnerSuccessAction,
		editOwnerSuccessAction,

		deleteOwnerSuccessAction,
		addNewPropertyToOwnerSuccessAction,

		(state, payload) => ({ ...initialChannelManagerSyncUnitPagesState, isLoaded: true })
	),

	// Lease actions (user actions + realtime updates)

	// ALL pages invalidation

	on(
		loadLeaseByIdSuccessAction,

		createLeaseSuccessAction,
		removeLeaseSuccessAction,
		updateLeaseSuccessAction,

		(state, payload) => ({ ...initialChannelManagerSyncUnitPagesState, isLoaded: true })
	)
);
