import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { PropertyOwner } from 'src/app/models/propertyOwner.model';
import { editPropertySuccessAction } from 'src/app/properties/state/properties.actions';
import { resetStoreActionSuccess } from 'src/app/store/actions';
import {
	addNewPropertyToOwnerSuccessAction as addNewPropertyToOwnerSuccessAction,
	loadOwnersPaginatedSuccessAction,
	loadOwnersSuccessAction,
	resetOwnersStateSuccessAction
} from './owners.actions';
import {
	addOwnerSuccessAction,
	deleteOwnerSuccessAction,
	editOwnerSuccessAction,
	loadOwnerSuccessAction
} from './owners.actions';

export interface PropertyOwnerState extends EntityState<PropertyOwner> {
	isLoaded: boolean;
}
export const ownersAdapter = createEntityAdapter<PropertyOwner>();
const initialOwnerRequestState = ownersAdapter.getInitialState({
	isLoaded: false
});

export const OwnersReducers = createReducer(
	initialOwnerRequestState,
	on(resetOwnersStateSuccessAction, (state, payload) => (state = initialOwnerRequestState)),
	on(editOwnerSuccessAction, addOwnerSuccessAction, loadOwnerSuccessAction, (state, payload) =>
		ownersAdapter.upsertOne(payload.owner, { ...state, isLoaded: true })
	),
	on(loadOwnersSuccessAction, (state, payload) => {
		// Basic owners
		if (payload.owners) {
			if (payload['refreshAll']) {
				return { ...initialOwnerRequestState, isLoaded: true };
			} else {
				return state;
			}
		} else {
			return state;
		}
	}),

	on(deleteOwnerSuccessAction, (state, payload) =>
		ownersAdapter.removeOne(payload.ownerId, { ...state, isLoaded: true })
	),
	on(editPropertySuccessAction, (state, payload) => {
		if (payload.owners) {
			if (payload.refreshAll) {
				return ownersAdapter.setAll(payload.owners, { ...state, isLoaded: true });
			}
			return ownersAdapter.upsertMany(payload.owners, { ...state, isLoaded: true });
		} else {
			return state;
		}
	}),
	on(addNewPropertyToOwnerSuccessAction, (state, payload) =>
		ownersAdapter.updateOne(
			{
				id: payload.ownerId,
				changes: {
					ownedPropertyIds: Array.from(
						new Set([...state.entities[payload.ownerId].ownedPropertyIds, ...payload.propertiesIdsToAdd])
					)
				}
			},
			state
		)
	),

	// Version 2

	on(loadOwnersPaginatedSuccessAction, (state, payload) => {
		if (payload.ownersQueryResult) {
			return ownersAdapter.upsertMany(payload.ownersQueryResult.data, { ...state, isLoaded: true });
		} else {
			return state;
		}
	})
);
