<div class="rf-half-right-dialog">
	<div class="rf-header-hr-dialog">
		<img src="./assets/img/ic_import_tenant.svg" />
		<p i18n="@@tenant_import_title"><strong>TENANT</strong> data import</p>
		<img (click)="closeDialog()" src="./assets/img/close.svg" />
	</div>

	<div class="rf-container-hr-dialog rf-section-hr-dialog">
		<!-- Actual content of scrollable dialog -->

		<div class="section">
			<p class="rf-itle" i18n="@@tenant_import_sec_csv_title">Upload a CSV file</p>
			<br />
			<p>
				<strong>1.&nbsp;</strong
				><span i18n="@@tenant_import_sec_csv_desc_1_new">Download the CSV file template</span><br />
				<strong>2.&nbsp;</strong
				><a
					i18n-href="@@tenant_import_sec_csv_desc_2_link_new"
					href="https://helpcenter.the-roommate.com/en/articles/6808308-how-to-import-tenants"
					target="_blank"
					i18n="@@tenant_import_sec_csv_desc_2"
					>Read our tutorial</a
				><span i18n="@@tenant_import_sec_csv_desc_2_bis_new"> to learn the procedure</span><br />
				<strong>3.&nbsp;</strong
				><span i18n="@@tenant_import_sec_csv_desc_3_new">Click or drag to upload the tenant data CSV file</span>
			</p>
			<p>
				(<a
					i18n-href="@@url_ten_import_csv_template"
					i18n="@@tenant_import_link_csv_template_new"
					href="https://firebasestorage.googleapis.com/v0/b/roommate-d6e73.appspot.com/o/dashboardData%2Fimport_template_en.csv?alt=media&token=fa23c061-95c5-41d4-94fb-33e39e55b669"
					target="_blank"
					>CSV file template</a
				>)
			</p>
			<p *ngIf="false">
				<a i18n-href="@@url_ten_import_csv_guide" i18n="@@tenant_import_link_csv_guide" href="" target="_blank"
					>Import data tutorial</a
				>
			</p>
			<!-- Upload zone -->
			<input type="file" accept=".csv" #file style="display: none" (change)="fileChangeEvent($event)" />

			<div
				class="rf-centered-block"
				*ngIf="!loading && !tenantPreviewDataSource"
				appDragDrop
				(click)="uploadFiles()"
				(onFileDragging)="fileDraggingStatusUpdated($event)"
				(onFileDropped)="uploadFilesByDrop($event)"
			>
				<div
					class="rf-upload-zone"
					[ngClass]="{
						'rf-border-round-dashed-secondary': !currentFileError,
						'rf-border-round-dashed-error': currentFileError,
						'rf-dragging-zone-active': fileDragging
					}"
				>
					<img *ngIf="!currentFileError" src="./assets/img/tenant_import_upload.svg" />
					<img *ngIf="currentFileError" src="./assets/img/tenant_import_error.svg" />
				</div>
				<p *ngIf="!currentFileError" i18n="@@ten_import_drag_hint_new">
					You can drag and drop <strong>your CSV file</strong> here, or you can click on the area to upload
					it.
				</p>
				<p *ngIf="currentFileError === 1" i18n="@@ten_import_file_error">
					An <strong>error</strong> occurred. Have you <strong>followed</strong> the
					<strong>tutorial?</strong>.<br />Try to <strong>upload</strong> a new document or, if the problem
					persists, <strong> contact us</strong>
				</p>
				<p *ngIf="currentFileError === 2" i18n="@@ten_import_file_error_wrong">
					An <strong>error</strong> occurred. The uploaded file
					<strong>is not in CSV format</strong>.<br />Try to <strong>upload</strong> a new document or, if the
					problem persists, <strong> contact us</strong>
				</p>
			</div>

			<div class="rf-centered-block" *ngIf="loading">
				<mat-spinner></mat-spinner>
			</div>

			<div class="rf-right-aligned-block" *ngIf="tenantPreviewDataSource">
				<button mat-button (click)="resetFile()" i18n="@@ten_import_file_reload">Upload a new document</button>
			</div>
		</div>

		<div class="rf-section-hr-dialog" *ngIf="existingTenants > 0 && tenantPreviewDataSource">
			<div class="rf-alert-box">
				<img src="./assets/img/exclamation_red.svg" />
				<span i18n="@@ten_import_existing">
					<strong>{{ existingTenants }}</strong> tenants have already been added.<br />Their information will
					be overwritten.
				</span>
			</div>
		</div>

		<div class="rf-section-hr-dialog" *ngIf="warningList && tenantPreviewDataSource">
			<div *ngFor="let warning of warningList" class="rf-alert-box" style="margin-bottom: 10px">
				<img src="./assets/img/exclamation_red.svg" />
				<span [innerHTML]="warning | safeHtml"></span>
			</div>
		</div>

		<div class="rf-centered-block" *ngIf="tenantPreviewDataSource">
			<p class="rf-total-rows-processed" i18n="@@ten_import_recognized_ten_1">
				<strong>{{ validTenants }}</strong> of <strong>{{ totalRows }}</strong> tenants processed correctly
			</p>

			<!-- Tenant preview table-->
			<div class="rf-rows-processed-table">
				<table mat-table [dataSource]="tenantPreviewDataSource">
					<ng-container matColumnDef="line">
						<th mat-header-cell *matHeaderCellDef>#</th>
						<td mat-cell *matCellDef="let element">{{ element.line + 2 }}</td>
					</ng-container>

					<ng-container matColumnDef="name">
						<th mat-header-cell *matHeaderCellDef i18n="@@com_person_name">Name</th>
						<td mat-cell *matCellDef="let element">
							{{
								element.errors.length === 0 ? element.tenant.name + ' ' + element.tenant.surname : '---'
							}}
						</td>
					</ng-container>

					<ng-container matColumnDef="email">
						<th mat-header-cell *matHeaderCellDef i18n="@@com_person_email">Email</th>
						<td mat-cell *matCellDef="let element">
							{{ element.errors.length === 0 ? element.tenant.email : '---' }}
						</td>
					</ng-container>

					<ng-container matColumnDef="phone">
						<th mat-header-cell *matHeaderCellDef i18n="@@com_person_phone">Phone</th>
						<td mat-cell *matCellDef="let element">
							{{ element.errors.length === 0 ? element.tenant.phone : '---' }}
						</td>
					</ng-container>

					<ng-container matColumnDef="address">
						<th mat-header-cell *matHeaderCellDef i18n="@@com_person_address">Address</th>
						<td mat-cell *matCellDef="let element">
							{{
								element.errors.length === 0 ? tenantService.getTenantAddress(element.tenant, 40) : '---'
							}}
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="previewTableColumns; sticky: true"></tr>
					<tr
						mat-row
						*matRowDef="let row; columns: previewTableColumns"
						[ngClass]="{ 'rf-error-text': row.errors.length > 0, 'highligthed-text': row.existing }"
					></tr>
				</table>
			</div>
		</div>
	</div>

	<div class="rf-footer-hr-dialog">
		<button mat-button (click)="closeDialog()" i18n="@@com_cancel">Cancel</button>

		<button mat-button [disabled]="!buttonEnabled" (click)="executeImport()" i18n="@@com_import">Import</button>
	</div>
</div>
