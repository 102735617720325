import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { act, Actions, createEffect, ofType } from '@ngrx/effects';
import { SnotifyService } from 'ng-snotify';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { MinimumUserData } from 'src/app/models/common';
import { LandlordData } from 'src/app/models/landlord';
import { LandlordReport } from 'src/app/models/report';
import { AppConstants } from 'src/app/utils/app-costants';
import { LocalizationUtils } from 'src/app/utils/localization-utils';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth.service';
import { LoadingService } from '../../loading.service';
import { EffectInteractionService } from '../../effectInteraction.service';
import { ContactRequestInfo, LandlordService } from '../landlord.service';
import {
	askForReportGenerationAction,
	askForReportGenerationFailureAction,
	askForReportGenerationSuccessAction,
	initUserAction,
	landlordSignOutAction,
	landlordSignOutSuccessAction,
	loadLandlordDataAction,
	loadLandlordDataFailureAction,
	loadLandlordDataSuccessAction,
	loadReportsAction,
	loadReportsFailureAction,
	loadReportsSuccessAction,
	requestNewPasswordAction,
	requestNewPasswordFailureAction,
	requestNewPasswordSuccessAction,
	setLandlordTokenAction,
	setLandlordTokenFailureAction,
	setLandlordTokenSuccessAction,
	updateLandlordAction,
	updateLandlordDataFailureAction,
	updateLandlordDataSuccessAction
} from './landlord.actions';

@Injectable()
export class LandlordEffects {
	private landlordId: string;
	private userId: string;

	private translations = LocalizationUtils.getTranslations();
	private readonly BACKEND_HOST = `${environment.services.backend}/api-dash/v1`;

	constructor(
		private readonly landlordService: LandlordService,
		private readonly authService: AuthService,
		private readonly actions$: Actions,
		private readonly toastService: SnotifyService,
		private readonly httpClient: HttpClient,
		private readonly loadingService: LoadingService,
		private readonly effectInteractionService: EffectInteractionService
	) {
		this.landlordService.getLandlordId().subscribe(id => {
			this.landlordId = id;
		});
		this.landlordService.getLandlordUserData().subscribe(userData => {
			if (userData && userData.id) {
				this.userId = userData.id;
			} else {
				this.userId = '';
			}
		});
	}

	public signOutEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(landlordSignOutAction),
			switchMap(() => of(landlordSignOutSuccessAction()))
		)
	);

	public initUserEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(initUserAction),
			switchMap(action => {
				return this.initUser(action.userId, action.forcedLandlordIdForGodUser).pipe(
					take(1),
					map(result =>
						loadLandlordDataSuccessAction({ landlord: result.landlordData, user: result.userData })
					),
					catchError(err => of(loadLandlordDataFailureAction({})))
				);
			})
		)
	);

	public loadLandlordDataEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadLandlordDataAction),
			switchMap(action => {
				return this.loadLandlordInfo(action.landlordId).pipe(
					take(1),
					map(landlord => loadLandlordDataSuccessAction({ landlord })),
					catchError(err => of(loadLandlordDataFailureAction({})))
				);
			})
		)
	);

	public loadReportsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadReportsAction),
			switchMap(action =>
				this.loadReports().pipe(
					map(reports => loadReportsSuccessAction({ reports })),
					catchError(err => of(loadReportsFailureAction({})))
				)
			)
		)
	);

	public updateLandlordEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(updateLandlordAction),
			mergeMap(action =>
				this.updateLandlord(action.landlord).pipe(
					map(landlord => {
						this.effectInteractionService.callbackSuccess(action);
						return updateLandlordDataSuccessAction({ landlord });
					}),
					catchError(err => {
						this.effectInteractionService.callbackFail(action);
						return of(updateLandlordDataFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public setLandlordTokenEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(setLandlordTokenAction),
			mergeMap(action => {
				return this.setLandlordToken(action.token).pipe(
					map(() => setLandlordTokenSuccessAction()),
					catchError(err => of(setLandlordTokenFailureAction({})))
				);
			})
		)
	);

	public askForReportGenerationEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(askForReportGenerationAction),
			mergeMap(action =>
				this.askForReportGeneration(action.options, action.hostedPageId).pipe(
					map(reports => {
						this.effectInteractionService.callbackSuccess(action);
						return askForReportGenerationSuccessAction({ reports });
					}),
					catchError(err => {
						this.effectInteractionService.callbackFail(action);
						return of(askForReportGenerationFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public requestNewPasswordEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(requestNewPasswordAction),
			mergeMap(action =>
				this.requestNewPassword(action.password).pipe(
					map(() => requestNewPasswordSuccessAction()),
					catchError(err => of(requestNewPasswordFailureAction({})))
				)
			)
		)
	);

	/*** Effects handle success and failure actions  ***/

	public updateLandlordDataSuccessEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(updateLandlordDataSuccessAction),
				tap(() =>
					this.effectInteractionService.displaySuccessToast(
						this.translations.toast.saved,
						this.translations.toast.settings_saved
					)
				)
			),
		{ dispatch: false }
	);

	public updateLandlordDataFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(updateLandlordDataFailureAction),
				tap(() =>
					this.effectInteractionService.showDialogError(
						$localize`:@@update_landlord_failed:Something went wrong during the update of your data. Try again and if the problem persists contact us.`
					)
				)
			),
		{ dispatch: false }
	);

	public askForReportGenerationSuccessEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(askForReportGenerationSuccessAction),
				tap(() =>
					this.effectInteractionService.displaySuccessToast(
						this.translations.toast.report_gen_title,
						this.translations.toast.report_gen_msg
					)
				)
			),
		{ dispatch: false }
	);

	public genericMaintenanceFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(
					loadLandlordDataFailureAction,
					loadReportsFailureAction,
					askForReportGenerationFailureAction,
					requestNewPasswordFailureAction
				),
				tap(() => this.loadingService.hideAndDisplayErrorToast())
			),
		{ dispatch: false }
	);

	public silentMaintenanceFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(setLandlordTokenFailureAction),
				tap(action => this.effectInteractionService.logErrorSilently(action.type))
			),
		{ dispatch: false }
	);

	/***  ***/

	// Private calls
	private loadLandlordInfo(landlordId: string): Observable<LandlordData> {
		return this.httpClient.get<LandlordData>(`${this.BACKEND_HOST}/landlords/${landlordId || this.landlordId}`);
	}

	private initUser(
		userId: string,
		forcedLandlordIdForGodUser?: string
	): Observable<{ landlordData: LandlordData; userData?: MinimumUserData }> {
		let params = {};
		if (forcedLandlordIdForGodUser) {
			params = {
				forcedLandlordIdForGodUser: forcedLandlordIdForGodUser
			};
		}

		return this.httpClient
			.get<{ landlordData: LandlordData }>(`${this.BACKEND_HOST}/init/${userId}`, {
				params: params,
				observe: 'response',
				responseType: 'json'
			})
			.pipe(
				map(response => {
					console.log(
						`Connected to backend ${response.headers.get('roommate-backend-version') || 'unkown'} `
					);
					return response.body;
				})
			);
	}

	public sendContactRequest(title, desc): Observable<ContactRequestInfo> {
		const contactRequestInfo: ContactRequestInfo = {
			landlordId: this.landlordId,
			title,
			desc,
			sentTime: new Date().getTime()
		};
		return this.httpClient.post<ContactRequestInfo>(`${this.BACKEND_HOST}/contactRequest`, { contactRequestInfo });
	}

	// TODO: Add action to increment counter
	private incrementCounter() {
		// return this.httpClient.post<null>(`${this.BACKEND_HOST}/landlords/${landlordId}/incrementCounter`, {}).pipe();
	}

	private loadReports(): Observable<LandlordReport[]> {
		return this.httpClient.get<LandlordReport[]>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/reports`);
	}

	private updateLandlord(landlord: LandlordData): Observable<LandlordData> {
		// Update any property under /landLordId
		return this.httpClient.patch<LandlordData>(`${this.BACKEND_HOST}/landlords/${this.landlordId}`, {
			...landlord
		});
	}

	private setLandlordToken(token: string): Observable<void> {
		// This can be expanded with informations about language, version, creation time, ecc.
		return this.httpClient.post<void>(`${this.BACKEND_HOST}/users/${this.userId}/tokens`, { token });
	}

	private askForReportGeneration(options: any, hostedPageId?: string): Observable<any> {
		return this.httpClient.post<any>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/reports`, {
			...options,
			hostedPageId
		});
	}

	private requestNewPassword(password: string): Observable<void> {
		const userId = this.authService.getUserId();

		return this.httpClient.post<void>(`${this.BACKEND_HOST}/users/${userId}/password`, {
			password,
			newPassword: password
		});
	}
}
