import { createAction, props } from '@ngrx/store';
import { MinimumUserData } from 'src/app/models/common';

export enum UserActions {
	UPDATE_ONBOARDING_DATA = '[User] Update Onboarding Data',

	//SUCCESS
	UPDATE_ONBOARDING_DATA_SUCCESS = '[User] Update Onboarding Data Success',

	//FAILURE
	UPDATE_ONBOARDING_DATA_FAILURE = '[User] Update Onboarding Data Failure'
}

export const updateOnboardingDataAction = createAction(UserActions.UPDATE_ONBOARDING_DATA, props<{ userId: string; componentName: string }>());

export const updateOnboardingDataSuccessAction = createAction(UserActions.UPDATE_ONBOARDING_DATA_SUCCESS, props<{ user: MinimumUserData }>());

export const updateOnboardingDataFailureAction = createAction(UserActions.UPDATE_ONBOARDING_DATA_FAILURE, props<{ error?: any }>());
