import { SnapshotAction } from '@angular/fire/compat/database';
import { AppUtils } from '../utils/app-utils';
import { BasePageModelStore, InvoiceData } from './common';
import { AttachedFile } from './fileRm.model';
import { Property } from './property.model';

export interface PropertyWithUsage extends Property {
	busy: number;
	total: number;
	busyMonth: number;
	totalMonth: number;
	irrMonthPresent: boolean;
	irrMonth: number;
}

export interface PropertyOwnerCreate extends PropertyOwner {
	ownedProperties?: { propertyId: string; investment: number }[];
}
export class PropertyOwner {
	public creationDate = 0;

	constructor(
		public id: string = '',
		public name: string = '',
		public surname: string = '',
		public email: string = '',
		public invoiceData: InvoiceData = {},
		public files: AttachedFile[] = [],
		public ownedPropertyIds: string[] = []
	) {}

	public static clone(prop: PropertyOwner): PropertyOwner {
		return AppUtils.deepCopy(prop) as PropertyOwner;
	}

	public static sanitize(owner: PropertyOwner): PropertyOwner {
		const t = new PropertyOwner('', '', '', '', null, [], []);

		Object.assign(t, owner);

		return t;
	}

	public static getBasicInfo(o: PropertyOwner): OwnerBasic {
		return {
			id: o.id,
			name: o.name,
			surname: o.surname,
			ownedPropertyIds: o.ownedPropertyIds
		};
	}

	public static getBasicInfoMultiple(os: PropertyOwner[]): OwnerBasic[] {
		return os.map(o => this.getBasicInfo(o)).reduce((a, b) => a.concat(b), []);
	}
}

export interface OwnerBasic {
	id: string;
	name: string;
	surname: string;
	ownedPropertyIds: string[];
}

export interface OwnerPageModelStore extends BasePageModelStore {
	ownerIds: string[];
}

export interface OwnerPageModelUI {
	owners: PropertyOwner[];
	totalItems: number;
	filteredItems: number;
}
