import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/store/reducer';

const selectChannelManagerConnectionState = createSelector(
	state => state,
	(state: AppState) => state.channelManagerConnections
);

export const selectChannelManagerConnectionEntities = createSelector(selectChannelManagerConnectionState, state => {
	console.log(state.isLoaded);
	if (state.isLoaded) {
		return state.entities;
	} else {
		return null;
	}
});
