import { createReducer, on } from '@ngrx/store';
import { CalendarCategory, CalendarEvent } from 'src/app/models/calendar';
import { resetStoreAction } from 'src/app/store/actions';
import {
	loadCMUnitMetricsSuccessAction,
	loadCMMetricsAction,
	loadCMMetricsFailureAction,
	loadCMMetricsSuccessAction,
	loadCMUnitMetricsAction,
	loadMaintenancesViewAction,
	loadMaintenancesViewFailureAction,
	loadMaintenancesViewSuccessAction,
	loadOverdueIdsViewSuccessAction,
	loadPaymentsViewAction,
	loadPaymentsViewFailureAction,
	loadPaymentsViewSuccessAction,
	loadPropertiesMetricsAction,
	loadPropertiesMetricsFailureAction,
	loadPropertiesMetricsSuccessAction,
	loadTenantsMetricsAction,
	loadTenantsMetricsFailureAction,
	loadTenantsMetricsSuccessAction,
	loadUnitStatusViewAction,
	loadUnitStatusViewFailureAction,
	loadUnitStatusViewSuccessAction,
	resetPaymentDatabaseViewSuccessAction,
	loadCMUnitMetricsFailureAction,
	loadOverdueIdsViewFailureAction,
	loadOverdueIdsViewAction,
	loadEventsMetricsAction,
	loadChatMetricsAction,
	loadEventsMetricsSuccessAction,
	loadChatMetricsSuccessAction,
	loadEventsMetricsFailureAction,
	loadChatMetricsFailureAction
} from './metrics.actions';

export interface LoadableEntity {
	isLoading: boolean;
	error?: string;
}

export interface GenericMetric<T> extends LoadableEntity {
	data?: T;
}

export interface OpenMaintenancesData {
	low: number;
	medium: number;
	high: number;
	critical: number;
}
export interface UnitStatusData {
	active: number;
	starting: number;
	vacant: number;
	all: number;
}
export interface TenantMetricsData {
	turnover: number;
	appDownloads: number;
	leasesCount: number;
}

export interface EventMetricsData {
	events: CalendarEvent[];
	categories: CalendarCategory[];
}

export interface ChatMetricsData {
	messages: { photo: string; name: string; time: number; mess: string; type: string; id: string }[];
}

export interface PropertiesMetricsData {
	unitsCount: number;
	occupancyRate: { value: number; increase: number };
	unitVacancies: { value: number; increase: number };
}

export interface PropertyMetric {
	depositAmount: number;
	paidRent: number;
	overdueRent: number;
	overduePendingRent: number;
	paidExpense: number;
	overdueExpense: number;
	overduePendingExpense: number;
	paidOutgoing: number;
	overdueOutgoing: number;
	pendingOutgoing: number;
	upcomingOutgoing: number;
}

export interface TenantMetric {
	paidRent: number;
	paidExpense: number;
	depositAmount: number;
}

export interface ChannelManagerMetricsData {
	avgListingScore: number;
	optimizableUnits: number;
}

export interface ChannelManagerUnitMetricsData {
	published: number;
	errors: number;
	syncing: number;
	publishable: number;
}

export interface AmountWithCount {
	count: number;
	amount: number;
}
export interface OverdueIdsData {
	overdueTenantIds: { [key: string]: number };
	overdueUnitIds: { [key: string]: number };
	overduePropIds: { [key: string]: number };
}

export interface PaymentMetricsData {
	overdueRents: { [monthkey: string]: AmountWithCount };
	overduePendingRents: { [monthkey: string]: AmountWithCount };
	overdueExpenses: { [monthkey: string]: AmountWithCount };
	overduePendingExpenses: { [monthkey: string]: AmountWithCount };
	paidRents: { [monthkey: string]: AmountWithCount };
	paidExpenses: { [monthkey: string]: AmountWithCount };
	upcomingRents: { [monthkey: string]: AmountWithCount };
	upcomingPendingRents: { [monthKey: string]: AmountWithCount };
	upcomingExpenses: { [monthkey: string]: AmountWithCount };
	upcomingPendingExpenses: { [monthkey: string]: AmountWithCount };
	count: {
		rents: {
			[monhtkey: string]: {
				open: number;
				paid: number;
				upcoming: number;
				upcomingPending: number;
				overdue: number;
				overduePending: number;
			};
		};
		expenses: {
			[monhtkey: string]: {
				open: number;
				paid: number;
				upcoming: number;
				upcomingPending: number;
				overdue: number;
				overduePending: number;
			};
		};
	};
	movements: {
		[monthkey: string]: {
			incomes: number;
			outcomes: number;
		};
	};
	solvencyRate: {
		[monthkey: string]: {
			closed: number;
			overdue: number;
			percentage: number;
		};
	};
}

export interface OverdueMetrics extends GenericMetric<OverdueIdsData> {}
export interface OpenMaintenancesMetric extends GenericMetric<OpenMaintenancesData> {}
export interface UnitLeaseStatusMetric extends GenericMetric<UnitStatusData> {}
export interface TenantsMetrics extends GenericMetric<TenantMetricsData> {}
export interface EventsMetrics extends GenericMetric<EventMetricsData> {}
export interface ChatMetrics extends GenericMetric<ChatMetricsData> {}
export interface PropertiesMetrics extends GenericMetric<PropertiesMetricsData> {}
export interface PaymentsMetrics extends GenericMetric<PaymentMetricsData> {}
export interface ChannelManagerMetrics extends GenericMetric<ChannelManagerMetricsData> {}
export interface ChannelManagerUnitMetrics extends GenericMetric<ChannelManagerUnitMetricsData> {}

export interface MetricsState {
	overdueIds: OverdueMetrics;
	paymentsMetrics: PaymentsMetrics;
	openMaintenancesMetrics: OpenMaintenancesMetric;
	unitsMetrics: UnitLeaseStatusMetric;
	tenantsMetrics: TenantsMetrics;
	propertiesMetrics: PropertiesMetrics;
	channelManagerMetrics: ChannelManagerMetrics;
	channelManagerUnitMetrics: ChannelManagerUnitMetrics;
	eventsMetrics: EventsMetrics;
	chatMetrics: ChatMetrics;
}

function getGenericEmptyState() {
	return {
		data: null,
		isLoading: false,
		error: null
	};
}

function setSuccessStateOfGeneric<T>(payload: T): GenericMetric<T> {
	return {
		isLoading: false,
		error: undefined,
		data: payload
	};
}

function setLoadingState() {
	return { isLoading: true };
}

function setFailureStateOfGeneric<T>(errorMessage: string): GenericMetric<T> {
	return { isLoading: false, data: null, error: errorMessage || 'Error' };
}

export const metricsInitialState: MetricsState = {
	overdueIds: getGenericEmptyState(),
	paymentsMetrics: getGenericEmptyState(),
	openMaintenancesMetrics: getGenericEmptyState(),
	unitsMetrics: getGenericEmptyState(),
	tenantsMetrics: getGenericEmptyState(),
	propertiesMetrics: getGenericEmptyState(),
	channelManagerMetrics: getGenericEmptyState(),
	channelManagerUnitMetrics: getGenericEmptyState(),
	eventsMetrics: getGenericEmptyState(),
	chatMetrics: getGenericEmptyState()
};

export const MetricsReducers = createReducer(
	metricsInitialState,

	// Reset of the store we reset everything
	on(resetStoreAction, () => metricsInitialState),

	// Loading state
	on(loadTenantsMetricsAction, (state, action) => (state = { ...state, tenantsMetrics: setLoadingState() })),
	on(
		loadMaintenancesViewAction,
		(state, action) => (state = { ...state, openMaintenancesMetrics: setLoadingState() })
	),
	on(loadPropertiesMetricsAction, (state, action) => (state = { ...state, propertiesMetrics: setLoadingState() })),
	on(loadUnitStatusViewAction, (state, action) => (state = { ...state, unitsMetrics: setLoadingState() })),
	on(loadPaymentsViewAction, (state, action) => (state = { ...state, paymentsMetrics: setLoadingState() })),
	on(loadCMMetricsAction, (state, action) => (state = { ...state, channelManagerMetrics: setLoadingState() })),
	on(
		loadCMUnitMetricsAction,
		(state, action) => (state = { ...state, channelManagerUnitMetrics: setLoadingState() })
	),
	on(loadOverdueIdsViewAction, (state, payload) => (state = { ...state, overdueIds: setLoadingState() })),

	on(loadEventsMetricsAction, (state, action) => (state = { ...state, eventsMetrics: setLoadingState() })),
	on(loadChatMetricsAction, (state, action) => (state = { ...state, chatMetrics: setLoadingState() })),

	// Success State
	on(
		loadMaintenancesViewSuccessAction,
		(state, payload) => (state = { ...state, openMaintenancesMetrics: setSuccessStateOfGeneric(payload.view) })
	),
	on(
		loadTenantsMetricsSuccessAction,
		(state, payload) => (state = { ...state, tenantsMetrics: setSuccessStateOfGeneric(payload.view) })
	),
	on(
		loadPropertiesMetricsSuccessAction,
		(state, payload) => (state = { ...state, propertiesMetrics: setSuccessStateOfGeneric(payload.view) })
	),
	on(
		loadUnitStatusViewSuccessAction,
		(state, payload) => (state = { ...state, unitsMetrics: setSuccessStateOfGeneric(payload.view) })
	),
	on(
		loadPaymentsViewSuccessAction,
		(state, payload) => (state = { ...state, paymentsMetrics: setSuccessStateOfGeneric(payload.views) })
	),
	on(
		loadCMMetricsSuccessAction,
		(state, payload) => (state = { ...state, channelManagerMetrics: setSuccessStateOfGeneric(payload.view) })
	),
	on(
		loadCMUnitMetricsSuccessAction,
		(state, payload) => (state = { ...state, channelManagerUnitMetrics: setSuccessStateOfGeneric(payload.view) })
	),
	on(
		loadEventsMetricsSuccessAction,
		(state, payload) => (state = { ...state, eventsMetrics: setSuccessStateOfGeneric(payload.view) })
	),
	on(
		loadChatMetricsSuccessAction,
		(state, payload) => (state = { ...state, chatMetrics: setSuccessStateOfGeneric(payload.view) })
	),
	on(
		loadOverdueIdsViewSuccessAction,
		(state, payload) => (state = { ...state, overdueIds: setSuccessStateOfGeneric(payload.view) })
	),

	// Error state
	on(
		loadMaintenancesViewFailureAction,
		(state, payload) => (state = { ...state, openMaintenancesMetrics: setFailureStateOfGeneric(payload.error) })
	),
	on(
		loadTenantsMetricsFailureAction,
		(state, payload) => (state = { ...state, tenantsMetrics: setFailureStateOfGeneric(payload.error) })
	),
	on(
		loadPropertiesMetricsFailureAction,
		(state, payload) => (state = { ...state, propertiesMetrics: setFailureStateOfGeneric(payload.error) })
	),
	on(
		loadUnitStatusViewFailureAction,
		(state, payload) => (state = { ...state, unitsMetrics: setFailureStateOfGeneric(payload.error) })
	),
	on(
		loadPaymentsViewFailureAction,
		(state, payload) => (state = { ...state, paymentsMetrics: setFailureStateOfGeneric(payload.error) })
	),
	on(
		loadCMMetricsFailureAction,
		(state, payload) => (state = { ...state, channelManagerMetrics: setFailureStateOfGeneric(payload.error) })
	),
	on(
		loadCMUnitMetricsFailureAction,
		(state, payload) => (state = { ...state, channelManagerUnitMetrics: setFailureStateOfGeneric(payload.error) })
	),
	on(
		loadEventsMetricsFailureAction,
		(state, payload) => (state = { ...state, eventsMetrics: setFailureStateOfGeneric(payload.error) })
	),
	on(
		loadChatMetricsFailureAction,
		(state, payload) => (state = { ...state, chatMetrics: setFailureStateOfGeneric(payload.error) })
	),
	on(
		loadOverdueIdsViewFailureAction,
		(state, payload) => (state = { ...state, overdueIds: setFailureStateOfGeneric(payload.error) })
	),

	on(resetPaymentDatabaseViewSuccessAction, (state, action) => (state = metricsInitialState))
);
