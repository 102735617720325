import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/store/reducer';
import { landlordReportsAdapter, LandlordState } from './landlord.reducers';

const selectLandlord = createSelector(
	state => state,
	(state: AppState) => state.landlord
);

export const selectLandlordId = createSelector(selectLandlord, (state: LandlordState) => state.data.id);
export const selectLandlordCurrency = createSelector(selectLandlord, (state: LandlordState) => state.data.currency);
export const selectLandlordData = createSelector(selectLandlord, (state: LandlordState) => state.data);
export const selectLandlordUserData = createSelector(selectLandlord, (state: LandlordState) => state.userData);
const selectLandlordReportsState = createSelector(selectLandlord, (state: LandlordState) => state.reports);
export const { selectAll: selectLandlordReports } = landlordReportsAdapter.getSelectors(selectLandlordReportsState);
