import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { deletePropertySuccessAction } from 'src/app/properties/state/properties.actions';
import {
	addTenantSuccessAction,
	archiveTenantSuccessAction,
	deleteTenantSuccessAction,
	editTenantSuccessAction,
	loadTenantsSuccessAction,
	loadTenantSuccessAction,
	recoverTenantSuccessAction,
	resetTenantStateSuccessAction,
	uploadMultipleTenantsSuccessAction
} from './tenant.actions';
import { addMockMaintenanceSuccessAction } from 'src/app/maintenances/state/maintenances.actions';
import {
	addMockLeasesSuccessAction,
	createLeaseSuccessAction,
	removeLeaseSuccessAction,
	updateLeaseSuccessAction
} from 'src/app/leases/state/lease.actions';
import { Tenant, TenantBasic } from 'src/app/models/tenant.model';
import { EntityLoadable, setSuccessStateOfGeneric } from 'src/app/utils/store-utils';

export interface TenantsBasicState extends EntityLoadable<TenantBasic> {}
export const tenantsBasicAdapter = createEntityAdapter<TenantBasic>();

const initialTenantsBasicState = tenantsBasicAdapter.getInitialState({
	isLoaded: false,
	isLoading: false,
	error: null
});

export const TenantsBasicReducer = createReducer(
	initialTenantsBasicState,
	on(resetTenantStateSuccessAction, (state, payload) => (state = initialTenantsBasicState)),
	on(loadTenantSuccessAction, addTenantSuccessAction, createLeaseSuccessAction, (state, payload) =>
		tenantsBasicAdapter.upsertOne(Tenant.getBasicInfo(payload.tenant), { ...state })
	),
	on(editTenantSuccessAction, updateLeaseSuccessAction, removeLeaseSuccessAction, (state, payload) => {
		if (payload.tenant) {
			return tenantsBasicAdapter.updateOne(
				{ id: payload.tenant.id, changes: Tenant.getBasicInfo(payload.tenant) },
				state
			);
		} else {
			return state;
		}
	}),
	on(deleteTenantSuccessAction, (state, payload) => {
		return tenantsBasicAdapter.removeOne(payload.deletedTenantId, state);
	}),
	on(loadTenantsSuccessAction, (state, payload) =>
		setSuccessStateOfGeneric(state, tenantsBasicAdapter, payload.tenants, payload.refreshAll)
	),
	on(deletePropertySuccessAction, (state, payload) => {
		if (payload.tenants) {
			if (payload['refreshAll']) {
				return tenantsBasicAdapter.setAll(Tenant.getBasicInfoMultiple(payload.tenants), {
					...state,
					isLoaded: true
				});
			} else {
				return tenantsBasicAdapter.upsertMany(Tenant.getBasicInfoMultiple(payload.tenants), {
					...state,
					isLoaded: true
				});
			}
		} else {
			return state;
		}
	}),
	on(archiveTenantSuccessAction, (state, payload) =>
		tenantsBasicAdapter.updateOne({ id: payload.tenant.id, changes: Tenant.getBasicInfo(payload.tenant) }, state)
	),
	on(recoverTenantSuccessAction, (state, payload) =>
		tenantsBasicAdapter.updateOne({ id: payload.tenant.id, changes: Tenant.getBasicInfo(payload.tenant) }, state)
	),
	on(uploadMultipleTenantsSuccessAction, (state, payload) =>
		tenantsBasicAdapter.upsertMany(Tenant.getBasicInfoMultiple(payload.tenants), state)
	),

	on(addMockLeasesSuccessAction, addMockMaintenanceSuccessAction, (state, payload) => {
		let tenants = [];
		payload.leases.forEach(lResult => {
			if (lResult.tenant) {
				tenants.push(lResult.tenant);
			}
		});

		if (tenants.length > 0) {
			return tenantsBasicAdapter.upsertMany(Tenant.getBasicInfoMultiple(tenants), state);
		} else {
			return state;
		}
	})
);
