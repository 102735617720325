import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Tenant } from 'src/app/models/tenant.model';

@Component({
	selector: 'app-tenant-post-add-dialog',
	templateUrl: './tenant-post-add-dialog.component.html',
	styleUrls: ['./tenant-post-add-dialog.component.scss']
})
export class TenantPostAddDialogComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<TenantPostAddDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: Tenant
	) {}

	ngOnInit() {}

	cancelClick() {
		this.dialogRef.close();
	}

	addTenant() {
		this.dialogRef.close({ action: 'addTenant' });
	}

	addLease() {
		this.dialogRef.close({ action: 'addLease' });
	}
}
