import { LocalizationUtils } from './localization-utils';

export type AvailabilityType =
	| 'PAST_LEASE'
	| 'CURRENT_LEASE'
	| 'BOOKING_REQUEST'
	| 'BLOCKED_INTERVAL_BOOKING'
	| 'COMING_LEASE';

export class AvailabilityUtils {
	static getColorForType(type: AvailabilityType): [number, number, number] {
		switch (type) {
			case 'PAST_LEASE':
				return [174, 184, 192];
			case 'CURRENT_LEASE':
				return [62, 213, 152];
			case 'BOOKING_REQUEST':
				return [11, 147, 239];
			case 'BLOCKED_INTERVAL_BOOKING':
				return [255, 122, 122];
			case 'COMING_LEASE':
				return [255, 153, 0];
		}
	}

	static getTitleForType(type: AvailabilityType): string {
		switch (type) {
			case 'PAST_LEASE':
				return LocalizationUtils.getTranslations().calendar.past_lease;
			case 'CURRENT_LEASE':
				return LocalizationUtils.getTranslations().calendar.current_lease;
			case 'BOOKING_REQUEST':
				return LocalizationUtils.getTranslations().calendar.booking_request;
			case 'BLOCKED_INTERVAL_BOOKING':
				return LocalizationUtils.getTranslations().calendar.blocked_interval_booking;
			case 'COMING_LEASE':
				return LocalizationUtils.getTranslations().calendar.coming_lease;
		}
	}

	static getStringDate(date: Date): string {
		const day = date.getDate();
		const month = LocalizationUtils.getTranslations().months[date.getMonth()];
		const year = date.getFullYear();

		return `${day} ${this.capitalizeString(month)} ${year}`;
	}

	static capitalizeString(str: string): string {
		return `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`;
	}
}
