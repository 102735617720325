import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/store/reducer';
import { tenantsAdapter } from './tenant.reducers';

const selectTenantsState = createSelector(
	state => state,
	(state: AppState) => state.tenants
);

export const { selectTotal: selectTenantsLength } = tenantsAdapter.getSelectors(selectTenantsState);

export const selectTenantsEntities = createSelector(selectTenantsState, state => {
	if (state.isLoaded) {
		return state.entities;
	}
	return null;
});
