import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnotifyService, ToastComponent } from 'ng-snotify';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BillingService } from 'src/app/billing/billing.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { LandlordService } from 'src/app/core/services/landlord/landlord.service';
import { PresenceService } from 'src/app/core/services/presence.service';
import { LandlordData } from 'src/app/models/landlord';
import { PropertyService } from 'src/app/properties/property.service';

@Component({
	selector: 'app-conditions-changing-dialog',
	templateUrl: './conditions-changing-dialog.component.html',
	styleUrls: ['./conditions-changing-dialog.component.scss']
})
export class ConditionsChangingDialogComponent implements OnInit, OnDestroy {
	public landlordName: string;
	private componentDestroyed = new Subject();
	private landlordData: LandlordData;
	confirmCheckbox: boolean = false;
	isAdmin: boolean = true;

	constructor(
		public dialogRef: MatDialogRef<ConditionsChangingDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: string,
		public authService: AuthService,
		private readonly landlordService: LandlordService,
		private toastService: SnotifyService
	) {}

	ngOnDestroy(): void {
		this.componentDestroyed.next();
	}

	ngOnInit(): void {
		this.landlordService
			.getLandlordUserData()
			.pipe(takeUntil(this.componentDestroyed))
			.subscribe(data => {
				this.landlordData = data as unknown as LandlordData;
				this.isAdmin = data.role === 'admin';
				this.landlordName = data.name + ' ' + data.surname;
			});
	}

	getLandlordName(): string {
		return this.landlordName;
	}

	selectionChange(event) {
		this.confirmCheckbox = event.checked;
	}

	closeClick() {
		const updatedLandlordData = {
			...this.landlordData,
			lastDialogShownTime: Date.now()
		};
		this.landlordService.updateLandlord(updatedLandlordData);
		this.dialogRef.close({ action: 'none' });
	}

	confirmClick() {
		if (!this.confirmCheckbox) {
			this.toastService.error(
				$localize`:@@checkbox_not_checked: Click the checkbox to confirm that you have read and acknowledged the changes`,
				''
			);
			return;
		}

		const updatedLandlordData = {
			...this.landlordData,
			lastDialogShownTime: 0
		};
		this.landlordService.updateLandlord(updatedLandlordData);
		this.dialogRef.close({ action: 'none' });
	}
}
