<div class="dialog">
	<div class="dialog-header">
		<div>
			<img src="./assets/img/add_payment.svg" />
			<span class="medium-wide-20" i18n="@@pay_withdrawal_title">Withdrawal</span>
		</div>
		<img class="img-close rf-is-clickable" src="./assets/img/close.svg" (click)="cancelClick()" />
	</div>
	<div class="dialog-content">
		<p>
			<span i18n="@@pay_withdrawal_info" class="color-1">Withdrawal info</span>
			<span class="info_msg" i18n="@@pay_withdrawal_info_view_msg">
				This is the amount you transferred to your bank account.<br />
				Depending on your bank and other factors this may take up to 5 working days.
			</span>
		</p>
		<p>
			<span i18n="@@pay_status_withdrawn" class="color-1">Withdrawal status</span>
			<button disabled class="status-fail" [ngStyle]="{ 'background-color': statusColor }">{{ status }}</button>
		</p>
		<div>
			<mat-form-field appearance="outline">
				<mat-label i18n="@@pay_amount">Amount</mat-label>
				<input
					disabled
					type="text"
					matInput
					[value]="
						(amountWithdrawn | formatAmountNoCode: currentCurrency) + (currentCurrency | currencySymbol)
					"
				/>
			</mat-form-field>
			<mat-form-field appearance="outline">
				<mat-label i18n="@@pay_date">Date</mat-label>
				<input disabled type="text" matInput [value]="dateWithdrawn | dfnsFormat: 'dd/MM/yyyy'" />
			</mat-form-field>
		</div>
		<p>
			<span *ngIf="failureReason" i18n="@@pay_failure_info_title" class="color-2">Failure reason</span>
			<span class="info_msg">
				{{ failureReason }}
			</span>
		</p>
	</div>

	<div class="dialog-actions">
		<button mat-button class="cancel-button" (click)="cancelClick()" i18n="@@com_cancel">Cancel</button>
		<span class="dialog-actions-spacer"></span>
		<button mat-button class="ok-button" (click)="okClick()" i18n="@@com_ok">Ok</button>
	</div>
</div>
