import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { endOfDay, startOfDay } from 'date-fns';
import { combineLatest, from, Observable, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FileEntities, FileService } from '../core/services/file/file.service';
import { FiltersStoreModel, PaymentQueryFilters } from '../core/services/filters/filters.model';
import { selectFiltersEntities } from '../core/services/filters/state/filters.selector';
import { LandlordService } from '../core/services/landlord/landlord.service';
import { LeaseService } from '../leases/lease.service';
import { FileWrapper } from '../models/fileRm.model';
import { Lease } from '../models/lease.model';
import {
	PaymentItem,
	PaymentPageModelStore,
	PaymentPageModelUI,
	PropertyPayment,
	RecurringPayment,
	TaxInfo
} from '../models/payments';
import { OwnersService } from '../owners/owners.service';
import { CallbackFunctions } from '../store/reducer';
import { TenantService } from '../tenants/tenant.service';
import { AppUtils } from '../utils/app-utils';
import { PaymentsCategoriesService } from './paymentsCategories.service';
import { PaymentsOperationsService } from './paymentsOperations.service';
import { selectPaymentsEntities } from './state/newPayments.selector';
import { selectPaymentPagesEntities } from './state/payments-pages.selector';
import {
	createPaymentAction,
	createPaymentSuccessAction,
	deletePaymentAction,
	deletePaymentsAction,
	getMarginsAction,
	getMarginsSuccessAction,
	loadOpenPaymentsByTenantAction,
	loadPaymentByIdAction,
	loadPaymentByIdSuccessAction,
	loadPaymentsByDateAndPropertiesAction,
	loadPaymentsByIdsAction,
	loadPaymentsByOwnerAction,
	loadPaymentsByOwnerPlusConnectedRentsAction,
	loadPaymentsByPropertyAction,
	loadPaymentsPaginatedAction,
	lockPaymentsAction,
	updatePaymentAction
} from './state/payments.actions';

export enum PaymentType {
	IN = 'in',
	OUT = 'out',
	REIMBURSEMENT = 'reimbursement'
}

export interface PaymentCategory {
	name: string;
	id: string;
	blocked?: boolean;
	reason?: string;
}

@Injectable({
	providedIn: 'root'
})
export class PaymentsService {
	private landlordId: string;
	private currency: string;
	private BACKEND_HOST = `${environment.services.backend}/api-dash/v1`;

	constructor(
		private readonly fileService: FileService,
		private readonly leaseService: LeaseService,
		private readonly store: Store,
		private readonly landlordService: LandlordService,
		private readonly actions$: Actions,
		private readonly paymentsCategoriesService: PaymentsCategoriesService,
		private readonly paymentsOperationsService: PaymentsOperationsService,
		private readonly httpClient: HttpClient,
		private ownerService: OwnersService,
		private tenantService: TenantService
	) {
		combineLatest([this.landlordService.getLandlordData()]).subscribe(([landlord]) => {
			this.landlordId = landlord.id;
			this.currency = landlord.currency;
		});
	}

	public loadPaymentById(paymentId: string): Observable<PropertyPayment> {
		return this.httpClient.get<PropertyPayment>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/payments/${paymentId}`
		);
	}

	public getPaymentById(paymentId: string): Promise<PropertyPayment> {
		return this.loadPaymentById(paymentId).pipe(take(1)).toPromise();
	}

	public getPaymentsById(paymentsId: string[]): Promise<PropertyPayment[]> {
		return Promise.all(paymentsId.map(paymentId => this.getPaymentById(paymentId).catch(e => {}))) // Getting payments: in case of error I return nothing
			.then(res => res.filter(it => !!it) as PropertyPayment[]); // Filtering payents with error
	}

	public loadPaymentsByIds(paymentIds: string[]): Observable<PropertyPayment[]> {
		if (paymentIds.length === 0) {
			return of([]);
		}

		return from(
			AppUtils.buildActionWithPromise<PropertyPayment[]>(this.store, loadPaymentsByIdsAction({ paymentIds }))
				.then(payments => {
					return payments;
				})
				.catch(() => {
					console.log('[Payments Service] - ERROR on loadPaymentsByIdsAction');
					return [];
				})
		);
	}

	public requestReceiptForPayment(paymentId: string): Observable<any> {
		return this.httpClient.post<any>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/receipts/${paymentId}`, {});
	}

	public requestRecurringPaymentNextExecution(recurringPaymentId: string): Observable<{ payment: PropertyPayment }> {
		return this.httpClient
			.get<{ payment: PropertyPayment }>(
				`${this.BACKEND_HOST}/landlords/${this.landlordId}/recurringPayments/${recurringPaymentId}/execute`
			)
			.pipe(
				tap(result => {
					if (result.payment) {
						this.store.dispatch(loadPaymentByIdSuccessAction({ payment: result.payment }));
					}
				})
			);
	}

	createPayment(
		payment: PropertyPayment,
		callbacks?: CallbackFunctions,
		isMaintenanceClosingPayment?: boolean
	): Promise<PropertyPayment> {
		return AppUtils.buildActionWithPromise<PropertyPayment>(
			this.store,
			createPaymentAction({ payment, isMaintenanceClosingPayment, callbacks })
		);
	}

	removePayment(paymentId: string) {
		return AppUtils.buildActionWithPromise<void>(this.store, deletePaymentAction({ paymentId: paymentId }));
	}

	removePayments(isAllSelected: boolean, payments: PropertyPayment[]): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			const callbacks = {
				success: () => {
					resolve();
				},
				fail: () => {
					reject();
				}
			};
			this.store.dispatch(deletePaymentsAction({ payments, isAllSelected, callbacks }));
		});
	}

	updatePayment(payment: PropertyPayment): Promise<PropertyPayment> {
		// Just for now, this fixes the payment not marked as paid. This will be done on the backend
		if (payment.amount <= payment.amountPaid) {
			payment.status = 'close';
			payment.closingDate = Date.now();
		}

		// If we edit a deposit, edit the lease accordingly
		if (payment.category === 'deposit_in') {
			const depositInItem = payment.extraFees.find(it => it.category === 'deposit_in');
			this.leaseService.editLeaseDepositAmount(payment.leaseId, depositInItem.amount);
		}

		return AppUtils.buildActionWithPromise<PropertyPayment>(this.store, updatePaymentAction({ payment }));
	}

	lockPaymentsOnDeposit(lockingPayment: PropertyPayment, lockedPaymentsId: string[]): Promise<void> {
		const action = lockPaymentsAction({
			paymentsId: lockedPaymentsId,
			lockedBy: lockingPayment.id,
			lockerType: 'deposit'
		});

		return AppUtils.buildActionWithPromise<void>(this.store, action);
	}

	createDepositFromLease(
		lease: Lease,
		tenantId: string,
		amount: number,
		paid: boolean,
		relevantTime: number, // Due date or date paid depending on paid variable
		files: FileWrapper[],
		notes: string
	): Promise<PropertyPayment> {
		const todayDate = new Date();
		const landlordId = this.landlordId;

		const payment: PropertyPayment = {
			title: '',
			category: 'deposit_in',
			creationDate: todayDate.getTime(),
			referenceDate: todayDate.getTime(),
			dueDate: relevantTime,
			amount: amount,
			currency: lease.currency || this.currency,
			tenantId: tenantId,
			propertyId: lease.propertyId,
			leaseId: lease.id,
			landlordId: landlordId,
			note: notes,
			attachments: [],
			authorId: landlordId,
			status: 'open',
			id: '',
			amountPaid: 0,
			extraFees: [
				new PaymentItem(
					'deposit_in',
					this.paymentsCategoriesService.getCategoryName('deposit_in'),
					amount,
					'deposit_in',
					lease.id
				)
			],
			metadata: {}
		};

		return AppUtils.buildActionWithPromise<PropertyPayment>(this.store, createPaymentAction({ payment })).then(
			newPayment => {
				this.fileService.syncFileWrappers(FileEntities.PAYMENT, newPayment.id, files);
				if (paid) {
					this.paymentsOperationsService.markAsPaid(newPayment, amount, relevantTime, 'cash');
				}
				return newPayment;
			}
		);
	}

	returnDepositWithCustomExpenses(
		lease: Lease,
		relevantTime: number, // Date paid depending on paid variable
		files: FileWrapper[],
		linkedPayments: PropertyPayment[],
		extraCosts: PaymentItem[],
		notes: string,
		paymentAlreadyMade: boolean = false
	): Promise<PropertyPayment> {
		const tenantId = Object.keys(lease.tenantsId)[0];
		const depositAmount = lease.deposit;

		const paymentsItems: PaymentItem[] = linkedPayments.map(it => {
			const item = new PaymentItem(
				it.category,
				this.paymentsCategoriesService.getCategoryName(it.category),
				it.amount - it.amountPaid,
				it.category,
				it.id
			);
			return item;
		});

		const paymentLists = [...paymentsItems, ...extraCosts];
		const itemsAmount = paymentLists.map(it => it.amount).reduce((a, b) => a + b, 0);

		// Main differences are the TYPE (in, out) and the amount (deposit positive in costs, )
		const basePayment =
			itemsAmount > depositAmount
				? this.buildPaymentForAdjustment(depositAmount, itemsAmount, lease.id, paymentLists)
				: this.buildPaymentForDepositOut(depositAmount, itemsAmount, lease.id, paymentLists);

		const payment: PropertyPayment = {
			...basePayment,
			title: '',
			creationDate: Date.now(),
			referenceDate: Date.now(),
			dueDate: relevantTime || Date.now(),
			currency: lease.currency,
			tenantId: tenantId,
			propertyId: lease.propertyId,
			leaseId: lease.id,
			landlordId: this.landlordId,
			note: notes,
			attachments: [],
			authorId: this.landlordId,
			status: 'open',
			amountPaid: 0,
			metadata: {}
		} as PropertyPayment;

		return AppUtils.buildActionWithPromise<PropertyPayment>(this.store, createPaymentAction({ payment }))
			.then(payment => {
				// Once the payment is created, I lock the payments included with the deposit OR the adjustment

				this.fileService.syncFileWrappers(FileEntities.PAYMENT, payment.id, files);

				if (linkedPayments.length === 0) {
					return payment;
				}

				return this.lockPaymentsOnDeposit(
					payment,
					linkedPayments.map(payment => payment.id)
				).then(() => {
					return payment;
				});
			})
			.then(payment => {
				// Then I marked as paid: in this case, I'm not waiting for this operation to complete
				if (paymentAlreadyMade) {
					this.paymentsOperationsService.markAsPaid(
						payment,
						payment.amount - payment.amountPaid,
						Date.now(),
						'other'
					);
				}

				return payment;
			});
	}

	private buildPaymentForDepositOut(
		depositAmount: number,
		itemsAmount: number,
		leaseId: string,
		itemsToAttach: PaymentItem[]
	): Partial<PropertyPayment> {
		const finalAmount = depositAmount - itemsAmount;

		const depositItem: PaymentItem = {
			amount: depositAmount,
			code: 'deposit_in',
			category: 'deposit_in',
			name: '',
			linkedId: leaseId
		};

		const extraFees = [
			depositItem,
			...itemsToAttach.map(item => {
				item.amount = -item.amount;
				return item;
			})
		];

		const payment: Partial<PropertyPayment> = {
			type: PaymentType.OUT,
			category: 'deposit_out',
			amount: finalAmount,
			extraFees
		};

		return payment;
	}

	private buildPaymentForAdjustment(
		depositAmount: number,
		itemsAmount: number,
		leaseId: string,
		itemsToAttach: PaymentItem[]
	): Partial<PropertyPayment> {
		const finalAmount = itemsAmount - depositAmount;

		const depositItem: PaymentItem = {
			amount: -depositAmount,
			code: 'deposit_in',
			category: 'deposit_in',
			name: '',
			linkedId: leaseId
		};

		const extraFees = [depositItem, ...itemsToAttach];

		const payment: Partial<PropertyPayment> = {
			type: PaymentType.IN,
			category: 'adjustment',
			amount: finalAmount,
			extraFees
		};

		return payment;
	}

	createPaymentFromMaintenanceClosing(
		amount: number,
		alreadyPaid: boolean,
		propertyId: string,
		tenantIds: { tenantId: string; leaseId: string }[] = [],
		choosenDate: Date = new Date(),
		maintenanceId: string,
		maintenanceTitle: string
	) {
		const landlordId = this.landlordId;
		const isPaidByLandlord = tenantIds.length === 0;
		const todayDate = new Date();

		if (isPaidByLandlord) {
			const payment: PropertyPayment = {
				title: '',
				category: 'maintenance',
				creationDate: todayDate.getTime(),
				referenceDate: todayDate.getTime(),
				dueDate: choosenDate.getTime(),
				amount: amount,
				currency: this.currency,
				//tenantId: undefined,
				propertyId: propertyId,
				//leaseId: undefined,
				landlordId: landlordId,
				note: maintenanceTitle,
				attachments: [],
				authorId: landlordId,
				status: 'open',
				id: '',
				amountPaid: 0,
				extraFees: [
					{
						taxInfo: TaxInfo.NONE(),
						category: 'maintenance',
						code: 'maintenance',
						amount,
						name: ''
					}
				],
				metadata: { linkedMaintenance: maintenanceId }
			};

			this.createPayment(payment, undefined, true);

			if (alreadyPaid) {
				this.actions$.pipe(ofType(createPaymentSuccessAction), take(1)).subscribe(action => {
					this.paymentsOperationsService.markAsPaid(
						action.payment,
						action.payment.amount,
						endOfDay(choosenDate).getTime(),
						undefined,
						undefined,
						true
					);
				});
			}
		} else {
			const tenantAmount = Math.round(amount / tenantIds.length); // Integer number
			const payments = tenantIds.map(t => {
				const payment: PropertyPayment = {
					title: '',
					category: 'maintenance',
					creationDate: todayDate.getTime(),
					referenceDate: todayDate.getTime(),
					dueDate: choosenDate.getTime(),
					amount: tenantAmount,
					currency: this.currency,
					tenantId: t.tenantId,
					propertyId: propertyId,
					leaseId: t.leaseId,
					landlordId: landlordId,
					note: maintenanceTitle,
					attachments: [],
					authorId: landlordId,
					status: 'open',
					id: '',
					amountPaid: 0,
					extraFees: [
						{
							taxInfo: TaxInfo.NONE(),
							category: 'maintenance',
							code: 'maintenance',
							amount: tenantAmount,
							name: ''
						}
					],
					metadata: { linkedMaintenance: maintenanceId }
				};

				return payment;
			});

			payments.map(payment => this.createPayment(payment, undefined, true));

			if (alreadyPaid) {
				this.actions$.pipe(ofType(createPaymentSuccessAction), take(payments.length)).subscribe(action => {
					this.paymentsOperationsService.markAsPaid(
						action.payment,
						action.payment.amount,
						endOfDay(choosenDate).getTime(),
						undefined,
						undefined,
						true
					);
				});
			}
		}
	}

	public getPaymentsByDateAndProperties(
		minDate: Date,
		maxDate: Date,
		propertyIds: string[]
	): Observable<Dictionary<PropertyPayment>> {
		const dateFrom = startOfDay(minDate).getTime();
		const dateTo = endOfDay(maxDate).getTime();

		return from(
			AppUtils.buildActionWithPromise<Dictionary<PropertyPayment>>(
				this.store,
				loadPaymentsByDateAndPropertiesAction({ dateFrom, dateTo, propertyIds })
			)
				.then(paymentDict => {
					return paymentDict;
				})
				.catch(() => {
					console.log('[Payments Service] - ERROR on loadPaymentsByDateAndPropertiesAction');
					return {};
				})
		);
	}

	public getOpenPaymentsByTenant(tenantId: string): Observable<Dictionary<PropertyPayment>> {
		return from(
			AppUtils.buildActionWithPromise<Dictionary<PropertyPayment>>(
				this.store,
				loadOpenPaymentsByTenantAction({ tenantId })
			)
				.then(paymentDict => {
					return paymentDict;
				})
				.catch(() => {
					console.log('[Payments Service] - ERROR on loadOpenPaymentsByTenantAction');
					return {};
				})
		);
	}

	public getPaymentsByProperty(filters: PaymentQueryFilters): Observable<Dictionary<PropertyPayment>> {
		return from(
			AppUtils.buildActionWithPromise<Dictionary<PropertyPayment>>(
				this.store,
				loadPaymentsByPropertyAction({ filters })
			)
				.then(paymentDict => {
					return paymentDict;
				})
				.catch(() => {
					console.log('[Payments Service] - ERROR on loadPaymentsByPropertyAction');
					return {};
				})
		);
	}

	public getPaymentsByOwner(ownerId: string, limitTo: number = 0): Observable<Dictionary<PropertyPayment>> {
		return from(
			AppUtils.buildActionWithPromise<Dictionary<PropertyPayment>>(
				this.store,
				loadPaymentsByOwnerAction({ ownerId })
			)
				.then(paymentDict => {
					return paymentDict;
				})
				.catch(() => {
					console.log('[Payments Service] - ERROR on loadPaymentsByOwnerAction');
					return {};
				})
		);
	}

	public getPaymentsByOwnerPlusConnectedRents(ownerId: string): Observable<Dictionary<PropertyPayment>> {
		return from(
			AppUtils.buildActionWithPromise<Dictionary<PropertyPayment>>(
				this.store,
				loadPaymentsByOwnerPlusConnectedRentsAction({ ownerId })
			)
				.then(paymentDict => {
					return paymentDict;
				})
				.catch(() => {
					console.log('[Payments Service] - ERROR on loadPaymentsByOwnerPlusConnectedRentsAction');
					return {};
				})
		);
	}

	public getExternalPaymentUpdatesObservable(paymentIds: string[]): Observable<boolean> {
		return this.actions$.pipe(
			ofType(loadPaymentByIdAction),
			filter(loadAction => paymentIds.includes(loadAction.paymentId)),
			map(() => true)
		);
	}

	public getPaymentDataForPopCard(paymentId: string) {
		return from(this.getPaymentById(paymentId)).pipe(
			take(1),
			switchMap(payment => {
				return this.getAssigneeName(payment).pipe(
					take(1),
					map(assigneeName => {
						const paymentTitle = payment.title
							? payment.title
							: this.paymentsCategoriesService.getCategoryName(payment.category, '');
						const paymentStatus = PropertyPayment.getStatusFromPayment(payment);
						let paidOn = null;
						if (paymentStatus === 'close') {
							paidOn = payment.closingDate;
						}
						return {
							id: payment.id,
							title: paymentTitle,
							assigneeName,
							paidOn,
							invoiced: !!(Object.keys(payment?.invoices || {}).length > 0)
						};
					})
				);
			})
		);
	}

	public getAssigneeName(payment: Partial<PropertyPayment>): Observable<string> {
		try {
			if (payment.ownerId) {
				return this.ownerService.getOwnerBasicById(payment.ownerId).pipe(map(it => it.name + ' ' + it.surname));
			} else if (payment.tenantId) {
				return this.tenantService.getTenantNameById(payment.tenantId);
			} else {
				return of($localize`:@@com_you:You`);
			}
		} catch (error) {
			console.log('Error while getting Assignee Name');
			return of('-');
		}
	}

	public getPaymentsPaginated(pageSize: number): Promise<PaymentPageModelUI> {
		return combineLatest([this.store.select(selectFiltersEntities), this.store.select(selectPaymentPagesEntities)])
			.pipe(
				take(1),
				switchMap(
					([storeFilters, storePaymentPages]: [
						Dictionary<FiltersStoreModel>,
						Dictionary<PaymentPageModelStore>
					]) => {
						if (
							storeFilters &&
							storePaymentPages &&
							storePaymentPages[storeFilters['payments']?.pageIndex || 0]
						) {
							return combineLatest([
								of(storeFilters),
								of(storePaymentPages),
								this.store.select(selectPaymentsEntities)
							]);
						} else {
							return combineLatest([of(null), of(null), of(null)]);
						}
					}
				),
				switchMap(
					([storeFilters, storePaymentPages, paymentsDict]: [
						Dictionary<FiltersStoreModel>,
						Dictionary<PaymentPageModelStore>,
						Dictionary<PropertyPayment>
					]) => {
						if (storeFilters !== null && storePaymentPages && paymentsDict) {
							const sortedPaymentIds =
								storePaymentPages[storeFilters['payments']?.pageIndex || 0].paymentIds;

							const filteredPayments = Object.values(paymentsDict)
								.filter(payment => sortedPaymentIds.includes(payment.id))
								.sort((a, b) => sortedPaymentIds.indexOf(a.id) - sortedPaymentIds.indexOf(b.id));

							console.log(
								'[SERVICE] page foud and valid => return it: (' + storeFilters['payments']?.pageIndex ||
									0 + ' - ' + pageSize + ')'
							);

							return of({
								payments: filteredPayments,
								totalItems: storePaymentPages[storeFilters['payments']?.pageIndex || 0].totalItems || 0,
								filteredItems:
									storePaymentPages[storeFilters['payments']?.pageIndex || 0].filteredItems || 0
							});
						} else {
							console.log('[SERVICE] page NOT foud => return null');
							return of(null);
						}
					}
				),
				take(1)
			)
			.toPromise();
	}

	public loadPaymentsPage(pageSize: number) {
		this.store
			.select(selectFiltersEntities)
			.pipe(take(1))
			.subscribe(storeFilters => {
				console.log(
					'[SERVICE] dispatch LOAD page ' +
						(storeFilters['payments']?.pageIndex || 0) +
						' ' +
						pageSize +
						' ' +
						JSON.stringify(storeFilters['payments']?.sort || {}) +
						' -->' +
						JSON.stringify(storeFilters['payments']?.filters || {})
				);
				this.store.dispatch(
					loadPaymentsPaginatedAction({
						page: storeFilters['payments']?.pageIndex || 0,
						pageSize: pageSize,
						filters: storeFilters['payments']?.filters || {},
						sort: storeFilters['payments']?.sort || {},
						entityType: storeFilters['payments']?.entityType,
						entityId: storeFilters['payments']?.entityId
					})
				);
			});
	}

	public getLinkedRecurringPayment(recurringPaymentId: string) {
		return this.httpClient.get<RecurringPayment>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/recurringPayments/${recurringPaymentId}`
		);
	}

	public getMargins(propertyId?: string, ownerId?: string): Observable<any> {
		const action = getMarginsAction({
			propertyId: propertyId,
			ownerId: ownerId
		});

		this.store.dispatch(action);

		return this.actions$.pipe(ofType(getMarginsSuccessAction));
	}
}
