<div class="rf-dash-lable-simple" [ngClass]="{ 'rf-two-lines': isTwoLines }">
	<p class="rf-label" [ngStyle]="{ 'text-align': align }">{{ label }}</p>
	<p
		class="rf-content"
		[ngClass]="{
			'rf-is-font-medium-weight': isBold,
			'rf-is-letter-green': color === 'green' || color === 'color-priority-1',
			'rf-is-letter-red': color === 'red' || color === 'color-priority-3',
			'rf-is-letter-orange': color === 'orange' || color === 'color-priority-2',
			'rf-is-letter-blue': color === 'blue',
			'rf-is-letter-light-blue': color === 'light-blue',
			'rf-is-letter-light-green': color === 'color-priority-0',
			'rf-is-multiline': multiline,
			'rf-is-two-lines-text': isTwoLines,
			'rf-is-max-height': maxHeight,
			'rf-two-lines': isTwoLines
		}"
		[ngStyle]="{ 'text-align': align, 'max-height': suggestedHeight }"
	>
		<ng-content></ng-content>
	</p>
</div>
