import { createAction, props } from '@ngrx/store';
import { ChannelConnection } from 'src/app/models/channelManager.model';
import { QueryResult } from 'src/app/models/common';
import { CallbackFunctions } from 'src/app/store/reducer';

export enum ChannelManagerActions {
	//Effect actions
	LOAD_CHANNEL_CONNECTIONS = '[Channel Manager] Load Channel Manager Connections',
	UPDATE_CHANNEL_CONNECTION = '[Channel Manager] Update Channel Manager Connection',

	// Reducer Success Actions
	LOAD_CHANNEL_CONNECTIONS_SUCCESS = '[Channel Manager] Load Channel Manager Connections Success',
	UPDATE_CHANNEL_CONNECTION_SUCCESS = '[Channel Manager] Update Channel Manager Connection Success',

	// Reducer Failure actions
	LOAD_CHANNEL_CONNECTIONS_FAILURE = '[Channel Manager] Load Channel Manager Connections Failure',
	UPDATE_CHANNEL_CONNECTION_FAILURE = '[Channel Manager] Update Channel Manager Connection Failure'
}

// Effect Actions
export const loadChannelManagerConnectionsAction = createAction(ChannelManagerActions.LOAD_CHANNEL_CONNECTIONS, props<{ callbacks?: CallbackFunctions }>());

export const updateChannelManagerConnectionAction = createAction(ChannelManagerActions.UPDATE_CHANNEL_CONNECTION, props<{ connection: Partial<ChannelConnection>; callbacks?: CallbackFunctions }>());

// Reducer Success Actions
export const loadChannelManagerConnectionsSuccessAction = createAction(ChannelManagerActions.LOAD_CHANNEL_CONNECTIONS_SUCCESS, props<{ connections: ChannelConnection[] }>());

export const updateChannelManagerConnectionActionSuccess = createAction(ChannelManagerActions.UPDATE_CHANNEL_CONNECTION_SUCCESS, props<{ connection: ChannelConnection }>());

// Reducer Failure Actions
export const loadChannelManagerConnectionsFailureAction = createAction(ChannelManagerActions.LOAD_CHANNEL_CONNECTIONS_FAILURE, props<{ error?: any }>());

export const updateChannelManagerConnectionActionFailure = createAction(ChannelManagerActions.UPDATE_CHANNEL_CONNECTION_FAILURE, props<{ error?: any }>());
