import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm-dialog/confirm-dialog.component';

import { TenantPostAddDialogComponent } from 'src/app/tenants/tenant-post-add-dialog/tenant-post-add-dialog.component';
import { ChatBroadcastComponent } from '../../chat/chat-broadcast/chat-broadcast.component';
import { LeaseService } from '../../leases/lease.service';
import { Lease } from '../../models/lease.model';
import { MangopayTransactionWrapper } from '../../models/mangopay.model';
import { Tenant } from '../../models/tenant.model';
import { WithdrawalViewDialogComponent } from '../../settings/settings-wallet/settings-wallet-withdraw/withdrawal-view-dialog/withdrawal-view-dialog.component';
import { BasicInfoDialogComponent } from '../../shared/dialogs/basic-info-dialog/basic-info-dialog.component';
import { TenantMassImportComponent } from '../../tenants/tenant-mass-import/tenant-mass-import.component';
import { AppConstants } from '../../utils/app-costants';
import { LocalizationUtils } from '../../utils/localization-utils';

@Injectable({
	providedIn: 'root'
})
export class EntityDialogsService {
	translations = LocalizationUtils.getTranslations();

	constructor(
		private readonly dialog: MatDialog,
		private readonly router: Router,
		private readonly leaseService: LeaseService
	) {}

	showDialogPostAddTenant(t: Tenant) {
		const dialogRef = this.dialog.open(TenantPostAddDialogComponent, {
			width: '720px',
			backdropClass: 'backdrop-dark-background',
			disableClose: true,
			panelClass: 'no-padding-modalbox',
			data: t
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result && result.action === 'addLease') {
				this.leaseService.addLeaseSource = 'post_add_tenant';
				this.router.navigate(['leases', 'add'], {
					state: {
						pendingLeaseProcedure: {
							pendingTenant: t
						}
					}
				});
			} else if (result && result.action === 'addTenant') {
				//this.addTenant();
			}
		});
	}

	/* Utils */
	private uniqBy(a, key) {
		const seen = {};
		return a.filter(item => {
			const k = key(item);
			return seen.hasOwnProperty(k) ? false : (seen[k] = true);
		});
	}

	onPayoutOpen(payoutTW: MangopayTransactionWrapper) {
		const dialogRef = this.dialog.open(WithdrawalViewDialogComponent, {
			...AppConstants.NEW_LATERAL_DIALOG_CONFIG,
			data: {
				...payoutTW
			}
		});

		dialogRef.afterClosed().subscribe(result => {});
	}

	showTenantImportConfirmDialog(tenantNumber: number) {
		const dialogRef = this.dialog.open(BasicInfoDialogComponent, {
			...AppConstants.CENTRAL_DIALOG_CONFIG,
			width: '400px',
			data: {
				rightButton: this.translations.tenantImportConfirmCta,
				picturePath: './assets/img/ic_import_tenant.svg',
				content: this.translations.tenantImportCtaMessage.replace('{0}', `${tenantNumber}`)
			}
		});
		dialogRef.afterClosed().subscribe(result => {});
	}

	showTenantImportDialog(): Observable<any> {
		const dialogRef = this.dialog.open(TenantMassImportComponent, AppConstants.NEW_LATERAL_DIALOG_CONFIG);
		return dialogRef.afterClosed();
	}

	showPropertyImportConfirmDialog(propertiesNumber: number) {
		const dialogRef = this.dialog.open(BasicInfoDialogComponent, {
			...AppConstants.CENTRAL_DIALOG_CONFIG,
			width: '400px',
			data: {
				rightButton: this.translations.propertyImportConfirmCta,
				picturePath: './assets/img/ic_import_property.svg',
				content: this.translations.propertyImportCtaMessage.replace('{0}', `${propertiesNumber}`)
			}
		});
		dialogRef.afterClosed().subscribe(result => {});
	}

	openEditLeaseProcedure(lease: Lease) {
		this.router.navigate(['/leases/edit', lease.id]);
	}

	openConfirmDialog(title: string, desc: string, confirmButtonText: string): Observable<boolean> {
		const dialogRef1 = this.dialog.open(ConfirmDialogComponent, {
			width: '720px',
			backdropClass: 'backdrop-dark-background',
			disableClose: true,
			panelClass: 'no-padding-modalbox',
			data: {
				confirmTitle: title,
				layoutImgURL: './assets/img/ic_green_tick.svg',
				confirmDesc: desc,
				neutralCancelButton: false,
				positiveConfirmButton: true,
				confirmButtonText: confirmButtonText
			}
		});

		return dialogRef1.afterClosed().pipe(
			filter(result => !!result.action),
			map(result => {
				return result.action === 'do_action';
			})
		);
	}

	openDeleteConfirmDialog(title: string, desc: string, confirmButtonText: string): Observable<boolean> {
		const dialogDel = this.dialog.open(ConfirmDialogComponent, {
			width: '720px',
			backdropClass: 'backdrop-dark-background',
			disableClose: true,
			panelClass: 'no-padding-modalbox',
			data: {
				confirmTitle: title,
				layoutImgURL: './assets/img/ic_delete.svg',
				confirmDesc: desc,
				neutralCancelButton: true,
				positiveConfirmButton: false,
				confirmButtonText: confirmButtonText
			}
		});

		return dialogDel.afterClosed().pipe(
			filter(result => !!result.action),
			map(result => {
				return result.action === 'do_action';
			})
		);
	}

	handleInvoceExportResul() {}
}
