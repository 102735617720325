import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ChannelConnection } from 'src/app/models/channelManager.model';
import {
	loadChannelManagerConnectionsSuccessAction,
	updateChannelManagerConnectionActionSuccess
} from './connections.actions';

export interface ChannelManagerConnectionsState extends EntityState<ChannelConnection> {
	isLoaded: boolean;
}
export const channelManagerConnectionsAdapter = createEntityAdapter<ChannelConnection>({
	selectId: model => model.id
});
const initialChannelConnectionsStatus = channelManagerConnectionsAdapter.getInitialState({
	isLoaded: false
});

export const ChannelManagerConnectionReducer = createReducer(
	initialChannelConnectionsStatus,

	on(loadChannelManagerConnectionsSuccessAction, (state, payload) => {
		console.log(JSON.stringify(payload));
		if (payload.connections) {
			return channelManagerConnectionsAdapter.upsertMany(payload.connections, { ...state, isLoaded: true });
		} else {
			return state;
		}
	}),
	on(updateChannelManagerConnectionActionSuccess, (state, payload) => {
		if (payload.connection) {
			return channelManagerConnectionsAdapter.upsertOne(payload.connection, { ...state, isLoaded: true });
		} else {
			return state;
		}
	})
);
