import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/store/reducer';
import { propertyPagesAdapter } from './properties-pages.reducers';

const selectPropertyPagesState = createSelector(
	state => state,
	(state: AppState) => state.propertyPages
);

export const { selectTotal: selectPropertyPagesLength } = propertyPagesAdapter.getSelectors(selectPropertyPagesState);

export const selectPropertyPagesEntities = createSelector(selectPropertyPagesState, state => {
	if (state.isLoaded) {
		return state.entities;
	}
	return null;
});
