import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject, combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { BillingService } from 'src/app/billing/billing.service';
import { MixpanelService } from 'src/app/core/services/mixpanel-service.service';
import { UserBillingInfo } from 'src/app/models/billing.model';
import { UnitBasic } from 'src/app/models/property.model';
import { PropertyService } from 'src/app/properties/property.service';
import { LocalizationUtils } from 'src/app/utils/localization-utils';
import { ChangePaidPlanDialogComponent } from '../change-paid-plan-dialog/change-paid-plan-dialog.component';
import { PresenceService } from 'src/app/core/services/presence.service';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
	selector: 'app-upgrade-subscription-dialog',
	templateUrl: './upgrade-subscription-dialog.component.html',
	styleUrls: ['./upgrade-subscription-dialog.component.scss']
})
export class UpgradeSubscriptionDialogComponent implements OnInit, OnDestroy {
	translations = LocalizationUtils.getTranslations();
	private componentDestroyed = new Subject();

	unitsBasic: UnitBasic[];
	billingInfo: UserBillingInfo;
	currentPlan: string;

	constructor(
		public dialogRef: MatDialogRef<UpgradeSubscriptionDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		private readonly billingService: BillingService,
		private readonly propertyService: PropertyService,
		private readonly mixpanelService: MixpanelService,
		public authService: AuthService,
		private prensenceService: PresenceService,
		private readonly router: Router,
		public dialog: MatDialog
	) {}

	ngOnDestroy(): void {
		this.componentDestroyed.next();
	}

	ngOnInit() {
		combineLatest([this.propertyService.getUnitsBasicFromNoMockProperties(), this.billingService.getBillingInfo()])
			.pipe(take(1), takeUntil(this.componentDestroyed))
			.subscribe(async ([unitsBasic, billingInfo]) => {
				this.unitsBasic = unitsBasic;
				this.billingInfo = billingInfo;
				this.currentPlan = billingInfo.currentSubscription;
			});
	}

	triggerSubscriptionButton() {
		if (this.currentPlan.startsWith('free')) {
			this.billingService.requestCheckoutPage(
				'units-plan@RYN-350',
				this.data.futureUnits ?? this.unitsBasic.length,
				{
					success: () => {
						this.mixpanelService.track('plan_switch');
						this.router.navigateByUrl('/waiting');
						this.dialogRef.close({ action: 'plan_switch' });
					}
				}
			);
		} else {
			this.billingService
				.loadChangePlanEstimateInfo('units-plan@RYN-350', this.data.futureUnits ?? this.unitsBasic.length)
				.pipe(takeUntil(this.componentDestroyed))
				.subscribe(result => {
					const changePlanDialogRef = this.dialog.open(ChangePaidPlanDialogComponent, {
						width: '720px',
						backdropClass: 'backdrop-dark-background',
						disableClose: true,
						data: { estimation: result, currentPlan: this.currentPlan, selectedPlan: 'units-plan@RYN-350' },
						panelClass: 'no-padding-modalbox'
					});

					changePlanDialogRef.afterClosed().subscribe(res => {
						if (res.action === 'ok') {
							this.mixpanelService.track('plan_switch');
							this.router.navigateByUrl('/waiting');
							this.dialogRef.close({ action: 'plan_switch' });
						}
					});
				});
		}
	}

	logout() {
		this.prensenceService.signOut();
		this.authService.logout();

		this.dialogRef.close({ action: 'logout' });
	}

	closeClick() {
		this.dialogRef.close({ action: 'close' });
	}
}
