import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/store/reducer';
import { BillingFeatures } from '../billing.service';
import { BillingState, featuresUsageStatsAdapter } from './billing.reducers';

export const selectBillingState = createSelector(
	state => state,
	(state: AppState) => state.billing
);

export const selectBillingInfo = createSelector(selectBillingState, (state: BillingState) => state.billingInfo);

export const selectCurrentPlan = createSelector(
	selectBillingState,
	(state: BillingState) => state?.billingInfo?.currentSubscription || 'free-plan'
);

export const selectBillingFeatureUsageStats = createSelector(
	selectBillingState,
	(state: BillingState) => state.featureUsageStats
);

export const { selectEntities: selectBillingFeatureUsageStatsEntities } =
	featuresUsageStatsAdapter.getSelectors(selectBillingFeatureUsageStats);

export const selectBillingSpecificFeatures = (features: BillingFeatures[]) =>
	createSelector(selectBillingFeatureUsageStatsEntities, featureUsagesStats =>
		features.map(feature => featureUsagesStats[feature])
	);
