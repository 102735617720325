import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { SnotifyPosition, SnotifyService } from 'ng-snotify';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LoadingService } from './core/services/loading.service';
import { PresenceService } from './core/services/presence.service';
import { ScreenStateService } from './services/screen-state.service';
import { LocalizationUtils } from './utils/localization-utils';
import { RouterExtService } from './services/router-external.service';
import { IntercomService } from './core/services/intercom-service.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {
	translations = LocalizationUtils.getTranslations();
	currentLoadingText: BehaviorSubject<string> = new BehaviorSubject<string>('');

	// Mobile
	mobile: boolean = false;
	private mobileSubscription: Subscription;

	constructor(
		private snotifyService: SnotifyService,
		public presence: PresenceService,
		public remoteConfig: AngularFireRemoteConfig,
		private loadingService: LoadingService,
		private cdr: ChangeDetectorRef,
		private readonly screenStateService: ScreenStateService,
		private routerExtService: RouterExtService,
		private intercomService: IntercomService // Init the service
	) {
		this.mobile = screenStateService.isMobileResolution();
	}

	ngOnInit(): void {
		this.currentLoadingText = this.loadingService.getLoadingTextObservable();
		this.snotifyService.setDefaults({
			toast: {
				position: SnotifyPosition.rightTop
			}
		});

		this.mobileSubscription = this.screenStateService.screenResized.subscribe(isMobile => (this.mobile = isMobile));
	}

	ngOnDestroy(): void {
		this.mobileSubscription.unsubscribe();
	}

	ngAfterViewChecked() {
		this.cdr.detectChanges();
	}
}
