import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
	name: 'formatAmountNoCode'
})
export class FormatAmountNoCodePipe extends CurrencyPipe implements PipeTransform {
	transform(value: number, currencyCode: string, ...args: any[]): any {
		const decimalNumber = Math.round(value) / 100;

		const result = super.transform(decimalNumber, currencyCode, 'code', '1.2-2');
		if (result) {
			return result.split(currencyCode)[0].trim();
		}
		return '';
	}
}
