import { createAction, props } from '@ngrx/store';
import { Booking } from 'src/app/models/booking.model';
import { AttachedFile } from 'src/app/models/fileRm.model';
import { AvailabilityQueryOptions, Lease, LeaseAddOptions, SignatureDatabaseDetails } from 'src/app/models/lease.model';
import { PropertyPayment } from 'src/app/models/payments';
import { Tenant } from 'src/app/models/tenant.model';
import { PropertyCreationResult } from 'src/app/properties/state/properties.effects';
import { CalendarData } from 'src/app/shared/roommate-calendar/roommate-calendar.component';
import { CallbackFunctions } from 'src/app/store/reducer';
import { EsignatureRequestPayload } from '../lease-add/lease-add.component';
import { LeaseDepositStatus } from '../lease.service';
import { LeaseCreateResult, LeaseDeleteResult, LeaseUpdateResult } from './lease.effects';

export enum LeaseActions {
	LOAD_LEASES = '[Leases] Load Leases',
	UPDATE_LEASE_DEPOSIT = '[Leases] Update lease deposit',
	ADD_LEASE_SIGNATURE = '[Leases] Add Lease Signature',
	LOAD_LEASE_BY_ID = '[Leases] Load lease By Id',
	CREATE_LEASE = '[Leases] Create Lease',
	REMOVE_LEASE = '[Leases] Remove Lease',
	UPDATE_LEASE = '[Leases] Update Lease',
	RESET_LEASE_STATE = '[Leases] Reset Lease State',
	LOAD_LEASES_SUCCESS = '[Leases] Load Leases Success',
	UPDATE_LEASE_DEPOSIT_SUCCESS = '[Leases] Update lease deposit Success',
	ADD_LEASE_SIGNATURE_SUCCESS = '[Leases] Add Lease Signature Success',
	CREATE_LEASE_SUCCESS = '[Leases] Create Lease Success',
	REMOVE_LEASE_SUCCESS = '[Leases] Remove Lease Success',
	UPDATE_LEASE_SUCCESS = '[Leases] Update Lease Success',
	LOAD_LEASE_BY_ID_SUCCESS = '[Leases] Load lease By Id Success',
	RESET_LEASE_STATE_SUCCESS = '[Leases] Reset Lease State Success',
	LOAD_LEASES_FAILURE = '[Leases] Load Leases Failure',
	LOAD_LEASE_BY_ID_FAILURE = '[Leases] Load lease By Id Failure',
	UPDATE_LEASE_DEPOSIT_FAILURE = '[Leases] Update lease deposit Failure',
	ADD_LEASE_SIGNATURE_FAILURE = '[Leases] Add Lease Signature Failure',
	CREATE_LEASE_FAILURE = '[Leases] Create Lease Failure',
	REMOVE_LEASE_FAILURE = '[Leases] Remove Lease Failure',
	UPDATE_LEASE_FAILURE = '[Leases] Update Lease Failure',
	RESET_LEASE_STATE_FAILURE = '[Leases] Reset Lease State Failure',
	GET_LEASE_CSV_STRING = '[Lease] Get Lease CSV String',
	GET_LEASE_CSV_STRING_SUCCESS = '[Lease] Get Lease CSV String Success',
	GET_LEASE_CSV_STRING_FAILURE = '[Lease] Get Lease CSV String Failure',
	ADD_MOCK_LEASES = '[Leases] Add Mock Leases',
	ADD_MOCK_LEASES_SUCCESS = '[Leases] Add Mock Leases Success',

	LOAD_AVAILABILITY = '[Leases] Load availability',
	LOAD_AVAILABILITY_SUCCESS = '[Leases] Load availability Success',
	LOAD_AVAILABILITY_FAILURE = '[Leases] Load availability Failure',

	LOAD_LEASE_DEPOSIT_STATUS = '[Leases] Load lease deposit status',
	LOAD_LEASE_DEPOSIT_STATUS_SUCCESS = '[Leases] Load lease deposit status Success',
	LOAD_LEASE_DEPOSIT_STATUS_FAILURE = '[Leases] Load lease deposit status Failure'
}

export const loadLeasesAction = createAction(LeaseActions.LOAD_LEASES);
export const updateLeaseDepositAction = createAction(LeaseActions.UPDATE_LEASE_DEPOSIT, props<{ leaseId: string; deposit: number }>());
export const addLeaseSignatureAction = createAction(
	LeaseActions.ADD_LEASE_SIGNATURE,
	props<{
		esignaturePayload: EsignatureRequestPayload;
		hostedPageId?: string;
	}>()
);
export const createLeaseAction = createAction(LeaseActions.CREATE_LEASE, props<{ lease: Lease; options: LeaseAddOptions; callbacks?: CallbackFunctions }>());
export const removeLeaseAction = createAction(LeaseActions.REMOVE_LEASE, props<{ lease: Lease }>());
export const updateLeaseAction = createAction(LeaseActions.UPDATE_LEASE, props<{ lease: Lease; callbacks?: CallbackFunctions }>());
export const loadLeasesSuccessAction = createAction(LeaseActions.LOAD_LEASES_SUCCESS, props<{ leases: Lease[] }>());
export const loadLeaseByIdAction = createAction(LeaseActions.LOAD_LEASE_BY_ID, props<{ leaseId: string; callbacks?: CallbackFunctions }>());
export const updateLeaseDepositSuccessAction = createAction(LeaseActions.UPDATE_LEASE_DEPOSIT_SUCCESS, props<{ lease: Lease }>());
export const addLeaseSignatureSuccessAction = createAction(LeaseActions.ADD_LEASE_SIGNATURE_SUCCESS, props<{ leaseId: string; signatureDetails: SignatureDatabaseDetails }>());
export const createLeaseSuccessAction = createAction(LeaseActions.CREATE_LEASE_SUCCESS, props<LeaseCreateResult>());
export const removeLeaseSuccessAction = createAction(LeaseActions.REMOVE_LEASE_SUCCESS, props<LeaseDeleteResult>());
export const updateLeaseSuccessAction = createAction(LeaseActions.UPDATE_LEASE_SUCCESS, props<LeaseUpdateResult>());
export const loadLeaseByIdSuccessAction = createAction(LeaseActions.LOAD_LEASE_BY_ID_SUCCESS, props<{ lease: Lease }>());
export const loadLeasesFailureAction = createAction(LeaseActions.LOAD_LEASES_FAILURE, props<{ error?: any }>());
export const updateLeaseDepositFailureAction = createAction(LeaseActions.UPDATE_LEASE_DEPOSIT_FAILURE, props<{ error?: any }>());
export const addLeaseSignatureFailureAction = createAction(LeaseActions.ADD_LEASE_SIGNATURE_FAILURE, props<{ errorMessage: string }>());
export const createLeaseFailureAction = createAction(LeaseActions.CREATE_LEASE_FAILURE, props<{ errorName: string; error?: string }>());
export const removeLeaseFailureAction = createAction(LeaseActions.REMOVE_LEASE_FAILURE, props<{ error?: any }>());
export const updateLeaseFailureAction = createAction(LeaseActions.UPDATE_LEASE_FAILURE, props<{ error?: any }>());
export const loadLeaseByIdFailureAction = createAction(LeaseActions.LOAD_LEASE_BY_ID_FAILURE, props<{ error?: any }>());
export const resetLeaseStateAction = createAction(LeaseActions.RESET_LEASE_STATE);
export const resetLeaseStateSuccessAction = createAction(LeaseActions.RESET_LEASE_STATE_SUCCESS);
export const resetLeaseStateFailureAction = createAction(LeaseActions.RESET_LEASE_STATE_FAILURE, props<{ error?: any }>());

export const addMockLeasesAction = createAction(LeaseActions.ADD_MOCK_LEASES, props<{ callbacks?: CallbackFunctions }>());
export const addMockLeasesSuccessAction = createAction(LeaseActions.ADD_MOCK_LEASES_SUCCESS, props<{ leases: LeaseCreateResult[]; property?: PropertyCreationResult }>());

// Export leases CSV
export const getLeaseCsvStringAction = createAction(LeaseActions.GET_LEASE_CSV_STRING);
export const getLeaseCsvStringSuccessAction = createAction(LeaseActions.GET_LEASE_CSV_STRING_SUCCESS, props<{ url: string }>());
export const getLeaseCsvStringFailureAction = createAction(LeaseActions.GET_LEASE_CSV_STRING_FAILURE, props<{ error?: any }>());

export const loadAvailabilityAction = createAction(LeaseActions.LOAD_AVAILABILITY, props<{ options: AvailabilityQueryOptions }>());
export const loadAvailabilitySuccessAction = createAction(LeaseActions.LOAD_AVAILABILITY_SUCCESS, props<{ availability: CalendarData[] }>());
export const loadAvailabilityFailureAction = createAction(LeaseActions.LOAD_AVAILABILITY_FAILURE, props<{ error?: any }>());

export const loadLeaseDepositStatusAction = createAction(LeaseActions.LOAD_LEASE_DEPOSIT_STATUS, props<{ leaseId: string; callbacks?: CallbackFunctions }>());
export const loadLeaseDepositStatusSuccessAction = createAction(LeaseActions.LOAD_LEASE_DEPOSIT_STATUS_SUCCESS, props<{ leaseId: string; depositStatus: LeaseDepositStatus }>());
export const loadLeaseDepositStatusFailureAction = createAction(LeaseActions.LOAD_LEASE_DEPOSIT_STATUS_FAILURE, props<{ error?: any }>());
