<div mat-dialog-content>
	<input type="text" style="display: none" cdkFocusInitial />

	<div fxLayout="column" fxLayoutAlign="center center" fxLayoutWrap>
		<img [src]="picturePath" style="width: 100px" />

		<p class="regular-15" style="text-align: center" [innerHTML]="content | safeHtml"></p>

		<div
			class="v-spacing"
			fxLayout="row"
			fxLayoutAlign="center center"
			style="width: 100%; margin-bottom: 20px"
			fxLayoutGap="80px"
		>
			<button *ngIf="leftButton" style="max-width: 200px" mat-button class="button-neutral" (click)="leftClick()">
				{{ leftButton }}
			</button>
			<button
				*ngIf="rightButton"
				style="max-width: 200px"
				mat-button
				class="button-positive"
				(click)="rightClick()"
			>
				{{ rightButton }}
			</button>
		</div>
	</div>
</div>
