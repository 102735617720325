import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './core/guards/auth-guard.service';

const routes: Routes = [
	{
		path: 'login',
		loadChildren: () => import('./login/login.module').then(mod => mod.LoginModule)
	},
	{
		path: 'passwordlessLogin',
		loadChildren: () =>
			import('./password/passwordless-login/passwordless-login.module').then(mod => mod.PasswordlessLoginModule)
	},
	{
		path: 'passwordrecovery',
		loadChildren: () =>
			import('./password/password-recovery/password-recovery.module').then(mod => mod.PasswordRecoveryModule)
	},
	{
		path: 'choosenewpassword',
		loadChildren: () =>
			import('./password/choose-new-password/choose-new-password.module').then(mod => mod.ChooseNewPasswordModule)
	},
	{
		path: '',
		loadChildren: () => import('./dashboard/dashboard.module').then(mod => mod.DashboardModule),
		canLoad: [AuthGuardService]
	},
	{ path: '**', redirectTo: '' }
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: 'top',
			relativeLinkResolution: 'legacy',
			paramsInheritanceStrategy: 'always'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
