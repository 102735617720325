<div class="lateral-container">
	<div class="lateral-top-bar">
		<img class="icon" src="./assets/img/calendar_add.svg" />
		<p *ngIf="!editCategory" i18n="@@add_category_title">Add category</p>
		<p *ngIf="editCategory" i18n="@@edit_category_title">Edit category</p>
		<img class="close" src="./assets/img/close.svg" (click)="backClick()" />
	</div>

	<div class="content">
		<!-- Actual content of scrollable dialog -->

		<div class="section">
			<p class="title" i18n="@@add_cal_cat_title">Category title</p>

			<div fxLayout="row wrap" fxLayoutGap="20px" style="margin-top: 20px">
				<mat-form-field class="dash-form-field-add" appearance="outline" fxFlex="200px">
					<mat-label i18n="@@add_cat_input_label">Title</mat-label>
					<input maxlength="40" type="text" matInput [formControl]="titleForm" autocomplete="off" />
				</mat-form-field>
			</div>

			<p class="title" i18n="@@add_cat_input_color_title">Category color</p>

			<div fxLayout="row wrap" fxLayoutGap="20px" style="margin-top: 20px">
				<mat-form-field class="dash-form-field-add" appearance="outline" fxFlex="200px">
					<mat-label i18n="@@add_cat_input_color_label">Color</mat-label>
					<mat-select [formControl]="colorFormControl" panelClass="panel-colors">
						<mat-select-trigger>
							<div
								style="width: 20px; height: 20px; border-radius: 50%"
								[ngStyle]="{ 'background-color': colorFormControl.value }"
							></div>
						</mat-select-trigger>
						<mat-option *ngFor="let c of colorList" [value]="c">
							<div
								style="width: 20px; height: 20px; border-radius: 50%"
								[ngStyle]="{ 'background-color': c }"
							></div>
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
	</div>

	<div class="lateral-bottom-bar">
		<button mat-button class="button-negative" (click)="backClick()" i18n="@@com_cancel">Cancel</button>
		<button
			*ngIf="editCategory && editCategory.author !== 'system'"
			mat-button
			class="button-neutral"
			(click)="deleteClick()"
			i18n="@@com_delete"
		>
			Delete
		</button>

		<div class="separator"></div>

		<button *ngIf="!editCategory" mat-button class="button-positive" (click)="saveClick()" i18n="@@com_create">
			Create
		</button>
		<button *ngIf="editCategory" mat-button class="button-positive" (click)="saveClick()" i18n="@@com_edit">
			Edit
		</button>
	</div>
</div>
