<div class="lateral-container">
	<div class="lateral-top-bar">
		<img class="icon" src="./assets/img/calendar_blue.svg" />
		<p style="text-transform: uppercase">
			{{ data.currentDate | dfnsFormat: 'iiii' }}&nbsp;<strong>{{ data.currentDate | dfnsFormat: 'dd' }}</strong
			>&nbsp;{{ data.currentDate | dfnsFormat: 'MMMM' }}
		</p>
		<img class="close" src="./assets/img/close.svg" (click)="closeClick()" />
	</div>

	<div class="content">
		<!-- Actual content of scrollable dialog -->

		<div class="section">
			<div fxLayout="column" fxLayoutAlign="flex-start center">
				<div
					*ngFor="let e of sortedEvents; let index = index"
					class="event-row"
					fxLayout="row"
					fxLayoutAlign="space-between flex-start"
					style="width: 100%"
					[ngStyle]="{ 'margin-top': index > 0 ? '0px' : '20px' }"
				>
					<div class="event-timeline" fxLayout="column" fxLayoutAlign="flex-start center">
						<div
							class="event-timeline-badge"
							[ngStyle]="{ 'background-color': getEventColor(e) }"
							fxLayout="column"
							fxLayoutAlign="center center"
						>
							<p class="semi-bold-13 color-white no-margin">{{ index + 1 }}</p>
						</div>

						<div *ngIf="index !== sortedEvents.length - 1" class="event-timeline-badge-mini"></div>
						<div *ngIf="index !== sortedEvents.length - 1" class="event-timeline-badge-mini"></div>
						<div *ngIf="index !== sortedEvents.length - 1" class="event-timeline-badge-mini"></div>
						<div *ngIf="index !== sortedEvents.length - 1" class="event-timeline-badge-mini"></div>
						<div *ngIf="index !== sortedEvents.length - 1" class="event-timeline-badge-mini"></div>
						<div *ngIf="index !== sortedEvents.length - 1" class="event-timeline-badge-mini"></div>

						<div *ngIf="!!e.tenantId" class="event-timeline-badge-mini"></div>
						<div *ngIf="!!e.propertyId" class="event-timeline-badge-mini"></div>
					</div>

					<div fxFlex class="event-desc" fxLayout="column" fxLayoutAlign="flex-start flex-start">
						<div fxLayout="row" fxLayoutAlign="flex-start flex-start" style="width: 100%">
							<p *ngIf="!e.allDay" class="regular-15 no-margin">
								{{ e.startDate | dfnsFormat: 'HH:mm' }}&nbsp;-&nbsp;{{
									e.endDate | dfnsFormat: 'HH:mm'
								}}
							</p>
							<p *ngIf="e.allDay" class="regular-15 no-margin" i18n="@@calendar_all_day">All day</p>
							<p fxFlex style="margin: 0 0 0 10px" class="semi-bold-15">{{ e.title }}</p>
						</div>

						<p *ngIf="!!e.tenantId" class="regular-15 event-info" (click)="seeTenantClick(e)">
							{{ tenantNames[e.tenantId] }}
						</p>
						<p *ngIf="!!e.propertyId" class="regular-15 event-info" (click)="seePropertyClick(e)">
							{{ propertyNames[e.propertyId] }}
						</p>

						<p class="regular-15 no-margin event-desc-text">{{ e.description }}</p>
					</div>

					<div class="event-actions" fxLayout="row" fxLayoutAlign="space-between center">
						<img
							class="img-event-action"
							src="./assets/img/icon_edit_blue.svg"
							(click)="editEventClick(e)"
						/>
						<img class="img-event-action" src="./assets/img/delete.svg" (click)="deleteEventClick(e)" />
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="lateral-bottom-bar">
		<button mat-button class="button-primary" (click)="addEventClick()" i18n="@@calendar_events_view_add_event">
			Add event
		</button>

		<div class="separator"></div>

		<button mat-button class="button-positive" (click)="okClick()" i18n="@@calendar_events_view_ok">OK</button>
	</div>
</div>
