import { AttachedFile } from './fileRm.model';
import { BankData, BasePageModelStore, EpaymentPreferences, InvoiceData } from './common';
import { PhotoData } from './viewPhotosData.model';
import { AppUtils } from '../utils/app-utils';
import { TenantsDisplayData } from '../tenants/tenants-table/tenants-table-datasource';
import { LandlordService } from '../core/services/landlord/landlord.service';
import { LocalizationUtils } from '../utils/localization-utils';

export class TenantChecklistTask {
	constructor(
		public id: string,
		public name: string = '',
		public status: boolean = false,
		public files: AttachedFile[] = []
	) {}
}

export class TenantChecklist {
	constructor(
		public id: string = '',
		public name: string = '',
		public creationDate: number = new Date().getTime(),
		public tasks: TenantChecklistTask[] = [],
		public description: string = ''
	) {}
}

export enum LeaseStatus {
	/* -1 Past lease | 0 Current lease | 1 Future lease | 2 No lease */
	LEASE_PAST = -1,
	LEASE_CURRENT = 0,
	LEASE_FUTURE = 1,
	LEASE_MISSING = 2
} // Open - Close

export class Tenant {
	static INVITE_LINK_EXPIRY_DAYS = 30;

	static STATUS_REQUEST = 0;
	static STATUS_TENANT = 1;

	public currentLeaseStatus: LeaseStatus;
	public userId;
	public creationDate = 0;
	public joinLink = '';

	public picturesData?: PhotoData[];

	public metadata: any = {};

	public invoiceData: InvoiceData = {};
	public bankData: BankData = {};
	public archiveDate: number = 0;
	public files: AttachedFile[] = [];
	public leaseId: string = '';
	public leasesId: string[] = [];
	public isMock: boolean = false;

	public epaymentPreferences?: EpaymentPreferences;

	public privateNotes?: string;

	public extraInfo?: {
		value: any;
		label: string;
	}[];

	constructor(
		public id: string,
		public photo: string,
		public name: string,
		public surname: string,
		public phone: string,
		public email: string,
		public status: 0 | 1 /* 0 Tenant request | 1 Actual tenant */,
		public birthDate: number,
		public emergContact: string,
		public role: number,
		public pets: number,
		public nameGuar: string,
		public lastGuar: string,
		public emailGuar: string,
		public phoneGuar: string,
		public gender: number
	) {}

	public static sanitize(tenant: Tenant): Tenant {
		const t = new Tenant('', '', '', '', '', '', 0, 0, '', null, null, '', '', '', '', 0);

		Object.assign(t, tenant);

		delete t['_id'];

		// Roomie use of nameGuard instead of roomie integration code
		if (t?.metadata?.roomieIntegrationTenantCode && !t.nameGuar) {
			t.nameGuar = t.metadata.roomieIntegrationTenantCode;
		}

		if (t?.extraInfo) {
			const language = LocalizationUtils.getLanguage();
			t.extraInfo = Object.keys(t.extraInfo).map(key => {
				const info = t.extraInfo[key];
				return {
					label: info.label[language] || info.label['en'] || key,
					value: info.value
				};
			});
		}

		return t;
	}

	public static clone(tenant: Tenant): Tenant {
		return AppUtils.deepCopy(tenant) as Tenant;
	}

	// This is a temporary tenant used to not make crash Estelle when a tenant is required and to see at first glance that something is going on
	public static DummyTenant(): Tenant {
		return new Tenant('', '', 'NO TENANT', '', '', '', 0, 0, '', 0, 0, '', '', '', '', 0);
	}

	public static getBasicInfo(t: Tenant): TenantBasic {
		return {
			id: t.id,
			name: t.name,
			surname: t.surname,
			email: t.email,
			userId: t.userId,
			leasesId: t.leasesId,
			currentLeaseStatus: t.currentLeaseStatus
		};
	}

	public static getBasicInfoMultiple(ts: Tenant[]): TenantBasic[] {
		return ts.map(t => this.getBasicInfo(t)).reduce((a, b) => a.concat(b), []);
	}
}

export interface TenantBasic {
	id: string;
	name: string;
	surname: string;
	email: string;
	userId: string;
	leasesId: string[];
	currentLeaseStatus: LeaseStatus;
	unitIds?: string[];
}

export interface TenantAddData {
	tenant: Tenant;
	comingFrom?: string;
}

export interface TenantConnectionResponse {
	tenantId: string;
	status: 'created' | 'updated';
	invites?: {
		email?: boolean;
	};
	token: string;
	expiryTime: number;
}

export interface TenantData {
	id: string;
	name: string;
	currentLeaseStatus?: LeaseStatus;
	propertyName?: string;
}

export interface TenantPageModelStore extends BasePageModelStore {
	tenantIds: string[];
}

export interface TenantPageModelUI {
	tenants: TenantsDisplayData[];
	totalItems: number;
	filteredItems: number;
}
