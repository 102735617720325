import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

/** A router wrapper, adding extra functions. */
@Injectable({
	providedIn: 'root'
})
export class RouterExtService {
	private defaultUrl = '';

	constructor(private router: Router, private location: Location) {}

	public setDefaultPreviousUrl(defaultUrl: string) {
		try {
			this.router.getCurrentNavigation().previousNavigation.finalUrl.toString(); //check if previous Estelle url exists
		} catch (er) {
			this.setDefaultUrl('/' + defaultUrl); //if not exist, set a default url
		}
	}

	public setDefaultUrl(defaultUrl: string) {
		this.defaultUrl = defaultUrl;
	}

	public navigateHidingUrl(comands: any[], extras?: any, hideUrl = false): Promise<boolean> {
		if (!!this.defaultUrl && !hideUrl) {
			//clean defaultUrl when we want to add history navigation on Estelle and the current page has a defaultUrl set
			this.defaultUrl = ''; //this logic doesn't affect setDefaultPreviousUrl functionality
		}

		let navigateExtras = { replaceUrl: hideUrl };
		navigateExtras = { ...navigateExtras, ...extras };

		return this.router.navigate(comands, navigateExtras);
	}

	public navigateBack() {
		if (!!this.defaultUrl) {
			this.router.navigateByUrl(this.defaultUrl, { replaceUrl: true });
			this.defaultUrl = '';
		} else {
			this.location.historyGo(-1);
		}
	}
}
