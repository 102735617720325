import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { LandlordService } from 'src/app/core/services/landlord/landlord.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { EffectInteractionService } from 'src/app/core/services/effectInteraction.service';
import { MaintenanceRequest } from 'src/app/models/maintenanceRequest.model';
import { resetStoreAction } from 'src/app/store/actions';
import { LocalizationUtils } from 'src/app/utils/localization-utils';
import { environment } from 'src/environments/environment';
import {
	addMaintenanceAction,
	addMaintenanceFailureAction,
	addMaintenanceSuccessAction,
	addMockMaintenanceAction,
	addMockMaintenanceSuccessAction,
	deleteMaintenanceByIdAction,
	deleteMaintenanceFailureAction,
	deleteMaintenancesAction,
	deleteMaintenancesFailureAction,
	deleteMaintenancesSuccessAction,
	deleteMaintenanceSuccessAction,
	editMaintenanceAction,
	editMaintenanceFailureAction,
	editMaintenanceSuccessAction,
	loadMaintenanceByIdAction,
	loadMaintenanceFailureAction,
	loadMaintenancesPaginatedAction,
	loadMaintenancesPaginatedFailureAction,
	loadMaintenancesPaginatedSuccessAction,
	loadMaintenanceSuccessAction,
	resetMaintenancesStateAction,
	resetMaintenancesStateFailureAction,
	resetMaintenancesStateSuccessAction
} from './maintenances.actions';
import { Tenant } from 'src/app/models/tenant.model';
import { Property } from 'src/app/models/property.model';
import { PropertyCreationResult } from 'src/app/properties/state/properties.effects';
import { LeaseCreateResult } from 'src/app/leases/state/lease.effects';
import { Lease } from 'src/app/models/lease.model';
import { LeaseService } from 'src/app/leases/lease.service';
import {
	FilterContainer,
	MaintenanceFilterKeys,
	MaintenanceQueryFilters,
	MaintenancesTabKeys,
	SortEvent
} from 'src/app/core/services/filters/filters.model';
import { QueryResult } from 'src/app/models/common';
import { AppUtils } from 'src/app/utils/app-utils';
import { Router } from '@angular/router';

@Injectable()
export class MaintenancesEffects {
	private BACKEND_HOST = `${environment.services.backend}/api-dash/v1`;
	private landlordId = '';
	private translations = LocalizationUtils.getTranslations();

	constructor(
		private readonly landlordService: LandlordService,
		private readonly leaseService: LeaseService,
		private readonly httpClient: HttpClient,
		private readonly actions$: Actions,
		private readonly loadingService: LoadingService,
		private readonly effectInteractionService: EffectInteractionService,
		private readonly router: Router
	) {
		this.landlordService.getLandlordId().subscribe(landlordId => {
			this.landlordId = landlordId;
		});
	}

	public resetStoreState = createEffect(() =>
		this.actions$.pipe(
			ofType(resetStoreAction),
			switchMap(() => of(resetMaintenancesStateAction()))
		)
	);

	public resetMaintenanceStateEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(resetMaintenancesStateAction),
			switchMap(() => of(resetMaintenancesStateSuccessAction())),
			catchError(() => of(resetMaintenancesStateFailureAction({})))
		)
	);

	public loadMaintenanceEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadMaintenanceByIdAction),
			switchMap(action =>
				this.loadMaintenance(action.maintenanceId, action.options).pipe(
					map(maintenance => loadMaintenanceSuccessAction({ maintenance })),
					catchError(err => of(loadMaintenanceFailureAction({})))
				)
			)
		)
	);

	public addMaintenanceEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(addMaintenanceAction),
			mergeMap(action =>
				this.addMaintenance(action.maintenance, action.options).pipe(
					map(maintenance => addMaintenanceSuccessAction({ maintenance })),
					catchError(errorResponse =>
						of(addMaintenanceFailureAction({ errorName: errorResponse?.error?.name || '' }))
					)
				)
			)
		)
	);

	public editMaintenanceEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(editMaintenanceAction),
			mergeMap(action =>
				this.editMaintenance(action.maintenance, action.options).pipe(
					map(maintenance => editMaintenanceSuccessAction({ maintenance })),
					catchError(err => of(editMaintenanceFailureAction({})))
				)
			)
		)
	);

	public deleteMaintenanceByIdEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(deleteMaintenanceByIdAction),
			mergeMap(action =>
				this.deleteMaintenance(action.maintenanceId).pipe(
					map(() => {
						this.effectInteractionService.callbackSuccess(action);
						return deleteMaintenanceSuccessAction({ maintenanceId: action.maintenanceId });
					}),
					catchError(err => {
						this.effectInteractionService.callbackFail(action);
						return of(deleteMaintenanceFailureAction({ isBulk: action.isBulk }));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public deleteMaintenancesEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(deleteMaintenancesAction),
			mergeMap(action =>
				this.deleteMaintenances(action.maintenanceIds).pipe(
					map(() => {
						this.effectInteractionService.callbackSuccess(action);
						return deleteMaintenancesSuccessAction();
					}),
					catchError(err => {
						this.effectInteractionService.callbackFail(action);
						return of(deleteMaintenancesFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public addMockMaintenanceEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(addMockMaintenanceAction),
			mergeMap(action =>
				this.generateMockMaintenance().pipe(
					map(packedData => {
						this.effectInteractionService.callbackSuccess(action);

						for (let i = 0; i < packedData.leases.length; i++) {
							packedData.leases[i].lease = Lease.sanitize(packedData.leases[i].lease);
							if (packedData.leases[i].tenant) {
								let sanitizedTenant = Tenant.sanitize(packedData.leases[i].tenant);
								sanitizedTenant.currentLeaseStatus = this.leaseService.getTenantCurrentLeaseStatus(
									sanitizedTenant,
									{ [packedData.leases[i].lease.id]: packedData.leases[i].lease }
								);
								packedData.leases[i].tenant = sanitizedTenant;
							}
						}

						return addMockMaintenanceSuccessAction({
							maintenance: packedData.maintenance,
							leases: packedData.leases,
							property: packedData.property
						});
					}),
					catchError(errorResponse => {
						this.effectInteractionService.callbackFail(action);
						return of(addMaintenanceFailureAction({ errorName: errorResponse?.error?.name || '' }));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	/*** Effects handle success and failure actions  ***/

	public addMaintenanceSuccessEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(addMaintenanceSuccessAction),
				tap(action =>
					this.effectInteractionService.displaySuccessToast(
						this.translations.toast.maintenance_added_title,
						this.translations.toast.maintenance_added_msg,
						() => {
							this.router.navigate(['maintenances', action.maintenance.id]);
						}
					)
				)
			),
		{ dispatch: false }
	);

	public addMockMaintenanceSuccessEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(addMockMaintenanceSuccessAction),
				tap(action => {
					this.effectInteractionService.displaySuccessToast(
						this.translations.toast.maintenance_added_title,
						this.translations.toast.maintenance_added_msg,
						() => {
							this.router.navigate(['maintenances', action.maintenance.id]);
						}
					);
				})
			),
		{ dispatch: false }
	);

	public addMaintenanceFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(addMaintenanceFailureAction),
				tap(action => {
					// Generic error message
					let errorStr = $localize`:@@add_maintenance_failed:Something went wrong during the creation of the maintenance. Try again and if the problem persists contact us.`;

					// Specific error messages
					if (action.errorName === 'OperationNotAllowedByMaxMockLimitQuotaError') {
						errorStr = $localize`:@@add_property_failed_max_mock_limitation:This operation is not allowed because it exceeds mock creation limit quota`;
					} else if (action.errorName === 'ExternalServiceFailedError') {
						errorStr = $localize`:@@add_tenant_failed_external_service:Something went wrong during an external service execution. Try again and if the problem persists, contact us.`;
					}

					this.loadingService.hide();
					this.effectInteractionService.showDialogError(errorStr);
				})
			),
		{ dispatch: false }
	);

	public editMaintenanceSuccessEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(editMaintenanceSuccessAction),
				tap(() =>
					this.effectInteractionService.displaySuccessToast(
						this.translations.toast.maintenance_updated_title,
						this.translations.toast.maintenance_updated_msg
					)
				)
			),
		{ dispatch: false }
	);

	public editMaintenanceFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(editMaintenanceFailureAction),
				tap(() => {
					this.loadingService.hide();
					this.effectInteractionService.showDialogError(
						$localize`:@@edit_maintenance_failed:Something went wrong during the update of the maintenance. Try again and if the problem persists contact us.`
					);
				})
			),
		{ dispatch: false }
	);

	public deleteMaintenanceFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(deleteMaintenanceFailureAction),
				tap(action => {
					if (action.isBulk) {
						this.loadingService.hideAndDisplayErrorToast();
					} else {
						this.loadingService.hide();
						this.effectInteractionService.showDialogError(
							$localize`:@@delete_maintenance_failed:Something went wrong during the delete of the maintenance. Try again and if the problem persists contact us.`
						);
					}
				})
			),
		{ dispatch: false }
	);

	public genericMaintenanceFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(resetMaintenancesStateFailureAction, loadMaintenanceFailureAction),
				tap(() => this.loadingService.hideAndDisplayErrorToast())
			),
		{ dispatch: false }
	);

	// Version 2

	public loadMaintenancesPaginatedEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadMaintenancesPaginatedAction),
			switchMap(action =>
				this.loadMaintenancesPaginated(action.page, action.pageSize, action.filters, action.sort).pipe(
					map(maintenances =>
						loadMaintenancesPaginatedSuccessAction({
							page: action.page,
							pageSize: action.pageSize,
							maintenancesQueryResult: maintenances
						})
					),
					catchError(err => of(loadMaintenancesPaginatedFailureAction({ error: err })))
				)
			)
		)
	);

	/***  ***/

	// Private calls
	private loadMaintenance(maintenanceId: string, options: any = {}): Observable<MaintenanceRequest> {
		return this.httpClient.get<MaintenanceRequest>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/maintenances/${maintenanceId}`
		);
	}

	private addMaintenance(maintenance: MaintenanceRequest, options: any = {}): Observable<MaintenanceRequest> {
		return this.httpClient.post<MaintenanceRequest>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/maintenances`,
			{
				maintenance,
				options
			}
		);
	}

	private editMaintenance(maintenance: MaintenanceRequest, options: any = {}): Observable<MaintenanceRequest> {
		return this.httpClient.put<MaintenanceRequest>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/maintenances/${maintenance.id}`,
			{
				maintenance,
				options
			}
		);
	}

	private deleteMaintenance(maintenanceId: string, options: any = {}): Observable<void> {
		return this.httpClient.delete<void>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/maintenances/${maintenanceId}`
		);
	}

	private deleteMaintenances(maintenanceIds: string[]): Observable<void> {
		const bulkDeleteMaintenances = {
			isAllSelected: false,
			selectedEntities: maintenanceIds,
			deselectedEntities: []
		};

		return this.httpClient.delete<void>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/maintenances/`, {
			body: {
				bulkDeleteMaintenances: bulkDeleteMaintenances
			}
		});
	}

	private generateMockMaintenance(): Observable<{
		maintenance: MaintenanceRequest;
		leases?: LeaseCreateResult[];
		property?: PropertyCreationResult;
	}> {
		return this.httpClient.post<{
			maintenance: MaintenanceRequest;
			leases?: LeaseCreateResult[];
			property?: PropertyCreationResult;
		}>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/mock/maintenance`, {});
	}

	// Version 2

	private loadMaintenancesPaginated(
		page: number,
		pageSize: number,
		filters: FilterContainer,
		sort: SortEvent
	): Observable<QueryResult<MaintenanceRequest>> {
		const customFilters = this.getMaintenanceQueryFiltersFromEstelleFilters(filters);
		const customSort = AppUtils.getQuerySortFromEstelleSort(sort);

		return this.httpClient
			.get<QueryResult<MaintenanceRequest>>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/maintenances`, {
				params: {
					page: page,
					pageSize: pageSize,
					...customFilters,
					...customSort
				}
			})
			.pipe(
				map(maintenances => {
					const maintenancesSanitized = maintenances.data.map(MaintenanceRequest.sanitize);

					maintenances.data = maintenancesSanitized;

					return maintenances;
				})
			);
	}

	private getMaintenanceQueryFiltersFromEstelleFilters(filters: FilterContainer): MaintenanceQueryFilters {
		let customFilters: MaintenanceQueryFilters = {};

		customFilters.status = filters.selectedTabId as MaintenancesTabKeys;

		if (filters.searchedValue) {
			customFilters.text = filters.searchedValue;
		}

		// Custom filters
		if (filters.appliedFilters) {
			const priorityFilter = FilterContainer.getFilterIfExists(filters, MaintenanceFilterKeys.PRIORITY);
			if (priorityFilter) {
				customFilters.priority = priorityFilter.selectedValue.value;
			}
			const addedByFilter = FilterContainer.getFilterIfExists(filters, MaintenanceFilterKeys.ADDED_BY);
			if (addedByFilter) {
				customFilters.addedByYou = addedByFilter.selectedValue.value;
			}
		}

		return customFilters;
	}
}
