import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';
import { FiltersStoreModel } from '../core/services/filters/filters.model';
import { selectFiltersEntities } from '../core/services/filters/state/filters.selector';
import { LandlordService } from '../core/services/landlord/landlord.service';
import { Property, PropertyBasic } from '../models/property.model';
import {
	OwnerBasic,
	OwnerPageModelStore,
	OwnerPageModelUI,
	PropertyOwner,
	PropertyOwnerCreate
} from '../models/propertyOwner.model';
import { PropertyService } from '../properties/property.service';
import {
	loadPropertiesByOwnerAction,
	loadPropertiesByOwnerSuccessAction
} from '../properties/state/properties.actions';
import { CallbackFunctions } from '../store/reducer';
import { AppUtils } from '../utils/app-utils';
import { selectOwnersBasicEntities } from './state/owners-basic.selectors';
import { selectOwnerPagesEntities } from './state/owners-pages.selectors';
import {
	addOwnerAction,
	deleteOwnerAction,
	editOwnerAction,
	getOwnerByIdAction,
	loadOwnersAction,
	loadOwnersPaginatedAction,
	resetOwnersStateSuccessAction
} from './state/owners.actions';
import { selectOwnersEntities } from './state/owners.selectors';

@Injectable({
	providedIn: 'root'
})
export class OwnersService {
	private owners$: Observable<Dictionary<PropertyOwner>>;
	private ownersBasic$: Observable<Dictionary<any>>;

	constructor(
		private readonly propertyService: PropertyService,
		private readonly landlordService: LandlordService,
		private readonly store: Store,
		private readonly actions$: Actions
	) {
		this.actions$.pipe(ofType(resetOwnersStateSuccessAction)).subscribe(() => {
			this.owners$ = null;
			this.ownersBasic$ = null;
		});
	}

	private getOwnersDict(): Observable<Dictionary<PropertyOwner>> {
		if (!this.owners$) {
			(this.owners$ = this.store
				.select(selectOwnersEntities)
				.pipe(takeUntil(this.landlordService.getLandlordId().pipe(filter(res => !!!res))))),
				map((ownersDict: Dictionary<PropertyOwner> | null) => {
					if (ownersDict) {
						return ownersDict;
					}
					return null;
				});
		}

		return this.owners$;
	}

	private getOwnersBasicDict(): Observable<Dictionary<any>> {
		if (!this.ownersBasic$) {
			this.ownersBasic$ = this.store
				.select(selectOwnersBasicEntities)
				.pipe(takeUntil(this.landlordService.getLandlordId().pipe(filter(res => !!!res))));
		}

		return this.ownersBasic$;
	}

	public getOwnersBasic(): Observable<OwnerBasic[]> {
		return this.getOwnersBasicDict().pipe(
			map(ownersDict => {
				if (ownersDict) {
					return Object.values(ownersDict);
				} else {
					this.store.dispatch(loadOwnersAction({ refreshAll: false }));

					return null;
				}
			}),
			filter(it => !!it)
		);
	}

	public getOwnerBasicById(id: string): Observable<OwnerBasic> {
		return this.getOwnersBasic().pipe(map(ownersBasicArray => ownersBasicArray.find(o => o.id === id)));
	}

	public getOwnerById(ownerId: string, bypassPermissionsCheck?: boolean): Observable<PropertyOwner> {
		return this.getOwnersDict().pipe(
			AppUtils.applyRefreshLogicForItemNotFound(
				this.store,
				ownerId,
				getOwnerByIdAction({ ownerId: ownerId, bypassPermissionsCheck: bypassPermissionsCheck })
			)
		);
	}

	public getPropertiesForOwner$(ownerId: string): Observable<Property[]> {
		this.store.dispatch(loadPropertiesByOwnerAction({ ownerId }));
		return this.actions$.pipe(
			ofType(loadPropertiesByOwnerSuccessAction),
			map(it => it.propertiesQueryResult.data)
		);
	}

	public getPropertiesForOwner(owner: PropertyOwner | OwnerBasic, properties: PropertyBasic[]): PropertyBasic[] {
		return properties.filter(property => {
			return owner.ownedPropertyIds.indexOf(property.id) >= 0;
		});
	}

	public async getOwnerDataForPopCard(ownerId: string) {
		let ownerData = null;
		const owner = await this.getOwnerById(ownerId, true).pipe(take(1)).toPromise();
		if (!!owner) {
			ownerData = {
				id: ownerId,
				name: owner.name + ' ' + owner.surname,
				status: owner.invoiceData?.type || ''
			};
		}
		return ownerData;
	}

	public addOwner(owner: PropertyOwnerCreate): Promise<PropertyOwner> {
		return new Promise<PropertyOwner>((resolve, reject) => {
			const callbacks = {
				success: owner => {
					resolve(owner);
				},
				fail: () => {
					reject();
				}
			};

			this.store.dispatch(addOwnerAction({ owner, callbacks }));
		});
	}

	public editOwner(owner: PropertyOwnerCreate, bypassPermissionsCheck?: boolean): Promise<PropertyOwner> {
		return new Promise<PropertyOwner>((resolve, reject) => {
			const callbacks = {
				success: owner => {
					resolve(owner);
				},
				fail: () => {
					reject();
				}
			};

			this.store.dispatch(editOwnerAction({ owner, callbacks, bypassPermissionsCheck }));
		});
	}

	public removeOwner(owner: PropertyOwner): Promise<void> {
		return AppUtils.buildActionWithPromise<void>(this.store, deleteOwnerAction({ owner }));
	}

	// Version 2 - Backend pagination & filtering

	public getOwnersPaginated(pageSize: number): Promise<OwnerPageModelUI> {
		return combineLatest([this.store.select(selectFiltersEntities), this.store.select(selectOwnerPagesEntities)])
			.pipe(
				take(1),
				switchMap(
					([storeFilters, storeOwnerPages]: [
						Dictionary<FiltersStoreModel>,
						Dictionary<OwnerPageModelStore>
					]) => {
						if (
							storeFilters &&
							storeOwnerPages &&
							storeOwnerPages[storeFilters['owners']?.pageIndex || 0]
						) {
							return combineLatest([
								of(storeFilters),
								of(storeOwnerPages),
								this.store.select(selectOwnersEntities)
							]);
						} else {
							return combineLatest([of(null), of(null), of(null)]);
						}
					}
				),
				switchMap(
					([storeFilters, storeOwnerPages, ownersDict]: [
						Dictionary<FiltersStoreModel>,
						Dictionary<OwnerPageModelStore>,
						Dictionary<PropertyOwner>
					]) => {
						if (storeFilters !== null && storeOwnerPages && ownersDict) {
							const sortedOwnerIds = storeOwnerPages[storeFilters['owners']?.pageIndex || 0].ownerIds;

							const filteredOwners = Object.values(ownersDict)
								.filter(owner => sortedOwnerIds.includes(owner.id))
								.sort((a, b) => sortedOwnerIds.indexOf(a.id) - sortedOwnerIds.indexOf(b.id));

							console.log(
								'[SERVICE] page foud and valid => return it: (' + storeFilters['owners']?.pageIndex ||
									0 + ' - ' + pageSize + ')'
							);
							return of({
								owners: filteredOwners,
								totalItems: storeOwnerPages[storeFilters['owners']?.pageIndex || 0].totalItems || 0,
								filteredItems:
									storeOwnerPages[storeFilters['owners']?.pageIndex || 0].filteredItems || 0
							});
						} else {
							console.log('[SERVICE] page NOT foud => return null');
							return of(null);
						}
					}
				),
				take(1)
			)
			.toPromise();
	}

	public loadOwnersPage(pageSize: number) {
		this.store
			.select(selectFiltersEntities)
			.pipe(take(1))
			.subscribe(storeFilters => {
				console.log(
					'[SERVICE] dispatch LOAD page ' +
						(storeFilters['owners']?.pageIndex || 0) +
						' ' +
						pageSize +
						' ' +
						JSON.stringify(storeFilters['owners']?.sort || {}) +
						' -->'
				);
				this.store.dispatch(
					loadOwnersPaginatedAction({
						page: storeFilters['owners']?.pageIndex || 0,
						pageSize: pageSize,
						filters: storeFilters['owners']?.filters || {},
						sort: storeFilters['owners']?.sort || {}
					})
				);
			});
	}
}
