<div class="lateral-container">
	<div class="lateral-top-bar">
		<img class="icon" src="./assets/img/add_payment.svg" />
		<p i18n="@@sign_title">Sign documents</p>
		<img class="close" src="./assets/img/close.svg" (click)="cancelClick()" />
	</div>

	<div class="content" #divToScroll>
		<!-- Actual content of scrollable dialog -->

		<div class="section">
			<p class="title" i18n="@@add_tenant_esig_title">E-signature of the lease</p>

			<p *ngIf="!!featureUsageStats" class="desc" i18n="@@add_tenant_esig_desc_new">
				<strong>1.</strong> Here you can upload documents of the lease to sign electronically. <br />
				<span *ngIf="featureUsageStats.includedQuantity === 0"
					><strong>2.</strong> This service is not included in your subscription plan. It has a cost
					of&nbsp;<span class="semi-bold-12 color-orange">{{
						featureUsageStats.cost | formatAmountSymbol: featureUsageStats.currency
					}}</span
					>&nbsp;(VAT excl.) for any amount of documents to sign. <br
				/></span>
				<span *ngIf="featureUsageStats.includedQuantity > 0"
					><strong>2.</strong> This service is included for free in your subscription plan. <br
				/></span>
				<strong *ngIf="featureUsageStats.includedQuantity === -1">2.</strong
				><strong *ngIf="featureUsageStats.includedQuantity !== -1">3.</strong> You may find more information in
				this guide
			</p>

			<a
				i18n-href="@@url_esignature_guide"
				i18n="@@add_lease_guide_link"
				href="https://firebasestorage.googleapis.com/v0/b/roommate-d6e73.appspot.com/o/dashboardData%2F2021_Esignature%20EN.pdf?alt=media&token=504c4b1b-084a-4e5a-b9df-085841abe53a"
				target="_blank"
				>Information about e-signature</a
			>

			<div style="margin-bottom: 20px"></div>

			<app-file-picker
				#file_signature_picker
				[truncateLenght]="40"
				customAcceptedFiles=".pdf"
				[entityType]="fileEntities.ESIGNATURES"
				sharingLabel=""
				[entityId]="leaseId ? leaseId : ''"
				[updateImmediatly]="false"
				[showIsShared]="false"
				[uploadEnabled]="true"
				(filesChanged)="onSignatureFilesChanged($event)"
			>
			</app-file-picker>

			<div style="margin-top: 10px">
				<span i18n="@@e_signature_only_tenant">Only the tenant e-sign this document</span>
				<mat-checkbox
					style="margin-left: 5px"
					[(ngModel)]="onlyTenantSigning"
					[ngModelOptions]="{ standalone: true }"
				></mat-checkbox>
			</div>
		</div>
	</div>

	<div class="lateral-bottom-bar">
		<button mat-button class="button-neutral" (click)="cancelClick()" i18n="@@com_cancel">Cancel</button>
		<div class="separator"></div>
		<button
			mat-button
			[ngClass]="{ 'button-positive': !!featureUsageStats, 'button-neutral': !!!featureUsageStats }"
			(click)="confirmClick()"
			i18n="@@com_send"
		>
			Send
		</button>
	</div>
</div>
