import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CallbackFunctions } from 'src/app/store/reducer';

import { addMockTenantAction } from 'src/app/tenants/state/tenant.actions';
import { addMockPropertyAction } from 'src/app/properties/state/properties.actions';
import { addMockMaintenanceAction } from 'src/app/maintenances/state/maintenances.actions';
import { addMockLeasesAction } from 'src/app/leases/state/lease.actions';

@Injectable({
	providedIn: 'root'
})
export class MockDataGeneratorService {
	constructor(private readonly store: Store) {}

	public generateMockProperty(callbacks?: CallbackFunctions): void {
		this.store.dispatch(addMockPropertyAction({ callbacks }));
	}

	public generateMockTenant(callbacks?: CallbackFunctions): void {
		this.store.dispatch(addMockTenantAction({ callbacks }));
	}

	public generateMockMaintenance(callbacks?: CallbackFunctions): void {
		this.store.dispatch(addMockMaintenanceAction({ callbacks }));
	}

	public generateMockLeases(callbacks?: CallbackFunctions): void {
		this.store.dispatch(addMockLeasesAction({ callbacks }));
	}
}
