import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FeatureUsageStats, BillingService, BillingFeatures } from 'src/app/billing/billing.service';
import { Tenant } from 'src/app/models/tenant.model';
import { LocalizationUtils } from 'src/app/utils/localization-utils';
import { SnotifyService } from 'ng-snotify';
import { TenantService } from '../tenant.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export interface TenantImportPayload {
	tenants: Tenant[];
}

@Component({
	selector: 'app-tenant-mass-import',
	templateUrl: './tenant-mass-import.component.html',
	styleUrls: ['./tenant-mass-import.component.scss']
})
export class TenantMassImportComponent implements OnInit, OnDestroy {
	@ViewChild('file', { static: true }) elUploadFile: ElementRef;

	featureUsageStats: FeatureUsageStats;

	constructor(
		private readonly dialogRef: MatDialogRef<TenantMassImportComponent>,
		private readonly toastService: SnotifyService,
		private readonly billingService: BillingService,
		private readonly tenantService: TenantService
	) {}

	fileDragging = false;
	currentFileError = 0;
	buttonEnabled = false;

	translations = LocalizationUtils.getTranslations();
	previewTableColumns = ['line', 'name', 'email', 'phone', 'address'];

	tenantPreviewDataSource: { errors?: string[]; line: number; tenant?: Tenant; existing: boolean }[];
	validTenants = 0;
	totalRows = 0;
	existingTenants = 0;
	warningList;
	loading = false;

	private componentUnsubscribe = new Subject();

	ngOnInit() {
		this.billingService.loadFeatureUsageStats(BillingFeatures.FEATURE_TENANTS_IMPORT);
		this.billingService
			.getFeaturesUsageStats([BillingFeatures.FEATURE_TENANTS_IMPORT])
			.pipe(takeUntil(this.componentUnsubscribe))
			.subscribe(res => {
				this.featureUsageStats = res[0];
			});
	}

	ngOnDestroy() {
		this.componentUnsubscribe.next();
	}

	fileDraggingStatusUpdated(dragging: boolean) {
		this.fileDragging = dragging;
		this.currentFileError = 0;
	}

	uploadFilesByDrop(fileList: FileList) {
		return this.processFileList(fileList);
	}

	uploadFiles() {
		this.elUploadFile.nativeElement.value = '';
		this.elUploadFile.nativeElement.click();
	}

	fileChangeEvent(ev) {
		return this.processFileList((ev.target as HTMLInputElement).files);
	}

	resetFile() {
		this.currentFileError = 0;
		this.tenantPreviewDataSource = null;
		this.buttonEnabled = false;
	}

	private processFileList(fileList: FileList) {
		console.log(JSON.stringify(fileList));
		this.currentFileError = 0;

		const file = fileList[0];

		if (file) {
			this.loading = true;

			this.tenantService
				.loadCsvFile(fileList)
				.pipe(take(1))
				.subscribe(
					result => {
						const sortedResult = result.parsedLines.sort((a, b) => a.line - b.line);

						this.currentFileError = 0;
						this.tenantPreviewDataSource = sortedResult;
						this.validTenants = sortedResult.filter(it => !it.errors || it.errors.length === 0).length;
						this.totalRows = sortedResult.length;
						this.existingTenants = sortedResult.filter(it => it.existing).length;
						this.buttonEnabled = this.validTenants > 0;
						this.warningList = sortedResult
							.filter(it => it.errors && it.errors.length > 0)
							.map(item => {
								return this.translations.tenantImportErrorTemplate
									.replace('{0}', `${item.line + 2}`)
									.replace('{1}', this.generateLocalizedNamesForParsingError(item.errors));
							});
					},
					error => {
						console.error(error);

						if (error.error && error.error.name === 'WrongFormat') {
							this.currentFileError = 2;
						} else {
							this.currentFileError = 1;
						}

						this.buttonEnabled = false;
					},
					() => {
						this.loading = false;
					}
				);
		} else {
			this.currentFileError = 0;
			this.buttonEnabled = false;
		}
	}

	executeImport() {
		const tenants =
			(this.tenantPreviewDataSource &&
				this.tenantPreviewDataSource
					.filter(it => (!it.errors || it.errors.length === 0) && it.tenant)
					.map(it => it.tenant)) ||
			[];

		if (tenants.length > 0) {
			let paylaod: TenantImportPayload = { tenants: tenants };
			let returnVal = {
				featureUsageStats: this.featureUsageStats,
				payload: paylaod
			};

			this.dialogRef.close(returnVal);
		} else {
			// This shouldn't appear
			this.toastService.error('You need at least a valid tenant to import');
		}
	}

	generateLocalizedNamesForParsingError(errors: string[]) {
		const localizedErrors = this.translations.tenantFieldsErrors;

		return errors.reduce((prev, curr, index) => {
			prev += localizedErrors[curr];

			if (index + 1 < errors.length) {
				prev += ', ';
			}

			return prev;
		}, '');
	}

	closeDialog() {
		this.dialogRef.close();
	}
}
