import { Injectable } from '@angular/core';
import { endOfMonth, startOfMonth, subMonths, setHours } from 'date-fns';
import { Observable } from 'rxjs';
import { LandlordReport } from '../../models/report';
import { LocalizationUtils } from '../../utils/localization-utils';
import { MixpanelService } from './mixpanel-service.service';
import { LandlordService } from './landlord/landlord.service';
import { CallbackFunctions } from 'src/app/store/reducer';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import { loadReportsAction } from './landlord/state/landlord.actions';

@Injectable({
	providedIn: 'root'
})
export class ReportsService {
	constructor(
		private analyticsService: MixpanelService,
		private readonly landlordService: LandlordService,
		private readonly actions$: Actions,
		private readonly store: Store
	) {}

	/*
		If ownerId is not set, I'm going to consider all the proprieties in the dashboard
		If propertiesId is not empty, I'm going to filter the properties and select only those in this array
	*/
	askForReportGeneration(
		toYear: number,
		toMonth: number,
		monthsDuration: number,
		propertiesId: string[] = [],
		ownerId: string = '',
		hostedPageId?: string,
		callbacks?: CallbackFunctions
	) {
		const endDate = endOfMonth(new Date(toYear, toMonth));
		const startDate = setHours(startOfMonth(subMonths(endDate, monthsDuration - 1)), 12);

		const options = {
			startTime: startDate.getTime(),
			endTime: endDate.getTime(),
			propertiesId,
			ownerId,
			language: LocalizationUtils.getLanguage()
		};

		this.analyticsService.track('report_requested', {
			properties_count: propertiesId.length,
			for_owner: ownerId !== '',
			monthsDuration
		});

		return this.landlordService.askForReportGeneration(options, hostedPageId, callbacks);
	}

	getReportsObservable(): Observable<LandlordReport[]> {
		return this.landlordService.getLandlordReports();
	}

	loadReports(): void {
		return this.store.dispatch(loadReportsAction());
	}
}
