import { SnotifyPosition, SnotifyToastConfig } from 'ng-snotify';
import { Spinner, Size } from 'ngx-spinner/lib/ngx-spinner.enum';
import { TaxInfo } from '../models/payments';

export class AnalyticsNames {
	static USER_PROP_COUNT = 'properties_count';
	static USER_MOCK_PROP_COUNT = 'mock_properties_count';
	static USER_UNITS_COUNT = 'total_units_count';
	static USER_MOCK_UNITS_COUNT = 'mock_total_units_count';
	static USER_TEN_COUNT = 'tenants_count';
	static USER_MOCK_TEN_COUNT = 'mock_tenants_count';
	static USER_LEASE_COUNT = 'leases_count';
	static USER_EMAIL = '$email';
	static USER_NAME = '$name';
	static USER_PLAN = 'current_plan';
	static USER_DEMO_EXPIRY = 'demo_expiry';
	static LAST_VERSION = 'last_estelle_version';
	static USER_DEMO_DURATION = 'demo_duration';
	static START_COUNT = 'start_counter';
	static UNIT_TYPE_PROP = 'unit_type_prop_count';
	static UNIT_TYPE_SINGLE = 'unit_type_single_count';
	static UNIT_TYPE_DOUBLE = 'unit_type_double_count';
}

export class AppConstants {
	public static DATE_FORMAT_XML_INVOICE = 'yyyy-MM-dd';
	public static DATE_FORMAT_STANDARD_INVOICE = 'yyyy-MM-dd';
	public static DATE_FORMAT_PDF_INVOICE = 'yyyy-MM-dd';

	public static ONESHOT_DIALOG_CONFIG = {
		width: '720px',
		backdropClass: 'backdrop-dark-background',
		disableClose: true,
		panelClass: 'no-padding-modalbox'
	};

	public static LATERAL_DIALOG_CONFIG = {
		restoreFocus: false,
		autoFocus: false,
		disableClose: true,
		width: '50%',
		height: '100%',
		minWidth: '50%',
		minHeight: '100%',
		maxWidth: '50%',
		maxHeight: '100%',
		panelClass: 'lateral-dialog-container',
		backdropClass: 'backdrop-dark-background',
		position: {
			top: '0px',
			right: '0px'
		}
	};

	public static NEW_LATERAL_DIALOG_CONFIG = {
		restoreFocus: false,
		autoFocus: false,
		disableClose: true,
		width: 'fit-content',
		height: '100%',
		minWidth: '700px',
		minHeight: '100%',
		maxWidth: '50%',
		maxHeight: '100%',
		panelClass: 'new-lateral-dialog-container',
		backdropClass: 'backdrop-dark-background',
		position: {
			top: '0px',
			right: '0px'
		}
	};

	public static LATERAL_DIALOG_CLOSEABLE_CONFIG = {
		restoreFocus: false,
		disableClose: false,
		autoFocus: false,
		width: '50%',
		height: '100%',
		minWidth: '50%',
		minHeight: '100%',
		maxWidth: '50%',
		maxHeight: '100%',
		panelClass: 'lateral-dialog-container',
		backdropClass: 'backdrop-dark-background',
		position: {
			top: '0px',
			right: '0px'
		}
	};

	public static LATERAL_DIALOG_FILTER_CONFIG = {
		restoreFocus: false,
		disableClose: false,
		width: '35%',
		height: '100%',
		minWidth: '35%',
		minHeight: '100%',
		maxWidth: '35%',
		maxHeight: '100%',
		panelClass: 'lateral-dialog-container',
		backdropClass: 'backdrop-dark-background',
		position: {
			top: '0px',
			right: '0px'
		}
	};

	public static CENTRAL_DIALOG_CONFIG = {
		panelClass: 'central-dialog-container',
		backdropClass: 'backdrop-dark-background',
		width: '720px'
	};

	public static CENTRAL_MODAL_DIALOG_CONFIG = {
		width: '720px',
		backdropClass: 'backdrop-dark-background',
		disableClose: true,
		panelClass: 'no-padding-modalbox'
	};

	public static CENTRAL_NO_ACTIONS_MODAL_DIALOG_CONFIG = {
		width: '720px',
		backdropClass: 'backdrop-dark-background',
		disableClose: true,
		panelClass: 'no-actions-dialog'
	};

	public static SPINNER_STANDARD_CONFIG: Spinner = {
		type: 'line-scale-party',
		size: 'large' as Size,
		bdColor: 'rgba(0,0,0, .7)',
		color: '#00BFF2'
	};

	public static SPINNER_ELEMENT_CONFIG: Spinner = {
		type: 'line-scale-party',
		size: 'medium' as Size,
		bdColor: '#ffffff',
		color: '#00BFF2',
		zIndex: 1
	};

	public static TOAST_STD_CONFIG: SnotifyToastConfig = {
		position: SnotifyPosition.rightTop,
		timeout: 2000,
		titleMaxLength: 40
	};

	public static TOAST_MOBILE_VERSION_CONFIG: SnotifyToastConfig = {
		position: SnotifyPosition.rightTop,
		timeout: 0,
		titleMaxLength: 40
	};

	public static DatabaseNotificationType = {
		NEW_SCREENING: 0,
		NEW_PROPERTY_MESSAGE: 1,
		NEW_TENANT_MESSAGE: 2,
		NEW_MAINTENANCE_REQUEST: 3,
		RENT_PAID: 4, // With mangopay
		EXPENSE_PAID: 5, // With mangopay
		RENT_OVERDUE_MANGO: 6, // If mangopay is configured and tenant hasn't paid
		EXPENSE_OVERDUE_MANGO: 7, // If mangopay is configured and tenant hasn't paid
		RENT_OVERDUE: 8, // If mangopay is not configured and tenant hans't paid
		EXPENSE_OVERDUE: 9, // If mangopay is not configured and tenant hasn't paid
		LEASE_30_DAY: 10, // Lease is expiring within 30 days
		LEASE_0_DAY: 11, // Lease just expired
		LEASE_0_DAY_TERMINATED: 12 // Lease has ended before the date due to an intervention of the landlord
	};

	public static landlordsWithLeaseTemplate = {
		YBETNOVNlOPhXoxHjyGg7FdzpeI3: 181, // Roomie
		JlQYSg2aAcX4CMDMcwdAwWYjQOl2: 192,
		lnMK7rnXP3OFlnQj1h2zRJ1chZD2: 208, // OurCrib
		r1h5xSlVsOgSmgSU2HDv0rUilE93: 228, // Stéphane Godefroit
		muP4M9SiECb89fe7h5g1CEVpG3l1: 229, // Wow Living
		hUrbgl1hFLbPXTOdFk4MsJHt8iA2: 232, // LivingMad
		VEDmyJkiwDQVWBn4VmnFsEUHZVW2: 250,
		mTdeitT6IJPGnBNus9lLW16z7N12: 251,
		GWLQ6mMnboUIPM6gtPwbxB3G8dW2: 252, // MyColiving PMS2
		lTLGmWY1d0dpeD2H4fJLfiimLPm2: 253, // MyColiving PMS1
		O89zEZ9ftlh6WF4SK8PWnxQC2dt2: 254, // Patrick Mayer
		aGOJ4fp7qnhToqNtG7PHziUq0iE2: 256, // EstateToRent
		elelx6L3ZjbnNgJAF78d9IwpAkg2: 259, // // Bjorn Vankerkhoven Atworkforyou + Fantasio
		C84ezB5X5rXioo4xoY7HsKP7IbM2: 309, // Italia in Campus
		Apw5KR98tSNkQb5nlHlQGiR7mBs1: 313, // Habita Sense
		bH4rxgHfUzTxoVp7mdqcILu2dng1: 324,
		pNTQixfXVCO80ndrYLQOfUZ1cQ92: 327, // Blanco Rooms
		'9RzxpLQbhYaDiLlLibuXLaZo4Ns2': 322,
		'5AR0w88xkUOax7FMKjvgN7KmyMV2': -1 // landlord@example.com
	};

	public static taxValueList: TaxInfo[] = [
		{
			vatCode: '0',
			vatValue: 22,
			description: ''
		},
		{
			vatCode: '1',
			vatValue: 21,
			description: ''
		},
		{
			vatCode: '2',
			vatValue: 20,
			description: ''
		},
		{
			vatCode: '3',
			vatValue: 10,
			description: ''
		},
		{
			vatCode: '4',
			vatValue: 4,
			description: ''
		},
		{
			vatCode: '6',
			vatValue: 0,
			description: ''
		},
		{
			vatCode: '21',
			vatValue: 0,
			description: 'Escluso Art.15'
		},
		/*
		{
			vatCode: "7",
			vatValue: 0,
			description: "Regime dei minimi"
		},
		{
			vatCode: "9",
			vatValue: 0,
			description: "Fuori campo IVA",
		},
		{
			vatCode: "10",
			vatValue: 0,
			description: "Oper. non soggetta, art.7 ter",
		},
		{
			vatCode: "11",
			vatValue: 0,
			description: "Inversione contabile, art.7 ter",
		},
		{
			vatCode: "12",
			vatValue: 0,
			description: "Non Imponibile",
		},
		{
			vatCode: "13",
			vatValue: 0,
			description: "Non Imp. Art.8",
		},
		{
			vatCode: "14",
			vatValue: 0,
			description: "Non Imp. Art.9 1C",
		},
		{
			vatCode: "15",
			vatValue: 0,
			description: "Non Imp. Art.14 Legge 537/93",
		},
		{
			vatCode: "16",
			vatValue: 0,
			description: "Non Imp. Art.41 D.P.R. 331/93",
		},
		{
			vatCode: "17",
			vatValue: 0,
			description: "Non Imp. Art.72, D.P.R. 633/72",
		},
		{
			vatCode: "18",
			vatValue: 0,
			description: "Non Imp. Art.74 quotidiani/libri",
		},
		{
			vatCode: "19",
			vatValue: 0,
			description: "Escluso Art.10",
		},
		{
			vatCode: "20",
			vatValue: 0,
			description: "Escluso Art.13 5C DPR 633/72",
		},
		{
			vatCode: "22",
			vatValue: 0,
			description: "Rev. charge art.17",
		},
		{
			vatCode: '23',
			vatValue: 0,
			description: 'Escluso Art.74 ter D.P.R. 633/72'
		},
		{
			vatCode: "24",
			vatValue: 0,
			description: "Escluso Art.10 comma 1",
		},
		{
			vatCode: "25",
			vatValue: 0,
			description: "Escluso Art.10 comma 20",
		},
		{
			vatCode: "26",
			vatValue: 0,
			description: "Non Imp. Art.9",
		},
		{
			vatCode: "27",
			vatValue: 0,
			description: "Escluso Art.10 n.27 D.P.R 633/72",
		},
		{
			vatCode: "29",
			vatValue: "8",
			description: "",
		},
		{
			vatCode: "30",
			vatValue: 0,
			description: "Regime del margine art.36 41/95",
		},
		{
			vatCode: "31",
			vatValue: 0,
			description: "Escluso Art.3 comma 4 D.P.R 633/72",
		},
		{
			vatCode: "33",
			vatValue: 0,
			description: "Non imp. Art.8/c D.P.R. 633/72",
		},
		{
			vatCode: "34",
			vatValue: 0,
			description: "Non Imp. Art.7 ter",
		},
		{
			vatCode: "35",
			vatValue: 0,
			description: "Escluso Art.7 D.P.R 633/72",
		},
		{
			vatCode: "36",
			vatValue: "22",
			description: "Esigibilita differita Art. 6 comma 5 D.P.R 633/72",
		},
		{
			vatCode: "37",
			vatValue: 0,
			description: "Escluso Art.10 comma 9",
		},
		{
			vatCode: "38",
			vatValue: 0,
			description: "Non imp. Art.7 quater DPR 633/72",
		},
		{
			vatCode: "39",
			vatValue: 0,
			description: "Non Imp. Art.8 comma 1A",
		},
		*/
		{
			vatCode: '40',
			vatValue: 23,
			description: ''
		},
		/*
		{
			vatCode: "41",
			vatValue: "24",
			description: "",
		},
		{
			vatCode: "42",
			vatValue: 0,
			description: "Non Imp. Art.2 comma 4 D.P.R 633/72",
		},
		{
			vatCode: "43",
			vatValue: 0,
			description: "Non Imp. Art.18 633/72",
		},
		{
			vatCode: "44",
			vatValue: 0,
			description: "Fuori Campo IVA Art.7 ter D.P.R 633/72",
		},
		{
			vatCode: "45",
			vatValue: 0,
			description: "Non Imp. Art.10 n.18 DPR 633/72",
		},
		{
			vatCode: "47",
			vatValue: 0,
			description: "Non imp. art.1 L. 244/2008",
		},
		{
			vatCode: "48",
			vatValue: 0,
			description: "Non imp. art.40 D.L. 427/93",
		},
		{
			vatCode: "49",
			vatValue: 0,
			description: "Non imp. art.41 D.L. 427/93",
		},
		{
			vatCode: "50",
			vatValue: 0,
			description: "Non imp. art.71 DPR 633/72",
		},
		{
			vatCode: "51",
			vatValue: 0,
			description: "Non imp. art.8 DPR 633/72",
		},
		{
			vatCode: "52",
			vatValue: 0,
			description: "Non imp. art.9 DPR 633/72",
		},
		{
			vatCode: "53",
			vatValue: 0,
			description: "Regime minimi 2015",
		},
		{
			vatCode: "54",
			vatValue: 5,
			description: "",
		},
		*/
		{
			vatCode: '32',
			vatValue: 0,
			description: 'Escluso Art.15/1c D.P.R 633/72',
			natura: 'N1',
			exlusiveOf: ['it']
		},
		{
			vatCode: '46',
			vatValue: 0,
			description: 'Esente Art.10 DPR 633/72',
			natura: 'N4',
			exlusiveOf: ['it']
		},
		{
			vatCode: '55',
			vatValue: 0,
			description: 'Non soggetta IVA',
			exlusiveOf: ['it']
		},
		{
			vatCode: 'rm1_lu',
			vatValue: 17,
			description: '',
			exlusiveOf: ['lu']
		},
		{
			vatCode: 'rm1_lu',
			vatValue: 17,
			description: '',
			exlusiveOf: ['lu']
		},
		{
			vatCode: 'rm1_ch',
			vatValue: 7.7,
			description: '',
			exlusiveOf: ['ch']
		},
		{
			vatCode: 'rm2_ch',
			vatValue: 3.7,
			description: '',
			exlusiveOf: ['ch']
		},
		{
			vatCode: 'rm3_ch',
			vatValue: 2.5,
			description: '',
			exlusiveOf: ['ch']
		},
		{
			vatCode: 'rm1_de',
			vatValue: 19,
			description: ''
		}
	];

	public static getOneShotDialogConfig(data: any) {
		return {
			...this.ONESHOT_DIALOG_CONFIG,
			data
		};
	}
}
