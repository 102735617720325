import { createAction, props } from '@ngrx/store';
import { PlanEstimation, UserBillingInfo } from 'src/app/models/billing.model';
import { CallbackFunctions } from 'src/app/store/reducer';
import { BillingFeatures, BillingPlanNames, CheckoutPopupEvent, FeatureUsageStats, PlanUnitDetails } from '../billing.service';

export enum BillingActions {
	LOAD_BILLING_DETAILS = '[Billing] Load Billing Details',
	LOAD_FEATURE_USAGE_STATS = '[Billing] Load Feature Usage Stats',
	REQUEST_CHECKOUT_PAGE = '[Billing] Update Checkout Plan',
	REQUEST_CHECKOUT_FEATURE = '[Billing] Update Checkout Feature',
	REQUEST_PORTAL_PAGE = '[Billing] Request Portal Page',
	UPDATE_BILLING_PLAN = '[Billing] Update Billing Plan',
	RESET_BILLING_STATE = '[Billing] Reset Billing State',

	LOAD_BILLING_DETAILS_SUCCESS = '[Billing] Load Billing Details Success',
	LOAD_FEATURE_USAGE_STATS_SUCCESS = '[Billing] Load Feature Usage Stats Success',
	REQUEST_CHECKOUT_PAGE_SUCCESS = '[Billing] Update Checkout Plan Success',
	REQUEST_CHECKOUT_FEATURE_SUCCESS = '[Billing] Update Checkout Feature Success',
	REQUEST_PORTAL_PAGE_SUCCESS = '[Billing] Request Portal Page Success',
	UPDATE_BILLING_PLAN_SUCCESS = '[Billing] Update Billing Plan Success',
	RESET_BILLING_STATE_SUCCESS = '[Billing] Reset Billing State Success',

	LOAD_BILLING_DETAILS_FAILURE = '[Billing] Load Billing Details Failure',
	LOAD_FEATURE_USAGE_STATS_FAILURE = '[Billing] Load Feature Usage Stats Failure',
	REQUEST_CHECKOUT_PAGE_FAILURE = '[Billing] Update Checkout Plan Failure',
	REQUEST_CHECKOUT_FEATURE_FAILURE = '[Billing] Update Checkout Feature Failure',
	REQUEST_PORTAL_PAGE_FAILURE = '[Billing] Request Portal Page Failure',
	UPDATE_BILLING_PLAN_FAILURE = '[Billing] Update Billing Plan Failure',
	RESET_BILLING_STATE_FAILURE = '[Billing] Reset Billing State Failure',

	REQUEST_CHECKOUT_PAGE_CLOSE = '[Billing] Request Checkout Page Close'
}

export const loadBillingDetailsAction = createAction(BillingActions.LOAD_BILLING_DETAILS);

export const loadFeatureUsageStatsAction = createAction(BillingActions.LOAD_FEATURE_USAGE_STATS, props<{ featureName: BillingFeatures; referenceId: string }>());
export const requestCheckoutPageAction = createAction(BillingActions.REQUEST_CHECKOUT_PAGE, props<{ planId: BillingPlanNames; planQuantity: number; callbacks?: CallbackFunctions }>());
export const requestCheckoutFeatureAction = createAction(BillingActions.REQUEST_CHECKOUT_FEATURE, props<{ feature: BillingFeatures }>());
export const requestPortalPageAction = createAction(BillingActions.REQUEST_PORTAL_PAGE);
export const updateBillingPlanAction = createAction(BillingActions.UPDATE_BILLING_PLAN, props<{ planId: string; planQuantity?: number }>());
export const resetBillingStateAction = createAction(BillingActions.RESET_BILLING_STATE);

export const loadBillingDetailsSuccessAction = createAction(BillingActions.LOAD_BILLING_DETAILS_SUCCESS, props<{ billingInfo: UserBillingInfo }>());
export const loadFeatureUsageStatsSuccessAction = createAction(BillingActions.LOAD_FEATURE_USAGE_STATS_SUCCESS, props<{ featureUsage: FeatureUsageStats }>());
export const requestCheckoutPageSuccessAction = createAction(BillingActions.REQUEST_CHECKOUT_PAGE_SUCCESS, props<{ result: string; planId: BillingPlanNames; planQuantity: number }>());
export const requestCheckoutFeatureSuccessAction = createAction(BillingActions.REQUEST_CHECKOUT_FEATURE_SUCCESS, props<{ popup: CheckoutPopupEvent }>());
export const requestPortalPageSuccessAction = createAction(BillingActions.REQUEST_PORTAL_PAGE_SUCCESS);
export const updateBillingPlanSuccessAction = createAction(BillingActions.UPDATE_BILLING_PLAN_SUCCESS, props<{ res: any }>());
export const resetBillingStateSuccessAction = createAction(BillingActions.RESET_BILLING_STATE_SUCCESS);

// Failure actions
export const loadBillingDetailsFailureAction = createAction(BillingActions.LOAD_BILLING_DETAILS_FAILURE, props<{ error?: any }>());
export const loadFeatureUsageStatsFailureAction = createAction(BillingActions.LOAD_FEATURE_USAGE_STATS_FAILURE, props<{ error?: any }>());
export const requestCheckoutPageFailureAction = createAction(BillingActions.REQUEST_CHECKOUT_PAGE_FAILURE, props<{ error?: any }>());
export const requestCheckoutFeatureFailureAction = createAction(BillingActions.REQUEST_CHECKOUT_FEATURE_FAILURE, props<{ error?: any }>());
export const requestPortalPageFailureAction = createAction(BillingActions.REQUEST_PORTAL_PAGE_FAILURE, props<{ error?: any }>());
export const updateBillingPlanFailureAction = createAction(BillingActions.UPDATE_BILLING_PLAN_FAILURE, props<{ error?: any }>());
export const resetBillingStateFailureAction = createAction(BillingActions.RESET_BILLING_STATE_FAILURE, props<{ error?: any }>());

export const requestCheckoutPageCloseAction = createAction(BillingActions.REQUEST_CHECKOUT_PAGE_CLOSE);
