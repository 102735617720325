import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { ActionsSubject, Store } from '@ngrx/store';
import { flatten } from 'lodash-es';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FiltersStoreModel } from '../core/services/filters/filters.model';
import { selectFiltersEntities } from '../core/services/filters/state/filters.selector';
import { LandlordService } from '../core/services/landlord/landlord.service';
import { MixpanelService } from '../core/services/mixpanel-service.service';
import { LeaseService } from '../leases/lease.service';
import { Lease } from '../models/lease.model';
import {
	Property,
	PropertyBasic,
	PropertyData,
	PropertyPageModelStore,
	PropertyPageModelUI,
	UnitBasic,
	UnitData
} from '../models/property.model';
import { addNewPropertyToOwnerAction } from '../owners/state/owners.actions';
import { CallbackFunctions } from '../store/reducer';
import { CsvTenantParseResponse } from '../tenants/tenant.service';
import { AnalyticsNames } from '../utils/app-costants';
import { AppUtils } from '../utils/app-utils';
import { LocalizationUtils } from '../utils/localization-utils';
import { PropertiesView } from './properties.component';
import { selectPropertyBasicState } from './state/properties-basic.selectors';
import { selectPropertyPagesEntities } from './state/properties-pages.selectors';
import {
	addPropertyAction,
	deletePropertiesAction,
	deletePropertyAction,
	editPropertyAction,
	getPropertiesCsvAction,
	loadPropertiesBasicAction,
	loadPropertiesPaginatedAction,
	loadPropertyByIdAction,
	uploadMultiplePropertiesAction
} from './state/properties.actions';
import { selectPropertyDictionaryState } from './state/properties.selectors';

@Injectable({
	providedIn: 'root'
})
export class PropertyService {
	private translations = LocalizationUtils.getTranslations();
	private BACKEND_HOST = `${environment.services.backend}/api-dash/v1`;
	// Global state for selected view on the current session of Estelle (cards or lists? Units or properties?)
	propertiesSelectedView: PropertiesView = 'properties';
	propertiesSelectedDisplayMode = 1; // 0=cards_view, 1=list_view
	lock: false;

	constructor(
		private readonly store: Store,
		private readonly analyticsService: MixpanelService,
		private readonly leaseService: LeaseService,
		private readonly httpClient: HttpClient,
		private readonly landlordService: LandlordService
	) {}

	/**
	 *  Tries to see if the property is looking for is in the state. If not, request the property to be retrieved
	 * */
	public getPropertyById(id: string, isForLeaseItem?: boolean): Observable<Property> {
		return this.store
			.select(selectPropertyDictionaryState)
			.pipe(
				AppUtils.applyRefreshLogicForItemNotFound(
					this.store,
					id,
					loadPropertyByIdAction({ propertyId: id, isForLeaseItem: isForLeaseItem })
				)
			);
	}

	public getPropertyBasicById(id: string): Observable<PropertyBasic> {
		return this.getPropertiesBasicDict().pipe(map(props => props[id]));
	}

	private getPropertiesBasicDict(): Observable<Dictionary<PropertyBasic>> {
		return this.store
			.select(selectPropertyBasicState)
			.pipe(AppUtils.fetchIfMissing(this.store, loadPropertiesBasicAction({ refreshAll: false })));
	}

	public getPropertiesBasicArray(): Observable<PropertyBasic[]> {
		return this.getPropertiesBasicDict().pipe(
			map(propertiesDict => {
				if (propertiesDict) {
					return Object.values(propertiesDict);
				} else {
					return null;
				}
			})
		);
	}

	public getUnitsBasic(): Observable<UnitBasic[]> {
		return this.getPropertiesBasicArray().pipe(
			map((propertiesBasic: PropertyBasic[]) => {
				return flatten(
					propertiesBasic.map(
						propertyBasic => propertyBasic.units.map(u => ({ ...u, propertyId: propertyBasic.id })) || []
					)
				);
			})
		);
	}

	public getUnitsBasicFromNoMockProperties(): Observable<UnitBasic[]> {
		return this.getPropertiesBasicArray().pipe(
			map((propertiesBasic: PropertyBasic[]) => {
				const realProperties = propertiesBasic.filter(p => !p.isMock);
				return flatten(
					realProperties.map(
						propertyBasic => propertyBasic.units.map(u => ({ ...u, propertyId: propertyBasic.id })) || []
					)
				);
			})
		);
	}

	/** Observable version, will try to fetch the property if  */
	public getPropertyNameById(id: string): Observable<string> {
		return this.getPropertyBasicById(id).pipe(map(it => this.getPropertyName(it)));
	}

	public deleteProperty(id: string, isBulk: boolean = false): Promise<void> {
		return AppUtils.buildActionWithPromise(this.store, deletePropertyAction({ propertyId: id, isBulk })).then(
			() => {
				this.analyticsService.incrementUserProperty(AnalyticsNames.USER_PROP_COUNT, -1);
				this.analyticsService.track('property_delete');
			}
		);
	}

	public deleteProperties(ids: string[]) {
		return AppUtils.buildActionWithPromise(this.store, deletePropertiesAction({ propertyIds: ids }));
	}

	public getPropertiesCsvFile(files: FileList): Observable<CsvTenantParseResponse> {
		const file = files[0];
		const formData = new FormData();
		formData.append('file', file, file.name);
		return this.landlordService
			.getLandlordId()
			.pipe(
				switchMap(landlordId =>
					this.httpClient.post<CsvTenantParseResponse>(
						`${this.BACKEND_HOST}/landlords/${landlordId}/properties/csv`,
						formData
					)
				)
			);
	}

	public getPropertyName(property: Property | PropertyBasic, truncation: number = 0, fallback?: string): string {
		if (property && property.name) {
			let name = property.name;

			if (truncation > 0 && name.length > truncation) {
				name = name.substr(0, truncation - 1) + '…';
			}
			return name;
		} else {
			if (fallback) {
				return fallback;
			} else {
				return this.translations.property_deleted;
			}
		}
	}

	public getPropertyAddress(property: Property, truncation: number = 0, fallback?: string): string {
		if (!property) {
			return fallback || '-';
		}

		const propertyAddress = this.extractPropertyaddress(property);
		if (propertyAddress == ' , ') {
			return '';
		}

		if (truncation > 0 && propertyAddress.length > truncation) {
			return propertyAddress.substr(0, truncation - 1) + '…';
		} else {
			return propertyAddress;
		}
	}

	// ----------------------------------------------------------------------------------------------------
	// ---------------------------------------- TO BE ITERATED YET ----------------------------------------

	public getPropertiesByUnitIds(properties: Property[], unitIds: string[]): Property[] {
		return properties.filter(property => {
			const propUnits = Object.values(property.units).filter(unit => unitIds.includes(unit.id));
			return propUnits.length;
		});
	}

	public getUnitDataForPopCard(unitId: string): Observable<UnitData> {
		return this.getPropertiesBasicArray().pipe(
			map((properties: PropertyBasic[]) => {
				let unitData = null;

				const property = properties.find(prop => {
					return prop.units.find(u => u.id === unitId);
				});

				if (!!property) {
					const unit = property.units.find(u => u.id === unitId);

					const name = unit.name || property.name;

					let type = '';

					if (property.managedAsProperty) {
						type = $localize`:@@com_property:Property`;
					} else {
						type =
							unit.type === 'single' ? $localize`:@@com_single:Single` : $localize`:@@com_double:Double`;
					}

					unitData = <UnitData>{
						id: unitId,
						name,
						propertyId: property.id,
						propertyName: this.getPropertyName(property),
						type
					};
				}
				return unitData;
			})
		);
	}

	public getPropertyDataForPopCard(propertyId: string): Observable<PropertyData> {
		return this.getPropertyById(propertyId).pipe(
			map(property => {
				return <PropertyData>{
					id: propertyId,
					name: this.getPropertyName(property),
					city: property.city,
					address: this.getPropertyAddress(property)
				};
			})
		);
	}

	public addPropertyIdsToOwners(property: Property) {
		if (property.owners && property.owners.length > 0) {
			property.owners.forEach(owner =>
				this.store.dispatch(addNewPropertyToOwnerAction({ ownerId: owner.id, propertyIdsToAdd: [property.id] }))
			);
		}
	}

	public addProperty(property: Property, pastPaymentsPaid: boolean = false, callbacks?: CallbackFunctions): void {
		this.store.dispatch(
			addPropertyAction({
				property,
				options: {
					pastPaymentsPaid
				},
				callbacks
			})
		);
	}

	public editProperty(property: Property, showSuccessToast: boolean = false, callbacks?: CallbackFunctions) {
		this.store.dispatch(editPropertyAction({ property, showSuccessToast, callbacks }));
	}

	public isUnitAvailableInPeriod(
		unitId: string,
		fromEpoch: number,
		toEpoch: number,
		leasesDict: Dictionary<Lease>
	): boolean {
		const leases = Object.values(leasesDict)
			.filter(it => it.unitId === unitId)
			.filter(it => this.leaseService.isLeaseActiveInPeriod(it, fromEpoch, toEpoch));
		return leases.length > 0;
	}

	public numAvailableRoomsInPeriod(
		property: Property,
		fromEpoch: number,
		toEpoch: number,
		leasesDict: Dictionary<Lease>
	): number {
		return Object.values(property.units || {}).filter(it =>
			this.isUnitAvailableInPeriod(it.id, fromEpoch, toEpoch, leasesDict)
		).length;
	}

	public isPropertyIdAvailableInPeriod(
		property: Property,
		fromEpoch: number,
		toEpoch: number,
		leasesDict: Dictionary<Lease>
	): boolean {
		return this.numAvailableRoomsInPeriod(property, fromEpoch, toEpoch, leasesDict) > 0;
	}

	public getNumberOfUnits(properties: Property[]) {
		let numOfUnits = 0;
		properties.forEach(property => {
			if (property.units) {
				numOfUnits += Object.values(property.units).length;
			}
		});

		return numOfUnits;
	}

	public uploadMultipleProperties(properties: Property[], hostedPageId?: string, callbacks?: CallbackFunctions) {
		return this.store.dispatch(uploadMultiplePropertiesAction({ properties, hostedPageId, callbacks }));
	}

	public extractPropertyaddress(p: Property): string {
		return p
			? p.address +
					' ' +
					p.houseNumber +
					', ' +
					p.city +
					(!!p.stairwell ? ', ' + p.stairwell : '') +
					(!!p.unit ? ', ' + p.unit : '')
			: '';
	}

	// Version 2 - Backend pagination & filtering

	public getPropertiesPaginated(pageSize: number): Promise<PropertyPageModelUI> {
		return combineLatest([this.store.select(selectFiltersEntities), this.store.select(selectPropertyPagesEntities)])
			.pipe(
				take(1),
				switchMap(
					([storeFilters, storePropertyPages]: [
						Dictionary<FiltersStoreModel>,
						Dictionary<PropertyPageModelStore>
					]) => {
						if (
							storeFilters &&
							storePropertyPages &&
							storePropertyPages[storeFilters['properties']?.pageIndex || 0]
						) {
							return combineLatest([
								of(storeFilters),
								of(storePropertyPages),
								this.store.select(selectPropertyDictionaryState)
							]);
						} else {
							return combineLatest([of(null), of(null), of(null)]);
						}
					}
				),
				switchMap(
					([storeFilters, storePropertyPages, propertiesDict]: [
						Dictionary<FiltersStoreModel>,
						Dictionary<PropertyPageModelStore>,
						Dictionary<Property>
					]) => {
						if (storeFilters !== null && storePropertyPages && propertiesDict) {
							const sortedPropertyIds =
								storePropertyPages[storeFilters['properties']?.pageIndex || 0].propertyIds;

							const filteredProperties = Object.values(propertiesDict)
								.filter(property => sortedPropertyIds.includes(property.id))
								.sort((a, b) => sortedPropertyIds.indexOf(a.id) - sortedPropertyIds.indexOf(b.id));

							console.log(
								'[SERVICE] page foud and valid => return it: (' +
									(storeFilters['properties']?.pageIndex || 0) +
									' - ' +
									pageSize +
									')'
							);
							return of({
								properties: filteredProperties,
								totalItems:
									storePropertyPages[storeFilters['properties']?.pageIndex || 0].totalItems || 0,
								filteredItems:
									storePropertyPages[storeFilters['properties']?.pageIndex || 0].filteredItems || 0
							});
						} else {
							console.log('[SERVICE] page NOT foud => return null');
							return of(null);
						}
					}
				),
				take(1)
			)
			.toPromise();
	}

	public loadPropertiesPage(pageSize: number) {
		this.store
			.select(selectFiltersEntities)
			.pipe(take(1))
			.subscribe(storeFilters => {
				console.log(
					'[SERVICE] dispatch LOAD page ' +
						(storeFilters['properties']?.pageIndex || 0) +
						' ' +
						pageSize +
						' ' +
						JSON.stringify(storeFilters['properties']?.sort || {}) +
						' -->'
				);
				this.store.dispatch(
					loadPropertiesPaginatedAction({
						page: storeFilters['properties']?.pageIndex || 0,
						pageSize: pageSize,
						filters: storeFilters['properties']?.filters || {},
						sort: storeFilters['properties']?.sort || {}
					})
				);
			});
	}

	public exportProperties(): void {
		return this.store.dispatch(getPropertiesCsvAction());
	}
}
