import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { FiltersStoreModel } from '../filters.model';
import { publishNewFiltersAction } from './filters.actions';

export interface FiltersState extends EntityState<FiltersStoreModel> {}

export const filtersAdapter = createEntityAdapter<FiltersStoreModel>({
	selectId: model => model.type
});
const initialTenantsFiltersState = filtersAdapter.getInitialState();

export const FiltersReducers = createReducer(
	initialTenantsFiltersState,
	on(publishNewFiltersAction, (state, payload) => filtersAdapter.upsertOne(payload.filters, { ...state }))
);
