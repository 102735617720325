import { createAction } from '@ngrx/store';

export enum RoommateActions {
	RESET_STORE_ACTION = '[CORE] Reset Store', // Clear all observables caching effects
	RESET_STORE_ACTION_SUCCESS = '[CORE] Reset Store Success'
}

export const resetStoreAction = createAction(RoommateActions.RESET_STORE_ACTION);

export const resetStoreActionSuccess = createAction(RoommateActions.RESET_STORE_ACTION_SUCCESS);
