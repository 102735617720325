export class CalendarEvent {
	constructor(
		public id: string,
		public title: string,
		public description: string,
		public creationDate: number,
		public authorId: string,
		public categoryId: string,
		public startDate: number,
		public endDate: number,
		public allDay: boolean,
		public tenantId?: string,
		public propertyId?: string,
		public leaseId?: string,
		public notification?: {
			type: 'hours' | 'minutes' | 'days';
			value: number;
			medium: string[];
		}
	) {}
}

export class CalendarCategory {
	constructor(
		public id: string,
		public name: string,
		public color: string,
		public author: string,
		public order: number
	) {}
}

export interface CalendarEventsViewData {
	currentDate: Date;
	events: CalendarEvent[];
	categories: CalendarCategory[];
}
