import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { differenceInHours } from 'date-fns';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Booking, BookingStatus, CreateBooking } from 'src/app/models/booking.model';
import { CallbackFunctions } from '../store/reducer';
import { AppUtils } from '../utils/app-utils';

import { addBookingAction, deleteBookingAction, editBookingAction, loadBookingsAction } from './state/bookings.actions';
import { selectBookingsState } from './state/bookings.selectors';

/**
 * Class that still fetchs all bokings before displaying data
 * */
@Injectable({
	providedIn: 'root'
})
export class BookingsService {
	constructor(private readonly store: Store) {}

	public getBookingById(bookingId: string): Observable<Booking> {
		return this.getBookingsDict().pipe(map(bookingsDict => bookingsDict[bookingId]));
	}

	public getBookingsDict() {
		return this.store.select(selectBookingsState).pipe(AppUtils.fetchIfMissing(this.store, loadBookingsAction()));
	}

	getBookings(): Observable<Booking[]> {
		return this.getBookingsDict().pipe(map(bookingsDict => Object.values(bookingsDict)));
	}

	getBookingByUnitId(unitId: string): Observable<Booking[]> {
		return this.getBookings().pipe(
			map(bookings => {
				return bookings.filter(booking => {
					return booking.unitId === unitId;
				});
			})
		);
	}

	getBookingByPlatformId(platformId: string): Observable<Booking[]> {
		return this.getBookings().pipe(
			map(bookings => {
				return bookings.filter(booking => {
					return booking.channelId === platformId;
				});
			})
		);
	}

	getBookingsForPeriod(fromEpoch: number, toEpoch: number): Promise<Booking[]> {
		return this.getBookings()
			.pipe(
				take(1),
				map(bookings => {
					return bookings.filter(booking => {
						return (
							booking.status === BookingStatus.CONFIRMED &&
							booking.startDate <= toEpoch &&
							booking.endDate >= fromEpoch
						);
					});
				})
			)
			.toPromise();
	}

	public getActiveConfirmedBookingsForUnitStatus(
		bookings: Booking[],
		propertyId?: string,
		unitId?: string
	): Booking[] {
		return bookings
			.filter(
				booking =>
					(booking.propertyId === propertyId || booking.unitId === unitId) &&
					booking.status === BookingStatus.CONFIRMED &&
					new Date().getTime() >= booking.startDate &&
					new Date().getTime() <= booking.endDate
			)
			.sort((bookingA, bookingB) => bookingA.startDate - bookingB.startDate);
	}

	public getFutureConfirmedBookingsForUnitStatus(
		bookings: Booking[],
		propertyId?: string,
		unitId?: string
	): Booking[] {
		const maxIntervalInHours = 48;
		return bookings
			.filter(
				booking =>
					(booking.propertyId === propertyId || booking.unitId === unitId) &&
					booking.status === BookingStatus.CONFIRMED &&
					booking.startDate > new Date().getTime() &&
					Math.abs(differenceInHours(new Date().getTime(), booking.startDate)) < maxIntervalInHours
			)
			.sort((bookingA, bookingB) => bookingA.startDate - bookingB.startDate);
	}

	public getActiveOrFutureConfirmedBookingsForUnitStatus(
		bookings: Booking[],
		propertyId?: string,
		unitId?: string
	): Booking[] {
		const maxIntervalInHours = 48;
		return bookings
			.filter(
				booking =>
					(booking.propertyId === propertyId || booking.unitId === unitId) &&
					booking.status === BookingStatus.CONFIRMED &&
					((booking.startDate > new Date().getTime() &&
						Math.abs(differenceInHours(new Date().getTime(), booking.startDate)) < maxIntervalInHours) ||
						(new Date().getTime() >= booking.startDate && new Date().getTime() <= booking.endDate))
			)
			.sort((bookingA, bookingB) => bookingA.startDate - bookingB.startDate);
	}

	public createBooking(booking: CreateBooking, callbacks?: CallbackFunctions): void {
		this.store.dispatch(addBookingAction({ booking, callbacks }));
	}

	public editBooking(booking: Booking, callbacks?: CallbackFunctions): void {
		this.store.dispatch(editBookingAction({ booking, callbacks }));
	}

	public removeBooking(booking: Booking): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			const callbacks: CallbackFunctions = {
				success: () => {
					resolve();
				},
				fail: () => {
					reject();
				}
			};
			this.store.dispatch(deleteBookingAction({ booking, callbacks }));
		});
	}

	public getBookingDataForPopCard(bookingId: string) {
		return this.getBookingById(bookingId).pipe(
			map(booking => {
				return {
					id: booking.id,
					currency: booking.currency,
					startDate: booking.startDate,
					endDate: booking.endDate,
					tenantId: booking.tenantId,
					pendingTenantId: booking.pendingTenantData?.id,
					value: booking.monthlyRent,
					type: booking.type
				};
			})
		);
	}
}
