export const environment = {
	production: true,

	featuresEnabled: {
		analytics: false,
		verboseLog: true
	},

	bookingEngine: {
		bookingEngine: 'booking-test.rentyournest.com',
		sourceSite: 'ryn',
		channelCode: 'trm'
	},

	services: {
		backend: 'https://backend-beta-gkd742rcla-ew.a.run.app',
		billing: 'https://billing-service-beta-dot-roommate-d6e73.uc.r.appspot.com',
		channelmanager: 'https://channelmanager-prod-gkd742rcla-ew.a.run.app/',
		fileManager: 'https://file-manager-beta-dot-roommate-d6e73.uc.r.appspot.com/fm/v1',
		socket: 'https://roommate-websockets-dot-roommate-d6e73.uc.r.appspot.com/'
	},

	firebase: {
		apiKey: 'AIzaSyDBnIOA0ZzfcdG_I5v2Mj50Fe2B2KtO7Oo',
		authDomain: 'roommate-d6e73.firebaseapp.com',
		databaseURL: 'https://roommate-d6e73.firebaseio.com',
		projectId: 'roommate-d6e73',
		storageBucket: 'roommate-d6e73.appspot.com',
		messagingSenderId: '677472613267',
		appId: '1:677472613267:web:aab5616d4b092d15'
	},

	chargebee: {
		site: 'the-roommate',
		publishableKey: 'live_55cMV6MNLUGcdnxKwsnjgcugQevD8sZWUf'
	},

	maps: {
		key: 'AIzaSyAEFDycZlfSp2fsbtcraGc8czOPOP9XpaE'
	}
};
