import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, filter, switchMap, map } from 'rxjs/operators';
import { LandlordData } from 'src/app/models/landlord';
import { LandlordService } from '../services/landlord/landlord.service';
import { BillingService } from 'src/app/billing/billing.service';

@Injectable()
export class LandlordDataReadyGuard implements CanLoad, CanActivateChild, CanActivate {
	constructor(private readonly landlordService: LandlordService, private readonly actions$: Actions) {}

	canActivateChild() {
		return this.canLoad();
	}

	canActivate() {
		return this.canLoad();
	}

	canLoad(): Observable<boolean> {
		return this.loadLandlordGuard().pipe(
			switchMap(() => of(true)),
			catchError(() => of(false))
		);
	}

	loadLandlordGuard(): Observable<LandlordData> {
		return this.landlordService.getLandlordId().pipe(
			filter(landlordId => !!landlordId),
			switchMap(() => this.landlordService.getLandlordData()),
			filter(landlordData => !!landlordData),
			filter(landlord => !!landlord.id)
		);
	}
}
