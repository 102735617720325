import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { WalletPageModelStore } from 'src/app/models/mangopay.model';
import { loadWalletSettingsPaginatedSuccessAction } from './wallet.actions';

export interface WalletPagesState extends EntityState<WalletPageModelStore> {
	isLoaded: boolean;
}
export const walletPagesAdapter = createEntityAdapter<WalletPageModelStore>({
	selectId: model => model.id
});
const initialWalletPagesState = walletPagesAdapter.getInitialState({
	isLoaded: false
});

export const WalletPagesReducers = createReducer(
	initialWalletPagesState,

	// Load pages
	// Load pages

	on(loadWalletSettingsPaginatedSuccessAction, (state, payload) => {
		if (payload.mangoPaymentsQueryResult) {
			return walletPagesAdapter.upsertOne(
				{
					id: payload.page,
					mangoPaymentIds: payload.mangoPaymentsQueryResult.data.map(mangoPayment => mangoPayment.PaymentId),
					totalItems: payload.mangoPaymentsQueryResult.metadata.totalItems || 0,
					filteredItems: payload.mangoPaymentsQueryResult.metadata.filteredItems || 0
				},
				{ ...state, isLoaded: true }
			);
		} else {
			return state;
		}
	})
);
