import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Lease } from 'src/app/models/lease.model';
import { deleteTenantSuccessAction } from 'src/app/tenants/state/tenant.actions';

import { addMockMaintenanceSuccessAction } from 'src/app/maintenances/state/maintenances.actions';
import { deletePropertySuccessAction } from 'src/app/properties/state/properties.actions';
import {
	addLeaseSignatureSuccessAction,
	addMockLeasesSuccessAction,
	createLeaseSuccessAction,
	loadLeaseByIdSuccessAction,
	loadLeasesAction,
	loadLeasesSuccessAction,
	removeLeaseSuccessAction,
	resetLeaseStateSuccessAction,
	updateLeaseDepositSuccessAction,
	updateLeaseSuccessAction
} from './lease.actions';
import { setLoadingStateOfGeneric, setSuccessStateOfGeneric } from 'src/app/utils/store-utils';

export interface LeasesState extends EntityState<Lease> {
	isLoaded: boolean;
}
export const leaseAdapter = createEntityAdapter<Lease>();

const initialLeaseState = leaseAdapter.getInitialState({
	isLoaded: false,
	isLoading: false,
	error: null
});

export const LeaseReducers = createReducer(
	initialLeaseState,
	on(resetLeaseStateSuccessAction, (state, payload) => (state = initialLeaseState)),
	on(loadLeasesAction, state => setLoadingStateOfGeneric(state)),
	on(loadLeasesSuccessAction, (state, payload) =>
		setSuccessStateOfGeneric(state, leaseAdapter, payload.leases, payload['refreshAll'])
	),
	on(updateLeaseDepositSuccessAction, (state, payload) =>
		leaseAdapter.updateOne({ id: payload.lease.id, changes: payload.lease }, { ...state, isLoaded: true })
	),
	on(createLeaseSuccessAction, (state, payload) => {
		return leaseAdapter.addOne(payload.lease, { ...state, isLoaded: true });
	}),
	on(removeLeaseSuccessAction, (state, payload) =>
		leaseAdapter.removeOne(payload.deletedLeaseId, { ...state, isLoaded: true })
	),
	on(deleteTenantSuccessAction, deletePropertySuccessAction, (state, payload) => {
		if (payload.deletedLeaseIds) {
			return leaseAdapter.removeMany(payload.deletedLeaseIds, { ...state, isLoaded: true });
		} else {
			return state;
		}
	}),
	on(updateLeaseSuccessAction, (state, payload) =>
		leaseAdapter.upsertOne(payload.lease, { ...state, isLoaded: true })
	),
	on(loadLeaseByIdSuccessAction, (state, payload) =>
		leaseAdapter.upsertOne(payload.lease, { ...state, isLoaded: true })
	),
	on(addLeaseSignatureSuccessAction, (state, payload) =>
		leaseAdapter.updateOne(
			{ id: payload.leaseId, changes: { signatureDetails: payload.signatureDetails } },
			{ ...state, isLoaded: true }
		)
	),

	on(addMockLeasesSuccessAction, addMockMaintenanceSuccessAction, (state, payload) => {
		return leaseAdapter.addMany(
			payload.leases.map(it => it.lease),
			{ ...state, isLoaded: true }
		);
	})
);
