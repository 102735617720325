<div class="subscription-container">
	<div class="subscription-header">
		<span class="subscription-header-text" i18n="@@subscription_level_up">Level Up!</span>
		<img
			*ngIf="data?.origin !== 'invalid_plan'"
			(click)="closeClick()"
			src="./assets/img/subscription_close_button.svg"
			alt="Close Button"
			style="width: 16px; height: 16px; cursor: pointer"
		/>
	</div>
	<div *ngIf="data?.origin !== 'invalid_plan'" class="subscription-description">
		<span class="subscription-description-text" i18n="@@subscription_amount-per-month">3.5€/unit/month</span>
		<span i18n="@@subscription_amount_desc">Pay only for active units in system</span>
	</div>
	<div *ngIf="data?.origin === 'invalid_plan'" class="subscription-description-invalid-plan">
		<span class="subscription-description-text" i18n="@@subscription_invalid_plan_desc">
			You have reached the maximum number of units in your subscription plan, update your plan to add more. In the
			new plan you will pay 3.5€/unit/month only for active units in the system.
		</span>
	</div>
	<div class="subscription-button-container">
		<div (click)="triggerSubscriptionButton()" class="subscription-button">
			<span class="subscription-button-header" i18n="@@subscription_button_text">Subscribe</span>
		</div>
	</div>
	<div class="subscription-features">
		<span class="subscription-features-header" i18n="@@subscription_plan_features"
			>Subscribing to this plan, you can get:</span
		>
		<div class="subscription-feature-row">
			<img src="./assets/img/subscription_check.svg" alt="Green check image" />
			<span class="subscription-feature-text" i18n="@@subscription_plan_features_1"
				>Unlimited access to all features</span
			>
		</div>
		<div class="subscription-feature-row">
			<img src="./assets/img/subscription_check.svg" alt="Green check image" />
			<span class="subscription-feature-text" i18n="@@subscription_plan_features_2"
				>Management of the master data of multiple owners</span
			>
		</div>
		<div class="subscription-feature-row">
			<img src="./assets/img/subscription_check.svg" alt="Green check image" />
			<span class="subscription-feature-text" i18n="@@subscription_plan_features_3"
				>Management of digital payments</span
			>
		</div>
		<div class="subscription-feature-row">
			<img src="./assets/img/subscription_check.svg" alt="Green check image" />
			<span class="subscription-feature-text" i18n="@@subscription_plan_features_4">Tenants app included</span>
		</div>
	</div>
	<div class="subscription-book-demo">
		<span class="subscription-book-demo-text" i18n="@@subscription_book_demo_text"
			>If you have more than 150 units contact us at this
			<a class="subscription-book-demo-link" href="https://calendly.com/roommate-estelle/ryn-demo" target="_blank"
				>link</a
			></span
		>
	</div>
	<div
		*ngIf="data?.origin === 'invalid_plan'"
		fxLayout="row"
		fxLayoutAlign="center flex-start"
		style="margin-top: 20px"
	>
		<p
			class="regular-12 no-margin color-grey rf-is-clickable rf-is-underlined"
			style="font-style: italic"
			(click)="logout()"
			i18n="@@com_or_click_here_to_logout"
		>
			Or click here to logout
		</p>
	</div>
</div>
