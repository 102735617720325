import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { MinimumUserData } from 'src/app/models/common';
import { LandlordData } from 'src/app/models/landlord';
import { LandlordReport } from 'src/app/models/report';
import { updateOnboardingDataSuccessAction } from '../../user/state/user.actions';
import {
	landlordSignOutSuccessAction,
	loadLandlordDataSuccessAction,
	loadReportsSuccessAction,
	requestNewPasswordSuccessAction,
	setLandlordTokenSuccessAction,
	updateLandlordDataSuccessAction
} from './landlord.actions';

interface LandlordReportsState extends EntityState<LandlordReport> {}
export interface LandlordState {
	data: LandlordData;
	userData?: MinimumUserData;
	reports: LandlordReportsState;
}

export const landlordReportsAdapter = createEntityAdapter<LandlordReport>();
const landlordReportsInitialState = landlordReportsAdapter.getInitialState([]);

const landlorStateInitialState: LandlordState = {
	data: {} as LandlordData,
	userData: undefined,
	reports: landlordReportsInitialState
};

export const LandlordReducers = createReducer(
	landlorStateInitialState,
	on(landlordSignOutSuccessAction, (state, payload) => (state = landlorStateInitialState)),
	on(
		loadReportsSuccessAction,
		(state, action) =>
			(state = { ...state, reports: landlordReportsAdapter.upsertMany(action.reports, state.reports) })
	),
	on(
		loadLandlordDataSuccessAction,
		(state, action) =>
			(state = { ...state, data: action.landlord, userData: { ...state.userData, ...action.user } })
	),
	on(
		updateOnboardingDataSuccessAction,
		(state, action) => (state = { ...state, userData: { ...state.userData, ...action.user } })
	),
	on(updateLandlordDataSuccessAction, (state, action) => (state = { ...state, data: action.landlord })),
	on(setLandlordTokenSuccessAction, (state, action) => (state = { ...state })), // Do Nothing
	// on(
	// 	askForReportGenerationSuccess,
	// 	(state, action) =>
	// 		(state = { ...state, reports: landlordReportsAdapter.upsertMany(action.reports, state.reports) })
	// ),
	on(requestNewPasswordSuccessAction, (state, action) => (state = { ...state })) // Do nothing
);
