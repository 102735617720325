import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { EffectInteractionService } from 'src/app/core/services/effectInteraction.service';
import { LandlordService } from 'src/app/core/services/landlord/landlord.service';
import { ChannelConnection } from 'src/app/models/channelManager.model';
import { QueryResult } from 'src/app/models/common';
import { LocalizationUtils } from 'src/app/utils/localization-utils';
import { environment } from 'src/environments/environment';
import {
	loadChannelManagerConnectionsAction,
	loadChannelManagerConnectionsFailureAction,
	loadChannelManagerConnectionsSuccessAction,
	updateChannelManagerConnectionAction,
	updateChannelManagerConnectionActionFailure,
	updateChannelManagerConnectionActionSuccess
} from './connections.actions';

@Injectable()
export class ChannelManagerConnectionsEffects {
	translations = LocalizationUtils.getTranslations();
	private landlordId;
	private BACKEND_HOST = `${environment.services.backend}/api-dash/v1`;
	private CM_HOST = `${environment.services.channelmanager}`;

	constructor(
		private readonly httpClient: HttpClient,
		private readonly actions$: Actions,
		private readonly landlordService: LandlordService,
		private readonly effectInteractionService: EffectInteractionService
	) {
		this.landlordService.getLandlordId().subscribe(id => (this.landlordId = id));
	}

	public loadChannelManagerConnectionsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadChannelManagerConnectionsAction),
			switchMap(action => {
				return this.loadChannelManagerConnections().pipe(
					map(connections => {
						this.effectInteractionService.callbackSuccess(action);
						return loadChannelManagerConnectionsSuccessAction({ connections: connections.data });
					}),
					catchError(error => {
						console.log(error.message);
						this.effectInteractionService.callbackFail(action);
						return of(loadChannelManagerConnectionsFailureAction({ error: error }));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				);
			})
		)
	);

	public updateChannelManagerConnectionEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(updateChannelManagerConnectionAction),
			switchMap(action => {
				return this.updateChannelManagerConnection(action.connection).pipe(
					map(connection => {
						this.effectInteractionService.callbackSuccess(action);
						return updateChannelManagerConnectionActionSuccess({ connection: connection });
					}),
					catchError(error => {
						console.log(error.message);
						this.effectInteractionService.callbackFail(action);
						return of(updateChannelManagerConnectionActionFailure({ error: error }));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				);
			})
		)
	);

	/***  ***/

	// Private calls

	private updateChannelManagerConnection(connection: Partial<ChannelConnection>): Observable<ChannelConnection> {
		return this.httpClient.put<ChannelConnection>(
			`${this.CM_HOST}/landlords/${this.landlordId}/connections/${connection.id}`,
			connection
		);
	}

	private loadChannelManagerConnections(): Observable<QueryResult<ChannelConnection>> {
		return this.httpClient.get<QueryResult<ChannelConnection>>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/channelManager/connections`
		);
	}
}
