import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { endOfDay, startOfDay } from 'date-fns';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { EffectInteractionService } from 'src/app/core/services/effectInteraction.service';
import {
	CustomFilterType,
	DateCustomFilterType,
	FilterContainer,
	PaymentFilterKeys,
	PaymentOptions,
	PaymentQueryFilters,
	SortEvent,
	SortQuery
} from 'src/app/core/services/filters/filters.model';
import { LandlordService } from 'src/app/core/services/landlord/landlord.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { MixpanelService } from 'src/app/core/services/mixpanel-service.service';
import { QueryResult } from 'src/app/models/common';
import { PaymentOperation, PropertyPayment } from 'src/app/models/payments';
import { SettingsPayment } from 'src/app/settings/settings-payment/settings-payment.component';
import { resetStoreAction } from 'src/app/store/actions';
import { AppUtils } from 'src/app/utils/app-utils';
import { environment } from 'src/environments/environment';
import {
	createPaymentAction,
	createPaymentFailureAction,
	createPaymentOperationAction,
	createPaymentOperationFailureAction,
	createPaymentOperationSuccessAction,
	createPaymentSuccessAction,
	deletePaymentAction,
	deletePaymentFailureAction,
	deletePaymentOperationAction,
	deletePaymentOperationFailureAction,
	deletePaymentOperationSuccessAction,
	deletePaymentsAction,
	deletePaymentsFailureAction,
	deletePaymentsSuccessAction,
	deletePaymentSuccessAction,
	exportPaymentsCsvAction,
	exportPaymentsCsvFailureAction,
	exportPaymentsCsvSuccessAction,
	exportPaymentsPdfAction,
	exportPaymentsPdfActionFailureAction,
	exportPaymentsPdfActionSuccessAction,
	getMarginsAction,
	getMarginsFailureAction,
	getMarginsSuccessAction,
	loadOpenPaymentsByTenantAction,
	loadOpenPaymentsByTenantFailureAction,
	loadOpenPaymentsByTenantSuccessAction,
	loadPaymentByIdAction,
	loadPaymentByIdFailureAction,
	loadPaymentByIdSuccessAction,
	loadPaymentsByDateAndPropertiesAction,
	loadPaymentsByDateAndPropertiesFailureAction,
	loadPaymentsByDateAndPropertiesSuccessAction,
	loadPaymentsByIdsAction,
	loadPaymentsByIdsFailureAction,
	loadPaymentsByIdsSuccessAction,
	loadPaymentsByOwnerAction,
	loadPaymentsByOwnerFailureAction,
	loadPaymentsByOwnerPlusConnectedRentsAction,
	loadPaymentsByOwnerPlusConnectedRentsFailureAction,
	loadPaymentsByOwnerPlusConnectedRentsSuccessAction,
	loadPaymentsByOwnerSuccessAction,
	loadPaymentsByPropertyAction,
	loadPaymentsByPropertyFailureAction,
	loadPaymentsByPropertySuccessAction,
	loadPaymentSettingsAction,
	loadPaymentSettingsFailureAction,
	loadPaymentSettingsSuccessAction,
	loadPaymentsPaginatedAction,
	loadPaymentsPaginatedFailureAction,
	loadPaymentsPaginatedSuccessAction,
	lockPaymentsAction,
	lockPaymentsFailureAction,
	lockPaymentsSuccessAction,
	resetPaymentDatabaseViewAction,
	resetPaymentDatabaseViewFailureAction,
	resetPaymentDatabaseViewSuccessAction,
	resetPaymentsStateAction,
	resetPaymentsStateFailureAction,
	resetPaymentsStateSuccessAction,
	updatePaymentAction,
	updatePaymentFailureAction,
	updatePaymentSettingAction,
	UpdatePaymentSettingFailureAction,
	updatePaymentSettingSuccessAction,
	updatePaymentSuccessAction
} from './payments.actions';

@Injectable()
export class PaymentsEffects {
	private landlordId: string;
	private BACKEND_HOST = `${environment.services.backend}/api-dash/v1`;

	constructor(
		private readonly analyticsService: MixpanelService,
		private readonly landlordService: LandlordService,
		private readonly httpClient: HttpClient,
		private readonly afs: AngularFirestore,
		private readonly actions$: Actions,
		private readonly loadingService: LoadingService,
		private readonly effectInteractionService: EffectInteractionService
	) {
		this.landlordService.getLandlordId().subscribe(id => (this.landlordId = id));
	}

	public resetStoreState = createEffect(() =>
		this.actions$.pipe(
			ofType(resetStoreAction),
			switchMap(() => {
				return of(resetPaymentsStateAction());
			})
		)
	);

	public resetPaymentsStateEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(resetPaymentsStateAction),
			switchMap(() => {
				return of(resetPaymentsStateSuccessAction());
			}),
			catchError(() => of(resetPaymentsStateFailureAction({})))
		)
	);

	public loadPaymentsByDateAndPropertiesEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadPaymentsByDateAndPropertiesAction),
			mergeMap(action =>
				this.loadPaymentsByDateAndPropertiesForOwnerDetailsPage(
					action.dateFrom,
					action.dateTo,
					action.propertyIds
				).pipe(
					map(payments => {
						this.effectInteractionService.callbackSuccess(action, payments.data);
						return loadPaymentsByDateAndPropertiesSuccessAction({
							paymentsQueryResult: payments
						});
					}),
					catchError(err => {
						this.effectInteractionService.callbackFail(action);
						return of(loadPaymentsByDateAndPropertiesFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public loadOpenPaymentsByTenantEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadOpenPaymentsByTenantAction),
			mergeMap(action =>
				this.loadOpenPaymentsByTenant(action.tenantId).pipe(
					map(payments => {
						this.effectInteractionService.callbackSuccess(action, payments.data);
						return loadOpenPaymentsByTenantSuccessAction({
							paymentsQueryResult: payments
						});
					}),
					catchError(err => {
						this.effectInteractionService.callbackFail(action);
						return of(loadOpenPaymentsByTenantFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public loadPaymentsByPropertyEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadPaymentsByPropertyAction),
			mergeMap(action =>
				this.loadPaymentsByProperty(action.filters).pipe(
					map(payments => {
						this.effectInteractionService.callbackSuccess(action, payments.data);
						return loadPaymentsByPropertySuccessAction({
							paymentsQueryResult: payments
						});
					}),
					catchError(err => {
						this.effectInteractionService.callbackFail(action);
						return of(loadPaymentsByPropertyFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public loadPaymentsByOwnerEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadPaymentsByOwnerAction),
			mergeMap(action =>
				this.loadPaymentsByOwner(action.ownerId).pipe(
					map(payments => {
						this.effectInteractionService.callbackSuccess(action, payments.data);
						return loadPaymentsByOwnerSuccessAction({
							paymentsQueryResult: payments
						});
					}),
					catchError(err => {
						this.effectInteractionService.callbackFail(action);
						return of(loadPaymentsByOwnerFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public loadPaymentsByOwnerPlusConnectedRentsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadPaymentsByOwnerPlusConnectedRentsAction),
			mergeMap(action =>
				this.loadPaymentsByOwnerPlusConnectedRents(action.ownerId).pipe(
					map(payments => {
						this.effectInteractionService.callbackSuccess(action, payments);
						return loadPaymentsByOwnerPlusConnectedRentsSuccessAction({
							paymentsQueryResult: {
								data: payments,
								metadata: {
									filteredItems: payments.length,
									totalItems: payments.length
								}
							}
						});
					}),
					catchError(err => {
						this.effectInteractionService.callbackFail(action);
						return of(loadPaymentsByOwnerPlusConnectedRentsFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public loadPaymentByIdEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadPaymentByIdAction),
			mergeMap(action =>
				this.loadPaymentById(action.paymentId).pipe(
					map(payment => loadPaymentByIdSuccessAction({ payment })),
					catchError(err => of(loadPaymentByIdFailureAction({})))
				)
			)
		)
	);

	public lockPaymentsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(lockPaymentsAction),
			mergeMap(action =>
				this.lockPayments(action.paymentsId, action.lockedBy, action.lockerType).pipe(
					map(result => {
						this.effectInteractionService.callbackSuccess(action);
						return lockPaymentsSuccessAction({ payments: result });
					}),
					catchError(() => {
						this.effectInteractionService.callbackFail(action);
						return of(lockPaymentsFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public loadPaymentSettingsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadPaymentSettingsAction),
			mergeMap(action =>
				this.loadPaymentSettings().pipe(
					map(
						settings => loadPaymentSettingsSuccessAction({ settings }),
						catchError(err => of(loadPaymentSettingsFailureAction({})))
					)
				)
			)
		)
	);

	public resetPaymentDatabaseViewEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(resetPaymentDatabaseViewAction),
			mergeMap(action =>
				this.resetPaymentDatabaseView().pipe(
					map(() => resetPaymentDatabaseViewSuccessAction()),
					catchError(err => of(resetPaymentDatabaseViewFailureAction({})))
				)
			)
		)
	);
	public createPaymentEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(createPaymentAction),
			mergeMap(action =>
				this.createPayment(action.payment, action.isMaintenanceClosingPayment).pipe(
					map(payment => {
						this.effectInteractionService.callbackSuccess(action, payment);
						return createPaymentSuccessAction({ payment });
					}),
					catchError(() => {
						this.effectInteractionService.callbackFail(action);
						return of(createPaymentFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);
	public createPaymentOperationEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(createPaymentOperationAction),
			mergeMap(action =>
				this.createPaymentOperation(action.paymentOperation, action.isMaintenanceClosingPayment).pipe(
					map(result => createPaymentOperationSuccessAction({ ...result })),
					catchError(err => of(createPaymentOperationFailureAction))
				)
			)
		)
	);

	public createOrUpdatePaymentSettingEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(updatePaymentSettingAction),
			mergeMap(action =>
				this.createOrUpdatePaymentRegistration(action.paymentRegistration).pipe(
					map(() => {
						return updatePaymentSettingSuccessAction({ setting: action.paymentRegistration });
					}),
					catchError(() => of(UpdatePaymentSettingFailureAction({})))
				)
			)
		)
	);

	public updatePaymentEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(updatePaymentAction),
			mergeMap(action =>
				this.updatePayment(action.payment).pipe(
					map(payment => {
						this.effectInteractionService.callbackSuccess(action, payment);
						return updatePaymentSuccessAction({ payment });
					}),
					catchError(() => {
						this.effectInteractionService.callbackFail(action);
						return of(updatePaymentFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public deletePaymentEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(deletePaymentAction),
			mergeMap(action =>
				this.deletePayment(action.paymentId).pipe(
					map(() => {
						this.effectInteractionService.callbackSuccess(action);
						return deletePaymentSuccessAction({ paymentId: action.paymentId });
					}),
					catchError(() => {
						this.effectInteractionService.callbackFail(action);
						return of(deletePaymentFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public loadPaymentsByIdsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadPaymentsByIdsAction),
			switchMap(action =>
				this.loadPaymentsByIds(action.paymentIds).pipe(
					map(payments => {
						this.effectInteractionService.callbackSuccess(action, payments.data);
						return loadPaymentsByIdsSuccessAction();
					}),
					catchError(() => {
						this.effectInteractionService.callbackFail(action);
						return of(loadPaymentsByIdsFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public deletePaymentsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(deletePaymentsAction),
			mergeMap(action =>
				this.deletePayments(action.payments, action.isAllSelected).pipe(
					map(paymentIds => {
						this.effectInteractionService.callbackSuccess(action);
						return deletePaymentsSuccessAction({ deletedPaymentIds: paymentIds });
					}),
					catchError(() => {
						this.effectInteractionService.callbackFail(action);
						return of(deletePaymentsFailureAction({}));
					}),
					tap(() => {
						this.effectInteractionService.callbackFinally(action);
					})
				)
			)
		)
	);

	public deletePaymentOperationEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(deletePaymentOperationAction),
			mergeMap(action =>
				this.deletePaymentOperation(action.paymentOperation.id).pipe(
					map(result =>
						deletePaymentOperationSuccessAction({
							paymentOperation: action.paymentOperation,
							payment: result.payment
						})
					),
					catchError(err => of(deletePaymentOperationFailureAction({})))
				)
			)
		)
	);

	public loadPaymentsPaginatedEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadPaymentsPaginatedAction),
			switchMap(action =>
				this.loadPaymentsPaginated(
					action.page,
					action.pageSize,
					action.filters,
					action.sort,
					action.entityType,
					action.entityId
				).pipe(
					map(payments => {
						return loadPaymentsPaginatedSuccessAction({
							page: action.page,
							pageSize: action.pageSize,
							paymentsQueryResult: payments
						});
					}),
					catchError(err => of(loadPaymentsPaginatedFailureAction({ error: err })))
				)
			)
		)
	);

	public exportCsvPaymentsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(exportPaymentsCsvAction),
			switchMap(action =>
				this.prepareCsvExport(action.filters, action.sort, action.entityType, action.entityId).pipe(
					map(() => {
						this.loadingService.hide();
						return exportPaymentsCsvSuccessAction();
					}),
					catchError(err => {
						this.loadingService.hideAndDisplayErrorToast();
						return of(exportPaymentsCsvFailureAction({ error: err }));
					})
				)
			)
		)
	);

	public exportPdfPaymentsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(exportPaymentsPdfAction),
			switchMap(action =>
				this.preparePdfExport(action.filters, action.sort, action.entityType, action.entityId).pipe(
					map(() => {
						this.loadingService.hide();
						return exportPaymentsPdfActionSuccessAction();
					}),
					catchError(err => {
						this.loadingService.hideAndDisplayErrorToast();
						return of(exportPaymentsPdfActionFailureAction({ error: err }));
					})
				)
			)
		)
	);

	public getMarginsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(getMarginsAction),
			switchMap(action =>
				this.getMargins(action.propertyId, action.ownerId).pipe(
					map(data => {
						this.loadingService.hide();
						return getMarginsSuccessAction({ marginsData: data });
					}),
					catchError(err => {
						this.loadingService.hideAndDisplayErrorToast();
						return of(getMarginsFailureAction({ error: err }));
					})
				)
			)
		)
	);
	/*** Effects handle success and failure actions  ***/

	public createPaymentFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(createPaymentFailureAction),
				tap(() => {
					this.effectInteractionService.showDialogError(
						$localize`:@@create_payment_failed:Something went wrong during the creation of the payment. Try again and if the problem persists, contact us.`
					);
				})
			),
		{ dispatch: false }
	);

	public updatePaymentFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(updatePaymentFailureAction),
				tap(() => {
					this.effectInteractionService.showDialogError(
						$localize`:@@update_payment_failed:Something went wrong during the update of the payment. Try again and if the problem persists, contact us.`
					);
				})
			),
		{ dispatch: false }
	);

	public createPaymentSuccessActionEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(createPaymentSuccessAction),
				tap(action => {
					this.analyticsService.track('expense_add', {
						category: action.payment.category,
						type: 'property',
						amount: action.payment.amount,
						item_count: action.payment.extraFees,
						already_paid: false,
						documents_count: (action.payment.attachments || []).length,
						people_count: 0,
						currency: action.payment.currency
					});
				})
			),
		{ dispatch: false }
	);

	public genericPaymentFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(createPaymentOperationFailureAction, UpdatePaymentSettingFailureAction),
				tap(() => this.loadingService.hideAndDisplayErrorToast())
			),
		{ dispatch: false }
	);

	public silentPaymentFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(
					resetPaymentsStateFailureAction,
					loadPaymentsByDateAndPropertiesFailureAction,
					loadOpenPaymentsByTenantFailureAction,
					loadPaymentsByPropertyFailureAction,
					loadPaymentsByOwnerFailureAction,
					loadPaymentsByOwnerPlusConnectedRentsFailureAction,
					loadPaymentByIdFailureAction,
					lockPaymentsFailureAction,
					loadPaymentSettingsFailureAction,
					resetPaymentDatabaseViewFailureAction,
					deletePaymentFailureAction,
					deletePaymentOperationFailureAction,
					loadPaymentsPaginatedFailureAction,
					exportPaymentsCsvFailureAction
				),
				tap(action => this.effectInteractionService.logErrorSilently(action.type))
			),
		{ dispatch: false }
	);

	/***   ***/

	// *******	[START]	Payments Table Paginated		*******
	private loadPaymentsPaginated(
		page: number,
		pageSize: number,
		filters: FilterContainer,
		sort: SortEvent,
		entityType?: string,
		entityId?: string
	): Observable<QueryResult<PropertyPayment>> {
		const paymentOptions = this.fromCurrentFiltersToPaymentQuery(
			page,
			pageSize,
			filters,
			sort,
			entityType,
			entityId
		);

		return this.getPayments(paymentOptions);
	}

	private prepareCsvExport(
		filters: FilterContainer,
		sort: SortEvent,
		entityType?: string,
		entityId?: string
	): Observable<any> {
		const paymentOptions = this.fromCurrentFiltersToPaymentQuery(0, 20000, filters, sort, entityType, entityId);
		return this.downloadCsvExport(paymentOptions);
	}

	private preparePdfExport(
		filters: FilterContainer,
		sort: SortEvent,
		entityType?: string,
		entityId?: string
	): Observable<any> {
		const paymentOptions = this.fromCurrentFiltersToPaymentQuery(0, 20000, filters, sort, entityType, entityId);
		return this.downloadPdfExport(paymentOptions);
	}

	private getMargins(propertyId?: string, ownerId?: string): Observable<any> {
		let params = {
			propertyId: propertyId,
			ownerId: ownerId
		};

		return this.httpClient.get<any>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/payments/getMarginsData`, {
			params
		});
	}
	private fromCurrentFiltersToPaymentQuery(
		page: number,
		pageSize: number,
		filters: FilterContainer,
		sort: SortEvent,
		entityType?: string,
		entityId?: string
	): PaymentOptions {
		let paymentOptions: PaymentOptions = {
			page,
			items: pageSize
		};

		const { propertyId, tenantId } = this.setTypePaymentTable(entityType, entityId);
		const { dateFrom, dateTo } = this.setDateOptions(filters);
		const { category, invoice, type, propertyOwnerId } = this.setCustomFilterOption(filters);

		paymentOptions = {
			...paymentOptions,
			filters: {
				...paymentOptions.filters,
				propertyId,
				tenantId,
				propertyOwnerId,
				dateFrom,
				dateTo,
				invoice,
				type,
				category,
				status: this.setStatus(filters.selectedTabId.toLowerCase()),
				textSearch: filters.searchedValue
			},
			sort: {
				sortType: sort.type,
				sortDirection: sort.direction
			}
		};

		return paymentOptions;
	}

	private setTypePaymentTable(entityType?: string, entityId?: string): { propertyId?: string; tenantId?: string } {
		if (entityType === 'properties') {
			return { propertyId: entityId };
		}
		if (entityType === 'tenants') {
			return { tenantId: entityId };
		}
		return {};
	}

	private setDateOptions(filters: FilterContainer): { dateFrom: number; dateTo: number } {
		const isFilteredByDate =
			filters.appliedFilters.filter(filterValue => filterValue.type === CustomFilterType.DATE).length > 0;
		const today = new Date();
		let dateFrom;
		let dateTo;
		if (isFilteredByDate) {
			const customFilter = filters.appliedFilters.filter(
				filterValue => filterValue.type === CustomFilterType.DATE
			)[0];
			dateTo = today.getTime();
			if (customFilter.selectedValue.value !== DateCustomFilterType.CUSTOM) {
				dateFrom = AppUtils.getDateFromFilterOption(today, customFilter.selectedValue.value).getTime();
			} else {
				const minValue = customFilter.selectedCustomDate.from || dateFrom; // Avoid having undefined as a date
				const maxValue = customFilter.selectedCustomDate.to || dateTo; // Allow the user to ignore the end date
				dateFrom = startOfDay(new Date(minValue)).getTime();
				dateTo = endOfDay(new Date(maxValue)).getTime();
			}
		}

		return { dateFrom, dateTo };
	}

	private setCustomFilterOption(filters: FilterContainer): {
		category?: string[];
		invoice?: boolean;
		type?: any;
		propertyOwnerId?: string;
	} {
		let category, invoice, type, propertyOwnerId;

		filters.appliedFilters.forEach(filterToApply => {
			switch (filterToApply.filterId.toLocaleLowerCase()) {
				case PaymentFilterKeys.CATEGORY:
					category = [filterToApply.selectedValue.value];
					break;
				case PaymentFilterKeys.INVOICE:
					invoice = filterToApply.selectedValue.value;
					break;
				case PaymentFilterKeys.TYPE:
					type = filterToApply.selectedValue.value;
					break;
				case PaymentFilterKeys.OWNER:
					propertyOwnerId = filterToApply.selectedValue.value;
					break;
			}
		});

		return { category, invoice, type, propertyOwnerId };
	}

	private setStatus(filterTab: string) {
		if (filterTab === 'paid') {
			return 'close';
		} else if (filterTab === 'open') {
			return 'open';
		} else if (filterTab === 'upcoming') {
			return 'upcoming';
		} else if (filterTab === 'overdue') {
			return 'overdue';
		} else if (filterTab === 'forecasted') {
			return 'forecasted';
		} else if (filterTab === 'pending') {
			return 'pending';
		}

		return;
	}

	// *******	[END]	Payments Table Paginated		*******

	public loadPaymentById(paymentId: string): Observable<PropertyPayment> {
		return this.httpClient.get<PropertyPayment>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/payments/${paymentId}`
		);
	}

	private resetPaymentDatabaseView(): Observable<any> {
		return this.httpClient.get<any>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/payments/createViews`);
	}

	private createPaymentOperation(
		paymentOperation: PaymentOperation,
		isMaintenanceClosingPayment?: boolean
	): Observable<{ payment: PropertyPayment; paymentOperation: PaymentOperation }> {
		let params = { paymentOperation };
		if (isMaintenanceClosingPayment) {
			params['isMaintenanceClosingPayment'] = true;
		}

		return this.httpClient.put<{ payment: PropertyPayment; paymentOperation: PaymentOperation }>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/paymentOperations`,
			params
		);
	}

	private loadPaymentSettings(): Observable<SettingsPayment> {
		const emtpySettingsPayment: SettingsPayment = {
			status: null,
			entity: null,
			files: [],
			errorMessage: ''
		};

		return this.afs
			.doc<SettingsPayment>(`landlords/${this.landlordId}/data/paymentRegistration`)
			.valueChanges()
			.pipe(
				take(1),
				map(
					res => {
						return res || emtpySettingsPayment;
					},
					catchError(error => of(emtpySettingsPayment))
				)
			);
	}

	private createOrUpdatePaymentRegistration(paymentRegistration: SettingsPayment): Observable<void> {
		return from(
			this.afs.doc(`landlords/${this.landlordId}/data/paymentRegistration`).set(
				{
					entity: paymentRegistration.entity,
					files: JSON.parse(JSON.stringify(paymentRegistration.files)),
					status: paymentRegistration.status
				},
				{ merge: true }
			)
		);
	}

	private loadPaymentsByOwner(ownerId: string): Observable<QueryResult<PropertyPayment>> {
		let paymentOptions: PaymentOptions = this.setPaymentOptionsNotPaginated(
			{
				ownerId
			},
			{
				sortType: 'dueDate',
				sortDirection: -1
			}
		);

		return this.getPayments(paymentOptions);
	}

	private deletePayment(paymentId: string): Observable<void> {
		return this.httpClient.delete<void>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/payments/${paymentId}`);
	}

	private deletePayments(payments: PropertyPayment[], isAllSelected: boolean): Observable<string[]> {
		const paymentIds = payments.map(payment => payment.id);

		const bulkDeletePayments = {
			isAllSelected: isAllSelected,
			selectedEntities: isAllSelected ? [] : paymentIds,
			deselectedEntities: isAllSelected ? paymentIds : []
		};

		return this.httpClient.delete<string[]>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/payments/`, {
			body: {
				bulkDeletePayments: bulkDeletePayments
			}
		});
	}

	private createPayment(
		payment: PropertyPayment,
		isMaintenanceClosingPayment?: boolean
	): Observable<PropertyPayment> {
		let params = { payment };
		if (isMaintenanceClosingPayment) {
			params['isMaintenanceClosingPayment'] = true;
		}

		return this.httpClient.put<PropertyPayment>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/payments/`,
			params
		);
	}

	private updatePayment(payment: PropertyPayment): Observable<PropertyPayment> {
		return this.httpClient.put<PropertyPayment>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/payments/${payment.id}`,
			{ payment }
		);
	}

	private deletePaymentOperation(paymentOperationId: string): Observable<{ payment: PropertyPayment }> {
		return this.httpClient.delete<{ payment: PropertyPayment }>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/paymentOperations/${paymentOperationId}`
		);
	}

	private lockPayments(
		paymentsId: string[],
		lockedBy: string,
		lockerType: string = 'deposit'
	): Observable<PropertyPayment[]> {
		return this.httpClient.post<PropertyPayment[]>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/lockPayments/`,
			{
				paymentsId,
				lockedBy,
				lockerType
			}
		);
	}

	private loadPaymentsByIds(paymentIds: string[]): Observable<QueryResult<PropertyPayment>> {
		let paymentOptions: PaymentOptions = this.setPaymentOptionsNotPaginated({
			paymentIds
		});

		return this.getPayments(paymentOptions);
	}

	private loadPaymentsByDateAndPropertiesForOwnerDetailsPage(
		dateFrom: number,
		dateTo: number,
		propertyIds: string[]
	): Observable<QueryResult<PropertyPayment>> {
		let paymentOptions: PaymentOptions = this.setPaymentOptionsNotPaginated({
			dateFrom,
			dateTo,
			propertyIds,
			ownerPageQuery: true
		});

		return this.getPayments(paymentOptions);
	}

	private loadPaymentsByOwnerPlusConnectedRents(ownerId: string): Observable<PropertyPayment[]> {
		let paymentOptions: PaymentOptions = this.setPaymentOptionsNotPaginated(
			{
				ownerId
			},
			{
				sortType: 'dueDate',
				sortDirection: -1
			}
		);

		return this.getfilteredPaymentsForOwnerIncludingConnectedRents(paymentOptions);
	}

	private getfilteredPaymentsForOwnerIncludingConnectedRents(paymentOptions: PaymentOptions) {
		const params = this.setQueryParams(paymentOptions);

		const requestUrl = `${this.BACKEND_HOST}/landlords/${this.landlordId}/payments/filteredPaymentsForOwnerIncludingConnectedRents`;

		return this.httpClient.get<PropertyPayment[]>(requestUrl, { params });
	}

	private loadPaymentsByProperty(filters: PaymentQueryFilters): Observable<QueryResult<PropertyPayment>> {
		let paymentOptions: PaymentOptions = this.setPaymentOptionsNotPaginated(filters, {
			sortType: 'dueDate',
			sortDirection: -1
		});

		return this.getPayments(paymentOptions);
	}

	private loadOpenPaymentsByTenant(tenantId: string): Observable<QueryResult<PropertyPayment>> {
		let paymentOptions: PaymentOptions = this.setPaymentOptionsNotPaginated(
			{
				tenantId,
				status: 'open'
			},
			{
				sortType: 'dueDate',
				sortDirection: -1
			}
		);

		return this.getPayments(paymentOptions);
	}

	private setPaymentOptionsNotPaginated(filters: PaymentQueryFilters, sort?: SortQuery) {
		let paymentOptions: PaymentOptions = {
			page: 0,
			items: 20000,
			filters,
			sort
		};

		return paymentOptions;
	}

	private getPayments(paymentOptions: PaymentOptions) {
		const params = this.setQueryParams(paymentOptions);

		const requestUrl = `${this.BACKEND_HOST}/landlords/${this.landlordId}/payments`;

		return this.httpClient.get<QueryResult<PropertyPayment>>(requestUrl, { params });
	}

	public downloadCsvExport(paymentOptions: PaymentOptions): Observable<any> {
		this.loadingService.show(
			$localize`:@@payments_export_loading_text:Exporting payments. This may take some time, please wait...`
		);

		const params = this.setQueryParams(paymentOptions);

		const requestUrl = `${this.BACKEND_HOST}/landlords/${this.landlordId}/payments/exportCsv`;

		return this.httpClient
			.get(requestUrl, {
				params,
				responseType: 'blob',
				observe: 'response'
			})
			.pipe(
				map(it => {
					const response = it as HttpResponse<Blob>;
					const body = response.body;
					return AppUtils.saveFile(body, AppUtils.getFileNameFromResponseContentDisposition(response));
				})
			);
	}

	public downloadPdfExport(paymentOptions: PaymentOptions): Observable<any> {
		this.loadingService.show(
			$localize`:@@payments_export_loading_text:Exporting payments. This may take some time, please wait...`
		);

		const params = this.setQueryParams(paymentOptions);

		const requestUrl = `${this.BACKEND_HOST}/landlords/${this.landlordId}/payments/exportPdf`;

		return this.httpClient
			.get(requestUrl, {
				params,
				responseType: 'blob',
				observe: 'response'
			})
			.pipe(
				map(it => {
					const response = it as HttpResponse<Blob>;
					const body = response.body;
					return AppUtils.saveFile(body, AppUtils.getFileNameFromResponseContentDisposition(response));
				})
			);
	}

	private setQueryParams(paymentOptions: PaymentOptions): HttpParams {
		let params = new HttpParams();
		params = params.set('page', paymentOptions.page);
		params = params.set('items', paymentOptions.items);

		if (!!paymentOptions.filters) {
			Object.keys(paymentOptions.filters).forEach(key => {
				if (!(paymentOptions.filters[key] === undefined)) {
					params = params.set(key, paymentOptions.filters[key]);
				}
			});
		}

		if (!!paymentOptions.sort) {
			Object.keys(paymentOptions.sort).forEach(key => {
				if (!(paymentOptions.sort[key] === undefined)) {
					params = params.set(key, paymentOptions.sort[key]);
				}
			});
		}

		return params;
	}
}
