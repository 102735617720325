import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { addMockLeasesSuccessAction, createLeaseSuccessAction } from 'src/app/leases/state/lease.actions';
import { addMockMaintenanceSuccessAction } from 'src/app/maintenances/state/maintenances.actions';
import { Booking } from 'src/app/models/booking.model';
import {
	addBookingSuccessAction,
	deleteBookingSuccessAction,
	editBookingSuccessAction,
	loadBookingFailureAction,
	loadBookingsAction,
	loadBookingsSuccessAction,
	loadBookingSuccessAction,
	resetBookingsStateSuccessAction
} from './bookings.actions';

export interface EntityLoadable<T> extends EntityState<T> {
	isLoaded: boolean;
	isLoading: boolean;
	error: any | undefined;
}

function setSuccessStateOfGeneric<T>(
	state: EntityLoadable<T>,
	entityAdapter: EntityAdapter<T>,
	payload: T[]
): EntityLoadable<T> {
	return entityAdapter.upsertMany(payload, {
		...state,
		isLoaded: true,
		isLoading: false,
		error: undefined
	});
}

function setErrorStateOfGeneric<T>(
	state: EntityLoadable<T>,
	entityAdapter: EntityAdapter<T>,
	error: any
): EntityLoadable<T> {
	return entityAdapter.removeAll({
		...state,
		isLoaded: true,
		isLoading: false,
		error
	});
}

function setLoadingStateOfGeneric<T>(state: EntityLoadable<T>): EntityLoadable<T> {
	return {
		...state,
		isLoaded: false,
		isLoading: true
	};
}

export interface BookingState extends EntityLoadable<Booking> {}

export const bookingsAdapter = createEntityAdapter<Booking>();

const initialBookingRequestState = bookingsAdapter.getInitialState({
	isLoaded: false,
	isLoading: false,
	error: null
});

export const BookingsReducers = createReducer(
	initialBookingRequestState,
	on(resetBookingsStateSuccessAction, () => initialBookingRequestState),
	on(loadBookingsAction, state => setLoadingStateOfGeneric(state)),
	on(loadBookingFailureAction, (state, payload) => setErrorStateOfGeneric(state, bookingsAdapter, payload.error)),
	on(loadBookingsSuccessAction, (state, payload) =>
		setSuccessStateOfGeneric(state, bookingsAdapter, payload.bookings)
	),

	on(
		editBookingSuccessAction,
		loadBookingSuccessAction,
		createLeaseSuccessAction,
		addBookingSuccessAction,
		(state, payload) => {
			return payload.booking ? bookingsAdapter.upsertOne(payload.booking, state) : state;
		}
	),
	on(deleteBookingSuccessAction, (state, payload) => bookingsAdapter.removeOne(payload.bookingId, state)),
	on(addMockLeasesSuccessAction, addMockMaintenanceSuccessAction, (state, payload) => {
		const bookings = payload.leases.filter(it => it.booking).map(it => it.booking);
		return bookings.length > 0 ? bookingsAdapter.upsertMany(bookings, state) : state;
	})
);
