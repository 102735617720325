import { createSelector } from '@ngrx/store';
import { PropertyPayment } from 'src/app/models/payments';
import { AppState } from 'src/app/store/reducer';
import { AppUtils } from 'src/app/utils/app-utils';
import { paymentsAdapter } from './payments.reducer';

const selectNewPaymentsState = createSelector(
	state => state,
	(state: AppState) => state.newPayments
);

export const { selectTotal: selectPaymentsLength } = paymentsAdapter.getSelectors(selectNewPaymentsState);

export const selectPaymentsEntities = createSelector(selectNewPaymentsState, state => {
	if (state.isLoaded) {
		return state.entities;
	}
	return null;
});

export const selectPaymentsForLease = (leaseId: string) =>
	createSelector(selectPaymentsEntities, paymentsDict => {
		if (paymentsDict) {
			return AppUtils.filterDict<PropertyPayment>(paymentsDict, payment => payment.leaseId === leaseId);
		} else {
			return null;
		}
	});
