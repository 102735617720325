<div mat-dialog-content>
	<input type="text" style="display: none" cdkFocusInitial />

	<div fxLayout="column" fxLayoutAlign="flex-start center" class="center-popup-header">
		<p class="semi-bold-15 color-white no-margin v-spacing-20" i18n="@@property_post_dialog_title">
			<span class="color-orange">Well</span>&nbsp;done mate!
		</p>

		<div fxLayout="row" fxLayoutAlign="flex-end center" style="width: 100%; margin: -18px 50px 0px 0px">
			<img
				src="./assets/img/close.svg"
				class="rf-is-clickable"
				(click)="cancelClick()"
				style="width: 15px; height: 15px"
			/>
		</div>
	</div>

	<div class="side-padded">
		<p class="regular-12 text-center" style="margin: 20px 0 0 0" i18n="@@post_add_tenant_desc">
			You have created the tenant <span class="semi-bold-12 color-1">{{ data.name }}</span
			>!
		</p>
		<div fxLayout="row" fxLayoutAlign="center center">
			<div fxLayout="column" fxFlex="50%" fxLayoutAlign="center center">
				<img class="img-copy-done" src="./assets/img/menu_prop_property.svg" />

				<p class="regular-12" style="margin: 0 0 5px 0" i18n="@@post_tenant_lease_title">
					Connect a <span class="semi-bold-12 color-orange">Property</span>
				</p>
				<p class="regular-12" style="margin: 0 0 20px 0" i18n="@@post_tenant_lease_desc">
					By starting a lease with this tenant
				</p>

				<button
					mat-button
					class="button-positive button-small"
					style="margin: 0 0 40px 0"
					i18n="@@post_property_cta_add_lease"
					(click)="addLease()"
				>
					Add Lease
				</button>
			</div>

			<div fxLayout="column" fxFlex="50%" fxLayoutAlign="center center">
				<img class="img-copy-done" src="./assets/img/menu_tenant_profile.svg" />

				<p class="regular-12" style="margin: 0 0 5px 0" i18n="@@post_tenant_tenant_title">
					Add a new <span class="semi-bold-12 color-orange">Tenant</span>
				</p>
				<p class="regular-12" style="margin: 0 0 20px 0" i18n="@@post_tenant_tenant_desc">
					To complete your portfolio
				</p>

				<button
					mat-button
					class="button-positive button-small"
					style="margin: 0 0 40px 0"
					i18n="@@post_tenant_cta_add_tenant"
					(click)="addTenant()"
				>
					Add Tenant
				</button>
			</div>
		</div>
	</div>

	<div class="center-popup-footer" fxLayoutAlign="center center" style="width: inherit">
		<button mat-button class="button-primary button-small" (click)="cancelClick()" i18n="@@com_ok">Ok</button>
	</div>
</div>
