import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/store/reducer';
import { ownerPagesAdapter } from './owners-pages.reducers';

const selectOwnerPagesState = createSelector(
	state => state,
	(state: AppState) => state.ownerPages
);

export const { selectTotal: selectOwnerPagesLength } = ownerPagesAdapter.getSelectors(selectOwnerPagesState);

export const selectOwnerPagesEntities = createSelector(selectOwnerPagesState, state => {
	if (state.isLoaded) {
		return state.entities;
	}
	return null;
});
