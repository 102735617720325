import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnotifyService } from 'ng-snotify';
import { Observable, throwError } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { MinimumUserData } from 'src/app/models/common';
import { LandlordData } from 'src/app/models/landlord';
import { LandlordReport } from 'src/app/models/report';
import { CallbackFunctions } from 'src/app/store/reducer';
import { AppConstants } from 'src/app/utils/app-costants';
import { environment } from 'src/environments/environment';
import packageInfo from '../../../../../package.json';
import {
	askForReportGenerationAction,
	landlordSignOutSuccessAction,
	loadLandlordDataAction,
	requestNewPasswordAction,
	setLandlordTokenAction,
	updateLandlordAction
} from './state/landlord.actions';
import {
	selectLandlordCurrency,
	selectLandlordData,
	selectLandlordId,
	selectLandlordReports,
	selectLandlordUserData
} from './state/landlord.selectors';
import { addDays } from 'date-fns';

export interface ContactRequestInfo {
	landlordId: string;
	title: string;
	desc: string;
	sentTime: number;
}

export enum SectionsList {
	METRICS = 'metrics',
	PROPERTIES = 'properties',
	TENANTS = 'tenants',
	AVAILABILITY = 'availability',
	OWNERS = 'owners',
	PAYMENTS = 'payments',
	MAINTENANCES = 'maintenances',
	CALENDAR = 'calendar',
	CHAT = 'chat',
	CHANNEL_MANAGER = 'channel_manager',
	PRICING = 'pricing'
}

@Injectable({
	providedIn: 'root'
})
export class LandlordService {
	private BACKEND_HOST = `${environment.services.backend}/api-dash/v1`;
	landlordsCache$: Observable<string[]>;

	constructor(
		private readonly store: Store,
		private readonly httpClient: HttpClient,
		private readonly actions$: Actions,
		private readonly toastService: SnotifyService
	) {
		this.actions$.pipe(ofType(landlordSignOutSuccessAction)).subscribe(() => (this.landlordsCache$ = null));
	}

	public getLandlordData(): Observable<LandlordData> {
		return this.store.select(selectLandlordData).pipe(distinctUntilChanged());
	}

	public getLandlordUserData(): Observable<MinimumUserData> {
		return this.store.select(selectLandlordUserData).pipe(distinctUntilChanged());
	}

	public canFeatureRead(feature: SectionsList = undefined, showToast?: boolean): Observable<boolean> {
		return this.canFeature(feature, ['view', 'edit']).pipe(
			take(1),
			tap(canRead => {
				if (!canRead && showToast) {
					this.toastService.error(
						$localize`:@@permissions_error_toast_desc:Please contact your administrator to request permission`,
						$localize`:@@permissions_error_toast_title:Access Denied`,
						{
							...AppConstants.TOAST_STD_CONFIG,
							timeout: 5000
						}
					);
				}
			})
		);
	}

	public canFeatureWrite(feature: SectionsList = undefined, showToast?: boolean): Observable<boolean> {
		return this.canFeature(feature, ['edit']).pipe(
			take(1),
			tap(canWrite => {
				if (!canWrite && showToast) {
					this.toastService.error(
						$localize`:@@permissions_error_toast_desc:Please contact your administrator to request permission`,
						$localize`:@@permissions_error_toast_title:Access Denied`,
						{
							...AppConstants.TOAST_STD_CONFIG,
							timeout: 5000
						}
					);
				}
			})
		);
	}

	public canFeature(feature: SectionsList = undefined, validValues: string[]): Observable<boolean> {
		return this.getLandlordUserData().pipe(
			distinctUntilChanged(),
			filter(it => !!it),
			map(it => {
				if (it && it.permissions) {
					const p = it.permissions.find(p => p.id === feature);
					if (p) {
						return validValues.includes(p.value);
					}
				}

				return true;
			})
		);
	}

	public getLandlordId(): Observable<string> {
		return this.store.select(selectLandlordId).pipe(distinctUntilChanged());
	}

	public getLandlordCurrency(): Observable<string> {
		return this.store.select(selectLandlordCurrency);
	}

	public getLandlordReports(): Observable<LandlordReport[]> {
		return this.store.select(selectLandlordReports).pipe(distinctUntilChanged());
	}

	public loadLandlordData(id: string): void {
		this.store.dispatch(loadLandlordDataAction({ landlordId: id }));
	}

	public updateLandlord(landlord: LandlordData, callbacks?: CallbackFunctions): void {
		this.store.dispatch(updateLandlordAction({ landlord, callbacks }));
	}

	public setLandlordToken(token: string): void {
		// TODO: This is called from a service indipendent from landlordId.
		// First login is ok, second login is triggered before landlordId can be set on the landlordEffect
		this.store.dispatch(setLandlordTokenAction({ token }));
	}

	public askForReportGeneration(options: any, hostedPageId?: string, callbacks?: CallbackFunctions): void {
		this.store.dispatch(askForReportGenerationAction({ options, hostedPageId, callbacks }));
	}

	public requestNewPassword(password: string): void {
		this.store.dispatch(requestNewPasswordAction({ password }));
	}

	public allowLandlordSkipBlock(days: number): void {
		this.getLandlordData()
			.pipe(take(1))
			.subscribe(landlordData => {
				const updatedLandlordData = {
					...landlordData,
					skipBlockUntil: addDays(Date.now(), days).getTime(),
					blockSkipped: true
				};

				this.updateLandlord(updatedLandlordData);
			});
	}

	public contactUs(title: string, message: string) {
		return this.getLandlordId().pipe(
			switchMap(landlordId => {
				return this.httpClient.post(
					`${this.BACKEND_HOST}/landlords/${landlordId}/contact-requests`,
					{ title, message, estelleVersion: packageInfo.version },
					{ responseType: 'text' }
				);
			})
		);
	}

	public reportAnError(title: string, message: string) {
		return this.getLandlordId().pipe(
			switchMap(landlordId => {
				return this.httpClient.post(
					`${this.BACKEND_HOST}/landlords/${landlordId}/bugs`,
					{ title, message, estelleVersion: packageInfo.version },
					{ responseType: 'text' }
				);
			})
		);
	}

	public handleError(error: HttpErrorResponse) {
		return this.getLandlordId().pipe(
			switchMap(landlordId => {
				this.httpClient
					.post<any>(`${this.BACKEND_HOST}/landlords/${landlordId}/logs`, error)
					.pipe(take(1))
					.toPromise()
					.catch(e => {
						console.error(e);
					});

				return throwError(error);
			})
		);
	}

	getLandlordNameSurname(): Observable<string> {
		return this.getLandlordData().pipe(map(landlord => `${landlord.name} ${landlord.surname}`));
	}

	getLandlordName(): Observable<string> {
		return this.getLandlordData().pipe(map(landlord => landlord.name));
	}

	getLandlordPicture() {
		return this.getLandlordData().pipe(
			map(landlord => {
				const name = (landlord.name || '').replace(/[^0-9a-z]/gi, '');
				const surname = (landlord.surname || '').replace(/[^0-9a-z]/gi, '');

				if (name.length > 0) {
					const n1 = name.toUpperCase()[0];
					let n2 = '';

					if (surname.length > 0) {
						n2 = surname.toUpperCase()[0];
					}

					if (n1 + n2 !== '') {
						return (
							'https://firebasestorage.googleapis.com/v0/b/roommate-d6e73.appspot.com/o/appData%2FDefaultProfilePicturesPrimary%2F' +
							(n1 + n2) +
							'.png?alt=media'
						);

						// &token=f9436700-7267-4cfb-a4a0-09e7d5e92257
					} else {
						return './assets/img/tenant_blue.svg';
					}
				} else {
					return './assets/img/tenant_blue.svg';
				}
			})
		);
	}
}
