import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListingScoreCalculator, ListingScoreMessage, ListingScoreResult } from 'src/app/models/listingScore.model';
import { EstelleUnit, Property, UnitCompleteData } from 'src/app/models/property.model';
import { AppUtils } from 'src/app/utils/app-utils';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ListingScoreService implements ListingScoreCalculator<UnitCompleteData> {
	private BACKEND_HOST = `${environment.services.backend}/api-dash/v1`;

	listingScoreMessages: ListingScoreMessage[] = [
		{
			id: 'address',
			localizedMessage: $localize`:@@listing_score_prop_addr:Property address`
		},
		{
			id: 'house-number',
			localizedMessage: $localize`:@@listing_score_prop_number:House number`
		},
		{
			id: 'property-size',
			localizedMessage: $localize`:@@listing_score_prop_size:Property size`
		},
		{
			id: 'prop-desc-min',
			localizedMessage: $localize`:@@listing_score_prop_desc:Property description`
		},
		{
			id: 'unit-desc-min',
			localizedMessage: $localize`:@@listing_score_unit_desc:Unit description`
		},
		{
			id: 'prop-photo',
			localizedMessage: $localize`:@@listing_score_prop_photos:Property photos`
		},
		{
			id: 'unit-photo',
			localizedMessage: $localize`:@@listing_score_unit_photos:Unit photos`
		},
		{
			id: 'unit-size',
			localizedMessage: $localize`:@@listing_score_unit_size:Unit size`
		},
		{
			id: 'unit-amenities',
			localizedMessage: $localize`:@@listing_score_unit_amenities:Unit amenities`
		},
		{
			id: 'rent-amount',
			localizedMessage: $localize`:@@listing_score_unit_amount:Rent amount`
		},
		{
			id: 'deposit-amount',
			localizedMessage: $localize`:@@listing_score_unit_dep_amount:Deposit amount`
		},
		{
			id: 'prop-amenities',
			localizedMessage: $localize`:@@listing_score_prop_amen:Your tenants will be looking for the available amenities`
		},
		{
			id: 'prop-desc-long',
			localizedMessage: $localize`:@@listing_score_long_desc:Properties with longer descriptions perform better on listing platforms`
		},
		{
			id: 'multiple-photos-prop',
			localizedMessage: $localize`:@@listing_score_5_photo:Properties with 5 photos or more perform better on listing platforms`
		},
		{
			id: 'multiple-photos',
			localizedMessage: $localize`:@@listing_score_5_photo:Properties with 5 photos or more perform better on listing platforms`
		},
		{
			id: 'multiple-photos-unit',
			localizedMessage: $localize`:@@listing_score_5_photo-unit:Units with 5 photos or more perform better on listing platforms`
		},
		{
			id: 'unit-name',
			localizedMessage: $localize`:@@listing_score_unit_name:Unit name`
		},
		{
			id: 'bathroom-count',
			localizedMessage: $localize`:@@add_property_bathrooms_count:Number of bathrooms`
		}
	];

	constructor(private readonly httpClient: HttpClient) {}

	public calcUnitScore(
		landlordId: string,
		input: UnitCompleteData,
		avoidExtraRules: boolean = false
	): Observable<ListingScoreResult> {
		return this.httpClient.post<ListingScoreResult>(
			`${this.BACKEND_HOST}/landlords/${landlordId}/unitListingScore`,
			{
				property: input.property,
				unit: input.unit,
				avoidExtraRules
			}
		);
	}

	public calcPropertyScore(
		landlordId: string,
		input: Property,
		avoidExtraRules: boolean = false
	): Observable<ListingScoreResult> {
		return this.httpClient.post<ListingScoreResult>(
			`${this.BACKEND_HOST}/landlords/${landlordId}/propertyListingScore`,
			{
				property: input,
				avoidExtraRules
			}
		);
	}

	public applyLocalizedMessages(
		scoreResult: ListingScoreResult,
		managedAsProperty: boolean,
		name?: string
	): ListingScoreResult {
		if (scoreResult?.suggestions && scoreResult.suggestions.length > 0) {
			scoreResult.suggestions.forEach(suggestion => {
				const localizedMessage = this.listingScoreMessages.find(
					rule => rule.id === suggestion.id
				).localizedMessage;

				if (suggestion.applyTo === 'unit' && !managedAsProperty) {
					suggestion.localizedMessage = `${suggestion.unitName || name}: ${localizedMessage}`;
				} else {
					suggestion.localizedMessage = localizedMessage;
				}
			});
		}

		return scoreResult;
	}
}
