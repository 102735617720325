<div mat-dialog-content>
	<input type="text" style="display: none" cdkFocusInitial />

	<div fxLayout="column" fxLayoutAlign="flex-start center" class="popup-top-section">
		<div class="v-spacing-20"></div>
		<p class="semi-bold-15 color-white no-margin">
			<span [innerHTML]="dialogData.confirmTitle"></span>
		</p>

		<img
			src="./assets/img/close.svg"
			class="rf-is-clickable"
			(click)="cancelClick()"
			style="width: 15px; height: 15px"
		/>
	</div>

	<div fxLayout="column" fxLayoutAlign="center center" fxLayoutWrap>
		<img [src]="dialogData.layoutImgURL" class="v-spacing-20" style="width: 80px" />

		<p class="regular-15 text-center">
			<span [innerHTML]="dialogData.confirmDesc"></span>
		</p>

		<div
			class="popup-bottom-section"
			fxLayout="row"
			fxLayoutAlign="space-between center"
			fxLayoutWrap
			style="width: 100%"
		>
			<button
				mat-button
				[ngClass]="dialogData.neutralCancelButton ? 'button-neutral' : 'button-negative-empty'"
				(click)="cancelClick()"
			>
				Cancel
			</button>

			<button
				mat-button
				[ngClass]="dialogData.positiveConfirmButton ? 'button-positive' : 'button-negative'"
				(click)="proceedClick()"
			>
				{{ dialogData.confirmButtonText }}
			</button>
		</div>
	</div>
</div>
