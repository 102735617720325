import { isPast } from 'date-fns';
import { PaymentType } from '../payments/payments.service';
import { BasePageModelStore, CreditCardFeesType } from './common';
import { AttachedFile } from './fileRm.model';

export class TaxInfo {
	constructor(
		public vatCode: string,
		public vatValue: number,
		public description: string,
		public exlusiveOf?: string[],
		public natura?: string
	) {}

	public static NONE(): TaxInfo {
		return new TaxInfo('6', 0, '');
	}
}

export interface DatedItem {
	createdDate?: number;
	updatedDate?: number;
	createdBy?: string;
	updatedBy?: string;
	landlordId?: string;
}
export interface GenericInvoice extends DatedItem {
	id?: string;
	invoiceNumber: number;
	invoiceDate: string;
	name?: string;
	type: string;
	status: 'waiting' | 'ready' | 'error';
	message?: string;
	url?: string;
	referenceCode?: string;
	[key: string]: any; // TODO: Features in definition
}

export interface PaymentRecurrency {
	recurrencyInterval: number;
	recurrencyType: 'day' | 'week' | 'month' | 'year';
	recurrencyFrom: number;
}
export interface PaymentItemExtraInfo {
	leaseIncomeType?: 'management_fee' | 'recurring_fees' | 'entry_fee' | 'exit_fee';
	leaseIncomeAmountType?: 'fixed' | 'percentage' | ''; // Used if leaseIncomeType = 'management_fee'
	assigneeType?: 'you' | 'owner' | '';
	assigneeId?: string;
	recurrency?: PaymentRecurrency;
	forecasted?: boolean;
	pastPaymentsPaid?: boolean;
	linkedRecurringPaymentId?: string;
}

export class PaymentItem {
	constructor(
		public code: string,
		public name: string,
		public amount: number,
		public category: string,
		public linkedId?: string,
		public taxInfo?: TaxInfo,
		public excludeInvoice?: boolean,
		public description?: string,
		public additionalAmount?: number
	) {}
}

export interface PaymentSetup {
	item: PaymentItem;
	info: PaymentItemExtraInfo;
}
export interface PropertyPayment {
	title: string; // Title (unused if we show category, property and tenant)
	category: string; // ID of category
	creationDate: number; // Date of creation of this payment
	referenceDate: number;
	dueDate: number; // Expiry of this payment
	amount: number; // Amount of transaction
	currency: string; // Currency of the amount
	landlordId: string;
	note: string;
	attachments?: AttachedFile[];
	authorId: string;
	status: PaymentsStatus;
	id: string;
	amountPaid: number;
	extraFees: PaymentItem[];
	linkedPeriodicPaymentId?: string;
	metadata: {
		[key: string]: string | number | boolean | PaymentRecurrency;
		lockedBy?: string;
		xmlInvoiceTime?: number;
		xmlInvoiceNumber?: string;
		pdfInvoiceTime?: number;
		pdfInvoiceNumber?: number;
		customInvoiceTime?: number;
		customInvoiceNumber?: number;
		linkedMaintenance?: string;
		recurrency?: PaymentRecurrency;
	};

	unitId?: string;
	invoices?: { [key: string]: { [key: string]: GenericInvoice } };
	closingDate?: number;

	// Payments iteration
	// 1. These fields are optional
	tenantId?: string; // TENANT
	propertyId?: string; // PROPERTY
	leaseId?: string; // LEASE
	// 2. New fields
	ownerId?: string; // OWNER
	type?: PaymentType;
	forecasted?: boolean;
	transactions?: Partial<PaymentOperation>[];
}

export interface PaymentPageModelStore extends BasePageModelStore {
	paymentIds: string[];
}

export enum PaymentProviderRecurringPaymentStatus {
	ACTIVE = 'active',
	AUTH_NEEDED = 'auth_needed',
	INVALID = 'invalid'
}
export interface RecurringPayment extends DatedItem {
	id: string;
	endTime: number;
	paymentProviderAssociation?: {
		id: string;
		amount: number;
		status: PaymentProviderRecurringPaymentStatus;
		associatedCardId: string;
	};
}

export interface PaymentPageModelUI {
	payments: PropertyPayment[];
	totalItems: number;
	filteredItems: number;
}

export class PropertyPayment implements PropertyPayment {
	static getStatusFromPayment(payment: Partial<PropertyPayment>): PaymentsStatus {
		if (payment.metadata && payment.metadata.lockedBy) {
			return 'pending';
		} else {
			if (isPast(payment.dueDate) && payment.status === 'open') {
				return 'overdue';
			} else if (!isPast(payment.dueDate) && payment.status === 'open') {
				return 'upcoming';
			} else {
				return payment.status;
			}
		}
	}

	static getPaymentType(payment: PropertyPayment): PaymentType {
		if (!!payment.type) {
			return payment.type || null;
		} else {
			if (payment.category === 'rent' || payment.category === 'deposit_in' || payment.category === 'late_fees') {
				return PaymentType.IN;
			} else if (payment.category === 'deposit_out' || !!!payment.tenantId) {
				return PaymentType.OUT;
			} else {
				return PaymentType.REIMBURSEMENT;
			}
		}
	}
}

export interface AppliedFees {
	feesAmount: number;
	netFeeAmount: number;
	totalAmount: number;
	vatRate: number;
	feesType: CreditCardFeesType;
	vatCountry: string;
	feeRate: number;
}

export class PaymentOperation {
	public fees?: AppliedFees;

	constructor(
		public id: string,
		public linkedPaymentId: string,
		public amount: number,
		public currency: string,
		public createdDate: number,
		public completionTime: number,
		public paymentMethod: string,
		public linkedPaymentMethodOpId: string,
		public status: OperationStatus,
		public authorId: string,
		public landlordId: string,
		public fee: number,
		public failureReason?: string,
		public metadata?: { [key: string]: any },
		public note?: string
	) {}
}

export declare type PaymentsStatus =
	| 'open'
	| 'overdue'
	| 'upcoming'
	| 'close'
	| 'pending'
	| 'failed'
	| 'succeeded'
	| 'forecasted';
export declare type OperationStatus = 'pending' | 'failure' | 'late_failure' | 'succeeded';
