import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CalendarEvent, CalendarCategory } from 'src/app/models/calendar';
import { resetCalendarStateSuccessAction } from './calendar.actions';
import {
	createCategorySuccessAction,
	createEventSuccessAction,
	deleteCategorySuccessAction,
	deleteEventSuccessAction,
	fixCategoriesOrderSuccessAction,
	loadCategoriesSuccessAction,
	loadEventsFromDateIntervalSuccessAction,
	updateCategorySuccessAction,
	updateEventSuccessAction
} from './calendar.actions';

interface CurrentCategoriesState extends EntityState<CalendarCategory> {}
interface CurrentEventsState extends EntityState<CalendarEvent> {}

export const currentCategoriesAdapter = createEntityAdapter<CalendarCategory>();
export const currentEventsAdapter = createEntityAdapter<CalendarEvent>();

const currentCategoriesInitialState = currentCategoriesAdapter.getInitialState([]);
const currentEventsInitialState = currentEventsAdapter.getInitialState([]);

export interface CalendarState {
	currentEvents: CurrentEventsState;
	currentCategories: CurrentCategoriesState;
}

export const calendarInitialState: CalendarState = {
	currentEvents: currentEventsInitialState,
	currentCategories: currentCategoriesInitialState
};

export const CalendarReducers = createReducer(
	calendarInitialState,
	on(resetCalendarStateSuccessAction, (state, payload) => (state = calendarInitialState)),
	on(
		fixCategoriesOrderSuccessAction,
		(state, payload) =>
			(state = {
				...state,
				currentCategories: currentCategoriesAdapter.setAll(payload.categories, state.currentCategories)
			})
	),
	on(
		loadCategoriesSuccessAction,
		(state, payload) =>
			(state = {
				...state,
				currentCategories: currentCategoriesAdapter.setAll(payload.categories, state.currentCategories)
			})
	),
	on(
		createCategorySuccessAction,
		(state, payload) =>
			(state = {
				...state,
				currentCategories: currentCategoriesAdapter.addOne(payload.category, state.currentCategories)
			})
	),
	on(
		updateCategorySuccessAction,
		(state, payload) =>
			(state = {
				...state,
				currentCategories: currentCategoriesAdapter.updateOne(
					{ id: payload.category.id, changes: payload.category },
					state.currentCategories
				)
			})
	),
	on(
		deleteCategorySuccessAction,
		(state, payload) =>
			(state = {
				...state,
				currentCategories: currentCategoriesAdapter.removeOne(payload.id, state.currentCategories)
			})
	),
	on(
		loadEventsFromDateIntervalSuccessAction,
		(state, payload) =>
			(state = { ...state, currentEvents: currentEventsAdapter.setAll(payload.events, state.currentEvents) })
	),
	on(
		createEventSuccessAction,
		(state, payload) =>
			(state = { ...state, currentEvents: currentEventsAdapter.addOne(payload.event, state.currentEvents) })
	),
	on(
		updateEventSuccessAction,
		(state, payload) =>
			(state = {
				...state,
				currentEvents: currentEventsAdapter.updateOne(
					{ id: payload.event.id, changes: payload.event },
					state.currentEvents
				)
			})
	),
	on(
		deleteEventSuccessAction,
		(state, payload) =>
			(state = { ...state, currentEvents: currentEventsAdapter.removeOne(payload.id, state.currentEvents) })
	)
);
