export class AttachedFile {
	constructor(
		public name: string,
		public size: number,
		public isShared: boolean,
		public privateUrl: string,
		public publicUrl: string,
		public creationTime: number,
		public metadata?: { [keyId: string]: string[] | string },
		public id?: string
	) {}
}

export class PendingFile {
	constructor(
		public name: string,
		public size: number,
		public isShared: boolean,
		public insertId: number,
		public newFile: boolean,
		public creationTime: number,
		public url?: string,
		public file?: File
	) {}
}

export declare type FileStatus = 'done' | 'to_upload' | 'error' | 'edit' | 'uploading' | 'deleting' | 'downloading';
export interface FileWrapper {
	status: FileStatus;
	file: PendingFile | AttachedFile;
}
