import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SnotifyService } from 'ng-snotify';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { EffectInteractionService } from 'src/app/core/services/effectInteraction.service';
import { LandlordService } from 'src/app/core/services/landlord/landlord.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { Booking, CreateBooking } from 'src/app/models/booking.model';
import { resetStoreAction } from 'src/app/store/actions';
import { LocalizationUtils } from 'src/app/utils/localization-utils';
import { environment } from 'src/environments/environment';
import {
	addBookingAction,
	addBookingFailureAction,
	addBookingSuccessAction,
	deleteBookingAction,
	deleteBookingFailureAction,
	deleteBookingSuccessAction,
	editBookingAction,
	editBookingFailureAction,
	editBookingSuccessAction,
	loadBookingByIdAction,
	loadBookingFailureAction,
	loadBookingsAction,
	loadBookingsFailureAction,
	loadBookingsSuccessAction,
	loadBookingSuccessAction,
	resetBookingsStateAction,
	resetBookingsStateFailureAction,
	resetBookingsStateSuccessAction
} from './bookings.actions';

@Injectable()
export class BookingsEffects {
	private translations = LocalizationUtils.getTranslations();
	private BACKEND_HOST = `${environment.services.backend}/api-dash/v1`;
	private landlordId = '';

	constructor(
		private readonly landlordService: LandlordService,
		private readonly httpClient: HttpClient,
		private readonly actions$: Actions,
		private readonly loadingService: LoadingService,
		private readonly effectInteractionService: EffectInteractionService,
		private readonly router: Router
	) {
		combineLatest([this.landlordService.getLandlordId()]).subscribe(([landlordId]) => {
			this.landlordId = landlordId;
		});
	}

	public resetStoreState = createEffect(() =>
		this.actions$.pipe(
			ofType(resetStoreAction),
			switchMap(() => of(resetBookingsStateAction()))
		)
	);

	public resetBookingsStateEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(resetBookingsStateAction),
			switchMap(() => of(resetBookingsStateSuccessAction())),
			catchError(() => of(resetBookingsStateFailureAction))
		)
	);

	public loadBookingEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadBookingByIdAction),
			switchMap(action =>
				this.loadBooking(action.bookingId, action.options).pipe(
					map(booking => loadBookingSuccessAction({ booking })),
					catchError(err => of(loadBookingFailureAction({})))
				)
			)
		)
	);

	public addBookingEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(addBookingAction),
			mergeMap(action =>
				this.addBooking(action.booking).pipe(
					map(booking => {
						this.effectInteractionService.callbackSuccess(action);
						return addBookingSuccessAction({ booking });
					}),
					catchError(() => {
						this.effectInteractionService.callbackFail(action);
						return of(addBookingFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public editBookingEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(editBookingAction),
			switchMap(action =>
				this.editBooking(action.booking).pipe(
					map(booking => editBookingSuccessAction({ booking })),
					catchError(() => {
						this.effectInteractionService.callbackFail(action);
						return of(editBookingFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public deleteBookingEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(deleteBookingAction),
			mergeMap(action =>
				this.deleteBooking(action.booking.id).pipe(
					map(() => {
						this.effectInteractionService.callbackSuccess(action);
						return deleteBookingSuccessAction({ bookingId: action.booking.id });
					}),
					catchError(err => {
						this.effectInteractionService.callbackFail(action);
						return of(deleteBookingFailureAction({}));
					})
				)
			)
		);
	});

	public loadBookingsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadBookingsAction),
			switchMap(action =>
				this.loadBookings().pipe(
					map(bookings => loadBookingsSuccessAction({ bookings })),
					catchError(err => of(loadBookingsFailureAction({})))
				)
			)
		)
	);

	/*** Effects handle success and failure actions  ***/

	public addBookingSuccessEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(addBookingSuccessAction),
				tap(action =>
					this.effectInteractionService.displaySuccessToast(
						this.translations.toast.booking_added_title,
						this.translations.toast.booking_added_msg,
						() => {
							this.router.navigate(['bookings/detail', action.booking.id]);
						}
					)
				)
			),
		{ dispatch: false }
	);

	public addBookingFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(addBookingFailureAction),
				tap(() =>
					this.effectInteractionService.showDialogError(
						$localize`:@@add_booking_failed:Something went wrong during the creation of the booking. Try again and if the problem persists contact us.`
					)
				)
			),
		{ dispatch: false }
	);

	public deleteBookingSuccessEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(deleteBookingSuccessAction),
				tap(() =>
					this.effectInteractionService.displaySuccessToast(
						'',
						this.translations.toast.booking_delete_successfully_msg
					)
				)
			),
		{ dispatch: false }
	);

	public deleteBookingFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(deleteBookingFailureAction),
				tap(() =>
					this.effectInteractionService.showDialogError(
						$localize`:@@delete_booking_failed:Something went wrong during the delete of the booking. Try again and if the problem persists contact us.`
					)
				)
			),
		{ dispatch: false }
	);

	public genericBookingFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(editBookingFailureAction, loadBookingFailureAction),
				tap(() => this.loadingService.hideAndDisplayErrorToast())
			),
		{ dispatch: false }
	);

	public silentBookingFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(loadBookingsFailureAction, resetBookingsStateFailureAction),
				tap(action => this.effectInteractionService.logErrorSilently(action.type))
			),
		{ dispatch: false }
	);

	/***  ***/

	// Private calls
	private loadBooking(bookingId: string, options: any = {}): Observable<Booking> {
		return this.httpClient.get<Booking>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/bookings/${bookingId}`);
	}

	private addBooking(booking: CreateBooking, options: any = {}): Observable<Booking> {
		return this.httpClient.post<Booking>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/bookings`, {
			booking,
			options
		});
	}

	private editBooking(booking: Booking, options: any = {}): Observable<Booking> {
		return this.httpClient.put<Booking>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/bookings/${booking.id}`,
			{
				booking,
				options
			}
		);
	}

	private deleteBooking(bookingId: string): Observable<void> {
		return this.httpClient.delete<void>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/bookings/${bookingId}`);
	}

	private loadBookings(): Observable<Booking[]> {
		return this.httpClient.get<Booking[]>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/bookings`);
	}
}
