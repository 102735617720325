import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { LandlordService } from 'src/app/core/services/landlord/landlord.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { EffectInteractionService } from 'src/app/core/services/effectInteraction.service';
import { PropertyOwner, PropertyOwnerCreate } from 'src/app/models/propertyOwner.model';
import { resetStoreAction } from 'src/app/store/actions';
import { environment } from 'src/environments/environment';
import {
	addNewPropertyToOwnerAction as addNewPropertyToOwnerAction,
	addNewPropertyToOwnerSuccessAction as addNewPropertyToOwnerSuccessAction,
	addOwnerAction,
	addOwnerFailureAction,
	addOwnerSuccessAction,
	deleteOwnerAction,
	deleteOwnerFailureAction,
	deleteOwnerSuccessAction,
	editOwnerAction,
	editOwnerFailureAction,
	editOwnerSuccessAction,
	getOwnerByIdAction,
	loadOwnerFailureAction,
	loadOwnersAction,
	loadOwnersFailureAction,
	loadOwnersPaginatedAction,
	loadOwnersPaginatedFailureAction,
	loadOwnersPaginatedSuccessAction,
	loadOwnersSuccessAction,
	loadOwnerSuccessAction,
	resetOwnersStateAction,
	resetOwnersStateFailureAction,
	resetOwnersStateSuccessAction
} from './owners.actions';
import { FilterContainer, OwnersQueryFilters, SortEvent } from 'src/app/core/services/filters/filters.model';
import { QueryResult } from 'src/app/models/common';
import { AppUtils } from 'src/app/utils/app-utils';

@Injectable()
export class OwnersEffects {
	private BACKEND_HOST = `${environment.services.backend}/api-dash/v1`;
	private landlordId = '';

	constructor(
		private readonly landlordService: LandlordService,
		private readonly httpClient: HttpClient,
		private readonly actions$: Actions,
		private readonly loadingService: LoadingService,
		private readonly effectInteractionService: EffectInteractionService
	) {
		this.landlordService.getLandlordId().subscribe(id => (this.landlordId = id));
	}

	public resetStoreState = createEffect(() =>
		this.actions$.pipe(
			ofType(resetStoreAction),
			switchMap(() => of(resetOwnersStateAction()))
		)
	);

	public addNewPropertyToOwnerEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(addNewPropertyToOwnerAction),
			mergeMap(action =>
				of(
					addNewPropertyToOwnerSuccessAction({
						ownerId: action.ownerId,
						propertiesIdsToAdd: action.propertyIdsToAdd
					})
				)
			)
		)
	);

	public resetOwnersStateEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(resetOwnersStateAction),
			switchMap(() => of(resetOwnersStateSuccessAction())),
			catchError(() => of(resetOwnersStateFailureAction({})))
		)
	);

	public loadOwnerEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(getOwnerByIdAction),
			switchMap(action =>
				this.loadOwner(action.ownerId, action.bypassPermissionsCheck).pipe(
					map(owner => loadOwnerSuccessAction({ owner })),
					catchError(err => of(loadOwnerFailureAction({})))
				)
			)
		)
	);

	public addOwnerEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(addOwnerAction),
			mergeMap(action =>
				this.addOwner(action.owner).pipe(
					map(owner => {
						this.effectInteractionService.callbackSuccess(action, owner);
						return addOwnerSuccessAction({ owner });
					}),
					catchError(() => {
						this.effectInteractionService.callbackFail(action);
						return of(addOwnerFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public editOwnerEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(editOwnerAction),
			mergeMap(action =>
				this.editOwner(action.owner, action.bypassPermissionsCheck).pipe(
					map(owner => {
						this.effectInteractionService.callbackSuccess(action, owner);
						return editOwnerSuccessAction({ owner });
					}),
					catchError(() => {
						this.effectInteractionService.callbackFail(action);
						return of(editOwnerFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public deleteOwnerEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(deleteOwnerAction),
			mergeMap(action =>
				this.deleteOwner(action.owner.id).pipe(
					map(() => {
						this.effectInteractionService.callbackSuccess(action);
						return deleteOwnerSuccessAction({ ownerId: action.owner.id });
					}),
					catchError(() => {
						this.effectInteractionService.callbackFail(action);
						return of(deleteOwnerFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		);
	});

	public loadOwnersEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadOwnersAction),
			switchMap(action =>
				this.loadOwnersBasic().pipe(
					map(owners => loadOwnersSuccessAction({ owners, refreshAll: action.refreshAll })),
					catchError(err => of(loadOwnersFailureAction({})))
				)
			)
		)
	);

	// Version 2

	public loadOwnersPaginatedEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadOwnersPaginatedAction),
			switchMap(action =>
				this.loadOwnersPaginated(action.page, action.pageSize, action.filters, action.sort).pipe(
					map(owners =>
						loadOwnersPaginatedSuccessAction({
							page: action.page,
							pageSize: action.pageSize,
							ownersQueryResult: owners
						})
					),
					catchError(err => of(loadOwnersPaginatedFailureAction({ error: err })))
				)
			)
		)
	);

	/*** Effects handle success and failure actions  ***/

	public addOwnerFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(addOwnerFailureAction),
				tap(() =>
					this.effectInteractionService.showDialogError(
						$localize`:@@add_owner_failed:Something went wrong during the creation of the property owner. Try again and if the problem persists contact us.`
					)
				)
			),
		{ dispatch: false }
	);

	public editOwnerFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(editOwnerFailureAction),
				tap(() =>
					this.effectInteractionService.showDialogError(
						$localize`:@@edit_owner_failed:Something went wrong during the update of the property owner. Try again and if the problem persists contact us.`
					)
				)
			),
		{ dispatch: false }
	);
	public deleteOwnerFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(deleteOwnerFailureAction),
				tap(() =>
					this.effectInteractionService.showDialogError(
						$localize`:@@delete_owner_failed:Something went wrong during the delete of the property owner. Try again and if the problem persists contact us.`
					)
				)
			),
		{ dispatch: false }
	);

	public genericOwnerFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(),
				tap(() => this.loadingService.hideAndDisplayErrorToast())
			),
		{ dispatch: false }
	);

	public silentOwnerFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(resetOwnersStateFailureAction, loadOwnersFailureAction),
				tap(action => this.effectInteractionService.logErrorSilently(action.type))
			),
		{ dispatch: false }
	);

	/***  ***/

	// Private calls
	private loadOwner(ownerId: string, bypassPermissionsCheck?: boolean): Observable<PropertyOwner> {
		let params = {};
		if (bypassPermissionsCheck) {
			params['bpcheck'] = true;
		}

		return this.httpClient.get<PropertyOwner>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/owners/${ownerId}`,
			{
				params: params
			}
		);
	}

	private addOwner(owner: PropertyOwnerCreate, options: any = {}): Observable<PropertyOwner> {
		return this.httpClient.post<PropertyOwner>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/owners`, {
			owner,
			options
		});
	}

	private editOwner(owner: PropertyOwnerCreate, bypassPermissionsCheck?: boolean): Observable<PropertyOwner> {
		let params = {
			owner
		};
		if (bypassPermissionsCheck) {
			params['bpcheck'] = true;
		}

		return this.httpClient.put<PropertyOwner>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/owners/${owner.id}`,
			params
		);
	}

	private deleteOwner(ownerId: string): Observable<void> {
		return this.httpClient.delete<void>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/owners/${ownerId}`);
	}

	// Version 2

	private loadOwnersBasic(): Observable<any[]> {
		return this.httpClient.get<PropertyOwner[]>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/owners/basic`);
	}

	private loadOwnersPaginated(
		page: number,
		pageSize: number,
		filters: FilterContainer,
		sort: SortEvent
	): Observable<QueryResult<PropertyOwner>> {
		// the getTenantCurrentLeaseStatus method calculates the currentLeaseStatus, when a lease is deleted
		// the observable below re-run and recalculate the currentTenantLeaseStatuses

		let customFilters: OwnersQueryFilters = {};

		customFilters.status = filters.selectedTabId as 'all' | 'natural' | 'legal';

		if (filters.searchedValue) {
			customFilters.text = filters.searchedValue;
		}

		const customSort = AppUtils.getQuerySortFromEstelleSort(sort);

		return this.httpClient
			.get<QueryResult<PropertyOwner>>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/owners`, {
				params: {
					page: page,
					pageSize: pageSize,
					...customFilters,
					...customSort
				}
			})
			.pipe(
				map(owners => {
					const ownersSanitized = owners.data.map(PropertyOwner.sanitize);

					owners.data = ownersSanitized;

					return owners;
				})
			);
	}
}
