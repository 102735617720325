import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { publishNewFiltersAction } from 'src/app/core/services/filters/state/filters.actions';
import {
	addMockLeasesSuccessAction,
	createLeaseSuccessAction,
	loadLeaseByIdSuccessAction,
	removeLeaseSuccessAction,
	updateLeaseSuccessAction
} from 'src/app/leases/state/lease.actions';
import { addMockMaintenanceSuccessAction } from 'src/app/maintenances/state/maintenances.actions';
import {
	addPropertySuccessAction,
	deletePropertySuccessAction,
	editPropertySuccessAction,
	loadPropertiesPaginatedSuccessAction,
	loadPropertiesBasicSuccessAction,
	loadPropertyByIdSuccessAction,
	resetPropertiesStateSuccessAction,
	uploadMultiplePropertiesSuccessAction
} from 'src/app/properties/state/properties.actions';
import { PropertyPageModelStore } from 'src/app/models/property.model';
import {
	addNewPropertyToOwnerSuccessAction,
	addOwnerSuccessAction,
	deleteOwnerSuccessAction,
	editOwnerSuccessAction,
	loadOwnersSuccessAction,
	loadOwnerSuccessAction
} from 'src/app/owners/state/owners.actions';

export interface PropertyPagesState extends EntityState<PropertyPageModelStore> {
	isLoaded: boolean;
}
export const propertyPagesAdapter = createEntityAdapter<PropertyPageModelStore>({
	selectId: model => model.id
});
const initialPropertyPagesState = propertyPagesAdapter.getInitialState({
	isLoaded: false
});

export const PropertyPagesReducers = createReducer(
	initialPropertyPagesState,

	// Load pages

	on(loadPropertiesPaginatedSuccessAction, (state, payload) => {
		if (payload.propertiesQueryResult) {
			return propertyPagesAdapter.upsertOne(
				{
					id: payload.page,
					propertyIds: payload.propertiesQueryResult.data.map(property => property.id),
					totalItems: payload.propertiesQueryResult.metadata.totalItems || 0,
					filteredItems: payload.propertiesQueryResult.metadata.filteredItems || 0
				},
				{ ...state, isLoaded: true }
			);
		} else {
			return state;
		}
	}),

	// Filters user actions

	on(publishNewFiltersAction, (state, payload) => {
		if (payload.filters.type === 'properties' && (payload.filters.filters || payload.filters.sort)) {
			return initialPropertyPagesState;
		} else {
			return state;
		}
	}),

	// Property actions (user actions + realtime updates)

	// ALL pages invalidation

	on(
		resetPropertiesStateSuccessAction,

		loadPropertyByIdSuccessAction,

		addPropertySuccessAction,
		editPropertySuccessAction,

		uploadMultiplePropertiesSuccessAction,
		addMockLeasesSuccessAction,
		addMockMaintenanceSuccessAction,

		(state, payload) => ({ ...initialPropertyPagesState, isLoaded: true })
	),

	on(loadPropertiesBasicSuccessAction, loadOwnersSuccessAction, (state, payload) => {
		if (payload['refreshAll']) {
			return { ...initialPropertyPagesState, isLoaded: true };
		} else {
			return state;
		}
	}),

	// SOME pages invalidation

	on(deletePropertySuccessAction, (state, payload) => {
		const allPages = Object.values(state.entities).sort((a, b) => a.id - b.id);
		let minDirtyPage = -1;
		for (let i = 0; i < allPages.length; i++) {
			if (allPages[i].propertyIds.includes(payload.deletePropertyId)) {
				minDirtyPage = allPages[i].id;
				break;
			}
		}

		if (minDirtyPage >= 0) {
			return propertyPagesAdapter.removeMany(it => it.id >= minDirtyPage, state);
		} else {
			return state;
		}
	}),

	// Owner actions (user actions + realtime updates)

	// ALL pages invalidation

	on(
		loadOwnerSuccessAction,

		addOwnerSuccessAction,
		editOwnerSuccessAction,

		deleteOwnerSuccessAction,
		addNewPropertyToOwnerSuccessAction,

		(state, payload) => ({ ...initialPropertyPagesState, isLoaded: true })
	),

	// Lease actions (user actions + realtime updates)

	// ALL pages invalidation

	on(
		loadLeaseByIdSuccessAction,

		createLeaseSuccessAction,
		removeLeaseSuccessAction,
		updateLeaseSuccessAction,

		(state, payload) => ({ ...initialPropertyPagesState, isLoaded: true })
	)
);
