import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import * as firebase from 'firebase/compat/app';
import { of } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class PresenceService {
	constructor(private afAuth: AngularFireAuth, private db: AngularFireDatabase) {
		this.updateOnUser().subscribe();
	}

	getPresence(uid: string) {
		return this.db.object(`data/onlineStatus/${uid}`).valueChanges();
	}

	getUser() {
		return this.afAuth.authState.pipe(first()).toPromise();
	}

	async setPresence(status: string) {
		const user = await this.getUser();

		if (user) {
			return this.db.object(`data/onlineStatus/${user.uid}`).update({ status, timestamp: this.timestamp });
		}
	}

	get timestamp() {
		return firebase.default.database.ServerValue.TIMESTAMP;
	}

	// Implement presence logic here

	// Updates status when logged-in connection to Firebase starts
	updateOnUser() {
		const connection = this.db
			.object('.info/connected')
			.valueChanges()
			.pipe(map(connected => (connected ? 'online' : 'offline')));

		return this.afAuth.authState.pipe(
			switchMap(user => (user ? connection : of('offline'))),
			tap(status => this.setPresence(status))
		);
	}

	updateOnDisconnect() {
		return this.afAuth.authState.pipe(
			tap(user => {
				if (user) {
					this.db.object(`data/onlineStatus/${user.uid}`).query.ref.onDisconnect().update({
						status: 'offline',
						timestamp: this.timestamp
					});
				}
			})
		);
	}

	async signOut() {
		await this.setPresence('offline');
		// await this.afAuth.auth.signOut(); This is done in another part
	}

	// User navigates to a new tab, case 3
	updateOnAway() {
		document.onvisibilitychange = e => {
			if (document.visibilityState === 'hidden') {
				this.setPresence('away');
			} else {
				this.setPresence('online');
			}
		};
	}
}
