import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { BookingsService } from 'src/app/bookings/bookings.service';
import { FiltersStoreModel } from './core/services/filters/filters.model';
import { selectFiltersEntities } from './core/services/filters/state/filters.selector';
import { LeaseService } from './leases/lease.service';
import { Booking } from './models/booking.model';
import { Lease } from './models/lease.model';
import { EstelleUnit, Property, UnitPageModelStore, UnitPageModelUI } from './models/property.model';
import { UnitDisplayData } from './properties/properties.component';
import { selectChannelManagerSyncUnitPagesEntities } from './properties/state/cm-sync-units-pages.selectors';
import { selectChannelManagerUnitPagesEntities } from './properties/state/cm-units-pages.selector';
import {
	loadChannelManagerSyncUnitsPaginatedAction,
	loadChannelManagerUnitsPaginatedAction,
	loadUnitsPaginatedAction
} from './properties/state/properties.actions';
import { selectPropertyDictionaryState } from './properties/state/properties.selectors';
import { selectUnitPagesEntities } from './properties/state/units-pages.selectors';

export enum UnitStatus {
	RENTED,
	VACANT,
	STARTING,
	EXPIRING,
	BOOKED
}

export interface UnitStatusData {
	status: UnitStatus;
	startDate?: number;
	endDate?: number;
}

@Injectable({
	providedIn: 'root'
})
export class UnitService {
	private leases: Lease[];
	private bookings: Booking[];

	constructor(
		private readonly leaseService: LeaseService,
		private readonly bookingService: BookingsService,
		private readonly store: Store
	) {
		combineLatest([this.leaseService.getLeases(), this.bookingService.getBookings()]).subscribe(
			([leases, bookings]) => {
				this.leases = leases;
				this.bookings = bookings;
			}
		);
	}

	public getEndDateOfActiveLease(unit: EstelleUnit): number {
		const activeLeases = this.leaseService.getActiveLeasesForUnitStatus(this.leases, unit.propertyId, unit.id);

		if (activeLeases.length > 0) {
			return activeLeases[0].endDate;
		} else {
			return -1;
		}
	}

	public getEndDateOfExpiringLease(unit: EstelleUnit): number {
		const expiringLeases = this.leaseService.getExpiringLeasesForUnitStatus(this.leases, unit.propertyId, unit.id);

		if (expiringLeases.length > 0) {
			return expiringLeases[0].endDate;
		} else {
			return -1;
		}
	}

	public getStartDateOfFutureLease(unit: EstelleUnit): number {
		const futureLeases = this.leaseService.getFutureLeasesForUnitStatus(this.leases, unit.propertyId, unit.id);

		if (futureLeases.length > 0) {
			return futureLeases[0].startDate;
		} else {
			return -1;
		}
	}

	private getEndDateOfActiveBooking(unit: EstelleUnit): number {
		const activeBookings = this.bookingService.getActiveConfirmedBookingsForUnitStatus(
			this.bookings,
			unit.propertyId,
			unit.id
		);

		if (activeBookings.length > 0) {
			return activeBookings[0].endDate;
		} else {
			return -1;
		}
	}

	public getStartDateOfFutureBooking(unit: EstelleUnit): number {
		const futureBookings = this.bookingService.getFutureConfirmedBookingsForUnitStatus(
			this.bookings,
			unit.propertyId,
			unit.id
		);

		if (futureBookings.length > 0) {
			return futureBookings[0].startDate;
		} else {
			return -1;
		}
	}

	public getUnitStatusData(unit: EstelleUnit): UnitStatusData {
		let unitStatusData: UnitStatusData = {
			status: UnitStatus.VACANT
		};

		const activeLeaseEndDate = this.getEndDateOfActiveLease(unit);
		if (activeLeaseEndDate >= 0) {
			unitStatusData = {
				status: UnitStatus.RENTED,
				endDate: activeLeaseEndDate
			};
		} else {
			const expiringLeaseEndDate = this.getEndDateOfExpiringLease(unit);
			if (expiringLeaseEndDate > 0) {
				unitStatusData = {
					status: UnitStatus.EXPIRING,
					endDate: expiringLeaseEndDate
				};
			} else {
				const activeBookingEndDate = this.getEndDateOfActiveBooking(unit);
				if (activeBookingEndDate > 0) {
					unitStatusData = {
						status: UnitStatus.BOOKED,
						endDate: activeBookingEndDate
					};
				} else {
					const futureLeaseStartDate = this.getStartDateOfFutureLease(unit);
					if (futureLeaseStartDate > 0) {
						unitStatusData = {
							status: UnitStatus.STARTING,
							startDate: futureLeaseStartDate
						};
					} else {
						const futureBookingStartDate = this.getStartDateOfFutureBooking(unit);
						if (futureBookingStartDate > 0) {
							unitStatusData = {
								status: UnitStatus.BOOKED,
								startDate: futureBookingStartDate
							};
						}
					}
				}
			}
		}
		return unitStatusData;
	}

	public getUnitStatus(unit: EstelleUnit): UnitStatus {
		const activeLease = this.getEndDateOfActiveLease(unit);
		if (activeLease >= 0) {
			return UnitStatus.RENTED;
		} else if (this.getEndDateOfExpiringLease(unit) > 0) {
			return UnitStatus.EXPIRING;
		} else if (this.getStartDateOfFutureLease(unit) < 0) {
			return UnitStatus.VACANT;
		} else {
			return UnitStatus.STARTING;
		}
	}

	public getStatusName(status: UnitStatus): string {
		if (status === UnitStatus.EXPIRING) {
			return $localize`:@@com_expiring:Expiring`;
		} else if (status === UnitStatus.RENTED) {
			return $localize`:@@com_rented:Rented`;
		} else if (status === UnitStatus.STARTING) {
			return $localize`:@@com_starting:Starting`;
		} else if (status === UnitStatus.VACANT) {
			return $localize`:@@com_vacant:Vacant`;
		}
	}

	getButtonStylesForStatus(status) {
		let colorClass = '';
		if (status === UnitStatus.EXPIRING) {
			colorClass = 'rf-tag-blue';
		} else if (status === UnitStatus.RENTED) {
			colorClass = 'rf-tag-green';
		} else if (status === UnitStatus.STARTING) {
			colorClass = 'rf-tag-orange';
		} else if (status === UnitStatus.VACANT) {
			colorClass = 'rf-tag-red';
		}
		return colorClass;
	}

	getUnitName(property: Property, unitId: string, truncation: number = 0, fallback?: string): string {
		if (!property) {
			return fallback || '-';
		}

		if (!property || !property.units[unitId]) {
			return fallback || '-';
		}

		const unitName = property.units[unitId].name;

		if (truncation > 0 && unitName.length > truncation) {
			return unitName.substr(0, truncation - 1) + '…';
		} else {
			return unitName;
		}
	}

	public getUnitIdsWithActiveAndExpiringLeases(propertyId: string, leases: Lease[]): Set<string> {
		const activeLeasesInProperty = this.leaseService.getPropertyActiveLeases(propertyId, leases);
		const unitIds: string[] = activeLeasesInProperty.map(lease => lease.unitId || lease.propertyId);
		return new Set(unitIds);
	}

	public getUnitIdsWithActiveLeases(propertyId: string, leases: Lease[]): Set<string> {
		const activeLeasesInProperty = this.leaseService.getActiveLeasesForUnitStatus(leases, propertyId);
		const unitIds: string[] = activeLeasesInProperty.map(lease => lease.unitId || lease.propertyId);
		return new Set(unitIds);
	}

	public getUnitIdsWithStartingLease(propertyId: string, leases: Lease[]): Set<string> {
		const futureLeases = this.leaseService.getFutureLeasesForUnitStatus(leases, propertyId);
		const unitIds = futureLeases.map(lease => lease.unitId);
		return new Set(unitIds);
	}

	public getUnitIdsWithExpiringLease(propertyId: string, leases: Lease[]): Set<string> {
		const expiringLeases = this.leaseService.getExpiringLeasesForUnitStatus(leases, propertyId);
		const unitIds = expiringLeases.filter(lease => lease.unitId).map(lease => lease.unitId);
		return new Set(unitIds);
	}

	public getUnitIdsWithLeases(propertyId: string, leases: Lease[]) {
		const activeLeases = this.leaseService.getPropertyActiveLeases(propertyId, leases);
		const futureLeases = this.leaseService.getFutureLeasesForUnitStatus(leases, propertyId);
		const unitIds: string[] = activeLeases.concat(futureLeases).map(lease => lease.unitId);
		return new Set(unitIds);
	}

	public getUnitIdsWithBookings(propertyId: string, bookings: Booking[]): Set<string> {
		const activeBookings = this.bookingService.getActiveConfirmedBookingsForUnitStatus(bookings, propertyId);
		const futureBookings = this.bookingService.getFutureConfirmedBookingsForUnitStatus(bookings, propertyId);

		const unitIds: string[] = activeBookings.concat(futureBookings).map(booking => booking.unitId);
		return new Set(unitIds);
	}

	public getUnitsWithLease(property: Property, leases: Lease[]): EstelleUnit[] {
		// A booked unit is a unit that has active or future booking
		return Object.values(property.units).filter(unit =>
			this.getUnitIdsWithLeases(property.id, leases).has(unit.id)
		);
	}

	public getVacantUnits(property: Property, leases: Lease[], bookings: Booking[]): EstelleUnit[] {
		// A vacant unit is a unit that has no active lease and no lease starting in the next 30 days
		const bookedUnits = this.getBookedUnits(property, bookings);
		const unitsWithLease = this.getUnitsWithLease(property, leases);

		return Object.values(property.units).filter(
			unit =>
				unitsWithLease.filter(lease => lease.propertyId === property.id).length === 0 &&
				bookedUnits.filter(bookedUnit => bookedUnit.id === unit.id).length === 0
		);
	}

	public getRentedUnits(property: Property, leases: Lease[]): EstelleUnit[] {
		// A rented unit is a unit that has an active lease
		return Object.values(property.units).filter(unit =>
			this.getUnitIdsWithActiveLeases(property.id, leases).has(unit.id)
		);
	}

	public getExpiringUnits(property: Property, leases: Lease[]): EstelleUnit[] {
		// An expiring unit is a unit that will have no active lease in the upcoming 30 days
		return Object.values(property.units).filter(unit =>
			this.getUnitIdsWithExpiringLease(property.id, leases).has(unit.id)
		);
	}

	public getStartingUnits(property: Property, leases: Lease[]): EstelleUnit[] {
		// A starting unit is a unit that has no active lease but will have one in the next 30 days
		return Object.values(property.units).filter(unit =>
			this.getUnitIdsWithStartingLease(property.id, leases).has(unit.id)
		);
	}

	public getBookedUnits(property: Property, bookings: Booking[]): EstelleUnit[] {
		// A booked unit is a unit that has active or future booking
		return Object.values(property.units).filter(unit =>
			this.getUnitIdsWithBookings(property.id, bookings).has(unit.id)
		);
	}

	// Version 2 - Backend pagination & filtering

	public getUnitsPaginated(pageSize: number): Promise<UnitPageModelUI> {
		return combineLatest([this.store.select(selectFiltersEntities), this.store.select(selectUnitPagesEntities)])
			.pipe(
				take(1),
				switchMap(
					([storeFilters, storeUnitPages]: [
						Dictionary<FiltersStoreModel>,
						Dictionary<UnitPageModelStore>
					]) => {
						if (storeFilters && storeUnitPages && storeUnitPages[storeFilters['units']?.pageIndex || 0]) {
							return combineLatest([
								of(storeFilters),
								of(storeUnitPages),
								this.store.select(selectPropertyDictionaryState)
							]);
						} else {
							return combineLatest([of(null), of(null), of(null)]);
						}
					}
				),
				switchMap(
					([storeFilters, storeUnitPages, propertiesDict]: [
						Dictionary<FiltersStoreModel>,
						Dictionary<UnitPageModelStore>,
						Dictionary<Property>
					]) => {
						if (storeFilters !== null && storeUnitPages && propertiesDict) {
							const sortedUnitIds = storeUnitPages[storeFilters['units']?.pageIndex || 0].unitIds;

							const allProperties: Property[] = Object.values(propertiesDict);

							let unitDisplayData: UnitDisplayData[] = [];
							for (let i = 0; i < sortedUnitIds.length; i++) {
								const p = allProperties.find(property => {
									const unitIds = Object.keys(property.units);
									for (let j = 0; j < unitIds.length; j++) {
										if (sortedUnitIds[i] === unitIds[j]) {
											return true;
										}
									}
									return false;
								});

								const u = Object.values(p.units).find(unit => unit.id === sortedUnitIds[i]);

								unitDisplayData.push({
									property: p,
									unit: u
								});
							}

							console.log(
								'[SERVICE] page foud and valid => return it: (' +
									(storeFilters['units']?.pageIndex || 0) +
									' - ' +
									pageSize +
									')'
							);
							return of({
								units: unitDisplayData,
								totalItems: storeUnitPages[storeFilters['units']?.pageIndex || 0].totalItems || 0,
								filteredItems: storeUnitPages[storeFilters['units']?.pageIndex || 0].filteredItems || 0
							});
						} else {
							console.log('[SERVICE] page NOT foud => return null');
							return of(null);
						}
					}
				),
				take(1)
			)
			.toPromise();
	}

	public loadUnitsPage(pageSize: number) {
		this.store
			.select(selectFiltersEntities)
			.pipe(take(1))
			.subscribe(storeFilters => {
				console.log(
					'[SERVICE] dispatch LOAD page ' +
						(storeFilters['units']?.pageIndex || 0) +
						' ' +
						pageSize +
						' ' +
						JSON.stringify(storeFilters['units']?.sort || {}) +
						' -->'
				);
				this.store.dispatch(
					loadUnitsPaginatedAction({
						page: storeFilters['units']?.pageIndex || 0,
						pageSize: pageSize,
						filters: storeFilters['units']?.filters || {},
						sort: storeFilters['units']?.sort || {}
					})
				);
			});
	}

	public getChannelManagerUnitsPaginated(pageSize: number): Promise<UnitPageModelUI> {
		return combineLatest([
			this.store.select(selectFiltersEntities),
			this.store.select(selectChannelManagerUnitPagesEntities)
		])
			.pipe(
				take(1),
				switchMap(
					([storeFilters, storeUnitPages]: [
						Dictionary<FiltersStoreModel>,
						Dictionary<UnitPageModelStore>
					]) => {
						if (
							storeFilters &&
							storeUnitPages &&
							storeUnitPages[storeFilters['cm-units']?.pageIndex || 0]
						) {
							return combineLatest([
								of(storeFilters),
								of(storeUnitPages),
								this.store.select(selectPropertyDictionaryState)
							]);
						} else {
							return combineLatest([of(null), of(null), of(null)]);
						}
					}
				),
				switchMap(
					([storeFilters, storeUnitPages, propertiesDict]: [
						Dictionary<FiltersStoreModel>,
						Dictionary<UnitPageModelStore>,
						Dictionary<Property>
					]) => {
						if (storeFilters !== null && storeUnitPages && propertiesDict) {
							const sortedUnitIds = storeUnitPages[storeFilters['cm-units']?.pageIndex || 0].unitIds;
							const availabilities =
								storeUnitPages[storeFilters['cm-units']?.pageIndex || 0].availabilities;

							const allProperties: Property[] = Object.values(propertiesDict);

							let unitDisplayData: UnitDisplayData[] = [];
							for (let i = 0; i < sortedUnitIds.length; i++) {
								const p = allProperties.find(property => {
									const unitIds = Object.keys(property.units);
									for (let j = 0; j < unitIds.length; j++) {
										if (sortedUnitIds[i] === unitIds[j]) {
											return true;
										}
									}
									return false;
								});

								const u = Object.values(p.units).find(unit => unit.id === sortedUnitIds[i]);

								unitDisplayData.push({
									property: p,
									unit: u,
									availabilities: availabilities?.[u.id]
								});
							}

							console.log(
								'[SERVICE] page foud and valid => return it: (' +
									(storeFilters['cm-units']?.pageIndex || 0) +
									' - ' +
									pageSize +
									')'
							);
							return of({
								units: unitDisplayData,
								availabilities: availabilities,
								totalItems: storeUnitPages[storeFilters['cm-units']?.pageIndex || 0].totalItems || 0,
								filteredItems:
									storeUnitPages[storeFilters['cm-units']?.pageIndex || 0].filteredItems || 0
							});
						} else {
							console.log('[SERVICE] page NOT foud => return null');
							return of(null);
						}
					}
				),
				take(1)
			)
			.toPromise();
	}

	public loadChannelManagerUnitsPage(pageSize: number) {
		this.store
			.select(selectFiltersEntities)
			.pipe(take(1))
			.subscribe(storeFilters => {
				console.log(
					'[SERVICE] dispatch LOAD page ' +
						(storeFilters['cm-units']?.pageIndex || 0) +
						' ' +
						pageSize +
						' ' +
						JSON.stringify(storeFilters['cm-units']?.sort || {}) +
						' -->'
				);
				this.store.dispatch(
					loadChannelManagerUnitsPaginatedAction({
						page: storeFilters['cm-units']?.pageIndex || 0,
						pageSize: pageSize,
						filters: storeFilters['cm-units']?.filters || {},
						sort: storeFilters['cm-units']?.sort || {}
					})
				);
			});
	}

	public getChannelManagerSyncUnitsPaginated(pageSize: number): Promise<UnitPageModelUI> {
		return combineLatest([
			this.store.select(selectFiltersEntities),
			this.store.select(selectChannelManagerSyncUnitPagesEntities)
		])
			.pipe(
				take(1),
				switchMap(
					([storeFilters, storeSyncUnitPages]: [
						Dictionary<FiltersStoreModel>,
						Dictionary<UnitPageModelStore>
					]) => {
						if (
							storeFilters &&
							storeSyncUnitPages &&
							storeSyncUnitPages[storeFilters['cm-sync-units']?.pageIndex || 0]
						) {
							return combineLatest([
								of(storeFilters),
								of(storeSyncUnitPages),
								this.store.select(selectPropertyDictionaryState)
							]);
						} else {
							return combineLatest([of(null), of(null), of(null)]);
						}
					}
				),
				switchMap(
					([storeFilters, storeSyncUnitPages, propertiesDict]: [
						Dictionary<FiltersStoreModel>,
						Dictionary<UnitPageModelStore>,
						Dictionary<Property>
					]) => {
						if (storeFilters !== null && storeSyncUnitPages && propertiesDict) {
							const sortedUnitIds =
								storeSyncUnitPages[storeFilters['cm-sync-units']?.pageIndex || 0].unitIds;
							const availabilities =
								storeSyncUnitPages[storeFilters['cm-sync-units']?.pageIndex || 0].availabilities;

							const allProperties: Property[] = Object.values(propertiesDict);

							let unitDisplayData: UnitDisplayData[] = [];
							for (let i = 0; i < sortedUnitIds.length; i++) {
								const p = allProperties.find(property => {
									const unitIds = Object.keys(property.units);
									for (let j = 0; j < unitIds.length; j++) {
										if (sortedUnitIds[i] === unitIds[j]) {
											return true;
										}
									}
									return false;
								});

								const u = Object.values(p.units).find(unit => unit.id === sortedUnitIds[i]);

								unitDisplayData.push({
									property: p,
									unit: u,
									availabilities: availabilities?.[u.id]
								});
							}

							console.log(
								'[SERVICE] page foud and valid => return it: (' +
									(storeFilters['cm-sync-units']?.pageIndex || 0) +
									' - ' +
									pageSize +
									')'
							);
							return of({
								units: unitDisplayData,
								totalItems:
									storeSyncUnitPages[storeFilters['cm-sync-units']?.pageIndex || 0].totalItems || 0,
								filteredItems:
									storeSyncUnitPages[storeFilters['cm-sync-units']?.pageIndex || 0].filteredItems || 0
							});
						} else {
							console.log('[SERVICE] page NOT foud => return null');
							return of(null);
						}
					}
				),
				take(1)
			)
			.toPromise();
	}

	public loadChannelManagerSyncUnitsPage(pageSize: number) {
		this.store
			.select(selectFiltersEntities)
			.pipe(take(1))
			.subscribe(storeFilters => {
				console.log(
					'[SERVICE] dispatch LOAD page ' +
						(storeFilters['cm-sync-units']?.pageIndex || 0) +
						' ' +
						pageSize +
						' ' +
						JSON.stringify(storeFilters['cm-sync-units']?.sort || {}) +
						' -->'
				);
				this.store.dispatch(
					loadChannelManagerSyncUnitsPaginatedAction({
						page: storeFilters['cm-sync-units']?.pageIndex || 0,
						pageSize: pageSize,
						filters: storeFilters['cm-sync-units']?.filters || {},
						sort: storeFilters['cm-sync-units']?.sort || {}
					})
				);
			});
	}
}
