<div mat-dialog-content>
	<input type="text" style="display: none" cdkFocusInitial />

	<div fxLayout="column" fxLayoutAlign="flex-start center" class="oneshot-top-section">
		<img class="premium-img" src="./assets/img/rm_premium_icon.png" />

		<p
			*ngIf="
				!data.origin ||
				(data.origin !== 'mock_creation_blocked' &&
					data.origin !== 'mass_import_blocked' &&
					data.origin !== 'finish_plan')
			"
			class="semi-bold-25 color-white no-margin"
			i18n="@@blocking_popup_choose_plan_title"
		>
			<span class="color-orange">Choose</span>&nbsp;Plan
		</p>
		<p
			*ngIf="data?.origin && (data.origin === 'mock_creation_blocked' || data.origin === 'mass_import_blocked')"
			class="semi-bold-25 color-white no-margin"
			i18n="@@blocking_popup_upgrade_plan_title"
		>
			<span class="color-orange">Upgrade</span>&nbsp;Plan
		</p>
		<p
			*ngIf="data?.origin && data.origin === 'finish_plan'"
			class="semi-bold-25 color-white no-margin"
			i18n="@@dashboard_complete_subscription"
		>
			<span class="color-orange">Finalize</span>&nbsp; Subscription
		</p>

		<div
			*ngIf="data.origin && (data.origin === 'mock_creation_blocked' || data.origin === 'mass_import_blocked')"
			fxLayout="row"
			fxLayoutAlign="flex-end center"
			style="width: 100%; margin: -70px 30px 0px 0px"
		>
			<img
				src="./assets/img/close.svg"
				class="rf-is-clickable"
				(click)="closeClick()"
				style="width: 15px; height: 15px"
			/>
		</div>
	</div>

	<p
		*ngIf="
			!data.origin ||
			(data.origin !== 'mock_creation_blocked' &&
				data.origin !== 'mass_import_blocked' &&
				data.origin !== 'finish_plan')
		"
		class="regular-15 text-center"
		style="margin: 20px 0px 0px 0px"
		i18n="@@blocking_popup_choose_plan_desc"
	>
		Hi&nbsp;<span class="semi-bold-15">{{ getLandlordName() }}</span
		>, your account does not adhere to your current plan restrictions.<br />In order to continue using Estelle you
		need to&nbsp;<span class="semi-bold-15">select a plan</span>&nbsp;below<br />or schedule a
		<span class="semi-bold-15">demo</span> via our
		<span class="semi-bold-15"><a href="https://the-roommate.com/en/bookademo" target="_blank">website</a></span>
		for a <span class="semi-bold-15">custom plan</span>:
	</p>

	<p
		*ngIf="data.origin && data.origin === 'mock_creation_blocked'"
		class="regular-15 text-center"
		style="margin: 20px 0px 0px 0px"
		i18n="@@blocking_popup_choose_plan_desc_2"
	>
		Hi&nbsp;<span class="semi-bold-15">{{ getLandlordName() }}</span
		>, you have reached the maximum number of units<br />in your subscription plan,&nbsp;<span
			class="semi-bold-15 color-green"
			>update</span
		>&nbsp;your plan to add more.
	</p>
	<p
		*ngIf="data.origin && data.origin === 'mass_import_blocked'"
		class="regular-15 text-center"
		style="margin: 20px 0px 0px 0px"
		i18n="@@blocking_popup_choose_plan_desc_3"
	>
		Hi&nbsp;<span class="semi-bold-15">{{ getLandlordName() }}</span
		>, unfortunately this feature is not available<br />in your current subscription plan.&nbsp;<span
			class="semi-bold-15 color-green"
			>Update</span
		>&nbsp;your plan to continue.
	</p>

	<div class="side-padded">
		<div class="side-padded">
			<div *ngIf="isAdmin" fxLayout="row wrap" fxLayoutAlign="center flex-start" class="v-spacing-20">
				<button
					*ngIf="!data.origin || data.origin !== 'finish_plan'"
					[disabled]="numOfUnits > premiumMaxUnits"
					matTooltip="Your current number of units is {{ numOfUnits }}. You can only add up to {{
						premiumMaxUnits
					}} units with this plan. Consider upgrading to the Professional or custom plan."
					i18n-matTooltip="@@tooltip_blocked_premium_purchase"
					[matTooltipDisabled]="numOfUnits <= premiumMaxUnits"
					mat-button
					class="button-plan plan_premium"
					(click)="btnPermiumClick()"
					style="margin: 5px"
				>
					<span class="medium-wide-20 color-white" style="font-weight: 1000">Premium</span>&nbsp;
					<span class="regular-20 color-white">plan</span>
				</button>
				<button
					*ngIf="!data.origin || data.origin !== 'finish_plan'"
					[disabled]="numOfUnits > professionalMaxUnits"
					matTooltip="Your current number of units is {{ numOfUnits }}. You can only add up to {{
						professionalMaxUnits
					}} units with this plan. Consider upgrading to a custom plan."
					i18n-matTooltip="@@tooltip_blocked_professional_purchase"
					[matTooltipDisabled]="numOfUnits <= premiumMaxUnits"
					mat-button
					class="button-plan plan-professional"
					(click)="btnProfessionalClick()"
					style="margin: 5px"
				>
					<span class="medium-wide-20 color-white" style="font-weight: 1000">Professional</span>&nbsp;
					<span class="regular-20 color-white">plan</span>
					<img class="img-crown" src="./assets/img/rm_icon_crown.png" />
				</button>
				<button
					*ngIf="data.origin && data.origin === 'finish_plan'"
					[disabled]="numOfUnits > professionalMaxUnits"
					matTooltip="Your current number of units is {{ numOfUnits }}. You can only add up to {{
						professionalMaxUnits
					}} units with this plan. Consider upgrading to a custom plan."
					i18n-matTooltip="@@tooltip_blocked_professional_purchase"
					[matTooltipDisabled]="numOfUnits <= premiumMaxUnits"
					mat-button
					class="button-plan plan-professional"
					(click)="finalizeSubscriptionClick()"
					style="margin: 5px"
				>
					<span class="medium-wide-20 color-white" i18n="@@dashboard_go_to_checkout">Go to checkout</span
					>&nbsp;
					<img class="img-crown" src="./assets/img/rm_icon_crown.png" />
				</button>
			</div>

			<p
				*ngIf="!isAdmin"
				class="regular-15 text-center"
				style="margin: 20px 0px 0px 0px"
				i18n="@@blocking_popup_ask_your_admin_not_admin"
			>
				Ask you <span class="semi-bold-15 color-1">administrator</span> to upgrade the subscription plan
			</p>
		</div>
	</div>

	<div
		*ngIf="
			!data.origin || (data.origin !== 'mock_creation_blocked' && data.origin !== 'mass_import_blocked');
			else separatorSpace
		"
		fxLayout="row"
		fxLayoutAlign="center flex-start"
		style="margin-top: 20px; margin-bottom: 20px"
	>
		<p
			*ngIf="!data.origin || data.origin !== 'finish_plan'"
			class="regular-12 no-margin color-grey rf-is-clickable rf-is-underlined"
			style="font-style: italic"
			(click)="logout()"
			i18n="@@com_or_click_here_to_logout"
		>
			Or click here to logout
		</p>
		<p
			*ngIf="data.origin && data.origin === 'finish_plan'"
			class="regular-12 no-margin color-grey rf-is-clickable rf-is-underlined"
			style="font-style: italic"
			(click)="downgradeRequestedPlanClick()"
			i18n="@@com_or_click_here_to_downgrade_plan"
		>
			Or click here to downgrade your plan
		</p>
	</div>
	<ng-template #separatorSpace>
		<div style="height: 30px; width: 100%"></div>
	</ng-template>
</div>
