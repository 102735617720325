<ng-snotify></ng-snotify>

<ngx-spinner
	*ngIf="!mobile"
	name="mainSpinner"
	template="<img  class='spinner-logo' src='./assets/img/RYN-logo.svg' />"
>
	<p class="loading-text">{{ currentLoadingText | async }}</p>
</ngx-spinner>
<ngx-spinner
	*ngIf="mobile"
	name="mainSpinner"
	template="<img  class='spinner-logo-mobile' src='./assets/img/RYN-logo.svg' />"
>
	<p class="loading-text-mobile">{{ currentLoadingText | async }}</p>
</ngx-spinner>

<router-outlet></router-outlet>
