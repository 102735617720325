export const translations = {
	defaultErrorMessage: $localize`:@@ctr_defaultError:"Invalid status, please contact us or try again."`,
	paymentSettings: {
		entityType: {
			NATURAL_PERSON: $localize`:@@ctr_entityNatural:Natural Person`,
			LEGAL_BUSINESS: $localize`:@@ctr_entityLegal:Legal Business`,
			SOLE_TRADER: $localize`:@@ctr_entitySoleTrader:Sole Trader`
		}
	},
	months: [
		$localize`:@@ctr_month1:JAN`,
		$localize`:@@ctr_month2:FEB`,
		$localize`:@@ctr_month3:MAR`,
		$localize`:@@ctr_month4:APR`,
		$localize`:@@ctr_month5:MAY`,
		$localize`:@@ctr_month6:JUN`,
		$localize`:@@ctr_month7:JUL`,
		$localize`:@@ctr_month8:AUG`,
		$localize`:@@ctr_month9:SEP`,
		$localize`:@@ctr_month10:OCT`,
		$localize`:@@ctr_month11:NOV`,
		$localize`:@@ctr_month12:DEC`
	],

	calendar: {
		past_lease: $localize`:@@ctr_cal_past_lease:Past Lease`,
		current_lease: $localize`:@@ctr_cal_current_lease:Current Lease`,
		booking_request: $localize`:@@ctr_cal_book_req:Booking Request`,
		blocked_interval_booking: $localize`:@@ctr_cal_block_int_book:Blocked Interval`,
		coming_lease: $localize`:@@ctr_cal_coming_lease:Upcoming Lease`
	},

	months_full: [
		$localize`:@@ctr_month_full_1:JANUARY`,
		$localize`:@@ctr_month_full_2:FEBRUARY`,
		$localize`:@@ctr_month_full_3:MARCH`,
		$localize`:@@ctr_month_full_4:APRIL`,
		$localize`:@@ctr_month_full_5:MAY`,
		$localize`:@@ctr_month_full_6:JUNE`,
		$localize`:@@ctr_month_full_7:JULY`,
		$localize`:@@ctr_month_full_8:AUGUST`,
		$localize`:@@ctr_month_full_9:SEPTEMBER`,
		$localize`:@@ctr_month_full_10:OCTOBER`,
		$localize`:@@ctr_month_full_11:NOVEMBER`,
		$localize`:@@ctr_month_full_12:DECEMBER`
	],

	months_full_lower: [
		$localize`:@@ctr_month_full_lw_1:January`,
		$localize`:@@ctr_month_full_lw_2:February`,
		$localize`:@@ctr_month_full_lw_3:March`,
		$localize`:@@ctr_month_full_lw_4:April`,
		$localize`:@@ctr_month_full_lw_5:May`,
		$localize`:@@ctr_month_full_lw_6:June`,
		$localize`:@@ctr_month_full_lw_7:July`,
		$localize`:@@ctr_month_full_lw_8:August`,
		$localize`:@@ctr_month_full_lw_9:September`,
		$localize`:@@ctr_month_full_lw_10:October`,
		$localize`:@@ctr_month_full_lw_11:November`,
		$localize`:@@ctr_month_full_lw_12:December`
	],

	daysOfWeekShort: [
		$localize`:@@ctr_week_day_1:Sun`,
		$localize`:@@ctr_week_day_2:Mon`,
		$localize`:@@ctr_week_day_3:Tue`,
		$localize`:@@ctr_week_day_4:Wed`,
		$localize`:@@ctr_week_day_5:Thu`,
		$localize`:@@ctr_week_day_6:Fri`,
		$localize`:@@ctr_week_day_7:Sat`
	],

	dashboard_labels: [
		$localize`:@@ctr_dash_label_1:Home`,
		$localize`:@@ctr_dash_label_2_new:Portfolio`,
		$localize`:@@ctr_dash_label_3:Tenants`,
		$localize`:@@ctr_dash_label_4_new:Finance`,
		$localize`:@@ctr_dash_label_5:Management`,
		$localize`:@@ctr_dash_label_6:Chat`,
		$localize`:@@ctr_dash_label_8:Channel Manager`
	],

	dashboard_labels_caps: [
		$localize`:@@ctr_dash_label_cap_1:HOME`,
		$localize`:@@ctr_dash_label_cap_2_new:PORTFOLIO`,
		$localize`:@@ctr_dash_label_cap_3:TENANTS`,
		$localize`:@@ctr_dash_label_cap_4_new:FINANCE`,
		$localize`:@@ctr_dash_label_cap_5:MANAGEMENT`,
		$localize`:@@ctr_dash_label_cap_6:CHAT`,
		$localize`:@@ctr_dash_label_cap_8:CHANNEL MANAGER`
	],

	dashboard_menu_extensions: [
		[],
		[
			$localize`:@@ctr_dash_menu_ext_2_1:Properties`,
			$localize`:@@ctr_dash_menu_ext_2_2:Availability`,
			$localize`:@@ctr_dash_menu_ext_3_3:Leases`,
			$localize`:@@ctr_dash_menu_ext_2_3:Bookings`,
			$localize`:@@ctr_dash_menu_ext_2_4:Reports`
		],
		[],
		[$localize`:@@ctr_dash_menu_ext_4_1:Payments`, $localize`:@@ctr_dash_menu_ext_4_2:Wallet`],
		[
			$localize`:@@ctr_dash_menu_ext_5_1:Owners`,
			$localize`:@@ctr_dash_menu_ext_5_2:Maintenances`,
			$localize`:@@ctr_dash_menu_ext_5_3:Calendar`
		],
		[],
		[],
		[]
	],

	link_copied: {
		message: $localize`:@@ctr_link_copied_msg:Share it wherever you prefer!`,
		title: $localize`:@@ctr_link_copied_title:Link copied!`
	},

	email_copied: {
		message: $localize`:@@ctr_email_copied_msg:Paste it wherever you prefer!`,
		title: $localize`:@@ctr_email_copied_title:Email copied!`
	},

	file_sent: {
		message: $localize`:@@ctr_file_sent_msg:The file has been sent in the chat`,
		title: $localize`:@@ctr_file_sent_title:File sent!`
	},

	events_cats: {
		leases: $localize`:@@ctr_events_cats_lease:Leases`,
		check_ins_outs: $localize`:@@ctr_events_cats_check_ins_outs:Check-in Check-out`,
		lease_reg: $localize`:@@ctr_events_cats_lease_reg:Lease registration`,
		tenants: $localize`:@@ctr_events_cats_tenants:Tenants`,
		stamp_duty: $localize`:@@ctr_events_cats_stamp_duty:Rental tax`
	},
	table: {
		wallet: {
			headers: {
				Month: $localize`:@@ctr_table_waller_head_month:Month`,
				Status: $localize`:@@ctr_table_waller_head_status:Status`,
				'Paid Date': $localize`:@@ctr_table_waller_head_paid_date:Paid Date`,
				Category: $localize`:@@ctr_table_waller_head_category:Category`,
				Who: $localize`:@@ctr_table_waller_head_who:Who`,
				Amount: $localize`:@@ctr_table_waller_head_amount:Amount`,
				Actions: $localize`:@@ctr_table_waller_head_actions:Actions`
			}
		}
	},

	toast: {
		mobile_version_title: $localize`:@@ctr_toast_mobile_version:Mobile version`,
		mobile_version_msg: $localize`:@@ctr_toast_mobile_version_title:For an optimal experience with all the features available, we invite you to use our application in the desktop version.`,
		check_fields: $localize`:@@ctr_toast_check_fields:Check fields`,
		enter_valid_date: $localize`:@@ctr_toast_enter_valid_date:Enter a valid date`,
		enter_missing_values: $localize`:@@ctr_toast_enter_missing_values:Enter the missing values`,
		enter_expense_title: $localize`:@@ctr_toast_enter_expense_title:Add a title`,
		insert_valid_date: $localize`:@@ctr_toast_insert_valid_date:Insert valid dates`,
		tenant_added_msg: $localize`:@@ctr_toast_tenant_added_msg:You will now find them in the tenants list`,
		tenant_added_title: $localize`:@@ctr_toast_tenant_added_title:Tenant added!`,
		owner_added_msg: $localize`:@@ctr_toast_owner_added_msg:You will now find them in the owners list`,
		owner_added_title: $localize`:@@ctr_toast_owner_added_title:Owner added!`,
		owner_updated_title: $localize`:@@ctr_toast_owner_updated_title:Owner updated!`,
		tenant_edit_msg: $localize`:@@ctr_toast_tenant_edit_msg:Info has been saved`,
		tenant_edit_title: $localize`:@@ctr_toast_tenant_edit_title:Tenant edited!`,
		tenant_accepted_msg: $localize`:@@ctr_toast_tenant_accepted_msg:Now you can create a lease`,
		tenant_accepted_title: $localize`:@@ctr_toast_tenant_accepted_title:Tenant accepted!`,
		prop_min_age_error: $localize`:@@prop_min_age_error:Select a maximum age higher than the minimum age`,
		maintenance_added_msg: $localize`:@@ctr_toast_maintenance_added_msg:Now you'll see it in the details of the property`,
		maintenance_added_title: $localize`:@@ctr_toast_maintenance_added_title:Maintenance created!`,
		maintenance_updated_msg: $localize`:@@ctr_toast_maintenance_updated_msg:See here its details`,
		maintenance_updated_title: $localize`:@@ctr_toast_maintenance_updated_title:Maintenance updated!`,
		chose_property: $localize`:@@ctr_toast_chose_property:Choose a property`,
		chose_unit: $localize`:@@ctr_toast_chose_unit:Choose a unit`,
		select_at_least_property: $localize`:@@ctr_toast_select_at_least_property:Select at least one property`,
		select_at_least_unit: $localize`:@@ctr_toast_select_at_least_unit:Select at least one unit`,
		lease_added_title: $localize`:@@ctr_toast_lease_added_title:Lease added!`,
		lease_added_msg: $localize`:@@ctr_toast_lease_added_msg:Now you'll find it in the leases list`,
		booking_added_title: $localize`:@@ctr_toast_lease_added_title:Booking added!`,
		booking_added_msg: $localize`:@@ctr_toast_lease_added_msg:Now you'll find it in the bookings list`,
		property_added_title: $localize`:@@ctr_toast_property_added_title:Property added!`,
		property_edit_title: $localize`:@@ctr_toast_property_edit_title:Property edited!`,
		property_edit_msg: ``,
		property_added_msg: ``,
		saved: $localize`:@@ctr_toast_saved:Changes saved`,
		settings_saved: $localize`:@@ctr_toast_settings_saved:Settings have been saved!`,
		invite_sent_successfully: $localize`:@@ctr_toast_invite_sent_successfully:Invite sent successfully`,
		something_went_wrong: $localize`:@@ctr_toast_something_went_wrong:Something went wrong`,
		withdrawal_error_title: $localize`:@@ctr_toast_withdrawal_error_title:Withdrawal error`,
		withdrawal_error_desc: $localize`:@@ctr_toast_withdrawal_error_desc:Unsufficient wallet balance`,
		lease_terminate_successfully_title: $localize`:@@ctr_toast_lease_terminate_successfully_title:Operation succeded`,
		lease_terminate_successfully_msg: $localize`:@@ctr_toast_lease_terminate_successfully_msg:Lease will end in the selected date`,
		tenants_invited_successfully: $localize`:@@ctr_toast_tenants_invited_successfully:Tenants invited successfully`,
		select_at_least_tenant: $localize`:@@ctr_toast_select_at_least_tenant:Select at least a tenant`,
		message_sent_successfully: $localize`:@@ctr_toast_message_sent_successfully:Broadcast message sent successfully`,
		inv_fic_send_successfull: $localize`:@@ctr_toast_inv_fic_send_successfull:Sending successfull`,
		inv_complete_recv_data: $localize`:@@ctr_toast_inv_complete_recv_data:Fill in the receiver’s information`,
		inv_complete_owner_data: $localize`:@@ctr_toast_inv_complete_owner_data:Fill in the owner’s invoicing information`,
		inv_complete_owners_data: $localize`:@@ctr_toast_inv_complete_owners_data:Fill in the owners’ invoicing information`,
		inv_pick_type: $localize`:@@ctr_toast_inv_pick_type:Pick the Export type to proceed to the next step`,
		inv_not_valid: $localize`:@@ctr_toast_inv_not_valid:There are no payments to proceed with`,
		mark_as_paid_not_valid: $localize`:@@ctr_toast_mark_as_paid_not_valid:There are no payments to proceed with`,
		mark_as_paid_not_valid_amount: $localize`:@@ctr_toast_mark_as_paid_not_valid_amount:Choose a valid amount`,
		inv_progr_number: $localize`:@@ctr_toast_inv_progr_number:Choose a valid progressive number`,
		inv_select_payment: $localize`:@@ctr_toast_inv_select_payment:Pick at least an Expense`,
		feedback_sent_title: $localize`:@@ctr_toast_feedback_sent_title:Thank you!`,
		feedback_sent_message: $localize`:@@ctr_toast_feedback_sent_message:We will get back to you within 2 hours`,
		calendar_event_wrong_time_title: $localize`:@@ctr_toast_calendar_event_wrong_time_title:Check event times`,
		calendar_event_wrong_time_msg: $localize`:@@ctr_toast_calendar_event_wrong_time_msg:Starting time must be before ending time`,
		lease_minimum_amount: $localize`:@@ctr_toast_lease_minimum_amount:Rent amount must be at lease 2€`,
		report_gen_title: $localize`:@@ctr_toast_report_gen_title:Report requested!`,
		report_gen_msg: $localize`:@@ctr_toast_report_gen_msg:The report has been requested, you will get a notification to download it or find it on the Report list.`,
		report_gen_error_title: $localize`:@@ctr_toast_report_gen_error_title:An error occurred`,
		report_gen_error_msg: $localize`:@@ctr_toast_report_gen_error_msg:Something went wrong during the report request, please try again later`,
		mark_as_paid_multi_error_title: $localize`:@@ctr_toast_mark_as_paid_multi_error_title:An error occurred`,
		mark_as_paid_multi_error_msg: $localize`:@@ctr_toast_mark_as_paid_multi_error_msg:Something went wrong during the process, please try again later`,
		minimum_value: $localize`:@@ctr_toast_minimum_value:The minimum amount of a payment is 2€`,
		tenant_recovered_title: $localize`:@@ctr_toast_tenant_recovered_title:Tenant recovered`,
		tenant_recovered_msg: $localize`:@@ctr_toast_tenant_recovered_msg:You will find him/her back in the Tenants section`,
		type_a_message_to_send: $localize`:@@ctr_toast_type_a_message_to_send:Type a message to be able to send it`,
		add_missing_fields_to_send: $localize`:@@ctr_toast_add_missing_fields_to_send:Enter the missing fields to send the invitation`,
		units_limitation_reached_title: $localize`:@@ctr_toast_units_limitation_reached_title:Unable to proceed`,
		units_limitation_reached_desc: $localize`:@@ctr_toast_units_limitation_reached_desc:You reached the maximum number of units included in the free plan. Upgrade your plan now!`,
		units_limitation_reached_premium_desc: $localize`:@@ctr_toast_units_limitation_reached_premium_desc:You reached the maximum number of units included in the premium plan. Upgrade your plan now!`,
		units_limitation_reached_professional_desc: $localize`:@@ctr_toast_units_limitation_reached_professional_desc:You reached the maximum number of units included in the professional plan. Upgrade your plan now!`,
		password_recovery_title: $localize`:@@ctr_toast_password_recovery_title:Email sent!`,
		password_recovery_msg: $localize`:@@ctr_toast_password_recovery_msg:We sent you an email with a link to reset your password`,
		password_recovery_chosen_title: $localize`:@@ctr_toast_password_recovery_chosen_title:Password reset!`,
		password_recovery_chosen_msg: $localize`:@@ctr_toast_password_recovery_chosen_msg:Your password has been updated! Login now`,
		file_size_exceeded_title: $localize`:@@ctr_toast_file_size_exceeded_title:File skipped`,
		file_size_exceeded_desc: $localize`:@@ctr_toast_file_size_exceeded_desc:This file exceeds the maximum allowed size`,
		file_type_no_accept_title: $localize`:@@ctr_toast_file_type_no_accept_title:File type not match`,
		file_type_no_accept_desc: $localize`:@@ctr_toast_file_type_no_accept_desc:The file type is not an image`,
		checklist_copied_title: $localize`:@@ctr_toast_checklist_copied_title:Checklist copied`,
		checklist_copied_desc_A: $localize`:@@ctr_toast_checklist_copied_desc_A:You can find it in the selected tenant's details`,
		checklist_copied_desc_B: $localize`:@@ctr_toast_checklist_copied_desc_B:You can find it here`,
		units_same_name_title: $localize`:@@ctr_toast_units_same_name_title:Units' names`,
		units_same_name_desc: $localize`:@@ctr_toast_units_same_name_desc:Choose different names for the units`,

		passwordless_password_chosen_title: $localize`:@@ctr_toast_password_recovery_title:Password chosen!`,
		passwordless_password_chosen_msg: $localize`:@@ctr_toast_password_recovery_title:The password has been set for your account`,

		custom_logo_sent_title: $localize`:@@ctr_toast_custom_logo_sent_title:Thank you!`,
		custom_logo_sent_message: $localize`:@@ctr_toast_custom_logo_sent_message:We will set up your logo in your RoomMate Places platform`,

		invitation_sent_title: $localize`:@@ctr_toast_invitation_sent_title:Invitation sent!`,
		invitation_sent_message: $localize`:@@ctr_toast_invitation_sent_message:The tenant has received the invitation to Places`,
		invitation_multiple_sent_title: $localize`:@@ctr_toast_invitation_multiple_sent_title:Invitation sent!`,
		invitation_multiple_sent_message: $localize`:@@ctr_toast_invitation_multiple_sent_message:The selected tenants have received the invitation to Places`,
		invitation_sent_error_title: $localize`:@@ctr_toast_invitation_sent_error:An error occurred`,
		invitation_sent_error_message: $localize`:@@ctr_toast_invitation_error_message:Something went wrong while sending the invite, please try again later`,

		invitation_already_sent_title: $localize`:@@ctr_toast_invitation_already_sent_title:Setup complete`,
		invitation_already_sent_message: $localize`:@@ctr_toast_invitation_already_sent_message:The tenant has already set up Places`,

		places_setup_needed_first_title: $localize`:@@ctr_toast_invitation_already_inv_required:Invitation required`,
		places_setup_needed_first_message: $localize`:@@ctr_toast_invitation_already_inv_required:Invite the tenant to RoomMate Places`,

		e_payments_already_setup_title: $localize`:@@ctr_toast_invitation_already_sent_title:Setup complete`,
		e_payments_already_setup_message: $localize`:@@ctr_toast_invitation_already_configured_message:The tenant has already set up e-Payments`,

		transaction_add_missing_data_1: $localize`:@@ctr_toast_transaction_add_missing_data_1:Please select a category to proceed`,
		transaction_add_missing_data_2: $localize`:@@ctr_toast_transaction_add_missing_data_2:Please select an assignee to proceed`,
		transaction_add_missing_data_3: $localize`:@@ctr_toast_transaction_add_missing_data_3:Please select a property to proceed`,

		get_csv_string_msg: $localize`:@@get_tenant_csv_string_msg:Export started`,
		get_csv_string_title: $localize`:@@get_tenant_csv_string_title:Download will be starting soon`,

		booking_delete_successfully_msg: $localize`:@@booking_delete_successfully_msg:Successfully deleted`,
		not_admin_so_cant_proceed: $localize`:@@ctr_not_admin_so_cant_proceed:You reached your subscription quota limit. Ask your administrator to upgrade the plan to proceed`,
		landlord_updated_error_msg: $localize`:@@ctr_toast_landlord_updated_msg:We are going to try to save your changes again. Please reload the page, if this error continues`
	},

	fic: {
		error_cant_send: $localize`:@@ctr_fic_err_1:Sending failed`,
		error_cant_send_partially: $localize`:@@ctr_fic_err_2:Sending partially failed`,
		error_wrong_credentials: $localize`:@@ctr_fic_err_3:Wrong credentials. Check in the settings section your UserId and your API Key of FattureInCloud`
	},

	gender: {
		male: $localize`:@@ctr_gender_1:Male`,
		female: $localize`:@@ctr_gender_2:Female`
	},

	selector: {
		propertyTitle: $localize`:@@ctr_selector_p_title:PROPERTY`,
		propertyDesc: $localize`:@@ctr_selector_p_desc:Select property`,
		tenantTitle: $localize`:@@ctr_selector_t_title:TENANT`,
		tenantDesc: $localize`:@@ctr_selector_t_desc:Select tenant`,
		ownerTitle: $localize`:@@ctr_selector_ll_title:OWNER`,
		ownerDesc: $localize`:@@ctr_selector_ll_desc:Select owner`,
		unitTitle: $localize`:@@ctr_selector_unit_title:UNIT`,
		unitDesc: $localize`:@@ctr_selector_unit_desc:Select unit`,

		searchPlaceholder: $localize`:@@ctr_selector_search_placeholder:Search`,

		checklistTitle: $localize`:@@ctr_selector_ck_title:CHECKLIST`,
		checklistDesc: $localize`:@@ctr_selector_ck_desc:Select checklist`
	},

	share_confirm_message: $localize`:@@ctr_share_confirm_message:You are sharing a private file. Once shared, it will become public and visible by the tenants in the property`,

	user_deleted: $localize`:@@ctr_user_deleted:Tenant deleted`,
	property_deleted: $localize`:@@ctr_property_deleted:Property deleted`,

	properties: {
		share_file_label: $localize`:@@ctr_prop_share_file:SEND IN PROPERTY CHAT`,
		/*services: [
			$localize`:@@ctr_prop_serv_1:Air Conditioned`,
			$localize`:@@ctr_prop_serv_2:Private garden`,
			$localize`:@@ctr_prop_serv_3:Heating`,
			$localize`:@@ctr_prop_serv_4:Dishwasher`,
			$localize`:@@ctr_prop_serv_5:Tv`,
			$localize`:@@ctr_prop_serv_6:Washer`,
			$localize`:@@ctr_prop_serv_7:Wi-fi`,
			$localize`:@@ctr_prop_serv_8:Dryer`,
			$localize`:@@ctr_prop_serv_9:Parking area`
		],*/
		amenities: [
			{
				id: 'furnished',
				name: $localize`:@@ctr_prop_amenity_1:Furnished`
			},
			{
				id: 'elevator',
				name: $localize`:@@ctr_prop_amenity_2:Elevator`
			},
			{
				id: 'air_conditioner',
				name: $localize`:@@ctr_prop_amenity_3:Air conditioner`
			},
			{
				id: 'equipped_kitchen',
				name: $localize`:@@ctr_prop_amenity_4:Equipped kitchen`
			},
			{
				id: 'balcony_or_terrace',
				name: $localize`:@@ctr_prop_amenity_5:Balcony or terrace`
			},
			{
				id: 'pool_access',
				name: $localize`:@@ctr_prop_amenity_6:Pool access`
			},
			{
				id: 'parking_available',
				name: $localize`:@@ctr_prop_amenity_7:Parking available`
			},
			{
				id: 'pets_allowed',
				name: $localize`:@@ctr_prop_amenity_8:Pets allowed`
			},
			{
				id: 'smoking_allowed',
				name: $localize`:@@ctr_prop_amenity_9:Smoking allowed`
			},
			{
				id: 'washing_machine',
				name: $localize`:@@ctr_prop_amenity_10:Washing machine`
			},
			{
				id: 'dryer',
				name: $localize`:@@ctr_prop_amenity_11:Dryer`
			},
			{
				id: 'dishwasher',
				name: $localize`:@@ctr_prop_amenity_12:Dishwasher`
			},
			{
				id: 'oven',
				name: $localize`:@@ctr_prop_amenity_13:Oven`
			},
			{
				id: 'tv',
				name: $localize`:@@ctr_prop_amenity_14:Tv`
			},
			{
				id: 'handicap_accessible',
				name: $localize`:@@ctr_prop_amenity_15:Handicap accessible`
			},
			{
				id: 'heated_water_system',
				name: $localize`:@@ctr_prop_amenity_16:Heated water system`
			},
			{
				id: 'heating_system',
				name: $localize`:@@ctr_prop_amenity_17:Heating system`
			},
			{
				id: 'private_garden',
				name: $localize`:@@ctr_prop_amenity_18:Private garden`
			},
			{
				id: 'wi_fi',
				name: $localize`:@@ctr_prop_amenity_19:Wi-fi`
			},
			{
				id: 'living_room_forniture',
				name: $localize`:@@ctr_prop_amenity_20:Livinng Room Furniture`
			},
			{
				id: 'kitchen_utensils',
				name: $localize`:@@ctr_prop_amenity_21:Kitchen Utensils`
			},
			{
				id: 'cellar',
				name: $localize`:@@ctr_prop_amenity_22:Cellar`
			},
			{
				id: 'living_room',
				name: $localize`:@@ctr_prop_amenity_20:Living Room`
			}
		],
		room: $localize`:@@ctr_prop_room:Room`,
		bed: $localize`:@@ctr_prop_beb:Bed`
	},

	units: {
		amenities: [
			{
				id: 'furnished',
				name: $localize`:@@ctr_units_amenity_1:Furnished`
			},
			{
				id: 'air_conditioner',
				name: $localize`:@@ctr_units_amenity_2:Air conditioner`
			},
			{
				id: 'heating_system',
				name: $localize`:@@ctr_units_amenity_3:Heating system`
			},
			{
				id: 'private_bath',
				name: $localize`:@@ctr_units_amenity_4:Private bathroom`
			},
			{
				id: 'desk',
				name: $localize`:@@ctr_units_amenity_5:Desk`
			},
			{
				id: 'balcony_or_terrace',
				name: $localize`:@@ctr_units_amenity_6:Balcony or terrace`
			},
			{
				id: 'tv',
				name: $localize`:@@ctr_units_amenity_7:Tv`
			},
			{
				id: 'chest_drawers',
				name: $localize`:@@ctr_units_amenity_8:Chest drawers`
			},
			{
				id: 'storage',
				name: $localize`:@@ctr_units_amenity_9:Storage`
			},
			{
				id: 'wardrobe',
				name: $localize`:@@ctr_units_amenity_10:Wardrobe`
			},
			{
				id: 'bedroom_lock',
				name: $localize`:@@ctr_units_amenity_12:Bedroom Lock`
			},
			{
				id: 'private_kitchen',
				name: $localize`:@@ctr_units_amenity_11:Private kitchen`
			}
		],
		cloneSingleUnitCostsTooltip: $localize`:@@add_prop_clone_single_unit_costs_tooltip:Clone to the other single empty units`,
		cloneSharedUnitCostsTooltip: $localize`:@@add_prop_clone_shared_unit_costs_tooltip:Clone to the other shared empty units`
	},

	payments: {
		status: {
			overdue: $localize`:@@ctr_pay_status_overdue:Overdue`,
			open: $localize`:@@ctr_pay_status_open:Open`,
			close: $localize`:@@ctr_pay_status_close:Paid`,
			pending: $localize`:@@ctr_pay_status_pending:Pending`,
			failed: $localize`:@@ctr_pay_status_failed:Failed`,
			succeeded: $localize`:@@ctr_pay_status_succeeded:Succeeded`,
			upcoming: $localize`:@@ctr_pay_status_upcoming:Upcoming`
		},
		paymentMethods: {
			other: $localize`:@@ctr_pay_meth_other:Other method`,
			cash: $localize`:@@ctr_pay_meth_cash:Cash`,
			bankwire: $localize`:@@ctr_pay_meth_bankwire:Wire transfer`,
			deposit_in: $localize`:@@ctr_pay_meth_deposit_in:Deposit`,
			card: $localize`:@@ctr_pay_meth_card:Credit card`,
			mango_mandate: $localize`:@@ctr_pay_meth_roommate:RoomMate (Bank transfer)`,
			mango_credit_card: $localize`:@@ctr_pay_meth_roommate_cc:RoomMate (Credit card)`
		},
		tootltip: {
			forecast: $localize`:@@ctr_pay_forecasted_tooltip:Forecasted`,
			pending_by_deposit: $localize`:@@ctr_pay_pending_by_deposit_tooltip:Waiting for a balance/deposit return to be paid`,
			pending_by_epayment: $localize`:@@ctr_pay_pending_by_epayment_tooltip:A transaction is in process`
		}
	},

	maintenances: {
		categories: {
			electrical: $localize`:@@ctr_maintenances_cat_1:Electrical system`,
			plumbing: $localize`:@@ctr_maintenances_cat_2:Plumbing`,
			furniture: $localize`:@@ctr_maintenances_cat_3:Furniture`,
			windows: $localize`:@@ctr_maintenances_cat_4:Windows`,
			boiler: $localize`:@@ctr_maintenances_cat_5:Boiler`,
			heating: $localize`:@@ctr_maintenances_cat_6:Heating`,
			structural_renovation: $localize`:@@ctr_maintenances_cat_8:Structural Renovation`,
			small_renovation: $localize`:@@ctr_maintenances_cat_9:Small Renovation`,
			cleaning: $localize`:@@ctr_maintenances_cat_10:Cleaning`,
			other: $localize`:@@ctr_maintenances_cat_7:Other`
		}
	},

	noEndDate: $localize`:@@ctr_no_end_date:No end date`,

	checklistEmptyTitle: $localize`:@@ctr_ck_empty_title:LIST NAME`,

	checklistTemplate1: {
		name: $localize`:@@ctr_ct1_name:New LEASE`,
		taskNames: [
			$localize`:@@ctr_ct1_t_1:Meter readings (gas, water, electricity, etc)`,
			$localize`:@@ctr_ct1_t_2:Key delivery`,
			$localize`:@@ctr_ct1_t_3:Furniture integrity check`,
			$localize`:@@ctr_ct1_t_4:Lease signed`,
			$localize`:@@ctr_ct1_t_5:Deposit received`,
			$localize`:@@ctr_ct1_t_6:Lease registered`,
			$localize`:@@ctr_ct1_t_7:Room cleaning`,
			$localize`:@@ctr_ct1_t_8:Tenant check-in`
		]
	},

	checklistTemplate2: {
		name: $localize`:@@ctr_ct2_name:LEASE Termination`,
		taskNames: [
			$localize`:@@ctr_ct2_t_1:Meter readings (gas, water, electricity, etc)`,
			$localize`:@@ctr_ct2_t_2:Key return`,
			$localize`:@@ctr_ct2_t_3:Furniture integrity check`,
			$localize`:@@ctr_ct2_t_4:Last montlhy payment`,
			$localize`:@@ctr_ct2_t_5:Payment adjustment`,
			$localize`:@@ctr_ct2_t_6:Lease termination notice`,
			$localize`:@@ctr_ct2_t_7:Deposit Returned`,
			$localize`:@@ctr_ct2_t_8:Room cleaning`,
			$localize`:@@ctr_ct2_t_9:Tenant check out`
		]
	},

	tenantMenuTooltips: [
		$localize`:@@ctr_t_menu_tootps_1:Profile`,
		$localize`:@@ctr_t_menu_tootps_2:Leases`,
		$localize`:@@ctr_t_menu_tootps_3:Checklists`,
		$localize`:@@ctr_t_menu_tootps_4:Payments`,
		$localize`:@@ctr_t_menu_tootps_5:Documents`,
		$localize`:@@ctr_t_menu_tootps_6:Chat`
	],
	tenantMenuTooltips_B: [
		$localize`:@@ctr_t_menu_tootps_B_1:Billing info`,
		$localize`:@@ctr_t_menu_tootps_B_2:Additional info`,
		$localize`:@@ctr_t_menu_tootps_B_3:App`
	],

	propertyMenuTooltips: [
		$localize`:@@ctr_p_menu_tootps_1:Property Profile`,
		$localize`:@@ctr_p_menu_tootps_2:Gallery`,
		$localize`:@@ctr_p_menu_tootps_3:Leases`,
		$localize`:@@ctr_p_menu_tootps_4:Property Balance`,
		$localize`:@@ctr_p_menu_tootps_5:Documents`,
		$localize`:@@ctr_p_menu_tootps_6:Chat`
	],
	propertyMenuTooltips_B: [
		$localize`:@@ctr_p_menu_tootps_B_1:Rooms availability`,
		$localize`:@@ctr_p_menu_tootps_B_2:Additional info`,
		$localize`:@@ctr_p_menu_tootps_B_3:Past leases`
	],

	maintenanceMenuTooltips: [
		$localize`:@@ctr_m_menu_tootps_1:Maintenance Profile`,
		$localize`:@@ctr_m_menu_tootps_2:Documents`
	],

	addPropertyTooltips: [
		$localize`:@@ctr_add_p_tootps_1:Property`,
		$localize`:@@ctr_add_p_tootps_2:TAG`,
		$localize`:@@ctr_add_p_tootps_3:Management`,
		$localize`:@@ctr_add_p_tootps_4:Documents`
	],
	addTenantTooltips: [
		$localize`:@@ctr_add_t_tootps_1:Lease`,
		$localize`:@@ctr_add_t_tootps_2:Invoicing info`,
		$localize`:@@ctr_add_t_tootps_3:Documents`
	],
	addLeaseTooltips: [
		$localize`:@@ctr_add_l_tootps_1:Period`,
		$localize`:@@ctr_add_l_tootps_2:Link`,
		$localize`:@@ctr_add_l_tootps_3:Costs`,
		$localize`:@@ctr_add_l_tootps_4:Other options`,
		$localize`:@@ctr_add_l_tootps_5:Documents`
	],

	tenantRequestAmountPropertyTooltip: $localize`:@@ctr_t_req_amount_prop:This is the amount you proposed for renting this property`,
	tenantRequestAmountRoomTooltip: $localize`:@@ctr_t_req_amount_room:This is the amount you proposed for renting this room`,

	propertyStandardServices: [
		$localize`:@@ctr_p_serv_stamdard_1:Air conditioned`,
		$localize`:@@ctr_p_serv_stamdard_2:Private garden`,
		$localize`:@@ctr_p_serv_stamdard_3:Heating`,
		$localize`:@@ctr_p_serv_stamdard_4:Dishwasher`,
		$localize`:@@ctr_p_serv_stamdard_5:Tv`,
		$localize`:@@ctr_p_serv_stamdard_6:Washer`,
		$localize`:@@ctr_p_serv_stamdard_7:Wi-fi`,
		$localize`:@@ctr_p_serv_stamdard_8:Dryer`,
		$localize`:@@ctr_p_serv_stamdard_9:Parking area`
	],

	you: $localize`:@@ctr_you:You`,

	deposit: $localize`:@@ctr_deposit:Deposit`,

	landlordStandardMessages: [
		$localize`:@@ctr_ll_standard_msg_1:To the attention of %@1,\n\none or more payments due are currently unpaid.\n\nPay off the amount as soon as possible by paying through the App.\n\nIf you have already paid through other channels, please let us know.\n\nRegards,\n`,
		$localize`:@@ctr_ll_standard_msg_2:To the attention of %@1,\n\nin light of the repeated reports and reminders, we will be forced to submit your situation to our lawyers, who will be able to bring a case without further delay.\n\nWe would not want to proceed through legal channels. However, if the situation is not resolved within 3 working days we will be forced to.\n\nRegards,\n`,
		$localize`:@@ctr_ll_standard_msg_3:To the attention of %@1,\n\nfollowing numerous reports and reminders, you are invited to settle the payments due. Alternatively, you will be evicted from the apartment located in %@2.\n\nIf the request is not carried out, we will be forced to declare the forfeiture of the rental contract to proceed with the initiation of the necessary legal actions.\n\nRegards,\n`
	],

	tenantImportConfirmCta: $localize`:@@ctr_t_import_confirm_cta:Continue`,

	tenantImportCtaMessage: $localize`:@@ctr_t_import_confirm_cta_msg:<strong>IMPORT</strong> tenant data</br>You have added "<strong>{0}</strong>" tenants succesfully`,

	tenantImportErrorTemplate: $localize`:@@ctr_t_import_error_template:An error occurred on line <strong>{0}</strong>. Please  </strong>check<strong> fields: {1}`,

	tenantFieldsErrors: {
		email: $localize`:@@ctr_t_fields_error_1:Email`,
		name: $localize`:@@ctr_t_fields_error_2:Name`,
		surname: $localize`:@@ctr_t_fields_error_3:Surname`,
		birthday: $localize`:@@ctr_t_fields_error_4:Birthday`,
		phone: $localize`:@@ctr_t_fields_error_5:Phone`,
		phoneGuarantor: $localize`:@@ctr_t_fields_error_6:Guarantor Phone`,
		emailGuarantor: $localize`:@@ctr_t_fields_error_7:Guarantor Email`,
		nameGuarantor: $localize`:@@ctr_t_fields_error_8:Guarantor Name`,
		lastGuarantor: $localize`:@@ctr_t_fields_error_9:Guarantor Surname`,
		gender: $localize`:@@ctr_t_fields_error_10:Gender`,
		taxCode: $localize`:@@ctr_t_fields_error_11:Tax Code`,
		country: $localize`:@@ctr_t_fields_error_12:Country`,
		region: $localize`:@@ctr_t_fields_error_13:Region`,
		address: $localize`:@@ctr_t_fields_error_14:Address`,
		city: $localize`:@@ctr_t_fields_error_15:City`,
		postalCode: $localize`:@@ctr_t_fields_error_16:Postal Code`
	},

	propertyImportConfirmCta: $localize`:@@ctr_p_import_confirm:Continue`,

	propertyImportCtaMessage: $localize`:@@ctr_p_import_confirm_msg:<strong>IMPORT</strong> property data</br>You have added "<strong>{0}</strong>" properties succesfully`,

	propertyImportErrorTemplate: $localize`:@@ctr_p_import_error_template:An error occurred on line <strong>{0}</strong>. Please  </strong>check<strong> fields: {1}`,

	propertyFieldsErrors: {
		name: $localize`:@@ctr_p_fields_error_1:Name`,
		address: $localize`:@@ctr_p_fields_error_2:Address`,
		city: $localize`:@@ctr_p_fields_error_3:City`,
		zip: $localize`:@@ctr_p_fields_error_4:ZIP`,
		region: $localize`:@@ctr_p_fields_error_5:Region`,
		country: $localize`:@@ctr_p_fields_error_6:Country`,
		whole_apt: $localize`:@@ctr_p_fields_error_7:Is whole apartment`,
		single: $localize`:@@ctr_p_fields_error_8:N. single rooms`,
		double: $localize`:@@ctr_p_fields_error_9:N. double rooms`,
		numRooms: $localize`:@@ctr_p_fields_error_10:Is whole apartment, N. single rooms, N. double rooms`
	},
	chatTitles: [
		$localize`:@@ctr_chat_titles_1:Tenant chat`,
		$localize`:@@ctr_chat_titles_2_communications:Communications`
	],
	loadingSpinnerTitles: {
		conversations: $localize`:@@chat_convs_loading_title:Loading conversations...`,
		conversation: $localize`:@@chat_conv_loading_title:Loading conversation...`
	},
	ownersTitle: $localize`:@@ctr_owners_title:Property Owner`,
	ownersMissingTitle: $localize`:@@ctr_owners_missing_title:No owners found`,

	collectedRents: $localize`:@@ctr_collected_rents:Collected rents`,
	pendingRents: $localize`:@@ctr_pending_rents:Pending rents`,
	overdueRents: $localize`:@@ctr_overdue_rents:Overdue rents`,
	upcomingRents: $localize`:@@ctr_upcoming_rents:Upcoming rents`,
	withdrawal: $localize`:@@ctr_withdrawal_category:Withdrawal`,
	paymentDeleted: $localize`:@@ctr_payment_deleted:Payment deleted`,
	notRecognized: $localize`:@@ctr_not_recognized:Not recognized`,
	missingConfiguration: $localize`:@@ctr_missing_conf:Start the online payments configuration to use your wallet`,
	all_item: $localize`:@@all_label_margins:All`,
	menus: {
		property_A: [
			{
				path: 'profile',
				message: $localize`:@@ctr_menus_prop_A_1_msg:Property`,
				imagePath: './assets/img/menu_prop_property.svg'
			},
			{
				path: 'units',
				message: $localize`:@@ctr_menus_prop_A_2_msg:Units`,
				imagePath: './assets/img/menu_prop_units.svg'
			},
			{
				path: 'balance',
				message: $localize`:@@ctr_menus_prop_A_3_msg:Property Balance`,
				imagePath: './assets/img/menu_prop_balance.svg'
			},
			{
				path: 'margins',
				message: $localize`:@@ctr_menus_prop_margins_msg:Margins`,
				imagePath: './assets/img/rm_notifications_payments.svg'
			},
			{
				path: 'files',
				message: $localize`:@@ctr_menus_prop_A_4_msg:Documents`,
				imagePath: './assets/img/menu_prop_documents.svg'
			},
			{
				path: 'conversation',
				message: $localize`:@@ctr_menus_prop_A_5_msg:Chats`,
				imagePath: './assets/img/menu_prop_chat.svg'
			},
			{
				path: 'gallery',
				message: $localize`:@@ctr_menus_prop_A_6_msg:Gallery`,
				imagePath: './assets/img/menu_prop_gallery.svg'
			}
		],
		property_B: [
			{
				path: 'units',
				message: $localize`:@@ctr_menus_prop_B_1_msg:Unit`,
				imagePath: './assets/img/menu_prop_property.svg'
			},
			{
				path: 'balance',
				message: $localize`:@@ctr_menus_prop_B_2_msg:Property Balance`,
				imagePath: './assets/img/menu_prop_balance.svg'
			},
			{
				path: 'margins',
				message: $localize`:@@ctr_menus_prop_margins_msg:Margins`,
				imagePath: './assets/img/rm_notifications_payments.svg'
			},
			{
				path: 'files',
				message: $localize`:@@ctr_menus_prop_B_3_msg:Documents`,
				imagePath: './assets/img/menu_prop_documents.svg'
			},
			{
				path: 'conversation',
				message: $localize`:@@ctr_menus_prop_B_4_msg:Chats`,
				imagePath: './assets/img/menu_prop_chat.svg'
			},
			{
				path: 'gallery',
				message: $localize`:@@ctr_menus_prop_B_5_msg:Gallery`,
				imagePath: './assets/img/menu_prop_gallery.svg'
			}
		],

		tenant_A: [
			{
				message: $localize`:@@ctr_menus_ten_A_1_msg:Profile`,
				imagePath: './assets/img/menu_tenant_profile.svg'
			},
			{
				message: $localize`:@@ctr_menus_ten_A_2_msg:Checklists`,
				imagePath: './assets/img/menu_tenant_checklists.svg'
			},
			{
				message: $localize`:@@ctr_menus_ten_A_3_msg:Transactions`,
				imagePath: './assets/img/menu_prop_balance.svg'
			},
			{
				message: $localize`:@@ctr_menus_ten_A_4_msg:Documents`,
				imagePath: './assets/img/menu_prop_documents.svg'
			},
			{
				message: $localize`:@@ctr_menus_ten_A_5_msg:Chat`,
				imagePath: './assets/img/menu_prop_chat.svg'
			}
		],

		property_add: [
			{
				message: $localize`:@@ctr_menus_prop_add_1_msg:Property`
			},
			{
				message: $localize`:@@ctr_menus_prop_add_2_msg:Additional info`
			},
			{
				message: $localize`:@@ctr_menus_prop_add_3_msg:Management`
			},
			{
				message: $localize`:@@ctr_menus_prop_add_4_msg:Costs`
			},
			{
				message: $localize`:@@ctr_menus_prop_add_5_msg:Documents`
			}
			// {
			// 	message: $localize`:@@ctr_menus_prop_add_6_msg:Upgrade plan`
			// }
		],
		channel_manager: [
			{
				path: 'units',
				message: $localize`:@@ctr_menus_cm_1_msg:Manage Units`,
				imagePath: './assets/img/menu_prop_property.svg'
			} /*
			{
				path: 'bookings',
				message: $localize`:@@ctr_menus_cm_2_msg:Manage Bookings`,
				imagePath: './assets/img/menu_prop_units.svg'
			},*/,
			{
				path: 'channels',
				message: $localize`:@@ctr_menus_cm_3_msg:Manage Channels`,
				imagePath: './assets/img/menu_prop_units.svg'
			}
		],

		cm_sync_units: [
			{
				message: $localize`:@@ctr_menus_cm_sync_units_1_msg:Select units`
			} /*,
			{
				message: $localize`:@@ctr_menus_cm_sync_units_2_msg:Review & Sync`
			}*/
		],

		lease_add: [
			{
				message: $localize`:@@ctr_menus_lease_add_1_msg:Management`
			},
			{
				message: $localize`:@@ctr_menus_lease_add_2_msg:Costs`
			},
			{
				message: $localize`:@@ctr_menus_lease_add_3_msg:Other options`
			},
			{
				message: $localize`:@@ctr_menus_lease_add_4_msg:Documents`
			}
		],

		booking_add: [
			{
				message: $localize`:@@ctr_menus_booking_add_1_msg:Management`
			},
			{
				message: $localize`:@@ctr_menus_booking_add_2_msg:Costs`
			},
			{
				message: $localize`:@@ctr_menus_booking_add_3_msg:Other options`
			},
			{
				message: $localize`:@@ctr_menus_booking_add_4_msg:Documents`
			}
		],

		lease_edit: [
			{
				message: $localize`:@@ctr_menus_lease_edit_1_msg:Management`
			}
		],

		notifications: [
			{
				message: $localize`:@@ctr_menus_notifications_1_msg:Payments`,
				imagePath: './assets/img/ic_notifications_payments.svg'
			},
			{
				message: $localize`:@@ctr_menus_notifications_2_msg:Leases`,
				imagePath: './assets/img/rm_notifications_leases.svg'
			},
			{
				message: $localize`:@@ctr_menus_notifications_3_msg:Chat`,
				imagePath: './assets/img/rm_notifications_chat.svg'
			},
			{
				message: $localize`:@@ctr_menus_notifications_4_msg:Management`,
				imagePath: './assets/img/rm_notifications_management.svg'
			},
			{
				message: $localize`:@@ctr_menus_notifications_5_msg:Channel manager`,
				imagePath: './assets/img/rm_notifications_channel_manager.svg'
			} /*,
			{
				message: $localize`:@@ctr_menus_notifications_6_msg:Announcements`,
				imagePath: './assets/img/rm_notifications_announcements.svg'
			}
            */
		],
		tenant_add: [
			{
				message: $localize`:@@ctr_menus_ten_add_1_msg:Contact`
			},
			{
				message: $localize`:@@ctr_menus_ten_add_2_msg:Billing info`
			},
			{
				message: $localize`:@@ctr_menus_ten_add_4_msg:Documents`
			}
		],
		maintenance_add: [
			{
				message: $localize`:@@maintenance_add_step_1:Basic information`
			},
			{
				message: $localize`:@@maintenance_add_step_2:Documents`
			}
		],
		invoices_generation: [
			{
				message: $localize`:@@invoices_generation_step_1:Exportation type`
			},
			{
				message: $localize`:@@invoices_generation_step_2:Data check`
			}
		],
		mark_as_paid_multi: [
			{
				message: $localize`:@@mark_as_paid_multi_step_2:Data check`
			}
		],
		deposit_return: [
			{
				message: $localize`:@@deposit_return_step_1:Data check`
			}
		],
		deposit_create: [
			{
				message: $localize`:@@deposit_create_step_1:Data check`
			}
		],
		transaction_add: [
			{
				message: $localize`:@@com_type:Type`
			},
			{
				message: $localize`:@@com_details:Details`
			}
		],
		owner: [
			{
				message: $localize`:@@com_insights:Insights`,
				imagePath: './assets/img/menu_insights.svg'
			},
			{
				message: $localize`:@@com_margins:Margins`,
				imagePath: './assets/img/rm_notifications_payments.svg'
			},
			{
				message: $localize`:@@com_documents:Documents`,
				imagePath: './assets/img/menu_prop_documents.svg'
			},
			{
				message: $localize`:@@com_profile:Profile`,
				imagePath: './assets/img/menu_tenant_profile.svg'
			}
		],
		maintenance: [
			{
				message: $localize`:@@com_maintenance:Maintenance`,
				imagePath: './assets/img/menu_tenant_profile.svg'
			},
			{
				message: $localize`:@@com_documents:Documents`,
				imagePath: './assets/img/menu_prop_documents.svg'
			}
		],
		owner_add: [
			{
				message: $localize`:@@owner_add_step_1:Basic information`
			},
			{
				message: $localize`:@@owner_add_step_2:Match properties`
			},
			{
				message: $localize`:@@owner_add_step_3:Documents`
			}
		],
		resolve_maintenance: [
			{
				message: $localize`:@@com_maintenance:Maintenance`
			}
		],
		notifications_tabs: [
			{
				id: 'all',
				label: $localize`:@@com_all:All`
			},
			{
				id: 'calendar',
				label: $localize`:@@com_calendar:Calendar`,
				type: 'management'
			},
			{
				id: 'maintenances',
				label: $localize`:@@com_maintenances:Maintenances`,
				type: 'management'
			},
			{
				id: 'report',
				label: $localize`:@@com_report:Report`,
				type: 'management'
			},
			{
				id: 'bookings',
				label: $localize`:@@com_bookings:Bookings`,
				type: 'channel_manager'
			},
			{
				id: 'sync',
				label: $localize`:@@com_sync:Sync`,
				type: 'channel_manager'
			},
			{
				id: 'channels',
				label: $localize`:@@com_channels:Channels`,
				type: 'channel_manager'
			},
			{
				id: 'changelog',
				label: $localize`:@@com_changelog:Changelog`,
				type: 'announcements'
			},
			{
				id: 'upcoming',
				label: $localize`:@@com_upcoming:Upcoming`,
				type: 'announcements'
			}
		],
		event_add: [
			{
				message: $localize`:@@com_details:Details`
			}
		],
		generate_report: [
			{
				message: $localize`:@@com_generate_report:Generate Report`
			}
		],
		broadcast_message: [
			{
				message: $localize`:@@com_broadcast_message_recipients:Recipients`
			},
			{
				message: $localize`:@@com_broadcast_message_message:Message`
			}
		]
	},

	ownerIRRtooltipA: $localize`:@@ctr_owner_irr_totp_A:Internal Rate of Return for these properties since the beginning`,
	ownerIRRtooltipB: $localize`:@@ctr_owner_irr_totp_B:Internal Rate of Return for these properties since the beginning. Don't forget to fill the investment value in the properties' details`,
	ownerIRRtooltipC: $localize`:@@ctr_owner_irr_totp_C:Don't forget to fill the investment value in the properties' details`,

	insights: {
		properties: {
			revenueGrowth: $localize`:@@ctr_insights_props_rev_growth:Monthly growth rate of your income`,
			occupancyRate: $localize`:@@ctr_insights_props_occp_rate:Monthly occupancy rate of your units`,
			NOI: $localize`:@@ctr_insights_props_noi:All the inflows minus the outflows of the month`
		},

		units: {
			cashflow: $localize`:@@ctr_insights_units_cashflow:All the inflows of the current month`,
			avgRevenue: $localize`:@@ctr_insights_units_avg_rev:To properly take advantage of this metric, be sure to add your past leases`,
			avgVacancy: $localize`:@@ctr_insights_units_avg_vacancy:To properly take advantage of this metric, be sure to add your past leases`
		},

		tenants: {
			solvencyRate: $localize`:@@ctr_insights_tens_solv_rate:Percentage of tenants paying on time`,
			appDownloads: $localize`:@@ctr_insights_tens_app_downloads:Total app downloads considering the current leases`,
			avgTurnover: $localize`:@@ctr_insights_tens_avg_turnover:Average days for the replacement of a tenant`
		}
	},
	doubleUnitFlatFeeDescription: $localize`:@@double_unit_flat_fee_description:This is an extra fee for the additional tenant`,

	defaultFlatFeeDescription: $localize`:@@default_flat_fee_description:This flat fee includes the following bills: `,
	categoryAlreadyIncludedBlockedText: $localize`:@@category_already_included_blocked_text:Category blocked because is already included`,
	additionalGroosAmountTooltipMessage: $localize`:@@tooltip_additional_gross_amount_message:This amount will be added to the gross amount when adding an additional tenant`
};
