import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ScreenStateService {
	private mobileResolution: boolean;

	public screenResized: BehaviorSubject<boolean>;

	public dashboardTitleSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
		$localize`:@@estelle_title_your_dashboard:Your Dashboard`
	);
	public chatBackNavigationTriggerSubject: Subject<void> = new Subject<void>();

	constructor() {
		this.mobileResolution = window.innerWidth < 992;
		this.screenResized = new BehaviorSubject<boolean>(this.isMobileResolution());

		fromEvent(window, 'resize').subscribe(() => {
			const newVal = window.innerWidth < 992;
			if (newVal != this.mobileResolution) {
				this.screenResized.next(newVal);
			}
			this.mobileResolution = newVal;
		});
	}

	public isMobileResolution(): boolean {
		return this.mobileResolution;
	}

	public setMobileDashboardTitle(title: string) {
		this.dashboardTitleSubject.next(title);
	}

	public triggerChatBackNavigation() {
		this.chatBackNavigationTriggerSubject.next();
	}
}
