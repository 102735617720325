import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { addMockLeasesSuccessAction } from 'src/app/leases/state/lease.actions';
import { addMockMaintenanceSuccessAction } from 'src/app/maintenances/state/maintenances.actions';
import { Property, PropertyBasic } from 'src/app/models/property.model';
import { EntityLoadable, setLoadingStateOfGeneric, setSuccessStateOfGeneric } from 'src/app/utils/store-utils';
import {
	addPropertySuccessAction,
	deletePropertySuccessAction,
	editPropertySuccessAction,
	loadPropertiesBasicAction,
	loadPropertiesBasicSuccessAction,
	loadPropertyByIdSuccessAction,
	resetPropertiesStateSuccessAction,
	uploadMultiplePropertiesSuccessAction
} from './properties.actions';

export interface PropertiesBasicState extends EntityLoadable<PropertyBasic> {}
export const propertiesBasicAdapter = createEntityAdapter<PropertyBasic>();

const initialPropertiesBasicState = propertiesBasicAdapter.getInitialState({
	isLoaded: false,
	isLoading: false,
	error: null
});

export const PropertiesBasicReducer = createReducer(
	initialPropertiesBasicState,

	on(resetPropertiesStateSuccessAction, state => (state = initialPropertiesBasicState)),
	on(loadPropertiesBasicSuccessAction, (state, payload) =>
		setSuccessStateOfGeneric(state, propertiesBasicAdapter, payload.propertiesBasic, payload.refreshAll)
	),
	on(loadPropertiesBasicAction, state => setLoadingStateOfGeneric(state)),

	on(loadPropertyByIdSuccessAction, addPropertySuccessAction, (state, payload) =>
		propertiesBasicAdapter.upsertOne(Property.getBasicInfo(payload.property), { ...state })
	),
	on(uploadMultiplePropertiesSuccessAction, (state, payload) =>
		propertiesBasicAdapter.upsertMany(Property.getBasicInfoMultiple(payload.properties), state)
	),
	on(editPropertySuccessAction, (state, payload) =>
		propertiesBasicAdapter.updateOne(
			{ id: payload.property.id, changes: Property.getBasicInfo(payload.property) },
			state
		)
	),
	on(deletePropertySuccessAction, (state, payload) =>
		propertiesBasicAdapter.removeOne(payload.deletePropertyId, state)
	),
	on(addMockLeasesSuccessAction, addMockMaintenanceSuccessAction, (state, payload) => {
		if (payload.property) {
			return propertiesBasicAdapter.addOne(Property.getBasicInfo(payload.property.property), state);
		} else {
			return state;
		}
	})
);
