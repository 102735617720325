import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { mergeMapTo, take, takeWhile } from 'rxjs/operators';
import { SnotifyService } from 'ng-snotify';
import { AppConstants } from '../../utils/app-costants';
import { LandlordService } from './landlord/landlord.service';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class MessagingService {
	currentMessage = new BehaviorSubject(null);

	constructor(
		private angularFireMessaging: AngularFireMessaging,
		private router: Router,
		private toastService: SnotifyService,
		private readonly landlordService: LandlordService,
		private readonly authService: AuthService
	) {
		this.angularFireMessaging.tokenChanges.subscribe(
			token => this.updateToken(token),
			error => console.error(error)
		);

		this.initMessages();
	}

	updateToken(token) {
		if (!this.authService.isGodUser()) {
			this.landlordService.setLandlordToken(token);
		}
	}

	requestPermission() {
		this.angularFireMessaging.requestPermission.pipe(mergeMapTo(this.angularFireMessaging.tokenChanges)).subscribe(
			token => this.updateToken(token),
			error => console.error(error)
		);
	}

	hasNotificationPermissionEnabled(): boolean {
		return Notification.permission === 'granted';
	}

	hasExplicitlyRefusedNotification(): boolean {
		return Notification.permission === 'denied';
	}

	showToaster(notificationData) {
		this.playAudio();
		this.toastService
			.success(notificationData.message, notificationData.title, AppConstants.TOAST_STD_CONFIG)
			.on('click', () => {
				this.toasterClickedHandler(notificationData);
			});
	}

	toasterClickedHandler(notificationData) {
		if (notificationData.suggestedPath) {
			this.router.navigateByUrl(notificationData.suggestedPath);
		}
	}

	playAudio() {
		const audio = new Audio();
		audio.src = './assets/sounds/definite.mp3';
		audio.load();
		audio.play();
	}

	initMessages() {
		this.angularFireMessaging.messages.subscribe((payload: any) => {
			this.showToaster(payload.data);
		});
	}
}
