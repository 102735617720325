import { AttachedFile } from './fileRm.model';
import { DatedItem } from './payments';
import { Tenant } from './tenant.model';

export enum BookingStatus {
	PENDING = 'pending',
	CONFIRMED = 'confirmed',
	REFUSED = 'refused',
	PROCESSED = 'processed'
}

export enum BookingType {
	TENANT = 'tenant',
	OTHER = 'other'
}

export interface Booking extends DatedItem {
	id: string;
	startDate: number;
	endDate: number;
	unitId: string;
	propertyId: string;

	tenantId?: string;
	pendingTenantData?: Tenant;

	documents?: AttachedFile[];

	channelId?: string;
	integrationId?: string;

	metadata?: {
		linkedLeaseId?: string;
		[key: string]: any;
	};

	monthlyRent?: number;
	deposit?: number;
	currency?: string;

	type: BookingType;
	status: BookingStatus;

	referenceCode?: string;
	description?: string;
}

export type CreateBooking = Omit<Booking, 'id' | 'createdBy' | 'updatedBy' | 'createdAt' | 'updatedAt'>;
