import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-send-tenant-app-invite-multiple-dialog',
	templateUrl: './send-tenant-app-invite-multiple-dialog.component.html',
	styleUrls: ['./send-tenant-app-invite-multiple-dialog.component.css']
})
export class SendTenantAppInviteMultipleDialogComponent implements OnInit {
	status: number = 0;

	sendEmail: boolean = true;

	constructor(
		public dialogRef: MatDialogRef<SendTenantAppInviteMultipleDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			total: number;
			alreadyLinked: number;
			popupStatus: number;
		}
	) {
		this.status = data.popupStatus;
	}

	ngOnInit() {}

	cancelClick() {
		this.dialogRef.close({ action: 'none' });
	}

	proceedClick() {
		this.dialogRef.close({ action: 'do_send', email: this.sendEmail });
	}

	doneClick() {
		this.dialogRef.close({ action: 'none' });
	}
}
