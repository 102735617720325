import { Directive, Output, Input, EventEmitter, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subject, interval, Subscription, Observable } from 'rxjs';
import { throttle, throttleTime, debounceTime, debounce } from 'rxjs/operators';

@Directive({
	selector: '[appDragDrop]'
})
export class DragDropDirective implements OnInit, OnDestroy {
	@Output() onFileDropped = new EventEmitter<FileList>();
	@Output() onFileDragging = new EventEmitter<boolean>();

	private subject = new Subject<boolean>();
	private subscription: Subscription;

	ngOnInit(): void {
		// Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		// Add 'implements OnInit' to the class.

		this.subscription = this.subject.pipe(debounceTime(30)).subscribe(it => {
			this.onFileDragging.next(it);
		});
	}

	ngOnDestroy(): void {
		// Called once, before the instance is destroyed.
		// Add 'implements OnDestroy' to the class.
		if (this.subscription) this.subscription.unsubscribe();
	}

	// @HostBinding('style.background-color') private background = '#f5fcff'
	// @HostBinding('style.opacity') private opacity = '1'

	// Dragover listener
	@HostListener('dragover', ['$event']) onDragOver(evt) {
		evt.preventDefault();
		// 	evt.stopPropagation();
		// this.background = '#9ecbec';
		// this.opacity = '0.8'
		// this.onFileDragging.next(true);
		this.subject.next(true);
	}

	// Dragleave listener
	@HostListener('dragleave', ['$event']) public onDragLeave(evt) {
		evt.preventDefault();
		// 	evt.stopPropagation();
		// this.background = '#f5fcff'
		// this.opacity = '1'
		// this.onFileDragging.next(false);
		this.subject.next(false);
	}

	// Drop listener
	@HostListener('drop', ['$event']) public ondrop(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		// 	this.background = '#f5fcff'
		// 	this.opacity = '1'
		const files = evt.dataTransfer.files;
		if (files.length > 0) {
			this.onFileDropped.emit(files);
		}
		this.subject.next(false);
	}
}
