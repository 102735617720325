import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/store/reducer';

const selectOwnerBasicState = createSelector(
	state => state,
	(state: AppState) => state.ownersBasic
);

export const selectOwnersBasicEntities = createSelector(selectOwnerBasicState, state => {
	if (state.isLoaded) {
		return state.entities;
	} else {
		return null;
	}
});
