import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-roommate-info-card',
	templateUrl: './roommate-info-card.component.html',
	styleUrls: ['./roommate-info-card.component.scss']
})
export class RoommateInfoCardComponent implements OnInit {
	@Input()
	public readonly sidebarColor;

	constructor() {}

	ngOnInit(): void {}
}
