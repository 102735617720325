import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { publishNewFiltersAction } from 'src/app/core/services/filters/state/filters.actions';
import { PaymentPageModelStore } from 'src/app/models/payments';
import {
	deletePaymentSuccessAction,
	invalidatePaymentPagesAction,
	loadPaymentsPaginatedSuccessAction
} from './payments.actions';

export interface PaymentPagesState extends EntityState<PaymentPageModelStore> {
	isLoaded: boolean;
}
export const paymentPagesAdapter = createEntityAdapter<PaymentPageModelStore>({
	selectId: model => model.id
});
const initialPaymentPagesState = paymentPagesAdapter.getInitialState({
	isLoaded: false
});

export const PaymentPagesReducers = createReducer(
	initialPaymentPagesState,

	// Load pages

	on(loadPaymentsPaginatedSuccessAction, (state, payload) => {
		if (payload.paymentsQueryResult) {
			return paymentPagesAdapter.upsertOne(
				{
					id: payload.page,
					paymentIds: payload.paymentsQueryResult.data.map(payment => payment.id),
					totalItems: payload.paymentsQueryResult.metadata.totalItems || 0,
					filteredItems: payload.paymentsQueryResult.metadata.filteredItems || 0
				},
				{ ...state, isLoaded: true }
			);
		} else {
			return state;
		}
	}),

	// Filters user actions

	on(publishNewFiltersAction, (state, payload) => {
		if (payload.filters.type === 'payments' && (payload.filters.filters || payload.filters.sort)) {
			return initialPaymentPagesState;
		} else {
			return state;
		}
	}),

	// Payment actions (user actions + realtime updates)

	on(invalidatePaymentPagesAction, (state, payload) => {
		return { ...initialPaymentPagesState, isLoaded: true };
	}),

	// SOME pages invalidation

	on(deletePaymentSuccessAction, (state, payload) => {
		const allPages = Object.values(state.entities).sort((a, b) => a.id - b.id);
		let minDirtyPage = -1;
		for (let i = 0; i < allPages.length; i++) {
			if (allPages[i].paymentIds.includes(payload.paymentId)) {
				minDirtyPage = allPages[i].id;
				break;
			}
		}

		if (minDirtyPage >= 0) {
			return paymentPagesAdapter.removeMany(it => it.id >= minDirtyPage, state);
		} else {
			return state;
		}
	})
);
