import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of, Subscriber } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { LandlordService } from 'src/app/core/services/landlord/landlord.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { UserBillingInfo } from 'src/app/models/billing.model';
import { LocalizationUtils } from 'src/app/utils/localization-utils';
import { environment } from 'src/environments/environment';
import { resetStoreAction } from '../../store/actions';
import { BillingFeatures, BillingPlanNames, CheckoutPopupEvent, FeatureUsageStats } from '../billing.service';
import {
	loadBillingDetailsAction,
	loadBillingDetailsFailureAction,
	loadBillingDetailsSuccessAction,
	loadFeatureUsageStatsAction,
	loadFeatureUsageStatsFailureAction,
	loadFeatureUsageStatsSuccessAction,
	requestCheckoutFeatureAction,
	requestCheckoutFeatureFailureAction,
	requestCheckoutFeatureSuccessAction,
	requestCheckoutPageAction,
	requestCheckoutPageCloseAction,
	requestCheckoutPageFailureAction,
	requestCheckoutPageSuccessAction,
	requestPortalPageAction,
	requestPortalPageFailureAction,
	requestPortalPageSuccessAction,
	resetBillingStateAction,
	resetBillingStateFailureAction,
	resetBillingStateSuccessAction,
	updateBillingPlanAction,
	updateBillingPlanFailureAction,
	updateBillingPlanSuccessAction
} from './billing.actions';

declare var Chargebee;

@Injectable()
export class BillingEffects {
	private landlordId: string;

	private chargebeeInstance;
	private BACKEND_HOST = environment.services.billing;

	constructor(
		private readonly httpClient: HttpClient,
		private readonly landlordService: LandlordService,
		private readonly zone: NgZone,
		private readonly actions$: Actions,
		private readonly store: Store,
		private readonly loadingService: LoadingService
	) {
		if (Chargebee) {
			Chargebee.init(environment.chargebee);
			this.chargebeeInstance = Chargebee.getInstance();
		}

		this.landlordService
			.getLandlordId()
			.pipe(filter(landlordId => !!landlordId))
			.subscribe(id => (this.landlordId = id));
	}

	public resetStoreEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(resetStoreAction),
			switchMap(() => of(resetBillingStateAction()))
		)
	);

	public resetBillingStateEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(resetBillingStateAction),
			switchMap(() => of(resetBillingStateSuccessAction())),
			catchError(() => of(resetBillingStateFailureAction({})))
		)
	);

	public loadBillingDetailsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadBillingDetailsAction),
			switchMap(() =>
				this.loadBillingInfo().pipe(
					map(billingInfo =>
						loadBillingDetailsSuccessAction({
							billingInfo
						})
					),
					catchError(err => of(loadBillingDetailsFailureAction({})))
				)
			)
		)
	);

	public loadFeatureUsageStatsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadFeatureUsageStatsAction),
			mergeMap(action =>
				this.loadFeatureUsageStats(action.featureName, action.referenceId).pipe(
					map(featureUsage => loadFeatureUsageStatsSuccessAction({ featureUsage })),
					catchError(err => of(loadFeatureUsageStatsFailureAction({})))
				)
			)
		)
	);

	public requestCheckoutPlanEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(requestCheckoutPageAction),
			mergeMap(action =>
				this.requestCheckoutPage(action.planId, action.planQuantity).pipe(
					map(res => {
						if (res === 'success') {
							if (action.callbacks) {
								action.callbacks.success();
							}
							return requestCheckoutPageSuccessAction({
								result: res,
								planId: action.planId,
								planQuantity: action.planQuantity
							});
						} else if (res === 'close') {
							return requestCheckoutPageCloseAction();
						} else {
							return requestCheckoutPageFailureAction({});
						}
					}),
					catchError(err => of(requestCheckoutPageFailureAction({})))
				)
			)
		)
	);

	public requestCheckoutFeatureEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(requestCheckoutFeatureAction),
			mergeMap(action =>
				this.requestCheckoutFeature(action.feature).pipe(
					map(popup => requestCheckoutFeatureSuccessAction({ popup })),
					catchError(err => of(requestCheckoutFeatureFailureAction({})))
				)
			)
		)
	);

	public requestUserPortalEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(requestPortalPageAction),
			mergeMap(() =>
				this.requestUserPortal().pipe(
					map(() => requestPortalPageSuccessAction()),
					catchError(err => of(requestPortalPageFailureAction({})))
				)
			)
		)
	);

	public updateBillingPlanEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(updateBillingPlanAction),
			mergeMap(action =>
				this.updateBillingPlan(action.planId, action.planQuantity).pipe(
					map(res => updateBillingPlanSuccessAction(res)),
					catchError(err => of(updateBillingPlanFailureAction({})))
				)
			)
		)
	);

	/*** Effects handle success and failure actions  ***/

	public genericBillingFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(
					resetBillingStateFailureAction,
					loadBillingDetailsFailureAction,
					loadFeatureUsageStatsFailureAction,
					requestCheckoutPageFailureAction,
					requestCheckoutFeatureFailureAction,
					requestPortalPageFailureAction,
					updateBillingPlanFailureAction
				),
				tap(() => this.loadingService.hideAndDisplayErrorToast())
			),
		{ dispatch: false }
	);

	/***  ***/

	// Private calls
	private requestCheckoutPage(planId: BillingPlanNames, planQuantity: number = 1): Observable<string> {
		return this.landlordService
			.getLandlordData()
			.pipe(take(1))
			.pipe(
				mergeMap(landlord => {
					const obs = new Observable<string>((subscriber: Subscriber<string>) => {
						this.chargebeeInstance.openCheckout({
							hostedPage: () => {
								return this.httpClient
									.post(`${this.BACKEND_HOST}/landlords/${this.landlordId}/billing/checkoutPlan`, {
										planId,
										planQuantity,
										billingData: landlord.invoiceData,
										email: landlord.email,
										name: landlord.name,
										surname: landlord.surname,
										phoneNumber: landlord.phone,
										locale: LocalizationUtils.getLanguage()
									})
									.pipe(
										take(1),
										map(callResult => callResult['hostedPage'])
									)
									.toPromise()
									.then(result => {
										console.log(`Results arrived: ${JSON.stringify(result)}`);

										return result;
									}); // hostedPage requires always a ormise back
							},
							success: hostedPageId => {
								this.zone.run(() => {
									subscriber.next('success');
									subscriber.complete();
									this.chargebeeInstance.closeAll();
								});
							},
							close: () => {
								this.zone.run(() => {
									subscriber.next('close');
									subscriber.complete();
								});
							}
						});
					});
					return obs;
				})
			);
	}

	private requestCheckoutFeature(feature: BillingFeatures): Observable<CheckoutPopupEvent> {
		const classReference = this;

		return this.landlordService
			.getLandlordData()
			.pipe(take(1))
			.pipe(
				mergeMap(landlord => {
					return new Observable<CheckoutPopupEvent>((subscriber: Subscriber<CheckoutPopupEvent>) => {
						this.chargebeeInstance.openCheckout({
							hostedPage: () => {
								return this.httpClient
									.post(
										`${this.BACKEND_HOST}/landlords/${this.landlordId}/billing/checkoutFeature/${feature}`,
										{
											billingData: landlord.invoiceData,
											email: landlord.email,
											name: landlord.name,
											surname: landlord.surname,
											phoneNumber: landlord.phone,
											locale: LocalizationUtils.getLanguage()
										}
									)
									.pipe(
										take(1),
										map(callResult => callResult['hostedPage'])
									)
									.toPromise()
									.then(result => {
										console.log(`Results arrived: ${JSON.stringify(result)}`);
										return result;
									});
							},
							success: hostedPageId => {
								subscriber.next({ event: 'success', hostedPageId });
								classReference.requestBillingInfoUpdate();
							},
							close() {
								subscriber.next({ event: 'close' });
								subscriber.complete();
							}
						});
					});
				})
			);
	}

	private requestUserPortal(): Observable<void> {
		const classReference = this;

		// Setting up the portal session retrieval
		this.chargebeeInstance.setPortalSession(() => {
			return this.httpClient
				.post(`${this.BACKEND_HOST}/landlords/${this.landlordId}/billing/userPortal`, {})
				.pipe(
					map(callResult => {
						return callResult['portalSession'];
					}),
					take(1)
				)
				.toPromise(); // This is necessary because setPortalSession is expecting a promise
		});

		// Opening the portal page
		setTimeout(() => {
			const cbPortal = this.chargebeeInstance.createChargebeePortal();

			cbPortal.open({
				subscriptionCancelled() {
					console.log(`Subscription cancelled`);
				},
				close() {
					// I check for updates 20 second after closing the portal.
					// Why so late?
					// Because the only interesting usecase for us is a subscription cancelled. We can afford having the landlord use Estelle for a couple of seconds more
					// If we need to react immediatly to the cancell, we can emit an action in the subscriptionCancelled() method above
					setTimeout(() => classReference.requestBillingInfoUpdate(), 20000);
				}
			});
		}, 0);

		return of();
	}

	private loadBillingInfo(): Observable<UserBillingInfo> {
		return this.httpClient.get<UserBillingInfo>(`${this.BACKEND_HOST}/landlords/${this.landlordId}`);
	}

	private updateBillingPlan(planId: string, planQuantity?: number): Observable<any> {
		return this.httpClient
			.put(`${this.BACKEND_HOST}/landlords/${this.landlordId}/plans/${planId}`, {
				planId,
				planQuantity
			})
			.pipe(
				map(callResult => ({
					...callResult['hostedPage'],
					planAmount: callResult['planAmount'],
					planQuantity: callResult['planQuantity']
				}))
			);
	}

	/**
	 * Info about how a certain feature is limited by the current plan and how
	 * @param featureName
	 * @param referenceId is an optional parameter used if billing depends on an external item. i.e Every billing cycle, I can attach only X document per property
	 */
	private loadFeatureUsageStats(featureName: BillingFeatures, referenceId?: string): Observable<FeatureUsageStats> {
		const id = referenceId || '';
		return this.httpClient
			.get<{
				feature: {
					id: string;
					includedQuantity: number;
					limitQuantity: number;
					planId: string;
					isGroupByRefId: boolean;
					billingType: 'billingCycle' | 'lease';
					referenceId?: string;
					billingItemId: string;
					cost: number;
					currency: string;
					creditsEnabled: boolean;
					groupByRefId: boolean;
				};
				usedQuantity: number;
			}>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/usage/${featureName}/${id}`)
			.pipe(
				map(res => {
					return {
						...res.feature,
						usedQuantity: res.usedQuantity
					};
				})
			);
	}

	private requestBillingInfoUpdate() {
		this.store.dispatch(loadBillingDetailsAction());
	}
}
