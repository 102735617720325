import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ReplaySubject, interval } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { MangoPersonalData, MangoLegalData, MangoBankAccountData } from '../../models/bankConfiguration.model';
import { AttachedFile } from '../../models/fileRm.model';
import { LandlordService } from './landlord/landlord.service';

export interface BankAccountConfiguration {
	type: string;
	readyToSend: boolean;
	sectionCompleted: { [key: string]: boolean };
	dataPayload: MangoPersonalData | MangoLegalData;
	bankPayload: MangoBankAccountData;
	filesPayload: { [key: string]: AttachedFile };
	maxProgress: number;
	currentProgress: number;
}

@Injectable({
	providedIn: 'root'
})
export class BankAccountService {
	private naturalSections = ['data', 'bank', 'id'];
	private legalSections = ['data', 'bank', 'id', 'article', 'registration']; //  'ubo'
	private naturalDataKeys = ['FirstName', 'LastName', 'Nationality', 'CountryOfResidence', 'Email', 'Birthday'];
	private legalDataKeys = [
		'LegalRepresentativeFirstName',
		'LegalRepresentativeLastName',
		'Name',
		'CompanyNumber',
		'Email',
		'HeadquartersAddress',
		'LegalRepresentativeNationality',
		'LegalRepresentativeBirthday',
		'LegalRepresentativeCountryOfResidence'
	];
	private bankDataKeys = ['OwnerName', 'OwnerSurname', 'IBAN', 'OwnerAddress'];

	private actualConfiguration = this.getDefaultConfiguration();

	private completedNumbers: { data?: number; bank?: number } = {};

	private configurationUpdatedSubject = new ReplaySubject<BankAccountConfiguration>(1);
	public configurationUpdatedObservable = this.configurationUpdatedSubject.asObservable();

	private dirty = false;

	constructor(
		private afs: AngularFirestore,
		public dialog: MatDialog,
		public router: Router,
		private readonly landlordService: LandlordService
	) {
		// Autosaving feature
		interval(10000).subscribe();

		this.landlordService
			.getLandlordId()
			.pipe(take(1))
			.subscribe(landlordId => {
				this.afs
					.doc(`landlords/${landlordId}/data/bankConfiguration`)
					.get()
					.pipe(
						take(1),
						map(doc =>
							doc.exists ? (doc.data() as BankAccountConfiguration) : this.getDefaultConfiguration()
						)
					)
					.subscribe(
						configuration => {
							this.actualConfiguration = configuration;
							if (configuration.type === 'natural') {
								this.checkMangoNaturalData(configuration.dataPayload as MangoPersonalData);
								this.actualConfiguration.maxProgress = this.getNaturalNumbers(); // I force these numbers with the local value because I may have add or removed new fields
							} else {
								this.checkMangoLegalData(configuration.dataPayload as MangoLegalData);
								this.actualConfiguration.maxProgress = this.getLegalNumbers(); // I force these numbers with the local value because I may have add or removed new fields
							}
							this.checkMangoBankData(configuration.bankPayload as MangoBankAccountData);
							this.checkEverythingAndNotifyChildren();
						},
						onError => {
							this.actualConfiguration = this.getDefaultConfiguration();
							this.checkMangoBankData({} as any);
							this.checkMangoNaturalData({} as any);
							this.checkEverythingAndNotifyChildren();
						}
					);
			});
	}

	saveCurrentConfiguration(destroying = false) {
		if (this.dirty) {
			this.dirty = false;
			this.landlordService
				.getLandlordId()
				.pipe(take(1))
				.subscribe(landlordId => {
					this.afs
						.doc(`landlords/${landlordId}/data/bankConfiguration`)
						.set(JSON.parse(JSON.stringify(this.actualConfiguration)));
				});
		}
	}

	private getDefaultConfiguration() {
		const defaultConfiguration: BankAccountConfiguration = {
			type: 'natural',
			readyToSend: false,
			sectionCompleted: {},
			dataPayload: {} as MangoPersonalData,
			bankPayload: {} as MangoBankAccountData,
			filesPayload: {},
			maxProgress: this.getNaturalNumbers(),
			currentProgress: 0
		};

		return defaultConfiguration;
	}

	private getNaturalNumbers() {
		return this.naturalDataKeys.length + this.bankDataKeys.length + this.naturalSections.length - 2;
	}

	private getLegalNumbers() {
		return this.legalDataKeys.length + this.bankDataKeys.length + this.legalSections.length - 2;
	}

	updateNaturalDataPayload(payload: MangoPersonalData) {
		this.dirty = true;
		this.checkMangoNaturalData(payload);
		this.checkEverythingAndNotifyChildren();
	}

	updateLegalDataPayload(payload: MangoLegalData) {
		this.dirty = true;
		this.checkMangoLegalData(payload);
		this.checkEverythingAndNotifyChildren();
	}

	updateBankPayload(payload: MangoBankAccountData) {
		this.dirty = true;
		this.checkMangoBankData(payload);
		this.checkEverythingAndNotifyChildren();
	}

	updateFileStatus(name: string, attachedFile?: AttachedFile) {
		this.dirty = true;
		this.checkMangoFileChanged(name, attachedFile);
		this.checkEverythingAndNotifyChildren();
	}

	changeType(type: string) {
		// Reset configuration
		this.actualConfiguration = this.getDefaultConfiguration();
		this.completedNumbers = {};
		this.dirty = true;

		// Set type
		this.actualConfiguration.type = type;

		// Calculate final number
		if (type === 'natural') {
			this.actualConfiguration.maxProgress = this.getNaturalNumbers();
		} else {
			this.actualConfiguration.maxProgress = this.getLegalNumbers();
		}

		// Notify changes
		this.checkEverythingAndNotifyChildren();
	}

	private checkEverythingAndNotifyChildren() {
		const actualSections = this.actualConfiguration.type === 'natural' ? this.naturalSections : this.legalSections;

		if (actualSections.filter(it => !this.actualConfiguration.sectionCompleted[it]).length === 0) {
			this.actualConfiguration.readyToSend = true;
		} else {
			this.actualConfiguration.readyToSend = false;
		}

		const fieldsDone = Object.keys(this.completedNumbers)
			.map(it => this.completedNumbers[it])
			.reduce((x, y) => x + y, 0);

		const fileDone = Object.keys(this.actualConfiguration.sectionCompleted).filter(
			it => ['data', 'bank'].indexOf(it) < 0 && this.actualConfiguration.sectionCompleted[it]
		).length;

		this.actualConfiguration.currentProgress = fieldsDone + fileDone;
		this.configurationUpdatedSubject.next({ ...this.actualConfiguration });
	}

	private checkMangoNaturalData(payload: MangoPersonalData) {
		const missingOnes = this.naturalDataKeys.filter(it => !payload[it]).length;
		const doneOnes = this.naturalDataKeys.length - missingOnes;

		if (missingOnes === 0) {
			// All fields are inserted
			this.actualConfiguration.sectionCompleted.data = true;
		} else {
			this.actualConfiguration.sectionCompleted.data = false;
		}

		this.actualConfiguration.dataPayload = payload;
		this.completedNumbers.data = doneOnes;
	}

	private checkMangoLegalData(payload: MangoLegalData) {
		const missingOnes = this.legalDataKeys.filter(it => !payload[it]).length;
		const doneOnes = this.legalDataKeys.length - missingOnes;

		if (missingOnes === 0) {
			// All fields are inserted
			this.actualConfiguration.sectionCompleted.data = true;
		} else {
			this.actualConfiguration.sectionCompleted.data = false;
		}

		this.actualConfiguration.dataPayload = payload;
		this.completedNumbers.data = doneOnes;
	}

	private checkMangoBankData(payload: MangoBankAccountData) {
		if (payload && payload.IBAN) {
			payload.IBAN = payload.IBAN.replace(' ', '');
		}

		const missingOnes = this.bankDataKeys.filter(it => !payload[it]).length;
		const doneOnes = this.bankDataKeys.length - missingOnes;

		if (missingOnes === 0) {
			// All bank fields inserted
			this.actualConfiguration.sectionCompleted.bank = true;
		} else {
			this.actualConfiguration.sectionCompleted.bank = false;
		}

		this.actualConfiguration.bankPayload = payload;
		this.completedNumbers.bank = doneOnes;
	}

	private checkMangoFileChanged(name: string, fileAdded?: AttachedFile) {
		// We assume that when a files is deleted we send again the event to this service

		this.actualConfiguration.sectionCompleted[name] = fileAdded ? true : false;
		this.actualConfiguration.filesPayload[name] = fileAdded;
	}
}
