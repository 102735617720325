import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LeaseStatus } from 'src/app/models/tenant.model';
import { OwnersService } from 'src/app/owners/owners.service';
import { PropertyService } from 'src/app/properties/property.service';
import { TenantService } from 'src/app/tenants/tenant.service';
import { UnitData } from '../../models/property.model';
import { takeUntil } from 'rxjs/operators';
import { AvailabilityUtils, AvailabilityType } from 'src/app/utils/availability-utils';
import { LeaseService } from 'src/app/leases/lease.service';
import { addYears } from 'date-fns';
import { LocalizationUtils } from 'src/app/utils/localization-utils';
import { BookingsService } from 'src/app/bookings/bookings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterExtService } from 'src/app/services/router-external.service';
import { PaymentsService } from 'src/app/payments/payments.service';

/**
 * This component is simply a container for the default popover.
 * It is just a set of styles to define things as the z-index and such.
 */

export enum EntityType {
	TENANT = 'TENANT',
	OWNER = 'OWNER',
	PROPERTY = 'PROPERTY',
	UNIT = 'UNIT',
	CURRENT_LEASE = 'CURRENT_LEASE',
	COMING_LEASE = 'COMING_LEASE',
	PAST_LEASE = 'PAST_LEASE',
	BOOKING_REQUEST = 'BOOKING_REQUEST',
	BLOCKED_INTERVAL_BOOKING = 'BLOCKED_INTERVAL_BOOKING',
	TRANSACTION = 'TRANSACTION',
	PAYMENT = 'PAYMENT'
}

@Component({
	selector: 'app-roommate-popover',
	templateUrl: './roommate-popover.component.html',
	styleUrls: ['./roommate-popover.component.scss']
})
export class RoommatePopoverComponent implements OnInit, OnDestroy {
	@Input() entityType: EntityType;
	@Input() entityId = '';

	tenantData = null;
	unitData: UnitData = null;
	propertyData = null;
	ownerData = null;
	leaseOrBookingData = null;
	paymentData = null;

	loading = true;
	eLeaseStatus = LeaseStatus;
	private unsubscribe$ = new Subject();
	public translations = LocalizationUtils.getTranslations();

	constructor(
		private tenantService: TenantService,
		private propertyService: PropertyService,
		private ownerService: OwnersService,
		private leaseService: LeaseService,
		private bookingService: BookingsService,
		private paymentsService: PaymentsService,
		public readonly route: ActivatedRoute,
		private readonly routerExtService: RouterExtService
	) {}

	ngOnInit(): void {
		this.loading = true;
		switch (this.entityType) {
			case EntityType.TENANT:
				this.tenantService
					.getTenantDataForPopCard(this.entityId)
					.pipe(takeUntil(this.unsubscribe$))
					.subscribe(data => {
						this.tenantData = {
							id: data.id,
							name: data.name,
							leaseStatus: data.currentLeaseStatus,
							propertyName: data.propertyName
						};
						this.loading = false;
					});
				break;
			case EntityType.OWNER:
				this.ownerService.getOwnerDataForPopCard(this.entityId).then(data => {
					this.ownerData = {
						id: data.id,
						name: data.name,
						status: data.status,
						propertyName: ''
					};
					this.loading = false;
				});
				break;
			case EntityType.PROPERTY:
				this.propertyService
					.getPropertyDataForPopCard(this.entityId)
					.pipe(takeUntil(this.unsubscribe$))
					.subscribe(data => {
						this.propertyData = {
							id: data.id,
							name: data.name,
							city: data.city,
							address: data.address
						};
						this.loading = false;
					});
				break;
			case EntityType.UNIT:
				this.propertyService
					.getUnitDataForPopCard(this.entityId)
					.pipe(takeUntil(this.unsubscribe$))
					.subscribe(data => {
						if (!!data) {
							this.unitData = {
								id: data.id,
								name: data.name,
								propertyId: data.propertyId,
								propertyName: data.propertyName,
								type: data.type
							};
							this.loading = false;
						}
					});
				break;
			case EntityType.PAST_LEASE:
			case EntityType.CURRENT_LEASE:
			case EntityType.COMING_LEASE:
				this.leaseService
					.getLeaseDataForPopCard(this.entityId)
					.pipe(
						takeUntil(this.unsubscribe$),
						switchMap(lease => {
							const tenantId = lease.tenantId;
							return combineLatest([
								of(lease),
								tenantId ? this.tenantService.getTenantNameSurname$(tenantId) : of('')
							]);
						})
					)
					.subscribe(([data, tenantName]) => {
						this.leaseOrBookingData = {
							title: AvailabilityUtils.getTitleForType(this.entityType as AvailabilityType),
							id: data.id,
							currency: data.currency,
							startDate: AvailabilityUtils.getStringDate(new Date(data.entryDate || data.startDate)),
							endDate: AvailabilityUtils.getStringDate(
								new Date(data.exitDate || data.endDate || addYears(data.startDate, 50))
							),
							tenantId: data.tenantId,
							name: tenantName,
							value: data.value,
							color: AvailabilityUtils.getColorForType(this.entityType as AvailabilityType)
						};
						this.loading = false;
					});
				break;
			case EntityType.BLOCKED_INTERVAL_BOOKING:
			case EntityType.BOOKING_REQUEST:
				this.bookingService
					.getBookingDataForPopCard(this.entityId)
					.pipe(
						takeUntil(this.unsubscribe$),
						switchMap(booking => {
							if (booking.type === 'tenant') {
								const tenantId = booking.tenantId ? booking.tenantId : booking.pendingTenantId;
								return combineLatest([of(booking), this.tenantService.getTenantNameSurname$(tenantId)]);
							} else {
								return combineLatest([
									of(booking),
									of($localize`:@@com_blocked_interval:Blocked Interval`)
								]);
							}
						})
					)
					.subscribe(([data, tenantName]) => {
						this.leaseOrBookingData = {
							title: AvailabilityUtils.getTitleForType(this.entityType as AvailabilityType),
							tenantId: data.tenantId,
							name: tenantName,
							startDate: AvailabilityUtils.getStringDate(new Date(data.startDate)),
							endDate: AvailabilityUtils.getStringDate(
								new Date(data.endDate || addYears(data.startDate, 50))
							),
							color: AvailabilityUtils.getColorForType(this.entityType as AvailabilityType)
						};
						this.loading = false;
					});
				break;
			case EntityType.PAYMENT:
				this.paymentsService
					.getPaymentDataForPopCard(this.entityId)
					.pipe(takeUntil(this.unsubscribe$))
					.subscribe(data => {
						this.paymentData = {
							title: data.title,
							assigneeName: data.assigneeName,
							paidOn: data.paidOn,
							invoiced: data.invoiced
						};
						this.loading = false;
					});
				break;
		}
	}

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	goToTenant(tenantId: string) {
		this.routerExtService.navigateHidingUrl(['/tenants', tenantId, 'profile', 'data'], { relativeTo: this.route });
	}

	goToOwner(ownerId: string) {
		this.routerExtService.navigateHidingUrl(['/owners/' + ownerId], { relativeTo: this.route });
	}

	goToProperty(propertyId: string) {
		this.routerExtService.navigateHidingUrl(['/properties', propertyId], { relativeTo: this.route });
	}

	goToUnit(propertyId: string, unitId: string) {
		this.routerExtService.navigateHidingUrl(['/properties', propertyId, 'units', unitId], {
			relativeTo: this.route
		});
	}
}
