import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CalendarEvent, CalendarEventsViewData } from '../../models/calendar';
import { TenantService } from 'src/app/tenants/tenant.service';
import { PropertyService } from 'src/app/properties/property.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import { TenantBasic } from 'src/app/models/tenant.model';
import { Dictionary } from '@ngrx/entity';
import { take, takeUntil } from 'rxjs/operators';
import { LandlordService } from 'src/app/core/services/landlord/landlord.service';

@Component({
	selector: 'app-calendar-events-view-dialog',
	templateUrl: './calendar-events-view-dialog.component.html',
	styleUrls: ['./calendar-events-view-dialog.component.css', '../../shared/styles/lateral-dialogs.css']
})
export class CalendarEventsViewDialogComponent implements OnInit, OnDestroy {
	sortedEvents: CalendarEvent[];
	landlordId: string;
	tenantNames: Dictionary<string> = {};
	propertyNames: Dictionary<string> = {};

	private componentUnsubscribe = new Subject();

	constructor(
		private readonly propertyService: PropertyService,
		public dialogRef: MatDialogRef<CalendarEventsViewDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: CalendarEventsViewData,
		private readonly tenantService: TenantService,
		private landlordService: LandlordService
	) {
		this.sortedEvents = data.events.sort((a, b) => {
			const realStartDate = a.allDay ? 0 : a.startDate;
			return realStartDate - b.startDate;
		});
		this.landlordService.getLandlordId().subscribe(id => (this.landlordId = id));
	}

	ngOnInit() {
		combineLatest([this.tenantService.getTenantsBasic(), this.propertyService.getPropertiesBasicArray()])
			.pipe(takeUntil(this.componentUnsubscribe))
			.subscribe(([tenantsBasic, propertiesBasic]) => {
				for (let i = 0; i < this.sortedEvents.length; i++) {
					if (this.sortedEvents[i].tenantId) {
						const tBasic = tenantsBasic.find(t => t.id === this.sortedEvents[i].tenantId);
						const tName = this.tenantService.getTenantNameSurname(tBasic);
						this.tenantNames[this.sortedEvents[i].tenantId] = tName;
					}

					if (this.sortedEvents[i].propertyId) {
						const pBasic = propertiesBasic.find(t => t.id === this.sortedEvents[i].propertyId);
						const pName = this.propertyService.getPropertyName(pBasic);
						this.propertyNames[this.sortedEvents[i].propertyId] = pName;
					}
				}
			});
	}

	ngOnDestroy() {
		this.componentUnsubscribe.next();
	}

	getEventColor(event) {
		const cat = this.data.categories.find(
			c => c.id.replace(this.landlordId, '') === event.categoryId || c.id === event.categoryId
		);

		return (cat && cat.color) || '#000';
	}

	getTenantName(tid: string) {
		return this.tenantService.getTenantNameById(tid);
	}

	getPropertyName(pid: string) {
		return this.propertyService.getPropertyNameById(pid);
	}

	addEventClick() {
		this.dialogRef.close({ action: 'add_event', currentDate: this.data.currentDate });
	}

	editEventClick(event) {
		this.dialogRef.close({ action: 'edit_event', event });
	}

	deleteEventClick(event) {
		this.dialogRef.close({ action: 'delete_event', event });
	}

	seeTenantClick(event) {
		this.dialogRef.close({ action: 'see_tenant', tid: event.tenantId });
	}

	seePropertyClick(event) {
		this.dialogRef.close({ action: 'see_property', pid: event.propertyId });
	}

	okClick() {
		this.closeClick();
	}

	closeClick() {
		this.dialogRef.close({ action: 'none' });
	}
}
