import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { PropertyOwner } from 'src/app/models/propertyOwner.model';
import { editPropertySuccessAction } from 'src/app/properties/state/properties.actions';
import { resetStoreActionSuccess } from 'src/app/store/actions';
import {
	addNewPropertyToOwnerSuccessAction as addNewPropertyToOwnerSuccessAction,
	loadOwnersPaginatedSuccessAction,
	loadOwnersSuccessAction,
	resetOwnersStateSuccessAction
} from './owners.actions';
import {
	addOwnerSuccessAction,
	deleteOwnerSuccessAction,
	editOwnerSuccessAction,
	loadOwnerSuccessAction
} from './owners.actions';

export interface OwnersBasicState extends EntityState<any> {
	isLoaded: boolean;
}
export const ownersBasicAdapter = createEntityAdapter<any>();
const initialOwnerRequestState = ownersBasicAdapter.getInitialState({
	isLoaded: false
});

export const OwnersBasicReducer = createReducer(
	initialOwnerRequestState,

	on(loadOwnersSuccessAction, (state, payload) => {
		if (payload.owners) {
			if (payload.refreshAll) {
				return ownersBasicAdapter.setAll(payload.owners, { ...state, isLoaded: true });
			}
			return ownersBasicAdapter.upsertMany(payload.owners, { ...state, isLoaded: true });
		} else {
			return state;
		}
	}),

	on(resetOwnersStateSuccessAction, (state, payload) => (state = initialOwnerRequestState)),
	on(editOwnerSuccessAction, addOwnerSuccessAction, loadOwnerSuccessAction, (state, payload) =>
		ownersBasicAdapter.upsertOne(PropertyOwner.getBasicInfo(payload.owner), { ...state, isLoaded: true })
	),
	on(deleteOwnerSuccessAction, (state, payload) =>
		ownersBasicAdapter.removeOne(payload.ownerId, { ...state, isLoaded: true })
	),
	on(editPropertySuccessAction, (state, payload) => {
		if (payload.owners) {
			if (payload.refreshAll) {
				return ownersBasicAdapter.setAll(PropertyOwner.getBasicInfoMultiple(payload.owners), {
					...state,
					isLoaded: true
				});
			}
			return ownersBasicAdapter.upsertMany(PropertyOwner.getBasicInfoMultiple(payload.owners), {
				...state,
				isLoaded: true
			});
		} else {
			return state;
		}
	}),
	on(addNewPropertyToOwnerSuccessAction, (state, payload) =>
		ownersBasicAdapter.updateOne(
			{
				id: payload.ownerId,
				changes: {
					ownedPropertyIds: Array.from(
						new Set([...state.entities[payload.ownerId].ownedPropertyIds, ...payload.propertiesIdsToAdd])
					)
				}
			},
			state
		)
	)
);
