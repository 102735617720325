import { POSITION_MAP } from 'src/app/core/services/popover.service';
import { addMonths, getDaysInMonth, isSameMonth, lastDayOfMonth, subMonths } from 'date-fns';
import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	AfterViewInit,
	OnDestroy,
	ChangeDetectorRef
} from '@angular/core';
import { LocalizationUtils } from 'src/app/utils/localization-utils';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { EntityType } from '../roommate-popover/roommate-popover.component';
import { AvailabilityUtils, AvailabilityType } from 'src/app/utils/availability-utils';
import { Sort } from '@angular/material/sort';
import { AppUtils } from 'src/app/utils/app-utils';

export interface AvailabilityItem {
	id: string;
	type: AvailabilityType;
	name: string;
	value: string | number;
	currency: string;
	startDate: Date;
	endDate: Date;
}

export interface CalendarData {
	propertyId?: string;
	unitId?: string;
	propertyName?: string;
	unitName?: string;
	unitType?: string;
	items: AvailabilityItem[];
}

interface CalendarDisplayableItem {
	id: string;
	color: [number, number, number];
	title: string;
	type: AvailabilityType;
	name: string;
	value: string | number;
	startDate: string;
	endDate: string;
	startDayOfYear: number;
	endDayOfYear: number;
	classes?: string[];
}

interface CalendarDisplayableData {
	title?: string;
	unitId?: string;
	unitName?: string;
	propertyId?: string;
	propertyName?: string;
	unitType?: string;
	items: CalendarDisplayableItem[];
}

@Component({
	selector: 'app-roommate-calendar',
	templateUrl: './roommate-calendar.component.html',
	styleUrls: ['./roommate-calendar.component.scss']
})
export class RoommateCalendarComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
	@Input()
	public mainCalendarTitle: string;

	@Input()
	public calendarTitle: string;

	@Input()
	public data: CalendarData[] = [];

	@Input()
	public selectedYear: number = new Date().getFullYear();

	@Input()
	public selectedMonth: number = new Date().getMonth();

	@Input()
	public suggestedHeight: string = '300px';

	@Output()
	public itemClicked = new EventEmitter<{ rowIndex: number; itemId: string }>();

	@Output()
	public itemTitleClicked = new EventEmitter<number>();

	@Output()
	public currentYearChanged = new EventEmitter<number>();
	public scrollWidth = '0';
	public translations = LocalizationUtils.getTranslations();
	public displayedRows: CalendarDisplayableData[];
	public displayedRowsOriginal: CalendarDisplayableData[];
	public slice = 100 / 365;
	public daysPerMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
	public years: number[] = [];
	public monthSelected = -1;
	public months = LocalizationUtils.getTranslations().months.map(month => AvailabilityUtils.capitalizeString(month));
	public monthsDisplayed = LocalizationUtils.getTranslations().months.map(month =>
		AvailabilityUtils.capitalizeString(month)
	);
	public leftValue = 30;
	public readonly titlePopoverPosition = POSITION_MAP.rightBottom;
	private mutationObserver: MutationObserver;
	@ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

	public entityType = EntityType;

	public firstPartCurrentYear: boolean = false;
	public secondPartCurrentYear: boolean = false;
	public flexFirstPart: number = 334;
	public flexSecondPart: number = this.daysPerMonth[11];

	public firstDate: Date;
	public lastDate: Date;

	public withoutScroll = false;

	public sort: Sort = {
		active: 'unit',
		direction: null
	};

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.selectedYear && !changes.selectedYear.isFirstChange) {
			this.moveToYear(changes.selectedYear.currentValue);
		} else {
			this.setupData();
		}

		if (!!this.virtualScroll) {
			if (
				this.displayedRows.length === 1 ||
				this.displayedRows.length * 50 < this.virtualScroll.elementRef.nativeElement.offsetHeight
			) {
				this.withoutScroll = true;
			}
			this.virtualScroll.scrollToIndex(0);
		}
	}

	ngAfterViewInit(): void {
		this.mutationObserver = new MutationObserver(mutations => {
			this.scrollWidth = `${
				this.virtualScroll.elementRef.nativeElement.offsetWidth -
				this.virtualScroll.elementRef.nativeElement.clientWidth
			}px`;
		});

		this.mutationObserver.observe(this.virtualScroll.elementRef.nativeElement.firstChild, {
			attributes: true,
			characterData: true
		});
	}

	ngOnDestroy(): void {
		this.mutationObserver.disconnect();
	}

	public onItemClicked(item: CalendarDisplayableItem, index: number): void {
		this.itemClicked.emit({
			rowIndex: index,
			itemId: item.id
		});
	}

	public onItemTitleClicked(propertyId: string, unitId: string): void {
		this.data.forEach((row, indx) => {
			if (row.propertyId === propertyId && row.unitId === unitId) {
				this.itemTitleClicked.emit(indx);
			}
		});
	}

	public moveToYear(moveTo: string): void {
		if (moveTo === 'previous') {
			this.selectedYear--;
		} else {
			this.selectedYear++;
		}

		this.setupData();

		//this.currentYearChanged.emit(this.selectedYear);
	}

	private setupData(): void {
		this.setDatesOfCalendar();
		this.displayedRows = [];
		this.data.forEach(row => {
			const items: CalendarDisplayableItem[] = this.setupItems(row.items);
			this.displayedRows.push({
				title: row.unitName,
				unitId: row.unitId,
				unitType: row.unitType,
				unitName: row.unitName,
				propertyId: row.propertyId,
				propertyName: row.propertyName,
				items
			});
		});

		this.displayedRowsOriginal = AppUtils.deepCopy(this.displayedRows);

		this.sortData('unitName');

		this.cdr.detectChanges();
	}

	private setupItems(items: AvailabilityItem[]): CalendarDisplayableItem[] {
		const displayableItems: CalendarDisplayableItem[] = [];
		items.forEach(item => {
			if (item.endDate < item.startDate) {
				// TODO: Add error to log (error end date cannot be before start date)
				return;
			}
			const startDayOfYear = this.getDayWithinTheMonths(item.startDate);
			const endDayOfYear = this.getDayWithinTheMonths(item.endDate);

			if ((startDayOfYear < 0 && endDayOfYear < 0) || startDayOfYear === 366) {
				return; // Don't show items that are not on the year we are looking at
			}

			displayableItems.push({
				...item,
				title: AvailabilityUtils.getTitleForType(item.type),
				color: AvailabilityUtils.getColorForType(item.type),
				startDate: AvailabilityUtils.getStringDate(item.startDate),
				endDate: AvailabilityUtils.getStringDate(item.endDate),
				startDayOfYear,
				endDayOfYear
			});
		});

		return displayableItems;
	}

	private getDayWithinTheMonths(date: Date): number {
		const daysBetweenFirstDate = this.getDaysBetweenDates(this.firstDate, date);
		const daysBetweenLastDate = this.getDaysBetweenDates(this.lastDate, date);

		if (daysBetweenFirstDate > 0 && daysBetweenLastDate > 0) {
			return 366;
		}

		if (daysBetweenFirstDate < 0) {
			return -1;
		}

		if (daysBetweenLastDate <= 0) {
			return daysBetweenFirstDate;
		}
	}

	private getDaysBetweenDates(date1: Date, date2: Date): number {
		let timeInMilisec: number = date2.getTime() - date1.getTime();
		return Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24));
	}

	private setDatesOfCalendar() {
		this.years = [];

		if (!this.selectedYear) {
			this.selectedYear = new Date().getFullYear();
		}
		if (!this.selectedMonth) {
			this.selectedMonth = new Date().getMonth();
		}

		this.firstDate = subMonths(new Date(this.selectedYear, this.selectedMonth), 5);
		this.lastDate = lastDayOfMonth(addMonths(new Date(this.selectedYear, this.selectedMonth), 6));

		const firstYear = this.firstDate.getFullYear();
		const lastYear = this.lastDate.getFullYear();

		this.setDaysPerMonth(firstYear, this.firstDate.getMonth());

		this.years.push(firstYear);
		if (firstYear !== lastYear) {
			this.years.push(lastYear);
			this.flexFirstPart = this.getDaysBetweenDates(this.firstDate, lastDayOfMonth(new Date(firstYear, 11)));
			this.flexSecondPart = this.getDaysBetweenDates(new Date(lastYear, 0), this.lastDate);
		} else {
			this.flexFirstPart =
				this.daysPerMonth.reduce((sumOfDays, days) => {
					return sumOfDays + days;
				}, 0) - this.daysPerMonth[11];
			this.flexSecondPart = this.daysPerMonth[11];
		}

		this.firstPartCurrentYear = this.selectedYear === firstYear;
		this.secondPartCurrentYear = this.selectedYear === lastYear;
	}

	private setDaysPerMonth(year: number, month: number) {
		const currentMont = new Date();
		this.monthSelected = -1;
		//set days per month to show, starting from the year and month parameters
		for (let i = 0; i < 12; i++) {
			let thisMonth = new Date(year, month);

			if (this.monthSelected === -1) {
				this.monthSelected = isSameMonth(currentMont, thisMonth) ? i : -1;
			}

			this.daysPerMonth[i] = getDaysInMonth(thisMonth);
			this.monthsDisplayed[i] = this.months[month];
			year = month + 1 > 11 ? year + 1 : year;
			month = month + 1 > 11 ? 0 : month + 1;
		}

		this.slice =
			100 /
			this.daysPerMonth.reduce((sumOfDays, days) => {
				return sumOfDays + days;
			}, 0);
	}

	private sortData(columnToSort: string) {
		if (!this.sort.direction) {
			this.displayedRows = AppUtils.deepCopy(this.displayedRowsOriginal);
		} else {
			this.displayedRows = AppUtils.deepCopy(
				this.sortByTextAttribute(
					this.displayedRows,
					columnToSort,
					columnToSort === 'unitName' ? 'unitName' : 'propertyName'
				)
			);
		}
	}

	public sortByTextAttribute(
		data: CalendarDisplayableData[],
		propertyAttribute: string,
		secondSortPropertyAttribute: string
	) {
		if (data.length > 0) {
			if (this.sort.direction === 'asc') {
				console.log(secondSortPropertyAttribute, 'asc');
				return data.sort((a, b) =>
					(a[propertyAttribute] as any).toLowerCase() < (b[propertyAttribute] as any).toLowerCase()
						? -1
						: (a[propertyAttribute] as any).toLowerCase() === (b[propertyAttribute] as any).toLowerCase() &&
						  (a[secondSortPropertyAttribute] as any).toLowerCase() <
								(b[secondSortPropertyAttribute] as any).toLowerCase()
						? -1
						: 1
				);
			} else if (this.sort.direction === 'desc') {
				console.log(secondSortPropertyAttribute, 'desc');
				return data.sort((a, b) =>
					(a[propertyAttribute] as any).toLowerCase() < (b[propertyAttribute] as any).toLowerCase()
						? 1
						: (a[propertyAttribute] as any).toLowerCase() === (b[propertyAttribute] as any).toLowerCase() &&
						  (a[secondSortPropertyAttribute] as any).toLowerCase() <
								(b[secondSortPropertyAttribute] as any).toLowerCase()
						? 1
						: -1
				);
			}
		} else {
			return data;
		}
	}

	public onSortDataClicked(columnToSort: string) {
		if (!this.sort.direction) {
			this.sort.direction = 'desc';
		} else {
			this.sort.direction = this.sort.direction === 'desc' ? 'asc' : null;
		}

		this.sortData(columnToSort);
	}
}
