import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { EffectInteractionService } from 'src/app/core/services/effectInteraction.service';
import { LandlordService } from 'src/app/core/services/landlord/landlord.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { Booking } from 'src/app/models/booking.model';
import { CalendarEvent } from 'src/app/models/calendar';
import { AvailabilityQueryOptions, Lease, LeaseAddOptions, SignatureDatabaseDetails } from 'src/app/models/lease.model';
import { PropertyPayment } from 'src/app/models/payments';
import { Tenant } from 'src/app/models/tenant.model';
import { PropertyCreationResult } from 'src/app/properties/state/properties.effects';
import { CalendarData } from 'src/app/shared/roommate-calendar/roommate-calendar.component';
import { resetStoreAction } from 'src/app/store/actions';
import { loadTenantByIdAction } from 'src/app/tenants/state/tenant.actions';
import { AppUtils } from 'src/app/utils/app-utils';
import { LocalizationUtils } from 'src/app/utils/localization-utils';
import { environment } from 'src/environments/environment';
import { EsignatureRequestPayload } from '../lease-add/lease-add.component';
import { LeaseDepositStatus, LeaseService } from '../lease.service';
import {
	addLeaseSignatureAction,
	addLeaseSignatureFailureAction,
	addLeaseSignatureSuccessAction,
	addMockLeasesAction,
	addMockLeasesSuccessAction,
	createLeaseAction,
	createLeaseFailureAction,
	createLeaseSuccessAction,
	getLeaseCsvStringAction,
	getLeaseCsvStringFailureAction,
	getLeaseCsvStringSuccessAction,
	loadAvailabilityAction,
	loadAvailabilityFailureAction,
	loadAvailabilitySuccessAction,
	loadLeaseByIdAction,
	loadLeaseByIdSuccessAction,
	loadLeaseDepositStatusAction,
	loadLeaseDepositStatusFailureAction,
	loadLeaseDepositStatusSuccessAction,
	loadLeasesAction,
	loadLeasesFailureAction,
	loadLeasesSuccessAction,
	removeLeaseAction,
	removeLeaseFailureAction,
	removeLeaseSuccessAction,
	resetLeaseStateAction,
	resetLeaseStateFailureAction,
	resetLeaseStateSuccessAction,
	updateLeaseAction,
	updateLeaseDepositAction,
	updateLeaseDepositFailureAction,
	updateLeaseDepositSuccessAction,
	updateLeaseFailureAction,
	updateLeaseSuccessAction
} from './lease.actions';

export interface LeaseCreateResult {
	lease: Lease;
	payments: PropertyPayment[];
	booking?: Booking;
	tenant: Tenant;
}
export interface LeaseUpdateResult {
	lease: Lease;
	tenant?: Tenant;
	payments?: PropertyPayment[];
	recurringPayment?: any;
	calendarEvents?: CalendarEvent[];
	deletedCalendarEventIds?: string[];
	deletedPaymentIds?: string[];
	notTouchedPayments?: NotTouchedPayment[];
}

export interface NotTouchedPayment {
	payment: PropertyPayment;
	reason: 'mango_pay_in_process' | 'updated' | 'payment_operation_made';
}

export interface LeaseDeleteResult {
	deletedLeaseId: string;
	deletedPaymentIds?: string[];
	deleteRecurringPaymentId?: string[];
	deletedCalendarEventIds?: string[];
	tenant?: Tenant;
}

@Injectable()
export class LeaseEffects {
	private BACKEND_HOST = `${environment.services.backend}/api-dash/v1`;
	private landlordId: string;
	private translations = LocalizationUtils.getTranslations();

	constructor(
		private readonly leaseService: LeaseService,
		private readonly landlordService: LandlordService,
		private readonly httpClient: HttpClient,
		private readonly actions$: Actions,
		private readonly loadingService: LoadingService,
		private readonly store: Store,
		private readonly effectInteractionService: EffectInteractionService
	) {
		this.landlordService.getLandlordId().subscribe(id => (this.landlordId = id));
	}

	public resetStoreState = createEffect(() =>
		this.actions$.pipe(
			ofType(resetStoreAction),
			switchMap(() => of(resetLeaseStateAction()))
		)
	);

	public resetLeaseStateEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(resetLeaseStateAction),
			switchMap(() => of(resetLeaseStateSuccessAction())),
			catchError(() => of(resetLeaseStateFailureAction({})))
		)
	);

	public loadLeasesEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadLeasesAction),
			switchMap(action =>
				this.loadLeases().pipe(
					map(leases => loadLeasesSuccessAction({ leases })),
					catchError(err => of(loadLeasesFailureAction({})))
				)
			)
		)
	);

	public updateLeaseDepositEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(updateLeaseDepositAction),
			mergeMap(action =>
				this.updateLeaseDeposit(action.leaseId, action.deposit).pipe(
					map(depositUpdateResult => {
						if (depositUpdateResult['lease']) {
							return updateLeaseDepositSuccessAction({
								lease: Lease.sanitize(depositUpdateResult['lease'])
							});
						} else {
							return updateLeaseDepositSuccessAction({
								lease: Lease.sanitize(depositUpdateResult as Lease)
							});
						}
					}),
					catchError(err => of(updateLeaseDepositFailureAction({})))
				)
			)
		)
	);

	public addLeaseSignatureEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(addLeaseSignatureAction),
			mergeMap(action =>
				this.addLeaseSignature(action.esignaturePayload, action.hostedPageId).pipe(
					map(signatureDetails =>
						addLeaseSignatureSuccessAction({ leaseId: action.esignaturePayload.leaseId, signatureDetails })
					),
					catchError(err =>
						of(
							addLeaseSignatureFailureAction({
								errorMessage:
									this.handlePhoneError(err) ||
									this.getErrorMessage(err?.error?.data?.detail || 'Generic Error')
							})
						)
					)
				)
			)
		)
	);

	public createLeaseEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(createLeaseAction),
			mergeMap(action =>
				this.createLease(action.lease, action.options).pipe(
					map(result => {
						const leaseCreated = Lease.sanitize(result.lease);

						this.effectInteractionService.callbackSuccess(action, leaseCreated);

						let sanitizedTenant = Tenant.sanitize(result.tenant);
						sanitizedTenant.currentLeaseStatus = this.leaseService.getTenantCurrentLeaseStatus(
							sanitizedTenant,
							{ [leaseCreated.id]: leaseCreated }
						);

						return createLeaseSuccessAction({
							lease: leaseCreated,
							payments: result.payments,
							booking: result.booking,
							tenant: sanitizedTenant
						});
					}),
					catchError(errorResponse => {
						this.effectInteractionService.callbackFail(errorResponse);
						return of(createLeaseFailureAction({ errorName: errorResponse?.error?.name || '' }));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);
	public removeLeaseEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(removeLeaseAction),
			mergeMap(action =>
				this.removeLease(action.lease).pipe(
					map(result => {
						if (result['leaseId']) {
							// Old version

							// We refresh the tenants involved with the lease
							Object.values(action.lease.tenantsId).map(tenantId =>
								this.store.dispatch(loadTenantByIdAction({ tenantId }))
							);

							return removeLeaseSuccessAction({
								deletedLeaseId: result['leaseId'],
								deletedPaymentIds: [],
								deleteRecurringPaymentId: [],
								deletedCalendarEventIds: []
							});
						} else {
							// New version
							return removeLeaseSuccessAction(result as LeaseDeleteResult);
						}
					}),
					catchError(err => of(removeLeaseFailureAction({})))
				)
			)
		)
	);

	public updateLeaseEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(updateLeaseAction),
			mergeMap(action =>
				this.updateLease(action.lease).pipe(
					map(leaseResult => {
						this.effectInteractionService.callbackSuccess(action, leaseResult);
						return updateLeaseSuccessAction(leaseResult);
					}),
					catchError(err => of(updateLeaseFailureAction({}))),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public loadLeaseByIdEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadLeaseByIdAction),
			mergeMap(action =>
				this.getLeaseById(action.leaseId).pipe(
					map(lease => {
						this.effectInteractionService.callbackSuccess(action);
						return loadLeaseByIdSuccessAction({ lease });
					}),
					catchError(err => {
						this.effectInteractionService.callbackFail(action);
						return of(loadLeasesFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public getLeaseCsvStringEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(getLeaseCsvStringAction),
			mergeMap(action =>
				this.getLeaseCsvString().pipe(
					map(url => getLeaseCsvStringSuccessAction({ url })),
					catchError(() => of(getLeaseCsvStringFailureAction({})))
				)
			)
		)
	);

	public addMockLeasesEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(addMockLeasesAction),
			mergeMap(action =>
				this.generateMockLeases().pipe(
					map(result => {
						this.effectInteractionService.callbackSuccess(action);

						for (let i = 0; i < result.leases.length; i++) {
							result.leases[i].lease = Lease.sanitize(result.leases[i].lease);
							if (result.leases[i].tenant) {
								let sanitizedTenant = Tenant.sanitize(result.leases[i].tenant);
								sanitizedTenant.currentLeaseStatus = this.leaseService.getTenantCurrentLeaseStatus(
									sanitizedTenant,
									{ [result.leases[i].lease.id]: result.leases[i].lease }
								);
								result.leases[i].tenant = sanitizedTenant;
							}
						}

						return addMockLeasesSuccessAction({ leases: result.leases, property: result.property });
					}),
					catchError(errorResponse => {
						this.effectInteractionService.callbackFail(action);
						return of(createLeaseFailureAction({ errorName: errorResponse?.error?.name || '' }));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public loadAvailabilityEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadAvailabilityAction),
			mergeMap(action =>
				this.loadAvailability(action.options).pipe(
					map(result => loadAvailabilitySuccessAction({ availability: result })),
					catchError(error => of(loadAvailabilityFailureAction({ error: error })))
				)
			)
		)
	);

	public loadLeaseDepositStatusEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadLeaseDepositStatusAction),
			mergeMap(action =>
				this.loadLeaseDepositStatus(action.leaseId).pipe(
					map(depositStatusObj => {
						this.effectInteractionService.callbackSuccess(action, depositStatusObj.status);
						return loadLeaseDepositStatusSuccessAction({
							leaseId: depositStatusObj.leaseId,
							depositStatus: depositStatusObj.status
						});
					}),
					catchError(err => {
						this.effectInteractionService.callbackFail(action);
						return of(loadLeaseDepositStatusFailureAction(err));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	/*** Effects handle success and failure actions  ***/

	public addLeaseSignatureSuccessEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(addLeaseSignatureSuccessAction),
				tap(action => {
					this.loadingService.hide();

					if (
						action.signatureDetails &&
						action.signatureDetails.users &&
						action.signatureDetails.users.length > 1
					) {
						this.leaseService.showDigitalSignatureCompletedDialog(action.signatureDetails.landlordUrl);
					}
				})
			),
		{ dispatch: false }
	);

	public addLeaseSignatureFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(addLeaseSignatureFailureAction),
				tap(action => {
					this.loadingService.hide();
					this.effectInteractionService.showDialogError(action.errorMessage);
				})
			),
		{ dispatch: false }
	);

	public createLeaseFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(createLeaseFailureAction),
				tap(action => {
					// Generic error message
					let errorStr = $localize`:@@create_lease_failed:Something went wrong during the creation of the lease. Try again and if the problem persists contact us.`;

					// Specific error messages
					if (action.errorName === 'OperationNotAllowedByMaxMockLimitQuotaError') {
						errorStr = $localize`:@@add_property_failed_max_mock_limitation:This operation is not allowed because it exceeds mock creation limit quota`;
					} else if (action.errorName === 'ExternalServiceFailedError') {
						errorStr = $localize`:@@add_tenant_failed_external_service:Something went wrong during an external service execution. Try again and if the problem persists, contact us.`;
					}

					this.effectInteractionService.showDialogError(errorStr);
				})
			),
		{ dispatch: false }
	);

	public removeLeaseFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(removeLeaseFailureAction),
				tap(() => {
					this.loadingService.hide();
					this.effectInteractionService.showDialogError(
						$localize`:@@remove_lease_failed:Something went wrong during the delete of the lease. Try again and if the problem persists contact us.`
					);
				})
			),
		{ dispatch: false }
	);

	public updateLeaseSuccessEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(updateLeaseSuccessAction),
				tap(() =>
					this.effectInteractionService.displaySuccessToast(
						this.translations.toast.lease_terminate_successfully_title,
						this.translations.toast.lease_terminate_successfully_msg
					)
				)
			),
		{ dispatch: false }
	);

	public updateLeaseFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(updateLeaseFailureAction),
				tap(() =>
					this.effectInteractionService.showDialogError(
						$localize`:@@update_lease_failed:Something went wrong during the update of the lease. Try again and if the problem persists contact us.`
					)
				)
			),
		{ dispatch: false }
	);

	public getLeaseCsvStringSuccessEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(getLeaseCsvStringSuccessAction),
				tap(action => {
					this.doDownload(action.url);
					this.effectInteractionService.displaySuccessToast(
						this.translations.toast.get_csv_string_title,
						this.translations.toast.get_csv_string_msg
					);
				})
			),
		{ dispatch: false }
	);

	public genericLeaseFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(
					getLeaseCsvStringFailureAction,
					loadLeasesFailureAction,
					resetLeaseStateFailureAction,
					updateLeaseDepositFailureAction
				),
				tap(() => this.loadingService.hideAndDisplayErrorToast())
			),
		{ dispatch: false }
	);

	public addMockLeasesSuccessEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(addMockLeasesSuccessAction),
				tap(() => {
					this.effectInteractionService.displaySuccessToast(
						this.translations.toast.lease_added_title,
						this.translations.toast.lease_added_msg
					);
				})
			),
		{ dispatch: false }
	);

	/***  ***/

	// Private calls
	private loadLeases(): Observable<Lease[]> {
		if (!this.landlordId) {
			return throwError('Error on loading leases. No landlordId is present');
		}

		return this.httpClient.get<Lease[]>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/leases`).pipe(
			map(leases => {
				const sanitizedLeases = leases.map(lease => Lease.sanitize(lease));
				return sanitizedLeases;
			})
		);
	}

	private updateLeaseDeposit(leaseId: string, deposit: number): Observable<LeaseUpdateResult | Lease> {
		return this.httpClient.post<Lease>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/leases/${leaseId}/deposit`,
			{ deposit }
		);
	}

	private addLeaseSignature(
		esignaturePayload: EsignatureRequestPayload,
		hostedPageId?: string
	): Observable<SignatureDatabaseDetails> {
		// Error checking before proceding - I assume there is only one tenant
		this.loadingService.show($localize`:@@sing_signing_in_progress:Starting signing procedure...`);

		return this.httpClient.post<SignatureDatabaseDetails>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/leases/${esignaturePayload.leaseId}/esignature`,
			{
				tenantsId: [esignaturePayload.tenantId],
				files: esignaturePayload.files,
				onlyTenantSigning: esignaturePayload.onlyTenantSigning,
				hostedPageId
			}
		);
	}

	private createLease(lease: Lease, options: LeaseAddOptions): Observable<LeaseCreateResult> {
		return this.httpClient.post<{ lease: Lease; payments: PropertyPayment[]; booking?: Booking; tenant: Tenant }>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/leases`,
			{
				lease,
				options
			}
		);
	}

	private removeLease(lease: Lease): Observable<LeaseDeleteResult | { result: string }> {
		return this.httpClient.delete<{ result: string }>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/leases/${lease.id}`
		);
	}

	private updateLease(lease: Lease): Observable<LeaseUpdateResult> {
		return this.httpClient
			.put<Lease | LeaseUpdateResult>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/leases/${lease.id}`, {
				lease
			})
			.pipe(
				map(leaseResult => {
					if (leaseResult['lease']) {
						return leaseResult as LeaseUpdateResult;
					} else {
						return { lease: leaseResult as Lease, payments: [] };
					}
				})
			);
	}

	private getLeaseCsvString(): Observable<string> {
		return this.httpClient.get(`${this.BACKEND_HOST}/landlords/${this.landlordId}/leases?format=csv&type=csv`, {
			responseType: 'text'
		});
	}

	private getLeaseById(leaseId: string): Observable<Lease> {
		return this.httpClient
			.get<Lease>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/leases/${leaseId}`)
			.pipe(map(result => Lease.sanitize(result)));
	}

	private doDownload(url: string) {
		const element = document.createElement('a');
		const fileType = 'text/csv';
		element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(url)}`);
		element.setAttribute('download', `roommate-leasea-${AppUtils.getDateString()}.csv`);
		const event = new MouseEvent('click');
		element.dispatchEvent(event);
	}

	private handlePhoneError(error: HttpErrorResponse) {
		if (error && error.error && error.error.extraParams) {
			const extraParams = JSON.parse(error.error.extraParams[0]);
			if (extraParams && extraParams.violations && extraParams.violations.length > 0) {
				const phoneNumberViolation = extraParams.violations.find(violation =>
					violation.propertyPath.includes('phone')
				);
				if (phoneNumberViolation) {
					// Display a specific error message for an invalid phone number
					return $localize`:@@signature_error_invalid_phone_error:Invalid phone number: ${phoneNumberViolation.message}`;
					// You can show this error message to the user using a toast, modal, or any other UI element
				}
			}
		}

		return null;
	}

	private getErrorMessage(possibleYousignMessage: string) {
		let sentMessage = '';

		if (possibleYousignMessage.includes('The string supplied did not seem to be a phone number')) {
			sentMessage = $localize`:@@signature_error_phone_number:Error with Phone number. Please check phone number of you and the tenant`;
		} else if (possibleYousignMessage.includes('The given file is not signable')) {
			sentMessage = $localize`:@@signature_error_not_signable:One of the documents provided is not signable. Check if one of them is protected or already signed and, in case the issue persists, contact us.`;
		} else if (possibleYousignMessage.includes('Your file is not signable')) {
			sentMessage = $localize`:@@signature_error_not_signable:One of the documents provided is not signable. Check if one of them is protected or already signed and, in case the issue persists, contact us.`;
		} else if (possibleYousignMessage.includes('phone') && possibleYousignMessage.includes('invalid')) {
			sentMessage = $localize`:@@signature_error_phone_number:Error with Phone number. Please check phone number of you and the tenant`;
		} else {
			sentMessage = possibleYousignMessage;
		}

		return (
			$localize`:@@signature_complete_msg_error:Something went wrong during the signature process. You can see the details below.` +
			`<br/>${sentMessage}`
		);
	}

	private generateMockLeases(): Observable<{
		leases: LeaseCreateResult[];
		tenants?: Tenant[];
		property?: PropertyCreationResult;
	}> {
		return this.httpClient.post<{
			leases: LeaseCreateResult[];
			tenants?: Tenant[];
			property?: PropertyCreationResult;
		}>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/mock/leases`, {});
	}

	private loadAvailability(options: AvailabilityQueryOptions): Observable<CalendarData[]> {
		return this.httpClient
			.get<CalendarData[]>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/availability`, {
				params: {
					...options
				}
			})
			.pipe(
				map(availability => {
					availability.forEach(a =>
						a.items.forEach(item => {
							item.startDate = new Date(item.startDate);
							item.endDate = new Date(item.endDate);
						})
					);

					return availability;
				})
			);
	}

	private loadLeaseDepositStatus(leaseId: string): Observable<{ leaseId: string; status: LeaseDepositStatus }> {
		const requestUrl = `${this.BACKEND_HOST}/landlords/${this.landlordId}/leases/${leaseId}/depositStatus`;
		return this.httpClient.get<{ leaseId: string; status: LeaseDepositStatus }>(requestUrl);
	}
}
