<div mat-dialog-content>
	<input type="text" style="display: none" cdkFocusInitial />

	<div fxLayout="column" fxLayoutAlign="flex-start center" class="oneshot-top-section">
		<img class="premium-img" src="./assets/img/rm_premium_icon.png" />

		<p class="semi-bold-25 color-white no-margin" i18n="@@conditions_changing_dialog_title">
			<span class="color-orange">Conditions changing</span>&nbsp;
		</p>

		<div fxLayout="row" fxLayoutAlign="flex-end center" style="width: 100%; margin: -70px 30px 0px 0px">
			<img
				src="./assets/img/close.svg"
				class="rf-is-clickable"
				(click)="closeClick()"
				style="width: 15px; height: 15px"
			/>
		</div>
	</div>

	<p class="regular-15 text-center" style="margin: 20px 0px 0px 0px" i18n="@@conditions_changing_dialog_desc">
		Hi&nbsp;<span class="semi-bold-15">{{ getLandlordName() }}</span
		><br />
		We're updating the terms of our <span class="semi-bold-15">free-plan</span> effective from January 6th, 2024.<br />
		Please visit our new
		<span class="semi-bold-15"><a href="https://the-roommate.com/pricing" target="_blank">website</a></span> for
		detailed information. You will also be able to <span class="semi-bold-15">upgrade</span> your subscription to
		the new alternatives directly from Estelle.
	</p>
	<p class="regular-15 text-center" style="margin: 20px 0px 0px 0px" i18n="@@conditions_changing_dialog_desc1">
		Below, is a summary of the key updates that will impact <span class="semi-bold-15">your experience</span> with
		us:<br />
	</p>

	<div fxLayout="row" fxLayoutAlign="center center">
		<table>
			<tr>
				<th></th>
				<th>Before</th>
				<th>After</th>
			</tr>
			<tr>
				<td>Max Number of Units</td>
				<td>9</td>
				<td>3</td>
			</tr>
			<tr>
				<td>Owners/Maintenance Section</td>
				<td>Available</td>
				<td>No longer available</td>
			</tr>
			<tr>
				<td>E-Payments</td>
				<td>1€ per transaction</td>
				<td>Included</td>
			</tr>
		</table>
	</div>

	<ng-template #separatorSpace>
		<div style="height: 30px; width: 100%"></div>
	</ng-template>

	<div fxLayout="column" fxLayoutAlign="flex-start center">
		<mat-checkbox [checked]="confirmCheckbox" (change)="selectionChange($event)" style="margin-bottom: 20px">
			<span i18n="@@acknowledged_conditios_dialog">I have read and acknowledged the changing conditions</span>
		</mat-checkbox>
		<button class="confirm-button" (click)="confirmClick()" i18n="@@com_confirm" style="margin-bottom: 20px">
			Confirm
		</button>
	</div>
</div>
