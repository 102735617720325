import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ChatFilters {
	global: string;
	onlyUnread: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class ChatFilterBroadcastService {
	/* Default filters for each type */
	defaultChatFilter: ChatFilters = {
		global: '',
		onlyUnread: false
	};

	/* Subscribers can always get the latest value. The first one are the ones above */

	private chatFilterSource = new BehaviorSubject<ChatFilters>({ ...this.defaultChatFilter });
	public chatFilterBroadcast: Observable<ChatFilters> = this.chatFilterSource.asObservable();

	constructor() {}

	/* Publish a new filter and broadcast to all subscribers */
	publishNewchatFilter(filter: ChatFilters) {
		this.chatFilterSource.next({ ...filter });
	}

	resetChatFilters() {
		this.chatFilterSource.next({ ...this.defaultChatFilter });
	}
}
