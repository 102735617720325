import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/store/reducer';
import { channelManagerSyncUnitPagesAdapter } from './cm-sync-units-pages.reducers';

const selectChannelManagerSyncUnitPagesState = createSelector(
	state => state,
	(state: AppState) => state.channelManagerSyncUnitPages
);

export const { selectTotal: selectChannelManagerSyncUnitPagesLength } = channelManagerSyncUnitPagesAdapter.getSelectors(
	selectChannelManagerSyncUnitPagesState
);

export const selectChannelManagerSyncUnitPagesEntities = createSelector(
	selectChannelManagerSyncUnitPagesState,
	state => {
		if (state.isLoaded) {
			return state.entities;
		}
		return null;
	}
);
