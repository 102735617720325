import { createAction, props } from '@ngrx/store';
import { CalendarCategory, CalendarEvent } from 'src/app/models/calendar';

export enum CalendarActions {
	RESET_CALENDAR_STATE = '[Calendar] Reset Calendar State',
	RESET_CALENDAR_STATE_SUCCESS = '[Calendar] Reset Calendar State Success',
	RESET_CALENDAR_STATE_FAILURE = '[Calendar] Reset Calendar State Failure'
}

export enum CalendarCategoryActions {
	LOAD_CATEGORIES = '[Calendar] Load Categories',
	CREATE_CATEGORY = '[Calendar] Create Category',
	UPDATE_CATEGORY = '[Calendar] Update Category',
	DELETE_CATEGORY = '[Calendar] Delete Category',
	FIX_CATEGORIES_ORDER = '[Calendar] Fix Categories Order',
	LOAD_CATEGORIES_SUCCESS = '[Calendar] Load Categories Success',
	CREATE_CATEGORY_SUCCESS = '[Calendar] Create Category Success',
	UPDATE_CATEGORY_SUCCESS = '[Calendar] Update Category Success',
	DELETE_CATEGORY_SUCCESS = '[Calendar] Delete Category Success',
	FIX_CATEGORIES_ORDER_SUCCESS = '[Calendar] Fix Categories Order Success',
	LOAD_CATEGORIES_FAILURE = '[Calendar] Load Categories Failure',
	CREATE_OR_UPDATE_CATEGORY_FAILURE = '[Calendar] Create Category Failure',
	UPDATE_CATEGORY_FAILURE = '[Calendar] Update Category Failure',
	DELETE_CATEGORY_FAILURE = '[Calendar] Delete Category Failure',
	FIX_CATEGORIES_ORDER_FAILURE = '[Calendar] Fix Categories Order Failure'
}

export enum CalendarEventActions {
	LOAD_EVENTS_FROM_DATE_INTERVAL = '[Calendar] Load Events From Date Interval',
	CREATE_EVENT = '[Calendar] Create Event',
	DELETE_EVENT = '[Calendar] Delete Event',
	UPDATE_EVENT = '[Calendar] Update Event',

	LOAD_EVENTS_FROM_DATE_INTERVAL_SUCCESS = '[Calendar] Load Events From Date Interval Success',
	CREATE_EVENT_SUCCESS = '[Calendar] Create Event Success',
	UPDATE_EVENT_SUCCESS = '[Calendar] Update Event Success',
	DELETE_EVENT_SUCCESS = '[Calendar] Delete Event Success',

	CREATE_EVENT_FAILURE = '[Calendar] Create Event Failure',
	UPDATE_EVENT_FAILURE = '[Calendar] Update Event Failure',
	DELETE_EVENT_FAILURE = '[Calendar] Delete Event Failure',
	LOAD_EVENTS_FROM_DATE_INTERVAL_FAILURE = '[Calendar] Load Events From Date Interval Failure'
}

// Categories
export const fixCategoriesOrderAction = createAction(CalendarCategoryActions.FIX_CATEGORIES_ORDER, props<{ categories: CalendarCategory[] }>());
export const fixCategoriesOrderSuccessAction = createAction(CalendarCategoryActions.FIX_CATEGORIES_ORDER_SUCCESS, props<{ categories: CalendarCategory[] }>());
export const loadCategoriesAction = createAction(CalendarCategoryActions.LOAD_CATEGORIES);
export const loadCategoriesSuccessAction = createAction(CalendarCategoryActions.LOAD_CATEGORIES_SUCCESS, props<{ categories: CalendarCategory[] }>());
export const createCategoryAction = createAction(CalendarCategoryActions.CREATE_CATEGORY, props<{ category: CalendarCategory }>());
export const createCategorySuccessAction = createAction(CalendarCategoryActions.CREATE_CATEGORY_SUCCESS, props<{ category: CalendarCategory }>());
export const updateCategoryAction = createAction(CalendarCategoryActions.UPDATE_CATEGORY, props<{ category: CalendarCategory }>());
export const updateCategorySuccessAction = createAction(CalendarCategoryActions.UPDATE_CATEGORY_SUCCESS, props<{ category: CalendarCategory }>());
export const deleteCategoryAction = createAction(CalendarCategoryActions.DELETE_CATEGORY, props<{ category: CalendarCategory }>());
export const deleteCategorySuccessAction = createAction(CalendarCategoryActions.DELETE_CATEGORY_SUCCESS, props<{ id: string }>());

// Events
export const loadEventsFromDateIntervalAction = createAction(CalendarEventActions.LOAD_EVENTS_FROM_DATE_INTERVAL, props<{ startTime: Date; endTime: Date }>());
export const loadEventsFromDateIntervalSuccessAction = createAction(CalendarEventActions.LOAD_EVENTS_FROM_DATE_INTERVAL_SUCCESS, props<{ events: CalendarEvent[] }>());
export const createEventAction = createAction(CalendarEventActions.CREATE_EVENT, props<{ event: CalendarEvent }>());
export const createEventSuccessAction = createAction(CalendarEventActions.CREATE_EVENT_SUCCESS, props<{ event: CalendarEvent }>());
export const updateEventAction = createAction(CalendarEventActions.UPDATE_EVENT, props<{ event: CalendarEvent }>());
export const updateEventSuccessAction = createAction(CalendarEventActions.UPDATE_EVENT_SUCCESS, props<{ event: CalendarEvent }>());
export const deleteEventAction = createAction(CalendarEventActions.DELETE_EVENT, props<{ event: CalendarEvent }>());
export const deleteEventSuccessAction = createAction(CalendarEventActions.DELETE_EVENT_SUCCESS, props<{ id: string }>());

export const loadCategoriesFailureAction = createAction(CalendarCategoryActions.LOAD_CATEGORIES_FAILURE, props<{ error?: any }>());
export const createOrUpdateCategoryFailureAction = createAction(CalendarCategoryActions.CREATE_OR_UPDATE_CATEGORY_FAILURE, props<{ error?: any }>());
export const updateCategoryFailureAction = createAction(CalendarCategoryActions.UPDATE_CATEGORY_FAILURE, props<{ error?: any }>());
export const deleteCategoryFailureAction = createAction(CalendarCategoryActions.DELETE_CATEGORY_FAILURE, props<{ error?: any }>());
export const fixCategoriesOrderFailureAction = createAction(CalendarCategoryActions.FIX_CATEGORIES_ORDER_FAILURE, props<{ error?: any }>());
export const createEventFailureAction = createAction(CalendarEventActions.CREATE_EVENT_FAILURE, props<{ error?: any }>());
export const updateEventFailureAction = createAction(CalendarEventActions.UPDATE_EVENT_FAILURE, props<{ error?: any }>());
export const deleteEventFailureAction = createAction(CalendarEventActions.DELETE_EVENT_FAILURE, props<{ error?: any }>());
export const loadEventsFromDateIntervalFailureAction = createAction(CalendarEventActions.LOAD_EVENTS_FROM_DATE_INTERVAL_FAILURE, props<{ error?: any }>());

export const resetCalendarStateAction = createAction(CalendarActions.RESET_CALENDAR_STATE);
export const resetCalendarStateSuccessAction = createAction(CalendarActions.RESET_CALENDAR_STATE_SUCCESS);
export const resetCalendarStateFailureAction = createAction(CalendarActions.RESET_CALENDAR_STATE_FAILURE, props<{ error?: any }>());
