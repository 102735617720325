import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { ActionsSubject, Store } from '@ngrx/store';
import { areIntervalsOverlapping, differenceInDays, differenceInHours, isFuture, isPast } from 'date-fns';
import { cloneDeep } from 'lodash-es';
import { SnotifyService } from 'ng-snotify';
import { from, Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LandlordService } from '../core/services/landlord/landlord.service';
import { LoadingService } from '../core/services/loading.service';
import { MixpanelService } from '../core/services/mixpanel-service.service';
import { Booking } from '../models/booking.model';
import { AttachedFile } from '../models/fileRm.model';
import { AdditionalTenant, LateFeesData, Lease, LeaseAddOptions, LeaseData } from '../models/lease.model';
import { TaxInfo } from '../models/payments';
import { Property, PropertyPaymentsSetup } from '../models/property.model';
import { LeaseStatus, Tenant, TenantBasic } from '../models/tenant.model';
import { TaxService } from '../payments/tax.service';
import { BasicInfoDialogComponent } from '../shared/dialogs/basic-info-dialog/basic-info-dialog.component';
import { CallbackFunctions } from '../store/reducer';
import { AnalyticsNames, AppConstants } from '../utils/app-costants';
import { AppUtils } from '../utils/app-utils';
import { LocalizationUtils } from '../utils/localization-utils';
import { EsignatureRequestPayload } from './lease-add/lease-add.component';
import {
	addLeaseSignatureAction,
	createLeaseAction,
	loadLeaseDepositStatusAction,
	loadLeasesAction,
	removeLeaseAction,
	removeLeaseSuccessAction,
	updateLeaseAction,
	updateLeaseDepositAction
} from './state/lease.actions';
import { selectLeasesState } from './state/lease.selectors';

export interface LeaseDates {
	startDate: number;
	endDate: number;
	entryDate: number;
	exitDate: number;
}

export enum LeaseDepositStatus {
	NO_DEPOSIT,
	DEPOSIT_IN_OPEN,
	DEPOSIT_IN_CLOSE,
	DEPOSIT_OUT_OPEN,
	DEPOSIT_OUT_CLOSE
}

@Injectable({
	providedIn: 'root'
})
export class LeaseService {
	translations = LocalizationUtils.getTranslations();
	public addLeaseSource = '';
	private landlordId: string;
	private BACKEND_HOST = `${environment.services.backend}/api-dash/v1`;

	constructor(
		private readonly actions$: ActionsSubject,
		private readonly landlordService: LandlordService,
		private readonly analyticsService: MixpanelService,
		private readonly loadingService: LoadingService,
		private readonly dialog: MatDialog,
		private readonly taxService: TaxService,
		private readonly store: Store,
		private readonly httpClient: HttpClient,
		private toastService: SnotifyService
	) {
		this.landlordService.getLandlordId().subscribe(id => (this.landlordId = id));
	}

	public getLeasesByPropertyOrUnitId(leases: Lease[], propertyId: string, unitId?: string): Lease[] {
		return leases.filter(lease => {
			if (!!unitId) {
				return lease.unitId === unitId;
			}
			return lease.propertyId === propertyId;
		});
	}

	public getActiveLeasesForUnitStatus(leases: Lease[], propertyId: string, unitId?: string): Lease[] {
		return this.getLeasesByPropertyOrUnitId(leases, propertyId, unitId).filter(
			lease =>
				this.getLeaseStatus(lease) === Lease.ACTIVE && lease.endDate >= 0 && !this.isExpiring(lease.endDate)
		);
	}

	public getExpiringLeasesForUnitStatus(leases: Lease[], propertyId: string, unitId?: string): Lease[] {
		return this.getLeasesByPropertyOrUnitId(leases, propertyId, unitId).filter(
			lease => this.getLeaseStatus(lease) === Lease.ACTIVE && this.isExpiring(lease.endDate)
		);
	}

	private isExpiring(endDate: number): boolean {
		const actualEndDate = endDate !== 0 ? endDate : 10000000000000;
		return differenceInDays(actualEndDate, new Date().getTime()) < 30;
	}

	public getFutureLeasesForUnitStatus(leases: Lease[], propertyId: string, unitId?: string): Lease[] {
		const maxIntervalInHours = 48;
		return this.getLeasesByPropertyOrUnitId(leases, propertyId, unitId)
			.filter(
				lease =>
					this.getLeaseStatus(lease) === Lease.FUTURE &&
					Math.abs(differenceInHours(new Date().getTime(), lease.startDate)) < maxIntervalInHours
			)
			.sort((leaseA, leaseB) => leaseA.startDate - leaseB.startDate);
	}

	public getPropertyLeases(propertyId: string, leases: Lease[]): Lease[] {
		return leases.filter(lease => lease.propertyId === propertyId);
	}

	public getPropertyActiveLeases(propertyId: string, leases: Lease[]): Lease[] {
		return this.getPropertyLeases(propertyId, leases).filter(l => this.getLeaseStatus(l) === Lease.ACTIVE);
	}

	public getUnitLeases$(propertyId: string, unitId: string): Observable<Lease[]> {
		return this.getLeases().pipe(
			map(leases => this.getPropertyLeases(propertyId, leases).filter(lease => lease.unitId === unitId))
		);
	}

	public getUnitLeases(propertyId: string, unitId: string, leases: Lease[]): Lease[] {
		return this.getPropertyLeases(propertyId, leases).filter(l => l.unitId === unitId);
	}

	public getLeasesByUnitId(unitId: string, leases: Lease[]): Lease[] {
		return leases.filter(l => l.unitId === unitId);
	}

	public getLeaseCurrency(propertyId: string, leases: Lease[]): string {
		return (
			this.getPropertyLeases(propertyId, leases).filter(it => this.getLeaseStatus(it) === 0)[0]?.currency || 'EUR'
		);
	}

	public getUnitActiveLeases(propertyId: string, unitId: string, leases: Lease[]): Lease[] {
		return this.getUnitLeases(propertyId, unitId, leases).filter(l => this.getLeaseStatus(l) === Lease.ACTIVE);
	}

	public getUnitActiveOrFutureLeases(propertyId: string, unitId: string, leases: Lease[]): Lease[] {
		return this.getUnitLeases(
			propertyId,
			unitId,
			leases.filter(l => {
				const status = this.getLeaseStatus(l);
				return status === Lease.ACTIVE || status === Lease.FUTURE;
			})
		);
	}

	public getUnitPastLeases(propertyId: string, unitId: string, leases: Lease[]): Lease[] {
		return this.getUnitLeases(propertyId, unitId, leases).filter(l => this.getLeaseStatus(l) === Lease.PAST);
	}

	public getUnitFutureLeases(propertyId: string, unitId: string, leases: Lease[]): Lease[] {
		return this.getUnitLeases(propertyId, unitId, leases)
			.filter(l => this.getLeaseStatus(l) === Lease.FUTURE)
			.sort((leaseA, leaseB) => leaseA.startDate - leaseB.startDate);
	}

	public getLeases(): Observable<Lease[]> {
		return this.getLeasesDict().pipe(
			map(leasesDict => {
				if (leasesDict) {
					return Object.values(leasesDict);
				} else {
					return null;
				}
			})
		);
	}

	public getLeasesDict(): Observable<Dictionary<Lease>> {
		return this.store
			.select(selectLeasesState)
			.pipe(AppUtils.fetchIfMissing(this.store, loadLeasesAction()))
			.pipe(
				AppUtils.tapOne<Dictionary<Lease>>(leases => {
					// First time we load, we set the count of landlord leases
					this.analyticsService.setUserProperties({
						[AnalyticsNames.USER_LEASE_COUNT]: Object.values(leases).length
					});
				})
			);
	}

	// TODO: Are we sure sending undefined down the components is what we want?
	public getLease(leaseId: string): Observable<Lease | undefined> {
		return this.getLeasesDict().pipe(map(leaseDict => leaseDict[leaseId]));
	}

	public getLeasesForPeriod(fromEpoch: number, toEpoch: number): Observable<Lease[]> {
		return this.getLeases().pipe(
			map(leases =>
				leases.filter(lease => {
					const actualLeaseStart = lease.entryDate || lease.startDate;
					const actualLeaseEnd = lease.exitDate || (lease.endDate !== 0 ? lease.endDate : 10000000000000);

					return areIntervalsOverlapping(
						{ start: actualLeaseStart, end: actualLeaseEnd },
						{ start: fromEpoch, end: toEpoch }
					);
				})
			)
		);
	}

	public getLeaseStatus(lease: Lease): LeaseStatus {
		const actualLeaseStart = lease.entryDate || lease.startDate;
		const actualLeaseEnd = lease.endDate !== 0 ? lease.endDate : 10000000000000;

		if (isPast(actualLeaseStart) && (actualLeaseEnd === 0 || isFuture(actualLeaseEnd))) {
			return Lease.ACTIVE; // Active
		} else if (isFuture(actualLeaseStart)) {
			return Lease.FUTURE; // Future
		} else {
			return Lease.PAST; // Past
		}
	}

	public isLeaseActiveInPeriod(lease: Lease, fromEpoch: number, toEpoch: number) {
		const actualLeaseStart = lease.entryDate || lease.startDate;
		const actualLeaseEnd = lease.endDate !== 0 ? lease.endDate : 10000000000000;

		if (lease) {
			return areIntervalsOverlapping(
				{ start: actualLeaseStart, end: actualLeaseEnd },
				{ start: fromEpoch, end: toEpoch }
			);
		} else {
			return false;
		}
	}

	public getTenantCurrentLease(tenant: Tenant | TenantBasic, leasesDict: Dictionary<Lease>): Lease {
		let currLease = null;
		(tenant.leasesId || []).forEach(leaseId => {
			if (leasesDict[leaseId]) {
				const isCurrentLease =
					(new Date().getTime() >= leasesDict[leaseId].startDate &&
						new Date().getTime() <= leasesDict[leaseId].endDate) ||
					leasesDict[leaseId].endDate === 0;
				if (isCurrentLease) {
					currLease = leasesDict[leaseId];
					return currLease;
				}
			}
		});
		return currLease;
	}

	public getTenantPastLeases(tenant: Tenant, leasesDict: Dictionary<Lease>): Lease[] {
		if (!tenant || !leasesDict) {
			return [];
		}

		let pastLeases = [];
		(tenant.leasesId || []).forEach(leaseId => {
			const isPastLease =
				leasesDict[leaseId] &&
				new Date().getTime() >= leasesDict[leaseId].endDate &&
				leasesDict[leaseId].endDate !== 0;
			if (isPastLease) {
				pastLeases.push(leasesDict[leaseId]);
			}
		});
		return pastLeases;
	}

	public getTenantCurrentLeaseStatus(tenant: Tenant, leasesDict: Dictionary<Lease>): LeaseStatus {
		// STATUS priority: 0 . 1 . -1 . 2
		// STATUS priority: running . future . past . no_lease
		let res: LeaseStatus = LeaseStatus.LEASE_MISSING;
		(tenant.leasesId || []).forEach(leaseId => {
			if (!leasesDict[leaseId]) {
				res = LeaseStatus.LEASE_PAST;
			} else if (
				new Date().getTime() >= leasesDict[leaseId].startDate &&
				(new Date().getTime() <= leasesDict[leaseId].endDate || leasesDict[leaseId].endDate === 0)
			) {
				res = LeaseStatus.LEASE_CURRENT;
				return; // Break loop (the current lease status is the most important)
			} else if (new Date().getTime() < leasesDict[leaseId].startDate) {
				res = LeaseStatus.LEASE_FUTURE;
			} else if (new Date().getTime() > leasesDict[leaseId].startDate) {
				if (res === LeaseStatus.LEASE_MISSING) {
					res = LeaseStatus.LEASE_PAST;
				}
				// else 0: impossible
				// else 1: don't update because less important
			}
		});
		return res;
	}

	public updateLeaseDeposit(leaseId: string, deposit: number): void {
		return this.store.dispatch(updateLeaseDepositAction({ leaseId, deposit }));
	}

	public getTenantCurrentOrNextLease$(tenant: Tenant): Observable<Lease> {
		return this.getTenantLeases$(tenant).pipe(
			map(leases => {
				return leases
					.filter(it => it && (it.endDate >= Date.now() || it.endDate === 0))
					.sort((a, b) => a.startDate - b.startDate)[0];
			})
		);
	}

	public getTenantCurrentOrNextLease(tenant: Tenant | TenantBasic, leasesDict: Dictionary<Lease>): Lease {
		const tenantLeases = tenant.leasesId
			? tenant.leasesId
					.map(leaseId => {
						return leasesDict[leaseId];
					})
					.filter(it => it)
			: [];

		const resultLeases = tenantLeases
			.filter(it => it.endDate >= Date.now() || it.endDate === 0)
			.sort((a, b) => a.startDate - b.startDate);

		if (resultLeases.length > 0) {
			return resultLeases[0];
		} else {
			return null;
		}
	}

	/*
	A tenant that has a lease starting in the future, or a lease passed can have a payment with the referenced lease.
	*/
	public getTenantReferencedLease(tenant: Tenant, leasesDict: Dictionary<Lease>): Lease {
		const tenantLease = this.getTenantLeases(tenant, leasesDict).sort((a, b) => a.startDate - b.startDate);
		return (
			tenantLease.filter(it => it.endDate >= Date.now() || it.endDate === 0)[0] ||
			tenantLease[tenantLease.length - 1]
		);
	}

	public getTenantCurrentOrNextLeaseInProperty(
		tenant: Tenant | TenantBasic,
		propertyId: string,
		leasesDict: Dictionary<Lease>
	): Lease {
		return this.getTenantLeases(tenant, leasesDict)
			.filter(it => it.propertyId === propertyId && (it.endDate >= Date.now() || it.endDate === 0))
			.sort((a, b) => a.startDate - b.startDate)[0];
	}

	public addLeaseSignature(esignaturePayload: EsignatureRequestPayload, hostedPageId?: string): void {
		return this.store.dispatch(addLeaseSignatureAction({ esignaturePayload, hostedPageId }));
	}

	public createLease(lease: Lease, options: LeaseAddOptions): Promise<Lease> {
		return AppUtils.buildActionWithPromise<Lease>(this.store, createLeaseAction({ lease, options }));
	}

	public addLease(
		property: Property,
		unitId: string,
		leaseDates: LeaseDates,
		tenants: Tenant[],
		rentAmount: number,
		deposit: number,
		currency: string,
		dueOn: number,
		depositAlreadyPaid = false,
		includePastPayments = false,
		pastPaymentsPaid = true,
		generateAllFuturePaymentsAtCreation = false,
		regDate: number,
		lastRegDate: number,
		leaseNumber: string,
		leaseCode: string,
		firstMonthPaidInFull: boolean,
		leaseType: { type: number; percentage: number },
		lateFees: LateFeesData,
		booking: Booking,
		emailLeaseGeneration: boolean,
		otherTenants: AdditionalTenant[],
		paymentsSetup: PropertyPaymentsSetup = { incomes: [], costs: [] },
		customVatInfo?: TaxInfo,
		leaseReferenceCode?: string
	): Promise<Lease> {
		const tenIds = tenants.reduce((obj, cur, i) => ({ ...obj, [cur.id]: cur.id }), {});

		const leaseRecurringFees = paymentsSetup.incomes
			.filter(it => it.info.leaseIncomeType === 'recurring_fees')
			.map(it => it.item);

		const totalAmount = rentAmount + leaseRecurringFees.map(it => it.amount).reduce((prev, curr) => prev + curr, 0);

		const l = new Lease(
			'',
			leaseDates.startDate,
			leaseDates.endDate,
			totalAmount,
			property.id,
			tenIds,
			deposit,
			currency,
			dueOn,
			1,
			{
				regDate,
				lastRegDate,
				leaseNumber,
				leaseCode
			},
			leaseType,
			lateFees,
			otherTenants
		);

		l.unitId = unitId;

		const customVatInfos = this.taxService.getCustomVat();

		const rentInfo = {
			amount: rentAmount,
			code: 'rent',
			name: $localize`:@@ctr_pay_cat_rent:Rent`,
			category: 'rent',
			taxInfo: customVatInfo || customVatInfos['rent'] || TaxInfo.NONE()
		};

		l.rentItem = rentInfo;
		l.exitDate = leaseDates.exitDate;
		l.entryDate = leaseDates.entryDate;

		l.referenceCode = leaseReferenceCode;

		const options = {
			depositAlreadyPaid: depositAlreadyPaid || false,
			includePastPayments: includePastPayments || false,
			pastPaymentsPaid,
			firstMonthPaidInFull,
			booking,
			emailLeaseGeneration,
			generateAllFuturePaymentsAtCreation
		};

		l.paymentsSetup = {
			incomes: paymentsSetup.incomes,
			costs: paymentsSetup.costs
		};

		const leaseCreated = this.createLease(l, options);

		this.analyticsService.track('lease_add', {
			rent_amount: rentAmount,
			collection_day: dueOn,
			deposit_amount: deposit,
			currency,
			source: this.addLeaseSource
		});

		return leaseCreated;
	}

	public removeLease(lease: Lease): void {
		return this.store.dispatch(removeLeaseAction({ lease }));
	}

	public deleteLease(lease: Lease): Observable<boolean> {
		// Maybe the opposite should happen
		this.removeLease(lease);

		return this.actions$.pipe(
			ofType(removeLeaseSuccessAction),
			take(1),
			map(resp => {
				// Track event
				this.analyticsService.track('lease_delete', {
					already_expired: lease.endDate < new Date().getTime()
				});
				return true;
			}),
			catchError(([err, caught]) => {
				console.error(`Error handling deleting lease process on db: ${err.message}`);
				return of(false);
			})
		);
	}

	private updateLease(lease: Lease, callbacks?: CallbackFunctions): void {
		return this.store.dispatch(updateLeaseAction({ lease, callbacks }));
	}

	public editLease(lease: Lease, oldLease: Lease, callbacks?: CallbackFunctions) {
		this.updateLease(cloneDeep(lease), {
			success: result => {
				if (callbacks?.success) callbacks?.success(result);
				this.analyticsService.track('lease_edit', {
					endTime: lease.endDate !== oldLease.endDate,
					dueOn: lease.endDate !== oldLease.endDate,
					rentAmount: lease.monthlyRent !== oldLease.monthlyRent,
					rentDeposit: lease.deposit !== oldLease.deposit
				});
			},
			fail: callbacks?.fail,
			finally: callbacks?.finally
		});
	}

	public editLeaseDepositAmount(leaseId: string, depositAmount: number) {
		this.getLeasesDict()
			.pipe(take(1))
			.subscribe(leaseDict => {
				const lease = cloneDeep(leaseDict[leaseId]);
				const oldLease = lease;
				if (lease && lease.deposit !== depositAmount) {
					lease.deposit = depositAmount;
					this.editLease(lease, oldLease);
				}
			});
	}

	showDigitalSignatureCompletedDialog(landlordUrl: string) {
		const dialogRef = this.dialog.open(BasicInfoDialogComponent, {
			...AppConstants.CENTRAL_DIALOG_CONFIG,
			width: '600px',
			data: {
				rightButton: $localize`:@@signature_complete_now:Sign now`,
				leftButton: $localize`:@@signature_do_it_later:Do it later`,
				picturePath: './assets/img/positive.svg',
				content: $localize`:@@signature_complete_msg:The signature process has started.<br/>The tenant will receive an email with a prompt to sign. <b>You can sign immediately</b> or do it later.`
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			switch (result) {
				case 'leftButton':
					break;
				case 'rightButton':
					window.open(landlordUrl, '_blank');
					break;
			}
		});
	}

	public getTenantLeases$(tenant: Tenant): Observable<Lease[]> {
		return this.getLeasesDict().pipe(
			map(leasesDict => {
				let leases: Lease[] = tenant.leasesId.map(leaseId => leasesDict[leaseId]);
				leases = leases.sort((a, b) => {
					if (a.startDate > b.startDate) {
						return -1;
					}
					if (a.startDate < b.startDate) {
						return 1;
					}
					return 0;
				});
				return leases;
			})
		);
	}

	public getTenantLeases(tenant: Tenant | TenantBasic, leasesDict: Dictionary<Lease>): Lease[] {
		let tenantLeases: Lease[] = tenant.leasesId.map(leaseId => leasesDict[leaseId]).filter(lease => !!lease);
		tenantLeases = tenantLeases.sort((a, b) => {
			if (a.startDate > b.startDate) {
				return -1;
			}
			if (a.startDate < b.startDate) {
				return 1;
			}
			return 0;
		});
		return tenantLeases;
	}

	public requestLeaseTemplateGeneration(leaseId: string) {
		this.loadingService.show();

		return this.httpClient
			.get<Lease>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/leases/${leaseId}/generateLease`)
			.toPromise()
			.then(() => {
				this.toastService.success(
					$localize`:@@lease_template_request_successfull:Lease PDF sent, check your email`
				);
			})
			.catch(e => {
				this.toastService.error(this.translations.toast.something_went_wrong);
			})
			.finally(() => {
				this.loadingService.hide();
			});
	}

	public requestLeaseDocumentTemplateGeneration(leaseId: string, documentId: string) {
		this.loadingService.show();

		return this.httpClient
			.get<Lease>(
				`${this.BACKEND_HOST}/landlords/${this.landlordId}/leases/${leaseId}/generateDocument/${documentId}`
			)
			.toPromise()
			.then(() => {
				this.toastService.success(
					$localize`:@@lease_template_request_successfull:Lease PDF sent, check your email`
				);
			})
			.catch(e => {
				this.toastService.error(this.translations.toast.something_went_wrong);
			})
			.finally(() => {
				this.loadingService.hide();
			});
	}

	public getLeaseDataForPopCard(leaseId: string): Observable<LeaseData> {
		return this.getLease(leaseId).pipe(
			map(lease => {
				return <LeaseData>{
					id: lease.id,
					currency: lease.currency,
					entryDate: lease.entryDate === 0 ? '' : lease.entryDate,
					startDate: lease.startDate,
					exitDate: lease.exitDate === 0 ? '' : lease.exitDate,
					endDate: lease.endDate,
					tenantId: Object.keys(lease.tenantsId)[0],
					value: lease.monthlyRent
				};
			})
		);
	}

	getLeaseDepositStatus(leaseId: string): Observable<LeaseDepositStatus> {
		return from(
			AppUtils.buildActionWithPromise<LeaseDepositStatus>(this.store, loadLeaseDepositStatusAction({ leaseId }))
				.then(paymentDict => {
					return paymentDict;
				})
				.catch(() => {
					console.log('[Lease Service] - ERROR on loadLeaseDepositStatusAction');
					return null;
				})
			/*this.getPaymentsDictForLease(leaseId).then(paymentsDict =>
				this.calculateDepositStatusFromPayments(paymentsDict)
			)*/
		);
	}
}
