import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MangopayService } from 'src/app/core/services/mangopay.service';
import { Colors } from 'src/app/utils/colors';
import { LocalizationUtils } from 'src/app/utils/localization-utils';
import { WalletTableData } from '../../settings-wallet-table/settings-wallet-table.datasource';

@Component({
	selector: 'app-withdrawal-view-dialog',
	templateUrl: './withdrawal-view-dialog.component.html',
	styleUrls: ['./withdrawal-view-dialog.component.scss']
})
export class WithdrawalViewDialogComponent implements OnInit {
	currentTransaction: WalletTableData;
	translations = LocalizationUtils.getTranslations();

	currentCurrency;
	amountWithdrawn;
	dateWithdrawn;
	status;
	statusColor;
	failureReason;

	private colorGreen = Colors.COLOR_GREEN;
	private colorRed = Colors.COLOR_RED;

	constructor(
		public dialogRef: MatDialogRef<WithdrawalViewDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		public mangopayService: MangopayService,
		public dialog: MatDialog
	) {
		this.currentTransaction = dialogData;
		let status;
		status = this.currentTransaction.status.toUpperCase();
		this.failureReason = this.currentTransaction.failureReason;

		switch (status) {
			case 'CREATED':
				this.status = this.translations.payments.status.pending;
				this.statusColor = this.colorGreen;
				break;
			case 'SUCCEEDED':
				this.status = this.translations.payments.status.succeeded;
				this.statusColor = this.colorGreen;
				break;
			case 'FAILED':
				this.status = this.translations.payments.status.failed;
				this.statusColor = this.colorRed;
				break;
			default:
				this.status = this.translations.payments.status.pending;
				this.statusColor = this.colorGreen;
				break;
		}
	}

	ngOnInit() {}

	okClick() {
		this.dialogRef.close();
	}

	cancelClick() {
		this.dialogRef.close();
	}
}
