export enum Colors {
	/*
		Progress bar completed
		Save buttons
	*/
	COLOR_GREEN = '#3ED598',

	/*
		Progress bar not completed
		Overdue payments
		Delete buttons
	*/
	COLOR_RED = '#FA3C4C',

	/*
		Primary color
		Open payments
	*/
	COLOR_BLUE = '#00BFF2',

	/*
		Cancel buttons
		Texts
	*/
	COLOR_GREY = '#AEB8C0',
	COLOR_WHITE = '#FFFFFF',
	COLOR_BLACK = '#000000',
	COLOR_ORANGE = '#FF9900'
}
