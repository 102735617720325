import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import { BillingReducers, BillingState } from '../billing/state/billing.reducers';
import { BookingsReducers, BookingState } from '../bookings/state/bookings.reducers';
import { CalendarReducers, CalendarState } from '../calendar/state/calendar.reducers';
import { LandlordReducers, LandlordState } from '../core/services/landlord/state/landlord.reducers';
import { LeaseReducers, LeasesState } from '../leases/state/lease.reducers';
import { MaintenanceRequestsReducers, MaintenanceRequestsState } from '../maintenances/state/maintenances.reducers';
import { OwnersReducers, PropertyOwnerState as PropertyOwnersState } from '../owners/state/owners.reducers';
import {
	PaymentsReducer,
	PaymentsMetricsState,
	PaymentsReducers,
	PaymentsState
} from '../payments/state/payments.reducer';
import { propertiesReducer, PropertiesState } from '../properties/state/properties.reducer';
import { TenantPagesReducers, TenantPagesState } from '../tenants/state/tenants-pages.reducers';
import { TenantsReducers, TenantsState } from '../tenants/state/tenant.reducers';
import { resetStoreActionSuccess } from './actions';
import { FiltersReducers, FiltersState } from '../core/services/filters/state/filters.reducers';
import { PaymentPagesReducers, PaymentPagesState } from '../payments/state/payments-pages.reducers';
import { PropertyPagesReducers, PropertyPagesState } from '../properties/state/properties-pages.reducers';
import { UnitPagesReducers, UnitPagesState } from '../properties/state/units-pages.reducers';
import { MaintenancePagesReducers, MaintenancePagesState } from '../maintenances/state/maintenances-pages.reducers';
import { OwnerPagesReducers, OwnerPagesState } from '../owners/state/owners-pages.reducers';
import { PropertiesBasicState, PropertiesBasicReducer } from '../properties/state/properties-basic.reducers';
import { OwnersBasicState, OwnersBasicReducer } from '../owners/state/owners-basic.reducers';
import { TenantsBasicReducer, TenantsBasicState } from '../tenants/state/tenants-basic.reducers';
import { WalletPagesReducers, WalletPagesState } from '../settings/settings-wallet/state/wallet-pages.reducers';
import { MetricsReducers, MetricsState } from '../core/services/metrics/metrics.reducers';
import {
	ChannelManagerUnitPagesReducers,
	ChannelManagerUnitPagesState
} from '../properties/state/cm-units-pages.reducers';
import { ChannelManagerListingsReducer, ChannelManagerListingsState } from '../properties/state/unit-listings.reducers';
import {
	ChannelManagerSyncUnitPagesReducers,
	ChannelManagerSyncUnitPagesState
} from '../properties/state/cm-sync-units-pages.reducers';
import {
	ChannelManagerConnectionReducer,
	ChannelManagerConnectionsState
} from '../channel-manager/state/connections.reducers';

export interface CallbackFunctions {
	success?: (entity?: any) => void;
	fail?: (entity?: any) => void;
	finally?: (entity?: any) => void;
}

export interface AppState {
	landlord: LandlordState;
	calendar: CalendarState;
	tenants: TenantsState;
	properties: PropertiesState;
	billing: BillingState;
	payments: PaymentsMetricsState;
	newPayments: PaymentsState;
	leases: LeasesState;
	owners: PropertyOwnersState;
	bookings: BookingState;
	maintenances: MaintenanceRequestsState;
	tenantPages: TenantPagesState;
	paymentPages: PaymentPagesState;
	propertyPages: PropertyPagesState;
	unitPages: UnitPagesState;
	maintenancePages: MaintenancePagesState;
	ownerPages: OwnerPagesState;
	walletPages: WalletPagesState;
	channelManagerUnitPages: ChannelManagerUnitPagesState;
	channelManagerSyncUnitPages: ChannelManagerSyncUnitPagesState;
	filters: FiltersState;
	propertiesBasic: PropertiesBasicState;
	tenantsBasic: TenantsBasicState;
	ownersBasic: OwnersBasicState;
	metrics: MetricsState;
	channelManagerListings: ChannelManagerListingsState;
	channelManagerConnections: ChannelManagerConnectionsState;
}

export const InitialAppState: AppState = {
	landlord: {} as LandlordState,
	bookings: {} as BookingState,
	calendar: {} as CalendarState,
	tenants: {} as TenantsState,
	properties: {} as PropertiesState,
	billing: {} as BillingState,
	payments: {} as PaymentsMetricsState,
	newPayments: {} as PaymentsState,
	leases: {} as LeasesState,
	owners: {} as PropertyOwnersState,
	maintenances: {} as MaintenanceRequestsState,
	tenantPages: {} as TenantPagesState,
	paymentPages: {} as PaymentPagesState,
	propertyPages: {} as PropertyPagesState,
	unitPages: {} as UnitPagesState,
	maintenancePages: {} as MaintenancePagesState,
	ownerPages: {} as OwnerPagesState,
	walletPages: {} as WalletPagesState,
	channelManagerUnitPages: {} as ChannelManagerUnitPagesState,
	channelManagerSyncUnitPages: {} as ChannelManagerSyncUnitPagesState,
	filters: {} as FiltersState,
	propertiesBasic: {} as PropertiesBasicState,
	tenantsBasic: {} as TenantsBasicState,
	ownersBasic: {} as OwnersBasicState,
	metrics: {} as MetricsState,
	channelManagerListings: {} as ChannelManagerListingsState,
	channelManagerConnections: {} as ChannelManagerConnectionsState
};

export const CoreReducers = createReducer(
	InitialAppState,
	on(resetStoreActionSuccess, (state: AppState, action) => {
		state = { ...InitialAppState };
		return state;
	})
);

export const AppReducers: ActionReducerMap<any> = {
	calendar: CalendarReducers,
	landlord: LandlordReducers,
	bookings: BookingsReducers,
	payments: PaymentsReducer,
	newPayments: PaymentsReducers,
	tenants: TenantsReducers,
	properties: propertiesReducer,
	billing: BillingReducers,
	leases: LeaseReducers,
	owners: OwnersReducers,
	maintenances: MaintenanceRequestsReducers,
	core: CoreReducers,

	tenantPages: TenantPagesReducers,
	paymentPages: PaymentPagesReducers,
	propertyPages: PropertyPagesReducers,
	unitPages: UnitPagesReducers,
	maintenancePages: MaintenancePagesReducers,
	ownerPages: OwnerPagesReducers,
	walletPages: WalletPagesReducers,
	channelManagerUnitPages: ChannelManagerUnitPagesReducers,
	channelManagerSyncUnitPages: ChannelManagerSyncUnitPagesReducers,

	filters: FiltersReducers,

	propertiesBasic: PropertiesBasicReducer,
	tenantsBasic: TenantsBasicReducer,
	ownersBasic: OwnersBasicReducer,
	metrics: MetricsReducers,
	channelManagerListings: ChannelManagerListingsReducer,
	channelManagerConnections: ChannelManagerConnectionReducer
};
