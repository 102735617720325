import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-basic-info-dialog',
	templateUrl: './basic-info-dialog.component.html'
})
export class BasicInfoDialogComponent implements OnInit {
	rightButton: string;
	leftButton: string;
	picturePath: string;
	content: string;

	constructor(
		public dialogRef: MatDialogRef<BasicInfoDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: { picturePath: string; content: string; rightButton?: string; leftButton?: string }
	) {
		this.rightButton = data.rightButton;
		this.picturePath = data.picturePath;
		this.content = data.content;
		this.leftButton = data.leftButton;
		this.rightButton = data.rightButton;
	}

	ngOnInit() {}

	rightClick() {
		this.dialogRef.close('rightButton');
	}

	leftClick() {
		this.dialogRef.close('leftButton');
	}
}
