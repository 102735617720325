import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { UserBillingInfo } from 'src/app/models/billing.model';
import { FeatureUsageStats } from '../billing.service';
import {
	loadBillingDetailsSuccessAction,
	loadFeatureUsageStatsSuccessAction,
	requestCheckoutPageSuccessAction,
	resetBillingStateSuccessAction,
	updateBillingPlanSuccessAction
} from './billing.actions';

interface FeatureUsageStatsState extends EntityState<FeatureUsageStats> {}
export const featuresUsageStatsAdapter = createEntityAdapter<FeatureUsageStats>();
const initialfeaturesUsageStatsState = featuresUsageStatsAdapter.getInitialState([]);

export interface BillingState {
	billingInfo: UserBillingInfo;
	featureUsageStats: FeatureUsageStatsState;
}

export const billingInitialState: BillingState = {
	billingInfo: {} as UserBillingInfo,
	featureUsageStats: initialfeaturesUsageStatsState
};

export const BillingReducers = createReducer(
	billingInitialState,
	on(resetBillingStateSuccessAction, (state, payload) => (state = billingInitialState)),
	on(
		loadBillingDetailsSuccessAction,
		(state, payload) =>
			(state = {
				...state,
				billingInfo: payload.billingInfo
			})
	),
	// TODO: UPDATE ACCORDINGLY THE NEW PLAN TO IMMEDIATLY REFLECT THE SERVICE
	on(
		updateBillingPlanSuccessAction,
		(state, payload) =>
			(state = {
				...state,
				billingInfo: {
					...state.billingInfo,
					subscriptionDetails: {
						...state.billingInfo.subscriptionDetails,
						planQuantity: payload['planQuantity'] || state.billingInfo.subscriptionDetails.planQuantity,
						planAmount: payload['planAmount'] || state.billingInfo.subscriptionDetails.planAmount
					}
				}
			})
	),
	on(
		requestCheckoutPageSuccessAction,
		(state, payload) =>
			(state = {
				...state,
				billingInfo: {
					...state.billingInfo,
					currentSubscription: payload.planId
				}
			})
	),

	on(
		loadFeatureUsageStatsSuccessAction,
		(state, payload) =>
			(state = {
				...state,
				featureUsageStats: featuresUsageStatsAdapter.upsertOne(payload.featureUsage, state.featureUsageStats)
			})
	)
);
