import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AppUtils } from 'src/app/utils/app-utils';
import { environment } from 'src/environments/environment';
import { AttachedFile, FileStatus, FileWrapper, PendingFile } from '../../../models/fileRm.model';
import { TenantFilesComponent } from '../../../tenants/tenant-files/tenant-files.component';
import { LandlordService } from '../landlord/landlord.service';

export enum FileEntities {
	TENANT = 'tenants',
	PROPERTY = 'properties',
	UNIT = 'units',
	PAYMENT = 'payments',
	OWNER = 'owners',
	MAINTENANCE = 'maintenances',
	TENANT_CHECKLISTS = 'checklists',
	CHAT = 'chats',
	CONFIGURATION = 'configurations',
	BOOKING = 'bookings',
	LEASE = 'leases',
	ESIGNATURES = 'leases_esignatures',
	TENANT_SHARED = 'tenantupload'
}
export interface FileQuery {
	entityType: string;
	entityId: string;
	pendingFile?: PendingFile; // on a get the pendingFile is null
}

@Injectable({
	providedIn: 'root'
})
export class FileService implements CanDeactivate<TenantFilesComponent> {
	private FILE_HOST = `${environment.services.fileManager}/landlords`;
	private fileUploadActive = false;
	public landlordId = ''; // Initialized at the auth service level

	constructor(
		private readonly httpClient: HttpClient,
		private readonly landlordService: LandlordService,
		private toastService: SnotifyService
	) {
		this.landlordService
			.getLandlordId()
			.pipe(filter(id => !!id))
			.subscribe(id => {
				this.landlordId = id;
			});
	}

	canDeactivate(
		component: TenantFilesComponent,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot
	): boolean {
		return (
			!this.fileUploadActive ||
			confirm(
				$localize`:@@com_files_uploading_warning:Some files are uploading. Press OK to continue and stop the process. Otherwise press Cancel`
			)
		);
	}

	public downloadFile(fileId: string, fileNameIfMissing?: string): Observable<any> {
		return this.httpClient
			.get(`${this.FILE_HOST}/${this.landlordId}/files/download/${fileId}`, {
				observe: 'response',
				responseType: 'blob'
			})
			.pipe(
				map(it => {
					const response = it as HttpResponse<Blob>;
					const body = response.body;
					return AppUtils.saveFile(
						body,
						AppUtils.getFileNameFromResponseContentDisposition(response, fileNameIfMissing)
					);
				})
			);
	}

	public downloadMultipleFiles(fileIds: string[]): Observable<any> {
		return this.httpClient
			.post(
				`${this.FILE_HOST}/${this.landlordId}/files/download/`,
				{ ids: fileIds },
				{
					observe: 'response',
					responseType: 'blob'
				}
			)
			.pipe(
				map(it => {
					const response = it as HttpResponse<Blob>;
					const body = response.body;
					return AppUtils.saveFile(body, AppUtils.getFileNameFromResponseContentDisposition(response));
				})
			);
	}

	public getFileMetadata(fileId: string): Observable<AttachedFile> {
		return this.httpClient.get<AttachedFile>(`${this.FILE_HOST}/${this.landlordId}/files/attachedFile/${fileId}`);
	}

	public getFileMetadataByEntity(fileQuery: FileQuery): Observable<AttachedFile[]> {
		if (!fileQuery.entityId || !fileQuery.entityType) {
			return of([]);
		}

		return this.httpClient
			.get<AttachedFile[]>(
				`${this.FILE_HOST}/${this.landlordId}/files/attachedFile/${fileQuery.entityType}/${fileQuery.entityId}`
			)
			.pipe(
				map(files =>
					files.map(file => {
						file.publicUrl = this.sanitizeFileUrl(file.publicUrl);
						return file;
					})
				)
			);
	}

	public getAllFilesMetadata(): Observable<AttachedFile[]> {
		return this.httpClient.get<AttachedFile[]>(`${this.FILE_HOST}/${this.landlordId}/files/attachedFile/`).pipe(
			map(files =>
				files.map(file => {
					file.publicUrl = this.sanitizeFileUrl(file.publicUrl);
					return file;
				})
			)
		);
	}

	private sanitizeFileUrl(url: string): string {
		const apiHost = '/fm/v1';
		const endpoint = url?.split(apiHost, 2) || '';
		return `${environment.services.fileManager}/${endpoint[1]}`;
	}

	public attachEntityToFile(fileQuery: FileQuery, fileId: string) {
		return this.httpClient.get<void>(
			`${this.FILE_HOST}/${this.landlordId}/files/attachEntity/${fileQuery.entityType}/${fileQuery.entityId}/${fileId}`
		);
	}

	// Just to know: this deletes the entity without deleting the file
	public detachEntityToFile(fileQuery: FileQuery, fileId: string) {
		return this.httpClient.get<void>(
			`${this.FILE_HOST}/${this.landlordId}/files/detachEntity/${fileQuery.entityType}/${fileQuery.entityId}/${fileId}`
		);
	}

	public deleteFile(fileQuery: FileQuery, fileId: string) {
		return this.httpClient.delete<AttachedFile>(
			`${this.FILE_HOST}/${this.landlordId}/files/${fileQuery.entityType}/${fileQuery.entityId}/${fileId}`
		);
	}

	public updateFile(file: AttachedFile) {
		this.fileUploadActive = true;
		return this.httpClient
			.put<AttachedFile>(`${this.FILE_HOST}/${this.landlordId}/files/${file.id}`, {
				data: { ...file }
			})
			.pipe(tap(() => (this.fileUploadActive = false)));
	}

	public uploadFile(
		fileQuery: FileQuery,
		metadata?: { [keyId: string]: string[] },
		notifyOnSuccess: boolean = false
	): Promise<AttachedFile> {
		console.log(fileQuery.entityType, fileQuery.entityId, fileQuery.pendingFile.name);

		if (fileQuery.entityType === FileEntities.CONFIGURATION) {
			fileQuery.pendingFile.isShared = true; // True by default
		}

		this.fileUploadActive = true;
		let formData: FormData = new FormData();
		formData.append('file', fileQuery.pendingFile.file);
		if (metadata && Object.keys(metadata).length > 0) {
			formData.append('metadata', JSON.stringify(metadata));
		}
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'multipart/form-data');
		return this.httpClient
			.post<AttachedFile>(
				`${this.FILE_HOST}/${this.landlordId}/files/${fileQuery.entityType}/${fileQuery.entityId}?
				${fileQuery.pendingFile.isShared ? `public=${fileQuery.pendingFile.isShared}` : ''}`,
				formData,
				{ headers }
			)
			.pipe(
				tap(() => {
					this.toastService.success($localize`:@@ctr_toast_document_uploaded:Document uploaded`);
					this.fileUploadActive = false;
				})
			)
			.toPromise();
	}

	public syncFileWrappers(
		entityType: FileEntities,
		entityId: string,
		stagingArea: FileWrapper[],
		metadata?: { [keyId: string]: string[] },
		notifyOnSuccess: boolean = false
	): Promise<FileWrapper[]> {
		console.log(`Syncing files of type ${entityType} for ${entityId}`);

		const filesToLoad = stagingArea.map(wrapper => {
			if (this.isPendingStatus(wrapper.status)) {
				// These are passing to the loading phase, I will receive a progress and a future result

				wrapper.status = 'uploading';

				const addFileTask = this.uploadFile(
					{ entityType, entityId, pendingFile: wrapper.file as PendingFile },
					metadata,
					notifyOnSuccess
				);

				return addFileTask
					.then(it => ({ file: it, status: 'done' } as FileWrapper))
					.catch(e => ({ file: wrapper.file, status: 'error' } as FileWrapper));
			} else {
				// There are ready files. I'm putting here a promise to uniform the response type
				return Promise.resolve(wrapper);
			}
		});

		// These will return a
		const filePromises = Promise.all(filesToLoad);

		return filePromises;
	}

	private isPendingStatus(status: FileStatus): boolean {
		return status === 'to_upload' || status === 'error' || status === 'edit';
	}
}
