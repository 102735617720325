import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { resetStoreAction } from 'src/app/store/actions';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { BillingService } from '../../billing/billing.service';
import { FileService } from './file/file.service';
import { LandlordService } from './landlord/landlord.service';
import {
	initUserAction,
	landlordSignOutAction,
	loadLandlordDataSuccessAction
} from './landlord/state/landlord.actions';
import { LoadingService } from './loading.service';
import { MangopayService } from './mangopay.service';
import { MixpanelService } from './mixpanel-service.service';
import { PicturesService } from './pictures/pictures.service';
import firebase from 'firebase/compat/app';

@Injectable()
export class AuthService {
	private routeToRedirect: ActivatedRouteSnapshot | string;
	private isUserLoggedIn$ = new BehaviorSubject<boolean>(null);

	public loginEmail: string = '';
	public resfreshHomeAtTheEnd: boolean = false;

	currentSession = uuidv4();

	passwordlessLoginTarget: string = 'choosepassword';

	private userId: string = '';

	constructor(
		private readonly afAuth: AngularFireAuth,
		private readonly store: Store,
		private readonly router: Router,
		private readonly picturesService: PicturesService,
		private readonly analyticsService: MixpanelService,
		private readonly actions$: Actions,
		private readonly loadingService: LoadingService,
		private readonly location: Location,
		private readonly mangoPayService: MangopayService
	) {
		this.afAuth.authState.subscribe(user => {
			if (user) {
				console.log(`User init ${user.uid}`);

				// Save current UserID
				this.userId = user.uid;
				// Save the current user EMAIL
				this.loginEmail = user.email;

				// The picturesService uses the userID because it's just the path in which
				// the picture will be saved in the Firebase Storage. Security rules impose
				// that the path is consistent with the authenticated user.
				// Once we get the URL to download it, everybody can see it (landlord and multi-users)
				// Since the deletion of the file, so far, it's just the removal of the link
				// from the object to which it was attached, no security rules concerning
				// Firebase Storage are missing
				this.picturesService.userId = user.uid;

				// The fileService gets the LandlordID from the landlordService.
				// There is no need to set the userID from here.
				//this.fileService.landlordId = user.uid;

				// The analyticsService gets the LandlordID from the landlordService.
				// There is no need to set the userID from here.
				// Here, though, we have to enable/disable them basing on the authenticated user
				this.analyticsService.analyticsEnabled = environment.featuresEnabled.analytics && !this.isGodUser();
				//this.analyticsService.init();

				// Init Estelle Dashboard
				this.store.dispatch(initUserAction({ userId: user.uid }));

				this.handlePostLoginNavigationIfNecessary();
			} else {
				this.userId = '';
				this.navigateToLoginIfNecessary();
			}

			// Broadcast of login status all across
			this.isUserLoggedIn$.next(!!user && !!user.email);
		});
	}

	getUserLoggedInObservable(): Observable<boolean> {
		return this.isUserLoggedIn$.asObservable();
	}

	login(email: string, password: string): Promise<{ status: 'success' | 'failure'; code?: number }> {
		this.loadingService.show();

		return this.afAuth
			.signInWithEmailAndPassword(email, password)
			.then(() => ({ status: 'success' as const }))
			.catch(e => {
				this.loadingService.hide();
				console.error(`Error on login: ${e.message}`);
				return { status: 'failure' as const, code: 1 };
			});
	}

	passwordlessLogin(token: string, passwordlessLoginTarget?: string) {
		if (passwordlessLoginTarget) {
			this.passwordlessLoginTarget = passwordlessLoginTarget;
		}
		this.afAuth.signInWithCustomToken(token);
	}

	facebookLogIn() {
		const firebaseAuthInstance = this.afAuth;

		firebaseAuthInstance
			.signInWithPopup(new firebase.auth.FacebookAuthProvider())
			.then(() => ({ status: 'success' as const }))
			.catch(e => {
				console.error(`Error on login: ${e.message}`);
				return { status: 'failure' as const, code: 1 };
			});
	}

	googleLogIn() {
		const firebaseAuthInstance = this.afAuth;

		firebaseAuthInstance
			.signInWithPopup(new firebase.auth.GoogleAuthProvider())
			.then(() => ({ status: 'success' as const }))
			.catch(e => {
				console.error(`Error on login: ${e.message}`);
				return { status: 'failure' as const, code: 1 };
			});
	}

	logout() {
		this.analyticsService.signalLogout();

		this.actions$.pipe(ofType(landlordSignOutAction), take(1)).subscribe(() => {
			this.store.dispatch(resetStoreAction());
			this.afAuth.signOut();
		});

		this.store.dispatch(landlordSignOutAction());
	}

	async logoutWithoutRedirect(password: string): Promise<void> {
		this.resfreshHomeAtTheEnd = true;

		this.actions$.pipe(ofType(landlordSignOutAction), take(1)).subscribe(() => {
			this.afAuth.signOut().then(async () => {
				await new Promise(res => setTimeout(res, 1000));

				// Try to login
				await this.login(this.loginEmail, password);
			});
		});

		this.store.dispatch(landlordSignOutAction());
	}

	setPendingRouteToRedirect(route: ActivatedRouteSnapshot | string) {
		this.routeToRedirect = route;
	}

	getTokenHeader(): Observable<string> {
		return this.afAuth.user.pipe(switchMap(user => (user ? user.getIdToken() : of(''))));
	}

	recoverPassword(email: string, url: string): Promise<void> {
		return this.afAuth.sendPasswordResetEmail(email, { url });
	}

	chooseNewPassword(pswd: string, code: string): Promise<void> {
		return this.afAuth.confirmPasswordReset(code, pswd);
	}

	public getUserId(): string {
		return this.userId;
	}

	private handlePostLoginNavigationIfNecessary() {
		if (this.router.url.startsWith('/login')) {
			this.router.navigate([this.routeToRedirect || '/home']);
		} else if (this.router.url.includes('/passwordlessLogin')) {
			this.router.navigate([this.passwordlessLoginTarget]);
		} else if (this.resfreshHomeAtTheEnd) {
			this.resfreshHomeAtTheEnd = false;
			this.router.navigate(['/home'], {
				queryParams: {
					refresh: true
				}
			});
		}
	}

	private navigateToLoginIfNecessary() {
		// These are the pages where I don't perform any redirect if I discover that the user is not logged
		const pageAllowedList = [
			'/login',
			'/passwordrecovery',
			'/passwordlessLogin',
			'/choosenewpassword',
			'/choosepassword'
		];

		const currentPath = this.location.path();

		if ((currentPath.includes('index') || currentPath === '') && currentPath.includes('token')) {
			const retrievedQueryParams = currentPath.substring(currentPath.indexOf('?'));
			const urlParams = new URLSearchParams(retrievedQueryParams);
			const token = urlParams.get('token');
			const target = urlParams.get('target');

			this.router.navigate(['/passwordlessLogin'], {
				queryParams: { token, target },
				queryParamsHandling: 'merge'
			});
			return;
		}

		if ((currentPath.includes('index') || currentPath === '') && currentPath.includes('oobCode')) {
			const retrievedQueryParams = currentPath.substring(currentPath.indexOf('?'));
			const urlParams = new URLSearchParams(retrievedQueryParams);
			const oobCode = urlParams.get('oobCode');
			const continueUrl = urlParams.get('continueUrl') || '';
			this.router.navigate(['/choosenewpassword'], {
				queryParams: { oobCode, continueUrl },
				queryParamsHandling: 'merge'
			});
			return;
		}

		if (pageAllowedList.filter(page => this.location.path().includes(page)).length === 0) {
			this.router.navigate(['/login']);
		}
	}

	public isGodUser(): boolean {
		return this.userId.startsWith('rm_system_admin_');
	}

	public changeLandlordIdForGodUser(landlordId: string) {
		if (this.isGodUser()) {
			this.actions$.pipe(ofType(loadLandlordDataSuccessAction), take(1)).subscribe(() => {
				this.loadingService.hide();

				// 2. Reset the store
				this.store.dispatch(resetStoreAction());

				this.mangoPayService.refreshPaymentStatus();

				this.router.navigate(['/']);
			});

			// 1. Reinit Estelle Dashboard
			this.store.dispatch(initUserAction({ userId: this.userId, forcedLandlordIdForGodUser: landlordId }));
		}
	}
}
