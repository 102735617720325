import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnotifyService } from 'ng-snotify';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BillingFeatures, BillingService, FeatureUsageStats } from 'src/app/billing/billing.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { EsignatureRequestPayload } from 'src/app/leases/lease-add/lease-add.component';
import { FeaturePopupReturnValue } from 'src/app/models/common';
import { AttachedFile } from 'src/app/models/fileRm.model';
import { Lease } from 'src/app/models/lease.model';
import { AppConstants } from 'src/app/utils/app-costants';
import { FilePickerComponent } from '../../file-picker/file-picker.component';
import { FileEntities } from '../../../core/services/file/file.service';
import { EsignatureService } from 'src/app/core/esignature.service';
import { LandlordService } from 'src/app/core/services/landlord/landlord.service';

@Component({
	selector: 'app-signature-add-dialog',
	templateUrl: './signature-add-dialog.component.html'
})
export class SignatureAddDialogComponent implements OnInit, OnDestroy {
	@ViewChild('file_signature_picker') signatureFilePicker: FilePickerComponent;
	public fileEntities = FileEntities;
	featureUsageStats: FeatureUsageStats;
	private componentDestroyed = new Subject();

	onlyTenantSigning = false;
	leaseId: string;
	tenantId: string;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public dialogData: any,
		private dialogRef: MatDialogRef<SignatureAddDialogComponent>,
		private toastService: SnotifyService,
		private billingService: BillingService,
		private loadingService: LoadingService,
		private landlordService: LandlordService
	) {
		this.leaseId = dialogData.leaseId;
		this.tenantId = dialogData.tenantId;
	}

	ngOnInit() {
		this.billingService.loadFeatureUsageStats(BillingFeatures.FEATURE_ESIGNATURE);
		combineLatest([
			this.billingService.getFeaturesUsageStats([BillingFeatures.FEATURE_ESIGNATURE]),
			this.landlordService.getLandlordData()
		])
			.pipe(takeUntil(this.componentDestroyed))
			.subscribe(([res, landlordData]) => {
				this.featureUsageStats = res[0];
				if (landlordData?.esignaturePreferences?.onlyTenantSign) {
					this.onlyTenantSigning = landlordData.esignaturePreferences?.onlyTenantSign;
				}
			});
	}

	ngOnDestroy() {
		this.componentDestroyed.next();
	}

	confirmClick() {
		this.loadingService.show();
		this.signatureFilePicker.saveFilesForNewEntity();
	}

	cancelClick() {
		this.dialogRef.close({ action: 'none' });
	}

	async onSignatureFilesChanged(files: AttachedFile[]) {
		this.loadingService.hide();

		if (files.length === 0) {
			this.toastService.error(
				$localize`:@@com_err_select_files:Select at least a file`,
				AppConstants.TOAST_STD_CONFIG
			);
			return;
		}
		const payload: EsignatureRequestPayload = {
			leaseId: this.leaseId,
			tenantId: this.tenantId,
			files,
			onlyTenantSigning: this.onlyTenantSigning
		};
		const returnVal: FeaturePopupReturnValue<EsignatureRequestPayload> = {
			featureUsageStats: this.featureUsageStats,
			payload: payload
		};

		this.dialogRef.close(returnVal);
	}
}
