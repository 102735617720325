import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { LocalizationUtils } from 'src/app/utils/localization-utils';
import { AppConstants } from 'src/app/utils/app-costants';
import { SnotifyService } from 'ng-snotify';

@Injectable({
	providedIn: 'root'
})
export class LoadingService {
	private _loadingTextSubject = new BehaviorSubject<string>('');
	private translations = LocalizationUtils.getTranslations();

	constructor(private readonly spinner: NgxSpinnerService, private readonly toastService: SnotifyService) {}

	show(text: string = '') {
		this._loadingTextSubject.next(text);
		this.spinner.show('mainSpinner');
	}

	hide() {
		this.spinner.hide('mainSpinner');
	}

	getLoadingTextObservable() {
		return this._loadingTextSubject;
	}

	hideAndDisplayErrorToast(title: string = this.translations.toast.something_went_wrong, message: string = '') {
		this.hide();

		this.toastService.error(title, message, {
			...AppConstants.TOAST_STD_CONFIG,
			timeout: 4000
		});
	}
}
