import {
	Directive,
	Input,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewContainerRef,
	ElementRef,
	HostListener,
	AfterViewInit
} from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';
import { PopoverPositions, PopoverService, POSITION_MAP } from '../core/services/popover.service';
import { delay, switchMap, takeUntil } from 'rxjs/operators';

export type PopoverState = 'closed' | 'open';

/**
 * This popover receives a template reference of the element to be rendered
 * to use it in your HTML you need to declare a ng template tag
 * <ng-template #yourPopoverRefHere>
 *  <app-roommate-popover>
 *    <any-content-here></any-content-here>
 *  </app-roommate-popover>
 * </ng-template>
 */
@Directive({
	selector: '[appPopoverRef]'
})
export class PopoverDirective implements OnDestroy, OnInit, AfterViewInit {
	// List of classes to ignore in the mouseLeave event so the popover doesn't close when going from one point to another
	@Input()
	public ignoreClassOnMouseLeave: string[] = [];

	@Input()
	public context: any;

	// You can use the popoverState to open or close the popover
	@Input()
	public popoverState?: BehaviorSubject<PopoverState> = new BehaviorSubject<PopoverState>('closed');

	@Input()
	public showPopoverOnMouseLocation = false;

	@Input()
	public hasBackDrop? = true;

	@Input()
	public openOnHover? = false;

	@Input()
	public openOnClick? = true;

	@Input()
	public appPopoverRef: TemplateRef<any>;

	@Input()
	public position: PopoverPositions = {
		bottomLeft: POSITION_MAP.bottomLeft,
		bottomRight: POSITION_MAP.bottomRight,
		...POSITION_MAP
	};

	@Input()
	public positionAtTheRight: boolean = false;

	@Input()
	public delay: number = 0;

	public positionRight: PopoverPositions = {
		bottomRight: POSITION_MAP.bottomLeft,
		bottomLeft: POSITION_MAP.bottomRight,
		...POSITION_MAP
	};

	private overlayRef: OverlayRef;

	private destroyed = new Subject();
	private enter = new Subject<void>();
	private leave = new Subject<void>();

	private clientX;
	private clientY;

	constructor(
		private elementRef: ElementRef,
		private overlay: Overlay,
		private vcr: ViewContainerRef,
		private popoverService: PopoverService
	) {}

	ngOnInit(): void {
		if (this.appPopoverRef) {
			this.sanitizeParameters();
			this.createOverlay();
			this.popoverState.subscribe(state => {
				if (state === 'closed') {
					this.detachOverlay();
				} else if (state === 'open') {
					this.attachOverlay();
				}
			});
		}
	}

	ngAfterViewInit() {
		this.enter
			.pipe(
				switchMap(() => of(null).pipe(delay(this.delay), takeUntil(this.leave))),
				takeUntil(this.destroyed)
			)
			.subscribe(() => {
				if (this.openOnHover && this.appPopoverRef && !this.overlayRef.hasAttached()) {
					if (this.showPopoverOnMouseLocation) {
						this.updatePositionToMouseLocation(this.clientX + 20, this.clientY + 10);
					}
					this.attachOverlay();
				}
			});
	}

	ngOnDestroy(): void {
		this.detachOverlay();
		this.destroyed.next();
		this.destroyed.complete();
	}

	@HostListener('click')
	clickListener() {
		if (this.openOnClick && this.appPopoverRef) {
			this.attachOverlay();
		}
	}

	@HostListener('mouseenter', ['$event'])
	show(event) {
		this.clientX = event.clientX;
		this.clientY = event.clientY;
		this.enter.next();
	}

	@HostListener('mouseleave', ['$event'])
	hide(event) {
		const target = event.toElement || event.relatedTarget;
		const containsIgnoreClassList =
			target &&
			this.ignoreClassOnMouseLeave.filter(it => Object.values(target.classList).includes(it)).length > 0;
		if (
			this.openOnHover &&
			this.appPopoverRef &&
			!containsIgnoreClassList &&
			!this.overlayRef.overlayElement.contains(target)
		) {
			this.detachOverlay();
			this.leave.next();
		}
	}

	private createOverlay(): void {
		const overlayConfig = this.createOverlayConfig();
		this.overlayRef = this.overlay.create(overlayConfig);
		if (this.openOnHover) {
			this.overlayRef.overlayElement.addEventListener('mouseleave', () => this.detachOverlay());
		}
		this.overlayRef.backdropClick().subscribe(() => {
			this.detachOverlay();
		});
	}

	private createOverlayConfig() {
		if (this.positionAtTheRight) {
			this.position = this.positionRight;
		}

		const overlayConfig: OverlayConfig = this.popoverService.getOverlayConfig(
			this.overlay,
			this.position,
			this.elementRef
		);
		overlayConfig.hasBackdrop = this.hasBackDrop;
		overlayConfig.backdropClass = '';
		return overlayConfig;
	}

	private attachOverlay(): void {
		if (this.overlayRef && !this.overlayRef.hasAttached()) {
			const periodSelectorPortal = new TemplatePortal(this.appPopoverRef, this.vcr, this.context);
			this.overlayRef.attach(periodSelectorPortal);
		}
	}

	private detachOverlay(): void {
		if (this.overlayRef && this.overlayRef.hasAttached()) {
			this.overlayRef.detach();
		}
	}

	private sanitizeParameters() {
		// Does not make sense and it is not supported to have both hover and backdrop
		if (this.openOnHover) {
			this.hasBackDrop = false;
		}
	}

	private updatePositionToMouseLocation(x: number, y: number) {
		this.overlayRef.updatePositionStrategy(
			this.overlay
				.position()
				.flexibleConnectedTo({ x, y })
				.withPositions([
					POSITION_MAP.bottomRight,
					POSITION_MAP.topLeft,
					POSITION_MAP.bottomLeft,
					POSITION_MAP.topRight
				])
		);
	}
}
