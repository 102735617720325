import { NgModule } from '@angular/core';
import { PaymentsExportDialogComponent } from 'src/app/payments/payments-export-dialog/payments-export-dialog.component';
import { PropertyPostAddDialogComponent } from 'src/app/properties/property-post-add-dialog/property-post-add-dialog.component';
import { ExportTenantsDialogComponent } from 'src/app/tenants/export-tenants-dialog/export-tenants-dialog.component';
import { TenantMenuDialogComponent } from 'src/app/tenants/tenant-menu-dialog/tenant-menu-dialog.component';
import { TenantPostAddDialogComponent } from 'src/app/tenants/tenant-post-add-dialog/tenant-post-add-dialog.component';
import { SharedModule } from '../shared.module';
import { AlertMobileVersionDialogComponent } from './alert-mobile-version-dialog/alert-mobile-version-dialog.component';
import { BasicInfoDialogComponent } from './basic-info-dialog/basic-info-dialog.component';
import { ChangePaidPlanDialogComponent } from './change-paid-plan-dialog/change-paid-plan-dialog.component';
import { ChoosePaidPlanDialogComponent } from './choose-paid-plan-dialog/choose-paid-plan-dialog.component';
import { BookingLinkDialogComponent } from './booking-link-dialog/booking-link-dialog.component';
import { ContactUsDialogComponent } from './contact-us-dialog/contact-us-dialog.component';
import { CropPictureDialogComponent } from './crop-picture-dialog/crop-picture-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DeleteConfirmDialogComponent } from './delete-confirm-dialog/delete-confirm-dialog.component';
import { FileAddDialogComponent } from './file-add-dialog/file-add-dialog.component';
import { GenericErrorDialogComponent } from './generic-error-dialog/generic-error-dialog.component';
import { HomeShortcutFilterDialogComponent } from './home-shortcut-filter-dialog/home-shortcut-filter-dialog.component';
import { InvoiceSubmissionDialogCompoment } from './invoice-submission-dialog/invoice-submission-dialog.component';
import { InvoiceSubmissionErrorDialogCompoment } from './invoice-submission-error-dialog/invoice-submission-error-dialog.component';
import { SignatureAddDialogComponent } from './signature-add-dialog/signature-add-dialog.component';
import { StandardMessagesDialogComponent } from './standard-messages-dialog/standard-messages-dialog.component';
import { CommonModule } from '@angular/common';
import { ChannelManagerInfoDialogComponent } from './channel-manager-info-dialog/channel-manager-info-dialog.component';
import { DateFnsModule } from 'ngx-date-fns';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { CtaDialogComponent } from './cta-dialog copy/cta-dialog.component';
import { UnitChannelStatusDialog } from './unit-channels-status-dialog/unit-channels-status-dialog.component';
import { MarkAsPaidSingleTransactionDialogComponent } from './mark-as-paid-single-transaction-dialog/mark-as-paid-single-transaction-dialog.component';
import { ActivityLogDialogComponent } from '../activity-log-dialog/activity-log-dialog.component';
import { ConditionsChangingDialogComponent } from './conditions-changing-dialog/conditions-changing.dialog.component';
import { UpgradeSubscriptionDialogComponent } from './upgrade-subscription/upgrade-subscription-dialog.component';

@NgModule({
	declarations: [
		/**  TENANTS DIALOG */
		ExportTenantsDialogComponent,
		TenantPostAddDialogComponent,
		TenantMenuDialogComponent,
		/** */
		AlertMobileVersionDialogComponent,
		BasicInfoDialogComponent,
		ChangePaidPlanDialogComponent,
		ChoosePaidPlanDialogComponent,
		UpgradeSubscriptionDialogComponent,
		BookingLinkDialogComponent,
		ConditionsChangingDialogComponent,
		ContactUsDialogComponent,
		CropPictureDialogComponent,
		ConfirmDialogComponent,
		DeleteConfirmDialogComponent,
		InvoiceSubmissionDialogCompoment,
		InvoiceSubmissionErrorDialogCompoment,
		CtaDialogComponent,
		FileAddDialogComponent,
		GenericErrorDialogComponent,
		HomeShortcutFilterDialogComponent,
		SignatureAddDialogComponent,
		StandardMessagesDialogComponent,
		ActivityLogDialogComponent,
		PaymentsExportDialogComponent,
		PropertyPostAddDialogComponent,
		ChannelManagerInfoDialogComponent,
		UnitChannelStatusDialog,
		MarkAsPaidSingleTransactionDialogComponent
	],
	imports: [
		SharedModule,
		DateFnsModule,
		MatFormFieldModule,
		ImageCropperModule,
		MatRadioModule,
		MatDatepickerModule,
		MatSelectModule,
		MatCardModule,
		MatCheckboxModule,
		MatDialogModule,
		MatButtonModule,
		MatBadgeModule,
		MatInputModule,
		MatSliderModule,
		MatTableModule,
		MatSortModule,
		MatStepperModule,
		Ng2TelInputModule,
		MatAutocompleteModule
	],
	exports: [
		AlertMobileVersionDialogComponent,
		BasicInfoDialogComponent,
		ChangePaidPlanDialogComponent,
		ChoosePaidPlanDialogComponent,
		UpgradeSubscriptionDialogComponent,
		BookingLinkDialogComponent,
		ConditionsChangingDialogComponent,
		ContactUsDialogComponent,
		CropPictureDialogComponent,
		ConfirmDialogComponent,
		DeleteConfirmDialogComponent,
		InvoiceSubmissionDialogCompoment,
		InvoiceSubmissionErrorDialogCompoment,
		CtaDialogComponent,
		FileAddDialogComponent,
		GenericErrorDialogComponent,
		HomeShortcutFilterDialogComponent,
		SignatureAddDialogComponent,
		StandardMessagesDialogComponent,
		/**  TENANTS DIALOG */
		ExportTenantsDialogComponent,
		TenantPostAddDialogComponent,
		TenantMenuDialogComponent,
		/** */
		/**  PAYMENTS DIALOG */
		PaymentsExportDialogComponent,
		ActivityLogDialogComponent,
		/** */
		/**  PROPERTIES DIALOG */
		PropertyPostAddDialogComponent,
		/** */
		CommonModule,
		ChannelManagerInfoDialogComponent,
		UnitChannelStatusDialog
	]
})
export class DialogsModule {}
