import { EntityAdapter, EntityState } from '@ngrx/entity';

export interface EntityLoadable<T> extends EntityState<T> {
	isLoaded: boolean;
	isLoading: boolean;
	error: any | undefined;
}

export function setSuccessStateOfGeneric<T>(
	state: EntityLoadable<T>,
	entityAdapter: EntityAdapter<T>,
	payload: T[],
	refreshAll?: boolean
): EntityLoadable<T> {
	if (refreshAll) {
		return entityAdapter.setAll(payload, {
			...state,
			isLoaded: true,
			isLoading: false,
			error: undefined
		});
	} else {
		return entityAdapter.upsertMany(payload, {
			...state,
			isLoaded: true,
			isLoading: false,
			error: undefined
		});
	}
}

export function setErrorStateOfGeneric<T>(
	state: EntityLoadable<T>,
	entityAdapter: EntityAdapter<T>,
	error: any
): EntityLoadable<T> {
	return entityAdapter.removeAll({
		...state,
		isLoaded: true,
		isLoading: false,
		error
	});
}

export function setLoadingStateOfGeneric<T>(state: EntityLoadable<T>): EntityLoadable<T> {
	return {
		...state,
		isLoaded: false,
		isLoading: true
	};
}
