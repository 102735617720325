import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './interceptor.service';

@NgModule({
	providers: [
		InterceptorService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptorService,
			multi: true // Check with Vin -> Why is this necessary? It creates a new instance every time I use interceptor service as dep
		}
	]
})
export class InterceptorModule {}
