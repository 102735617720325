import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnotifyService } from 'ng-snotify';
import { CalendarCategory } from 'src/app/models/calendar';
import { AppConstants } from 'src/app/utils/app-costants';
import { LocalizationUtils } from 'src/app/utils/localization-utils';
import { LoadingService } from 'src/app/core/services/loading.service';
import { Store } from '@ngrx/store';
import {
	createCategoryAction,
	createCategorySuccessAction,
	updateCategoryAction,
	updateCategorySuccessAction
} from '../state/calendar.actions';
import { Actions, ofType } from '@ngrx/effects';
import { take } from 'rxjs/operators';
import { LandlordService } from 'src/app/core/services/landlord/landlord.service';

@Component({
	selector: 'app-calendar-add-category-dialog',
	templateUrl: './calendar-add-category-dialog.component.html',
	styleUrls: []
})
export class CalendarAddCategoryDialogComponent implements OnInit {
	private landlordId = '';
	translations = LocalizationUtils.getTranslations();

	titleForm = new UntypedFormControl('', [Validators.required]);
	colorFormControl = new UntypedFormControl('', [Validators.required]);

	editCategory: CalendarCategory;

	colorList = ['#0B93EF', '#00E6DC', '#FA3C4C', '#F4A9D4', '#FF9900', '#8F37AA'];

	constructor(
		private readonly actions$: Actions,
		private readonly store: Store,
		public dialogRef: MatDialogRef<CalendarAddCategoryDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		private toastService: SnotifyService,
		private loadingService: LoadingService,
		private landlordService: LandlordService
	) {
		this.landlordService.getLandlordId().subscribe(id => (this.landlordId = id));

		if (dialogData && dialogData.category) {
			this.editCategory = Object.assign({}, dialogData.category);
			this.titleForm.setValue(this.editCategory.name);
			this.colorFormControl.setValue(this.editCategory.color);
		} else {
			this.colorFormControl.setValue(this.colorList[Math.round(Math.random() * this.colorList.length - 1)]);
		}
	}

	ngOnInit() {}

	backClick(): void {
		this.dialogRef.close();
	}

	deleteClick(): void {
		this.dialogRef.close();
		this.dialogRef.close({ action: 'delete' });
	}

	saveClick(): void {
		this.titleForm.markAsTouched();

		if (this.titleForm.valid) {
			this.loadingService.show($localize`:@@add_category_loading:Creating category...`);

			if (this.editCategory) {
				this.editCategory.name = this.titleForm.value;
				this.editCategory.color = this.colorFormControl.value;
				this.store.dispatch(updateCategoryAction({ category: this.editCategory }));
			} else {
				this.editCategory = new CalendarCategory(
					null,
					this.titleForm.value,
					this.colorFormControl.value,
					this.landlordId,
					999
				);
				this.store.dispatch(createCategoryAction({ category: this.editCategory }));
			}
			this.actions$
				.pipe(ofType(createCategorySuccessAction, updateCategorySuccessAction), take(1))
				.subscribe(() => {
					this.dialogRef.close();
					this.loadingService.hide();
				});
		} else {
			this.toastService.error(
				this.translations.toast.enter_missing_values,
				this.translations.toast.check_fields,
				AppConstants.TOAST_STD_CONFIG
			);
		}
	}
}
