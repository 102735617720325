import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LandlordService, SectionsList } from '../core/services/landlord/landlord.service';
import { loadLandlordDataSuccessAction } from '../core/services/landlord/state/landlord.actions';
import { MixpanelService } from '../core/services/mixpanel-service.service';
import { PlanEstimation, UserBillingInfo } from '../models/billing.model';
import { PropertyService } from '../properties/property.service';
import { deletePropertiesSuccessAction, deletePropertySuccessAction } from '../properties/state/properties.actions';
import { ChoosePaidPlanDialogComponent } from '../shared/dialogs/choose-paid-plan-dialog/choose-paid-plan-dialog.component';
import { ConditionsChangingDialogComponent } from '../shared/dialogs/conditions-changing-dialog/conditions-changing.dialog.component';
import { UpgradeSubscriptionDialogComponent } from '../shared/dialogs/upgrade-subscription/upgrade-subscription-dialog.component';
import { CallbackFunctions } from '../store/reducer';
import { AnalyticsNames } from '../utils/app-costants';
import {
	loadBillingDetailsAction,
	loadBillingDetailsSuccessAction,
	loadFeatureUsageStatsAction,
	requestCheckoutFeatureAction,
	requestCheckoutFeatureSuccessAction,
	requestCheckoutPageAction,
	requestPortalPageAction,
	updateBillingPlanAction,
	updateBillingPlanSuccessAction
} from './state/billing.actions';
import { selectBillingInfo, selectBillingSpecificFeatures, selectCurrentPlan } from './state/billing.selectors';

export interface PlanUnitDetails {
	currentUnits: number;
	planUnits: number;
}

export interface CheckoutPopupEvent {
	event: string;
	hostedPageId?: string;
}

export type BillingPlanNames =
	| 'transactions-plan'
	| 'units-plan'
	| 'units-plan-daily'
	| 'transactions-plan-daily'
	| string;

export interface FeatureUsageStats {
	id: string;
	includedQuantity: number;
	limitQuantity: number;
	planId: string;
	isGroupByRefId: boolean;
	billingType: 'billingCycle' | 'lease';
	referenceId?: string;
	billingItemId: string;
	cost: number;
	currency: string;
	creditsEnabled: boolean;
	groupByRefId: boolean;
	usedQuantity: number;
}

export enum BillingFeatures {
	FEATURE_ESIGNATURE = 'esignature',
	FEATURE_REPORT = 'report',
	FEATURE_RENT_RECEIVED = 'rent-received-amount',
	FEATURE_EPAYMENS_COUNT = 'epayments-count',
	FEATURE_INVOICE = 'invoice-generation',
	FEATURE_TENANTS_IMPORT = 'tenants-import',
	FEATURE_PROPERTIES_IMPORT = 'properties-import',
	FEATURE_BROADCAST_MESSAGE = 'broadcast-message',
	FEATURE_SUPPORT = 'express-support',
	PLAN_NONE = 'none',
	PLAN_FREE = 'free-plan',
	PLAN_PREMIUM = 'premium-plan',
	PLAN_PROFESSIONAL = 'professional-plan',
	PLAN_CUSTOM = 'custom-plan',
	PLAN_UNITS = 'units-plan',
	PLAN_TRANSACTIONS = 'transactions-plan',
	FREE_MAX_UNITS = 5,
	PREMIUM_MAX_UNITS = 9,
	PROFESSIONAL_MAX_UNITS = 25,
	FEATURE_X = 'feature_x'
}

@Injectable({
	providedIn: 'root'
})
export class BillingService {
	private BACKEND_HOST = environment.services.billing;
	private landlordId: string;
	private currentDialogShown:
		| MatDialogRef<ChoosePaidPlanDialogComponent, any>
		| MatDialogRef<ConditionsChangingDialogComponent, any>
		| MatDialogRef<UpgradeSubscriptionDialogComponent, any>;

	private refreshBillingInfoSubject = new Subject<boolean>();

	debugService = false;

	static isFeatureIncludedInPlan(featureStats: FeatureUsageStats) {
		return featureStats.includedQuantity === -1;
	}

	constructor(
		private readonly store: Store,
		private readonly httpClient: HttpClient,
		private readonly actions$: Actions,
		private readonly landlordService: LandlordService,
		private readonly propertyService: PropertyService,
		private readonly analyticsService: MixpanelService,
		private readonly dialog: MatDialog,
		private readonly router: Router
	) {
		this.log(`billing-service init`);

		// Every time I receive landlordData I check for billing information
		this.actions$.pipe(ofType(loadLandlordDataSuccessAction)).subscribe(() => {
			this.log(`billing-service loadLandlordDataSuccessAction`);
			this.fetchAndCheck();
		});

		this.refreshBillingInfoSubject.pipe(debounceTime(500)).subscribe(() => {
			this.log(`billing-service refreshing`);
			this.loadBillingInfo();
			this.checkBillingInfo();
		});

		this.landlordService
			.getLandlordId()
			.pipe(filter(landlordId => !!landlordId))
			.subscribe(id => {
				this.landlordId = id;
				this.fetchAndCheck();
			});

		this.actions$.pipe(ofType(deletePropertySuccessAction)).subscribe(res => {
			if (res.updateBilling) {
				combineLatest([this.propertyService.getUnitsBasicFromNoMockProperties(), this.getBillingInfo()])
					.pipe(take(1))
					.subscribe(async ([unitsBasic, billingInfo]) => {
						const unitCount = unitsBasic.length;
						const currentPlan = billingInfo.currentSubscription;
						const planQuantity = billingInfo.subscriptionDetails.planQuantity;

						if (!currentPlan.startsWith('existing') && unitCount != planQuantity) {
							this.updateBillingPlan(currentPlan, unitCount);
							this.actions$.pipe(ofType(updateBillingPlanSuccessAction), take(1)).subscribe(res => {
								this.checkBillingInfo();
							});
						}
					});
			}
		});

		this.actions$.pipe(ofType(deletePropertiesSuccessAction)).subscribe(res => {
			if (res.propertyDeletionResults) {
				combineLatest([this.propertyService.getUnitsBasicFromNoMockProperties(), this.getBillingInfo()])
					.pipe(take(1))
					.subscribe(async ([unitsBasic, billingInfo]) => {
						const unitBasicWithoutRemoved = unitsBasic.filter(
							unit =>
								!res.propertyDeletionResults.find(
									deleteResult => deleteResult.deletePropertyId === unit.propertyId
								)
						);
						const unitCount = unitBasicWithoutRemoved.length;
						const currentPlan = billingInfo.currentSubscription;
						const planQuantity = billingInfo.subscriptionDetails.planQuantity;

						if (!currentPlan.startsWith('existing') && unitCount != planQuantity) {
							this.updateBillingPlan(currentPlan, unitCount);
							this.actions$.pipe(ofType(updateBillingPlanSuccessAction), take(1)).subscribe(res => {
								this.checkBillingInfo();
							});
						}
					});
			}
		});
	}

	log(...args) {
		this.debugService && console.log(...args);
	}

	private fetchAndCheck() {
		this.refreshBillingInfoSubject.next(true);
	}

	public canAccessSection(section: SectionsList): Observable<boolean> {
		return combineLatest([this.getBillingInfo()]).pipe(
			map(([billingInfo]) => {
				if (!section || !billingInfo?.planDetails?.allowedSections) {
					return true;
				}
				if (billingInfo?.planDetails?.allowedSections?.includes(section)) {
					return true;
				}
				return false;
			})
		);
	}

	public getFeaturesUsageStats(features: BillingFeatures[]): Observable<FeatureUsageStats[]> {
		return this.store.select(selectBillingSpecificFeatures(features));
	}

	public openFromSignupRequestedCheckout() {
		return combineLatest([this.getBillingInfo()]).pipe(
			map(([billingInfo]) => {
				const currentPlan = billingInfo.currentSubscription;
				const requestedPlan = billingInfo.requestedPlan;

				if (requestedPlan !== currentPlan) {
					this.requestCheckoutPage(requestedPlan);
				}
			})
		);
	}

	public isRequestPlanSameAsCurrentPlan(): Observable<boolean> {
		return combineLatest([this.getBillingInfo()]).pipe(
			map(([billingInfo]) => {
				const currentPlan = billingInfo.currentSubscription;
				const requestedPlan = billingInfo.requestedPlan;

				if (!requestedPlan || !currentPlan) {
					return true;
				}

				if (!currentPlan.startsWith('free')) {
					return true;
				}

				return requestedPlan === currentPlan;
			})
		);
	}

	public downgradeRequestedPlan() {
		return this.httpClient
			.get(`${this.BACKEND_HOST}/landlords/${this.landlordId}/plans/removeRequestedPlan`)
			.subscribe(() => {
				//Maybe refetch billing info needed at some point
			});
	}

	public getValidPlanObservable(): Observable<boolean> {
		return combineLatest([this.getUnitPlans(), this.getBillingInfo(), this.landlordService.getLandlordData()]).pipe(
			map(([units, billingInfo, landlordData]) => {
				const currentPlan = billingInfo.currentSubscription;

				this.log(`billing-service getValidPlanObservable`, units, currentPlan);

				const isPlanAlwaysValid = currentPlan.startsWith('transactions') || currentPlan.startsWith('existing');

				if (isPlanAlwaysValid) {
					this.log(`billing-service plan is always valid`);
					return true;
				}

				if (landlordData.id === 'KA8UlhHWCHgsSVHvGMJuORAqbCB2') {
					return true;
				}

				if (currentPlan.startsWith('free')) {
					if (landlordData.skipBlockUntil && landlordData.skipBlockUntil > Date.now()) {
						this.log(`billing-service blockSkipped`);
						return true;
					}
					this.log(`billing-service free-plan`);
					return units.currentUnits <= BillingFeatures.FREE_MAX_UNITS;
				}

				if (currentPlan.startsWith('premium')) {
					if (landlordData.skipBlockUntil && landlordData.skipBlockUntil > Date.now()) {
						this.log(`billing-service blockSkipped`);
						return true;
					}
					this.log(`billing-service free-plan`);
					return units.currentUnits <= BillingFeatures.PREMIUM_MAX_UNITS;
				}

				if (currentPlan.startsWith('professional')) {
					if (landlordData.skipBlockUntil && landlordData.skipBlockUntil > Date.now()) {
						this.log(`billing-service blockSkipped`);
						return true;
					}
					this.log(`billing-service professional-plan`);
					return units.currentUnits <= BillingFeatures.PROFESSIONAL_MAX_UNITS;
				}

				if (currentPlan.startsWith('units')) {
					this.log(`billing-service units-plan`);
					return units.currentUnits <= units.planUnits;
				}
			})
		);
	}

	public getlastDialogShownTime(): Observable<number> {
		//Default has to be 1 because i want to set to 0 once acknowledged and confirmed
		return this.landlordService.getLandlordData().pipe(map(it => it.lastDialogShownTime ?? 1));
	}

	public getIsAccountBlocked(): Observable<boolean> {
		return this.landlordService.getLandlordData().pipe(map(it => it.landlordBlocked || false));
	}

	public getBillingInfo(): Observable<UserBillingInfo> {
		return this.store.select(selectBillingInfo).pipe(filter(billingInfo => !!billingInfo.currentSubscription));
	}

	public getCurrentPlan(): Observable<string> {
		return this.store.select(selectCurrentPlan).pipe(
			tap(currentSubscription => {
				this.analyticsService.setUserProperties({
					[AnalyticsNames.USER_PLAN]: currentSubscription
				});
			})
		);
	}

	public getUnitPlans(): Observable<PlanUnitDetails> {
		return combineLatest([
			this.propertyService.getUnitsBasicFromNoMockProperties(),
			this.store.select(selectBillingInfo)
		]).pipe(
			map(([units, billingInfo]) => {
				this.log(`billing-service getUnitPlans`, units, billingInfo);

				const unitCount = units.length;

				if (billingInfo.currentSubscription === 'free-plan') {
					return {
						currentUnits: unitCount,
						planUnits: BillingFeatures.FREE_MAX_UNITS as number
					};
				}

				if (billingInfo.currentSubscription.startsWith('premium')) {
					return {
						currentUnits: unitCount,
						planUnits: BillingFeatures.PREMIUM_MAX_UNITS as number
					};
				}

				if (billingInfo.currentSubscription.startsWith('professional')) {
					return {
						currentUnits: unitCount,
						planUnits: BillingFeatures.PROFESSIONAL_MAX_UNITS as number
					};
				}

				const unitPlan = billingInfo.subscriptionDetails?.planQuantity || BillingFeatures.FREE_MAX_UNITS;

				return {
					currentUnits: unitCount,
					planUnits: unitPlan
				};
			})
		);
	}

	public loadFeatureUsageStats(featureName: BillingFeatures, referenceId: string = ''): void {
		return this.store.dispatch(loadFeatureUsageStatsAction({ featureName, referenceId }));
	}

	public loadBillingInfo(): void {
		this.log(`billing-service loadBillingInfo`);
		this.store.dispatch(loadBillingDetailsAction());
	}

	public requestPortalPage(): void {
		return this.store.dispatch(requestPortalPageAction());
	}

	public updateBillingPlan(billingPlan: BillingPlanNames, planQuantity: number = 1): void {
		return this.store.dispatch(updateBillingPlanAction({ planId: billingPlan, planQuantity }));
	}

	public loadChangePlanEstimateInfo(planId: string, planQuantity?: number): Observable<PlanEstimation> {
		return this.httpClient.post<PlanEstimation>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/plans/${planId}/estimate`,
			{
				planId,
				planQuantity
			}
		);
	}

	public requestCheckoutPage(
		billingPlan: BillingPlanNames,
		planQuantity: number = 1,
		callbacks?: CallbackFunctions
	): void {
		//TODO: Can the planQuantity be smaller than 1? if not there should be a guard here to avoid it
		return this.store.dispatch(requestCheckoutPageAction({ planId: billingPlan, planQuantity, callbacks }));
	}

	public requestCheckoutFeature(feature: BillingFeatures): void {
		//TODO: Can the planQuantity be smaller than 1? if not there should be a guard here to avoid it
		return this.store.dispatch(requestCheckoutFeatureAction({ feature }));
	}

	public showCheckoutOrProceedAfterOneShotDialog<T>(
		res: { action: string },
		featureName: BillingFeatures,
		executedFeature: (payloadInfo: T, hostedPageId?: string) => void,
		payload: T
	) {
		this.loadBillingInfo();
		if (res.action === 'checkout') {
			// User wants to pay
			this.getBillingInfo()
				.pipe(
					map(billingInfo => {
						if (!(!!billingInfo.billingDetails && !!billingInfo.billingDetails.cardData)) {
							// Card not present
							this.requestCheckoutFeature(featureName);
							return this.actions$.pipe(ofType(requestCheckoutFeatureSuccessAction));
						}
					}),
					filter(res => !!res),
					switchMap(val => val),
					take(1)
				)
				.subscribe(action => {
					if (action.popup.event === 'success') {
						executedFeature(payload, action.popup.hostedPageId);
						this.loadBillingInfo();
					}
				});
		} else if (res.action === 'pay') {
			executedFeature(payload);
		} else {
			// User canceled the action
		}
	}

	checkBillingInfo() {
		this.log(`billing-service checkBillingInfo`);
		this.actions$
			.pipe(
				ofType(loadBillingDetailsSuccessAction),
				debounceTime(500),
				mergeMap(() =>
					combineLatest([
						this.getValidPlanObservable().pipe(take(1)),
						this.getIsAccountBlocked().pipe(take(1)),
						this.isRequestPlanSameAsCurrentPlan().pipe(take(1))
					])
				),
				take(1)
			)
			.subscribe(([isValidPlan, isLandlordBlocked, requestedPlanSame]) => {
				this.log(`billing-service checkBillingInfo isValidPlan`, isValidPlan);

				if (!isValidPlan || isLandlordBlocked) {
					this.showInvalidPlanDialog();
				} else if (!requestedPlanSame) {
					this.showFinishPlanDialog();
				} else {
					this.eventuallyHideInvalidPlanDialog();
				}
			});
	}

	showInvalidPlanDialog() {
		if (!this.currentDialogShown) {
			this.getCurrentPlan()
				.pipe(take(1))
				.subscribe(currentPlan => {
					this.currentDialogShown = this.dialog.open(UpgradeSubscriptionDialogComponent, {
						width: '400px',
						backdropClass: 'backdrop-dark-background',
						disableClose: true,
						panelClass: 'no-padding-modalbox',
						data: { origin: 'invalid_plan' }
					});

					this.currentDialogShown.afterClosed().subscribe(result => {
						this.currentDialogShown = undefined;
					});
				});
		}
	}

	showFinishPlanDialog() {
		if (!this.currentDialogShown) {
			this.getBillingInfo()
				.pipe(take(1))
				.subscribe(billingInfo => {
					const currentPlan = billingInfo.currentSubscription;
					const requestedPlan = billingInfo.requestedPlan;

					this.currentDialogShown = this.dialog.open(ChoosePaidPlanDialogComponent, {
						width: '720px',
						backdropClass: 'backdrop-dark-background',
						disableClose: true,
						panelClass: 'no-padding-modalbox',
						data: { origin: 'finish_plan', plan: currentPlan, requestedPlan }
					});

					this.currentDialogShown.afterClosed().subscribe(result => {
						this.currentDialogShown = undefined;
					});
				});
		}
	}

	openConditionsChangingDialog() {
		if (!this.currentDialogShown) {
			this.currentDialogShown = this.dialog.open(ConditionsChangingDialogComponent, {
				width: '720px',
				backdropClass: 'backdrop-dark-background',
				disableClose: true,
				panelClass: 'no-padding-modalbox'
			});

			this.currentDialogShown.afterClosed().subscribe(result => {
				this.currentDialogShown = undefined;
			});
		}
	}

	eventuallyHideInvalidPlanDialog() {
		if (this.currentDialogShown) {
			this.currentDialogShown.close();
			this.currentDialogShown = undefined;
		}
	}
}
