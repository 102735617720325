import { Injectable } from '@angular/core';
import { Event, Router, RoutesRecognized } from '@angular/router';
import mixpanel from 'mixpanel-browser';
import { Subject } from 'rxjs';
import { distinct, filter, take, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import packageInfo from '../../../../package.json';
import { AnalyticsNames } from '../../utils/app-costants';
import { LandlordService } from './landlord/landlord.service';

@Injectable({
	providedIn: 'root'
})
export class MixpanelService {
	analyticsEnabled = environment.featuresEnabled.analytics;

	private verbose = environment.featuresEnabled.verboseLog;

	landlordId: string = '';

	private subscriptionsReset = new Subject();

	constructor(public router: Router, private readonly landlordService: LandlordService) {
		this.landlordService
			.getLandlordId()
			.pipe(filter(landlordId => !!landlordId))
			.subscribe(landlordId => {
				this.subscriptionsReset.next();

				this.landlordId = landlordId;

				if (this.verbose) {
					console.log(`Init analytics for ${landlordId}`);
				}

				if (this.analyticsEnabled) {
					mixpanel.init(
						'b88a0914917f381f54c39ee1e1daed9c',
						{ api_host: 'https://api-eu.mixpanel.com', verbose: false },
						''
					); // batch_requests: true
					mixpanel.identify(landlordId);
					this.startTimingEvent('load_complete');
				}

				this.track('log_in');

				this.router.events
					.pipe(
						filter((e: Event): e is RoutesRecognized => e instanceof RoutesRecognized),
						distinct(),
						takeUntil(this.subscriptionsReset)
					)
					.subscribe(e => this.handleScreenView(e.url));

				this.landlordService
					.getLandlordData()
					.pipe(
						filter(data => !!data && !!data.id),
						take(1)
					)
					.subscribe(landlordData => {
						this.track(`load_complete`);

						this.setUserProperties({
							[AnalyticsNames.LAST_VERSION]: packageInfo.version,
							[AnalyticsNames.USER_EMAIL]: landlordData.email,
							[AnalyticsNames.USER_NAME]: `${landlordData.name} ${landlordData.surname}`
						});
					});
			});
	}

	handleScreenView(path: string) {
		const components = path
			.split('#')[0] // Remove parameters
			.split('?')[0] // Remove navigation
			.split('/')
			.filter(it => it); // We want to extract usable parts of the path like 'home', 'chat', ecc.

		/*
			Possible cases:
				- 1 -> Main section
				- 2 -> Main section + subsection (chat, settings)
				- 2 -> Main section + details (subsection is profile)
				- 3 -> Main section + details + subsection

		*/

		if (components.length === 1) {
			this.track('screen_view', { section: components[0] });
		} else if (components.length === 2 && (path.includes('chat') || path.includes('settings'))) {
			this.track('screen_view', {
				section: components[0],
				subSection: components[1]
			});
		} else if (components.length === 2) {
			this.track('screen_view', {
				section: `${components[0]}_detail`,
				subSection: 'profile'
			});
		} else if (components.length === 3) {
			this.track('screen_view', {
				section: `${components[0]}_detail`,
				subSection: components[2]
			});
		}
	}

	/**
	 * Push new action to mixpanel.
	 *
	 * @param {string} id Name of the action to track.
	 * @param {*} [action={}] Actions object with custom properties.
	 * @memberof MixpanelService`
	 */
	track(id: string, action: { [key: string]: any } = {}): void {
		try {
			if (this.verbose)
				console.log(
					`Analtytics service: tracking ${id}${Object.keys(action).length > 0 ? JSON.stringify(action) : ''}`
				);

			if (this.analyticsEnabled) {
				mixpanel.track(id, action);
			}
		} catch (e) {
			console.error(`Cannot see analytics: ${e.message}`);
		}

		return;
	}

	/**
	 * Set user properties for current user.
	 * Values will override existing values.
	 *
	 */
	setUserProperties(props: any = {}): void {
		if (this.verbose)
			console.log(`Analtytics service: setting user properties ${JSON.stringify(props, null, '\t')}`);

		if (this.analyticsEnabled) {
			mixpanel.people.set(props);
		}
	}

	incrementUserProperty(prop: string, value: number = 1): void {
		if (this.verbose) console.log(`Analtytics service: incrementing property ${prop} of ${value}`);

		if (this.analyticsEnabled) {
			mixpanel.people.increment(prop, value);
			return;
		}
	}

	unsetUserProperties(keyList: string[]) {
		if (!this.analyticsEnabled) {
			if (this.verbose) console.log(`Analtytics service: unsetting properties ${JSON.stringify(keyList)}`);
			return;
		}
		mixpanel.people.unset(keyList);
	}

	/**
	 * This will start the timing until the event is triggered by calling track
	 * @param event Key of the event to track
	 */
	startTimingEvent(event: string) {
		if (!this.analyticsEnabled) {
			if (this.verbose) console.log(`Analtytics service: start timing for event ${event}`);
			return;
		}
		mixpanel.time_event(event);
	}

	/**
	 * Clear user parameter and info from the current section
	 */
	signalLogout() {
		this.landlordId = '';

		if (this.analyticsEnabled) {
			this.track('log_out');

			if (this.verbose) console.log(`Analtytics service: signaling logout`);
			mixpanel.reset();
			return;
		}
	}
}
