import { createAction, props } from '@ngrx/store';
import { FilterContainer, SortEvent } from 'src/app/core/services/filters/filters.model';
import { UnitStatus } from 'src/app/models/channelManager.model';
import { QueryResult } from 'src/app/models/common';
import { EstelleUnit, Property, PropertyBasic } from 'src/app/models/property.model';
import { CallbackFunctions } from 'src/app/store/reducer';
import { PropertyCreationOptions, PropertyCreationResult, PropertyDeletionResult, PropertyUpdateResult } from './properties.effects';
import { CaclulatedUnitAvailabilities } from 'src/app/leases/availability-service';

export enum PropertiesActions {
	//Effect actions
	LOAD_PROPERTY_BY_ID = '[Properties] Load One Property By ID',
	LOAD_PROPERTIES_BASIC = '[Properties] Load Properties',
	UPLOAD_MULTIPLE_PROPERTIES = '[Properties] Upload Multiple Properties',
	ADD_PROPERTY = '[Properties] Add Property',
	EDIT_PROPERTY = '[Properties] Edit Property',
	DELETE_PROPERTY = '[Properties] Delete Property',
	DELETE_PROPERTIES = '[Properties] Delete Properties',
	RESET_PROPERTIES_STATE = '[Properties] Reset Properties State',
	ADD_MOCK_PROPERTY = '[Properties] Add Mock Property',
	// Version 2
	LOAD_PROPERTIES_PAGINATED = '[Properties] Load Properties Paginated',
	LOAD_UNITS_PAGINATED = '[Properties] Load Units Paginated',
	LOAD_CHANNEL_MANAGER_UNITS_PAGINATED = '[Properties] Load Channel Manager Units Paginated',
	LOAD_CHANNEL_MANAGER_SYNC_UNITS_PAGINATED = '[Properties] Load Channel Manager Sync Units Paginated',
	LOAD_PROPERTIES_BY_OWNER = '[Properties] Load Properties By Owner',
	GET_PROPERTIES_CSV = '[Properties] Get Properties CSV',

	// Reducer Success Actions
	LOAD_PROPERTY_BY_ID_SUCCESS = '[Properties] Load One Property By ID Success',
	LOAD_PROPERTIES_SUCCESS = '[Properties] Load Properties Success',
	UPLOAD_MULTIPLE_PROPERTIES_SUCCESS = '[Properties] Upload Multiple Properties Success',
	ADD_PROPERTY_SUCCESS = '[Properties] Add Property Success',
	EDIT_PROPERTY_SUCCESS = '[Properties] Edit Property Success',
	DELETE_PROPERTY_SUCCESS = '[Properties] Delete Property Success',
	DELETE_PROPERTIES_SUCCESS = '[Properties] Delete Properties Success',
	RESET_PROPERTIES_STATE_SUCCESS = '[Properties] Reset Properties State Success',
	// Version 2
	LOAD_PROPERTIES_PAGINATED_SUCCESS = '[Properties] Load Properties Paginated Success',
	LOAD_UNITS_PAGINATED_SUCCESS = '[Properties] Load Units Paginated Success',
	LOAD_CHANNEL_MANAGER_UNITS_PAGINATED_SUCCESS = '[Properties] Load Channel Manager Units Paginated Success',
	LOAD_CHANNEL_MANAGER_SYNC_UNITS_PAGINATED_SUCCESS = '[Properties] Load Channel Manager Sync Units Paginated Success',
	LOAD_PROPERTIES_BY_OWNER_SUCCESS = '[Properties] Load Properties By Owner Success',
	GET_PROPERTIES_CSV_SUCCESS = '[Properties] Get Properties CSV Success',

	// Reducer Failure actions
	LOAD_PROPERTY_BY_ID_FAILURE = '[Properties] Load One Property By ID Failure',
	LOAD_PROPERTIES_FAILURE = '[Properties] Load Properties Failure',
	UPLOAD_MULTIPLE_PROPERTIES_FAILURE = '[Properties] Upload Multiple Properties Failure',
	ADD_PROPERTY_FAILURE = '[Properties] Add Property Failure',
	EDIT_PROPERTY_FAILURE = '[Properties] Edit Property Failure',
	DELETE_PROPERTY_FAILURE = '[Properties] Delete Property Failure',
	DELETE_PROPERTIES_FAILURE = '[Properties] Delete Properties Failure',
	RESET_PROPERTIES_STATE_FAILURE = '[Properties] Reset Properties State Failure',
	// Version 2
	LOAD_PROPERTIES_PAGINATED_FAILURE = '[Properties] Load Properties Paginated Failure',
	LOAD_UNITS_PAGINATED_FAILURE = '[Properties] Load Units Paginated Failure',
	LOAD_CHANNEL_MANAGER_UNITS_PAGINATED_FAILURE = '[Properties] Load Channel Manager Units Paginated Failure',
	LOAD_CHANNEL_MANAGER_SYNC_UNITS_PAGINATED_FAILURE = '[Properties] Load Channel Manager Sync Units Paginated Failure',
	LOAD_PROPERTIES_BY_OWNER_FAILURE = '[Properties] Load Properties By Owner Failure',
	GET_PROPERTIES_CSV_FAILURE = '[Properties] Get Properties CSV Failure'
}

// Effect Actions
export const loadPropertyByIdAction = createAction(PropertiesActions.LOAD_PROPERTY_BY_ID, props<{ propertyId: string; isForLeaseItem?: boolean; callbacks?: CallbackFunctions }>());
export const loadPropertiesBasicAction = createAction(PropertiesActions.LOAD_PROPERTIES_BASIC, props<{ refreshAll?: boolean; callbacks?: CallbackFunctions; updateBilling?: boolean }>());
export const uploadMultiplePropertiesAction = createAction(PropertiesActions.UPLOAD_MULTIPLE_PROPERTIES, props<{ properties: Property[]; hostedPageId?: string; callbacks?: CallbackFunctions }>());
export const addPropertyAction = createAction(PropertiesActions.ADD_PROPERTY, props<{ property: Property; options: PropertyCreationOptions; callbacks?: CallbackFunctions }>());
export const editPropertyAction = createAction(PropertiesActions.EDIT_PROPERTY, props<{ property: Property; showSuccessToast?: boolean; callbacks?: CallbackFunctions }>());
export const deletePropertyAction = createAction(PropertiesActions.DELETE_PROPERTY, props<{ propertyId: string; isBulk: boolean; callbacks?: CallbackFunctions }>());
export const deletePropertiesAction = createAction(PropertiesActions.DELETE_PROPERTIES, props<{ propertyIds: string[]; callbacks?: CallbackFunctions }>());
export const addMockPropertyAction = createAction(PropertiesActions.ADD_MOCK_PROPERTY, props<{ callbacks?: CallbackFunctions }>());
export const resetPropertiesStateAction = createAction(PropertiesActions.RESET_PROPERTIES_STATE);
// Version 2
export const loadPropertiesPaginatedAction = createAction(PropertiesActions.LOAD_PROPERTIES_PAGINATED, props<{ page: number; pageSize: number; filters: FilterContainer; sort: SortEvent }>());
export const loadUnitsPaginatedAction = createAction(PropertiesActions.LOAD_UNITS_PAGINATED, props<{ page: number; pageSize: number; filters: FilterContainer; sort: SortEvent }>());
export const loadChannelManagerUnitsPaginatedAction = createAction(PropertiesActions.LOAD_CHANNEL_MANAGER_UNITS_PAGINATED, props<{ page: number; pageSize: number; filters: FilterContainer; sort: SortEvent }>());
export const loadChannelManagerSyncUnitsPaginatedAction = createAction(PropertiesActions.LOAD_CHANNEL_MANAGER_SYNC_UNITS_PAGINATED, props<{ page: number; pageSize: number; filters: FilterContainer; sort: SortEvent }>());
export const loadPropertiesByOwnerAction = createAction(PropertiesActions.LOAD_PROPERTIES_BY_OWNER, props<{ ownerId: string; callbacks?: CallbackFunctions }>());
export const getPropertiesCsvAction = createAction(PropertiesActions.GET_PROPERTIES_CSV);

// Reducer Success Actions
export const loadPropertyByIdSuccessAction = createAction(PropertiesActions.LOAD_PROPERTY_BY_ID_SUCCESS, props<{ property: Property }>());
export const loadPropertiesBasicSuccessAction = createAction(PropertiesActions.LOAD_PROPERTIES_SUCCESS, props<{ propertiesBasic: PropertyBasic[]; refreshAll?: boolean; updateBilling?: boolean }>());
export const uploadMultiplePropertiesSuccessAction = createAction(PropertiesActions.UPLOAD_MULTIPLE_PROPERTIES_SUCCESS, props<{ properties: Property[] }>());
export const addPropertySuccessAction = createAction(PropertiesActions.ADD_PROPERTY_SUCCESS, props<PropertyCreationResult>());
export const editPropertySuccessAction = createAction(PropertiesActions.EDIT_PROPERTY_SUCCESS, props<PropertyUpdateResult>());
export const deletePropertySuccessAction = createAction(PropertiesActions.DELETE_PROPERTY_SUCCESS, props<PropertyDeletionResult>());
export const deletePropertiesSuccessAction = createAction(PropertiesActions.DELETE_PROPERTIES_SUCCESS, props<{ propertyDeletionResults: PropertyDeletionResult[] }>());
export const resetPropertiesStateSuccessAction = createAction(PropertiesActions.RESET_PROPERTIES_STATE_SUCCESS);
// Version 2
export const loadPropertiesPaginatedSuccessAction = createAction(PropertiesActions.LOAD_PROPERTIES_PAGINATED_SUCCESS, props<{ page: number; pageSize: number; propertiesQueryResult: QueryResult<Property> }>());
export const loadUnitsPaginatedSuccessAction = createAction(PropertiesActions.LOAD_UNITS_PAGINATED_SUCCESS, props<{ page: number; pageSize: number; unitIdsQueryResult: QueryResult<string>; properties: Property[] }>());
export const loadChannelManagerUnitsPaginatedSuccessAction = createAction(
	PropertiesActions.LOAD_CHANNEL_MANAGER_UNITS_PAGINATED_SUCCESS,
	props<{ page: number; pageSize: number; unitIdsQueryResult: QueryResult<string>; properties: Property[]; listings: UnitStatus[]; availabilities: { [unitId: string]: CaclulatedUnitAvailabilities } }>()
);
export const loadChannelManagerSyncUnitsPaginatedSuccessAction = createAction(
	PropertiesActions.LOAD_CHANNEL_MANAGER_SYNC_UNITS_PAGINATED_SUCCESS,
	props<{ page: number; pageSize: number; unitIdsQueryResult: QueryResult<string>; properties: Property[]; listings: UnitStatus[]; availabilities: { [unitId: string]: CaclulatedUnitAvailabilities } }>()
);
export const loadPropertiesByOwnerSuccessAction = createAction(PropertiesActions.LOAD_PROPERTIES_BY_OWNER_SUCCESS, props<{ propertiesQueryResult: QueryResult<Property> }>());
export const getPropertiesCsvSuccessAction = createAction(PropertiesActions.GET_PROPERTIES_CSV_SUCCESS, props<{ url: string }>());

// Reducer Failure Actions
export const loadPropertyByIdFailureAction = createAction(PropertiesActions.LOAD_PROPERTY_BY_ID_FAILURE, props<{ error?: any }>());
export const loadPropertiesFailureAction = createAction(PropertiesActions.LOAD_PROPERTIES_FAILURE, props<{ error?: any }>());
export const uploadMultiplePropertiesFailureAction = createAction(PropertiesActions.UPLOAD_MULTIPLE_PROPERTIES_FAILURE, props<{ error?: any }>());
export const addPropertyFailureAction = createAction(PropertiesActions.ADD_PROPERTY_FAILURE, props<{ errorName: string }>());
export const editPropertyFailureAction = createAction(PropertiesActions.EDIT_PROPERTY_FAILURE, props<{ error?: any }>());
export const deletePropertyFailureAction = createAction(PropertiesActions.DELETE_PROPERTY_FAILURE, props<{ isBulk: boolean }>());
export const deletePropertiesFailureAction = createAction(PropertiesActions.DELETE_PROPERTIES_FAILURE, props<{ error?: any }>());
export const resetPropertiesStateFailureAction = createAction(PropertiesActions.RESET_PROPERTIES_STATE_FAILURE, props<{ error?: any }>());
// Version 2
export const loadPropertiesPaginatedFailureAction = createAction(PropertiesActions.LOAD_PROPERTIES_PAGINATED_FAILURE, props<{ error?: any }>());
export const loadUnitsPaginatedFailureAction = createAction(PropertiesActions.LOAD_UNITS_PAGINATED_FAILURE, props<{ error?: any }>());
export const loadChannelManagerUnitsPaginatedFailureAction = createAction(PropertiesActions.LOAD_CHANNEL_MANAGER_UNITS_PAGINATED_FAILURE, props<{ error?: any }>());
export const loadChannelManagerSyncUnitsPaginatedFailureAction = createAction(PropertiesActions.LOAD_CHANNEL_MANAGER_SYNC_UNITS_PAGINATED_FAILURE, props<{ error?: any }>());
export const loadPropertiesByOwnerFailureAction = createAction(PropertiesActions.LOAD_PROPERTIES_BY_OWNER_FAILURE, props<{ error?: any }>());
export const getPropertiesCsvFailureAction = createAction(PropertiesActions.GET_PROPERTIES_CSV_FAILURE, props<{ error?: any }>());
