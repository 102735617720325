import { createAction, props } from '@ngrx/store';
import { Booking, CreateBooking } from 'src/app/models/booking.model';
import { CallbackFunctions } from 'src/app/store/reducer';

export enum BookingActions {
	//Effect Actions
	LOAD_BOOKING_BY_ID = '[Booking] Get One Booking By ID',
	ADD_BOOKING = '[Booking] Add Booking',
	EDIT_BOOKING = '[Booking] Edit Booking',
	DELETE_BOOKING = '[Booking] Delete Booking',
	DELETE_BOOKING_BY_ID = '[Booking] Delete Booking by id',
	LOAD_BOOKINGS = '[Booking] Load Bookings',
	RESET_BOOKINGS_STATE = '[Bookings] Reset Bookings State',
	// Reducer Actions
	LOAD_BOOKING_BY_ID_SUCCESS = '[Booking] Load Booking by id Success',
	ADD_BOOKING_SUCCESS = '[Booking] Add Booking Success',
	EDIT_BOOKING_SUCCESS = '[Booking] Edit Booking Success',
	DELETE_BOOKING_SUCCESS = '[Booking] Delete Booking Success',
	LOAD_BOOKINGS_SUCCESS = '[Booking] Load Bookings Success',
	RESET_BOOKINGS_STATE_SUCCESS = '[Bookings] Reset Bookings State Success',

	LOAD_BOOKING_BY_ID_FAILURE = '[Booking] Load Booking Failure',
	ADD_BOOKING_FAILURE = '[Booking] Add Booking Failure',
	EDIT_BOOKING_FAILURE = '[Booking] Edit Booking Failure',
	DELETE_BOOKING_FAILURE = '[Booking] Delete Booking Failure',
	LOAD_BOOKINGS_FAILURE = '[Booking] Load Bookings Failure',
	RESET_BOOKINGS_STATE_FAILURE = '[Bookings] Reset Bookings State Failure'
}

export const loadBookingByIdAction = createAction(BookingActions.LOAD_BOOKING_BY_ID, props<{ bookingId: string; options?: any }>());
export const addBookingAction = createAction(BookingActions.ADD_BOOKING, props<{ booking: CreateBooking; callbacks?: CallbackFunctions }>());
export const editBookingAction = createAction(BookingActions.EDIT_BOOKING, props<{ booking: Booking; callbacks?: CallbackFunctions }>());
export const deleteBookingAction = createAction(BookingActions.DELETE_BOOKING, props<{ booking: Booking; callbacks?: CallbackFunctions }>());

export const loadBookingsAction = createAction(BookingActions.LOAD_BOOKINGS);
export const resetBookingsStateAction = createAction(BookingActions.RESET_BOOKINGS_STATE);

// Success Actions
export const loadBookingSuccessAction = createAction(BookingActions.LOAD_BOOKING_BY_ID_SUCCESS, props<{ booking: Booking }>());
export const addBookingSuccessAction = createAction(BookingActions.ADD_BOOKING_SUCCESS, props<{ booking: Booking }>());
export const editBookingSuccessAction = createAction(BookingActions.EDIT_BOOKING_SUCCESS, props<{ booking: Booking }>());
export const deleteBookingSuccessAction = createAction(BookingActions.DELETE_BOOKING_SUCCESS, props<{ bookingId: string }>());
export const loadBookingsSuccessAction = createAction(BookingActions.LOAD_BOOKINGS_SUCCESS, props<{ bookings: Booking[] }>());
export const resetBookingsStateSuccessAction = createAction(BookingActions.RESET_BOOKINGS_STATE_SUCCESS);

// Failure Actions
export const loadBookingFailureAction = createAction(BookingActions.LOAD_BOOKING_BY_ID_FAILURE, props<{ error?: any }>());
export const addBookingFailureAction = createAction(BookingActions.ADD_BOOKING_FAILURE, props<{ error?: any }>());
export const editBookingFailureAction = createAction(BookingActions.EDIT_BOOKING_FAILURE, props<{ error?: any }>());
export const deleteBookingFailureAction = createAction(BookingActions.DELETE_BOOKING_FAILURE, props<{ error?: any }>());
export const loadBookingsFailureAction = createAction(BookingActions.LOAD_BOOKINGS_FAILURE, props<{ error?: any }>());
export const resetBookingsStateFailureAction = createAction(BookingActions.RESET_BOOKINGS_STATE_FAILURE, props<{ error?: any }>());
