import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { EffectInteractionService } from 'src/app/core/services/effectInteraction.service';
import {
	CustomFilterType,
	FilterContainer,
	PropertyFilterKeys,
	PropertyQueryFilters,
	SortEvent,
	UnitFilterKeys,
	UnitQueryFilters
} from 'src/app/core/services/filters/filters.model';
import { LandlordService } from 'src/app/core/services/landlord/landlord.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ListingScoreService } from 'src/app/core/services/optimization-score-service';
import { UnitStatus } from 'src/app/models/channelManager.model';
import { QueryResult } from 'src/app/models/common';
import { PaymentOperation, PropertyPayment } from 'src/app/models/payments';
import { Property, PropertyBasic } from 'src/app/models/property.model';
import { PropertyOwner } from 'src/app/models/propertyOwner.model';
import { Tenant } from 'src/app/models/tenant.model';
import { resetStoreAction } from 'src/app/store/actions';
import { AppUtils } from 'src/app/utils/app-utils';
import { LocalizationUtils } from 'src/app/utils/localization-utils';
import { environment } from 'src/environments/environment';
import {
	addMockPropertyAction,
	addPropertyAction,
	addPropertyFailureAction,
	addPropertySuccessAction,
	deletePropertiesAction,
	deletePropertiesFailureAction,
	deletePropertiesSuccessAction,
	deletePropertyAction,
	deletePropertyFailureAction,
	deletePropertySuccessAction,
	editPropertyAction,
	editPropertyFailureAction,
	editPropertySuccessAction,
	loadChannelManagerSyncUnitsPaginatedAction,
	loadChannelManagerSyncUnitsPaginatedFailureAction,
	loadChannelManagerSyncUnitsPaginatedSuccessAction,
	loadChannelManagerUnitsPaginatedAction,
	loadChannelManagerUnitsPaginatedFailureAction,
	loadChannelManagerUnitsPaginatedSuccessAction,
	loadPropertiesBasicAction as loadPropertiesBasicAction,
	loadPropertiesByOwnerAction,
	loadPropertiesByOwnerFailureAction,
	loadPropertiesByOwnerSuccessAction,
	loadPropertiesFailureAction as loadPropertiesBasicFailureAction,
	loadPropertiesPaginatedAction,
	loadPropertiesPaginatedFailureAction,
	loadPropertiesPaginatedSuccessAction,
	loadPropertiesBasicSuccessAction as loadPropertiesBasicSuccessAction,
	loadPropertyByIdAction,
	loadPropertyByIdFailureAction,
	loadPropertyByIdSuccessAction,
	loadUnitsPaginatedAction,
	loadUnitsPaginatedFailureAction,
	loadUnitsPaginatedSuccessAction,
	resetPropertiesStateAction,
	resetPropertiesStateFailureAction,
	resetPropertiesStateSuccessAction,
	uploadMultiplePropertiesAction,
	uploadMultiplePropertiesFailureAction,
	uploadMultiplePropertiesSuccessAction,
	getPropertiesCsvAction,
	getPropertiesCsvFailureAction,
	getPropertiesCsvSuccessAction
} from './properties.actions';
import { CaclulatedUnitAvailabilities } from 'src/app/leases/availability-service';

export interface PropertyDeletionResult {
	deletePropertyId: string;
	deletedCalendarEventIds?: string[];
	deletedPaymentIds?: string[];
	deletedLeaseIds?: string[];
	deletedRecurringPaymentIds?: string[];
	tenants?: Tenant[];
	deletedMaintenanceIds?: string[];
	updateBilling?: boolean;
}

export interface PropertyUpdateResult {
	property?: Property;
	payments?: PropertyPayment[];
	deletedPaymentIds?: string[];
	owners?: PropertyOwner[];
	refreshAll?: boolean;
}

export interface PropertyCreationResult {
	property: Property;
	payments: PropertyPayment[];
	paymentOperations?: PaymentOperation[];
}

export interface PropertyCreationOptions {
	//pastPaymentsPaid?: boolean;
}

@Injectable()
export class PropertiesEffects {
	translations = LocalizationUtils.getTranslations();
	private landlordId;
	private BACKEND_HOST = `${environment.services.backend}/api-dash/v1`;

	constructor(
		private readonly httpClient: HttpClient,
		private readonly actions$: Actions,
		private readonly landlordService: LandlordService,
		private readonly loadingService: LoadingService,
		private readonly effectInteractionService: EffectInteractionService,
		private readonly unitScoreService: ListingScoreService,
		private readonly propertyScoreService: ListingScoreService,
		private readonly router: Router
	) {
		this.landlordService.getLandlordId().subscribe(id => (this.landlordId = id));
	}

	public resetStoreState = createEffect(() =>
		this.actions$.pipe(
			ofType(resetStoreAction),
			switchMap(() => of(resetPropertiesStateAction()))
		)
	);

	public resetPropertiesState = createEffect(() =>
		this.actions$.pipe(
			ofType(resetPropertiesStateAction),
			switchMap(() => of(resetPropertiesStateSuccessAction())),
			catchError(() => of(resetPropertiesStateFailureAction({})))
		)
	);

	public loadPropertyById = createEffect(() =>
		this.actions$.pipe(
			ofType(loadPropertyByIdAction),
			switchMap(action =>
				this.getPropertyById(action.propertyId, action.isForLeaseItem).pipe(
					map(property => {
						this.effectInteractionService.callbackSuccess(action);
						return loadPropertyByIdSuccessAction({ property });
					}),
					catchError(() => {
						this.effectInteractionService.callbackFail(action);
						return of(loadPropertyByIdFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public loadPropertiesEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadPropertiesBasicAction),
			switchMap(action => {
				return this.loadPropertiesBasic().pipe(
					map(properties => {
						this.effectInteractionService.callbackSuccess(action);
						return loadPropertiesBasicSuccessAction({
							refreshAll: action.refreshAll,
							propertiesBasic: properties,
							updateBilling: action.updateBilling
						});
					}),
					catchError(error => {
						console.log(error.message);
						this.effectInteractionService.callbackFail(action);
						return of(loadPropertiesBasicFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				);
			})
		)
	);

	public uploadMultiplePropertiesEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(uploadMultiplePropertiesAction),
			mergeMap(action =>
				this.uploadMultipleProperties(action.properties, action.hostedPageId).pipe(
					map(properties => {
						this.effectInteractionService.callbackSuccess(action);
						return uploadMultiplePropertiesSuccessAction({ properties });
					}),
					catchError(() => {
						this.effectInteractionService.callbackFail(action);
						return of(uploadMultiplePropertiesFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public addPropertyEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(addPropertyAction),
			switchMap(action =>
				this.addProperty(action.property, action.options).pipe(
					map(addPropertyResult => {
						const actualProperty = (addPropertyResult['property'] || addPropertyResult) as Property;
						this.effectInteractionService.callbackSuccess(action, actualProperty);

						if (addPropertyResult['property']) {
							// New system
							return addPropertySuccessAction(addPropertyResult as PropertyCreationResult);
						} else {
							return addPropertySuccessAction({ property: actualProperty as Property, payments: [] });
						}
					}),
					catchError(errorResponse => {
						this.effectInteractionService.callbackFail(action);
						return of(addPropertyFailureAction({ errorName: errorResponse?.error?.name || '' }));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public editPropertyEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(editPropertyAction),
			mergeMap(action =>
				this.editProperty(action.property).pipe(
					map(editedPropertyResult => {
						const actualProperty = (editedPropertyResult['property'] || editedPropertyResult) as Property;

						if (action.showSuccessToast) {
							this.effectInteractionService.displaySuccessToast(
								this.translations.toast.property_edit_title,
								this.translations.toast.property_edit_msg
							);
						}

						this.effectInteractionService.callbackSuccess(action);

						if (editedPropertyResult['property']) {
							// New system
							return editPropertySuccessAction(editedPropertyResult as PropertyUpdateResult);
						} else {
							return editPropertySuccessAction({ property: actualProperty });
						}
					}),
					catchError(() => {
						this.effectInteractionService.callbackFail(action);
						return of(editPropertyFailureAction({}));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public deletePropertyEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(deletePropertyAction),
			mergeMap(action =>
				this.deleteProperty(action.propertyId).pipe(
					map(result => {
						this.effectInteractionService.callbackSuccess(action);

						if (result['deletePropertyId']) {
							// New version
							return deletePropertySuccessAction({
								...(result as PropertyDeletionResult),
								updateBilling: true
							});
						} else {
							// Old version
							return deletePropertySuccessAction({
								deletePropertyId: action.propertyId,
								updateBilling: true
							});
						}
					}),
					catchError(() => {
						this.effectInteractionService.callbackFail(action);
						return of(deletePropertyFailureAction({ isBulk: action.isBulk }));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public deletePropertiesEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(deletePropertiesAction),
			mergeMap(action =>
				this.deleteProperties(action.propertyIds).pipe(
					map(deleteResults => {
						if (deleteResults) {
							// New version
							this.effectInteractionService.callbackSuccess(action);
							return deletePropertiesSuccessAction({ propertyDeletionResults: deleteResults });
						} else {
							// Old version
							const propertyDeletionResults: PropertyDeletionResult[] = action.propertyIds.map(
								propertyId => {
									return {
										deletePropertyId: propertyId
									};
								}
							);
							this.effectInteractionService.callbackSuccess(action);
							return deletePropertiesSuccessAction({ propertyDeletionResults });
						}
					}),
					catchError(() => {
						this.effectInteractionService.callbackFail(action);
						return of(deletePropertiesFailureAction({}));
					}),
					tap(() => {
						this.effectInteractionService.callbackFinally(action);
					})
				)
			)
		)
	);

	/*** Effects handle success and failure actions  ***/

	public addPropertySuccessEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(addPropertySuccessAction),
				tap(action =>
					this.effectInteractionService.displaySuccessToast(
						this.translations.toast.property_added_title,
						this.translations.toast.property_added_msg,
						() => {
							this.router.navigate(['properties', action.property.id]);
						}
					)
				)
			),
		{ dispatch: false }
	);

	public addPropertyFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(addPropertyFailureAction),
				tap(action => {
					this.loadingService.hide();

					// Generic error message
					let errorStr = $localize`:@@add_property_failed:Something went wrong during the creation of the property. Try again and if the problem persists, contact us.`;

					// Specific error message
					if (action.errorName === 'OperationNotAllowedByMaxMockLimitQuotaError') {
						errorStr = $localize`:@@add_property_failed_max_mock_limitation:This operation is not allowed because it exceeds mock creation limit quota`;
					}

					this.effectInteractionService.showDialogError(errorStr);
				})
			),
		{ dispatch: false }
	);

	public deletePropertyFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(deletePropertyFailureAction),
				tap(action => {
					if (action.isBulk) {
						this.loadingService.hideAndDisplayErrorToast();
					} else {
						this.loadingService.hide();
						this.effectInteractionService.showDialogError(
							$localize`:@@delete_property_failed:Something went wrong during the delete of the property. Try again and if the problem persists, contact us.`
						);
					}
				})
			),
		{ dispatch: false }
	);

	public deletePropertiesFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(deletePropertiesFailureAction),
				tap(action => {
					this.loadingService.hide();
					this.effectInteractionService.showDialogError(
						$localize`:@@delete_property_failed:Something went wrong during the delete of the property. Try again and if the problem persists, contact us.`
					);
				})
			),
		{ dispatch: false }
	);

	public editPropertyFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(editPropertyFailureAction),
				tap(() => {
					this.loadingService.hide();
					this.effectInteractionService.showDialogError(
						$localize`:@@edit_property_failed:Something went wrong during the update of the property. Try again and if the problem persists contact us.`
					);
				})
			),
		{ dispatch: false }
	);

	public genericPropertyFailureEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(
					resetPropertiesStateFailureAction,
					loadPropertyByIdFailureAction,
					loadPropertiesBasicFailureAction,
					uploadMultiplePropertiesFailureAction
				),
				tap(() => this.loadingService.hideAndDisplayErrorToast())
			),
		{ dispatch: false }
	);

	public addMockPropertyEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(addMockPropertyAction),
			mergeMap(action =>
				this.generateMockProperty().pipe(
					map(addPropertyResult => {
						const actualProperty = (addPropertyResult['property'] || addPropertyResult) as Property;
						this.effectInteractionService.callbackSuccess(action, actualProperty);

						if (addPropertyResult['property']) {
							// New system
							return addPropertySuccessAction(addPropertyResult as PropertyCreationResult);
						} else {
							return addPropertySuccessAction({ property: actualProperty as Property, payments: [] });
						}
					}),
					catchError(errorResponse => {
						this.effectInteractionService.callbackFail(action);
						return of(addPropertyFailureAction({ errorName: errorResponse?.error?.name || '' }));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	// Version 2

	public loadPropertiesPaginatedEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadPropertiesPaginatedAction),
			switchMap(action =>
				this.loadPropertiesPaginated(action.page, action.pageSize, action.filters, action.sort).pipe(
					map(properties =>
						loadPropertiesPaginatedSuccessAction({
							page: action.page,
							pageSize: action.pageSize,
							propertiesQueryResult: properties
						})
					),
					catchError(err => of(loadPropertiesPaginatedFailureAction({ error: err })))
				)
			)
		)
	);

	public loadUnitsPaginatedEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadUnitsPaginatedAction),
			switchMap(action =>
				this.loadUnitsPaginated(action.page, action.pageSize, action.filters, action.sort).pipe(
					map(result =>
						loadUnitsPaginatedSuccessAction({
							page: action.page,
							pageSize: action.pageSize,
							unitIdsQueryResult: result.unitIds,
							properties: result.properties
						})
					),
					catchError(err => of(loadUnitsPaginatedFailureAction({ error: err })))
				)
			)
		)
	);

	public loadChannelManagerUnitsPaginatedEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadChannelManagerUnitsPaginatedAction),
			switchMap(action => {
				return this.loadChannelManagerUnitsPaginated(
					action.page,
					action.pageSize,
					action.filters,
					action.sort
				).pipe(
					map(result =>
						loadChannelManagerUnitsPaginatedSuccessAction({
							page: action.page,
							pageSize: action.pageSize,
							unitIdsQueryResult: result.unitIds,
							properties: result.properties,
							listings: result.listings,
							availabilities: result.availabilities
						})
					),
					catchError(err => of(loadChannelManagerUnitsPaginatedFailureAction({ error: err })))
				);
			})
		)
	);

	public loadChannelManagerSyncUnitsPaginatedEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadChannelManagerSyncUnitsPaginatedAction),
			switchMap(action => {
				return this.loadChannelManagerSyncUnitsPaginated(
					action.page,
					action.pageSize,
					action.filters,
					action.sort
				).pipe(
					map(result =>
						loadChannelManagerSyncUnitsPaginatedSuccessAction({
							page: action.page,
							pageSize: action.pageSize,
							unitIdsQueryResult: result.unitIds,
							properties: result.properties,
							listings: result.listings,
							availabilities: result.availabilities
						})
					),
					catchError(err => of(loadChannelManagerSyncUnitsPaginatedFailureAction({ error: err })))
				);
			})
		)
	);

	public loadPropertiesByOwnerEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadPropertiesByOwnerAction),
			switchMap(action =>
				this.loadPropertiesForOwner(action.ownerId).pipe(
					map(properties => {
						this.effectInteractionService.callbackSuccess(action);
						return loadPropertiesByOwnerSuccessAction({ propertiesQueryResult: properties });
					}),
					catchError(err => {
						this.effectInteractionService.callbackFail(action);
						return of(loadPropertiesByOwnerFailureAction({ error: err }));
					}),
					tap(() => this.effectInteractionService.callbackFinally(action))
				)
			)
		)
	);

	public getPropertiesCsvEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(getPropertiesCsvAction),
			mergeMap(action =>
				this.getPropertiesCsv().pipe(
					map(url => {
						return getPropertiesCsvSuccessAction({ url });
					}),
					catchError(() => of(getPropertiesCsvFailureAction({})))
				)
			)
		)
	);

	public getProeprtiesCsvSuccessEffect = createEffect(
		() =>
			this.actions$.pipe(
				ofType(getPropertiesCsvSuccessAction),
				tap(action => {
					this.effectInteractionService.displaySuccessToast(
						this.translations.toast.get_csv_string_title,
						this.translations.toast.get_csv_string_msg
					);
					this.doDownload(action.url);
				})
			),
		{ dispatch: false }
	);

	/***  ***/

	// Private calls
	private getPropertyById(propertyId: string, isForLeaseItem?: boolean): Observable<Property> {
		let extraParams = {};
		if (isForLeaseItem) {
			extraParams['leaseItem'] = true;
		}

		return this.httpClient
			.get<Property>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/properties/${propertyId}`, {
				params: extraParams
			})
			.pipe(map(property => Property.sanitize(property, this.unitScoreService, this.propertyScoreService)));
	}

	private loadPropertiesBasic(): Observable<PropertyBasic[]> {
		if (!this.landlordId) {
			return throwError('Error on loading properties. No landlordId is present');
		}

		return this.httpClient.get<PropertyBasic[]>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/properties/basic`
		);
	}

	private uploadMultipleProperties(properties: Property[], hostedPageId?: string): Observable<Property[]> {
		return this.httpClient.post<Property[]>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/properties`, {
			properties,
			hostedPageId
		});
	}

	private addProperty(
		property: Property,
		options: PropertyCreationOptions
	): Observable<Property | PropertyCreationResult> {
		return this.httpClient
			.put<PropertyCreationResult>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/properties`, {
				property,
				options
			})
			.pipe(
				map(propResult => {
					if (propResult.property) {
						return {
							...propResult,
							property: Property.sanitize(
								propResult.property,
								this.unitScoreService,
								this.propertyScoreService
							)
						};
					} else {
						return Property.sanitize(
							propResult as any as Property,
							this.unitScoreService,
							this.propertyScoreService
						);
					}
				})
			);
	}

	private editProperty(property: Property): Observable<Property | PropertyUpdateResult> {
		return this.httpClient
			.put<PropertyUpdateResult>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/properties/${property.id}`, {
				property
			})
			.pipe(
				map(propResult => {
					if (propResult.property) {
						return {
							...propResult,
							property: Property.sanitize(
								propResult.property,
								this.unitScoreService,
								this.propertyScoreService
							)
						};
					} else {
						return Property.sanitize(
							propResult as any as Property,
							this.unitScoreService,
							this.propertyScoreService
						);
					}
				})
			);
	}

	private deleteProperty(id: string): Observable<void | PropertyDeletionResult> {
		return this.httpClient.delete<void>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/properties/${id}`);
	}

	private deleteProperties(propertyIds: string[]): Observable<void | PropertyDeletionResult[]> {
		const bulkDeleteProperties = {
			isAllSelected: false,
			selectedEntities: propertyIds,
			deselectedEntities: []
		};

		return this.httpClient.delete<void>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/properties/`, {
			body: {
				bulkDeleteProperties
			}
		});
	}

	private generateMockProperty(): Observable<Property | PropertyCreationResult> {
		return this.httpClient.post<Property>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/mock/property`, {});
	}

	// Version 2

	private loadPropertiesPaginated(
		page: number,
		pageSize: number,
		filters: FilterContainer,
		sort: SortEvent
	): Observable<QueryResult<Property>> {
		const customFilters = this.getPropertyQueryFiltersFromEstelleFilters(filters);
		const customSort = AppUtils.getQuerySortFromEstelleSort(sort);

		return this.httpClient
			.get<QueryResult<Property>>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/properties`, {
				params: {
					page: page,
					pageSize: pageSize,
					...customFilters,
					...customSort
				}
			})
			.pipe(
				map(properties => {
					const propertiesSanitized = properties.data.map(it =>
						Property.sanitize(it, this.unitScoreService, this.propertyScoreService)
					);

					properties.data = propertiesSanitized;

					return properties;
				})
			);
	}

	private loadUnitsPaginated(
		page: number,
		pageSize: number,
		filters: FilterContainer,
		sort: SortEvent
	): Observable<{ unitIds: QueryResult<string>; properties: Property[] }> {
		const customFilters = this.getUnitQueryFiltersFromEstelleFilters(filters);
		const customSort = AppUtils.getQuerySortFromEstelleSort(sort);

		return this.httpClient
			.get<{ unitIds: QueryResult<string>; properties: Property[] }>(
				`${this.BACKEND_HOST}/landlords/${this.landlordId}/units`,
				{
					params: {
						page: page,
						pageSize: pageSize,
						...customFilters,
						...customSort
					}
				}
			)
			.pipe(
				map(result => {
					if (result.properties) {
						const propertiesSanitized = result.properties.map(it =>
							Property.sanitize(it, this.unitScoreService, this.propertyScoreService)
						);

						result.properties = propertiesSanitized;
					}

					return result;
				})
			);
	}

	private loadChannelManagerUnitsPaginated(
		page: number,
		pageSize: number,
		filters: FilterContainer,
		sort: SortEvent
	): Observable<{
		unitIds: QueryResult<string>;
		properties: Property[];
		listings: UnitStatus[];
		availabilities: { [unitId: string]: CaclulatedUnitAvailabilities };
	}> {
		let customFilters: UnitQueryFilters = {};

		customFilters.channelManager = true;

		customFilters.status = filters.selectedTabId as
			| 'all'
			| 'vacant'
			| 'expiring'
			| 'rented'
			| 'soon_rented'
			| 'booked'
			| 'cm-synced'
			| 'cm-syncing'
			| 'cm-error';

		if (filters.searchedValue) {
			customFilters['text'] = filters.searchedValue;
		}

		const customSort = AppUtils.getQuerySortFromEstelleSort(sort);

		return this.httpClient
			.get<{
				unitIds: QueryResult<string>;
				properties: Property[];
				listings: UnitStatus[];
				availabilities: { [unitId: string]: CaclulatedUnitAvailabilities };
			}>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/units`, {
				params: {
					page: page,
					pageSize: pageSize,
					...customFilters,
					...customSort
				}
			})
			.pipe(
				map(result => {
					if (result.properties) {
						const propertiesSanitized = result.properties.map(it =>
							Property.sanitize(it, this.unitScoreService, this.propertyScoreService)
						);

						result.properties = propertiesSanitized;
					}

					return result;
				})
			);
	}

	private loadChannelManagerSyncUnitsPaginated(
		page: number,
		pageSize: number,
		filters: FilterContainer,
		sort: SortEvent
	): Observable<{
		unitIds: QueryResult<string>;
		properties: Property[];
		listings: UnitStatus[];
		availabilities: { [unitId: string]: CaclulatedUnitAvailabilities };
	}> {
		let customFilters: UnitQueryFilters = {};

		customFilters.channelManagerSync = true;

		// TODO: FILTERS
		customFilters['status'] = filters.selectedTabId as 'all' | 'vacant' | 'expiring';

		// Custom text
		if (filters.searchedValue) {
			customFilters['text'] = filters.searchedValue;
		}

		const customSort = AppUtils.getQuerySortFromEstelleSort(sort);

		return this.httpClient
			.get<{
				unitIds: QueryResult<string>;
				properties: Property[];
				listings: UnitStatus[];
				availabilities: { [unitId: string]: CaclulatedUnitAvailabilities };
			}>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/units`, {
				params: {
					page: page,
					pageSize: pageSize,
					...customFilters,
					...customSort
				}
			})
			.pipe(
				map(result => {
					if (result.properties) {
						const propertiesSanitized = result.properties.map(it =>
							Property.sanitize(it, this.unitScoreService, this.propertyScoreService)
						);

						result.properties = propertiesSanitized;
					}

					return result;
				})
			);
	}

	private loadPropertiesForOwner(ownerId: string): Observable<QueryResult<Property>> {
		let customFilters = { ownerId: ownerId };
		let customSort = { type: 'name', direction: 1 };

		return this.httpClient
			.get<QueryResult<Property>>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/properties`, {
				params: {
					page: 0,
					pageSize: 20000,
					...customFilters,
					...customSort
				}
			})
			.pipe(
				map(properties => {
					const propertiesSanitized = properties.data.map(it =>
						Property.sanitize(it, this.unitScoreService, this.propertyScoreService)
					);
					properties.data = propertiesSanitized;
					return properties;
				})
			);
	}

	private getPropertyQueryFiltersFromEstelleFilters(filters: FilterContainer): PropertyQueryFilters {
		let customFilters: PropertyQueryFilters = {};

		// Status
		customFilters.status = filters.selectedTabId as 'all' | 'overdue' | 'expiring';

		// Text search
		if (filters.searchedValue) {
			customFilters['text'] = filters.searchedValue;
		}

		// Custom filters
		if (filters.appliedFilters) {
			const ownerIdFilter = FilterContainer.getFilterIfExists(filters, PropertyFilterKeys.OWNER);
			if (ownerIdFilter) {
				customFilters.ownerId = ownerIdFilter.selectedValue.value;
			}
		}

		return customFilters;
	}

	private getUnitQueryFiltersFromEstelleFilters(filters: FilterContainer): UnitQueryFilters {
		let customFilters: UnitQueryFilters = {};

		// Status
		customFilters.status = filters.selectedTabId as
			| 'all'
			| 'vacant'
			| 'expiring'
			| 'rented'
			| 'soon_rented'
			| 'booked';

		// Text search
		if (filters.searchedValue) {
			customFilters['text'] = filters.searchedValue;
		}

		if (filters.appliedFilters) {
			const unitTypeFilter = FilterContainer.getFilterIfExists(filters, UnitFilterKeys.TYPE);
			if (unitTypeFilter) {
				customFilters.type = unitTypeFilter.selectedValue.value;
			}
		}

		return customFilters;
	}

	private getPropertiesCsv(): Observable<string> {
		return this.httpClient.get(`${this.BACKEND_HOST}/landlords/${this.landlordId}/properties?format=csv&type=all`, {
			responseType: 'text'
		});
	}

	private doDownload(url: string) {
		const element = document.createElement('a');
		const fileType = 'text/csv';
		element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(url)}`);
		element.setAttribute('download', `roommate-properties-${AppUtils.getDateString()}.csv`);
		const event = new MouseEvent('click');
		element.dispatchEvent(event);
	}
}
