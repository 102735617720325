import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/store/reducer';
import { channelManagerUnitPagesAdapter } from './cm-units-pages.reducers';

const selectChannelManagerUnitPagesState = createSelector(
	state => state,
	(state: AppState) => state.channelManagerUnitPages
);

export const { selectTotal: selectChannelManagerUnitPagesLength } = channelManagerUnitPagesAdapter.getSelectors(
	selectChannelManagerUnitPagesState
);

export const selectChannelManagerUnitPagesEntities = createSelector(selectChannelManagerUnitPagesState, state => {
	if (state.isLoaded) {
		return state.entities;
	}
	return null;
});
