import { Injectable } from '@angular/core';

export interface PaymentCategory {
	name: string;
	id: string;
}

@Injectable({
	providedIn: 'root'
})
export class PaymentsCategoriesService {
	private readonly privateCategories = ['deposit_in', 'deposit_out', 'stamp_duty', 'stamp', 'adjustment'];
	private static paymentCategories: { [key: string]: PaymentCategory } = {
		stamp_duty: { name: $localize`:@@ctr_pay_cat_stamp_duty:Rental tax`, id: 'stamp_duty' },
		stamp: { name: $localize`:@@ctr_pay_cat_stamp:Stamp`, id: 'stamp' },
		electrical: { name: $localize`:@@ctr_pay_cat_electrical:Electrical services`, id: 'electrical' },
		garbage: { name: $localize`:@@ctr_pay_cat_garbage:Garbage services`, id: 'garbage' },
		gas: { name: $localize`:@@ctr_pay_cat_gas:Gas services`, id: 'gas' },
		condo: { name: $localize`:@@ctr_pay_cat_condo:Condominium fee`, id: 'condo' },
		internet: { name: $localize`:@@ctr_pay_cat_internet:Internet services`, id: 'internet' },
		furnitures: { name: $localize`:@@ctr_pay_cat_furnitures:Furniture fee`, id: 'furnitures' },
		laundry: { name: $localize`:@@ctr_pay_cat_laundry:Laundry services`, id: 'laundry' },
		water: { name: $localize`:@@ctr_pay_cat_water:Water services`, id: 'water' },
		cleaning: { name: $localize`:@@ctr_pay_cat_cleaning:Cleaning services`, id: 'cleaning' },
		maintenance: { name: $localize`:@@ctr_pay_cat_maintenance:Maintenance request`, id: 'maintenance' },
		general: { name: $localize`:@@ctr_pay_cat_general:Custom expense`, id: 'general' },
		deposit_in: { name: $localize`:@@ctr_pay_cat_deposit_in:Deposit in`, id: 'deposit_in' },
		deposit_out: { name: $localize`:@@ctr_pay_cat_deposit_out:Deposit out`, id: 'deposit_out' },
		rent: { name: $localize`:@@ctr_pay_cat_rent:Rent`, id: 'rent' },
		rent_out: { name: $localize`:@@ctr_pay_cat_rent:Rent`, id: 'rent_out' },
		flat_fees: { name: $localize`:@@ctr_pay_cat_flat_fees:Flat fees`, id: 'flat_fees' },
		adjustment: { name: $localize`:@@ctr_pay_cat_adjustment:Balance`, id: 'adjustment' },
		late_fees: { name: $localize`:@@ctr_pay_cat_late_fees:Late fees`, id: 'late_fees' },
		acquisition: { name: $localize`:@@ctr_pay_cat_acquisition:Acquisition`, id: 'acquisition' },
		appliances: { name: $localize`:@@ctr_pay_cat_appliances:Appliances`, id: 'appliances' },
		entry_fee: { name: $localize`:@@ctr_pay_cat_entry_fee:Entry fee`, id: 'entry_fee' },
		renovation: { name: $localize`:@@ctr_pay_cat_renovation:Renovation`, id: 'renovation' },
		management_fee: { name: $localize`:@@ctr_pay_cat_management_fee:Management fee`, id: 'management_fee' },
		eviction: { name: $localize`:@@ctr_pay_cat_eviction:Eviction`, id: 'eviction' },
		legal: { name: $localize`:@@ctr_pay_cat_legal:Legal`, id: 'legal' },
		insurance: { name: $localize`:@@ctr_pay_cat_insurance:Insurance`, id: 'insurance' },
		marketing: { name: $localize`:@@ctr_pay_cat_marketing:Marketing`, id: 'marketing' },
		interests: { name: $localize`:@@ctr_pay_cat_interests:Interests`, id: 'interests' },
		activities: { name: $localize`:@@ctr_pay_cat_activities:Activities`, id: 'activities' },
		loan: { name: $localize`:@@ctr_pay_cat_loan:Loan`, id: 'loan' },
		taxes: { name: $localize`:@@ctr_pay_cat_taxes:Taxes`, id: 'taxes' },
		exit_fee: { name: $localize`:@@ctr_pay_cat_exit_fee:Exit fee`, id: 'exit_fee' },
		withdrawal: { name: $localize`:@@pay_withdrawal_title:Withdrawal`, id: 'withdrawal' }
	};

	constructor() {}

	public static getAllCategories(): PaymentCategory[] {
		return Object.keys(this.paymentCategories).map(it => this.paymentCategories[it]);
	}

	public getIncomeCategories(): PaymentCategory[] {
		return PaymentsCategoriesService.getAllCategories()
			.filter(
				cat =>
					cat.id === 'furnitures' ||
					cat.id === 'cleaning' ||
					cat.id === 'maintenance' ||
					cat.id === 'general' ||
					cat.id === 'deposit_in' ||
					cat.id === 'rent' ||
					cat.id === 'late_fees' ||
					cat.id === 'entry_fee' ||
					cat.id === 'renovation' ||
					cat.id === 'management_fee' ||
					cat.id === 'flat_fees' ||
					cat.id === 'exit_fee'
			)
			.filter(cat => !this.privateCategories.includes(cat.id))
			.map(cat =>
				cat.id === 'general'
					? { id: cat.id, name: $localize`:@@ctr_pay_cat_general_income:Custom income` }
					: cat
			);
	}

	public getCostCategories(): PaymentCategory[] {
		return PaymentsCategoriesService.getAllCategories()
			.filter(
				cat =>
					cat.id === 'electrical' ||
					cat.id === 'garbage' ||
					cat.id === 'gas' ||
					cat.id === 'condo' ||
					cat.id === 'internet' ||
					cat.id === 'furnitures' ||
					cat.id === 'laundry' ||
					cat.id === 'water' ||
					cat.id === 'cleaning' ||
					cat.id === 'maintenance' ||
					cat.id === 'general' ||
					cat.id === 'deposit_out' ||
					cat.id === 'acquisition' ||
					cat.id === 'appliances' ||
					cat.id === 'renovation' ||
					cat.id === 'management_fee' ||
					cat.id === 'eviction' ||
					cat.id === 'legal' ||
					cat.id === 'insurance' ||
					cat.id === 'marketing' ||
					cat.id === 'interests' ||
					cat.id === 'activities' ||
					cat.id === 'loan' ||
					cat.id === 'taxes' ||
					cat.id === 'withdrawal' ||
					cat.id === 'rent_out'
			)
			.filter(cat => !this.privateCategories.includes(cat.id))
			.map(cat =>
				cat.id === 'general' ? { id: cat.id, name: $localize`:@@ctr_pay_cat_general_cost:Custom cost` } : cat
			);
	}

	public getReimbursementCategories(): PaymentCategory[] {
		return PaymentsCategoriesService.getAllCategories()
			.filter(
				cat =>
					cat.id === 'stamp_duty' ||
					cat.id === 'stamp' ||
					cat.id === 'electrical' ||
					cat.id === 'garbage' ||
					cat.id === 'gas' ||
					cat.id === 'condo' ||
					cat.id === 'internet' ||
					cat.id === 'furnitures' ||
					cat.id === 'laundry' ||
					cat.id === 'water' ||
					cat.id === 'cleaning' ||
					cat.id === 'maintenance' ||
					cat.id === 'general' ||
					cat.id === 'flat_fees' ||
					cat.id === 'appliances' ||
					cat.id === 'entry_fee' ||
					cat.id === 'renovation' ||
					cat.id === 'management_fee' ||
					cat.id === 'eviction' ||
					cat.id === 'legal' ||
					cat.id === 'exit_fee'
			)
			.filter(cat => !this.privateCategories.includes(cat.id))
			.map(cat =>
				cat.id === 'general'
					? { id: cat.id, name: $localize`:@@ctr_pay_cat_general_reimbursement:Custom reimbursement` }
					: cat
			);
	}

	public getInsertableCategories(): PaymentCategory[] {
		return Object.keys(PaymentsCategoriesService.paymentCategories)
			.map(it => PaymentsCategoriesService.paymentCategories[it])
			.filter(it => this.privateCategories.indexOf(it.id) < 0);
	}

	public getLeaseExtraCategories(): PaymentCategory[] {
		return Object.values(PaymentsCategoriesService.paymentCategories);
	}

	public getCategoryName(categoryId: string, fallback?: string): string {
		return (
			(PaymentsCategoriesService.paymentCategories[categoryId] &&
				PaymentsCategoriesService.paymentCategories[categoryId].name) ||
			fallback
		);
	}
}
