import { createAction, props } from '@ngrx/store';
import { FilterContainer, PaymentQueryFilters, SortEvent } from 'src/app/core/services/filters/filters.model';
import { MultipleInvoiceResponse } from 'src/app/core/services/invoicing.service';
import { QueryResult } from 'src/app/models/common';
import { PaymentOperation, PropertyPayment } from 'src/app/models/payments';
import { SettingsPayment } from 'src/app/settings/settings-payment/settings-payment.component';
import { CallbackFunctions } from 'src/app/store/reducer';

export enum PaymentActions {
	LOAD_ALL_PAYMENTS = '[Payments] Load all Payments',
	LOAD_OPEN_PAYMENTS_BY_TENANT = '[Payments] Load Open Payments by Tenant',
	LOAD_PAYMENTS_BY_PROPERTY = '[Payments] Load Payments by Property',
	LOAD_PAYMENTS_BY_OWNER = '[Payments] Load Payments by Owner',
	LOAD_PAYMENTS_BY_OWNER_PLUS_CONNECTED_RENTS = '[Payments] Load Payments by Owner plus connected rents',
	LOAD_PAYMENT_BY_ID = '[Payments] Load Payment by Id',
	LOAD_PAYMENT_SETTINGS = '[Payments] Load Payment settings',
	LOAD_DEPOSIT_PAYMENTS_FOR_LEASE = '[Payments] Load Deposit Payments for Lease',
	RESET_PAYMENT_DATABASE_VIEW = '[Payments] Reset payment database view',
	CREATE_PAYMENT = '[Payment] Create Payment',
	CREATE_PAYMENT_OPERATION = '[Payments] Create Payment Operation',
	UPDATE_PAYMENT_SETTING = '[Payments] Update Payment Settings',
	UPDATE_PAYMENT = '[Payments] Update Payment',
	DELETE_PAYMENT = '[Payments] Delete Payment',
	DELETE_PAYMENTS = '[Payments] Delete Payments',
	LOCK_PAYMENTS = '[Payments] Lock Payments',
	DELETE_PAYMENT_OPERATION = '[Payment] Delete Payment Operation',
	RESET_PAYMENTS_STATE = '[Payment] Reset Payments State',
	LOAD_PAYMENTS_BY_IDS = '[Payments] Load Payments by Ids',
	LOAD_PAYMENTS_BY_IDS_SUCCESS = '[Payments] Load Payments by Ids Success',
	LOAD_ALL_PAYMENTS_SUCCESS = '[Payments] Load all Payments Success',
	LOAD_OPEN_PAYMENTS_BY_TENANT_SUCCESS = '[Payments] Load Open Payments by Tenant Success',
	LOAD_PAYMENTS_BY_PROPERTY_SUCCESS = '[Payments] Load Payments by Property Success',
	LOAD_PAYMENTS_BY_OWNER_SUCCESS = '[Payments] Load Payments by Owner Success',
	LOAD_PAYMENTS_BY_OWNER_PLUS_CONNECTED_RENTS_SUCCESS = '[Payments] Load Payments by Owner plus connected rents Success',
	LOAD_PAYMENT_BY_ID_SUCCESS = '[Payments] Load Payment by Id Success',
	LOAD_PAYMENT_SETTINGS_SUCCESS = '[Payments] Load Payment settings Success',
	LOAD_DEPOSIT_PAYMENTS_FOR_LEASE_SUCCESS = '[Payments] Load Deposit Payments for Lease Success',
	RESET_PAYMENT_DATABASE_VIEW_SUCCESS = '[Payments] Reset payment database view Success',
	CREATE_PAYMENT_SUCCESS = '[Payment] Create Payment Success',
	CREATE_PAYMENT_OPERATION_SUCCESS = '[Payments] Create Payment Operation Success',
	UPDATE_PAYMENT_SETTING_SUCCESS = '[Payments] Update Payment Settings Success',
	UPDATE_PAYMENT_SUCCESS = '[Payments] Update Payment Success',
	DELETE_PAYMENT_SUCCESS = '[Payments] Delete Payment Success',
	DELETE_PAYMENTS_SUCCESS = '[Payments] Delete Payments Success',
	LOCK_PAYMENTS_SUCCESS = '[Payments] Lock Payments Success',
	DELETE_PAYMENT_OPERATION_SUCCESS = '[Payment] Delete Payment Operation Success',
	RESET_PAYMENTS_STATE_SUCCESS = '[Payment] Reset Payments State Success',
	LOAD_PAYMENTS_BY_IDS_FAILURE = '[Payments] Load Payments by Ids Failure',
	LOAD_ALL_PAYMENTS_FAILURE = '[Payments] Load all Payments Failure',
	LOAD_OPEN_PAYMENTS_BY_TENANT_FAILURE = '[Payments] Load Open Payments by Tenant Failure',
	LOAD_PAYMENTS_BY_PROPERTY_FAILURE = '[Payments] Load Payments by Property Failure',
	LOAD_PAYMENTS_BY_OWNER_FAILURE = '[Payments] Load Payments by Owner Failure',
	LOAD_PAYMENTS_BY_OWNER_PLUS_CONNECTED_RENTS_FAILURE = '[Payments] Load Payments by Owner plus connected rents Failure',
	LOAD_PAYMENT_BY_ID_FAILURE = '[Payments] Load Payment by Id Failure',
	LOAD_PAYMENT_SETTINGS_FAILURE = '[Payments] Load Payment settings Failure',
	LOAD_DEPOSIT_PAYMENTS_FOR_LEASE_FAILURE = '[Payments] Load Deposit Payments for Lease Failure',
	RESET_PAYMENT_DATABASE_VIEW_FAILURE = '[Payments] Reset payment database view Failure',
	CREATE_PAYMENT_FAILURE = '[Payment] Create Payment Failure',
	CREATE_PAYMENT_OPERATION_FAILURE = '[Payments] Create Payment Operation Failure',
	CREATE_OR_UPDATE_PAYMENT_SETTING_FAILURE = '[Payments] Create or Update Payment Settings Failure',
	UPDATE_PAYMENT_FAILURE = '[Payments] Update Payment Failure',
	DELETE_PAYMENT_FAILURE = '[Payments] Delete Payment Failure',
	DELETE_PAYMENTS_FAILURE = '[Payments] Delete Payments Failure',
	DELETE_PAYMENT_OPERATION_FAILURE = '[Payment] Delete Payment Operation Failure',
	LOCK_PAYMENTS_FAILURE = '[Payments] Lock Payments Failure',
	RESET_PAYMENTS_STATE_FAILURE = '[Payment] Reset Payments State Failure',
	CREATE_PAYMENT_INVOICES_SUCCESS = '[Payment] Create Payment Invoices Success',
	LOAD_PAYMENTS_PAGINATED = '[Payment] Load Payments Paginated',
	LOAD_PAYMENTS_PAGINATED_SUCCESS = '[Payment] Load Payments Paginated Success',
	LOAD_PAYMENTS_PAGINATED_FAILURE = '[Payment] Load Payments Paginated Failure',
	EXPORT_PAYMENTS_CSV = '[Payment] Load Payments to Export',
	EXPORT_PAYMENTS_CSV_SUCCESS = '[Payment] Load Payments to Export Success',
	EXPORT_PAYMENTS_CSV_FAILURE = '[Payment] Load Payments to Export Failure',
	INVALIDATE_PAYMENT_PAGES = '[Payment] Invalidate Payment Pages',
	EXPORT_PAYMENTS_PDF = '[Payment] Export payments in pdf',
	EXPORT_PAYMENTS_PDF_SUCCESS = '[Payment] Export payments in pdf success',
	EXPORT_PAYMENTS_PDF_FAILURE = '[Payment] Export payments in pdf failure',
	GET_MARGINS = '[Payment] Get margins by property or owner',
	GET_MARGINS_SUCCESS = '[Payment] Get margins by property or owner success',
	GET_MARGINS_FAILURE = '[Payment] Get margins by property or owner failure'
}

/* Loading payments action */
export const loadPaymentsByIdsAction = createAction(PaymentActions.LOAD_PAYMENTS_BY_IDS, props<{ paymentIds: string[]; callbacks?: CallbackFunctions }>());
export const loadPaymentsByDateAndPropertiesAction = createAction(PaymentActions.LOAD_ALL_PAYMENTS, props<{ dateFrom: number; dateTo: number; propertyIds: string[]; callbacks?: CallbackFunctions }>());
export const loadPaymentsByPropertyAction = createAction(PaymentActions.LOAD_PAYMENTS_BY_PROPERTY, props<{ filters: PaymentQueryFilters; callbacks?: CallbackFunctions }>());
export const loadPaymentsByOwnerAction = createAction(PaymentActions.LOAD_PAYMENTS_BY_OWNER, props<{ ownerId: string; callbacks?: CallbackFunctions }>());
export const loadPaymentsByOwnerPlusConnectedRentsAction = createAction(PaymentActions.LOAD_PAYMENTS_BY_OWNER_PLUS_CONNECTED_RENTS, props<{ ownerId: string; callbacks?: CallbackFunctions }>());
export const loadOpenPaymentsByTenantAction = createAction(PaymentActions.LOAD_OPEN_PAYMENTS_BY_TENANT, props<{ tenantId: string; callbacks?: CallbackFunctions }>());

/* CRUD for payments */
export const createPaymentAction = createAction(PaymentActions.CREATE_PAYMENT, props<{ payment: PropertyPayment; isMaintenanceClosingPayment?: boolean; callbacks?: CallbackFunctions }>());
export const updatePaymentAction = createAction(PaymentActions.UPDATE_PAYMENT, props<{ payment: PropertyPayment; callbacks?: CallbackFunctions }>());
export const deletePaymentAction = createAction(PaymentActions.DELETE_PAYMENT, props<{ paymentId: string; callbacks?: CallbackFunctions }>());
export const deletePaymentsAction = createAction(PaymentActions.DELETE_PAYMENTS, props<{ payments: PropertyPayment[]; isAllSelected: boolean; callbacks?: CallbackFunctions }>());
export const loadPaymentByIdAction = createAction(PaymentActions.LOAD_PAYMENT_BY_ID, props<{ paymentId: string }>());

/* Payment operations */
export const createPaymentOperationAction = createAction(PaymentActions.CREATE_PAYMENT_OPERATION, props<{ paymentOperation: PaymentOperation; isMaintenanceClosingPayment?: boolean }>());
export const deletePaymentOperationAction = createAction(PaymentActions.DELETE_PAYMENT_OPERATION, props<{ paymentOperation: PaymentOperation }>());

/* Payment settings */
export const updatePaymentSettingAction = createAction(PaymentActions.UPDATE_PAYMENT_SETTING, props<{ paymentRegistration: SettingsPayment }>());
export const loadPaymentSettingsAction = createAction(PaymentActions.LOAD_PAYMENT_SETTINGS);

/* Extra */
export const resetPaymentDatabaseViewAction = createAction(PaymentActions.RESET_PAYMENT_DATABASE_VIEW);
export const lockPaymentsAction = createAction(
	PaymentActions.LOCK_PAYMENTS,
	props<{
		paymentsId: string[];
		lockerType: string;
		lockedBy: string;
		callbacks?: CallbackFunctions;
	}>()
);

/* Loading payments action */
export const loadPaymentsByIdsSuccessAction = createAction(PaymentActions.LOAD_PAYMENTS_BY_IDS_SUCCESS);
export const loadPaymentsByIdsFailureAction = createAction(PaymentActions.LOAD_PAYMENTS_BY_IDS_FAILURE, props<{ errorName?: string; error?: any }>());
export const loadPaymentsByDateAndPropertiesSuccessAction = createAction(PaymentActions.LOAD_ALL_PAYMENTS_SUCCESS, props<{ paymentsQueryResult: QueryResult<PropertyPayment> }>());
export const loadPaymentsByDateAndPropertiesFailureAction = createAction(PaymentActions.LOAD_ALL_PAYMENTS_FAILURE, props<{ errorName?: string; error?: any }>());
export const loadOpenPaymentsByTenantSuccessAction = createAction(PaymentActions.LOAD_OPEN_PAYMENTS_BY_TENANT_SUCCESS, props<{ paymentsQueryResult: QueryResult<PropertyPayment> }>());
export const loadOpenPaymentsByTenantFailureAction = createAction(PaymentActions.LOAD_OPEN_PAYMENTS_BY_TENANT_FAILURE, props<{ errorName?: string; error?: any }>());
export const loadPaymentsByPropertySuccessAction = createAction(PaymentActions.LOAD_PAYMENTS_BY_PROPERTY_SUCCESS, props<{ paymentsQueryResult: QueryResult<PropertyPayment> }>());
export const loadPaymentsByPropertyFailureAction = createAction(PaymentActions.LOAD_PAYMENTS_BY_PROPERTY_FAILURE, props<{ errorName?: string; error?: any }>());
export const loadPaymentsByOwnerSuccessAction = createAction(PaymentActions.LOAD_PAYMENTS_BY_OWNER_SUCCESS, props<{ paymentsQueryResult: QueryResult<PropertyPayment> }>());
export const loadPaymentsByOwnerFailureAction = createAction(PaymentActions.LOAD_PAYMENTS_BY_OWNER_FAILURE, props<{ errorName?: string; error?: any }>());
export const loadPaymentsByOwnerPlusConnectedRentsSuccessAction = createAction(PaymentActions.LOAD_PAYMENTS_BY_OWNER_PLUS_CONNECTED_RENTS_SUCCESS, props<{ paymentsQueryResult: QueryResult<PropertyPayment> }>());
export const loadPaymentsByOwnerPlusConnectedRentsFailureAction = createAction(PaymentActions.LOAD_PAYMENTS_BY_OWNER_PLUS_CONNECTED_RENTS_FAILURE, props<{ errorName?: string; error?: any }>());

/* CRUD for payments */
export const createPaymentSuccessAction = createAction(PaymentActions.CREATE_PAYMENT_SUCCESS, props<{ payment: PropertyPayment }>());
export const createPaymentFailureAction = createAction(PaymentActions.CREATE_PAYMENT_FAILURE, props<{ errorName?: string; error?: any }>());
export const updatePaymentSuccessAction = createAction(PaymentActions.UPDATE_PAYMENT_SUCCESS, props<{ payment: PropertyPayment }>());
export const updatePaymentFailureAction = createAction(PaymentActions.UPDATE_PAYMENT_FAILURE, props<{ errorName?: string; error?: any }>());
export const deletePaymentSuccessAction = createAction(PaymentActions.DELETE_PAYMENT_SUCCESS, props<{ paymentId: string }>());
export const deletePaymentsSuccessAction = createAction(PaymentActions.DELETE_PAYMENTS_SUCCESS, props<{ deletedPaymentIds: string[] }>());
export const deletePaymentFailureAction = createAction(PaymentActions.DELETE_PAYMENT_FAILURE, props<{ errorName?: string; error?: any }>());
export const deletePaymentsFailureAction = createAction(PaymentActions.DELETE_PAYMENTS_FAILURE, props<{ errorName?: string; error?: any }>());
export const loadPaymentByIdSuccessAction = createAction(PaymentActions.LOAD_PAYMENT_BY_ID_SUCCESS, props<{ payment: PropertyPayment }>());
export const loadPaymentByIdFailureAction = createAction(PaymentActions.LOAD_PAYMENT_BY_ID_FAILURE, props<{ errorName?: string; error?: any }>());

/* Payment settings */
export const loadPaymentSettingsSuccessAction = createAction(PaymentActions.LOAD_PAYMENT_SETTINGS_SUCCESS, props<{ settings: SettingsPayment }>());
export const loadPaymentSettingsFailureAction = createAction(PaymentActions.LOAD_PAYMENT_SETTINGS_FAILURE, props<{ errorName?: string; error?: any }>());
export const UpdatePaymentSettingFailureAction = createAction(PaymentActions.CREATE_OR_UPDATE_PAYMENT_SETTING_FAILURE, props<{ errorName?: string; error?: any }>());
export const updatePaymentSettingSuccessAction = createAction(PaymentActions.UPDATE_PAYMENT_SETTING_SUCCESS, props<{ setting: SettingsPayment }>());

/* Payment operations */
export const createPaymentOperationSuccessAction = createAction(PaymentActions.CREATE_PAYMENT_OPERATION_SUCCESS, props<{ paymentOperation: PaymentOperation; payment: PropertyPayment }>());
export const createPaymentOperationFailureAction = createAction(PaymentActions.CREATE_PAYMENT_OPERATION_FAILURE, props<{ errorName?: string; error?: any }>());
export const deletePaymentOperationSuccessAction = createAction(PaymentActions.DELETE_PAYMENT_OPERATION_SUCCESS, props<{ paymentOperation: PaymentOperation; payment: PropertyPayment }>());
export const deletePaymentOperationFailureAction = createAction(PaymentActions.DELETE_PAYMENT_OPERATION_FAILURE, props<{ errorName?: string; error?: any }>());

/* Invoices */
export const createPaymentInvoicesSuccess = createAction(PaymentActions.CREATE_PAYMENT_INVOICES_SUCCESS, props<{ invoices: MultipleInvoiceResponse }>());

/* Extra */
export const lockPaymentsSuccessAction = createAction(PaymentActions.LOCK_PAYMENTS_SUCCESS, props<{ payments: PropertyPayment[] }>());
export const resetPaymentDatabaseViewSuccessAction = createAction(PaymentActions.RESET_PAYMENT_DATABASE_VIEW_SUCCESS);
export const lockPaymentsFailureAction = createAction(PaymentActions.LOCK_PAYMENTS_FAILURE, props<{ errorName?: string; error?: any }>());
export const resetPaymentDatabaseViewFailureAction = createAction(PaymentActions.RESET_PAYMENT_DATABASE_VIEW_FAILURE, props<{ errorName?: string; error?: any }>());
export const resetPaymentsStateAction = createAction(PaymentActions.RESET_PAYMENTS_STATE);
export const resetPaymentsStateSuccessAction = createAction(PaymentActions.RESET_PAYMENTS_STATE_SUCCESS);
export const resetPaymentsStateFailureAction = createAction(PaymentActions.RESET_PAYMENTS_STATE_FAILURE, props<{ errorName?: string; error?: any }>());

export const loadPaymentsPaginatedAction = createAction(PaymentActions.LOAD_PAYMENTS_PAGINATED, props<{ page: number; pageSize: number; filters: FilterContainer; sort: SortEvent; entityType?: string; entityId?: string }>());
export const loadPaymentsPaginatedSuccessAction = createAction(PaymentActions.LOAD_PAYMENTS_PAGINATED_SUCCESS, props<{ page: number; pageSize: number; paymentsQueryResult: QueryResult<PropertyPayment> }>());
export const loadPaymentsPaginatedFailureAction = createAction(PaymentActions.LOAD_PAYMENTS_PAGINATED_FAILURE, props<{ error?: any }>());

export const exportPaymentsPdfAction = createAction(PaymentActions.EXPORT_PAYMENTS_PDF, props<{ filters: FilterContainer; sort: SortEvent; entityType?: string; entityId?: string }>());
export const exportPaymentsPdfActionSuccessAction = createAction(PaymentActions.EXPORT_PAYMENTS_PDF_SUCCESS);
export const exportPaymentsPdfActionFailureAction = createAction(PaymentActions.EXPORT_PAYMENTS_PDF_FAILURE, props<{ error?: any }>());

export const exportPaymentsCsvAction = createAction(PaymentActions.EXPORT_PAYMENTS_CSV, props<{ filters: FilterContainer; sort: SortEvent; entityType?: string; entityId?: string }>());
export const exportPaymentsCsvSuccessAction = createAction(PaymentActions.EXPORT_PAYMENTS_CSV_SUCCESS);
export const exportPaymentsCsvFailureAction = createAction(PaymentActions.EXPORT_PAYMENTS_CSV_FAILURE, props<{ error?: any }>());

export const invalidatePaymentPagesAction = createAction(PaymentActions.INVALIDATE_PAYMENT_PAGES);

/* Margins  */
export const getMarginsAction = createAction(PaymentActions.GET_MARGINS, props<{ propertyId?: string; ownerId?: string }>());
export const getMarginsSuccessAction = createAction(PaymentActions.GET_MARGINS_SUCCESS, props<{ marginsData: any }>());
export const getMarginsFailureAction = createAction(PaymentActions.GET_MARGINS_FAILURE, props<{ error?: any }>());
