import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { ActionsSubject, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, mergeMap, switchMap, take, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FiltersStoreModel } from '../core/services/filters/filters.model';
import { selectFiltersEntities } from '../core/services/filters/state/filters.selector';
import { LandlordService } from '../core/services/landlord/landlord.service';
import { LeaseService } from '../leases/lease.service';
import { getLeaseCsvStringAction } from '../leases/state/lease.actions';
import { Lease } from '../models/lease.model';
import { Tenant, TenantBasic, TenantData, TenantPageModelStore, TenantPageModelUI } from '../models/tenant.model';
import { resetPaymentsStateSuccessAction } from '../payments/state/payments.actions';
import { PropertyService } from '../properties/property.service';
import { CallbackFunctions } from '../store/reducer';
import { AppUtils } from '../utils/app-utils';
import { LocalizationUtils } from '../utils/localization-utils';
import {
	addTenantAction,
	archiveTenantAction,
	deleteTenantByIdAction,
	deleteTenantsAction,
	editTenantAction,
	getTenantCsvStringAction,
	getTenantPlacesStatusAction,
	getTenantsCsvAction,
	loadArchivedTenantsAction,
	loadArchivedTenantsSuccessAction,
	loadTenantByIdAction,
	loadTenantsAction,
	loadTenantsPaginatedAction,
	recoverTenantAction,
	sendPlacesInvitationAction,
	uploadMultipleTenantsAction
} from './state/tenant.actions';
import { selectTenantsEntities, selectTenantsLength } from './state/tenant.selectors';
import { selectTenantBasicState } from './state/tenants-basic.selectors';
import { selectTenantPagesEntities } from './state/tenants-pages.selectors';

export interface CsvTenantParseResponse {
	parsedLines: { errors?: string[]; line: number; tenant?: Tenant; existing: boolean }[];
	error?: string;
}
@Injectable({
	providedIn: 'root'
})
export class TenantService {
	private translations = LocalizationUtils.getTranslations();
	private landlordId: string;
	private BACKEND_HOST = `${environment.services.backend}/api-dash/v1`;

	private tenantsDict$: Observable<Dictionary<Tenant>>;

	constructor(
		private readonly store: Store,
		private readonly leaseService: LeaseService,
		private readonly propertyService: PropertyService,
		private readonly httpClient: HttpClient,
		private readonly landlordService: LandlordService,
		private readonly actions$: ActionsSubject
	) {
		this.landlordService.getLandlordId().subscribe(id => (this.landlordId = id));
		this.actions$.pipe(ofType(resetPaymentsStateSuccessAction)).subscribe(() => {
			this.tenantsDict$ = null;
		});
	}

	private getTenantsDict(): Observable<Dictionary<Tenant>> {
		if (!this.tenantsDict$) {
			this.tenantsDict$ = this.store.select(selectTenantsEntities).pipe(
				takeUntil(this.landlordService.getLandlordId().pipe(filter(res => !!!res))),
				map((tenantsDict: Dictionary<Tenant> | null) => {
					if (tenantsDict) {
						return tenantsDict;
					}
					return null;
				})
			);
		}

		return this.tenantsDict$;
	}

	public getTenantNameById(id: string, truncation: number = 0, fallback?: string): Observable<string> {
		return this.getTenantBasicById(id).pipe(
			map(tenant => {
				return this.getTenantNameSurname(tenant, truncation, fallback);
			})
		);
	}

	public getTenantsBasicDict(): Observable<Dictionary<TenantBasic>> {
		return this.store
			.select(selectTenantBasicState)
			.pipe(AppUtils.fetchIfMissing(this.store, loadTenantsAction({ refreshAll: false })));
	}

	public getTenantsBasic(): Observable<TenantBasic[]> {
		return this.getTenantsBasicDict().pipe(
			map(tenantsDict => {
				if (tenantsDict) {
					return Object.values(tenantsDict);
				} else {
					return null;
				}
			})
		);
	}

	public getTenantBasicById(id: string): Observable<TenantBasic> {
		return this.getTenantsBasicDict().pipe(map(tenantsBasicDict => tenantsBasicDict[id]));
	}

	public getTenantById(id: string, bypassPermissionsCheck?: boolean): Observable<Tenant> {
		return this.getTenantsDict().pipe(
			AppUtils.applyRefreshLogicForItemNotFound(
				this.store,
				id,
				loadTenantByIdAction({ tenantId: id, bypassPermissionsCheck: bypassPermissionsCheck })
			)
		);
	}

	public deleteTenant(id: string, isBulk: boolean = false): Promise<void> {
		return AppUtils.buildActionWithPromise(this.store, deleteTenantByIdAction({ tenantId: id, isBulk }));
	}

	public deleteTenants(tenantIds: string[]): Promise<void> {
		return AppUtils.buildActionWithPromise(this.store, deleteTenantsAction({ tenantIds: tenantIds }));
	}

	public getTenantNameSurname(tenant: Tenant | TenantBasic, truncation: number = 0, fallback?: string): string {
		if (!tenant) {
			return fallback || this.translations.user_deleted;
		} else {
			const name = tenant.name ? `${tenant.name} ${tenant.surname}` : '';
			if (truncation > 0 && name.length > truncation) {
				return name.substr(0, truncation - 1) + '…';
			} else {
				return name;
			}
		}
	}

	public getArchivedTenants(): Observable<Tenant[]> {
		this.store.dispatch(loadArchivedTenantsAction());
		return this.actions$.pipe(
			ofType(loadArchivedTenantsSuccessAction),
			map(res => res.tenantsQueryResult.data)
		);
	}

	public sendPlacesInvitation(tenantsId: string[], email: boolean): void {
		this.store.dispatch(sendPlacesInvitationAction({ tenantsId: tenantsId, invites: { email: email } }));
	}

	public archiveTenant(id: string, callbacks?: CallbackFunctions): void {
		this.getTenantById(id)
			.pipe(take(1))
			.subscribe(tenant => {
				this.store.dispatch(archiveTenantAction({ tenant, callbacks }));
			});
	}

	public uploadMultipleTenants(tenants: Tenant[], hostedPageId?: string, callbacks?: CallbackFunctions): void {
		hostedPageId = hostedPageId || '';
		this.store.dispatch(uploadMultipleTenantsAction({ tenants, hostedPageId, callbacks }));
	}

	public addTenant(tenant: Tenant, sendInvite: boolean = true, callbacks?: CallbackFunctions): void {
		this.store.dispatch(addTenantAction({ tenant, options: { sendInvite }, callbacks }));
	}

	public editTenant(tenant: Tenant, callbacks?: CallbackFunctions, bypassPermissionsCheck?: boolean): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			const newCallBacks = {
				success: () => {
					callbacks?.success();
					resolve();
				},
				fail: () => {
					callbacks?.fail();
					reject();
				}
			};
			this.store.dispatch(
				editTenantAction({ tenant, callbacks: newCallBacks, bypassPermissionsCheck: bypassPermissionsCheck })
			);
		});
	}

	public recoverTenant(tenant: Tenant, callbacks?: CallbackFunctions): void {
		this.store.dispatch(recoverTenantAction({ tenant, callbacks }));
	}

	// Test: An arcived tenant shouldn't show up
	// Test: A tenant with a past lease should show up
	// Test: A tenant with a current lease shouldn't show up
	// Test: A tenant with a current lease with no end should never show up
	public getTenantsAvailableInDate(fromEpoch: number, toEpoch: number): Observable<TenantBasic[]> {
		return this.leaseService.getLeasesDict().pipe(
			mergeMap(leasesDict =>
				this.getTenantsBasic().pipe(
					map(tenants => {
						return tenants.filter(
							tenant =>
								tenant.leasesId.filter(leaseId =>
									this.leaseService.isLeaseActiveInPeriod(leasesDict[leaseId], fromEpoch, toEpoch)
								).length === 0
						);
					})
				)
			)
		);
	}

	public createEmptyTenant(): Tenant {
		return new Tenant(
			'', // id
			'', // photo string
			'', // name
			'', // surname
			'', // phone
			'', // email
			1, // status
			null, // BirthDate
			'', // emergContact
			null, // Role
			null, // Pets
			'', // Guarantor name
			'', // Guarantor surname
			'', // Guarantor email
			'', // Guarantor phone,
			null // Gender
		);
	}

	public getTenantsByLease(lease: Lease, tenants: TenantBasic[]): TenantBasic[] {
		const tenantIdsInLease: string[] = Object.keys(lease.tenantsId);
		const tenantsByLease: TenantBasic[] = [];
		tenantIdsInLease.forEach(id => tenantsByLease.push(tenants.find(it => it.id === id)));
		return tenantsByLease;
	}

	//TODO: move this to observable
	public getTenantLastPropertyName$(tenant: Tenant): Observable<string> {
		let lastEndDate = 0;
		let lastLease: Lease;
		return this.leaseService.getLeasesDict().pipe(
			mergeMap(leasesDict => {
				(tenant.leasesId || []).forEach(cl => {
					const l = leasesDict[cl];

					if (!!l) {
						if (l.endDate > lastEndDate) {
							lastEndDate = l.endDate;
							lastLease = l;
						}
					}
				});

				if (!!lastLease) {
					return this.propertyService.getPropertiesBasicArray().pipe(
						map(properties => {
							if (!!properties) {
								const p = properties.find(p => p.id === lastLease.propertyId);
								return p.name;
							} else {
								return '-';
							}
						})
					);
				} else {
					return of('-');
				}
			})
		);
	}

	public getTenantCsvString(): void {
		return this.store.dispatch(getTenantCsvStringAction());
	}

	public getTenantsCsv(): void {
		return this.store.dispatch(getTenantsCsvAction());
	}

	public loadCsvFile(files: FileList): Observable<CsvTenantParseResponse> {
		const file = files[0];
		const formData = new FormData();
		formData.append('file', file, file.name);
		return this.httpClient.post<CsvTenantParseResponse>(
			`${this.BACKEND_HOST}/landlords/${this.landlordId}/upload-csv`,
			formData
		);
	}

	public getLeasesCsv() {
		return this.store.dispatch(getLeaseCsvStringAction());
	}

	public getTenantsLength(): Observable<number> {
		return this.store.select(selectTenantsLength);
	}

	public getTenantNameSurname$(tenantId: string, truncation: number = 0, fallback?: string): Observable<string> {
		if (!tenantId) return of(this.translations.user_deleted);

		return this.getTenantNameById(tenantId, truncation, fallback);
	}

	getTenantAddress(tenant: Tenant, truncation: number = 0): string {
		const result =
			tenant && tenant.invoiceData
				? tenant.invoiceData.address +
				  ', ' +
				  tenant.invoiceData.city +
				  tenant.invoiceData.postalCode +
				  tenant.invoiceData.country
				: '-';

		if (truncation > 0 && result.length > truncation) {
			return result.substr(0, truncation - 1) + '…';
		} else {
			return result;
		}
	}

	getTenantAddress$(tenantId: string, truncation: number = 0): Observable<string> {
		return this.getTenantById(tenantId).pipe(map(tenant => this.getTenantAddress(tenant, truncation)));
	}

	getTenantPicture$(tenantId: string): Observable<string> {
		return this.getTenantById(tenantId).pipe(map(tenant => this.getTenantPicture(tenant)));
	}

	getTenantPicture(tenant: Tenant): string {
		return tenant && tenant.photo ? tenant.photo : './assets/img/person_standard.svg';
	}

	loadTenantPlacesStatus(tenantId: string) {
		this.store.dispatch(getTenantPlacesStatusAction({ tenantId }));
	}

	public getTenantDataForPopCard(tenantId: string): Observable<TenantData> {
		return this.getTenantBasicById(tenantId).pipe(
			switchMap(tenant => {
				const leaseId = tenant.leasesId[tenant.leasesId.length - 1];
				return combineLatest([of(tenant), leaseId ? this.leaseService.getLease(leaseId) : of(undefined)]);
			}),
			switchMap(([tenant, lease]) => {
				const propertyId = (lease as Lease)?.propertyId;
				return combineLatest([
					of(tenant),
					propertyId ? this.propertyService.getPropertyNameById(propertyId) : of('')
				]);
			}),
			map(([tenant, propertyName]) => {
				return <TenantData>{
					id: tenantId,
					name: this.getTenantNameSurname(tenant),
					currentLeaseStatus: tenant.currentLeaseStatus,
					propertyName: propertyName
				};
			})
		);
	}

	// Version 2 - Backend pagination & filtering

	public getTenantsPaginated(pageSize: number): Promise<TenantPageModelUI> {
		return combineLatest([this.store.select(selectFiltersEntities), this.store.select(selectTenantPagesEntities)])
			.pipe(
				take(1),
				switchMap(
					([storeFilters, storeTenantPages]: [
						Dictionary<FiltersStoreModel>,
						Dictionary<TenantPageModelStore>
					]) => {
						if (
							storeFilters &&
							storeTenantPages &&
							storeTenantPages[storeFilters['tenants']?.pageIndex || 0]
						) {
							return combineLatest([
								of(storeFilters),
								of(storeTenantPages),
								this.store.select(selectTenantsEntities)
							]);
						} else {
							return combineLatest([of(null), of(null), of(null)]);
						}
					}
				),
				switchMap(
					([storeFilters, storeTenantPages, tenantsDict]: [
						Dictionary<FiltersStoreModel>,
						Dictionary<TenantPageModelStore>,
						Dictionary<Tenant>
					]) => {
						if (storeFilters !== null && storeTenantPages && tenantsDict) {
							const sortedTenantIds = storeTenantPages[storeFilters['tenants']?.pageIndex || 0].tenantIds;

							const filteredTenants = Object.values(tenantsDict)
								.filter(tenant => sortedTenantIds.includes(tenant.id))
								.sort((a, b) => sortedTenantIds.indexOf(a.id) - sortedTenantIds.indexOf(b.id));

							console.log(
								'[SERVICE] page foud and valid => return it: (' +
									(storeFilters['tenants']?.pageIndex || 0) +
									' - ' +
									pageSize +
									')'
							);
							return of({
								tenants: filteredTenants,
								totalItems: storeTenantPages[storeFilters['tenants']?.pageIndex || 0].totalItems || 0,
								filteredItems:
									storeTenantPages[storeFilters['tenants']?.pageIndex || 0].filteredItems || 0
							});
						} else {
							console.log('[SERVICE] page NOT foud => return null');
							return of(null);
						}
					}
				),
				take(1)
			)
			.toPromise();
	}

	public loadTenantsPage(pageSize: number) {
		this.store
			.select(selectFiltersEntities)
			.pipe(take(1))
			.subscribe(storeFilters => {
				console.log(
					'[SERVICE] dispatch LOAD page ' +
						(storeFilters['tenants']?.pageIndex || 0) +
						' ' +
						pageSize +
						' ' +
						JSON.stringify(storeFilters['tenants']?.sort || {}) +
						' -->'
				);
				this.store.dispatch(
					loadTenantsPaginatedAction({
						page: storeFilters['tenants']?.pageIndex || 0,
						pageSize: pageSize,
						filters: storeFilters['tenants']?.filters || {},
						sort: storeFilters['tenants']?.sort || {}
					})
				);
			});
	}
}
