import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of, throwError } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, take } from 'rxjs/operators';
import { LandlordService } from 'src/app/core/services/landlord/landlord.service';
import { environment } from 'src/environments/environment';
import {
	loadChatMetricsAction,
	loadChatMetricsFailureAction,
	loadChatMetricsSuccessAction,
	loadCMMetricsFailureAction,
	loadCMMetricsSuccessAction,
	loadCMUnitMetricsAction,
	loadCMUnitMetricsSuccessAction,
	loadEventsMetricsAction,
	loadEventsMetricsFailureAction,
	loadEventsMetricsSuccessAction,
	loadMaintenancesViewAction,
	loadMaintenancesViewFailureAction,
	loadMaintenancesViewSuccessAction,
	loadOverdueIdsViewAction,
	loadOverdueIdsViewFailureAction,
	loadOverdueIdsViewSuccessAction,
	loadPaymentsViewAction,
	loadPaymentsViewFailureAction,
	loadPaymentsViewSuccessAction,
	loadPropertiesMetricsAction,
	loadPropertiesMetricsFailureAction,
	loadPropertiesMetricsSuccessAction,
	loadTenantsMetricsAction,
	loadTenantsMetricsFailureAction,
	loadTenantsMetricsSuccessAction,
	loadUnitStatusViewAction,
	loadUnitStatusViewFailureAction,
	loadUnitStatusViewSuccessAction
} from './metrics.actions';
import {
	ChannelManagerMetricsData,
	ChannelManagerUnitMetricsData,
	ChatMetricsData,
	EventMetricsData,
	OpenMaintenancesData,
	OverdueIdsData,
	PaymentMetricsData,
	PropertiesMetricsData,
	TenantMetricsData,
	UnitStatusData
} from './metrics.reducers';

@Injectable()
export class MetricsEffects {
	private BACKEND_HOST = `${environment.services.backend}/api-dash/v1`;
	private landlordId = '';

	constructor(
		private readonly landlordService: LandlordService,
		private readonly httpClient: HttpClient,
		private readonly actions$: Actions
	) {
		this.landlordService.getLandlordId().subscribe(id => (this.landlordId = id));
	}

	public loadPaymentViewsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadPaymentsViewAction),
			mergeMap(action =>
				this.loadPaymentViews().pipe(
					map(views => loadPaymentsViewSuccessAction({ views })),
					catchError(err => of(loadPaymentsViewFailureAction({})))
				)
			)
		)
	);

	public loadOverdueIdsViewEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadOverdueIdsViewAction),
			mergeMap(action =>
				this.loadOverdueIdsView().pipe(
					map(view => loadOverdueIdsViewSuccessAction({ view })),
					catchError(err => of(loadOverdueIdsViewFailureAction({})))
				)
			)
		)
	);

	public loadMaintenancesViewEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadMaintenancesViewAction),
			mergeMap(action =>
				this.loadMaintenancesView().pipe(
					map(view => loadMaintenancesViewSuccessAction({ view })),
					catchError(err => of(loadMaintenancesViewFailureAction({})))
				)
			)
		)
	);

	public loadUnitLeaseStatusEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadUnitStatusViewAction),
			mergeMap(action =>
				this.loadUnitStatus().pipe(
					map(view => loadUnitStatusViewSuccessAction({ view })),
					catchError(err => of(loadUnitStatusViewFailureAction({})))
				)
			)
		)
	);

	public loadTenantsMetricsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadTenantsMetricsAction),
			mergeMap(action =>
				this.loadTenantMetrics().pipe(
					map(view => loadTenantsMetricsSuccessAction({ view })),
					catchError(err => of(loadTenantsMetricsFailureAction({})))
				)
			)
		)
	);

	public loadPropertiesMetricsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadPropertiesMetricsAction),
			mergeMap(action =>
				this.loadPropertiesMetrics().pipe(
					map(view => loadPropertiesMetricsSuccessAction({ view })),
					catchError(err => of(loadPropertiesMetricsFailureAction({})))
				)
			)
		)
	);

	public loadCMMetricsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadCMUnitMetricsAction),
			mergeMap(action =>
				this.loadCMMetrics().pipe(
					map(view => loadCMMetricsSuccessAction({ view })),
					catchError(err => of(loadCMMetricsFailureAction({})))
				)
			)
		)
	);

	public loadCMUnitMetricsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadCMUnitMetricsAction),
			mergeMap(action =>
				this.loadCMUnitMetrics().pipe(
					map(view => loadCMUnitMetricsSuccessAction({ view })),
					catchError(err => of(loadCMMetricsFailureAction({})))
				)
			)
		)
	);

	public loadEventsMetricsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadEventsMetricsAction),
			mergeMap(action =>
				this.loadEventMetrics().pipe(
					map(view => loadEventsMetricsSuccessAction({ view })),
					catchError(err => of(loadEventsMetricsFailureAction({})))
				)
			)
		)
	);

	public loadChatMetricsEffect = createEffect(() =>
		this.actions$.pipe(
			ofType(loadChatMetricsAction),
			mergeMap(action =>
				this.loadChatMetrics().pipe(
					map(view => loadChatMetricsSuccessAction({ view })),
					catchError(err => of(loadChatMetricsFailureAction({})))
				)
			)
		)
	);

	private loadPaymentViews(): Observable<PaymentMetricsData> {
		const defaultView: PaymentMetricsData = {
			overdueRents: {},
			overduePendingRents: {},
			overdueExpenses: {},
			overduePendingExpenses: {},
			upcomingExpenses: {},
			upcomingPendingExpenses: {},
			upcomingRents: {},
			upcomingPendingRents: {},
			paidRents: {},
			paidExpenses: {},
			count: { rents: {}, expenses: {} },
			solvencyRate: {},
			movements: {}
		};

		if (this.landlordId) {
			return this.httpClient
				.get<PaymentMetricsData>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/metrics/paymentViews`)
				.pipe(
					take(1),
					map(it => (it ? { ...defaultView, ...it } : defaultView)),
					debounceTime(1000) // Makes no sense to update this more than one per second
				);
		} else {
			console.error(`Cannot load payment views. Landlord id is null`);
			return throwError(`Cannot load payment views. Landlord id is null`);
		}
	}

	private loadOverdueIdsView(): Observable<OverdueIdsData> {
		const defaultView = {
			overduePropIds: {},
			overdueTenantIds: {},
			overdueUnitIds: {}
		};

		if (this.landlordId) {
			return this.httpClient
				.get<OverdueIdsData>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/metrics/overdueIds`)
				.pipe(
					take(1),
					map(it => {
						return it ? { ...defaultView, ...it } : defaultView;
					})
				);
		} else {
			console.error(`Cannot load payment views. Landlord id is null`);
			return throwError(`Cannot load payment views. Landlord id is null`);
		}
	}

	private loadMaintenancesView(): Observable<OpenMaintenancesData> {
		const defaultView = {
			low: 0,
			medium: 0,
			high: 0,
			critical: 0
		};

		if (this.landlordId) {
			return this.httpClient
				.get<OpenMaintenancesData>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/metrics/maintenances`)
				.pipe(
					take(1),
					map(it => {
						return it ? { ...defaultView, ...it } : defaultView;
					})
				);
		} else {
			console.error(`Cannot load payment views. Landlord id is null`);
			return throwError(`Cannot load payment views. Landlord id is null`);
		}
	}

	private loadUnitStatus(): Observable<UnitStatusData> {
		const defaultView = {
			active: 0,
			starting: 0,
			all: 0,
			vacant: 0
		};

		if (this.landlordId) {
			return this.httpClient
				.get<UnitStatusData>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/metrics/unitsStatus`)
				.pipe(
					take(1),
					map(it => {
						return it ? { ...defaultView, ...it } : defaultView;
					})
				);
		} else {
			console.error(`Cannot load payment views. Landlord id is null`);
			return throwError(`Cannot load payment views. Landlord id is null`);
		}
	}

	private loadTenantMetrics(): Observable<TenantMetricsData> {
		const defaultView = {
			turnover: 0,
			appDownloads: 0,
			leasesCount: 0
		};

		if (this.landlordId) {
			return this.httpClient
				.get<TenantMetricsData>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/metrics/tenants`)
				.pipe(
					take(1),
					map(it => {
						return it ? { ...defaultView, ...it } : defaultView;
					})
				);
		} else {
			console.error(`Cannot load payment views. Landlord id is null`);
			return throwError(`Cannot load payment views. Landlord id is null`);
		}
	}

	private loadCMUnitMetrics(): Observable<ChannelManagerUnitMetricsData> {
		const defaultView: ChannelManagerUnitMetricsData = {
			published: 0,
			errors: 0,
			syncing: 0,
			publishable: 0
		};

		if (this.landlordId) {
			return this.httpClient
				.get<ChannelManagerUnitMetricsData>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/metrics/cmUnits`)
				.pipe(
					take(1),
					map(it => {
						return it ? { ...defaultView, ...it } : defaultView;
					})
				);
		} else {
			console.error(`Cannot load payment views. Landlord id is null`);
			return throwError(`Cannot load CM Metrics. Landlord id is null`);
		}
	}

	private loadCMMetrics(): Observable<ChannelManagerMetricsData> {
		const defaultView: ChannelManagerMetricsData = {
			avgListingScore: 0,
			optimizableUnits: 0
		};

		if (this.landlordId) {
			return this.httpClient
				.get<ChannelManagerMetricsData>(
					`${this.BACKEND_HOST}/landlords/${this.landlordId}/metrics/listingScore`
				)
				.pipe(
					take(1),
					map(it => {
						return it ? { ...defaultView, ...it } : defaultView;
					})
				);
		} else {
			console.error(`Cannot load payment views. Landlord id is null`);
			return throwError(`Cannot load CM Metrics. Landlord id is null`);
		}
	}

	private loadEventMetrics(): Observable<EventMetricsData> {
		const defaultView: EventMetricsData = {
			events: [],
			categories: []
		};

		if (this.landlordId) {
			return this.httpClient
				.get<EventMetricsData>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/metrics/eventsPreview`)
				.pipe(
					take(1),
					map(it => {
						return it ? { ...defaultView, ...it } : defaultView;
					})
				);
		} else {
			console.error(`Cannot load events view. Landlord id is null`);
			return throwError(`Cannot load Event Metrics. Landlord id is null`);
		}
	}

	private loadChatMetrics(): Observable<ChatMetricsData> {
		const defaultView: ChatMetricsData = {
			messages: []
		};

		if (this.landlordId) {
			return this.httpClient
				.get<ChatMetricsData>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/metrics/chatPreview`)
				.pipe(
					take(1),
					map(it => {
						return it ? { ...defaultView, ...it } : defaultView;
					})
				);
		} else {
			console.error(`Cannot load chat views. Landlord id is null`);
			return throwError(`Cannot load Chat Metrics. Landlord id is null`);
		}
	}

	private loadPropertiesMetrics(): Observable<PropertiesMetricsData> {
		const defaultView = {
			unitsCount: 0,
			occupancyRate: { value: 0, increase: 0 },
			unitVacancies: { value: 0, increase: 0 }
		};

		if (this.landlordId) {
			return this.httpClient
				.get<PropertiesMetricsData>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/metrics/properties`)
				.pipe(
					take(1),
					map(it => {
						return it ? { ...defaultView, ...it } : defaultView;
					})
				);
		} else {
			console.error(`Cannot load payment views. Landlord id is null`);
			return throwError(`Cannot load payment views. Landlord id is null`);
		}
	}
}
