import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { BillingService } from 'src/app/billing/billing.service';
import { updateBillingPlanSuccessAction } from 'src/app/billing/state/billing.actions';
import { LandlordService } from 'src/app/core/services/landlord/landlord.service';
import { PropertyService } from 'src/app/properties/property.service';
import { LocalizationUtils } from 'src/app/utils/localization-utils';

@Component({
	selector: 'app-change-paid-plan-dialog',
	templateUrl: './change-paid-plan-dialog.component.html',
	styleUrls: ['./change-paid-plan-dialog.component.scss']
})
export class ChangePaidPlanDialogComponent implements OnInit, OnDestroy {
	translations = LocalizationUtils.getTranslations();
	landlordName;
	private numOfUnits: number;
	private componentDestroyed = new Subject();
	constructor(
		public dialogRef: MatDialogRef<ChangePaidPlanDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		private billingService: BillingService,
		private propertyService: PropertyService,
		private router: Router,
		private readonly actions$: Actions,
		private readonly landlordService: LandlordService
	) {
		this.landlordService
			.getLandlordUserData()
			.pipe(take(1))
			.subscribe(data => (this.landlordName = data.name + ' ' + data.surname));
	}

	ngOnDestroy(): void {
		this.componentDestroyed.next();
	}

	ngOnInit() {
		this.propertyService
			.getPropertiesBasicArray()
			.pipe(takeUntil(this.componentDestroyed))
			.subscribe(propertiesBasic => {
				const realProperties = propertiesBasic.filter(p => !p.isMock);
				this.numOfUnits = realProperties.map(p => p.units).reduce((a, b) => a.concat(b), []).length;
			});

		this.landlordService
			.getLandlordUserData()
			.pipe(take(1))
			.subscribe(data => (this.landlordName = data.name + ' ' + data.surname));
	}

	getNumberOfUnits(): number {
		let num = this.numOfUnits;
		if (num === 0) {
			num = 1;
		}
		return num;
	}

	getLandlordName(): string {
		return this.landlordName;
	}

	closeClick() {
		this.dialogRef.close({ action: 'none' });
	}

	okClick() {
		if (this.numOfUnits) {
			const newPlan = this.data.selectedPlan;

			this.billingService.updateBillingPlan(newPlan, this.getNumberOfUnits());
			this.actions$.pipe(ofType(updateBillingPlanSuccessAction), take(1)).subscribe(res => {
				this.dialogRef.close({ action: 'ok' });
			});
		}
	}

	seeTerms() {
		const url =
			LocalizationUtils.getLanguage() === 'it'
				? 'https://firebasestorage.googleapis.com/v0/b/roommate-d6e73.appspot.com/o/dashboardData%2FRoomMate_SubscriptionAndCancellationConditions_IT.pdf?alt=media&token=b29f9fbd-dda9-4043-b3a7-efa71656b4c4'
				: 'https://firebasestorage.googleapis.com/v0/b/roommate-d6e73.appspot.com/o/dashboardData%2FRoomMate_SubscriptionAndCancellationConditions_EN.pdf?alt=media&token=82cb06ea-b607-4b19-acc3-6e62f1da6b77';
		window.open(url, '_blank');
	}
}
