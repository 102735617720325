import { createAction, props } from '@ngrx/store';
import { QueryResult } from 'src/app/models/common';
import { Tenant } from 'src/app/models/tenant.model';
import { CallbackFunctions } from 'src/app/store/reducer';
import { FiltersStoreModel } from '../filters.model';

export enum FiltersActions {
	// Effect Actions
	PUBLISH_NEW_FILTERS = '[Filters] Publish new filters'
}

// Effect Actions
export const publishNewFiltersAction = createAction(FiltersActions.PUBLISH_NEW_FILTERS, props<{ filters: FiltersStoreModel }>());
