import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AvailableChannel, ChannelConnection, UnitStatus, UnitSyncStatus } from 'src/app/models/channelManager.model';
import { environment } from '../../environments/environment';
import { LandlordService } from '../core/services/landlord/landlord.service';
import { selectChannelManagerListingsEntities } from '../properties/state/unit-listings.selectors';
import { ChannelManagerUnitSyncRequest } from './channel-manager-sync-procedure/cm-sync-select-units/cm-sync-select-units-table/cm-sync-select-units-table-datasource.component';
import { loadChannelManagerConnectionsAction, updateChannelManagerConnectionAction } from './state/connections.actions';
import { selectChannelManagerConnectionEntities } from './state/connections.selectors';

@Injectable({
	providedIn: 'root'
})
export class ChannelManagerService {
	private landlordId;
	private BACKEND_HOST = environment.services.channelmanager;

	public allAvailableChannels: AvailableChannel[] = [
		{
			name: 'RentYourNest',
			description: $localize`:@@ryn_description: RentYourNest strives to simplify the journey of finding, renting, and managing properties for both tenants and small-scale property owners`,
			picture: './assets/img/logo_ryn.png',
			status: 'contact',
			id: 'ryn'
		},
		{
			name: 'Roomless',
			description: $localize`:@@roomless_description:Roomless is a real estate platform focused on mid-long term rentals of premium apartments and rooms.`,
			picture: './assets/img/logo_roomless_128.png',
			status: 'contact',
			id: 'roomless'
		},
		{
			name: 'Italia In Campus',
			description: '',
			picture: './assets/img/logo_roommate.png',
			status: 'contact',
			id: 'iic'
		},
		{
			name: 'Uniplaces',
			description: $localize`:@@uniplaces_description:If you rent to students and young professionals, this is the place to be`,
			picture: './assets/img/logo_uniplaces.png',
			status: 'contact',
			id: 'uniplaces'
		},
		{
			name: 'Housing Anywhere',
			id: 'hoa',
			description: $localize`:@@hoa_description:List your units to more than 10 million students and young professionals in the biggest European listing portal`,
			picture: './assets/img/logo_hoa.png',
			status: 'contact'
		},
		{
			name: 'SpotAHome',
			id: 'spotahome',
			description: $localize`:@@spotahome_description:Enter in the house-checker portal. More than 150.000 verified units to provide your best service to applicant tenants`,
			picture: './assets/img/logo_spotahome_280.png',
			status: 'contact'
		},
		// {
		// 	name: 'Idealista',
		// 	id: 'idealista',
		// 	description: $localize`:@@idealista_description:More than 22 millions monthly visits to maximise the visibility of your listings`,
		// 	picture: './assets/img/logo_idealista.png',
		// 	status: 'contact'
		// },
		{
			name: 'Koliving',
			id: 'koliving',
			description: $localize`:@@koliving_description:Koliving make it possible to book flats in coliving spaces in real time.`,
			picture: './assets/img/logo_koliving.png',
			status: 'contact'
		},
		{
			name: 'Personal FEED',
			id: 'personal',
			description: $localize`:@@roommatefeed_description:Connect your website or any other application to a JSON FEED connected to the channel manager.`,
			picture: './assets/img/logo_ryn.png',
			status: 'contact'
		},
		{
			name: 'Inlife',
			description: $localize`:@@inlife_description:Have live video calls with the landlords or book your favorite place instantly!`,
			picture: './assets/img/logo_inlife.svg',
			status: 'contact',
			id: 'inlife'
		},
		{
			name: 'Lodgerin',
			description: $localize`:@@lodgerin_description:Company selected by Lanzadera Agency for students and young professionals abroad We help you start a new experience!`,
			picture: './assets/img/logo_lodgerin.jpeg',
			status: 'contact',
			id: 'lodgerin'
		},
		{
			name: 'Erasmus Play',
			id: 'erasmusplay',
			description: $localize`:@@erasmusplay_description:Erasmus Play is a verified student housing search engine. They aggregate accommodation from multiple accommodation providers, residences, Colivings and property managers all across Europe.`,
			picture: './assets/img/logo_erasmusplay.jpg',
			status: 'contact'
		},
		{
			name: 'Dès Vu',
			description: $localize`:@@desvu_description:Dès Vu Feed integration`,
			picture: './assets/img/logo_desvu.svg',
			status: 'contact',
			id: 'desvu'
		},
		{
			name: 'Homelike',
			description: $localize`:@@homelike_description:Homelike integration. Fully Furnished Apartments with Everything You Need. Modern Decor and Comforts. Amazing Locations and Selection.`,
			picture: './assets/img/logo_homelike.png',
			status: 'contact',
			id: 'homelike'
		}
	];

	constructor(
		private http: HttpClient,
		private readonly landlordService: LandlordService,
		private readonly store: Store,
		private readonly actions: Actions
	) {
		this.landlordService.getLandlordId().subscribe(id => (this.landlordId = id));
	}

	getUnitIntegrationStatus(unitId: string): Observable<UnitSyncStatus[]> {
		return this.store.select(selectChannelManagerListingsEntities).pipe(
			map(dict => {
				const unitStatusFound = dict[unitId];

				if (!unitStatusFound) {
					return [];
				} else {
					return Object.values(unitStatusFound.connections);
				}
			})
		);
	}

	getChannelConnections(): Observable<ChannelConnection[]> {
		this.store.dispatch(loadChannelManagerConnectionsAction({}));

		return this.store.select(selectChannelManagerConnectionEntities).pipe(
			filter(it => !!it),
			map(it => {
				console.log(JSON.stringify(it));
				return Object.values<ChannelConnection>(it);
			})
		);
	}

	getUnitSyncStatuses(): Observable<UnitStatus[]> {
		return this.store.select(selectChannelManagerListingsEntities).pipe(
			map(dict => {
				if (dict) {
					return Object.values(dict);
				} else {
					return [];
				}
			})
		);
	}

	async addUnit(propertyId: string, unitId: string, connectionsId: string[]): Promise<any> {
		return this.http
			.put(`${this.BACKEND_HOST}/landlords/${this.landlordId}/units/${propertyId}/${unitId}`, {
				connectionsId
			})
			.pipe(take(1))
			.toPromise();
	}

	async syncUnitAll(propertyId: string, unitId: string, connectionsId: string[]): Promise<any> {
		return this.http
			.put(`${this.BACKEND_HOST}/landlords/${this.landlordId}/units/${propertyId}/${unitId}`, {
				connectionsId
			})
			.pipe(take(1))
			.toPromise();
	}

	async syncUnitData(propertyId: string, unitId: string, connectionsId: string[]): Promise<any> {
		return this.http
			.put(`${this.BACKEND_HOST}/landlords/${this.landlordId}/units/${propertyId}/${unitId}/data`, {
				connectionsId
			})
			.pipe(take(1))
			.toPromise();
	}

	async syncUnitAvailability(propertyId: string, unitId: string, connectionsId: string[]): Promise<any> {
		return this.http
			.put(`${this.BACKEND_HOST}/landlords/${this.landlordId}/units/${propertyId}/${unitId}/availability`, {
				connectionsId
			})
			.pipe(take(1))
			.toPromise();
	}

	async syncUnitPictures(propertyId: string, unitId: string, connectionsId: string[]): Promise<any> {
		return this.http
			.put(`${this.BACKEND_HOST}/landlords/${this.landlordId}/units/${propertyId}/${unitId}/pictures`, {
				connectionsId
			})
			.pipe(take(1))
			.toPromise();
	}

	async syncUnitPrices(propertyId: string, unitId: string, connectionsId: string[]): Promise<any> {
		return this.http
			.put(`${this.BACKEND_HOST}/landlords/${this.landlordId}/units/${propertyId}/${unitId}/prices`, {
				connectionsId
			})
			.pipe(take(1))
			.toPromise();
	}

	async startSyncProcedure(unitSyncDetails: ChannelManagerUnitSyncRequest[]): Promise<any> {
		return this.http
			.post(`${this.BACKEND_HOST}/landlords/${this.landlordId}/units/`, {
				unitSyncRequests: unitSyncDetails
			})
			.pipe(take(1))
			.toPromise();
	}

	async removeUnit(propertyId: string, unitId: string, connectionsId: string[] = ['hoa']): Promise<any> {
		return this.http
			.request('delete', `${this.BACKEND_HOST}/landlords/${this.landlordId}/units/${propertyId}/${unitId}`, {
				body: {
					connectionsId
				}
			})
			.pipe(take(1))
			.toPromise();
	}

	async pauseConnection(connection: ChannelConnection) {
		this.store.dispatch(
			updateChannelManagerConnectionAction({
				connection: {
					id: connection.id,
					status: 'paused',
					channelId: connection.channelId,
					type: connection.type,
					landlordId: connection.landlordId
				}
			})
		);
	}

	async resumeConnection(connection: ChannelConnection) {
		this.store.dispatch(
			updateChannelManagerConnectionAction({
				connection: {
					id: connection.id,
					status: 'active',
					channelId: connection.channelId,
					type: connection.type,
					landlordId: connection.landlordId
				}
			})
		);
	}

	async addHousingAnywhereApiKeyConnection(
		channelId: string,
		apiKey: string,
		name: string = 'Housing Anywhere',
		description: string = '',
		picture?: string,
		options?: any
	): Promise<ChannelConnection> {
		return this.http
			.post<ChannelConnection>(`${this.BACKEND_HOST}/landlords/${this.landlordId}/connections/${channelId}/`, {
				connection: {
					clientSecret: apiKey,
					name,
					description,
					picture,
					options,
					channelId: 'hoa'
				}
			})
			.pipe(take(1))
			.toPromise();
	}
}
