import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
	name: 'formatAmountSymbol'
})
export class FormatAmountSymbolPipe extends CurrencyPipe implements PipeTransform {
	transform(value: number, currencyCode: string, sign: boolean, ...args: any[]): any {
		const decimalNumber = Math.round(Math.abs(value)) / 100;

		const result = super.transform(decimalNumber, currencyCode, 'symbol', '1.2-2');

		if (!result && result !== '0') {
			return '';
		}

		if (sign) {
			return value >= 0 ? `+ ${result}` : `- ${result}`;
		} else {
			return value >= 0 ? `${result}` : `- ${result}`;
		}
	}
}
