import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { publishNewFiltersAction } from 'src/app/core/services/filters/state/filters.actions';
import { MaintenancePageModelStore } from 'src/app/models/maintenanceRequest.model';
import {
	deletePropertySuccessAction,
	loadPropertiesBasicSuccessAction,
	loadPropertyByIdSuccessAction
} from 'src/app/properties/state/properties.actions';
import {
	addMaintenanceSuccessAction,
	addMockMaintenanceSuccessAction,
	deleteMaintenanceSuccessAction,
	editMaintenanceSuccessAction,
	invalidateMaintenancePagesAction,
	loadMaintenancesPaginatedSuccessAction,
	loadMaintenanceSuccessAction,
	resetMaintenancesStateSuccessAction
} from './maintenances.actions';

export interface MaintenancePagesState extends EntityState<MaintenancePageModelStore> {
	isLoaded: boolean;
}
export const maintenancePagesAdapter = createEntityAdapter<MaintenancePageModelStore>({
	selectId: model => model.id
});
const initialMaintenancePagesState = maintenancePagesAdapter.getInitialState({
	isLoaded: false
});

export const MaintenancePagesReducers = createReducer(
	initialMaintenancePagesState,

	// Load pages

	on(loadMaintenancesPaginatedSuccessAction, (state, payload) => {
		if (payload.maintenancesQueryResult) {
			return maintenancePagesAdapter.upsertOne(
				{
					id: payload.page,
					maintenanceIds: payload.maintenancesQueryResult.data.map(maintenance => maintenance.id),
					totalItems: payload.maintenancesQueryResult.metadata.totalItems || 0,
					filteredItems: payload.maintenancesQueryResult.metadata.filteredItems || 0
				},
				{ ...state, isLoaded: true }
			);
		} else {
			return state;
		}
	}),

	// Filters user actions

	on(publishNewFiltersAction, (state, payload) => {
		if (payload.filters.type === 'maintenances' && (payload.filters.filters || payload.filters.sort)) {
			return initialMaintenancePagesState;
		} else {
			return state;
		}
	}),

	// SOME pages invalidation

	on(deleteMaintenanceSuccessAction, (state, payload) => {
		const allPages = Object.values(state.entities).sort((a, b) => a.id - b.id);
		let minDirtyPage = -1;
		for (let i = 0; i < allPages.length; i++) {
			if (allPages[i].maintenanceIds.includes(payload.maintenanceId)) {
				minDirtyPage = allPages[i].id;
				break;
			}
		}

		if (minDirtyPage >= 0) {
			return maintenancePagesAdapter.removeMany(it => it.id >= minDirtyPage, state);
		} else {
			return state;
		}
	}),

	// Property actions (user actions + realtime updates)

	// ALL pages invalidation

	on(loadPropertiesBasicSuccessAction, (state, payload) => {
		if (payload['refreshAll']) {
			return { ...initialMaintenancePagesState, isLoaded: true };
		} else {
			return state;
		}
	}),

	on(invalidateMaintenancePagesAction, (state, payload) => {
		return { ...initialMaintenancePagesState, isLoaded: true };
	})
);
