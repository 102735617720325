export enum IntercomGuide {
	HOW_ADD_PROPERTY = 6808266
}
//show product tour
export type ProductTourIds = {
	[key in ProductTourComponentName]: ProductTourId;
};

export type ProductTourId = {
	[key in language]: string; //productTourId
};

export declare type language = 'en' | 'it' | 'fr' | 'es';

export declare type ProductTourComponentName =
	| 'home'
	| 'tenants'
	| 'properties'
	| 'owners'
	| 'payments'
	| 'maintenances'
	| 'bookings'
	| 'availability'
	| 'calendar'
	| 'chat';

export class ProductTour {
	public static productTours: ProductTourIds = {
		home: {
			//en: '418098', //TEST
			en: '388887',
			it: '414639',
			fr: '414638',
			es: '414169'
		},
		tenants: {
			//en: '419772', //TEST
			en: '412247',
			it: '415491',
			fr: '415489',
			es: '415487'
		},
		properties: {
			en: '411565',
			it: '414645',
			fr: '414643',
			es: '414642'
		},
		owners: {
			en: '412387',
			it: '415606',
			fr: '415603',
			es: '415601'
		},
		payments: {
			en: '412266',
			it: '415495',
			fr: '415493',
			es: '415492'
		},
		maintenances: {
			//en: '420188', //TEST
			en: '412401',
			it: '415611',
			fr: '415610',
			es: '415609'
		},
		bookings: {
			en: '411567',
			it: '415486',
			fr: '415484',
			es: '415483'
		},
		availability: {
			en: '411566',
			it: '414650',
			fr: '414648',
			es: '414647'
		},
		calendar: {
			en: '412406',
			it: '415617',
			fr: '415614',
			es: '415612'
		},
		chat: {
			//en: '420205', //TEST
			en: '412629',
			it: '415625',
			fr: '415620',
			es: '415619'
		}
	};

	constructor() {}

	public static getProductTourId(component: string, language: string): string {
		return this.productTours[component] && this.productTours[component][language];
	}
}
