import { createAction, props } from '@ngrx/store';
import { FilterContainer, SortEvent } from 'src/app/core/services/filters/filters.model';
import { QueryResult } from 'src/app/models/common';
import { PropertyOwner, PropertyOwnerCreate } from 'src/app/models/propertyOwner.model';
import { CallbackFunctions } from 'src/app/store/reducer';

export enum OwnerActions {
	// Effect Actions
	GET_OWNER_BY_ID = '[Owner] Get One Owner By ID',
	ADD_OWNER = '[Owner] Add Owner',
	EDIT_OWNER = '[Owner] Edit Owner',
	DELETE_OWNER = '[Owner] Delete Owner',
	DELETE_OWNER_BY_ID = '[Owner] Delete Owner by id',
	LOAD_OWNERS = '[Owner] Load Owners',
	ADD_PROPERTY_TO_OWNER = '[Owner/Property] Add new property to Owner',
	RESET_OWNERS_STATE = '[Owner] Reset Owners State',
	// Version 2
	LOAD_OWNERS_PAGINATED = '[Owner] Load Owners Paginated',

	// Reducer Success Actions
	LOAD_OWNER_SUCCESS = '[Owner] Load Owner Success',
	ADD_OWNER_SUCCESS = '[Owner] Add Owner Success',
	EDIT_OWNER_SUCCESS = '[Owner] Edit Owner Success',
	DELETE_OWNER_SUCCESS = '[Owner] Delete Owner Success',
	LOAD_OWNERS_SUCCESS = '[Owner] Load Owners Success',
	RESET_OWNERS_STATE_SUCCESS = '[Owner] Reset Owners State Success',
	ADD_PROPERTY_TO_OWNER_SUCCESS = '[Owner/Property] Add new property to Owner Success',
	// Version 2
	LOAD_OWNERS_PAGINATED_SUCCESS = '[Owner] Load Owners Paginated Success',

	// Reducer Failure actions
	LOAD_OWNER_FAILURE = '[Owner] Load Owner Failure',
	ADD_OWNER_FAILURE = '[Owner] Add Owner Failure',
	EDIT_OWNER_FAILURE = '[Owner] Edit Owner Failure',
	DELETE_OWNER_FAILURE = '[Owner] Delete Owner Failure',
	LOAD_OWNERS_FAILURE = '[Owner] Load Owners Failure',
	RESET_OWNERS_STATE_FAILURE = '[Owner] Reset Owners State Failure',
	ADD_UNIT_TO_OWNER_FAILURE = '[Owner/Property] Add new property to Owner Failure',
	// Version 2
	LOAD_OWNERS_PAGINATED_FAILURE = '[Owner] Load Owners Paginated Failure'
}

// Effect Actions
export const getOwnerByIdAction = createAction(OwnerActions.GET_OWNER_BY_ID, props<{ ownerId: string; bypassPermissionsCheck?: boolean; options?: any }>());
export const addOwnerAction = createAction(OwnerActions.ADD_OWNER, props<{ owner: PropertyOwnerCreate; callbacks: CallbackFunctions }>());
export const editOwnerAction = createAction(OwnerActions.EDIT_OWNER, props<{ owner: PropertyOwnerCreate; callbacks: CallbackFunctions; bypassPermissionsCheck?: boolean }>());
export const deleteOwnerAction = createAction(OwnerActions.DELETE_OWNER, props<{ owner: PropertyOwner; callbacks?: CallbackFunctions }>());
export const loadOwnersAction = createAction(OwnerActions.LOAD_OWNERS, props<{ refreshAll?: boolean }>());
export const resetOwnersStateAction = createAction(OwnerActions.RESET_OWNERS_STATE);
export const addNewPropertyToOwnerAction = createAction(OwnerActions.ADD_PROPERTY_TO_OWNER, props<{ ownerId: string; propertyIdsToAdd: string[] }>());
// Version 2
export const loadOwnersPaginatedAction = createAction(OwnerActions.LOAD_OWNERS_PAGINATED, props<{ page: number; pageSize: number; filters: FilterContainer; sort: SortEvent }>());

// Reducer Success Actions
export const loadOwnerSuccessAction = createAction(OwnerActions.LOAD_OWNER_SUCCESS, props<{ owner: PropertyOwner }>());
export const addOwnerSuccessAction = createAction(OwnerActions.ADD_OWNER_SUCCESS, props<{ owner: PropertyOwner }>());
export const editOwnerSuccessAction = createAction(OwnerActions.EDIT_OWNER_SUCCESS, props<{ owner: PropertyOwner }>());
export const deleteOwnerSuccessAction = createAction(OwnerActions.DELETE_OWNER_SUCCESS, props<{ ownerId: string }>());
export const loadOwnersSuccessAction = createAction(OwnerActions.LOAD_OWNERS_SUCCESS, props<{ owners: PropertyOwner[]; refreshAll?: boolean }>());
export const resetOwnersStateSuccessAction = createAction(OwnerActions.RESET_OWNERS_STATE_SUCCESS);
export const addNewPropertyToOwnerSuccessAction = createAction(OwnerActions.ADD_PROPERTY_TO_OWNER_SUCCESS, props<{ ownerId: string; propertiesIdsToAdd: string[] }>());
// Version 2
export const loadOwnersPaginatedSuccessAction = createAction(OwnerActions.LOAD_OWNERS_PAGINATED_SUCCESS, props<{ page: number; pageSize: number; ownersQueryResult: QueryResult<PropertyOwner> }>());

// Reducer Failure Actions
export const loadOwnerFailureAction = createAction(OwnerActions.LOAD_OWNER_FAILURE, props<{ error?: any }>());
export const addOwnerFailureAction = createAction(OwnerActions.ADD_OWNER_FAILURE, props<{ error?: any }>());
export const editOwnerFailureAction = createAction(OwnerActions.EDIT_OWNER_FAILURE, props<{ error?: any }>());
export const deleteOwnerFailureAction = createAction(OwnerActions.DELETE_OWNER_FAILURE, props<{ error?: any }>());
export const loadOwnersFailureAction = createAction(OwnerActions.LOAD_OWNERS_FAILURE, props<{ error?: any }>());
export const resetOwnersStateFailureAction = createAction(OwnerActions.RESET_OWNERS_STATE_FAILURE, props<{ error?: any }>());
export const addNewUnitToOwnerFailureAction = createAction(OwnerActions.ADD_UNIT_TO_OWNER_FAILURE, props<{ error?: any }>());
// Version 2
export const loadOwnersPaginatedFailureAction = createAction(OwnerActions.LOAD_OWNERS_PAGINATED_FAILURE, props<{ error?: any }>());
